import { isAfter } from "date-fns";

import {
  AssessmentType,
  EnrollmentFragment,
  ExamTiming,
  ReleasedRequirementsFragment,
} from "@/generated/graphql";
import { toDate } from "@/utils/datetime";

/** Base settings to an enrollment that are assessment type agnostic. */
export interface BaseSettings {
  /** Is this an exam. */
  isExam: boolean | null;

  /**
   * Whether the student has accessed the assessment yet.
   * Definition of access will be determined by the assessment type.
   */
  hasAccessed: boolean;
}

/** Settings connected to an enrollment. */
export interface AssessmentSettings {
  /** Final Due Date specified in the sheet. */
  sheetFinalDue: Date | null;
  /** Extended date granted to the student. */
  finalExtension: Date | null;
  /** Time limit (in minutes) specified in the sheet. */
  sheetTimeLimit: number | null;
  /** Extended time limit (in minutes) granted to the student. */
  timeExtension: number | null;
}

/**
 * Settings connected to an exam enrollment.
 */
export interface ExamSettings {
  /** Exam reading time limit (in minutes) specified in the sheet. */
  sheetExamReadingTime: number | null;
  /** Extended exam reading time limit (in minutes) granted to the student */
  readingTime: number | null;
  /** Exam writing time limit (in minutes) specified in the sheet. */
  sheetExamWritingTime: number | null;
  /** Extended exam writing time limit (in minutes) granted to the student */
  writingTime: number | null;
  /** Exam start date specified in the sheet. */
  sheetExamStartDate: Date | null;
  /** Alternate exam start date for student special consideration */
  alternateExamStartDate: Date | null;
  /** Exam end date specified in the sheet for window exam */
  sheetExamEndDate: Date | null;
  /** Alternate exam end date for student special consideration */
  alternateExamEndDate: Date | null;
  /** calculated extra reading time based on sheet and special consideration. */
  extraExamReadingTime: number | null;
  /** calculated extra writing time based on sheet and special consideration. */
  extraExamWritingTime: number | null;
  /** if it is exam, what's the exam timing (window/live). */
  examTiming: ExamTiming | null;
  /** if exam is deferred */
  deferred: boolean | null;
  /** if student have exam special consideration or not. */
  hasSpecialConsideration: boolean | null;
  /** if exam auto submission is on or not. */
  examAutoSubmission: boolean | null;
}

/**
 * The calculated settings for a given enrollment, which considers extensions
 * and special considerations.
 *
 * Uses the following GraphQL fragments:
 * - EnrollmentFragment
 * - InstructionSheetFragment
 */
export type EnrollmentSettings = AssessmentSettings &
  ExamSettings &
  BaseSettings;

export const createExamSettings = (
  enrollment: EnrollmentFragment,
  sheet?: ReleasedRequirementsFragment
): ExamSettings => {
  const workReadingTime = enrollment.workSettings?.examReadingTime ?? null;
  const workWritingTime = enrollment.workSettings?.examWritingTime ?? null;
  const sheetExamReadingTime = sheet?.examReadingTime ?? null;

  let readingTime: number | null = null;
  let extraExamReadingTime: number | null = null;

  const sheetExamWritingTime = sheet?.examWritingTime ?? null;
  let writingTime: number | null = null;
  let extraExamWritingTime: number | null = null;

  const sheetExamStartDate = toDate(sheet?.examStartDate);
  const alternateExamStartDate = toDate(enrollment.workSettings?.examStartDate);

  const sheetExamEndDate = toDate(sheet?.examEndDate);
  const alternateExamEndDate = toDate(enrollment.workSettings?.examEndDate);

  // Choose between the workReadingTime and sheetExamReadingTime to be the student's
  // readingTime
  if (
    sheetExamReadingTime &&
    workReadingTime &&
    workReadingTime > sheetExamReadingTime
  ) {
    readingTime = workReadingTime;
  } else if (!sheetExamReadingTime) {
    readingTime = workReadingTime;
  } else {
    readingTime = sheetExamReadingTime;
  }

  // Choose between the workWritingTime and sheetExamWritingTime to be the student's
  // writingTime
  if (
    sheetExamWritingTime &&
    workWritingTime &&
    workWritingTime > sheetExamWritingTime
  ) {
    writingTime = workWritingTime;
  } else if (!sheetExamWritingTime) {
    writingTime = workWritingTime;
  } else {
    writingTime = sheetExamWritingTime;
  }

  if (
    writingTime &&
    sheetExamWritingTime &&
    writingTime > sheetExamWritingTime
  ) {
    extraExamWritingTime = writingTime - sheetExamWritingTime;
  }

  if (
    readingTime &&
    sheetExamReadingTime &&
    readingTime > sheetExamReadingTime
  ) {
    extraExamReadingTime = readingTime - sheetExamReadingTime;
  }

  const deferred = enrollment.workSettings?.examDeferred ?? false;
  const hasSpecialConsideration =
    sheet?.assessmentType === AssessmentType.Exam &&
    sheet?.examTiming === ExamTiming.Live
      ? !!extraExamWritingTime ||
        !!extraExamReadingTime ||
        !!alternateExamStartDate
      : !!extraExamWritingTime ||
        !!extraExamReadingTime ||
        !!alternateExamStartDate ||
        !!alternateExamEndDate;

  return {
    sheetExamReadingTime,
    readingTime,
    sheetExamWritingTime,
    writingTime,
    sheetExamStartDate,
    alternateExamStartDate,
    sheetExamEndDate,
    alternateExamEndDate,
    extraExamReadingTime,
    extraExamWritingTime,
    deferred,
    hasSpecialConsideration,
    examAutoSubmission: sheet?.enableExamAutoSubmission ?? false,
    examTiming: sheet?.examTiming ?? null,
  };
};

/**
 * Extract and validate enrollment work settings.
 *
 * Due date and time limit extension settings are not valid if the Sheet's
 * requirements supercedes the duration of the extensions.
 */
export function createEnrollmentSettings(
  enrollment: EnrollmentFragment,
  sheet?: ReleasedRequirementsFragment
): EnrollmentSettings {
  const now = new Date();
  const isExam = sheet?.assessmentType === AssessmentType.Exam;
  const sheetFinalDue = toDate(sheet?.dueDate);
  const workDueDate = toDate(enrollment.workSettings?.dueDate);
  const workTimeLimit = enrollment.workSettings?.timeLimit ?? null;
  let finalExtension: Date | null = null;

  const sheetTimeLimit = sheet?.timeLimit ?? null;
  let timeExtension: number | null = null;

  // Choose between the workDueDate and sheetDueDate to be the student's
  // finalDueDate.
  if (sheetFinalDue && workDueDate && isAfter(workDueDate, sheetFinalDue)) {
    finalExtension = workDueDate;
  } else if (!sheetFinalDue) {
    finalExtension = workDueDate;
  }

  // Choose between the workTimeLimit and sheetTimeLimit to be the student's
  // finalTimeLimit
  if (sheetTimeLimit && workTimeLimit && workTimeLimit > sheetTimeLimit) {
    timeExtension = workTimeLimit;
  } else if (!sheetTimeLimit) {
    timeExtension = workTimeLimit;
  }

  const examSettings = createExamSettings(enrollment, sheet);

  let hasAccessed = false;
  switch (sheet?.assessmentType) {
    case AssessmentType.Exam:
      if (examSettings.examTiming === ExamTiming.Window) {
        hasAccessed = !!enrollment.work?.startDate;
      } else {
        const startDate = examSettings.alternateExamStartDate
          ? examSettings?.alternateExamStartDate
          : examSettings?.sheetExamStartDate;
        hasAccessed = !!(
          startDate &&
          isAfter(now, startDate) &&
          enrollment.work
        );
      }

      break;
    case AssessmentType.Assignment:
      hasAccessed = !!enrollment.work;
      break;
    default:
      hasAccessed = false;
  }

  return {
    isExam,
    sheetFinalDue,
    finalExtension,
    sheetTimeLimit,
    timeExtension,
    hasAccessed,
    // New Exam settings
    ...examSettings,
  };
}
