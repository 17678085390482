import { ComponentProps } from "react";

import { Button, Text } from "@vericus/cadmus-ui";

import { FeedbackOptionModal } from "../feedback-option-modal";
import * as styles from "./feedback-release-summary.css";

interface FeedbackReleaseSummaryProps
  extends ComponentProps<typeof FeedbackOptionModal> {
  /** Is there a custom list of students have have been selected */
  hasStudentSelection: boolean;
  /** Total number of outcomes */
  outcomeCount: number;
  /** Number of outcomes that have been released */
  releasedOutcomeCount: number;
  /** Number of feedback release rules that exist. */
  ruleCount: number;
  /** Number of pending feedback release rules. */
  pendingRuleCount: number;
  /** Number of penalty rules that exist. */
  penaltyCount: number;
  /** Callback to go to feedback release rule setup page */
  onGotoSelectivelyReleaseFeedbackPage: () => void;
  /** Callback to go to penalty rule setup page */
  onGoToPenaltySetupPage: () => void;
}

/**
 * Renders a summary of feedback and penalty rules, outcomes, and actions on the
 * moderation page.
 */
export function FeedbackReleaseSummary(props: FeedbackReleaseSummaryProps) {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.title}>
          <Text kind="bodyMd" bold>
            Feedback released
          </Text>
          <div className={styles.feedbackCount}>
            <Text kind="headingThree">{props.releasedOutcomeCount}</Text>
            <Text kind="headingThree" style={{ color: "#B9C0CF" }}>
              /{props.outcomeCount}
            </Text>
          </div>
        </div>
        {props.ruleCount > 0 ? (
          <Button
            kind="primary"
            fullWidth
            onClick={props.onGotoSelectivelyReleaseFeedbackPage}
          >
            {releaseFeedbackButtonText(
              props.pendingRuleCount,
              props.releasedOutcomeCount,
              props.outcomeCount,
              props.hasStudentSelection
            )}
          </Button>
        ) : (
          <FeedbackOptionModal
            hasStudentSelection={props.hasStudentSelection}
            feedbackViewLevel={props.feedbackViewLevel}
            allowPdfDownload={props.allowPdfDownload}
            onConfirmReleaseInstantly={props.onConfirmReleaseInstantly}
            onConfirmSelectivelyRelease={props.onConfirmSelectivelyRelease}
          />
        )}
        <Button kind="outline" fullWidth onClick={props.onGoToPenaltySetupPage}>
          {props.penaltyCount > 0
            ? `${props.penaltyCount} ${plural(props.penaltyCount, "Penalty", "Penalties")} applied`
            : "Set up penalties"}
        </Button>
      </div>
    </div>
  );
}

function releaseFeedbackButtonText(
  pendingRules: number,
  released: number,
  total: number,
  hasCustomSelection: boolean
) {
  if (released === total) return "All feedback released";
  if (pendingRules === 0 || hasCustomSelection) return "Release Feedback";
  return `${pendingRules} ${plural(pendingRules, "Release", "Releases")} scheduled`;
}

function plural(count: number, singular: string, plural: string) {
  return count === 1 ? singular : plural;
}
