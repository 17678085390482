import { useCallback } from "react";

import { useJitsu } from "@jitsu/react";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { EnrollmentFragment, StudentSettingsInput } from "@/generated/graphql";
import { useSpawnToastCallback } from "@/ui/exam-special-considerations/hooks/useSpawnToastCallback";
import { useUpdateExamSpecialConsiderations } from "@/ui/exam-special-considerations/hooks/useUpdateExamSpecialConsiderations";
import { SpecialConsiderationFormAction } from "@/ui/exam-special-considerations/SpecialConsiderationFormAction";

/**
 * Callback to defer the given enrollments. This is essentially the same thing as withdrawing the student from class but
 * just in exam terms.
 *
 * It fires a mutation to set the field `examDeferred` to `true` for the given enrollments and upon success, it spawns a toast.
 * @param assessmentId
 */
export const useDeferEnrollments = (assessmentId: string) => {
  const updateExamSpecialConsiderations = useUpdateExamSpecialConsiderations();
  const { track } = useJitsu();
  const spawnToast = useSpawnToastCallback();

  return useCallback(
    async (enrollments: EnrollmentFragment[]) => {
      if (enrollments.length === 0) {
        return;
      }

      await updateExamSpecialConsiderations(
        assessmentId,
        enrollments.flatMap((enrollment) => {
          const {
            __typename,
            id: _id,
            ...currentSpecialConsiderations
          } = enrollment.workSettings || {};

          return [
            {
              studentId: enrollment.user.id,
              ...currentSpecialConsiderations,
              examDeferred: true,
            } satisfies StudentSettingsInput,
          ];
        })
      );

      if (enrollments.length === 1) {
        await track(JitsuEvent.STUDENT_DEFERRED, {
          assessment_id: assessmentId,
          enrollment_id: enrollments[0]!.id,
          student_id: enrollments[0]!.user.id,
        });
      } else {
        await track(JitsuEvent.STUDENT_BULK_DEFERRED, {
          assessment_id: assessmentId,
          enrollment_id: enrollments.map((e) => e.id),
          student_id: enrollments.map((e) => e.user.id),
        });
      }

      spawnToast(enrollments, SpecialConsiderationFormAction.UPDATED_DEFERRED);
    },
    [assessmentId, spawnToast, track, updateExamSpecialConsiderations]
  );
};
