import { styled } from "styled-components";

import { desktop, LinkButtonV2, Spacer, Text } from "@vericus/cadmus-ui";

import { useAppDispatch, useAppSelector } from "@/data/hooks";
import {
  DuplicatePastAssessmentCallback,
  PreviewPastAssessmentCallback,
  resetPastAssessmentFilter,
  resetPastAssessmentNameQueryFilter,
} from "@/data/templates";
import {
  selectFilterPastAssessments,
  selectIsPastAssessmentFiltersInitial,
} from "@/data/templates/selectors";
import { AssessmentType, TaskFormat } from "@/generated/graphql";
import { IPastAssessment } from "@/ui/templates/ConnectedMyAssessments";
import { PastAssignmentCard } from "@/ui/templates/MyAssessments/PastAssignmentCard";
import { PastExamCard } from "@/ui/templates/MyAssessments/PastExamCard";

import { NoFilteredPastAssessments } from "./NoFilteredPastAssessments";
import { NoPastAssessments } from "./NoPastAssessments";

interface PastAssessmentProps {
  /**
   * All the past assessments. This should not be a filtered list.
   */
  pastAssessments: IPastAssessment[];
  /**
   * Callback function for when the user intends to duplicate the selected
   * assessment.
   * @param pastAssessmentId - the ID of the assessment selected.
   */
  onDuplicate: DuplicatePastAssessmentCallback;
  /**
   * Callback function for when the user intends to preview the selected assessment
   * @param pastAssessmentId - the ID of the assessment selected.
   * @param pastAssessmentName - the name of the assessment selected.
   */
  onPreview: PreviewPastAssessmentCallback;
}

/**
 * The container that renders all the past assessments. This will take the store's
 * filtering state into consideration.
 */
export const PastAssessments = (props: PastAssessmentProps) => {
  const dispatch = useAppDispatch();
  const isDefaultFilter = useAppSelector(selectIsPastAssessmentFiltersInitial);
  const filteredAssessments = useAppSelector((state) =>
    selectFilterPastAssessments(state, props.pastAssessments)
  );

  if (props.pastAssessments.length === 0) {
    return <NoPastAssessments />;
  }

  if (filteredAssessments.length === 0) {
    return (
      <>
        {!isDefaultFilter && (
          <>
            <Text kind="bodyMd">
              {filteredAssessments.length} assessment
              {filteredAssessments.length > 1 ? "s" : ""} found -{" "}
              <StyledLinkButton
                onClick={() => {
                  dispatch(resetPastAssessmentFilter(null));
                }}
              >
                Clear all
              </StyledLinkButton>
            </Text>

            <Spacer spacing={12} />
          </>
        )}
        <NoFilteredPastAssessments
          onClear={() => {
            dispatch(resetPastAssessmentFilter(null));
          }}
          onNameQueryClear={() => {
            dispatch(resetPastAssessmentNameQueryFilter(null));
          }}
        />
      </>
    );
  }

  const filteredResultText = `${filteredAssessments.length} assessment${
    filteredAssessments.length > 1 ? "s" : ""
  } found`;

  return (
    <>
      {!isDefaultFilter && (
        <>
          <Text kind="bodyMd">
            <span>{filteredResultText}</span>
            {" - "}
            <StyledLinkButton
              onClick={() => {
                dispatch(resetPastAssessmentFilter(null));
              }}
            >
              Clear all
            </StyledLinkButton>
          </Text>

          <Spacer spacing={12} />
        </>
      )}
      <PastAssessmentsContainer>
        {filteredAssessments.map((pastAssessment) => {
          switch (pastAssessment.assessmentType) {
            case AssessmentType.Exam:
              return (
                <PastExamCard
                  assessmentId={pastAssessment.assessmentId}
                  instructionSheetVersionId={
                    pastAssessment.instructionSheetVersionId
                  }
                  name={pastAssessment.name}
                  subject={pastAssessment.subject}
                  examStartDate={pastAssessment.examStartDate}
                  examEndDate={pastAssessment.examEndDate}
                  onDuplicate={props.onDuplicate}
                  onPreview={props.onPreview}
                  key={pastAssessment.assessmentId}
                  examTiming={pastAssessment.examTiming}
                />
              );
            case AssessmentType.Assignment:
            default:
              return (
                <PastAssignmentCard
                  assessmentId={pastAssessment.assessmentId}
                  instructionSheetVersionId={
                    pastAssessment.instructionSheetVersionId
                  }
                  name={pastAssessment.name}
                  subject={pastAssessment.subject}
                  dueDate={pastAssessment.dueDate}
                  returnDate={pastAssessment.returnDate}
                  onDuplicate={props.onDuplicate}
                  onPreview={props.onPreview}
                  key={pastAssessment.assessmentId}
                  assessmentType={pastAssessment.assessmentType}
                  taskFormat={pastAssessment.taskFormat || TaskFormat.Classic}
                />
              );
          }
        })}
      </PastAssessmentsContainer>
    </>
  );
};

const StyledLinkButton = styled(LinkButtonV2)`
  font-size: 16px;
`;

const PastAssessmentsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;

  ${desktop`
    grid-template-columns: 1fr 1fr;
  `}
`;
