import { ReactNode } from "react";

import { ExamTiming } from "@/generated/graphql";
import { formatFullSentence } from "@/utils/datetime";

import { ActionableRequirementRow, RequirementRow } from "./shared";

/** Display the exam feedback return date. */
export function ExamReturnDate(props: {
  examReturnDate: string | null;
  hasChanged?: boolean;
  setExamFeedbackDate?: VoidFunction;
}) {
  if (props.examReturnDate) {
    return (
      <RequirementRow
        title="Return date"
        text={`Grades and feedback will be returned to students on ${formatFullSentence(
          new Date(props.examReturnDate)
        )}`}
        hasChanged={props.hasChanged}
      />
    );
  }
  return (
    <ActionableRequirementRow
      title={"Return date"}
      text="Select date"
      hasChanged={props.hasChanged}
      onAction={props.setExamFeedbackDate}
    />
  );
}

/** Display the writing time in minutes. */
export function WritingTime(props: {
  writingTime: number | null;
  errorMessage?: ReactNode;
  hasChanged?: boolean;
  isWindowExam: boolean;
}) {
  const { isWindowExam, errorMessage, writingTime, hasChanged } = props;
  const isWindowExamPostfix = isWindowExam ? "in one sitting" : "";
  const text =
    errorMessage ??
    (writingTime
      ? `${writingTime} minute${
          writingTime === 1 ? "" : "s"
        } ${isWindowExamPostfix}`
      : "");
  return (
    <RequirementRow
      title="Writing time"
      text={text}
      hasError={!!errorMessage}
      hasChanged={hasChanged}
    />
  );
}

/** Display the reading time in minutes. */
export function ReadingTime(props: {
  readingTime: number | null;
  errorMessage?: ReactNode;
  hasChanged?: boolean;
}) {
  const { errorMessage, readingTime, hasChanged } = props;
  const text =
    errorMessage ??
    (readingTime ? `${readingTime} minute${readingTime === 1 ? "" : "s"}` : "");
  return (
    <RequirementRow
      title="Reading time"
      text={text}
      hasError={!!errorMessage}
      hasChanged={hasChanged}
    />
  );
}

/** Display the exam start date and time. Display the title slightly differently based on the type of exam. */
export function ExamStart(props: {
  examType: ExamTiming;
  examStart: string | null;
  errorMessage?: ReactNode;
  hasChanged?: boolean;
  setExamStart?: VoidFunction;
}) {
  const formattedDate =
    props.examStart && formatFullSentence(new Date(props.examStart));
  const text = props.errorMessage ?? formattedDate ?? "";
  const title = props.examType === ExamTiming.Live ? "Exam start" : "Exam open";

  if (props.examStart || props.errorMessage) {
    return (
      <RequirementRow
        title={title}
        text={text}
        hasError={!!props.errorMessage}
        hasChanged={props.hasChanged}
      />
    );
  }
  return (
    <ActionableRequirementRow
      title={title}
      text="Select date"
      hasChanged={props.hasChanged}
      onAction={props.setExamStart}
    />
  );
}

/** Display the exam end date and time. Display the title slightly differently based on the type of exam. */
export function ExamEnd(props: {
  examType: ExamTiming;
  examEnd: string | null;
  errorMessage?: ReactNode;
  hasChanged?: boolean;
  setExamEnd?: VoidFunction;
}) {
  const formattedDate =
    props.examEnd && formatFullSentence(new Date(props.examEnd));
  const text = props.errorMessage ?? formattedDate ?? "";
  const title = props.examType === ExamTiming.Live ? "Exam end" : "Exam close";
  if (!(props.examEnd || props.errorMessage))
    return (
      <ActionableRequirementRow
        title={title}
        text="Select date"
        hasChanged={props.hasChanged}
        onAction={props.setExamEnd}
      />
    );

  return (
    <RequirementRow
      title={title}
      text={text}
      hasError={!!props.errorMessage}
      hasChanged={props.hasChanged}
    />
  );
}

/** Display that students will get a PDF copy of their submission. */
export function PdfSubmission(props: {
  isPdfCopyOn?: boolean;
  hasChanged?: boolean;
}) {
  return (
    <RequirementRow
      title="PDF copy"
      text="Students will receive a copy of their written submission"
      hasChanged={props.hasChanged}
    />
  );
}

/**
 * Display auto submission information, including the time up to
 * which students are allowed to submit late if autosubmission is off.
 **/
export function ExamAutosubmission(props: {
  /** If autosubmission is enabled then the late submission time limit is empty (or should be ignored at least) */
  isAutosubmisisonEnabled?: boolean;
  /** How late students can submit the exam (in minutes) in case auto submission is off. */
  lateSubmissionMinsTimeLimit: number | null;
  hasChanged?: boolean;
}) {
  return (
    <RequirementRow
      title={
        props.isAutosubmisisonEnabled ? "Auto submission" : "No auto submission"
      }
      text={
        props.isAutosubmisisonEnabled
          ? "Student's work will be auto submitted at the end of the exam"
          : props.lateSubmissionMinsTimeLimit
          ? `Students can submit late up to ${
              props.lateSubmissionMinsTimeLimit
            } minute${
              props.lateSubmissionMinsTimeLimit > 1 ? "s" : ""
            } after the exam ends. They will not be able to submit after that`
          : "Students can submit late indefinitely after the exam ends"
      }
      hasChanged={props.hasChanged}
    />
  );
}

export function ExamLDB(props: { ldbEnabled?: boolean; hasChanged?: boolean }) {
  return (
    <RequirementRow
      title="LockDown Browser"
      text={
        props.ldbEnabled
          ? "Students must use LockDown Browser to complete the exam"
          : "Students will not be required to use LockDown Browser"
      }
      hasChanged={props.hasChanged}
    />
  );
}
