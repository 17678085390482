/** Enum of error kinds in Athena. */
export enum ErrorKind {
  /// When updating requirements fail
  UpdateSettings,
  /// When task content saving fails
  SaveContent,
  /// When switching to editing a task fails
  EditTask,
  /// When releasing a task fails
  Release,
  /// When cancelling a future release fails
  RemoveRelease,
  /// When a group edit or create mutation fails
  GroupUpdate,
  /// General network issue error
  Network,
  /// Report generation fails for a student or assessment
  ReportGeneration,
  /// When a general update fails.
  /// Currently used as InstructionSheet update fail.
  Update,
}
