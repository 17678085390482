import { useCallback } from "react";

import { gql, Reference } from "@apollo/client";
import { toast } from "react-hot-toast";

import {
  EnrollmentFragment,
  ClassTab as GQLClassTab,
  useBulkAddTagMutation,
  useBulkRemoveTagMutation,
} from "@/generated/graphql";
import { ClassTab } from "@/ui/class/progress/types";

export function useAddTagMutation() {
  const [mutate] = useBulkAddTagMutation({ ignoreResults: true });

  return useCallback(
    (enrollments: EnrollmentFragment[], text: string, classTab: ClassTab) => {
      const tab = mapGQLTab(classTab);
      mutate({
        variables: {
          enrollmentIds: enrollments.map((enrollment) => enrollment.id),
          text,
          tab,
        },
        optimisticResponse: {
          bulkAddTag: enrollments.map((enrollment) => ({
            __typename: "Tag",
            id: `${enrollment.id}:${text}`,
            text,
            tab,
            column: 2,
          })),
        },
        onError: () => {
          toast.error("Failed to add tag");
        },
        update: (cache, { data }) => {
          // Don't do anything if the mutation errored
          if (!data) return;

          // Update all enrollments with the newly added tag
          for (const enrollment of enrollments) {
            cache.modify({
              id: cache.identify(enrollment),
              fields: {
                tags(existingTagRefs = []) {
                  const newTagRef = cache.writeFragment({
                    fragment: gql`
                      fragment EnrollmentTag on Tag {
                        id
                        text
                        tab
                        column
                      }
                    `,
                    data: {
                      __typename: "Tag",
                      id: `${enrollment.id}:${text}`,
                      text,
                      tab,
                      column: 2,
                    },
                  });

                  return [...existingTagRefs, newTagRef];
                },
              },
            });
          }
        },
      });
    },
    [mutate]
  );
}

export function useRemoveTagMutation() {
  const [mutate] = useBulkRemoveTagMutation({ ignoreResults: true });

  return useCallback(
    (enrollments: EnrollmentFragment[], text: string, classTab: ClassTab) => {
      const tab = mapGQLTab(classTab);
      return mutate({
        variables: {
          enrollmentIds: enrollments.map((enrollment) => enrollment.id),
          text,
          tab,
        },
        optimisticResponse: {
          bulkRemoveTag: true,
        },
        update: (cache, { data }) => {
          if (!data?.bulkRemoveTag) return;

          // Update all enrollments with the newly added tag
          for (const enrollment of enrollments) {
            cache.modify({
              id: cache.identify(enrollment),
              fields: {
                tags(existingTagRefs = [], { readField }) {
                  return existingTagRefs.filter(
                    (tagRef: Reference) =>
                      !(
                        text === readField("text", tagRef) &&
                        tab === readField("tab", tagRef)
                      )
                  );
                },
              },
            });
          }
        },
      });
    },
    [mutate]
  );
}

const mapGQLTab = (tab: ClassTab): GQLClassTab => {
  switch (tab) {
    case ClassTab.Drafts:
      return GQLClassTab.Drafts;
    case ClassTab.Finals:
      return GQLClassTab.Finals;
    case ClassTab.Students:
      return GQLClassTab.Students;
    case ClassTab.Submissions:
      return GQLClassTab.Finals;
  }
};
