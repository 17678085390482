import { styled } from "styled-components";

import { Switch, Text, Tooltip } from "@vericus/cadmus-ui";

import { HelpIcon } from "@/ui/shared/HelpIcon";

interface Props {
  /**
   * Callback when the shuffle switch is toggled
   */
  onToggleShuffle: () => void;
  /**
   * boolean value for the current state of the task layout shuffle.
   */
  shuffle: boolean;
  /**
   * boolean value for when to disable the shuffle switch.
   * Used for when a mutation is in progress.
   */
  disabled: boolean;
}

/**
   Pure component `TaskLayoutShuffle`. Mutations in `TaskLayoutShuffleWithData`.
   
   This component is located at the top of the TaskBuilderPage where academics
   build the MFA assessments.
    
   This component turns the task level shuffle on or off by inserting or 
   updating an overview block. Pantheon uses the overview block's shuffle property
   as the indicator to shuffle the entire assessment. Client-side, if an overview block exists, 
   update the overview block's `shuffle` property. If an overview block does not
   exist, insert an overview block with `shuffle` and `hidden` properties turned on.
   
   Each section and sub question node will maintain their own
   independent shuffle state in the TaskBuilder for the academic. 
   By turning task level shuffle on, section and sub question shuffle toggles
   will become disabled on the `TaskBuilderPage`. 
   But the task level shuffle will override each section and sub question's
   shuffle property for the student taking the assessment.
   It will have no bearing on MCQ blocks' shuffle state as MCQ shuffle is not
   overriden by the task level shuffle.
 */
export function TaskLayoutShuffle(props: Props) {
  const { onToggleShuffle, shuffle, disabled } = props;

  return (
    <Content>
      <Switch
        disabled={disabled}
        checked={shuffle}
        onClick={onToggleShuffle}
        content={"Shuffle Questions"}
        onChange={() => {}}
      />{" "}
      <Tooltip
        content={
          <Text kind="systemSm" color="black100">
            When enabled, this setting shuffles all questions, sections,
            sub-questions, and questions within sub-questions, excluding the
            order of sections themselves.
          </Text>
        }
      >
        <StyledDiv>
          <HelpIcon primary />
        </StyledDiv>
      </Tooltip>
    </Content>
  );
}

const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 12px;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;
