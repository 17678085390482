import { TaskFormat } from "@/generated/graphql";
import { SubmissionDetails } from "@/graphql/types/SubmissionDetails";
import { GradingLink } from "@/ui/StudentLists/GradingLink";
import * as Grid from "@/ui/StudentLists/InfoGrid";

interface GradeDataCellProps {
  /**
   * The submission to render the grade for.
   */
  submission: SubmissionDetails | null;

  /**
   * The grading service to use.
   */
  gradingService: string | null;

  /**
   * Whether the submission has been withdrawn.
   */
  withdrawn: boolean;

  /**
   * Whether the submission has been deferred.
   */
  deferred: boolean;

  /**
   * Kind of CTA action to use: button or a link. Default is "button".
   */
  kind?: "button" | "link";

  /**
   * Assessment format
   */
  format?: TaskFormat;

  /**
   * Marking PDF's `agsSentAt` (multiformat only)
   */
  markingPdfSentAt: string | null;
}

/**
 * Renders a grade link, used in the context of a table cell.
 *
 * If the submission is withdrawn or deferred, or if there is no grading
 * service, then no link is rendered.
 *
 */
export function GradeDataCell(props: GradeDataCellProps) {
  const {
    submission,
    gradingService,
    withdrawn,
    deferred,
    format,
    markingPdfSentAt,
  } = props;

  if (!submission || !gradingService || withdrawn || deferred) {
    return null;
  }

  return (
    <Grid.ActionCol>
      <GradingLink
        data-component={"Table.Grade.GradingLink"}
        result={submission.result}
        gradingService={gradingService}
        isDraft={false}
        kind={props.kind}
        format={format}
        markingPdfSentAt={markingPdfSentAt}
      />
    </Grid.ActionCol>
  );
}
