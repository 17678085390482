import { useCallback } from "react";
import { styled } from "styled-components";

import { Button, Modal, Text } from "@vericus/cadmus-ui";

import NiceModal from "@ebay/nice-modal-react";
import { useJitsu } from "@jitsu/react";
import toast from "react-hot-toast";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { Template, useSelectedSubcategory } from "@/data/templates";
import { useCopyAssessment } from "@/ui/templates/templatesHooks";

import { useClearEditorContents, useInsertHtmlContent } from "./editorHooks";
import {
  TemplatesModalId,
  useAppModal,
  useCloseAllTemplatesModals,
} from "./modals";

type EmptyContentReplacement = {
  type: "empty-content-replacement";
};

type TemplateReplacement = {
  type: "template-replacement";
  template: Template;
};

type PastAssessmentReplacement = {
  type: "past-assessment-replacement";
  currentAssessmentId: string;
  instructionSheetVersionId: string;
  pastAssessmentId: string;
  pastAssessmentName: string;
};

type UseCase =
  | EmptyContentReplacement
  | TemplateReplacement
  | PastAssessmentReplacement;

export interface ContentReplaceModalProps {
  /** Whether the modal should be displayed to the user. */
  isVisible: boolean;
  /** The use case for opening this confirmation modal. */
  useCase: UseCase;
  /** Called when the user indicates that they don't want to proceed. */
  onCancelClick: VoidFunction;
  /** Called when the user indicates that they want to proceed. */
  onConfirmClick: VoidFunction;
}

/** Asks to confirm the user that they want to replace their current editor contents.
 * Warn them that they may erase existing content if applicable.
 */
export const ContentReplaceModal = ({
  isVisible,
  useCase,
  onCancelClick,
  onConfirmClick,
}: ContentReplaceModalProps) => {
  return (
    <Modal.Root open={isVisible}>
      <StyledModalContent withCloseButton={false} style={{ zIndex: 1000 }}>
        <Modal.Title asChild>
          <Text kind="headingFour" textAlign="center">
            <ModalTitle useCase={useCase} />
          </Text>
        </Modal.Title>

        <Text
          kind="bodyMd"
          textAlign="center"
          style={{ marginTop: "16px", marginBottom: "24px" }}
        >
          <ModalText useCase={useCase} />
        </Text>
        <ButtonsContainer>
          <Modal.Close asChild>
            <Button onClick={onCancelClick}>Cancel</Button>
          </Modal.Close>
          <Button kind="secondary" onClick={onConfirmClick}>
            {getAffirmativeActionText(useCase)}
          </Button>
        </ButtonsContainer>
      </StyledModalContent>
    </Modal.Root>
  );
};

/*
 * Empty the editor or replace with template's instructions.
 */
export const useOnConfirm = (useCase: UseCase) => {
  const insertTemplateContent = useInsertHtmlContent();
  const clearEditor = useClearEditorContents();
  const closeTemplateModals = useCloseAllTemplatesModals();
  const copyAssessment = useCopyAssessment();
  const { track } = useJitsu();
  const selectedSubcategory = useSelectedSubcategory();

  return useCallback(() => {
    switch (useCase.type) {
      case "empty-content-replacement":
        clearEditor();
        closeTemplateModals();
        toast.success("Previous instructions have been cleared");
        track(JitsuEvent.INSTRUCTIONS_CLEARED);
        return;
      case "template-replacement": {
        const { id, content, title } = useCase.template;
        insertTemplateContent(content);
        closeTemplateModals();
        toast.success(`'${title}' template has been added`);
        if (selectedSubcategory) {
          track(JitsuEvent.TEMPLATE_USED, {
            template_id: id,
            template_title: title,
            subcategory_id: selectedSubcategory.id,
            subcategory_title: selectedSubcategory.title,
          });
        }
        return;
      }
      case "past-assessment-replacement": {
        const {
          pastAssessmentId,
          pastAssessmentName,
          instructionSheetVersionId,
          currentAssessmentId,
        } = useCase;
        copyAssessment(
          currentAssessmentId,
          pastAssessmentName,
          instructionSheetVersionId
        );
        closeTemplateModals();
        toast.success(`'${pastAssessmentName}' has been duplicated`);
        track(JitsuEvent.TEMPLATE_SHEET_USED, {
          assessment_id: pastAssessmentId,
          assessment_name: pastAssessmentName,
          sheet_id: instructionSheetVersionId,
        });
        return;
      }
    }
  }, [
    closeTemplateModals,
    copyAssessment,
    insertTemplateContent,
    clearEditor,
    useCase,
    track,
    selectedSubcategory,
  ]);
};

const useOnCancel = () => {
  const { remove } = useAppModal(TemplatesModalId.EditorContentReplacement);
  return useCallback(() => {
    remove();
  }, [remove]);
};

export interface ConnectedContentReplaceModalProps {
  useCase: UseCase;
}

export const ConnectedContentReplaceModal =
  NiceModal.create<ConnectedContentReplaceModalProps>(({ useCase }) => {
    const onConfirm = useOnConfirm(useCase);
    const onCancelClick = useOnCancel();
    const { visible } = useAppModal(TemplatesModalId.EditorContentReplacement);
    return (
      <ContentReplaceModal
        onCancelClick={onCancelClick}
        onConfirmClick={onConfirm}
        isVisible={visible}
        useCase={useCase}
      />
    );
  });

const ModalTitle = ({ useCase }: { useCase: UseCase }) => {
  switch (useCase.type) {
    case "empty-content-replacement":
      return <>Start again with new instructions?</>;
    case "template-replacement":
      return <>Overwrite instructions?</>;
    case "past-assessment-replacement":
      return <>Overwrite instructions?</>;
  }
};

const ModalText = ({ useCase }: { useCase: UseCase }) => {
  switch (useCase.type) {
    case "empty-content-replacement":
      return (
        <>
          {`Your exisiting instructions will be cleared and you won't be able to `}
          revert back.
        </>
      );
    case "template-replacement":
      return (
        <>
          Your existing instructions will be overwritten. Once you select this{" "}
          {`template, you won't be able to revert back.`}
        </>
      );
    case "past-assessment-replacement":
      return (
        <>
          Duplicating this assessment will overwrite your existing instructions{" "}
          {`and you won't be able to revert back.`}
        </>
      );
  }
};

const getAffirmativeActionText = (useCase: UseCase) => {
  switch (useCase.type) {
    case "empty-content-replacement":
      return "Start again";
    case "template-replacement":
    case "past-assessment-replacement":
      return "Overwrite";
  }
};

const StyledModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 78px;
  box-sizing: border-box;
  width: 570px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
`;
