import { QuestionType } from "@/generated/graphql";

interface QuestionItem {
  questionId: string;
  questionType: QuestionType;
  score: number | null;
  maxScore: number | null;
  nodeId: string;
  parentNodeId: string | null;
}

/**
 * Given sub question nodeId and questionItems, return
 * score of sub question.
 *
 * Function will return null if any child question score is null.
 * Otherwise, return sum of child question score.
 *
 * @param subQuestionNodeId Sub question nodeId
 * @param questionItems All question items of a task
 * @returns Score of sub question
 */
export const getSubquestionScore = (
  subQuestionNodeId: string,
  questionItems: QuestionItem[]
) => {
  const childQuestions = questionItems.filter(
    (item) => item.parentNodeId === subQuestionNodeId
  );
  const hasUnmarkedChild =
    childQuestions.find((childQuestion) => childQuestion.score === null) !==
    undefined;

  if (hasUnmarkedChild) return null;

  return childQuestions.reduce(
    (totalScore, item) => totalScore + (item.score ?? 0),
    0
  );
};

/**
 * Given sub question nodeId and questionItems, return
 * sum max score of all child questions.
 *
 */
export const getSubQuestionMaxScore = (
  subQuestionNodeId: string,
  questionItems: QuestionItem[]
) => {
  const childQuestions = questionItems.filter(
    (item) => item.parentNodeId === subQuestionNodeId
  );
  return childQuestions.reduce(
    (totalMaxScore, item) => totalMaxScore + (item.maxScore ?? 0),
    0
  );
};

/**
 * Given section question id and questionItems, return
 * all questions that are in this section.
 */
export const getSectionQuestion = (
  sectionQuestionId: string,
  questionItems: QuestionItem[]
) => {
  let belongToSection = false;
  const sectionQuestions: QuestionItem[] = [];

  for (const questionItem of questionItems) {
    if (questionItem.questionId === sectionQuestionId) belongToSection = true;
    else if (questionItem.questionType === QuestionType.Section)
      belongToSection = false;
    else if (belongToSection) sectionQuestions.push(questionItem);
  }

  return sectionQuestions;
};

/**
 * Given section question id and questionItems, return
 * sum  score of all questions that are in this section.
 */
export const getSectionScore = (
  sectionQuestionId: string,
  questionItems: QuestionItem[]
) => {
  const sectionQuestions = getSectionQuestion(sectionQuestionId, questionItems);
  return sectionQuestions.reduce((totalScore, item) => {
    return totalScore + (item.score ?? 0);
  }, 0);
};

/**
 * Given section question id and questionItems, return
 * sum max score of all questions that are in this section.
 */
export const getSectionMaxScore = (
  sectionQuestionId: string,
  questionItems: QuestionItem[]
) => {
  const sectionQuestions = getSectionQuestion(sectionQuestionId, questionItems);
  return sectionQuestions.reduce(
    (totalMaxScore, item) => totalMaxScore + (item.maxScore ?? 0),
    0
  );
};

/**
 * Given section question id, all question items, filtered question ids, return the checked
 * state of section question.
 *
 * Return checked state will be false if none of the questions in section are checked,
 * true if all questions in section are checked, indeterminate if only part of questions
 * are checked
 *
 * @param sectionQuestionId  question id of section question
 * @param items all question items of student
 * @param filteredQuestionIds ids of filtered questions
 * @returns checkedState
 */
export const getSectionQuestionCheckedState = (
  sectionQuestionId: string,
  items: QuestionItem[],
  filteredQuestionIds: string[]
) => {
  let checkedState: "indeterminate" | boolean = false;

  const sectionQuestionIds = getSectionQuestion(sectionQuestionId, items).map(
    (sectionQuestion) => sectionQuestion.questionId
  );
  if (
    sectionQuestionIds.every((sectionQuestionId) =>
      filteredQuestionIds.includes(sectionQuestionId)
    )
  ) {
    checkedState = true;
  } else if (
    sectionQuestionIds.some((sectionQuestionId) =>
      filteredQuestionIds.includes(sectionQuestionId)
    )
  ) {
    checkedState = "indeterminate";
  }

  return checkedState;
};

/**
 * Helper function for question filter list which returns updated array of
 * question ids based on the selected question and previous selection state.
 */
export function updateSelectedQuestionIds(
  selectedQuestionIds: string[],
  question: QuestionItem,
  allQuestions: QuestionItem[],
  visibleQuestions?: QuestionItem[]
): string[] {
  const visibleItems = visibleQuestions ?? allQuestions;
  if (selectedQuestionIds.includes(question.questionId)) {
    const unselectQuestionIds = getQuestionIdsToUnselect(
      question,
      allQuestions,
      visibleItems
    );
    return selectedQuestionIds.filter(
      (qid) => !unselectQuestionIds.includes(qid)
    );
  } else {
    const selectQuestionIds = getQuestionIdsToSelect(
      question,
      allQuestions,
      visibleItems
    );
    return Array.from(new Set([...selectedQuestionIds, ...selectQuestionIds]));
  }
}

function selectParentQuestionId<T extends QuestionItem>(
  question: T,
  allQuestions: T[]
): string | null {
  if (question.parentNodeId === null) return null;

  const parentQuestion = allQuestions.find(
    (parent) => parent.nodeId === question.parentNodeId
  );
  return parentQuestion ? parentQuestion.questionId : null;
}

const selectSectionQuestionIds = (
  item: QuestionItem,
  allQuestions: QuestionItem[]
): string[] => {
  return getSectionQuestion(item.questionId, allQuestions).map(
    (sectionQuestion) => sectionQuestion.questionId
  );
};

const selectSubQuestionIds = (
  item: QuestionItem,
  visibleQuestions: QuestionItem[]
): string[] => {
  return selectSubQuestions(item, visibleQuestions).map(
    (child) => child.questionId
  );
};

function getQuestionIdsToUnselect(
  question: QuestionItem,
  allQuestions: QuestionItem[],
  visibleQuestions: QuestionItem[]
) {
  let unselectQuestionIds = [question.questionId];

  if (question.questionType === QuestionType.Sub) {
    unselectQuestionIds = [
      ...unselectQuestionIds,
      ...selectSubQuestionIds(question, visibleQuestions),
    ];
  }

  if (question.questionType === QuestionType.Section) {
    unselectQuestionIds = [
      ...unselectQuestionIds,
      ...selectSectionQuestionIds(question, allQuestions),
    ];
  }

  return unselectQuestionIds;
}

function getQuestionIdsToSelect(
  question: QuestionItem,
  allQuestions: QuestionItem[],
  visibleQuestions: QuestionItem[]
) {
  let selectQuestionIds = [question.questionId];

  if (question.questionType === QuestionType.Section) {
    selectQuestionIds = [
      ...selectQuestionIds,
      ...selectSectionQuestionIds(question, allQuestions),
    ];
  }

  if (question.questionType === QuestionType.Sub) {
    selectQuestionIds = [
      ...selectQuestionIds,
      ...selectSubQuestionIds(question, visibleQuestions),
    ];
  }

  if (question.parentNodeId !== null) {
    const parentQuestionId = selectParentQuestionId(question, visibleQuestions);
    if (parentQuestionId) {
      selectQuestionIds.push(parentQuestionId);
    }
  }
  return selectQuestionIds;
}

export function selectSubQuestions<T extends QuestionItem>(
  question: T,
  allQuestions: T[]
): T[] {
  if (question.questionType !== QuestionType.Sub) return [];
  return allQuestions.filter((child) => child.parentNodeId === question.nodeId);
}
