import { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";

import { TextArea, TextAreaProps } from "@vericus/cadmus-ui";

import { useUpdateAssessmentNameMutation } from "generated/graphql";

interface Props extends TextAreaProps {
  assessmentId: string;
  isReadOnly: boolean;
  content: string;
}

/**
 * Editor for updating the Assessment name. Uses a styled <input /> field with debounced saving.
 */
export function HeaderEditor(props: Props) {
  const { isReadOnly, content, assessmentId, ...rest } = props;
  const [value, setValue] = useState(content);
  const [updateName] = useUpdateAssessmentNameMutation({ onError: () => {} });

  const onUpdateAssessmentName = useCallback(
    (name: string) => {
      updateName({
        variables: { assessmentId, name },
        optimisticResponse: {
          updateAssessmentName: {
            id: assessmentId,
            name,
            __typename: "Assessment",
          },
        },
      });
    },
    [assessmentId, updateName]
  );

  // Debounced effect to fire the assessment name update mutation
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (value !== content) {
        onUpdateAssessmentName(value);
      }
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [content, value, onUpdateAssessmentName]);

  return (
    <Container>
      <StyledAutoWidthWrapper
        defaultValue={value}
        renderTextArea={(onContentUpdate) => (
          <StyledInput
            placeholder="Give me a name..."
            disabled={isReadOnly}
            aria-label="assesment name"
            value={value}
            onChange={(e) => {
              setValue(e.currentTarget.value);
              onContentUpdate(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                e.currentTarget.blur();
              }
            }}
            withAutoWidthWrapper
            {...rest}
          />
        )}
      />
    </Container>
  );
}
const Container = styled.span`
  position: relative;
`;

const StyledAutoWidthWrapper = styled(TextArea.AutoWidthWrapper)`
  width: 100%;
  padding: 0;
  min-width: 180px;
  min-height: 36px;
  box-sizing: border-box;
  margin: 0;

  font-size: 1em;
  font-weight: inherit;
  color: inherit;
  line-height: 1;
  outline: none;
  padding: 0;
  background: none;
`;

const StyledInput = styled(TextArea.Input)`
  border: none;
  background: none;

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    color: ${(p) => p.theme.text.shade1};
    font-size: 0.875em;
    letter-spacing: 0.3px;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.36;
    background: transparent;
  }
`;
