import { createContext, useContext } from "react";

import { Editor } from "@vericus/cadmus-editor-prosemirror";
import { SearchListItem } from "@vericus/cadmus-ui";

export const ImportedFileTableRowContext = createContext<Editor | null>(null);

export const useImportedFileTableRowContext = () =>
  useContext(ImportedFileTableRowContext);

export interface ImportedFilesTableHandler {
  onUpdateFilename?: (fileId: string, filename: string) => void;
}

export const ImportedFilesTableContext =
  createContext<ImportedFilesTableHandler>({});

export const useImportedFilesTableContext = () =>
  useContext(ImportedFilesTableContext);

export interface QuestionBanksCategoryHandler {
  onAdd?: (category: string) => void;
  onSelect?: (
    category: string,
    fileOriginId?: string,
    questionIds?: string[]
  ) => void;
  categories?: SearchListItem[];
}

export const QuestionBanksCategoryContext =
  createContext<QuestionBanksCategoryHandler>({});

export const useQuestionBanksCategoryContext = () =>
  useContext(QuestionBanksCategoryContext);
