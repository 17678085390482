import { styled } from "styled-components";

import { ControlButton, Text } from "@vericus/cadmus-ui";

interface Props {
  onInsertBlank: () => void;
}

/** Insert blank button for fill in blanks question */
export const InsertBlank = ({ onInsertBlank }: Props) => (
  <Button onClick={onInsertBlank}>
    <StyledText kind="systemMd" color="pink800">
      Insert a blank
    </StyledText>
  </Button>
);

const Button = styled(ControlButton)`
  border-radius: 4px;
  border: 1px dashed ${(p) => p.theme.colors.pink700};
  background: var(--Secondary-Colour-Palette-Pink-200, #fcf6fc);
  padding: 8px 16px;
  display: inline-block;
`;

const StyledText = styled(Text)`
  display: inline-block;
`;
