import { createCadmusEditor, Editor } from "@vericus/cadmus-editor-prosemirror";

import JSZip, { JSZipObject } from "jszip";
import filetype from "magic-bytes.js";
import { v4 as uuid } from "uuid";

import { deserializeTaskBuilderBlockEditor } from "@/features/multi-format/task-builder-block/utils";
import {
  Blank,
  BlanksResponse,
  BooleanResponse,
  Cardinality,
  Choice,
  EditorResponse,
  MultiResponse,
  QuestionType,
  ShortResponse,
} from "@/generated/graphql";

import { ImportableQuestionType, ParsedQuestion } from "../../data";
import { Base64File, patchHTML as basePatchHTML } from "../utils";
import {
  QTIMaterial,
  QTIQuestion,
  QTIResponseCondition,
  QTIResponseContent,
} from "./types";

export interface Props {
  id: string;
  promptDoc: string | null;
  enableBlanks?: boolean;
}

export const createTaskBlockPreviewEditor = (props: Props) =>
  createCadmusEditor({
    editorId: props.id,
    editorA11yLabel: "Task instructions (read-only)",
    content: deserializeTaskBuilderBlockEditor(props.promptDoc) ?? "",
    editable: false,
    enableBlanks: props.enableBlanks,
  });

/**
 * Parse question type based on known qti metadata from things like
 * blackboard and canvas
 **/
function getMetadataQuestionType(question: QTIQuestion) {
  const maybeQuestionType = (question.metadata.question_type ??
    question.metadata.questiontype) as unknown as string | undefined;
  switch (maybeQuestionType) {
    // canvas' question type meta
    case "multiple_choice_question": {
      return QuestionType.Mcq;
    }
    case "true_false_question": {
      return QuestionType.Truefalse;
    }
    case "essay_question": {
      return QuestionType.Extended;
    }
    case "fill_in_multiple_blanks_question": {
      return QuestionType.Blanks;
    }
    // blackboard question type meta
    case "Short Response": {
      return QuestionType.Short;
    }
    case "Multiple Answer": {
      return QuestionType.Mcq;
    }
    case "Multiple Choice": {
      return QuestionType.Mcq;
    }
    case "Either/Or": {
      return QuestionType.Truefalse;
    }
    case "Essay": {
      return QuestionType.Extended;
    }
    default:
      return null;
  }
}

/**
 * get Cadmus' question type based on QTI responses and question type metadata
 * even though question type suggest that it is of type, based on the responses
 * it might require to be mapped to a different type or dropped all together
 * since we don't have a support for it yet.
 **/
function getQuestionType(question?: QTIQuestion) {
  if (!question) return null;
  const questionTypeMetadata = getMetadataQuestionType(question);
  const { responses, contents, respConditions } = question;
  if (
    (questionTypeMetadata === QuestionType.Mcq || !questionTypeMetadata) &&
    responses.length === 1 &&
    responses[0] &&
    responses[0].cardinality === Cardinality.Single &&
    responses[0].type === "lid"
  ) {
    // Check if question is of type mcq based on whether it has
    // a correct answer set
    if (
      responses[0].choiceResponse?.choices.some((c) =>
        getMCQIsCorrect(c.id, respConditions)
      )
    ) {
      return QuestionType.Mcq;
    }
  } else if (
    (questionTypeMetadata === QuestionType.Extended || !questionTypeMetadata) &&
    responses.length === 1 &&
    responses[0] &&
    responses[0].cardinality === Cardinality.Single &&
    responses[0].type === "str"
  ) {
    // Check if text type answer has a correct answer marked
    // if it is then treat it as Short question type otherwise
    // extended
    return respConditions?.some(
      (r) => r.conditions?.findIndex(({ condition }) => condition?.value) !== -1
    )
      ? QuestionType.Short
      : QuestionType.Extended;
  } else if (questionTypeMetadata === QuestionType.Short) {
    return QuestionType.Short;
  } else if (questionTypeMetadata === QuestionType.Truefalse) {
    return QuestionType.Truefalse;
  } else if (
    responses.length === 1 &&
    responses[0] &&
    responses[0].cardinality === Cardinality.Single &&
    Array.isArray(responses[0].choiceResponse?.choices) &&
    responses[0].choiceResponse.choices?.length === 2 &&
    responses[0].choiceResponse.choices.every((choice) =>
      choice.contents.some((c) => /true|false/gi.test(c.text ?? ""))
    )
  ) {
    return QuestionType.Truefalse;
  } else if (questionTypeMetadata === QuestionType.Blanks) {
    return QuestionType.Blanks;
  } else if (
    responses.length > 1 &&
    !responses?.some((r) => {
      const id = getResponseId(r.id);
      if (!id) return false;
      return contents.some((c) => c.text?.search(id) === -1);
    })
  ) {
    return QuestionType.Blanks;
  }
  return null;
}

/**
 * Get uuid or shorten id(without prefix) of QTI Response
 **/
function getResponseId(id: string) {
  const uuidMatch =
    /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/i.exec(id);
  if (uuidMatch && uuidMatch.length > 1) {
    return uuidMatch[1];
  }
  const responseMatch = /resp(?:onse)?_(.*)/i.exec(id);
  if (responseMatch && responseMatch.length > 1) {
    return responseMatch[1];
  }
  return id;
}

/**
 * Map QTI Question to ParsedQuestion
 **/
export async function convertQTIQuestion(
  question?: QTIQuestion,
  file?: JSZipObject | null,
  zip?: JSZip
): Promise<Omit<ParsedQuestion, "fileOriginId"> | undefined> {
  if (!question) return undefined;
  const { metadata, feedbacks, outcomes, contents, responses } = question;
  const questionId = (metadata.assessment_question_identifierref ??
    uuid()) as unknown as string;
  const points = Object.entries(outcomes).reduce(
    (acc, [_k, outcome]) => {
      const { max } = outcome;
      if (!acc) return max;
      if (!max) return acc;
      return acc + max;
    },
    undefined as number | undefined
  );
  const generalFeedback = feedbacks.find((f) =>
    /^general((?!correct).)*$/.test(f.id ?? "")
  );

  const questionType = getQuestionType(question);

  const patchedContents = contents.map((c) => {
    if (
      /html/i.test(c.textType ?? "") &&
      questionType === QuestionType.Blanks
    ) {
      let updatedText = c.text ?? "";
      responses.forEach((response) => {
        const responseId = getResponseId(response.id);
        if (responseId) {
          updatedText = updatedText.replace(
            `[${responseId}]`,
            `<span data-cadmus-blank="0" id="${responseId}">_____</span>`
          );
        }
      });

      return {
        ...c,
        text: updatedText,
      };
    }
    return c;
  });

  const promptEditor = await getConcatenatedEditor(patchedContents, file, zip);
  const prompt = JSON.stringify(promptEditor?.getJSON() ?? "");
  const shortPrompt = promptEditor?.getText()?.slice?.(0, 50) ?? "";

  const feedback =
    generalFeedback?.contents && generalFeedback.contents.length > 0
      ? await getContentsText(generalFeedback.contents)
      : undefined;

  const response = questionType
    ? await getResponse(question, questionType)
    : undefined;
  return {
    questionId,
    points,
    feedback,
    questionType,
    prompt,
    shortPrompt,
    response,
  };
}

/**
 * Map QTI responses and respConditions to
 * MultiResponse, BooleanResponse, EditorResponse, BlanksResponse or ShortResponse
 **/
async function getResponse(
  question: QTIQuestion,
  questionType: ImportableQuestionType
) {
  if (!questionType) return;
  switch (questionType) {
    case QuestionType.Mcq: {
      const { responses, respConditions } = question;
      const response = responses[0];
      if (!response?.choiceResponse) return;
      const choices = await getMCQChoices(
        response.choiceResponse.choices,
        respConditions
      );
      if (!choices) return;
      return {
        __typename: "MultiResponse",
        choices,
        partialScoring: true,
        cardinality: response.cardinality,
      } satisfies MultiResponse;
    }
    case QuestionType.Truefalse: {
      return {
        __typename: "BooleanResponse",
        isTrue: (await getTFIsTrue(question)) ?? false,
      } satisfies BooleanResponse;
    }
    case QuestionType.Extended: {
      return {
        __typename: "EditorResponse",
        wordLimit: question.responses[0]?.wordLimit ?? null,
      } satisfies EditorResponse;
    }
    case QuestionType.Blanks: {
      return {
        __typename: "BlanksResponse",
        matchBlanks: (
          await Promise.all(
            question.responses.map(async (r) => {
              const responseId = getResponseId(r.id);
              if (!responseId) return [] as Blank[];
              return [
                {
                  id: responseId,
                  phrases: await Promise.all(
                    (r.choiceResponse?.choices ?? []).map(
                      async (c) => (await getContentsText(c.contents)) ?? ""
                    )
                  ),
                } satisfies Blank,
              ];
            })
          )
        ).flat(),
        matchSimilarity: 1,
        caseSensitive: false,
      } satisfies BlanksResponse;
    }
    case QuestionType.Short: {
      const { respConditions } = question;
      if (respConditions.length > 1 || respConditions.length === 0) return;
      return {
        __typename: "ShortResponse",
        matchSimilarity: 1,
        matchPhrases:
          respConditions[0]?.conditions?.map((c) => c.condition?.value ?? "") ??
          [],
        caseSensitive: false,
      } satisfies ShortResponse;
    }
  }
}

async function getTFIsTrue(question: QTIQuestion) {
  const { responses, respConditions } = question;
  const response = responses[0];
  if (!response?.choiceResponse) return;
  const correctAnswerCondition = respConditions.find(
    (r) =>
      r.vars.findIndex(
        (v) => v.value === `${v.name}.MAX` || parseFloat(v.value) > 0
      ) !== -1
  );
  if (!correctAnswerCondition?.conditions) return;
  const correctAnswer = correctAnswerCondition.conditions.find((c) => {
    return c.condition && /(true|false)/gi.test(c.condition.value);
  });
  if (!correctAnswer) return;
  const correctChoice = response.choiceResponse.choices.find(
    ({ id }) => correctAnswer.condition && id === correctAnswer.condition.value
  );
  if (!correctChoice) return;
  const correctChoiceText = await getContentsText(correctChoice.contents);
  return (
    (/(true|false)$/gi.test(correctChoiceText) &&
      /true$/gi.test(correctChoiceText)) ||
    correctChoiceText.includes("t")
  );
}

async function getMCQChoices(
  rawChoices: QTIResponseContent[],
  respConditions: QTIResponseCondition[]
) {
  return (
    await Promise.all(
      rawChoices.map(async (c) => {
        const id = getResponseId(c.id);
        if (!id) return [] as Choice[];
        return [
          {
            id,
            text: await getContentsText(c.contents),
            isCorrect: getMCQIsCorrect(id, respConditions),
          } satisfies Choice,
        ];
      })
    )
  ).flat();
}

function getMCQIsCorrect(
  choiceId: string,
  respConditions: QTIResponseCondition[]
) {
  if (respConditions.length === 1) {
    return (
      respConditions[0]?.conditions?.findIndex(
        ({ condition }) => condition?.value === choiceId
      ) !== -1
    );
  } else {
    const positiveScores = respConditions.filter(({ vars }) => {
      return vars.find(({ value, action }) => {
        const maybeNumberValue = parseFloat(value);
        return (
          action &&
          /set/gi.test(action) &&
          ((!isNaN(maybeNumberValue) && maybeNumberValue > 0.0) ||
            /max/gi.test(value))
        );
      });
    });
    if (!positiveScores) return false;
    return positiveScores.every(
      ({ conditions }) =>
        conditions?.findIndex(
          ({ condition }) =>
            condition?.value === choiceId || condition?.id === choiceId
        ) !== -1
    );
  }
}

/** get concatenated text of all QTI's materials' contents */
async function getContentsText(contents: QTIMaterial[]): Promise<string> {
  const editor = await getConcatenatedEditor(contents);
  return editor?.getText() ?? "";
}

/** concatenate all QTI's materials' contents to editor content*/
async function getConcatenatedEditor(
  contents: QTIMaterial[],
  file?: JSZipObject | null,
  zip?: JSZip
) {
  let editor: Editor | undefined;
  for (const content of contents) {
    const { text, textType } = content;
    if (!text) continue;
    let patchedText = text;
    if (textType && /html/i.test(textType)) {
      const patchedHTML = await patchHTML(text, file, zip);
      patchedText = patchedHTML ?? patchedText;
    }
    if (!editor) {
      editor = createTaskBlockPreviewEditor({
        id: "qtiParserEditor",
        promptDoc: patchedText,
        enableBlanks: true,
      });
    } else {
      editor.commands.insertContent(`\n${patchedText}`);
    }
  }
  return editor;
}

/** get file from a zip file as a base64 string */
function getBase64File(file?: JSZipObject | null, zip?: JSZip) {
  return async (src: string): Promise<Base64File | null> => {
    if (!file || !zip) return null;
    const lastSlashIndex = file.name.lastIndexOf("/");
    const path = file.name.substring(0, lastSlashIndex + 1);
    const getUrl = window.location;
    const baseUrl =
      getUrl.origin + getUrl.pathname.split("/").slice(0, -1).join("/");
    const imgSrc = src.replace(baseUrl, "");
    const srcRegex = /(\/\$IMS-CC-FILEBASE\$\/?([^"]+))|(.*)/;
    const match = srcRegex.exec(imgSrc);
    let base64String;
    let extension;
    let filename;
    if (match?.[2]) {
      filename = decodeURI(match[2]);
      base64String = await zip?.file(new RegExp(filename))[0]?.async("base64");
      extension = await zip
        ?.file(new RegExp(filename))[0]
        ?.async("uint8array")
        .then((a) => filetype(a)?.[0]?.extension);
    } else if (match?.length === 1 && match[0]) {
      filename = decodeURI(match[0]);
      base64String = await zip?.file(path + filename)?.async("base64");
      extension = await zip
        ?.file(path + match[0])
        ?.async("uint8array")
        .then((a) => filetype(a)?.[0]?.extension);
    }
    if (base64String && extension && filename) {
      return {
        base64String,
        extension,
        filename,
      };
    }
    return null;
  };
}

async function patchHTML(str: string, file?: JSZipObject | null, zip?: JSZip) {
  return await basePatchHTML(str, getBase64File(file, zip));
}
