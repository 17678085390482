import { useCallback, useMemo, useState } from "react";
import { styled } from "styled-components";

import {
  Button,
  Colored,
  Desk,
  LinkButton,
  SectionLabel,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import moment, { Moment } from "moment";
import {
  dateColors,
  DateTimeGrid,
  DeprecatedDatePicker,
  Footer,
  getHighlightDays,
  GridDate,
  GridLead,
  GridTime,
  GridTitle,
  HeadingGrid,
  isDateInvalid,
  isOutsideRangePredicate,
  Legend,
  TimePicker,
  useJumpToToday,
} from "ui/DatePicker";

import { StudentsSettingsPageProps } from "./types";
import { useStudentExtensionGroup } from "./useStudentExtensionGroup";
import { useUpdateStudentSettings } from "./useUpdateStudentSettings";

/**
 * Student's due date extension page
 */
export function StudentsDueExtensionPage({
  assessmentId,
  onClose,
  enrollments,
  dueDate,
  returnDate,
  groups,
  selectedAll,
}: StudentsSettingsPageProps) {
  const group = useStudentExtensionGroup(enrollments, groups);
  const dueDates = enrollments
    .map(({ workSettings }) => workSettings?.dueDate)
    .filter((x) => x) as string[];
  const uniqueDueDates = Array.from(new Set(dueDates));
  const commonExtension =
    dueDates.length === enrollments.length && uniqueDueDates.length === 1
      ? moment(uniqueDueDates[0])
      : null;
  const [newDate, setNewDate] = useState<Moment | null>(commonExtension);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const setValidatedDate = useCallback(
    (d: Moment | null) => {
      setNewDate(d);

      // validations - from now and dueDate onwards
      d &&
        validateWorkDueDate(
          new Date(dueDate),
          d.toDate(),
          (e) => setErrorMessage(e),
          () => setErrorMessage(undefined)
        );
    },
    [dueDate, setNewDate]
  );

  const { updateDueDates } = useUpdateStudentSettings(assessmentId, onClose);

  const highlightDays = useMemo(
    () =>
      getHighlightDays({
        dueDate,
        returnDate,
      }),
    [dueDate, returnDate]
  );

  const [todayKey, jumpToToday] = useJumpToToday();

  // blocked dates - from now / dueDate onwards
  const isOutsideRange = useMemo(
    () => isOutsideRangePredicate([new Date(dueDate)], []),
    [dueDate]
  );
  const calendarInfo = useMemo(
    () => (
      <>
        <Text kind="headingSix" as="span">
          Quick Options
        </Text>
        <Spacer spacing={9} />
        <>
          <StyledLinkButton marginRight={20} onClick={() => jumpToToday()}>
            Jump to this month
          </StyledLinkButton>
          <StyledLinkButton
            marginRight={20}
            onClick={() => setValidatedDate(moment(dueDate).add(1, "w"))}
          >
            1 week <em>after</em> final due
          </StyledLinkButton>
          <StyledLinkButton
            onClick={() => setValidatedDate(moment(dueDate).add(2, "w"))}
          >
            2 weeks <em>after</em> final due
          </StyledLinkButton>
        </>
      </>
    ),
    [dueDate, setValidatedDate, jumpToToday]
  );

  const [headerPrefix, headerText, headerSummary] = useMemo(() => {
    const isWholeClass = enrollments.length !== 1 && selectedAll;
    let prefix, titleText, summary;
    if (isWholeClass) {
      titleText = "the whole class";
      summary = (
        <>
          {" "}
          <strong>everyone in the class</strong>{" "}
        </>
      );
      prefix = "They ";
    } else if (group) {
      titleText = `${group.name}'s marking group (${group.members.length} students)`;
      summary = (
        <>
          {" "}
          <strong>{`everyone in ${group.name}'s marking group`}</strong>{" "}
        </>
      );
      prefix = "They ";
    } else if (enrollments.length === 1) {
      const name = enrollments[0]!.user.name;
      titleText = name;
      summary = (
        <>
          <strong>{name}</strong>{" "}
        </>
      );
      prefix = `${name} `;
    } else {
      const names = enrollments.map(({ user }) => user.name);
      const last = names.pop();
      titleText = `${enrollments.length} students`;
      if (names.length <= 50) {
        summary = (
          <>
            {" "}
            <strong>{names.join(", ")}</strong> and <strong>{last}</strong>{" "}
          </>
        );
      }
      prefix = "They ";
    }
    return [prefix, titleText, summary];
  }, [group, enrollments, selectedAll]);

  return (
    <Desk.Layout>
      <Desk.Table>
        <Spacer spacing={90} />
        <HeadingGrid>
          <GridTitle kind="displayTwo">
            <Colored color={dateColors.extension}>Extend due date</Colored> for{" "}
            {headerText}
          </GridTitle>
          <GridLead kind="lead" color="shade1">
            The final due date for {headerSummary}will be extended in Cadmus.{" "}
            {headerPrefix} can continue to work and resubmit until this date.
          </GridLead>
        </HeadingGrid>
        <Spacer spacing={45} />
        <DateTimeGrid>
          <GridDate>
            <SectionLabel title="Select a date" />
            <DeprecatedDatePicker
              date={newDate}
              setDate={setValidatedDate}
              selectedColor={dateColors.extension}
              highlightDays={highlightDays}
              isOutsideRange={isOutsideRange}
              todayKey={todayKey}
            />
            <Spacer spacing={9} />
            {calendarInfo}
            <Spacer spacing={45} />
          </GridDate>
          <GridTime>
            <SectionLabel title="Select a time" />
            <Spacer spacing={18} />
            <TimePicker
              disabled={newDate === null}
              time={newDate}
              setTime={setValidatedDate}
              defaultTime="23:59"
              errorMessage={errorMessage}
            />
            <Legend
              dueDate={new Date(dueDate)}
              returnDate={new Date(returnDate)}
              extension={newDate ? newDate.toDate() : undefined}
            />
          </GridTime>
        </DateTimeGrid>
        <Spacer spacing={90} />
      </Desk.Table>
      <Desk.Drawer>
        <Footer>
          <Button marginRight={18} onClick={onClose}>
            Cancel
          </Button>
          <Button
            kind="primary"
            onClick={() =>
              newDate && updateDueDates(enrollments, newDate.toISOString())
            }
            disabled={newDate === null || errorMessage !== undefined}
            aria-disabled={
              newDate === null || errorMessage !== undefined ? "true" : "false"
            }
            marginRight={18}
          >
            Confirm extension date
          </Button>
        </Footer>
      </Desk.Drawer>
    </Desk.Layout>
  );
}
const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
`;

/**
 * Validate work extension due
 * workDate has to be from now or dueDate onwards.
 */
export const validateWorkDueDate = (
  dueDate: Date,
  workDue: Date,
  onError: (e: string) => void,
  onSucces: () => void
) => {
  if (
    isDateInvalid(workDue, [dueDate], [], () =>
      onError("extension before final due")
    )
  )
    return;

  onSucces();
};
