import { AnyNode, ChildNode, Element } from "domhandler";
import { findOne } from "domutils";

export interface Base64File {
  filename: string;
  extension: string;
  base64String: string;
}

/**
 * Patch imported HTML to fit into the editor by doing these steps:
 * - Parse file and inline it as a base64 string
 * - Put width into table's cell
 **/
export async function patchHTML(
  str: string,
  getBase64File: (src: string) => Promise<Base64File | null>
) {
  const dom = new DOMParser().parseFromString(str, "text/html");
  const images = dom?.querySelectorAll("img");
  for await (const img of images) {
    if (!img.src || /^data/.test(img.src)) return;
    const base64File = await getBase64File(img.src);
    if (base64File) {
      const { base64String, extension, filename } = base64File;
      img.src = `data:image/${extension};base64;filename:${filename},${base64String}`;
    }
    const parent = img.parentElement;
    // make sure that image is a block image instead of inline as
    // only block image is supported
    if (parent && parent.tagName.toLowerCase() !== "figure") {
      const figure = document.createElement("figure");
      figure.appendChild(img);
      if (parent.nextSibling && parent.parentNode) {
        parent.parentNode.insertBefore(figure, parent.nextSibling);
      } else if (parent.parentNode) {
        parent.parentNode.appendChild(figure);
      } else {
        dom?.appendChild(figure);
      }
    }
  }
  // Update cell width of tables
  const tables = dom?.querySelectorAll("table");
  tables?.forEach((table) => {
    const trs = table.querySelectorAll("tr");
    trs?.forEach((tr) => {
      const cols = tr.querySelectorAll("td,th");
      if (cols) {
        const colNum =
          Array.from(cols).reduce((acc, col) => {
            const colSpan = col.getAttribute("colSpan") ?? "1";
            return acc + parseInt(colSpan, 10);
          }, 0) ?? 1;
        cols.forEach((col) => {
          const colSpan = col.getAttribute("colSpan") ?? "1";
          const colWidth = (800 / colNum) * parseInt(colSpan, 10);
          col.setAttribute("colwidth", `${colWidth}`);
        });
      }
    });
  });
  return dom?.documentElement.outerHTML;
}

/** Custom domutils helper to recursively go through tagNames from the rootNode */
export function getElementByPath(node: AnyNode[], tagNames: string[]) {
  let result: { currentNodes: ChildNode[]; element: Element | null } = {
    currentNodes: node,
    element: null,
  };
  tagNames.every((tagName, index) => {
    const element = findOne(
      (e) => e.tagName === tagName,
      result.currentNodes,
      false
    );
    if (!element) return false;
    result = {
      currentNodes: element.childNodes,
      element: index === tagNames.length - 1 ? element : null,
    };
    return true;
  });
  return result.element;
}
