import { styled } from "styled-components";

import {
  colors,
  desktop,
  DropdownMenu,
  Input,
  SelectButton,
  Text,
} from "@vericus/cadmus-ui";

import {
  DatePickerInput,
  DatePickerValue,
  DatesRangeValue,
} from "@mantine/dates";

import { useAppDispatch, useAppSelector } from "@/data/hooks";
import {
  IPastAssessmentFilters,
  reduxDateDeserialiser,
  SerializedDateRangePickerValue,
  updatePastAssessmentsFilter,
} from "@/data/templates";
import { selectPastAssessmentFilters } from "@/data/templates/selectors";
import { AssessmentType, SubjectFragment } from "@/generated/graphql";

export interface PastAssessmentsFiltersProps {
  /**
   * Whether the exams feature is enabled.
   */
  areExamsEnabled: boolean;
  /**
   * The subjects that the user is able to select for filtering
   */
  subjects: SubjectFragment[];
}

/**
 * A set of filters for the Past Assessments component.
 */
export const PastAssessmentsFilters = ({
  areExamsEnabled,
  subjects,
}: PastAssessmentsFiltersProps) => {
  const pastAssessmentFilters = useAppSelector(selectPastAssessmentFilters);
  const dispatch = useAppDispatch();

  const [startDateValue, endDateValue] = pastAssessmentFilters.dueDateRange ?? [
    null,
    null,
  ];

  const datePickerRangeValue: DatePickerValue<"range"> = [
    startDateValue ? reduxDateDeserialiser(startDateValue) : null,
    endDateValue ? reduxDateDeserialiser(endDateValue) : null,
  ];

  return (
    <FilterContainer>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <StyledSelectButton>
            {pastAssessmentFilters.subject?.name ?? "Subject"}
          </StyledSelectButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          {subjects.map((subject) => (
            <StyledMenuItem
              key={subject.id}
              onClick={() => {
                dispatch(
                  updatePastAssessmentsFilter({
                    ...pastAssessmentFilters,
                    subject: subject,
                  })
                );
              }}
            >
              <Text
                key={`${subject.id}-child`}
                kind="systemSm"
                textAlign={"left"}
              >
                {subject.name}
              </Text>
            </StyledMenuItem>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
      {areExamsEnabled && (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <StyledSelectButton>
              {assessmentTypeSelectorOptions.find(
                (option) =>
                  option.value === pastAssessmentFilters.assessmentType
              )?.display ?? "Type"}
            </StyledSelectButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {assessmentTypeSelectorOptions.map((option) => (
              <StyledMenuItem
                key={option.display}
                onSelect={() => {
                  dispatch(
                    updatePastAssessmentsFilter({
                      ...pastAssessmentFilters,
                      assessmentType: option.value,
                    })
                  );
                }}
              >
                <Text kind="systemSm" textAlign={"left"}>
                  {option.display}
                </Text>
              </StyledMenuItem>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      )}

      <DatePickerInput
        data-testid={"dueDateRangePicker"}
        type="range"
        allowSingleDateInRange
        placeholder={
          pastAssessmentFilters.assessmentType === AssessmentType.Exam
            ? "Exam date range"
            : "Due date range"
        }
        value={datePickerRangeValue}
        onChange={(selectedValues: DatesRangeValue) => {
          dispatch(
            updatePastAssessmentsFilter({
              ...pastAssessmentFilters,
              dueDateRange: selectedValues.map((selectedValue) =>
                selectedValue ? selectedValue.getTime() : null
              ) as SerializedDateRangePickerValue,
            })
          );
        }}
        styles={{
          placeholder: {
            /**
             * For some reason, #adb5bd was overriding the colour of the
             * placeholder text
             */
            color: `${colors.grey500} !important`,
          },
        }}
        style={{
          whiteSpace: "nowrap",
        }}
      />
      <StyledInput
        iconName="Search"
        placeholder="Assessment name"
        data-testid="filterNameSearchInput"
        value={pastAssessmentFilters.assessmentNameQuery || ""}
        onChange={(event) => {
          const value = event.target.value;
          dispatch(
            updatePastAssessmentsFilter({
              ...pastAssessmentFilters,
              assessmentNameQuery: value,
            })
          );
        }}
      />
    </FilterContainer>
  );
};

type AssessmentTypeOption = {
  display: string;
  value: Exclude<IPastAssessmentFilters["assessmentType"], undefined>;
};

const assessmentTypeSelectorOptions = [
  { display: "All", value: "all" },
  { display: "Assignments", value: AssessmentType.Assignment },
  { display: "Exams", value: AssessmentType.Exam },
] satisfies AssessmentTypeOption[];

const FilterContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  & > * {
    flex-basis: 106px;
    flex-grow: 1;
  }

  margin-bottom: 24px;
  ${desktop`
    margin-bottom: 0;
  `}
`;

const StyledInput = styled(Input)`
  flex-basis: 214px;
  width: 100%;
  font-size: 14px;

  & > input {
    width: 100%;
    line-height: unset;

    &:focus {
      box-shadow: none;
      border-color: #228be6;
    }
  }
`;

const StyledMenuItem = styled(DropdownMenu.Item)`
  padding: 7px 16px;
`;

const StyledSelectButton = styled(SelectButton)`
  min-width: 106px;
  width: 100%;
  flex-grow: 1;
`;
