import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "@/data/hooks";
import {
  applyQuestionFilters,
  selectQuestionSortingState,
  setSorting,
} from "@/features/moderation/moderate-slice";
import { QuestionColumnId, ScoredQuestion } from "@/features/moderation/types";
import { QuestionType } from "@/generated/graphql";

import { HeaderButton, SortingHeaderButton } from "./header-button";
import * as styles from "./question-list.css";

/**
 * Renders the header for the question list with sortable columns.
 */
export function QuestionListHeader(props: { questions: ScoredQuestion[] }) {
  const dispatch = useAppDispatch();

  const QuestionSortingState = useAppSelector(selectQuestionSortingState);

  const handleSort = (column: QuestionColumnId) => {
    const newOrder =
      QuestionSortingState.column === column &&
      QuestionSortingState.order === "asc"
        ? "desc"
        : "asc";
    dispatch(setSorting({ column, order: newOrder }));

    const questionIdsWithoutSection = props.questions.flatMap((q) =>
      q.questionType === QuestionType.Section ? [] : [q.questionId]
    );
    dispatch(applyQuestionFilters(questionIdsWithoutSection));
  };

  const renderSortingHeaderButton = (
    columnId: QuestionColumnId,
    className: string,
    style?: React.CSSProperties
  ) => (
    <SortingHeaderButton
      className={className}
      style={style}
      onSort={() => handleSort(columnId)}
      sorted={
        QuestionSortingState.column === columnId
          ? QuestionSortingState.order
          : null
      }
    >
      {columnId}
    </SortingHeaderButton>
  );

  return (
    <div className={styles.row}>
      {renderSortingHeaderButton(QuestionColumnId.Order, styles.column.order)}
      {renderSortingHeaderButton(QuestionColumnId.Type, styles.column.small)}
      <HeaderButton className={styles.column.question}>
        {QuestionColumnId.Question}
      </HeaderButton>
      {/* empty cell to keep alignments of columns */}
      <EmptyCell className={styles.column.small} />
      {renderSortingHeaderButton(
        QuestionColumnId.ResponseRate,
        styles.column.small
      )}
      {renderSortingHeaderButton(
        QuestionColumnId.SuccessRate,
        styles.column.medium,
        {
          marginRight: "10px", // margin for the last column in the header to keep alignments
        }
      )}
    </div>
  );
}

/**
 * Used as a placeholder for the cell in the header that doesn't have a value.
 */
export const EmptyCell = styled.div`
  height: 30px;
`;
