import { styled } from "styled-components";

import { formatPoints } from "@vericus/cadmus-common";
import { InfoUnfilledIcon } from "@vericus/cadmus-icons";
import { colors, Divider, Pill, Text, Tooltip } from "@vericus/cadmus-ui";

import { formatDecimalNumber } from "../../utils";
import * as styles from "./summary.css";

export interface MarkingSummaryProps {
  /** No. of questions that is automarked */
  autoMarkedTotalQuestionsCount: number;

  /** Maximum total points that can be scored in automarked questions */
  autoMarkedMaxScore: number;

  /** Adjusted points for automarked questions */
  automarkedAdjustedScore?: number;

  /** Total points scored in automarked questions */
  automarkedScore: number;

  /** No. of questions that can be Manually Marked */
  totalManuallyMarkedQuestionsCount: number;

  /** Maximum total points that can be scored in manually marked questions */
  manuallyMarkedMaxScore: number;

  /** No. of questions that are yet to be marked */
  manuallyYetToBeMarkedQuestions: number;

  /** Total points scored in manually marked questions */
  manuallyMarkedScore: number;

  /** Total number of questions */
  totalQuestionsCount: number;

  /** Points added during moderation */
  moderatorModifier: number | null;

  /** Total score */
  totalScore: number;

  /** Maximum total score */
  maxScore: number;
}

/**
 * Summary Component for Marking Page which contains the following:
 *
 * - Auto-marked questions summary - Total questions, Maximum total points, Adjusted points, Score
 *
 * - Manually marked questions summary - Total questions, Maximum total points, Yet to be marked, Score
 *
 * - Total score summary - Total score, Maximum total score, Points added during moderation
 */
export function MarkingSummary(props: MarkingSummaryProps) {
  return (
    <div id="mark-summary" className={styles.container}>
      <div className={styles.containerRow}>
        <AutoMarkedSummary
          autoMarkedTotalQuestionsCount={props.autoMarkedTotalQuestionsCount}
          autoMarkedMaxScore={props.autoMarkedMaxScore}
          automarkedAdjustedScore={parseFloat(
            formatDecimalNumber(props?.automarkedAdjustedScore ?? 0)
          )}
          automarkedScore={props.automarkedScore}
        />
        <ManualMarkedSummary
          totalManuallyMarkedQuestionsCount={
            props.totalManuallyMarkedQuestionsCount
          }
          manuallyMarkedMaxScore={props.manuallyMarkedMaxScore}
          manuallyYetToBeMarkedQuestions={props.manuallyYetToBeMarkedQuestions}
          manuallyMarkedScore={props.manuallyMarkedScore}
        />
      </div>
      <div>
        <TotalScoreSummary
          moderatorModifier={props.moderatorModifier}
          totalScore={props.totalScore}
          maxScore={props.maxScore}
          totalQuestionsCount={props.totalQuestionsCount}
        />
      </div>
    </div>
  );
}

interface AutoMarkedSummary {
  autoMarkedTotalQuestionsCount: number;
  autoMarkedMaxScore: number;
  automarkedAdjustedScore?: number;
  automarkedScore: number;
}
function AutoMarkedSummary(props: AutoMarkedSummary) {
  return (
    <div className={styles.autoMarkedContainer}>
      <div>
        <Text kind="headingFive" color="navy400">
          Auto-marked questions
        </Text>
        <Divider />
        <Row
          label="No. of questions"
          value={props.autoMarkedTotalQuestionsCount}
        />
        <Row label="Maximum total points" value={props.autoMarkedMaxScore} />
      </div>

      <div>
        {props.automarkedAdjustedScore !== undefined &&
          props.automarkedAdjustedScore !== 0 && (
            <AdjustedPoints
              label="Adjusted points"
              value={props.automarkedAdjustedScore}
            />
          )}
        <SummaryScoreRow
          scoreTitle="Score"
          totalPts={`${formatDecimalNumber(props.automarkedScore)}/${props.autoMarkedMaxScore}`}
        />
      </div>
    </div>
  );
}
interface ManualMarkedSummary {
  totalManuallyMarkedQuestionsCount: number;
  manuallyMarkedMaxScore: number;
  manuallyYetToBeMarkedQuestions: number;
  manuallyMarkedScore: number;
}

function ManualMarkedSummary(props: ManualMarkedSummary) {
  return (
    <div className={styles.autoMarkedContainer}>
      <div>
        <Text kind="headingFive" color="navy400">
          Manually marked questions
        </Text>
        <Divider />
        <Row
          label="No. of questions"
          value={props.totalManuallyMarkedQuestionsCount}
        />
        <Row
          label="Maximum total points"
          value={props.manuallyMarkedMaxScore}
        />
      </div>

      <div>
        <Row
          label="Yet to be marked"
          value={props.manuallyYetToBeMarkedQuestions}
        />
        <SummaryScoreRow
          scoreTitle="Score"
          totalPts={formatDecimalNumber(props.manuallyMarkedScore)}
        />
      </div>
    </div>
  );
}

interface TotalScoreSummaryProps {
  moderatorModifier: number | null;
  totalScore: number;
  maxScore: number;
  totalQuestionsCount: number;
}

function TotalScoreSummary(props: TotalScoreSummaryProps) {
  const { moderatorModifier, totalScore, maxScore, totalQuestionsCount } =
    props;

  const score = moderatorModifier ? totalScore + moderatorModifier : totalScore;
  const points = moderatorModifier
    ? formatPoints(moderatorModifier, true)
    : null;

  return (
    <div className={styles.totalScoreContainer}>
      <div>
        <StyledHeading kind="headingThree">
          Total Score <br />
          <Text kind="bodyMd" color="navy400" as="span">
            from all {totalQuestionsCount} questions
          </Text>
        </StyledHeading>
        <Text kind="bodySm" color="navy400">
          Auto-marked + Adjusted points <br />+ Manually marked
        </Text>
      </div>
      <div>
        {points && (
          <div className={styles.row}>
            <Text kind="systemMd" as="span">
              Via moderation
            </Text>
            <InfoTooltip content="Points added during moderation reflect grade normalisation decisions taken for the entire class." />
            <PointsPill points={points} />
          </div>
        )}
        <StyledDivider />
        <Text kind="headingThree" bold>
          {`${formatDecimalNumber(score)}/${maxScore}`} pts
        </Text>
      </div>
    </div>
  );
}

interface SummaryScoreRowProps {
  scoreTitle: string;
  totalPts: number | string;
}
function SummaryScoreRow({ scoreTitle, totalPts }: SummaryScoreRowProps) {
  return (
    <>
      <StyledDivider />
      <div className={styles.row}>
        <Text kind="headingFive">{scoreTitle}</Text>
        <Text kind="headingFive" as="span" bold>
          {totalPts} pts
        </Text>
      </div>
    </>
  );
}

function Row(props: { label: string; value: number | string }) {
  return (
    <RowText kind="bodyMd" as="div">
      {props.label}
      <Text kind="bodyMd" as="span" bold>
        {props.value}
      </Text>
    </RowText>
  );
}

function AdjustedPoints(props: { label: string; value: number | string }) {
  return (
    <div className={styles.row}>
      <AdjustedPointsText kind="bodyMd" as="div" color="navy400">
        {props.label}
        <InfoTooltip content="Adjusted points are calculated based on the total points and the number of questions." />
      </AdjustedPointsText>
      <PointsPill points={props.value} />
    </div>
  );
}

function PointsPill({ points }: { points: number | string }) {
  return (
    <Pill color={colors.poppy200} basic={true}>
      <Text kind="bodyMd" as="span" bold>
        {points}
      </Text>
    </Pill>
  );
}

function InfoTooltip({ content }: { content: string }) {
  return (
    <Tooltip
      size="sm"
      side="right"
      content={<Text kind="systemSm">{content}</Text>}
    >
      <InfoUnfilledIcon label="" size={20} />
    </Tooltip>
  );
}

const RowText = styled(Text)`
  display: flex;
  justify-content: space-between;
  margin: 2px 0;
  color: ${colors.navy400};
`;

const AdjustedPointsText = styled(Text)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledDivider = styled(Divider)`
  background-color: ${colors.navy400};
  margin-top: 8px;
`;

const StyledHeading = styled(Text)`
  line-height: 1;
`;
