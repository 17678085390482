import { styled } from "styled-components";

import {
  CadmusEditorContent,
  Editor,
} from "@vericus/cadmus-editor-prosemirror";
import { Colored, Divider, Switch, Text } from "@vericus/cadmus-ui";

import { EditorWrapper } from "../../components/editor-wrapper";
import { Dispatch } from "../state";
import { BodyDiv } from "../styles";

interface OverviewQuestionProps {
  editor: Editor | null;
  dispatch: Dispatch;
  hidden: boolean;
  /** Error when question promptDoc less than 20 characters */
  promptDocTooShort?: boolean;
}

/**
 * Overview question question block
 */
export const OverviewQuestionBody = ({
  editor,
  dispatch,
  hidden,
  promptDocTooShort,
}: OverviewQuestionProps) => (
  <Root>
    <Content>
      <TitleContainer>
        <Text kind="headingFive" as="span">
          Assessment overview
          {!hidden && <Colored color="neutralgrey500"> (optional)</Colored>}
        </Text>
        <Switch
          checked={!hidden}
          onChange={() => dispatch({ type: "ToggleHideQuestion" })}
          content={"Enabled"}
          labelPosition="left"
        />
      </TitleContainer>
      <Divider />
      {hidden && (
        <Text kind="bodyMd" color="neutralgrey500">
          Students will only see the Requirements before beginning the questions{" "}
        </Text>
      )}
    </Content>
    {!hidden && (
      <EditorWrapper hasError={promptDocTooShort} isOverview>
        <CadmusEditorContent editor={editor} />
      </EditorWrapper>
    )}
  </Root>
);

const Content = styled.div`
  display: flex;
  padding: 0px 45px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  gap: 16px;
`;

const Root = styled(BodyDiv)`
  position: relative;
  padding: 24px 0px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background: ${(p) => p.theme.colors.white100};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
