import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { EnrollmentFragment, FeedbackLevel } from "@/generated/graphql";
import { useRootNavigate } from "@/router/routing";

import { useFeedbackVisibilityFragment } from "../../graphql/selectors";
import { FeedbackReleaseRuleState } from "../../types";
import { getEnrollmentIdsReleaseState } from "../../utils";
import { FeedbackReleaseConfirmModal } from "./feedback-release-confirm-modal";

interface Props {
  assessmentId: string;
  feedbackReleaseRuleStates: FeedbackReleaseRuleState[];
  enrollments: EnrollmentFragment[];
}

/** Feedback release confirm modal connected to redux and graphql */
export const FeedbackReleaseConfirmModalWithData = NiceModal.create<Props>(
  (props) => {
    const modal = useModal();

    const navigate = useRootNavigate();
    const feedbackVisibility = useFeedbackVisibilityFragment(
      props.assessmentId
    );

    const { enrollments = [], feedbackReleaseRuleStates = [] } = props;
    const {
      remainingEnrollmentIds,
      releasedEnrollmentIds,
      scheduledEnrollmentIds,
    } = getEnrollmentIdsReleaseState(feedbackReleaseRuleStates, true);

    const excludedStudents = enrollments.flatMap((enrollment) => {
      if (remainingEnrollmentIds.includes(enrollment.id)) {
        return enrollment.user;
      }
      return [];
    });

    return (
      <FeedbackReleaseConfirmModal
        open={modal.visible}
        onOpenChange={(open) => (open ? modal.show() : modal.hide())}
        releasedStudentsCount={releasedEnrollmentIds.length}
        scheduledStudentsCount={scheduledEnrollmentIds.length}
        withholdStudentsCount={remainingEnrollmentIds.length}
        excludedStudents={excludedStudents}
        visibility={
          feedbackVisibility.data.feedbackViewLevel ?? FeedbackLevel.Answer
        }
        onConfirmReleaseMore={() => {
          navigate("/grader/moderate/feedback");
          modal.hide();
        }}
        onGoToDashbord={() => {
          navigate("/grader/moderate/scores");
          modal.hide();
        }}
      />
    );
  }
);
