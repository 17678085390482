import { FC, useCallback } from "react";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useJitsu } from "@jitsu/react";
import { Userpilot } from "userpilot";

import { JitsuEvent } from "@/data/events/JitsuEvent";

import {
  ConnectedContentReplaceModal,
  ConnectedContentReplaceModalProps,
} from "./ContentReplaceModal";
import {
  ConnectedPastAssessmentPreviewModal,
  ConnectedPastAssessmentPreviewModalProps,
} from "./Preview/PastAssessmentPreviewModal";
import {
  ConnectedTemplatePreviewModal,
  ConnectedTemplatePreviewModalProps,
} from "./Preview/TemplatePreviewModal";
import {
  ConnectedTemplatesModal,
  ConnectedTemplatesModalProps,
} from "./TemplatesModal";
import { useRemoveSearchParams } from "@/router/routing";

export enum TemplatesModalId {
  Templates = "templates/TemplatesModal",
  EditorContentReplacement = "templates/ContentReplaceModal",
  TemplatePreview = "templates/TemplatePreviewModal",
  PastAssessmentPreview = "templates/PastAssessmentPreviewModal",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const registerTemplateModal = (modalId: TemplatesModalId, component: FC<any>) =>
  NiceModal.register(modalId, component);

/**
 * Register template modals with the modal state management system.
 */
export const registerTemplatesModals = () => {
  registerTemplateModal(TemplatesModalId.Templates, ConnectedTemplatesModal);
  registerTemplateModal(
    TemplatesModalId.EditorContentReplacement,
    ConnectedContentReplaceModal
  );
  registerTemplateModal(
    TemplatesModalId.TemplatePreview,
    ConnectedTemplatePreviewModal
  );
  registerTemplateModal(
    TemplatesModalId.PastAssessmentPreview,
    ConnectedPastAssessmentPreviewModal
  );
};

// TODO: move this further up the app hierarchy once other parts of the app start registering their IDs
const showAppModal = <PropsType>(
  modalId: TemplatesModalId,
  props?: PropsType
) => {
  Userpilot.end();
  return NiceModal.show(modalId, props);
};

// modal hooks
export const useAppModal = (modalId: TemplatesModalId) => useModal(modalId);

// Show modal hooks. Preferred way to show modals as they more strongly type modal component props.
export const useShowTemplatesModal = () => {
  const { track } = useJitsu();
  return useCallback(
    (props: ConnectedTemplatesModalProps) => {
      showAppModal(TemplatesModalId.Templates, props);
      track(JitsuEvent.TEMPLATES_MODAL_OPENED);
    },
    [track]
  );
};
export const useShowEditorContentReplacementModal = () =>
  useCallback((props: ConnectedContentReplaceModalProps) => {
    showAppModal(TemplatesModalId.EditorContentReplacement, props);
  }, []);
export const useShowPastAssessmentPreviewModal = () =>
  useCallback((props: ConnectedPastAssessmentPreviewModalProps) => {
    showAppModal(TemplatesModalId.PastAssessmentPreview, props);
  }, []);
export const useShowTemplatePreviewModal = () =>
  useCallback((props: ConnectedTemplatePreviewModalProps) => {
    showAppModal(TemplatesModalId.TemplatePreview, props);
  }, []);


/**
 * Close all template related modals.
 */
export const useCloseAllTemplatesModals = () => {
  const removeSearchParams = useRemoveSearchParams();

  const editorContentReplacementModal = useAppModal(
    TemplatesModalId.EditorContentReplacement
  );
  const templatesModal = useAppModal(TemplatesModalId.Templates);
  const templatePreviewModal = useAppModal(TemplatesModalId.TemplatePreview);
  const pastAssessmentPreviewModal = useAppModal(
    TemplatesModalId.PastAssessmentPreview
  );

  return useCallback(() => {
    removeSearchParams(["opentemplates", "createnew", "subcategoryslug"]);

    editorContentReplacementModal.remove();
    templatesModal.remove();
    templatePreviewModal.remove();
    pastAssessmentPreviewModal.remove();
  }, [
    editorContentReplacementModal,
    templatesModal,
    templatePreviewModal,
    pastAssessmentPreviewModal,
    removeSearchParams,
  ]);
};
