import { ReactNode } from "react";
import { styled } from "styled-components";

import { Alert, Button, colors, Modal, Spacer, Text } from "@vericus/cadmus-ui";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

export interface MidExamUpdateWarningModalProps {
  useCase: keyof typeof useCases;
  onPositiveAction: VoidFunction;
  onNegativeAction: VoidFunction;
}

export const ConnectedMidExamUpdateWarningModal =
  NiceModal.create<MidExamUpdateWarningModalProps>((props) => {
    const modal = useModal();
    return <MidExamUpdateWarningModal {...props} visible={modal.visible} />;
  });

interface InnerProps extends MidExamUpdateWarningModalProps {
  visible?: boolean;
}

export const MidExamUpdateWarningModal = (props: InnerProps) => {
  const { useCase, onPositiveAction, onNegativeAction, visible } = props;
  const copy = useCases[useCase];
  return (
    <Modal.Root open={visible}>
      <Modal.Content withCloseButton={false}>
        <Container>
          <Text kind="headingFour" textAlign="center">
            {copy.title}
          </Text>
          <Spacer spacing={24} />
          <Alert severity="warning" title={copy.alertTitle}>
            {copy.alertBody}
          </Alert>
          <Spacer spacing={32} />
          <ButtonsContainer>
            <Button onClick={onNegativeAction}>Cancel</Button>
            <Button onClick={onPositiveAction} kind="secondary">
              {copy.positiveAction}
            </Button>
          </ButtonsContainer>
        </Container>
      </Modal.Content>
    </Modal.Root>
  );
};

type Copy = {
  title: ReactNode;
  alertTitle?: ReactNode;
  alertBody: ReactNode;
  positiveAction: ReactNode;
};

export const updateSettingsCopy: Copy = {
  title: (
    <>
      Are you sure you want to make changes
      <br /> while the exam is underway?
    </>
  ),
  alertBody: (
    <>
      Applying these changes may affect students who <br /> are already working
    </>
  ),
  positiveAction: "Update exam settings",
};

export const updateInstructionsCopy: Copy = {
  title: (
    <>
      Are you sure you want to update the
      <br /> instructions while students are working?
    </>
  ),
  alertTitle: (
    <>
      Students will be notified but may not identify <br />
      what has changed
    </>
  ),
  alertBody: (
    <>
      We recommend highlighting any changes <br />
      clearly within the instructions
    </>
  ),
  positiveAction: "Update exam instructions",
};

const useCases = {
  updateSettings: updateSettingsCopy,
  updateInstructions: updateInstructionsCopy,
} satisfies Record<string, Copy>;

const Container = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white100};
  border-radius: 4px;
  padding: 56px 77px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`;
