import { styled, css } from "styled-components";

/**
 * Input component used for number input in multiple-format feature
 * e.g. points, word count input
 */
export const NumberInput = styled.input<{
  readOnly?: boolean;
  hasError?: boolean;
}>`
  padding: 0px 8px;
  box-sizing: border-box;
  text-align: center;
  gap: 16px;
  border-radius: 4px;
  background: ${(p) => p.theme.colors.grey50};
  border: ${(p) =>
    p.hasError ? `1px solid ${p.theme.colors.functionalFail}` : "none"};
  color: ${(p) => p.theme.colors.navy500};

  ${(p) =>
    p.readOnly
      ? css`
          color: ${(p) => p.theme.colors.grey400};
          cursor: unset;
          &:hover,
          &:focus {
            border: none;
            outline: none;
          }
        `
      : css`
          &::placeholder {
            font-size: 1em;
            letter-spacing: 0.3px;
            color: ${(p) => p.theme.colors.grey500};
            opacity: 0.5;
          }

          &:focus-visible {
            ${(p) => p.theme.focus};
            outline: none;
            border: none;
          }

          &:disabled,
          &:disabled::placeholder {
            background: ${(p) => p.theme.colors.white200};
            color: ${(p) => p.theme.colors.grey400};
          }
        `}
`;
