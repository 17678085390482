import { useCallback } from "react";

import { useJitsu } from "@jitsu/react";
import { Column } from "@tanstack/react-table";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { StudentListRow } from "@/ui/class/progress/types";

export const useOnClearFilter = (
  column: Column<StudentListRow>,
  filterGroup: string
) => {
  const { track } = useJitsu();

  return useCallback(() => {
    column.setFilterValue(undefined);
    track(JitsuEvent.CLASS_FILTER_CLEARED, {
      filter_group: filterGroup,
      filter_type: "dropdown",
    });
  }, [column, track, filterGroup]);
};
