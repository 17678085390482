import { styled } from "styled-components";

import {
  Colored,
  colors,
  LinkButton,
  SectionLabel,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import emptyImg from "assets/edit/requirements-empty.svg";
import {
  AssessmentType,
  RequirementsFragment,
  TaskFormat,
} from "generated/graphql";

import { IReqsDifference } from "../diffRequirements";
import {
  HiddenAssignmentRequirements,
  VisibleAssignmentRequirements,
} from "./AssignmentRequirements";
import {
  HiddenExamRequirements,
  RequirementViewErrors,
  VisibleExamRequirements,
} from "./ExamRequirements";

interface RequirementViewProps {
  /** Requirements to display */
  requirements: RequirementsFragment;
  /** Which requirements has changes */
  diff?: IReqsDifference;
  /** Optional Callback called when editing the current Requirements */
  onEdit?: () => void;
  /** Optionally show the hidden settings.  */
  showHidden?: boolean;
  /** Show the legend for comparison against previously released requirements */
  showLegend?: boolean;
  /** Whether the empty state should be rendered by the component. */
  shouldShowEmptyState?: boolean;
  examErrors?: RequirementViewErrors;
  /** Format of the task */
  format?: TaskFormat;
  /** Optional Callback called for exam start date. */
  setExamStart?: VoidFunction;
  /** Optional Callback called for set final due date. */
  setFinalDueDate?: VoidFunction;
}

/**
 * Display user-friendly readonly information on provided Requirements.
 *
 * Requirements can be split into 'public' and 'hidden'. Public requirements are
 * everything a student can see. Hidden requirements are only for lecturer
 * eyes. The display of the hidden requirements is controlled using the
 * `showHidden` prop.
 *
 * When requirements are empty, a nice illustration directing the user to update
 * them can be shown using the `showEmpty` prop. Otherwise, the default
 * behaviour is to show an empty section.
 */
export function RequirementView({
  requirements,
  diff,
  onEdit,
  showHidden,
  showLegend,
  examErrors,
  shouldShowEmptyState,
  format,
  setExamStart,
  setFinalDueDate,
}: RequirementViewProps) {
  const isExam = requirements.assessmentType === AssessmentType.Exam;

  if (shouldShowEmptyState) {
    return (
      <>
        <SectionLabel title="Requirements" />
        <EmptyRequirements isExam={isExam} />
        {onEdit && (
          <>
            <Spacer spacing={27} />
            <StyledLinkButton
              data-testid={"SetRequirements"}
              data-component={"RequirementView.set-requirements"}
              kind="primary"
              iconName="Settings"
              onClick={onEdit}
            >
              Set requirements, grading options and settings
            </StyledLinkButton>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <StyledSectionLabel title={isExam ? "Exam details" : "Requirements"} />
      <Spacer spacing={27} />
      <>
        {isExam ? (
          <VisibleExamRequirements
            reqs={requirements}
            diff={diff}
            goToEditSettings={onEdit}
            validationErrors={examErrors}
            format={format}
            setExamStart={setExamStart}
          />
        ) : (
          <VisibleAssignmentRequirements
            reqs={requirements}
            diff={diff}
            setFinalDueDate={setFinalDueDate}
            format={format}
          />
        )}

        {showHidden &&
          (isExam ? (
            <HiddenExamRequirements
              reqs={requirements}
              diff={diff}
              validationErrors={examErrors}
              goToEditSettings={onEdit}
              format={format}
            />
          ) : (
            <HiddenAssignmentRequirements
              reqs={requirements}
              diff={diff}
              format={format}
            />
          ))}
      </>
      {diff && showLegend && (
        <>
          <Spacer spacing={27} />
          <Text kind="bodySm">
            <Colored color="red500">*</Colored> <em>unreleased changes</em>
          </Text>
        </>
      )}
      {onEdit && (
        <>
          <Spacer spacing={27} />
          <StyledLinkButton
            data-testid={"SetRequirements"}
            data-component={"RequirementView.set-requirements"}
            kind="primary"
            iconName="Settings"
            onClick={onEdit}
          >
            Set requirements, grading options and settings
          </StyledLinkButton>
        </>
      )}
    </>
  );
}

const EmptyRequirements = ({ isExam }: { isExam: boolean }) => (
  <EmptyContainer data-testid={"EmptyRequirements"}>
    <ImgContainer>
      <Img
        role="presentation"
        src={emptyImg}
        alt="A doodle illustration of a gear."
      />
    </ImgContainer>
    <Spacer spacing={18} />
    <Text kind="headingFive">
      Set {isExam ? "exam" : "assignment"} requirements and grade settings
    </Text>
    <Text kind="bodySm" color="shade1">
      {isExam ? (
        <>
          Set details of this exam, like exam duration and enable auto
          submission.
          <br />
          Students will see these requirements in Cadmus.
        </>
      ) : (
        <>
          Set the details of the assignment, like due dates and referencing
          styles.
          <br />
          Students will see these requirements in Cadmus.
        </>
      )}
    </Text>
  </EmptyContainer>
);

const EmptyContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  display: block;
  height: 63px;
`;

const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
`;

const StyledSectionLabel = styled(SectionLabel)`
  p {
    color: ${colors.grey600};
  }
`;
