import { styled } from "styled-components";

import { Button } from "@vericus/cadmus-ui";

export interface HeaderProps {
  backgroundColor: string;
  confirmButtonText: string;
  onGoBack: VoidFunction;
  /** Called when the user confirms that they want to use this template (or past assessment). */
  onConfirm: VoidFunction;
  /** Template or past assesmment info. */
  children: JSX.Element;
}

export const Header = ({
  backgroundColor,
  children,
  confirmButtonText,
  onConfirm,
  onGoBack,
}: HeaderProps) => {
  return (
    <Container backgroundColor={backgroundColor}>
      <Button iconName="Left" iconPosition="left" onClick={onGoBack}>
        Back
      </Button>
      <InformationSection>{children}</InformationSection>
      <Button kind="secondary" onClick={onConfirm}>
        {confirmButtonText}
      </Button>
    </Container>
  );
};

const Container = styled.div<{ backgroundColor: string }>`
  background-color: ${(p) => p.backgroundColor};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;

  padding: 24px 20px 24px 20px;
`;

const InformationSection = styled.div`
  flex-grow: 1;
`;

export const PastAssessmentInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;
