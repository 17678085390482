import { Observable } from "@apollo/client";

/**
 * Pad an `observable` by `amount` in milliseconds.
 *
 * e.g. if amount = 3000, then a minimum of 3000ms will
 *        pass before the event is emitted.
 *
 * @param observable - The `Observable` to delay
 * @param amount - milliseconds to add
 * @return a wrapped Observable which will emit the inner Observable value only
 * if `amount` milliseconds have passed.
 */
export function delayObservable<T>(observable: Observable<T>, amount: number) {
  const startTime = Date.now();

  return new Observable<T>((obs) => {
    let timer: number;
    const subscription = observable.subscribe({
      next: (v) => {
        timer = window.setTimeout(() => {
          obs.next(v);
          obs.complete();
        }, amount - Date.now() + startTime);
      },
      error: (err) => obs.error(err),
    });

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      subscription.unsubscribe();
    };
  });
}
