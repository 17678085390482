import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { styled } from "styled-components";

import { Icon } from "@vericus/cadmus-icons";
import {
  colors,
  DropdownMenu,
  Popover,
  Text,
  Toolbar,
} from "@vericus/cadmus-ui";

import { useJitsu } from "@jitsu/react";
import { JitsuEvent } from "data/events/JitsuEvent";
import { isBefore } from "date-fns";
import {
  AssessmentType,
  EnrollmentFragment,
  GroupFragment,
  ReleasedRequirementsFragment,
  SubmissionType,
} from "generated/graphql";
import { useLocation, useParams } from "react-router-dom";

import { selectRevealedEnrollmentIds } from "@/data/class/selectors";
import { useAppSelector } from "@/data/hooks";
import { createExamSettings } from "@/graphql/types/EnrollmentSettings";
import { useRootNavigate } from "@/router/routing";
import {
  ClassTab,
  StudentListMeta,
  StudentListRow,
} from "@/ui/class/progress/types";
import { useCancelDeferred } from "@/ui/exam-special-considerations/hooks/useCancelDeferred";
import { useConfirmRemoveExtraTime } from "@/ui/exam-special-considerations/hooks/useConfirmRemoveExtraTime";
import { useConfirmResetExamTiming } from "@/ui/exam-special-considerations/hooks/useConfirmResetExamTiming";
import { useDeferEnrollments } from "@/ui/exam-special-considerations/hooks/useDeferEnrollments";
import { RevealScope } from "@/ui/integrity-assurance/RevealStudentConfirmationModal";
import { SpecialConsiderationsBulkMenuItem } from "@/ui/StudentLists/BulkActionsMenu/SpecialConsiderationsBulkMenuItem";

import { getExamEndDate, progressStageToString } from "../helpers";
import { ColumnId } from "../table";
import { bulkDisplayTags, TagSelectorBulk } from "../TagSelector";
import { ExtensionBulkMenuItem } from "./ExtensionBulkMenuItem";
import { GroupBulkEditCard } from "./GroupBulkEditCard";

type NarrowedHandlers = Pick<
  StudentListMeta,
  | "contactStudents"
  | "editExtensions"
  | "downloadSubmissions"
  | "updateEnrollmentStatus"
  | "forceSubmissions"
  | "clearExtensions"
  | "addTag"
  | "removeTag"
  | "tagConfigs"
>;

interface BulkActionMenuProps extends NarrowedHandlers {
  /** The released instruction sheet of the assessment */
  sheet?: ReleasedRequirementsFragment;
  /**
   * Current class list tab
   * // TODO: remove this
   */
  tab: ClassTab;
  /**
   * Currently selected NON-EMPTY list of rows data on which the bulk actions
   * are applied
   */
  rows: StudentListRow[];
  /**
   * Complete list of ALL the groups with their members
   */
  groups: GroupFragment[];
  /**
   * Callback to let the parent know that changes were made
   * related to the specified column.
   * @param column the column the change is based on.
   */
  onChanges: (column: ColumnId.Group | ColumnId.Tags) => void;

  /**
   * Whether the bulk menu should show the menu items related to anonymity management.
   */
  anonymityManagement?: {
    hideEnrollments: (enrollmentIds: string[]) => void;
    revealEnrollments: (enrollmentIds: string[], scope: RevealScope) => void;
  };

  /**
   * Did the user select all the possible rows?
   */
  selectedAll?: boolean;

  /**
   * Callback to update group memberships for enrollments in bulk. If not
   * passed, the edit group memberships menu will not be rendered.
   */
  updateGroupMemberships?: (
    enrollments: EnrollmentFragment[],
    groupName: string
  ) => void;
}

/**
 * Floating menu for bulk class list actions.
 */
export const BulkActionsMenu = forwardRef<HTMLDivElement, BulkActionMenuProps>(
  (props, ref) => {
    const {
      groups,
      rows,
      tab,
      contactStudents,
      editExtensions,
      downloadSubmissions,
      forceSubmissions,
      updateEnrollmentStatus,
      sheet,
      clearExtensions,
      updateGroupMemberships,
      addTag,
      removeTag,
      tagConfigs,
      onChanges,
    } = props;

    const { track } = useJitsu();

    const menuRef = useRef<HTMLDivElement | null>(null);
    const { assessmentId } = useParams<{
      assessmentId: string;
      workId: string;
      tenant: string;
    }>();

    if (!assessmentId) {
      throw new Error("assessmentId is undefined");
    }

    const revealedEnrollmentIds = useAppSelector(selectRevealedEnrollmentIds);

    // Selected Enrollment fragments
    const enrollments = useMemo(
      () => rows.map((row) => row.enrollment),
      [rows]
    );

    const withdrawn = useMemo(
      () =>
        enrollments.every((enrollment) => enrollment.deleted) &&
        !enrollments.some((enrollment) => !enrollment.deleted),
      [enrollments]
    );

    const someRevealed = enrollments.some((e) =>
      revealedEnrollmentIds.includes(e.id)
    );
    const someHidden = enrollments.some(
      (e) => !revealedEnrollmentIds.includes(e.id)
    );

    const location = useLocation();

    // The current selection of enrollments can have a bulk force submission
    // operation
    const canForceSubmit = useMemo(() => {
      return (
        tab === ClassTab.Students &&
        rows.every(({ enrollment, settings }) => {
          const cutoffDate = getExamEndDate(settings);
          return (
            enrollment.work &&
            !enrollment.work.final &&
            enrollment.work.lastSaveTime &&
            !settings?.deferred &&
            cutoffDate &&
            isBefore(cutoffDate, new Date())
          );
        })
      );
    }, [rows, tab]);

    const rootNavigate = useRootNavigate();
    const goToExamSpecialConsiderationsEditPage = useCallback(() => {
      rootNavigate("/class/marking/examSpecialConsiderations/edit", {
        replace: false,
        state: {
          enrollments: enrollments,
          from: location,
        },
      });
    }, [enrollments, location, rootNavigate]);

    const confirmResetExamTiming = useConfirmResetExamTiming(assessmentId);
    const confirmRemoveExtraTime = useConfirmRemoveExtraTime(assessmentId);
    const cancelDeferred = useCancelDeferred(assessmentId);
    const deferEnrollment = useDeferEnrollments(assessmentId);

    // Bulk edit group memberships across multiple existing groups or a new group
    const handleEditGroups = (groupName: string) => {
      updateGroupMemberships?.(enrollments, groupName);
    };

    // The menu is rendered when 1 or more rows are selected on the passed table instance
    const handleContactStudents = () => {
      const enrollmentsWithEmailsToContact = rows.flatMap((row) => {
        return row.enrollment.user.email
          ? [
              {
                progress_stage: row.progress,
                email: row.enrollment.user.email,
                enrollment: row.enrollment,
              },
            ]
          : [];
      });
      contactStudents(enrollmentsWithEmailsToContact.map((e) => e.email));

      track(JitsuEvent.STUDENT_BULK_CONTACTED, {
        enrollments: enrollmentsWithEmailsToContact.map((e) => {
          return {
            student_id: e.enrollment.user.id,
            enrollment_id: e.enrollment.id,
            progress_stage: progressStageToString(e.progress_stage),
          };
        }),
      });
    };

    const handleDownloadSubmissions = () => {
      const submissionType =
        tab === ClassTab.Drafts ? SubmissionType.Draft : SubmissionType.Final;
      downloadSubmissions(enrollments, submissionType);
    };

    const [markingGroupPopoverOpen, setMarkingGroupPopoverOpen] =
      useState(false);

    useEffect(() => {
      // Listen to if menu shortcut is pressed
      const handleMenuShortcut = (e: KeyboardEvent) => {
        if (e.code === "KeyM" && e.altKey) {
          menuRef.current?.focus();
        }
      };
      document.addEventListener("keydown", handleMenuShortcut);
      return () => {
        document.removeEventListener("keydown", handleMenuShortcut);
      };
    }, []);

    return (
      <AbsoluteContainer ref={ref}>
        <FloatingMenuRoot
          ref={menuRef}
          aria-label="Bulk actions menu"
          aria-keyshortcuts="alt+m"
        >
          {tab !== ClassTab.Students && (
            <>
              <DropdownMenu.Root>
                <Toolbar.Button asChild>
                  <DropdownMenu.Trigger asChild>
                    <FloatingMenuItem>
                      <Icon iconName="AddTag" />
                      <Text kind="headingSix">Tag</Text>
                    </FloatingMenuItem>
                  </DropdownMenu.Trigger>
                </Toolbar.Button>
                <TagSelectorBulk
                  tags={bulkDisplayTags(
                    rows.map((row) => row.tags),
                    tagConfigs
                  )}
                  onAddTag={(text, isCustom = false) => {
                    addTag(enrollments, text, tab);
                    onChanges(ColumnId.Tags);
                    track(JitsuEvent.ENROLLMENT_TAG_BULK_ADDED, {
                      tag_name: text,
                      is_custom: isCustom,
                      enrollments: enrollments.map((enrollment) => {
                        return {
                          enrollment_id: enrollment.id,
                          student_id: enrollment.user.id,
                        };
                      }),
                    });
                  }}
                  onRemoveTag={(text, isCustom = false) => {
                    removeTag(enrollments, text, tab);
                    onChanges(ColumnId.Tags);
                    track(JitsuEvent.ENROLLMENT_TAG_BULK_REMOVED, {
                      tag_name: text,
                      is_custom: isCustom,
                      enrollments: enrollments.map((enrollment) => {
                        return {
                          enrollment_id: enrollment.id,
                          student_id: enrollment.user.id,
                        };
                      }),
                    });
                  }}
                  onCreateTag={(text) => {
                    addTag(enrollments, text, tab);
                    onChanges(ColumnId.Tags);
                    track(JitsuEvent.ENROLLMENT_TAG_BULK_ADDED, {
                      tag_name: text,
                      is_custom: true,
                      enrollments: enrollments.map((enrollment) => {
                        return {
                          enrollment_id: enrollment.id,
                          student_id: enrollment.user.id,
                        };
                      }),
                    });
                  }}
                />
              </DropdownMenu.Root>

              <FloatingMenuDivider />
            </>
          )}
          {updateGroupMemberships && (
            <Popover.Root open={markingGroupPopoverOpen}>
              <Toolbar.Button asChild>
                <Popover.Trigger asChild>
                  <FloatingMenuItem
                    onClick={() =>
                      setMarkingGroupPopoverOpen(!markingGroupPopoverOpen)
                    }
                    as="button"
                  >
                    <Icon iconName="AddGroup" />
                    <Text kind="headingSix">Marking group</Text>
                  </FloatingMenuItem>
                </Popover.Trigger>
              </Toolbar.Button>
              <Popover.Content
                sideOffset={8}
                onInteractOutside={() => setMarkingGroupPopoverOpen(false)}
              >
                <GroupBulkEditCard
                  groups={groups}
                  onCancel={() => setMarkingGroupPopoverOpen(false)}
                  onConfirm={(groupName) => {
                    handleEditGroups(groupName);
                    onChanges(ColumnId.Group);
                    setMarkingGroupPopoverOpen(false);
                  }}
                />
              </Popover.Content>
            </Popover.Root>
          )}
          {sheet?.assessmentType === AssessmentType.Assignment &&
            sheet?.dueDate !== undefined && (
              <>
                <FloatingMenuDivider />
                <ExtensionBulkMenuItem
                  rows={rows}
                  sheet={sheet}
                  editExtensions={editExtensions}
                  clearExtensions={clearExtensions}
                  selectedAll={props.selectedAll}
                  renderControl={(label) => (
                    <FloatingMenuItem>
                      <Icon iconName="CalendarCheck" />
                      <Text kind="headingSix">{label}</Text>
                    </FloatingMenuItem>
                  )}
                />
              </>
            )}

          {sheet?.assessmentType === AssessmentType.Exam &&
            sheet?.examStartDate !== undefined && (
              <>
                <FloatingMenuDivider />
                <SpecialConsiderationsBulkMenuItem
                  examTiming={sheet.examTiming}
                  enrollmentExamSettings={enrollments.map((enrollment) => {
                    return createExamSettings(enrollment, sheet);
                  })}
                  removeExtraTime={() => {
                    confirmRemoveExtraTime(enrollments);
                  }}
                  setExtraTime={() => {
                    goToExamSpecialConsiderationsEditPage();
                  }}
                  setAlternativeExamDate={() => {
                    goToExamSpecialConsiderationsEditPage();
                  }}
                  removeAlternativeExamDate={() => {
                    confirmResetExamTiming(enrollments);
                  }}
                  markAsDeferred={async () => {
                    await deferEnrollment(enrollments);
                  }}
                  cancelMarkAsDeferred={async () => {
                    await cancelDeferred(enrollments);
                  }}
                  renderControl={() => {
                    return (
                      <FloatingMenuItem>
                        <Icon iconName="CalendarCheck" />
                        <Text kind="headingSix">Special considerations</Text>
                      </FloatingMenuItem>
                    );
                  }}
                />
              </>
            )}
          <FloatingMenuDivider />
          <Toolbar.Button asChild>
            <FloatingMenuItem as="button" onClick={handleDownloadSubmissions}>
              <Icon iconName="Download" />
              <Text kind="headingSix">Submissions</Text>
            </FloatingMenuItem>
          </Toolbar.Button>
          <Toolbar.Button asChild>
            <FloatingMenuItem
              onClick={() => updateEnrollmentStatus(enrollments, !withdrawn)}
              as="button"
            >
              <Icon iconName="Unset" />
              <Text kind="headingSix">
                {withdrawn ? "Cancel withdrawal" : "Withdraw"}
              </Text>
            </FloatingMenuItem>
          </Toolbar.Button>
          {canForceSubmit && forceSubmissions && (
            <>
              <FloatingMenuDivider />
              <Toolbar.Button asChild>
                <FloatingMenuItem
                  as="button"
                  onClick={() => forceSubmissions(enrollments)}
                >
                  <Icon iconName="Send" />
                  <Text kind="headingSix">Force Submit</Text>
                </FloatingMenuItem>
              </Toolbar.Button>
            </>
          )}

          {!props.anonymityManagement && (
            <>
              <FloatingMenuDivider />
              <Toolbar.Button asChild>
                <FloatingMenuItem as="button" onClick={handleContactStudents}>
                  <Icon iconName="Mail" />
                  <Text kind="headingSix">Contact</Text>
                </FloatingMenuItem>
              </Toolbar.Button>
            </>
          )}

          {props.anonymityManagement && someHidden && (
            <>
              <FloatingMenuDivider />
              <Toolbar.Button asChild>
                <FloatingMenuItem
                  onClick={() => {
                    let scope: RevealScope;
                    switch (true) {
                      case rows.length === 1:
                        scope = RevealScope.ONE;
                        break;
                      case props.selectedAll:
                        scope = RevealScope.ALL;
                        break;
                      case rows.length > 1:
                      default:
                        scope = RevealScope.SOME;
                    }

                    props.anonymityManagement?.revealEnrollments(
                      enrollments.map((e) => e.id),
                      scope
                    );
                  }}
                  as="button"
                >
                  <Icon iconName="Person" />
                  <Text kind="headingSix">Reveal names</Text>
                </FloatingMenuItem>
              </Toolbar.Button>
            </>
          )}
          {props.anonymityManagement && someRevealed && (
            <>
              <FloatingMenuDivider />
              <Toolbar.Button asChild>
                <FloatingMenuItem
                  onClick={() => {
                    props.anonymityManagement?.hideEnrollments(
                      enrollments.map((e) => e.id)
                    );
                  }}
                  as="button"
                >
                  <Icon iconName="Person" />
                  <Text kind="headingSix">Hide names</Text>
                </FloatingMenuItem>
              </Toolbar.Button>
            </>
          )}
        </FloatingMenuRoot>
      </AbsoluteContainer>
    );
  }
);

const FloatingMenuRoot = styled(Toolbar.Root)`
  pointer-events: all;
  margin: auto;
  display: inline-flex;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 3px;
  background: white;
  border: 1px solid ${colors.grey200};

  box-shadow:
    0px 3px 10px -5px rgb(59 64 79 / 45%),
    0px 14px 16px rgb(71 92 133 / 12%);

  z-index: 2;
`;

const FloatingMenuDivider = styled.div.attrs({ "aria-disabled": true })`
  display: block;
  width: 1px;
  height: 20px;
  margin: 0px 4px;
  background: ${(p) => p.theme.divider};
  border: none;
`;

const FloatingMenuItem = styled(DropdownMenu.MenuItemDiv)`
  width: auto;
`;

// Absolute floating positioned menu
const AbsoluteContainer = styled.div`
  pointer-events: none;
  position: fixed;
  bottom: 80px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 9999;
`;
