import { useMemo } from "react";

import { EnrollmentFragment, ModerationPenaltyRule } from "@/generated/graphql";

import { getEnrollmentHasTagIncluded } from "../../utils/get-enrollment-has-tag-included";
import {
  LocalModerationPenaltyRule,
  NewLocalModerationPenaltyRule,
  PartialPenaltyRule,
} from "./types";
import {
  EnrollmentSubmitLateByMap,
  useEnrollmentSubmitLateBy,
} from "./use-enrollment-submit-lateby";
import { getPenaltyRuleApplicableEnrollmentIds } from "./utils";

export type EnrollmentRulesMap<T extends PartialPenaltyRule> = {
  [enrollmentId: string]: T[];
};

/**
 * Given penaltyRules and enrollments, return a map of student ids
 * and corresponding applicable rules
 *
 * Note: Applicable rules mean rules that students will receive penalty
 * from them
 *
 * @param penaltyRules
 * @param enrollments
 * @returns A map whose key is student id, value is an array of
 * rules that are applicable to this student id
 */
export const useStudentApplicableRulesMap = (
  penaltyRules: (
    | (LocalModerationPenaltyRule & { isNew?: boolean })
    | NewLocalModerationPenaltyRule
    | ModerationPenaltyRule
  )[],
  enrollments: EnrollmentFragment[]
) => {
  const enrollmentsSubmitLateBy = useEnrollmentSubmitLateBy();

  return useMemo(
    () =>
      getStudentApplicableRulesMap(
        penaltyRules,
        enrollments,
        enrollmentsSubmitLateBy
      ),
    [penaltyRules, enrollments, enrollmentsSubmitLateBy]
  );
};

export function getStudentApplicableRulesMap(
  penaltyRules: (
    | (LocalModerationPenaltyRule & { isNew?: boolean })
    | NewLocalModerationPenaltyRule
    | ModerationPenaltyRule
  )[],
  enrollments: EnrollmentFragment[],
  enrollmentsSubmitLateBy: EnrollmentSubmitLateByMap
) {
  // Applied rules of each enrollment id
  const enrollmentAppliedRules = getEnrollmentAppliedRulesMap(
    penaltyRules,
    enrollments
  );
  // Applicable enrollment ids of each rule id
  const ruleApplicableEnrollmentIdsMap: { [ruleId: string]: string[] } = {};
  for (const penaltyRule of penaltyRules) {
    ruleApplicableEnrollmentIdsMap[penaltyRule.id] =
      getPenaltyRuleApplicableEnrollmentIds(
        penaltyRule,
        enrollmentAppliedRules,
        enrollmentsSubmitLateBy
      );
  }
  // Filter out the unapplicable rules of each student id
  const studentApplicableRulesMap: {
    [studentId: string]: (
      | (LocalModerationPenaltyRule & { isNew?: boolean })
      | NewLocalModerationPenaltyRule
      | ModerationPenaltyRule
    )[];
  } = {};
  for (const [enrollmentId, appliedPenaltyRules] of Object.entries(
    enrollmentAppliedRules
  )) {
    const studentId = enrollments.find(
      (enrollment) => enrollment.id === enrollmentId
    )?.user.id;
    if (!studentId) continue;
    studentApplicableRulesMap[studentId] = appliedPenaltyRules.filter(
      (penaltyRule) =>
        ruleApplicableEnrollmentIdsMap[penaltyRule.id]?.includes(enrollmentId)
    );
  }

  return studentApplicableRulesMap;
}

/**
 * Return map of enrollment id and applied penalty rules
 * Note: Applied penalty rules may not be applicable to enrollment
 * E.g. Can stack penalty rule will make other can't stack rules of
 * the same enrollment unapplicable
 * @param penaltyRules
 * @param enrollments
 * @returns
 */
export function getEnrollmentAppliedRulesMap(
  penaltyRules: (
    | (LocalModerationPenaltyRule & { isNew?: boolean })
    | NewLocalModerationPenaltyRule
    | ModerationPenaltyRule
  )[],
  enrollments: EnrollmentFragment[]
) {
  const newState: EnrollmentRulesMap<
    | (LocalModerationPenaltyRule & { isNew?: boolean })
    | NewLocalModerationPenaltyRule
    | ModerationPenaltyRule
  > = {};

  for (const penaltyRule of penaltyRules) {
    const penaltyTags = penaltyRule.includedTags ?? [];
    const excludingStudentIds = penaltyRule.excludedStudentIds ?? [];
    // Selectively include rules
    // Rule will only applied to students with included tags
    // Or will apply if no tag filter is set.
    for (const enrollment of enrollments) {
      const penaltyApplies =
        penaltyTags.length > 0
          ? getEnrollmentHasTagIncluded(enrollment, penaltyTags) &&
            !excludingStudentIds.includes(enrollment.user.id)
          : !excludingStudentIds.includes(enrollment.user.id);

      if (penaltyApplies) {
        const currentPenalties = newState[enrollment.id];
        if (currentPenalties) {
          currentPenalties.push(penaltyRule);
        } else {
          newState[enrollment.id] = [penaltyRule];
        }
      }
    }
  }

  return newState;
}
