import { forwardRef, useState } from "react";
import { styled, CSSProperties } from "styled-components";

import {
  FileCard,
  getFileType,
  LinkCard,
  ResourceCardBaseProps,
} from "@vericus/cadmus-ui";

import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import copy from "copy-to-clipboard";
import { ResourceFragment } from "generated/graphql";

import {
  formatFileSize,
  getExtension,
  isLink,
  removeExtension,
} from "./internal";

export interface ResourceCardProps extends ResourceCardBaseProps {
  resource: ResourceFragment;
  children?: JSX.Element | false;
  style?: CSSProperties;
  draggableAttributes?: DraggableAttributes;
  draggableListeners?: SyntheticListenerMap;
}

/** Adds drag and drop to a resource card. */
export const DraggableResourceCard = ({
  resource,
  ...otherProps
}: ResourceCardProps) => {
  const { id } = resource;
  const { attributes, listeners, setNodeRef, transform, transition, active } =
    useSortable({
      id,
      transition: {
        duration: 750, // in milliseconds
        easing: "ease",
      },
      strategy: verticalListSortingStrategy,
    });

  const isActive = active?.id === id;
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isActive ? "0" : "1",
  };
  return (
    <ResourceCard
      {...otherProps}
      ref={setNodeRef}
      resource={resource}
      style={style}
      draggableAttributes={attributes}
      draggableListeners={listeners}
    />
  );
};

/**
 * A more unified API for the resource card types. Presentational only.
 */
export const ResourceCard = forwardRef<HTMLDivElement, ResourceCardProps>(
  (
    {
      resource,
      style,
      draggableAttributes,
      draggableListeners,
      children,
      ...commonProps
    },
    ref
  ) => {
    const { name } = resource;
    const [focus, setFocus] = useState(false);
    const component = isLink(resource) ? (
      <LinkCard
        {...commonProps}
        linkTitle={name ?? ""}
        onCopyLink={() => resource.url && copy(resource.url)}
        focus={focus}
        onFocusChange={(focus) => setFocus(focus)}
      />
    ) : (
      <FileCard
        {...commonProps}
        fileInfo={{
          name: removeExtension(name ?? ""),
          type: getFileType(name ?? ""),
          size: resource.fileSize ? formatFileSize(resource.fileSize) : "",
          extension: getExtension(name ?? ""),
        }}
        focus={focus}
        onFocusChange={(focus) => setFocus(focus)}
      />
    );
    return (
      <DropShadowed
        ref={ref}
        style={style}
        onFocus={() => setFocus(true)}
        {...draggableAttributes}
        {...draggableListeners}
        onKeyDown={(e) => {
          if (e.shiftKey && e.key === "Tab" && e.currentTarget === e.target)
            setFocus(false);
        }}
      >
        {component}
        {children}
      </DropShadowed>
    );
  }
);

export const DropShadowed = styled.div`
  box-shadow:
    0px 0px 1px rgba(71, 92, 133, 0.36),
    0px 1px 3px rgba(71, 92, 133, 0.15);
  border-radius: 2px;

  &:focus {
    outline: 0;
    ${(p) => p.theme.focus}
  }
`;
