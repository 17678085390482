import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { max } from "date-fns";

import { EnrollmentFragment } from "@/generated/graphql";

import { generateAssessmentReport, syncClassList } from "./actions";

export interface ClassState {
  /** Enrollment IDs for which names can be revealed on submissions when anonymous marking is enabled. */
  revealedEnrollmentIds: string[];
  /** Class list is being roster synced */
  syncing: boolean;
  /**
   * Modal state to show the confirmation modal for assessment reports sent to a
   * particular email address.
   */
  reportEmailModal: string | null;
  /** ISO 8601 datetime string for when the class list was last synced. */
  lastRosterSyncedAt: string | null;
}

export const initialState: ClassState = {
  revealedEnrollmentIds: [],
  syncing: false,
  reportEmailModal: null,
  lastRosterSyncedAt: null,
};

/**
 * Redux state slice to manage state and async operations on the class page.
 */
export const classSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    // Reveal enrollmentId's name for anonymous submissions
    revealNames(state, action: PayloadAction<string[]>) {
      const enrollmentIds = action.payload;
      const newIds = Array.from(
        new Set([...state.revealedEnrollmentIds, ...enrollmentIds])
      );
      state.revealedEnrollmentIds = newIds;
    },
    // Hide enrollmentId's name for anonymous submissions
    hideNames(state, action: PayloadAction<string[]>) {
      const enrollmentIds = action.payload;
      state.revealedEnrollmentIds = state.revealedEnrollmentIds.filter(
        (eId) => !enrollmentIds.includes(eId)
      );
    },
    // Close all modals
    closeModals(state) {
      state.reportEmailModal = null;
    },
    updateLastClassSyncedAt(
      state,
      action: PayloadAction<{
        enrollments: EnrollmentFragment[];
        assessmentId: string;
      }>
    ) {
      const { enrollments } = action.payload;
      const syncedAtDates = enrollments
        .filter(({ syncedAt }) => syncedAt)
        .map(({ syncedAt }) => new Date(syncedAt!));
      if (syncedAtDates.length === 0) return;
      state.lastRosterSyncedAt = max(syncedAtDates).toISOString();
    },
  },
  extraReducers: (builder) => {
    // Roster syncing
    builder.addCase(syncClassList.pending, (state) => {
      state.syncing = true;
    });
    builder.addCase(syncClassList.fulfilled, (state) => {
      state.syncing = false;
      state.lastRosterSyncedAt = new Date().toISOString();
    });
    builder.addCase(syncClassList.rejected, (state) => {
      state.syncing = false;
    });
    // Assessment report generation
    builder.addCase(generateAssessmentReport.fulfilled, (state, action) => {
      state.reportEmailModal = action.payload;
    });
  },
});

// Export Actions
export const { revealNames, hideNames, closeModals, updateLastClassSyncedAt } =
  classSlice.actions;

// Export Reducer
export const classReducer = classSlice.reducer;
