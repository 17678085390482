import { useCallback, useState } from "react";
import { styled } from "styled-components";

import {
  ResourceCardBaseProps,
  SectionLabel,
  Spacer,
} from "@vericus/cadmus-ui";

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { freshlyUploadedFileVar } from "client/apollo";
import { ResourceFragment } from "generated/graphql";
import { ResourceActions } from "ui/task/resources/ResourceActions";

import { useResourceBeingEdited } from "./hooks";
import { getResourceUrl, openUrl } from "./internal";
import { NotesDisplay } from "./NotesDisplay";
import { NotesInput } from "./NotesInput";
import { DraggableResourceCard, ResourceCard } from "./ResourceCard";
import { ResourceEmpty } from "./ResourceEmpty";

interface ResourceListProps {
  resources: ResourceFragment[];
  /** Callback to move a resource to another resource's position. */
  moveResource: (dragId: string, hoverId: string) => void;
  /** Callback for changing a Resource name and notes. */
  onUpdateResource: (id: string, name: string, notes: string) => void;
  /** Callback for adding a new valid URL resource. */
  onAdd: (name: string, url: string) => void;
  /** Callback for attaching a new valid file resource. */
  onUpload: (name: string, file: File) => void;
  /** Callback to delete a resource. */
  onDelete: (id: string) => void;
  /** A new resource is being uploaded or saved.  */
  isLinking?: boolean;
}

/**
 * Editable, Sortable, Resource list view.
 */
export function ResourceList({
  resources,
  moveResource,
  onUpdateResource,
  onAdd,
  onUpload,
  onDelete,
  isLinking,
}: ResourceListProps) {
  const {
    clearResourceBeingEdited,
    isBeingEdited,
    nameBeingEdited,
    notesBeingEdited,
    setResourceBeingEdited,
    updateName,
    updateNotes,
    validatedName,
  } = useResourceBeingEdited(resources);
  const [dndActiveResource, setDndActiveResource] = useState<
    ResourceFragment | undefined
  >();
  const validatedResources = resources.filter((r) => !r.invalidated);
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over?.id && active.id !== over.id) {
        moveResource(active.id as string, over.id as string);
        return;
      }
      setDndActiveResource(undefined);
    },
    [moveResource]
  );

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const activeResourceId = active.id as string;
    const resource = validatedResources.find((r) => r.id === activeResourceId);
    setDndActiveResource(resource);
  };

  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;
      if (over?.id && active.id !== over.id) {
        moveResource(active.id as string, over.id as string);
      }
    },
    [moveResource]
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8, // only detect dragging after this amount of pixels. Allows onClick events to be handled too.
      },
    })
  );
  return (
    <>
      <SectionLabel title="Resources" />
      <Spacer spacing={27} />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
      >
        <SortableContext
          items={validatedResources}
          strategy={verticalListSortingStrategy}
        >
          <ListContainer>
            {validatedResources.map((res) => {
              const commonProps: ResourceCardBaseProps = {
                isEditingName: isBeingEdited(res),
                onCancelEditing: () => {
                  freshlyUploadedFileVar(undefined);
                  clearResourceBeingEdited();
                },
                onDelete: () => {
                  freshlyUploadedFileVar(undefined);
                  onDelete(res.id);
                },
                onUpdateNameBeingEdited: updateName,
                nameBeingEdited,
                onDoneEditing: () => {
                  onUpdateResource(res.id, validatedName, notesBeingEdited);
                  freshlyUploadedFileVar(undefined);
                  clearResourceBeingEdited();
                },
                onOpenInBrowser: () => openUrl(getResourceUrl(res.id)),
                onStartEditing: () => setResourceBeingEdited(res),
              };
              return (
                <DraggableResourceCard
                  {...commonProps}
                  key={res.id}
                  resource={res}
                >
                  <>
                    {isBeingEdited(res) && (
                      <NotesInputContainer>
                        <NotesInput
                          autoFocus={res.isFreshlyUploaded}
                          cancelButtonText={
                            res.isFreshlyUploaded
                              ? "No instructions"
                              : undefined
                          }
                          doneButtonText={
                            res.isFreshlyUploaded ? "Add" : undefined
                          }
                          notes={notesBeingEdited}
                          onNotesChange={updateNotes}
                          onDoneClick={() => {
                            onUpdateResource(
                              res.id,
                              validatedName,
                              notesBeingEdited
                            );
                            freshlyUploadedFileVar(undefined);
                            clearResourceBeingEdited();
                          }}
                          onCancelClick={() => {
                            freshlyUploadedFileVar(undefined);
                            clearResourceBeingEdited();
                          }}
                        />
                      </NotesInputContainer>
                    )}
                    {Boolean(res.notes) && !isBeingEdited(res) && (
                      <NotesDisplay notes={res.notes} />
                    )}
                  </>
                </DraggableResourceCard>
              );
            })}
            {validatedResources.length === 0 && <ResourceEmpty />}
          </ListContainer>
        </SortableContext>
        <DragOverlay modifiers={[restrictToParentElement]}>
          {dndActiveResource && (
            <ResourceCard resource={dndActiveResource} viewOnly>
              {Boolean(dndActiveResource.notes) && (
                <NotesDisplay notes={dndActiveResource.notes} />
              )}
            </ResourceCard>
          )}
        </DragOverlay>
      </DndContext>

      <Spacer spacing={isLinking ? 12 : 27} />
      <ResourceActions
        onAdd={onAdd}
        onUpload={onUpload}
        isLinking={isLinking}
      />
    </>
  );
}

// Toplevel resource list container
export const ListContainer = styled.div`
  cursor: grab;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const NotesInputContainer = styled.div`
  padding: 8px;
`;
