import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import client from "client/apollo";
import { v4 as uuid } from "uuid";

import { RootState } from "@/data/store";
import { add, getTempId } from "@/features/multi-format/task-layout";
import {
  AddQuestionBanksToAssessmentDocument,
  AddQuestionBanksToAssessmentInput,
  AddQuestionBanksToAssessmentMutation,
  AddQuestionBanksToAssessmentMutationVariables,
  CreateFileOriginDocument,
  CreateFileOriginMutation,
  CreateFileOriginMutationVariables,
  CreateQuestionBankDocument,
  CreateQuestionBankInput,
  CreateQuestionBankMutation,
  CreateQuestionBankMutationVariables,
  QuestionBankFileOrigin,
  QuestionBankScope,
  QuestionType,
  TaskBlockFragment,
  TaskBlockFragmentDoc,
  UpdateFileOriginDocument,
  UpdateFileOriginMutation,
  UpdateFileOriginMutationVariables,
  UpdateQuestionBanksCategoryDocument,
  UpdateQuestionBanksCategoryMutation,
  UpdateQuestionBanksCategoryMutationVariables,
} from "@/generated/graphql";

import { parser as QTIParser } from "../parsers/qti-parser";
import { ParsedQuestion, ParsedQuestionBank } from "./types";

export const loadFiles = createAsyncThunk<
  ParsedQuestionBank[][],
  File[],
  { state: RootState }
>("questionBanks/loadFiles", async (payload, thunkAPI) => {
  const banks = await Promise.all(
    payload.map(async (file) => {
      const parsed = await QTIParser.parse(
        file,
        (filesCount) => thunkAPI.dispatch(addProcessingFiles(filesCount)),
        (filesCount) => thunkAPI.dispatch(addProcessedFiles(filesCount))
      );
      if (parsed && file?.type === "application/zip") {
        return parsed.map((f) => ({
          ...f,
          fileOrigin: {
            ...f.fileOrigin,
            filepath: `${file.name}/${f.fileOrigin.filepath}`,
          },
        }));
      }
      return parsed;
    })
  );
  return banks;
});

export const updateFilename = createAsyncThunk<
  QuestionBankFileOrigin | undefined,
  { fileOriginId: string; filename: string },
  {
    state: RootState;
    rejectValue: { filename: string | undefined; error: Error };
  }
>("questionBanks/updateFilename", async (payload, thunkAPI) => {
  const { questionBanks } = thunkAPI.getState();
  const { fileOriginId, filename } = payload;
  const bank = questionBanks.banks.find(
    (bank) => bank.fileOrigin.fileOriginId === fileOriginId
  );
  const { id, filepath } = bank?.fileOrigin ?? {};
  if (!filepath) {
    return thunkAPI.rejectWithValue({
      filename: bank?.fileOrigin.filename,
      error: new Error("filepath cannot be empty"),
    });
  }
  try {
    if (id) {
      const { data } = await client.mutate<
        UpdateFileOriginMutation,
        UpdateFileOriginMutationVariables
      >({
        mutation: UpdateFileOriginDocument,
        variables: {
          input: {
            id: id,
            filename: filename,
          },
        },
      });
      return data?.updateFileOrigin;
    } else {
      const { data } = await client.mutate<
        CreateFileOriginMutation,
        CreateFileOriginMutationVariables
      >({
        mutation: CreateFileOriginDocument,
        variables: {
          input: {
            filename: filename,
            filepath: filepath,
          },
        },
      });
      return data?.createFileOrigin;
    }
  } catch (e) {
    return thunkAPI.rejectWithValue({
      filename: bank?.fileOrigin.filename,
      error: e as Error,
    });
  }
});

export interface AssignCategoryArgs {
  category: string;
  questionIds?: string[];
  fileOriginId?: string;
  scope: QuestionBankScope;
}

export interface CategorisedQuestion {
  id?: string;
  questionId: string;
  category?: string;
  serverQuestionId?: string;
  saveId?: string | undefined;
  fileOrigin?: {
    id?: string;
  };
}

export const assignCategory = createAsyncThunk<
  CategorisedQuestion[],
  AssignCategoryArgs,
  { state: RootState }
>("questionBanks/assignCategory", async (payload, thunkAPI) => {
  const categorisedQuestions: CategorisedQuestion[] = [];
  const { fileOriginId, questionIds = [], scope, category } = payload;
  if (!fileOriginId && questionIds.length === 0) {
    return thunkAPI.rejectWithValue("");
  }
  const { questionBanks } = thunkAPI.getState();
  let questions: ParsedQuestion[] | undefined;
  if (fileOriginId) {
    questions = questionBanks.banks
      .find(({ fileOrigin: { fileOriginId: id } }) => fileOriginId === id)
      ?.questions?.filter((question) => question.questionType);
  } else {
    questions = questionBanks.banks.flatMap(({ questions }) =>
      questions.filter((question) => questionIds.includes(question.questionId))
    );
  }
  if (!questions) {
    return thunkAPI.rejectWithValue("");
  }

  const fileOriginIds = [
    ...new Set(questions.map(({ fileOriginId }) => fileOriginId)),
  ];
  const fileOrigins = questionBanks.banks.flatMap(({ fileOrigin }) =>
    fileOriginIds.includes(fileOrigin.fileOriginId) ? [fileOrigin] : []
  );

  const newQuestions = questions.filter(({ id }) => !id);
  const oldQuestions = questions.filter(({ id }) => !!id);
  if (newQuestions.length > 0) {
    await Promise.all(
      newQuestions.map(async (question) => {
        const fileOrigin = fileOrigins.find(
          ({ fileOriginId }) => fileOriginId === question.fileOriginId
        );
        const input: CreateQuestionBankInput = {
          scope: scope,
          fileOriginId: fileOrigin!.id,
          filename: fileOrigin!.filename,
          filepath: fileOrigin!.filepath,
          category: category,
          points: question.points,
          promptDoc: question.prompt ?? "",
          shortPrompt: question.shortPrompt ?? "",
          questionType: question.questionType! satisfies QuestionType,
          feedback: question.feedback,
        };
        switch (question.response?.__typename) {
          case "MultiResponse": {
            input.multiResponse = {
              choices: question.response.choices,
            };
            break;
          }
          case "ShortResponse": {
            input.shortResponse = {
              matchPhrases: question.response.matchPhrases,
              matchSimilarity: question.response.matchSimilarity,
            };
            break;
          }
          case "EditorResponse": {
            input.editorResponse = {
              wordLimit: question.response.wordLimit,
            };
            break;
          }
          case "BooleanResponse": {
            input.booleanResponse = {
              isTrue: question.response.isTrue ?? false,
            };
            break;
          }
          case "BlanksResponse": {
            input.blanksResponse = {
              matchBlanks: question.response.matchBlanks,
              matchSimilarity: question.response.matchSimilarity,
            };
            break;
          }
        }
        const { data } = await client.mutate<
          CreateQuestionBankMutation,
          CreateQuestionBankMutationVariables
        >({
          mutation: CreateQuestionBankDocument,
          variables: {
            questionBank: input,
          },
        });
        categorisedQuestions.push({
          id: data?.createQuestionBank.id,
          serverQuestionId: data?.createQuestionBank.question.id,
          saveId: data?.createQuestionBank.question.body?.id,
          questionId: question.questionId,
          fileOrigin: {
            id: data?.createQuestionBank.fileOrigin?.id,
          },
          category: data?.createQuestionBank.category?.name,
        });
      })
    );
  }
  if (oldQuestions.length > 0) {
    const { data } = await client.mutate<
      UpdateQuestionBanksCategoryMutation,
      UpdateQuestionBanksCategoryMutationVariables
    >({
      mutation: UpdateQuestionBanksCategoryDocument,
      variables: {
        input: {
          scope,
          category,
          questionBankIds: oldQuestions.flatMap(({ questionBankId }) =>
            questionBankId ? [questionBankId] : []
          ),
        },
      },
    });
    data?.updateQuestionBanksCategory.forEach((q) => {
      const question = oldQuestions.find((oq) => oq.questionBankId === q.id);
      if (question) {
        categorisedQuestions.push({
          questionId: question.questionId,
          category,
        });
      }
    });
  }
  return categorisedQuestions;
});

export interface AddQuestionsToAssessmentFilter {
  filter: AddQuestionBanksToAssessmentInput["filter"];
  questionBankIds: string[];
}

export interface AddQuestionsToAssessmentArgs {
  questionBankIds?: string[];
  filters?: AddQuestionsToAssessmentFilter[];
  taskId: string;
  assessmentId: string;
}

export const addQuestionsToAssessment = createAsyncThunk<
  unknown,
  AddQuestionsToAssessmentArgs,
  { state: RootState }
>("questionBanks/addQuestionsToAssessment", async (payload, thunkAPI) => {
  const { assessmentId, questionBankIds, taskId, filters } = payload;
  const ids =
    questionBankIds ?? filters?.flatMap((f) => f.questionBankIds) ?? [];

  const sortedBlocks = thunkAPI.getState().taskLayout.sortedTaskBlocks;
  let previousNodeId: string | null = null;
  // get previousNodeId for the inserted block(s)
  const previousIndex = sortedBlocks
    .map((t) => t.parentNodeId)
    .lastIndexOf(null);
  if (previousIndex === -1) {
    previousNodeId = null;
  } else {
    const block = sortedBlocks[previousIndex]!;
    if (block.questionType === QuestionType.Overview) {
      previousNodeId = null;
    } else {
      previousNodeId = block.nodeId;
    }
  }
  const nodeIds = ids.reduce((acc, id, index) => {
    if (index === 0) {
      acc.set(id, {
        nodeId: uuid(),
        previousNodeId,
      });
    } else {
      const previousId = ids[index - 1];
      if (previousId) {
        acc.set(id, {
          nodeId: uuid(),
          previousNodeId: acc.get(previousId)?.nodeId ?? null,
        });
      }
    }
    return acc;
  }, new Map<string, { nodeId: string; previousNodeId: string | null }>());
  const taskBlockTempIds = new Map();
  for (const nodeEntry of nodeIds) {
    const [_k, node] = nodeEntry;
    taskBlockTempIds.set(node.nodeId, getTempId());
  }
  let input: AddQuestionBanksToAssessmentInput[] = [];
  if (questionBankIds) {
    input = questionBankIds.map((questionBankId) => {
      const node = nodeIds.get(questionBankId);
      return {
        filter: {
          questionBankIds: [questionBankId],
        },
        questionBankNodes: [
          {
            questionBankId,
            nodeId: node!.nodeId,
            previousNodeId: node!.previousNodeId,
          },
        ],
      };
    });
  } else if (filters) {
    input = filters.map((f) => {
      const questionBankNodes = f.questionBankIds.map((questionBankId) => {
        const node = nodeIds.get(questionBankId);
        return {
          questionBankId,
          nodeId: node!.nodeId,
          previousNodeId: node!.previousNodeId,
        };
      });
      return {
        filter: f.filter,
        questionBankNodes,
      };
    });
  }

  const questionBanks = thunkAPI
    .getState()
    .questionBanks.banks.flatMap(({ questions }) =>
      questions.filter(
        (q) => q.questionBankId && ids.includes(q.questionBankId)
      )
    )
    .sort(
      (a, b) =>
        ids.findIndex((id) => a.questionBankId === id) -
        ids.findIndex((id) => b.questionBankId === id)
    );

  if (input.length > 0) {
    await client.mutate<
      AddQuestionBanksToAssessmentMutation,
      AddQuestionBanksToAssessmentMutationVariables
    >({
      mutation: AddQuestionBanksToAssessmentDocument,
      variables: {
        taskId,
        assessmentId,
        input,
      },
      // TODO: We might want to to add optimistic response when question bank
      // will support new question data model (Fields instead of Response)
      update: (cache, { data }) => {
        if (!data?.addQuestionBanksToAssessment) return;

        // blocks returned from the server with the new question fields
        const blocks = data.addQuestionBanksToAssessment;

        /**
         * The blocks which are returned from the server can be sorted
         * arbitrarily, while nodes of parsed question banks have already been
         * placed in order.
         */
        const orderedBlocks = questionBanks.flatMap((q) => {
          if (!q.questionBankId) return [];

          // find the nodeId of the question bank
          const node = nodeIds.get(q.questionBankId);
          //  find the taskBlock data from the server based on node id of the question bank
          const taskBlock = blocks.find((b) => b.nodeId === node?.nodeId);

          if (!node || !taskBlock) return [];

          return [
            {
              __typename: "TaskBlock",
              id: taskBlock.id,
              nodeId: node.nodeId,
              previousNodeId: node.previousNodeId,
              parentNodeId: null,
              quantity: 1,
              shuffle: false,
              deleted: false,
              hidden: false,
              order: null,
              orderLabel: null,
              points: q.points ?? 0,
              question: taskBlock.question,
            } satisfies TaskBlockFragment,
          ];
        });

        thunkAPI.dispatch(add({ blocks: orderedBlocks }));

        cache.modify({
          id: `Task:${taskId}`,
          fields: {
            blocks(existingBlocks = []) {
              const newBlockRefs = orderedBlocks.map((block) =>
                cache.writeFragment({
                  data: block,
                  fragment: TaskBlockFragmentDoc,
                  fragmentName: "TaskBlock",
                })
              );
              return [...existingBlocks, ...newBlockRefs];
            },
          },
        });
      },
    });
  }
});

export const addProcessingFiles = createAction(
  "questionBanks/addProcessingFiles",
  (filesCount: number) => ({ payload: { filesCount } })
);

export const addProcessedFiles = createAction(
  "questionBanks/addProcessedFiles",
  (filesCount: number) => ({ payload: { filesCount } })
);
