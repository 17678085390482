import { ReactNode } from "react";

import { colors, Icon, Spacer, Text } from "@vericus/cadmus-ui";

import { UseFormReturnType } from "@mantine/form";

import { inferLiveExamEndDate } from "@/features/task/settings/use-settings-form";
import {
  ExamTiming,
  RequirementsFragment,
  TaskFormat,
} from "@/generated/graphql";
import { useInstitutionFeaturesFragment } from "@/graphql/institution-selectors";

import * as Req from "../RequirementRows";
import { SectionProps } from "./shared";

interface ExamRequirementSection extends SectionProps {
  validationErrors?: RequirementViewErrors;
  format?: TaskFormat;
  setExamStart?: VoidFunction;
}

/**
 * Render the public section of exam requirements.
 *
 * If `enableAutoSubmission` is true, then we show this setting to the students.
 */
export function VisibleExamRequirements({
  reqs,
  diff,
  validationErrors,
  goToEditSettings,
  setExamStart,
  format,
}: ExamRequirementSection) {
  const { ldbFeatureEnabled = false } = useInstitutionFeaturesFragment();

  return (
    <div data-testid="ExamRequirementsContainer">
      <Req.ExamStart
        examType={reqs.examTiming}
        examStart={reqs.examStartDate}
        errorMessage={validationErrors?.examStartDate}
        hasChanged={diff?.examStartDate}
        setExamStart={setExamStart}
      />
      {!reqs.noExamReadingTime &&
        (!!reqs.examReadingTime || !!validationErrors?.examReadingTime) && (
          <Req.ReadingTime
            readingTime={reqs.examReadingTime}
            errorMessage={validationErrors?.examReadingTime}
            hasChanged={diff?.examReadingTime}
          />
        )}

      {(!!reqs.examWritingTime || !!validationErrors?.examWritingTime) && (
        <Req.WritingTime
          writingTime={reqs.examWritingTime}
          isWindowExam={reqs.examTiming === ExamTiming.Window}
          errorMessage={validationErrors?.examWritingTime}
          hasChanged={diff?.examWritingTime}
        />
      )}

      {reqs.examTiming === ExamTiming.Live &&
        reqs.examStartDate &&
        reqs.examWritingTime && (
          <Req.ExamEnd
            examType={reqs.examTiming}
            examEnd={inferLiveExamEndDate(reqs)}
            hasChanged={
              diff &&
              (diff.examStartDate ||
                diff.examReadingTime ||
                diff.examWritingTime)
            }
          />
        )}

      {reqs.examTiming === ExamTiming.Window && (
        <Req.ExamEnd
          examType={reqs.examTiming}
          examEnd={reqs.examEndDate}
          errorMessage={validationErrors?.examEndDate}
          hasChanged={diff?.examEndDate}
          setExamEnd={goToEditSettings}
        />
      )}

      {reqs.enableExamAutoSubmission && (
        <Req.ExamAutosubmission
          isAutosubmisisonEnabled={reqs.enableExamAutoSubmission}
          lateSubmissionMinsTimeLimit={reqs.lateSubmissionTimeLimit}
          hasChanged={diff?.enableExamAutoSubmission}
        />
      )}

      {!reqs.noWeight && !!reqs.weight && (
        <Req.Weight weight={reqs.weight} hasChanged={diff?.weight} />
      )}
      {format === TaskFormat.Classic && (
        <>
          {reqs.wordLimit !== null && !reqs.noLimit && (
            <Req.WordLimit
              wordLimit={reqs.wordLimit}
              countReferences={reqs.countReferences}
              hasChanged={diff && diff.wordLimit}
              hideIncludeReferences
            />
          )}

          {reqs.referencingStyle !== null && !reqs.noReferencing && (
            <Req.ReferencingStyle
              referencingStyle={reqs.referencingStyle}
              hasChanged={diff && diff.referencingStyle}
            />
          )}
        </>
      )}
      {ldbFeatureEnabled && reqs.enableExamLdb && (
        <Req.ExamLDB
          ldbEnabled={reqs.enableExamLdb}
          hasChanged={diff && diff.enableExamLdb}
        />
      )}
    </div>
  );
}

/**
 * Render the private section of assignment requirements (invisible to students)
 *
 * If `enableAutoSubmission` is false, then we don't show this setting to
 * the students. The idea is that we want to discourage the students from
 * working on the exam to the absolute end (beyond the original exam end date).
 */
export function HiddenExamRequirements({
  reqs,
  diff,
  validationErrors,
  goToEditSettings,
  format,
}: ExamRequirementSection) {
  return (
    <>
      <Spacer spacing={27} />
      <Text kind="headingSix" style={{ color: colors.grey600 }} as="div">
        <Icon iconName="Show" iconColor="grey500" /> Additional settings not
        visible to students
      </Text>
      <Spacer spacing={18} />

      {/* We show either the CTA or the value if the setting for feedback has been turned on */}
      {!reqs.noExamFeedbackDate && (
        <Req.ExamReturnDate
          examReturnDate={reqs.examFeedbackDate}
          hasChanged={diff?.examFeedbackDate}
          setExamFeedbackDate={goToEditSettings}
        />
      )}

      {!reqs.enableExamAutoSubmission && (
        <Req.ExamAutosubmission
          isAutosubmisisonEnabled={reqs.enableExamAutoSubmission}
          lateSubmissionMinsTimeLimit={reqs.lateSubmissionTimeLimit}
          hasChanged={diff?.enableExamAutoSubmission}
        />
      )}

      <Req.GradingService
        gradingService={reqs.gradingService}
        hasChanged={diff?.gradingService}
      />
      <Req.AnonymousMarking
        anonymousMarking={reqs.anonymousMarking}
        hasChanged={diff?.anonymousMarking}
      />
      {reqs.enableSubmissionPdf && (
        <Req.PdfSubmission hasChanged={diff?.enableSubmissionPdf} />
      )}
      {(!!reqs.maxGrade || !!validationErrors?.maxGrade) &&
        reqs.gradingService === "turnitin" && (
          <Req.MaxGrade
            maxGrade={reqs.maxGrade}
            errorMessage={validationErrors?.maxGrade}
            hasChanged={diff?.maxGrade}
            format={format}
          />
        )}
    </>
  );
}

type ValidationError = ReactNode;

type RequirementViewFieldsWithErrors =
  | "examStartDate"
  | "examEndDate"
  | "maxGrade"
  | "examReadingTime"
  | "examWritingTime";

export type RequirementViewErrors = Record<
  RequirementViewFieldsWithErrors,
  ValidationError
>;

export const useExamRequirementErrors = (
  settingsForm: UseFormReturnType<RequirementsFragment>
): RequirementViewErrors => {
  const {
    examStartDate,
    examEndDate,
    maxGrade,
    examReadingTime,
    examWritingTime,
  } = settingsForm.errors;
  return {
    examStartDate: examStartDate
      ? "Please add exam start date and time"
      : undefined,
    examEndDate: examEndDate ? "Please add exam end date and time" : undefined,
    examReadingTime: examReadingTime,
    examWritingTime: examWritingTime,
    maxGrade: maxGrade ? "Please add total points" : undefined,
  };
};
