import {
  FeedbackReleaseRuleFragment,
  QuestionBodyFragment,
  QuestionOutcomeScoreFragment,
  QuestionRubricFragment,
  QuestionType,
  WorkOutcomeScoreFragment,
} from "@/generated/graphql";

/**
 * The names of columns for question list on moderation page
 */
export enum QuestionColumnId {
  Order = "#",
  Type = "Type",
  Question = "Question",
  ResponseRate = "Response",
  SuccessRate = "Success Rate",
}

/**
 * Calculated response and success rates for a question based on the question
 * metrics counts which are fetched from the server.
 */
export type Rates = {
  /** The percentage of responses received for the question. */
  responseRate: number;
  /** The percentage of responses that equals to max score of the question */
  successRate: number | null;
};

/*
 * This type represents a question that includes information about marking
 * changes, the question's body, associated rubric, and success/failure rates.
 */
export interface ScoredQuestion {
  /** Question ID. */
  questionId: string;
  /** Question Type */
  questionType: QuestionType;
  /** Question short prompt */
  shortPrompt: string | null;
  /** Question is markable and automarkable */
  automarked: boolean;
  /** Total score awarded to the answer to the question. */
  score: number | null;
  /** Total score possible. */
  maxScore: number | null;
  /** Tree node ID in the question tree. */
  nodeId: string;
  /** If the question is a child node in the question tree. */
  parentNodeId: string | null;
  /** Label suffix indicating question number. */
  orderLabel: string | null;
  /** Changes were made by the marker to this question. */
  hasMarkerChanges: boolean;
  /** Full question body */
  body: QuestionBodyFragment | null;
  /** Response rates */
  rates: Rates | null;
  /** If the question is markable, its marking rubric. */
  rubric?: QuestionRubricFragment;
}

/**
 * The state of sorting for the question list.
 */
export type QuestionSortingState = {
  column: QuestionColumnId | null;
  order: "asc" | "desc" | null;
};

/**
 * The state of filtering for the question list.
 */
export type FilterState = {
  selectedQuestionIds: string[];
  selectedQuestionTypes: QuestionType[];
  showOnlyWithChanges: boolean;
};

/**
 * Distribution of responses for a question with their percentages.
 */
export interface ResponseDistribution {
  acceptedResponsesPercent: number;
  mostCommonIncorrect: {
    response: string | null;
    percent: number;
  };
  restResponsesPercent: number;
}

/**
 * The data to be used to generate the overall metrics and chart.
 */
export interface ChartData {
  id: string;
  /** The label for x axis */
  label: string;
  /** Success rate. Can be null for questions that can't be automarked */
  percentage: number | null;
  /** If the data is highlighted */
  isHighlighted: boolean;
}

/**
 * Work outcome ready for moderation with a complete marking.
 */
export interface WorkOutcomeSummary {
  id: string;
  workId: string;
  score: number | null;
  automarkScore: number | null;
  maxScore: number;
  markerModifier: number | null;
  moderatorModifier: number | null;
  feedbackReleasedTimestamp: string | null;
  finalScore: number | null;
  finalScorePenalty: number | null;
}

/**
 * Represents the score of a work outcome along with the associated questions
 * and their outcomes. This data is used to generate a detailed metrics graph
 * showing scores of both students and questions.
 */
export type WorkOutcomeScoreWithQuestions = WorkOutcomeScoreFragment & {
  questionOutcomes: QuestionOutcomeScoreFragment[];
};

export interface OutcomeMetrics {
  passRate: number | null;
  averageScore: number | null;
  highestScore: number | null;
  lowestScore: number | null;
  stdDeviation: number | null;
}

type FeedbackReleaseLocalState = {
  /** Ids of enrollments that are incldued in this rule */
  appliedEnrollmentIds: string[];
  /**
   * All enrollments that are available to this rule. In the other words,
   * this is the excluded enrollment ids of previous rules
   */
  availableEnrollmentIds: string[];
};

/** Type of New feedback release rule that is not saved yet */
export type LocalFeedbackReleaseRule = Omit<
  FeedbackReleaseRuleFragment,
  "scheduledAt"
> & {
  /**
   * Whether this is a newly created local feedbck release rule
   */
  isNew: boolean;
  /**
   * Scheduled release time.
   *
   * Variants:
   *
   *   - `null`: release now
   *   - `undefined` (local only): scheduled release, release time is not yet set
   *   - `string`: scheduled release time
   */
  scheduledAt?: string | null;
};

/** State of feedback release rule */
export type FeedbackReleaseRuleState = LocalFeedbackReleaseRule &
  FeedbackReleaseLocalState;
