import { styled } from "styled-components";

import {
  Card,
  CardSkeleton,
  ParagraphSkeleton,
  SectionLabel,
  Skeleton,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import { RequirementsFragment, ResourceFragment } from "@/generated/graphql";
import { TaskEditorContentReadOnly } from "@/ui/task/editor";
import { RequirementView } from "@/ui/task/requirements";
import { ResourceListReadOnly } from "@/ui/task/resources";

export interface DoubleColumnPreviewProps {
  /** The title for the template or past assessment */
  title: string;
  /** Requirements for the assessment */
  requirements: RequirementsFragment;
  /** Editor content */
  content: string;
  isHtmlContent?: boolean;
  resources: ResourceFragment[];
  /** Whether a template is being previewed. */
  isTemplatePreview?: boolean;
}

export const DoubleColumnPreview = ({
  title,
  requirements,
  content,
  isHtmlContent,
  resources,
  isTemplatePreview,
}: DoubleColumnPreviewProps) => {
  return (
    <PaddedCard>
      <AssessmentTitleText kind="displayTwo">{title}</AssessmentTitleText>
      <TwoColumns>
        <Left>
          <div>
            <RequirementView
              requirements={requirements}
              showHidden
              shouldShowEmptyState={
                isTemplatePreview || !requirements.hasChanged
              }
            />
          </div>
          <div>
            <ResourceListReadOnly resources={resources} />
          </div>
        </Left>
        <Right>
          <TaskLabel title="Task" />
          <TaskEditorContentReadOnly
            content={content}
            isHtmlContent={isHtmlContent}
          />
        </Right>
      </TwoColumns>
    </PaddedCard>
  );
};

export const DoubleColumnPreviewSkeleton = () => {
  return (
    <PaddedCard>
      <Skeleton width={25} />
      <Spacer spacing={60} />
      <TwoColumns>
        <Left>
          <ParagraphSkeleton />

          <div>
            <Skeleton width={60} />
            <CardSkeleton heightPx={120} />
          </div>
        </Left>
        <Right>
          <Skeleton width={80} />
          <CardSkeleton heightPx={184} />
          <CardSkeleton heightPx={184} />
          <Skeleton width={80} />
          <CardSkeleton heightPx={184} />
          <CardSkeleton heightPx={184} />
        </Right>
      </TwoColumns>
    </PaddedCard>
  );
};

const AssessmentTitleText = styled(Text)`
  margin-bottom: 54px;
  padding: 0;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: [left] 1fr [right] 1fr;
`;
const Left = styled.div`
  grid-area: left;
  display: flex;
  flex-direction: column;
  gap: 38px;
  padding-right: 20%;
  min-width: 250px;
`;

const Right = styled.div`
  grid-area: right;
`;

const TaskLabel = styled(SectionLabel)`
  padding: 0 45px 27px 45px;
  max-width: 800px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: -63px;
`;

const PaddedCard = styled(Card)`
  box-sizing: border-box;
  padding: 64px 70px;
  height: 100%;
  max-height: 100%;
  width: 100%;
`;
