import { combineReducers } from "@reduxjs/toolkit";
import { classReducer } from "data/class";
import { errorReducer } from "data/error";
import { localFlagsReducer } from "data/localFlags";

import { taskReducer } from "@/data/task";
import { markReducer } from "@/features/marking";
import { moderateReducer } from "@/features/moderation";
import { taskLayoutReducer } from "@/features/multi-format/task-layout";
import { taskValidationReducer } from "@/features/multi-format/task-validation";
import { questionBanksReducer } from "@/features/question-bank-file-import/data";

import { templatesReducer } from "./templates";

export const rootReducer = combineReducers({
  error: errorReducer,
  task: taskReducer,
  templates: templatesReducer,
  class: classReducer,
  localFlags: localFlagsReducer,
  taskLayout: taskLayoutReducer,
  taskValidation: taskValidationReducer,
  questionBanks: questionBanksReducer,
  mark: markReducer,
  moderate: moderateReducer,
});
