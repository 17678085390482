import { CategorySearchSelect } from "./category-search-select";
import { useQuestionBanksCategoryContext } from "./context";

interface Props {
  questionId: string;
  category?: string;
}

export const QuestionCategoryAssignCell = ({ questionId, category }: Props) => {
  const { categories, onAdd, onSelect } = useQuestionBanksCategoryContext();
  return (
    <CategorySearchSelect
      items={categories ?? []}
      selectedItems={category ? [category] : []}
      onAdd={onAdd}
      onSelect={(category) => onSelect?.(category, undefined, [questionId])}
    />
  );
};
