import { useCallback } from "react";

import { produce } from "immer";

import {
  ClassDocument,
  ClassQuery,
  StudentSettingsInput,
  useUpdateStudentSettingsMutation,
} from "@/generated/graphql";

/**
 * Hook that returns a callback, used when the user intends to submit
 * the Special Considerations form.
 */
export const useUpdateExamSpecialConsiderations = () => {
  const [mutate] = useUpdateStudentSettingsMutation();

  return useCallback(
    async (
      assessmentId: string,
      studentSettingsInputs: StudentSettingsInput[]
    ) => {
      await mutate({
        variables: {
          assessmentId: assessmentId,
          settings: studentSettingsInputs,
        },
        update: (proxy, { data }) => {
          if (data?.updateStudentSettings) {
            const cacheData = proxy.readQuery<ClassQuery>({
              query: ClassDocument,
              variables: { assessmentId },
            });
            if (cacheData) {
              // Using immer to update the cache
              const newData = produce(cacheData, (draft) => {
                for (const workSettings of data.updateStudentSettings) {
                  const studentId = workSettings?.studentId;
                  const enrollmentIndex = draft.class.students.findIndex(
                    ({ user: { id } }) => id === studentId
                  );
                  if (enrollmentIndex !== -1) {
                    draft.class.students[enrollmentIndex]!.workSettings =
                      workSettings;
                  }
                }
              });

              proxy.writeQuery<ClassQuery>({
                query: ClassDocument,
                variables: { assessmentId },
                data: newData,
              });
            }
          }
        },
      });
    },
    [mutate]
  );
};
