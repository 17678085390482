import { QuestionType, SimpleChoiceFragment } from "@/generated/graphql";

import { InteractionKind } from "./interaction-kind-utils";
import { QuestionFormField } from "./state";

export const DEFAULT_MATCH_SIMILARITY = 80;
export const DEFAULT_FIELD_IDENTIFIER = "RESPONSE";

export function defaultResponse(): QuestionFormField["response"] {
  return {
    partialScoring: null,
    matchSimilarity: null,
    correctValues: [],
    caseSensitive: false,
  };
}

export function defaultInteraction(): QuestionFormField["interaction"] {
  return {
    choices: [],
    expectedLength: null,
    wordLimit: null,
    kind: null,
  };
}

export function trueFalseChoices(): SimpleChoiceFragment[] {
  return [
    {
      identifier: "true",
      content: "True",
    },
    {
      identifier: "false",
      content: "False",
    },
  ];
}

export function defaultQuestionFields(
  questionType: QuestionType
): QuestionFormField[] {
  switch (questionType) {
    case QuestionType.Blanks:
    case QuestionType.Overview:
    case QuestionType.Section:
    case QuestionType.Sub:
      return [];
    default:
      return [defaultFieldForQuestion(questionType)];
  }
}

export function defaultFieldForQuestion(
  questionType: QuestionType
): QuestionFormField {
  const response = defaultResponse();
  const interaction = defaultInteraction();

  switch (questionType) {
    case QuestionType.Truefalse:
      return {
        identifier: DEFAULT_FIELD_IDENTIFIER,
        response,
        interaction: {
          ...interaction,
          choices: trueFalseChoices(),
          kind: InteractionKind.Choice,
        },
      };

    case QuestionType.Short:
      return {
        identifier: DEFAULT_FIELD_IDENTIFIER,
        response: {
          ...response,
          matchSimilarity: DEFAULT_MATCH_SIMILARITY,
        },
        interaction: {
          ...interaction,
          kind: InteractionKind.TextEntry,
        },
      };

    case QuestionType.Blanks:
      return {
        identifier: DEFAULT_FIELD_IDENTIFIER,
        response: {
          ...response,
          matchSimilarity: DEFAULT_MATCH_SIMILARITY,
        },
        interaction: {
          ...interaction,
          kind: InteractionKind.TextEntry,
        },
      };

    case QuestionType.Extended:
      return {
        identifier: DEFAULT_FIELD_IDENTIFIER,
        response,
        interaction: {
          ...interaction,
          kind: InteractionKind.Extended,
        },
      };

    case QuestionType.Mcq:
      return {
        identifier: DEFAULT_FIELD_IDENTIFIER,
        response,
        interaction: {
          ...interaction,
          kind: InteractionKind.Choice,
        },
      };

    default: {
      return {
        identifier: DEFAULT_FIELD_IDENTIFIER,
        response,
        interaction,
      };
    }
  }
}
