import { ReactNode } from "react";
import { styled } from "styled-components";

import { Anchored, colors, LinkButton, Text } from "@vericus/cadmus-ui";

export interface SettingInfoCardProps {
  /**
   * Card background color.
   * @default "cyan"
   */
  backgroundColor?: "cyan" | "blue" | "red";
  /*
   * React children
   */
  children: ReactNode;
  /**
   * Whether a small NEW indicator should be shown inside the card.
   * @default true
   */
  showNewPill?: boolean;
  /**
   * If given, a Learn more link will be rendered.
   */
  learnMoreLink?: string;
}

/**
 * Additional information on a Setting with an optional "NEW" pill.
 */
export function SettingInfoCard(props: SettingInfoCardProps) {
  const {
    backgroundColor = "cyan",
    children,
    showNewPill = true,
    learnMoreLink,
  } = props;
  return (
    <Container
      backgroundColor={
        backgroundColor === "cyan"
          ? colors.cyan200
          : backgroundColor === "red"
            ? colors.functionalFailBg
            : colors.blue200
      }
    >
      {showNewPill && <NewPill />}
      <Text kind="bodySm" color="blue800" as="span">
        {children}
      </Text>
      {learnMoreLink && (
        <Anchored newTab href={learnMoreLink}>
          <LinkButton>Learn more</LinkButton>
        </Anchored>
      )}
    </Container>
  );
}

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${(p) => p.backgroundColor};
  border-radius: 4px;
  padding: 12px 20px;
`;

const NewPill = () => (
  <PillContainer>
    <Text kind="label" style={{ color: colors.blue500 }} inline>
      New
    </Text>
  </PillContainer>
);

const PillContainer = styled.div`
  background-color: ${colors.white100};
  border: 1px solid ${colors.blue500};
  border-radius: 8px;
  padding: 2px 8px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;
