import { ReactNode, useMemo } from "react";

import {
  CreateSubQuestionIcon,
  FillInBlanksIcon,
  LongAnswerIcon,
  MultipleChoiceIcon,
  ShortAnswerIcon,
  TrueFalseIcon,
} from "@vericus/cadmus-icons";
import { DropdownMenu, SelectButton } from "@vericus/cadmus-ui";

import { QuestionType } from "@/generated/graphql";

export type QuestionBlockType = Exclude<
  QuestionType,
  QuestionType.Overview | QuestionType.Section
>;

export const QUESTION_CONFIG: Record<
  QuestionBlockType,
  { text: string; icon: ReactNode; questionType: QuestionBlockType }
> = {
  [QuestionType.Mcq]: {
    text: "Multiple choice",
    icon: <MultipleChoiceIcon label="Multiple choice" size={24} />,
    questionType: QuestionType.Mcq,
  },
  [QuestionType.Short]: {
    text: "Short answer",
    questionType: QuestionType.Short,
    icon: <ShortAnswerIcon label="Short answer" size={24} />,
  },
  [QuestionType.Extended]: {
    text: "Extended response",
    questionType: QuestionType.Extended,
    icon: <LongAnswerIcon label="Extended response" size={24} />,
  },
  [QuestionType.Blanks]: {
    text: "Fill in the blanks",
    questionType: QuestionType.Blanks,
    icon: <FillInBlanksIcon label="Fill in the blanks" size={24} />,
  },
  [QuestionType.Truefalse]: {
    text: "True/false",
    questionType: QuestionType.Truefalse,
    icon: <TrueFalseIcon label="True/false" size={24} />,
  },
  [QuestionType.Sub]: {
    text: "Sub-questions",
    questionType: QuestionType.Sub,
    icon: <CreateSubQuestionIcon label="Sub-questions" size={24} />,
  },
};

interface QuestionTypeDropdownMenuProps {
  questionType?: QuestionBlockType;
  triggerButton?: (text: string) => ReactNode;
  onSelectQuestionType: (questionType: QuestionBlockType) => void;
  excludedKeys?: string[];
}

/**
 *  The dropdown menu of question types selection
 */
export const QuestionTypeDropdownMenu = ({
  questionType,
  triggerButton,
  onSelectQuestionType,
  excludedKeys = [],
}: QuestionTypeDropdownMenuProps) => {
  const subQuestionIncluded = useMemo(
    () => !excludedKeys?.includes(QuestionType.Sub),
    [excludedKeys]
  );
  const otherQuestions = useMemo(() => {
    const result: Record<
      string,
      { text: string; icon: ReactNode; questionType: QuestionBlockType }
    > = {};

    // If sub question is included, will be fixed on the top
    // So need to be filtered out from the rest
    const keysToExclude = [QuestionType.Sub, ...excludedKeys];
    Object.entries(QUESTION_CONFIG).map(([type, config]) => {
      if (!keysToExclude.includes(type)) result[type] = config;
      return null;
    });

    return result;
  }, [excludedKeys]);
  const selectedOption = Object.values(QUESTION_CONFIG).find(
    (availableQuestion) => availableQuestion.questionType === questionType
  );

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        {triggerButton ? (
          triggerButton(selectedOption?.text ?? "Select question type")
        ) : (
          <SelectButton>
            {selectedOption?.text ?? "Select question type"}
          </SelectButton>
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {subQuestionIncluded && (
          <>
            <DropdownMenu.Item
              content={QUESTION_CONFIG[QuestionType.Sub].text}
              leftSection={QUESTION_CONFIG[QuestionType.Sub].icon}
              selected={questionType === QuestionType.Sub}
              onSelect={() => onSelectQuestionType(QuestionType.Sub)}
            />
            <DropdownMenu.Separator />
          </>
        )}
        {Object.values(otherQuestions).map((option) => (
          <DropdownMenu.Item
            key={option.text}
            content={option.text}
            leftSection={option.icon}
            selected={questionType === option.questionType}
            onSelect={() => onSelectQuestionType(option.questionType)}
          />
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
