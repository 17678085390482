import { Button, Modal, Text } from "@vericus/cadmus-ui";

import ReportConfirmationImg from "@/assets/class/report-confirmation.png";
import { FeedbackLevel, UserFragment } from "@/generated/graphql";

import { RuleAppliedStudentsList } from "../rule-applied-students-list";
import * as styles from "./feedback-release-confirm-modal.css";

interface Props {
  releasedStudentsCount: number;
  scheduledStudentsCount: number;
  withholdStudentsCount: number;
  excludedStudents: Pick<UserFragment, "id" | "name">[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  /** Feedback visibility */
  visibility: FeedbackLevel;
  /** Confirm release feedback for more students */
  onConfirmReleaseMore: () => void;
  /** Go to selectively release dashboard */
  onGoToDashbord: () => void;
}

/** Confirmation modal of feedback release  */
export function FeedbackReleaseConfirmModal(props: Props) {
  return (
    <Modal.Root
      open={props.open}
      onOpenChange={(open) => props.onOpenChange?.(open)}
    >
      <Modal.Content width={846}>
        <div className={styles.header}>
          <img className={styles.icon} src={ReportConfirmationImg} alt="" />
          <div className={styles.title}>
            <Text kind="headingFour" as="span">
              Feedback is on its way!
            </Text>
            <Text kind="bodyMd" color="neutralBlack300">
              {visibilityDescription(props.visibility)}
            </Text>
          </div>
        </div>
        <div className={styles.body}>
          {props.withholdStudentsCount === 0 &&
          props.scheduledStudentsCount === 0 ? (
            <>
              <div className={styles.releaseAllText}>
                <Text kind="lead" textAlign="center">
                  Feedback has been successfully released for all{" "}
                  <strong>{props.releasedStudentsCount}</strong> students
                </Text>
              </div>
              <Button kind="secondary" onClick={props.onGoToDashbord}>
                go back to dashboard
              </Button>
            </>
          ) : (
            <>
              <Text kind="lead" textAlign="left">
                Feedback has been successfully released for{" "}
                {props.releasedStudentsCount && (
                  <>
                    <strong>{props.releasedStudentsCount}</strong>{" "}
                    {plural(props.releasedStudentsCount, "student", "students")}
                  </>
                )}
                . Feedback is has been scheduled for{" "}
                <strong>{props.scheduledStudentsCount}</strong>{" "}
                {plural(
                  props.scheduledStudentsCount,
                  "student",
                  "other students"
                )}
                . You chose to withhold feedback for{" "}
                {props.withholdStudentsCount && (
                  <>
                    <strong>{props.withholdStudentsCount}</strong>{" "}
                    {plural(props.withholdStudentsCount, "student", "students")}
                  </>
                )}
                .
              </Text>
              <RuleAppliedStudentsList
                className={styles.excludeStudentsList}
                students={props.excludedStudents}
                readonly
              />
              <div className={styles.selectiveReleaseButtonGroup}>
                <Button kind="outline" onClick={props.onConfirmReleaseMore}>
                  release for more students
                </Button>
                <Button kind="secondary" onClick={props.onGoToDashbord}>
                  go back to dashboard
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal.Content>
    </Modal.Root>
  );
}

function plural(count: number, singular: string, plural: string) {
  return count === 1 ? singular : plural;
}
function visibilityDescription(visibility: FeedbackLevel) {
  switch (visibility) {
    case FeedbackLevel.Mark:
      return "Students will see all answers, comments and marks";
    case FeedbackLevel.Answer:
      return "Students will see their marks";
    case FeedbackLevel.Feedback:
      return "Students will see their answers and comments";
  }
}
