import { useState } from "react";
import { styled } from "styled-components";

import {
  colors,
  Divider,
  Pill,
  ScrollArea,
  Switch,
  Text,
} from "@vericus/cadmus-ui";

import { QuestionType } from "@/generated/graphql";

import { formatDecimalNumber } from "../../utils";
import {
  getSectionMaxScore,
  getSectionQuestion,
  getSectionScore,
  getSubQuestionMaxScore,
  getSubquestionScore,
} from "../../utils/question-list-selectors";
import { NavigationFilter } from "./navigation-filter";
import { NavigationItem } from "./navigation-item";
import * as styles from "./navigation-list.css";
import { QuestionItem } from "./types";

interface Props {
  /** Global list of questions and their outcome details */
  items: QuestionItem[];
  /** Work outcome score */
  score: number | null;
  /** Max score possible for the Work */
  maxScore: number | null;
  /** IDs for question displayed */
  filteredQuestionIds: string[];
  /** Set the IDS for filtered questions */
  setFilteredQuestionIds: (questionIds: string[]) => void;
}

/**
 * Navigation list component for the marking page
 *
 * This component displays the list of Questions with Scores,
 * and provides the ability to filter the questions based on the
 * question and the automarked status.
 */
export function NavigationList(props: Props) {
  const {
    items,
    score,
    maxScore,
    filteredQuestionIds,
    setFilteredQuestionIds,
  } = props;

  const [showUnmarkOnly, setShowUnmarkOnly] = useState(false);
  const [foldedQuestionIds, setFoldedQuestionIds] = useState<string[]>([]);

  const filteredItems =
    filteredQuestionIds.length > 0
      ? items.filter((item) => filteredQuestionIds.includes(item.questionId))
      : [];

  return (
    <ScrollArea className={styles.root}>
      <div className={styles.content}>
        <SummaryContainer href="#mark-summary">
          <Text kind="systemMd" color="lilac500">
            SUMMARY
          </Text>
          <div className={styles.points}>
            <Pill color={colors.bgrey400} basic={true}>
              <Text
                kind={"systemMd"}
              >{`${formatDecimalNumber(score ?? 0)}/${maxScore}`}</Text>
            </Pill>
          </div>
        </SummaryContainer>
        <Divider />
        <FilterContainer>
          <NavigationFilter
            items={items}
            filteredQuestionIds={filteredQuestionIds}
            setFilteredQuestionIds={setFilteredQuestionIds}
            setShowUnMarkedOnly={(showUnmarkOnly) =>
              setShowUnmarkOnly(showUnmarkOnly)
            }
            showUnMarkedOnly={showUnmarkOnly}
          />
          <Switch
            content="Un-marked only"
            checked={showUnmarkOnly}
            onChange={() => {
              setShowUnmarkOnly(!showUnmarkOnly);
              if (!showUnmarkOnly) {
                const unMarkedQuestionIds = items
                  .filter(
                    (item) =>
                      item.score === null &&
                      item.questionType !== QuestionType.Overview &&
                      item.questionType !== QuestionType.Section &&
                      item.questionType !== QuestionType.Sub
                  )
                  .map((unmarkItem) => unmarkItem.questionId);
                setFilteredQuestionIds(
                  filteredQuestionIds.filter((filterQuestionId) =>
                    unMarkedQuestionIds.includes(filterQuestionId)
                  )
                );
              } else {
                setFilteredQuestionIds(items.map((item) => item.questionId));
              }
            }}
          />
        </FilterContainer>
        <Divider />
        {filteredItems.map((item) => {
          const { score: _score, maxScore: _maxScore, ...rest } = item;
          let score = _score;
          let maxScore = _maxScore;

          if (rest.questionType === QuestionType.Sub) {
            score = getSubquestionScore(item.nodeId, props.items);
            maxScore = getSubQuestionMaxScore(item.nodeId, props.items);
          } else if (rest.questionType === QuestionType.Section) {
            score = getSectionScore(item.questionId, props.items);
            maxScore = getSectionMaxScore(item.questionId, props.items);
          }

          if (foldedQuestionIds.includes(item.questionId)) return null;

          return (
            <NavigationItem
              key={item.questionId}
              score={score}
              maxScore={maxScore}
              onFoldSection={() =>
                setFoldedQuestionIds((preState) => [
                  ...preState,
                  ...getSectionQuestion(item.questionId, items).map(
                    (question) => question.questionId
                  ),
                ])
              }
              onUnfoldSection={() => {
                const foldedQuestionIds = getSectionQuestion(
                  item.questionId,
                  items
                ).map((question) => question.questionId);
                setFoldedQuestionIds((preState) =>
                  preState.filter(
                    (questionId) => !foldedQuestionIds.includes(questionId)
                  )
                );
              }}
              {...rest}
            />
          );
        })}
      </div>
    </ScrollArea>
  );
}

const SummaryContainer = styled.a`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  text-decoration: none;
  &:hover {
    background: ${(p) => p.theme.colors.lilac200};
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 16px;
`;
