import { useCallback } from "react";

import { ReleasedRequirementsFragment } from "@/generated/graphql";
import { isCadmusGraderEnabled, selectHasDrafting } from "@/graphql/sheet-selectors";
import { useRootNavigate } from "@/router/routing";

import { NavBar } from "./NavBar";
import { DisplayedTabs, TabId } from "./types";
import { useSelectedTab } from "./useSelectedTab";

interface Props {
  /** Determine displayed tabs based on current Instruction Sheet */
  sheet: ReleasedRequirementsFragment | null;
}

const ALL_TABS: DisplayedTabs[] = [
  {
    id: "instructions",
    display: "Instructions",
    dataComponent: "NavBar.Instructions",
  },
  { id: "progress", display: "Progress", dataComponent: "NavBar.Progress" },
  { id: "drafts", display: "Drafts", dataComponent: "NavBar.Drafts" },
  { id: "marking", display: "Marking", dataComponent: "NavBar.Marking" },
  {
    id: "moderation",
    display: "Moderation",
    dataComponent: "NavBar.Moderation",
  },
  {
    id: "integrity-assurance",
    display: "Integrity assurance",
    dataComponent: "NavBar.IntegrityAssurance",
  },
  { id: "insights", display: "Insights", dataComponent: "NavBar.Insights" },
];

/** NavBar component connected to react-router context */
export const ConnectedNavBar = ({ sheet }: Props) => {
  // Show drafts navigation
  const withDrafts = sheet ? selectHasDrafting(sheet) : false;
  const withModeration = sheet ? isCadmusGraderEnabled(sheet) : false;

  const displayedTabs = ALL_TABS.filter((t) => {
    if (t.id === "drafts") return withDrafts;
    if (t.id === "moderation") return withModeration;
    return true;
  });

  const selectedTab = useSelectedTab();

  const rootNavigate = useRootNavigate();

  const onNewTabSelected = useCallback(
    (tab: TabId) => {
      if (tab === selectedTab) return; // skip navigation if already there
      switch (tab) {
        case "instructions":
          rootNavigate("/task");
          break;
        case "drafts":
          rootNavigate("/class/drafts");
          break;
        case "integrity-assurance":
          rootNavigate("/integrity-assurance");
          break;
        case "insights":
          rootNavigate("/insights");
          break;
        case "progress":
          rootNavigate("/class/progress");
          break;
        case "marking":
          rootNavigate("/class/marking");
          break;
        case "moderation":
          rootNavigate("/grader/moderate");
          break;
      }
    },
    [rootNavigate, selectedTab]
  );

  return (
    <NavBar
      selectedTab={selectedTab}
      onNewTabSelected={onNewTabSelected}
      displayedTabs={displayedTabs}
    />
  );
};
