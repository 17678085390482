import { useCallback } from "react";

import NiceModal from "@ebay/nice-modal-react";

import {
  AssessmentFragment,
  EnrollmentFragment,
  GroupFragment,
  ReleasedRequirementsFragment,
} from "@/generated/graphql";
import { ExtensionType } from "@/ui/class/progress/pages";
import {
  ClearExtensionIntent,
  ConnectedClearExtensionConfirmModal,
  ConnectedClearExtensionConfirmModalProps,
} from "@/ui/StudentLists/ClearExtensionConfirmModal";
import {
  ConnectedStudentSettingsPageModal,
  ConnectedStudentSettingsPageModalProps,
  ExtensionGrantIntent,
} from "@/ui/StudentLists/StudentSettingsPageModal";

/**
 * Returns functions to handle extension granting and clearing.
 */
export function useExtensionHandlers(
  assessment: Pick<AssessmentFragment, "id" | "name">,
  instructionSheet: ReleasedRequirementsFragment | null,
  groups: GroupFragment[]
) {
  const clearExtensions = useCallback(
    (enrollments: EnrollmentFragment[], type: ExtensionType) => {
      const intent: ClearExtensionIntent = { enrollments, type };
      NiceModal.show(ConnectedClearExtensionConfirmModal, {
        intent,
        instructionSheet,
        assessment,
      } satisfies ConnectedClearExtensionConfirmModalProps);
    },
    [instructionSheet, assessment]
  );

  const editExtensions = useCallback(
    (
      enrollments: EnrollmentFragment[],
      type: ExtensionType,
      selectedAll?: boolean
    ) => {
      const intent: ExtensionGrantIntent = { enrollments, type };
      if (!instructionSheet) return;
      NiceModal.show(ConnectedStudentSettingsPageModal, {
        assessment,
        enrollments,
        groups,
        instructionSheet,
        intent,
        selectedAll,
      } satisfies ConnectedStudentSettingsPageModalProps);
    },
    [instructionSheet, assessment, groups]
  );

  return { clearExtensions, editExtensions };
}
