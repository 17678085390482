import { memo } from "react";
import { styled } from "styled-components";

import {
  CadmusEditorContent,
  Editor,
} from "@vericus/cadmus-editor-prosemirror";

import { EditorWrapper } from "../../components/editor-wrapper";
import { BodyDiv } from "../styles";

interface ShortAnswerProps {
  /** Rich text content */
  editor: Editor | null;
  /** Error when question promptDoc less than 20 characters */
  promptDocTooShort?: boolean;
}

/**
 * Extended answer question body
 */
const ExtendedQuestionBodyComponent = ({
  editor,
  promptDocTooShort,
}: ShortAnswerProps) => {
  return (
    <Root>
      <McqEditorWrapper hasError={promptDocTooShort}>
        <CadmusEditorContent editor={editor} />
      </McqEditorWrapper>
    </Root>
  );
};

export const ExtendedQuestionBody = memo(ExtendedQuestionBodyComponent);

const Root = styled(BodyDiv)`
  background: ${(p) => p.theme.colors.white100};
  position: relative;
`;

const McqEditorWrapper = styled(EditorWrapper)`
  margin: 16px 0px;
`;
