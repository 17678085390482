import { styled } from "styled-components";

import {
  Button,
  desktop,
  mobile,
  Spacer,
  tablet,
  Text,
} from "@vericus/cadmus-ui";

import RosterSyncImg from "@/assets/class/roster-sync.png";

interface Props {
  /** Trigger roster sync */
  syncClassList: () => void;
  mainText: string;
  secondaryText?: string;
}

export const EmptyTable = (props: Props) => {
  const { mainText, secondaryText, syncClassList } = props;
  return (
    <Root>
      <Left>
        <Img src={RosterSyncImg} />
      </Left>
      <Right>
        <Text kind="headingFour" color="navy500">
          {mainText}
        </Text>
        {secondaryText && (
          <Text kind="bodyMd" color="navy400">
            {secondaryText}
          </Text>
        )}
        <Spacer spacing={12} />
        <Button kind="secondary" onClick={syncClassList}>
          Sync Class List
        </Button>
      </Right>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${(p) => p.theme.colors.bgrey300};
  height: 100%;
  min-height: 380px;

  display: flex;
  flex-direction: row;
  gap: 24px;

  margin: 24px 45px;
  ${desktop`
    padding: 0 96px;
  `}
  ${tablet`
    padding: 0 48px;
  `}
  ${mobile`
    padding: 0 24px;
  `}
`;

const Img = styled.img`
  width: 100%;
  max-width: 320px;
  max-height: 320px;
`;

const Left = styled.div`
  box-sizing: border-box;
  display: none;
  ${desktop`
    display: flex;
  `}
  flex-basis: 50%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const Right = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
`;
