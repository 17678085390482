import { CSSProperties, forwardRef, ReactNode } from "react";
import { styled } from "styled-components";

import { ErrorMessage } from "@vericus/cadmus-ui";

import {
  DateValue,
  DateTimePicker as MantineDateTimePicker,
  DateTimePickerProps as MantineDateTimePickerProps,
} from "@mantine/dates";
import { startOfMinute } from "date-fns";

export interface DatePickerProps
  extends Omit<MantineDateTimePickerProps, "onChange"> {
  style?: CSSProperties;
  errorMessage?: ReactNode;
  /**
   * Callback that is called when the date is changed
   *
   * Note: because the input UI doesn't allow for seconds, the seconds will be clipped to 0.
   * @param newDate
   */
  onChange: (newDate: Date) => void;
  /**
   * The width of the date picker. Defaults to 192px. Can specify a percentage value too (eg. "100%")
   */
  width?: string | number;
}

const fieldWidthPx = 192;

/**
 * Remove the seconds for a date, e.g. "[...] 12:39:23" becomes "[...] 12:39:00".
 *
 * This is a simple alias for testing.
 **/
export const clipSeconds = startOfMinute;

export const DateTimePicker = forwardRef<HTMLButtonElement, DatePickerProps>(
  (
    {
      value,
      style,
      errorMessage,
      minDate,
      width = fieldWidthPx,
      onChange,
      ...props
    },
    ref
  ) => {
    return (
      <Container style={style} hasError={!!errorMessage} width={width}>
        <MantineDateTimePicker
          ref={ref}
          placeholder="select date and time"
          clearable={false}
          minDate={minDate ?? new Date()}
          display="inline-block"
          value={value}
          styles={{
            root: {
              width: width,
            },
          }}
          valueFormat="DD MMM YYYY [at] hh:mm A"
          {...props}
          onChange={(date: DateValue) => {
            if (date) {
              onChange(clipSeconds(date));
            }
          }}
        />
        {errorMessage && (
          <ErrorMessage fullWidth position="relative">
            {errorMessage}
          </ErrorMessage>
        )}
      </Container>
    );
  }
);

const Container = styled.div<{ hasError?: boolean; width: string | number }>`
  width: ${(props) =>
    typeof props.width === "string" ? props.width : `${props.width}px`};
  display: block;
  vertical-align: ${(p) => (p.hasError ? "top" : "middle")};
`;
