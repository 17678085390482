import { forwardRef, ReactNode } from "react";
import { styled } from "styled-components";

import { IconName } from "@vericus/cadmus-icons";
import { Button, Icon, typography } from "@vericus/cadmus-ui";

import {
  ComboboxItem,
  MultiSelect as MantineMultiSelect,
  MultiSelectProps as MantineMultiSelectProps,
  OptionsFilter,
} from "@mantine/core";

interface MultiSelectProps extends MantineMultiSelectProps {
  /**
   * The current set of values of the multi-select.
   */
  value: string[];

  /**
   * The options to display in the multi-select.
   */
  data: ComboboxItem[];

  /**
   * The action to display next to the multi-select.
   * This is rendered in a form of a button (dark).
   */
  action?: {
    name: string;
    onClick: () => void;
    disabled?: boolean;
  };

  /**
   * The placeholder to display when there are no values selected.
   */
  placeholder?: string;

  /**
   * The content to display when there are no options to select.
   */
  nothingFound?: ReactNode;

  /**
   * The icon to display next to the multi-select.
   */
  iconName?: IconName;

  /**
   * The callback to invoke when the value of the multi-select changes.
   * @param value
   */
  onChange?: (value: string[]) => void;

  /**
   * Whether show boxshadow under input. Default to true
   */
  withBoxShadow?: boolean;
  /** Whether input should have padding. Default to true */
  withPadding?: boolean;
}

const defaultSearchFilterFunction: OptionsFilter = ({ options, search }) => {
  return options.filter((option) => {
    const optionLabel: string | undefined = (option as ComboboxItem).label;
    return (
      !!optionLabel && optionLabel.toLowerCase().includes(search.toLowerCase())
    );
  });
};

/**
 * A multi-select component that uses the Mantine MultiSelect component, themed
 * and styled for Cadmus.
 */
export const MultiSelect = forwardRef<HTMLInputElement, MultiSelectProps>(
  (props, ref) => {
    const {
      value,
      data,
      placeholder,
      nothingFound,
      renderOption,
      onChange,
      filter: _filterFunction = defaultSearchFilterFunction,
      action,
      iconName,
      hidePickedOptions,
      withBoxShadow = true,
      withPadding = true,
      ...rest
    } = props;
    const {
      textTransform: _textTransform,
      element: _element,
      ...otherLeadProperties
    } = typography["bodyLg"];

    return (
      <Container withBoxShadow={withBoxShadow} withPadding={withPadding}>
        {iconName && <InputIcon iconName={iconName} />}
        <MantineMultiSelect
          ref={ref}
          value={value}
          data={data}
          placeholder={placeholder}
          searchable
          nothingFoundMessage={nothingFound}
          styles={{
            pill: {
              ...otherLeadProperties,
              padding: "2px 4px 4px 12px",
              border: "1px solid rgba(89, 115, 166, 0.36)",
              backgroundColor: "white",
              height: "auto",
              borderRadius: "8px",
              fontSize: "14px",
            },
            input: {
              border: "none",
            },
            root: {
              flexGrow: 1,
            },
            inputField: {
              fontSize: "16px",
            },
            option: {
              p: {
                ...otherLeadProperties,
              },
            },
          }}
          renderOption={renderOption}
          onChange={onChange}
          filter={_filterFunction}
          hidePickedOptions={hidePickedOptions ?? true}
          {...rest}
        />
        {action && (
          <Button
            onClick={action.onClick}
            kind={"secondary"}
            disabled={action.disabled}
          >
            {action.name}
          </Button>
        )}
      </Container>
    );
  }
);

const Container = styled.div<{
  withBoxShadow?: boolean;
  withPadding?: boolean;
}>`
  display: flex;
  align-items: center;
  box-shadow: ${(p) => (p.withBoxShadow ? "0 1px 0 #0073e6" : "unset")};
  padding: ${(p) => (p.withPadding ? "8px 0" : "unset")};
`;

const InputIcon = styled(Icon)`
  width: 30px;
  height: 30px;
`;
