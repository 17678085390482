import * as React from "react";
import { styled } from "styled-components";

import { Button, colors, Input, Spacer, Text } from "@vericus/cadmus-ui";

import { validateLink } from "ui/task/resources/internal";

interface Props {
  /** Callback for adding a new valid URL resource. */
  onAdd: (name: string, url: string) => void;
  /** Callback for cancelling adding a new URL resource. */
  onCancel: () => void;
}

/**
 * Form to validate and add a new URL resource. Opens under the
 * `ResourceActions` component.
 */
export function ResourceAddForm(props: Props) {
  const { onAdd, onCancel } = props;

  // Input state for new URL resource name
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState<string | undefined>();

  // Input state for new URL resource url
  const [url, setUrl] = React.useState("");
  const [urlError, setUrlError] = React.useState<string | undefined>();

  // Handler for adding a new resource.
  const handleAdd = React.useCallback(() => {
    const validName = name.trim() !== "";
    const validUrl = validateLink(url);
    if (!validName) {
      setNameError("Must have a display text");
    } else {
      setNameError(undefined);
    }
    if (!validUrl) {
      setUrlError("Invalid URL");
    } else {
      setUrlError(undefined);
    }
    if (validName && validateLink(url)) {
      onAdd(name.trim(), url.trim());
    }
  }, [onAdd, name, url, setNameError, setUrlError]);

  return (
    <FormContainer>
      <Text kind="headingFive">Link to a website...</Text>
      <Spacer spacing={9} />
      <FormRow>
        <FormLabel kind="headingSix">Title: </FormLabel>
        <FormInput
          aria-label="Resource name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          placeholder="Text to display"
          autoFocus
          fullWidth
          errorMessage={nameError}
          onKeyPress={(e) => e.which === 13 && handleAdd()}
        />
      </FormRow>
      <Spacer spacing={nameError ? 35 : 9} />
      <FormRow>
        <FormLabel kind="headingSix">URL: </FormLabel>
        <FormInput
          aria-label="Web address"
          value={url}
          type="url"
          onChange={(
            e: React.ChangeEvent<HTMLInputElement> & { nativeEvent: InputEvent }
          ) => {
            setUrl(e.currentTarget.value);
            const nativeEvent: InputEvent = e.nativeEvent;
            if (nativeEvent.inputType === "insertFromPaste") {
              const hostName = new URL(e.target.value).hostname;
              setName(hostName);
            }
          }}
          placeholder="Paste web address"
          fullWidth
          errorMessage={urlError}
          onKeyPress={(e) => e.which === 13 && handleAdd()}
        />
      </FormRow>
      <Spacer spacing={urlError ? 35 : 27} />
      <FormActions>
        <Button onClick={onCancel} aria-label="Cancel">
          Cancel
        </Button>
        <Button
          kind="primary"
          onClick={handleAdd}
          marginLeft={27}
          aria-label="Confirm"
        >
          Add Resource
        </Button>
      </FormActions>
    </FormContainer>
  );
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.white200};
  padding: 27px;
`;

const FormRow = styled.label`
  display: flex;
  align-items: baseline;
`;

const FormLabel = styled(Text)`
  width: 72px;
  flex: none;
`;

const FormInput = styled(Input)`
  flex: auto;
`;

const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;
