import { QuestionFieldFragment } from "@/generated/graphql";

export enum InteractionKind {
  Choice,
  TextEntry,
  Extended,
}

export function defaultBlankFieldInteractionKind() {
  return InteractionKind.TextEntry;
}

export function mapInteractionKind(
  graphQLkind:
    | "ChoiceInteraction"
    | "TextEntryInteraction"
    | "ExtendedInteraction"
): InteractionKind {
  switch (graphQLkind) {
    case "ChoiceInteraction":
      return InteractionKind.Choice;
    case "TextEntryInteraction":
      return InteractionKind.TextEntry;
    case "ExtendedInteraction":
      return InteractionKind.Extended;
  }
}

export function mapFieldInteractionKind(field: QuestionFieldFragment) {
  switch (field.interaction.__typename) {
    case "ChoiceInteraction":
      return InteractionKind.Choice;
    case "TextEntryInteraction":
      return InteractionKind.TextEntry;
    case "ExtendedInteraction":
    default:
      return InteractionKind.Extended;
  }
}
