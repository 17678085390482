import { useState } from "react";
import { styled } from "styled-components";

import {
  ActionInput,
  Divider,
  Popover,
  SearchList,
  SearchListItem,
  SelectButton,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

export interface Props {
  /**
   * All the available items to display and that are searchable
   */
  items: SearchListItem[];

  /**
   * The placeholder text for when there is no search query inputted yet.
   */
  placeholder?: string;

  /**
   * Callback function for when an item is selected from the menu list.
   * @param {SearchListItem} searchListItem the item that was just selected.
   */
  onSelect: (searchListItem: SearchListItem) => void;

  /**
   * Callback function for when an intent to add a new group is signaled.
   * @param {string} addedItem the input field value from when the action was emitted.
   */
  onAdd: (addedItem: string) => void;

  /**
   * The current item to be shown as selected.
   */
  selectedItem?: SearchListItem;
}

export const GroupSearchSelect = ({
  items,
  placeholder,
  onSelect,
  onAdd,
  selectedItem,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [newTagInput, setNewTagInput] = useState<string>("");

  const error = items
    .map((item) => item.label?.toLowerCase())
    .includes(newTagInput.toLowerCase())
    ? "Group name already exists"
    : undefined;

  const onSearchQueryChange = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  const onNewTagInputChange = (newTagInput: string) => {
    setNewTagInput(newTagInput);
  };

  const onAddNewTag = (newTag: string) => {
    onAdd(newTag);
    setNewTagInput("");
  };

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <SelectButton>{selectedItem?.label ?? "Select group"}</SelectButton>
      </Popover.Trigger>
      <Popover.Content>
        <Popover.Card>
          {items.length > 0 && (
            <StyledSearchList
              items={items}
              placeholder={placeholder}
              onSelect={(searchListItem: SearchListItem) => {
                onSelect(searchListItem);
              }}
              queryValue={searchQuery}
              onQueryValueChange={onSearchQueryChange}
            />
          )}
          {items.length === 0 && (
            <NoItemsContainer>
              <NoItemsContent>
                <Text kind="bodyMd" color="grey500">
                  {`You don't have any marking groups set up yet. Set up marking `}
                  groups to quickly filter the class list by marker
                </Text>
                <Spacer spacing={10} />
              </NoItemsContent>
            </NoItemsContainer>
          )}
          <Divider />
          <ActionableInputContainer>
            <ActionInput
              value={newTagInput}
              onChange={onNewTagInputChange}
              iconName={"Plus"}
              placeholder={"Create New Group"}
              onAction={onAddNewTag}
              actionName={"Create"}
              error={error}
              disabled={!!error || newTagInput.trim().length === 0}
              onKeyDown={(e) => {
                if (e.key === "Enter") onAddNewTag(newTagInput);
              }}
            />
          </ActionableInputContainer>
        </Popover.Card>
      </Popover.Content>
    </Popover.Root>
  );
};

const NoItemsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  text-align: center;
`;

const NoItemsContainer = styled.div`
  height: 232px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionableInputContainer = styled.div`
  padding: 12px;
`;

const StyledSearchList = styled(SearchList)`
  max-height: 400px;
`;
