import { styled } from "styled-components";

import {
  Button,
  colors,
  Divider,
  Pill,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import {
  DuplicatePastAssessmentCallback,
  PreviewPastAssessmentCallback,
} from "@/data/templates";
import {
  AssessmentType,
  SubjectFragment,
  TaskFormat,
} from "@/generated/graphql";
import { DisplayTag } from "@/ui/StudentLists/TagSelector";
import { formatCondensedDate } from "@/utils/datetime";

/**
 * The display component to show the user's past assessment.
 * Displays some general information about the assessment.
 *
 * The user can action on the card:
 * - Duplicate the assessment
 * - Preview the assessment
 */
export interface PastAssignmentProps {
  /**
   * The assessment ID
   */
  assessmentId: string;
  /**
   * The instruction sheet version relating to the assessment in question
   */
  instructionSheetVersionId: string;
  /**
   * The name of the assessment
   */
  name: string;
  /**
   * The subject of the assessment
   */
  subject: SubjectFragment;
  /**
   * The due date of the assessment
   */
  dueDate: Date;
  /**
   * The feedback return date of the assessment.
   * Used to calculate whether the assessment is considered closed.
   * Where "closed" is the current date is past this date.
   */
  returnDate: Date;
  /**
   * Callback for when the user intends to duplicate the assessment
   * @param assessmentId
   */
  onDuplicate: DuplicatePastAssessmentCallback;
  /**
   * Callback for when the user intends to preview the assessment
   * @param assessmentId
   */
  onPreview: PreviewPastAssessmentCallback;

  /**
   * The type of assessment.
   */
  assessmentType: AssessmentType;

  /**
   * The format of the task.
   */
  taskFormat: TaskFormat;
}

/**
 * A component to represent a past assessment.
 */
export const PastAssignmentCard = (props: PastAssignmentProps) => {
  return (
    <Container>
      <TopContainer>
        <Text kind={"headingFour"}>{props.name}</Text>
        <Text kind={"label"}>{props.subject.name}</Text>
        <Spacer spacing={16} />
        <span>
          <Text kind="bodySm">
            <b>Due date:</b> {formatCondensedDate(props.dueDate)}
          </Text>
        </span>
        <Spacer spacing={12} />
        <Divider />
        <Spacer spacing={12} />
        <TagsContainer>
          {generateTags(props).map((tag, index) => {
            return (
              <Pill key={index} color={tag.color}>
                <Text kind={"label"}>{tag.value}</Text>
              </Pill>
            );
          })}
        </TagsContainer>
      </TopContainer>
      <BottomContainer>
        <ControlBar>
          <PreviewButton
            kind={"outline"}
            onClick={() =>
              props.onPreview(
                props.assessmentId,
                props.name,
                props.instructionSheetVersionId
              )
            }
          >
            Preview
          </PreviewButton>
          <DuplicateButton
            kind={"secondary"}
            onClick={() =>
              props.onDuplicate(
                props.assessmentId,
                props.name,
                props.instructionSheetVersionId
              )
            }
          >
            Duplicate
          </DuplicateButton>
        </ControlBar>
      </BottomContainer>
    </Container>
  );
};

const TopContainer = styled.div``;
const BottomContainer = styled.div`
  position: relative;
`;

// Default state when mouse is not hovering on the container
const ControlBar = styled.div`
  position: absolute;
  bottom: -10px;
  opacity: 0;
  width: 100%;
  display: flex;
  gap: 8px;
  background-color: white;
  z-index: 1;

  transition:
    bottom 0.2s ease-in,
    opacity 0.2s ease-in;

  & > * {
    flex-grow: 1;
  }
`;

const PreviewButton = styled(Button)`
  border-color: ${colors.black200};
  color: ${colors.black200};
  &:not(:disabled):after {
    box-shadow: none;
  }
`;

const DuplicateButton = styled(Button)`
  &:not(:disabled):hover:after {
    opacity: 0.5;
  }
`;

const Container = styled.div`
  border: 1px solid #5973a65c;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;

  &:focus,
  &:active,
  &:hover {
    & ${ControlBar} {
      opacity: 1;
      bottom: 0px;
    }
  }
`;

const TagsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const generateTags = (props: PastAssignmentProps): DisplayTag[] => {
  const releasedTag: DisplayTag = {
    value: "released",
    active: true,
    color: colors.green200,
  };
  const tags: DisplayTag[] = [releasedTag];
  tags.push({
    value: props.taskFormat,
    active: true,
    color: colors.cyan200,
  });
  if (new Date() > new Date(props.returnDate)) {
    tags.push({
      value: "closed",
      active: true,
      color: colors.grey100,
    });
  }

  return tags;
};
