import { styled } from "styled-components";

/**
 * Used to group UI elements underneath the navigation bar.
 */
export const PurpleArea = styled.div`
  box-sizing: border-box;
  background-color: ${(p) => p.theme.colors.bgrey500};
  padding: 40px 0px;
  border-radius: 0px 0px 32px 0px;
`;
