import { useMemo } from "react";
import { styled } from "styled-components";

import {
  CloseRoundIcon,
  ErrorIcon,
  Icon,
  PlusRoundIcon,
} from "@vericus/cadmus-icons";
import { Checkbox, IconButton, PhraseInput, Text } from "@vericus/cadmus-ui";

import { useOptionListState } from "./use-option-list-state";

interface OptionsInputProps {
  /** Current options */
  options: { identifier: string; content: string }[];
  correctAnswerIds?: string[];
  /** (Optional) On toggle correct option - this is used for choices */
  onToggleCorrectOption?: (choiceId: string) => void;
  /** On add new option */
  onAddOption: (option: string) => void;
  /** On delete existing option */
  onDeleteOption: (option: string) => void;
  /** Place holder of option input */
  inputPlaceHolder?: string;
  /**
   * Callback function of the right delete button. If not provided,
   * the right delete button will be used to reset input
   */
  onDeleteButtonClick?: () => void;
  /**
   * Additional customized error message, passed by users
   */
  additionalErrorMsg?: string;
}

/**
 * Render a form to create or delete multiple unique string options in a row.
 */
export function OptionListInput({
  options,
  onToggleCorrectOption,
  correctAnswerIds,
  onAddOption,
  onDeleteOption,
  additionalErrorMsg,
  inputPlaceHolder,
  onDeleteButtonClick,
}: OptionsInputProps) {
  const isSelectable = onToggleCorrectOption !== undefined;

  // Items contents are used
  const itemContents = useMemo(
    () => options.map((option) => option.content),
    [options]
  );

  const {
    inputValue,
    showInput,
    inputHasError,
    setInputValue,
    resetInputState,
    onAddButtonClick,
    errorMsg,
    clearError,
    setShowInput,
  } = useOptionListState(
    itemContents,
    onAddOption,
    false,
    (inputValue: string) => {
      if (inputValue.length > 50) return "";
    }
  );

  return (
    <Root>
      <OptionContainer>
        {options.map((option) => {
          const checked = correctAnswerIds?.includes(option.identifier);
          const isHighlighted = checked === undefined || checked === true;
          return (
            <Option checked={isHighlighted} key={option.identifier}>
              {isSelectable && (
                <Checkbox
                  onClick={(e) => {
                    e.stopPropagation();
                    onToggleCorrectOption?.(option.identifier);
                  }}
                  onChange={() => {}}
                  checked={checked}
                  colorVariant="nteal700"
                />
              )}
              <Text
                kind="bodyMd"
                color={isHighlighted ? "nteal700" : "navy400"}
              >
                {option.content}
              </Text>
              <StyledButton
                onClick={() => onDeleteOption(option.identifier)}
                aria-label="Delete answer"
              >
                <StyledCloseRoundIcon label="" checked={isHighlighted} />
              </StyledButton>
            </Option>
          );
        })}
        {(showInput || options.length === 0) && (
          <InputWrapper>
            <StyledPhraseInput
              charactersLimit={50}
              onChange={(e) => {
                setInputValue(e.target.value);
                if (inputHasError) clearError();
              }}
              value={inputValue}
              onKeyDown={(e) => {
                if (e.key !== "Enter") return;
                onAddButtonClick();
                e.currentTarget.blur();
              }}
              hasError={additionalErrorMsg !== undefined || inputHasError}
              placeholder={inputPlaceHolder}
              hideLimit={inputValue.length === 0}
            />
            {((errorMsg?.length ?? 0) > 0 ||
              (additionalErrorMsg?.length ?? 0) > 0) && (
              <InputError>
                <ErrorIcon label="" />
                <Text kind="bodySm" color="functionalFail">
                  {additionalErrorMsg !== undefined && additionalErrorMsg !== ""
                    ? additionalErrorMsg
                    : errorMsg}
                </Text>
              </InputError>
            )}
          </InputWrapper>
        )}
        <StyledButton onClick={onAddButtonClick} aria-label="Add item">
          <AddButtonIcon label="" size={36} />
        </StyledButton>
      </OptionContainer>
      <DeleteButton
        onClick={
          onDeleteButtonClick
            ? onDeleteButtonClick
            : () => {
                resetInputState();
                setShowInput(false);
              }
        }
        aria-label="Delete all accepted answers"
      >
        <Icon iconName="Trash" />
      </DeleteButton>
    </Root>
  );
}

const StyledCloseRoundIcon = styled(CloseRoundIcon)<{
  checked?: boolean;
}>`
  fill: ${({ theme, checked }) =>
    checked ? theme.colors.nteal700 : theme.colors.grey400};
  &:hover {
    fill: ${(p) => p.theme.colors.functionalFail};
  }
`;

const Root = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: start;
  justify-content: space-between;
  width: 100%;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: 8px;
  border-radius: 8px;
`;

const Option = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  background: ${({ theme, checked }) =>
    checked ? theme.colors.nteal200 : theme.colors.bgrey300};
  padding: 9px 12px 9px 16px;
  gap: 12px;
  border-radius: 8px;
  max-height: 42px;
`;


const StyledPhraseInput = styled(PhraseInput)`
  padding: 8px 16px;
  align-self: center;
  width: 206px;
`;

const DeleteButton = styled(IconButton)`
  margin-top: 3px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: start;
`;

const StyledButton = styled.button`
  border: none;
  background: none;
  padding: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 3px;
`;

const AddButtonIcon = styled(PlusRoundIcon)`
  background: ${(p) => p.theme.colors.grey50};
`;

const InputError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  column-gap: 4px;
`;
