import { Discipline } from "@/generated/graphql";

import { Category, Subcategory } from "./types";

/**
 * Given an array of categories, find the selected subcategory (there should only be one).
 */
export const findSelectedSubcategory = (
  categories: Category[]
): Subcategory | undefined => {
  for (const category of categories) {
    const activeSubCategory = category.subcategories.find(
      (sc) => sc.isSelected
    );
    if (activeSubCategory) {
      return activeSubCategory;
    }
  }
};

export const findSubcategoryBySlug = (
  categories: Category[],
  slug: string
): Subcategory | undefined => {
  for (const category of categories) {
    const subcategory = category.subcategories.find((sc) => sc.slug === slug);
    if (subcategory) {
      return subcategory;
    }
  }
};

export const findSubcategoryByDiscipline = (
  categories: Category[],
  discipline: Discipline
) => {
  let discoveredSubcategory;

  categories.forEach((category) => {
    category.subcategories.forEach((subcategory) => {
      if (subcategory.discipline?.includes(discipline)) {
        discoveredSubcategory = subcategory;
      }
    });
  });

  return discoveredSubcategory;
};

export const findSubcategoryById = (
  categories: Category[],
  subcategoryId?: string
): Subcategory | undefined => {
  if (!subcategoryId) {
    return undefined;
  }

  for (const category of categories) {
    const subcategory = category.subcategories.find(
      (sc) => sc.id === subcategoryId
    );
    if (subcategory) {
      return subcategory;
    }
  }
};

/**
 * Returns a new array of categories with the specified subcategory made active.
 */
export const makeSubcategorySelected = (
  categories: Category[],
  subcategoryId: string
): Category[] => {
  const newCategories = [...categories].map(({ subcategories, ...rest }) => ({
    ...rest,
    subcategories: subcategories.map((sc) => {
      if (sc.id === subcategoryId) {
        const subcategory: Subcategory = {
          ...sc,
          isSelected: true,
        };
        return subcategory;
      }
      return { ...sc, isSelected: false };
    }),
  }));
  return newCategories;
};

/**
 * Returns a new array of categories with the specified subcategory made active.
 */
export const makeSubcategorySelectedBySlug = (
  categories: Category[],
  slug: string
): Category[] => {
  const newCategories = [...categories].map(({ subcategories, ...rest }) => ({
    ...rest,
    subcategories: subcategories.map((sc) => {
      if (sc.slug === slug) {
        const subcategory: Subcategory = {
          ...sc,
          isSelected: true,
        };
        return subcategory;
      }
      return { ...sc, isSelected: false };
    }),
  }));
  return newCategories;
};

/**
 * Whether the slug leads to a subcategory that is tied to a discipline.
 *
 * For now, this is relatively hard coded. Maybe in the future, it needs to
 * do some sort of traversing and answer dynamically.
 * @param slug - The slug name of interest to test against.
 */
export const isSlugDisciplined = (slug: string | null) => {
  return slug !== "past-assessment";
};
