import { useState } from "react";
import { styled } from "styled-components";

import {
  Button,
  levels,
  SearchListItem,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import { GroupFragment } from "generated/graphql";

import { GroupSearchSelect } from "./GroupSearchSelect";

interface Props {
  /**
   * Complete list of ALL the groups with their members
   */
  groups: GroupFragment[];
  /**
   * Callback to confirm selection of an existing or new group by the user.
   */
  onConfirm: (groupName: string) => void;
  /**
   * Cancel user's interaction without confirming changes.
   */
  onCancel: () => void;
  /**
   * The group that is currently selected. By default, will render "Select group"
   */
  selected?: GroupFragment;
}

/**
 * Component to bulk apply a new Group membership onto multiple enrollments/students.
 *
 * The Component renders a searchable select list of the
 */
export const GroupBulkEditCard = (props: Props) => {
  const { groups, onConfirm, onCancel, selected } = props;

  // Selected item state
  const [selectedItem, setSelectedItem] = useState<SearchListItem | null>(
    selected
      ? {
          value: selected.id,
          label: selected.name,
          description: `${selected.members.length} Students`,
        }
      : null
  );

  const items = groups.map((group) => ({
    value: group.id,
    label: group.name,
    description: `${group.members.length} Students`,
  }));

  // Add the selected item to the front of the list if it's a new group
  const itemsWithNewGroup =
    selectedItem?.value === "new-group" ? [selectedItem, ...items] : items;

  return (
    <CardRoot>
      <Text kind="headingSix">Add to a marking group</Text>
      <Spacer spacing={8} />
      <GroupSearchSelect
        items={itemsWithNewGroup}
        onSelect={setSelectedItem}
        onAdd={(groupName) => {
          const item = {
            value: "new-group",
            label: groupName,
            description: "0 Students",
          };
          setSelectedItem(item);
        }}
        selectedItem={selectedItem ?? undefined}
      />
      <Spacer spacing={16} />
      <CardActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          kind="secondary"
          disabled={selectedItem === null}
          onClick={() => selectedItem && onConfirm(selectedItem.label)}
        >
          Add
        </Button>
      </CardActions>
    </CardRoot>
  );
};

const CardRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 382px;
  padding: 24px 21px;
  box-sizing: border-box;
  box-shadow: ${levels.two};
  background: white;
  border-radius: 16px;
  z-index: 10;
`;

const CardActions = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: flex-end;
`;
