import { addMinutes, isAfter, isBefore } from "date-fns";

import {
  AssessmentType,
  ExamTiming,
  InstructionSheetFragment,
} from "@/generated/graphql";

//////////////////////////////////////////////////////////////////////////////
// Assignment Sheet                                                         //
//////////////////////////////////////////////////////////////////////////////

type AssignmentSheet = Pick<InstructionSheetFragment, "draftDueDate">;

/** Select if drafting is enabled. */
export function selectHasDrafting(sheet: AssignmentSheet) {
  return sheet.draftDueDate !== null;
}

//////////////////////////////////////////////////////////////////////////////
// Exam Sheet                                                               //
//////////////////////////////////////////////////////////////////////////////

type ExamSheet = Pick<
  InstructionSheetFragment,
  | "examTiming"
  | "examReadingTime"
  | "examWritingTime"
  | "examStartDate"
  | "examEndDate"
>;

/** Select if the sheet is for an exam. */
export function selectIsExam(
  sheet: Pick<InstructionSheetFragment, "assessmentType">
): boolean {
  return sheet.assessmentType === AssessmentType.Exam;
}

/**
 * Select if the exam is in progress right now.
 */
export function selectIsExamInProgress(sheet: ExamSheet): boolean {
  const { examStartDate } = sheet;

  const endDate = selectExamCloseDate(sheet);
  if (!examStartDate || !endDate) return false;

  const now = new Date();
  return (
    isAfter(now, new Date(examStartDate)) && isBefore(now, new Date(endDate))
  );
}

/**
 * Select whether the exam has closed now.
 */
export function isExamClosed(sheet: ExamSheet): boolean {
  const endDate = selectExamCloseDate(sheet);
  if (!endDate) return false;
  return isAfter(new Date(), new Date(endDate));
}

/**
 * Select the exam close date from the sheet.
 *
 * NOTE: does not comsider special considerations or extensions.
 *
 * @returns the ISO8601 date for when the exam will close submissions.
 */
export function selectExamCloseDate(sheet: ExamSheet): string | null {
  const {
    examReadingTime,
    examTiming,
    examEndDate,
    examStartDate,
    examWritingTime,
  } = sheet;

  if (examTiming === ExamTiming.Live && examStartDate) {
    const totalTime = (examReadingTime || 0) + (examWritingTime || 0);
    return addMinutes(new Date(examStartDate), totalTime).toISOString();
  }

  return examEndDate;
}

export function isCadmusGraderEnabled(
  sheet: Pick<InstructionSheetFragment, "gradingService">
) {
  return sheet.gradingService === "cadmusgrader";
}
