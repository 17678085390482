import { differenceInMinutes } from "date-fns";
import { saveAs } from "file-saver";
import { EnrollmentFragment, GroupFragment } from "generated/graphql";
import { formatDate } from "utils/datetime";

interface GradeRow {
  "Last name": string;
  "First name": string;
  "Full name": string;
  Email: string;
  "Student ID": string | null;
  "Final grade": string;
  "Final similarity": string;
  "Final submit time": string;
  "Start time"?: string;
  "Duration (mins)"?: string;
  "Draft similarity": string;
  "Draft submit time": string;
  "Group ID": string;
  "Group name": string;
  "Due extension": string;
  "Time extension (mins)"?: string;
  "Has accessed": string;
  "Work ID": string | null;
}

const formatCsvDate = (date: Date | string) =>
  formatDate(date, "yyyy-MM-dd HH:mm");

/**
 * Download enrollment list with grades and submission information as a CSV.
 *
 * @param enrollments Current enrollments in the class
 * @param groups All groups in the assessment
 * @param isTimeLimited flag indicated if time limit is enabled
 * @return a promise which generates the csv and opens the browser file save dialog.
 */
export async function exportGrades(
  assessmentName: string,
  enrollments: EnrollmentFragment[],
  groups: GroupFragment[],
  isTimeLimited: boolean
): Promise<void> {
  const parser = await import("papaparse");
  const rows = createGradeRows(enrollments, groups, isTimeLimited);
  const csv = parser.unparse(rows);

  saveAs(
    new Blob([csv], {
      type: "text/csv;charset=UTF-8",
    }),
    `${assessmentName}.csv`,
    { autoBom: true }
  );
}

/**
 * Create CSV data object rows mapped from given enrollments.
 *
 * @param enrollments Current enrollments in the class
 * @param groups All groups in the assessment
 * @param isTimeLimited flag indicated if time limit is enabled
 * @return list of `GradeRow` which can be rendered as rows in CSV
 */
export function createGradeRows(
  enrollments: EnrollmentFragment[],
  groups: GroupFragment[],
  isTimeLimited: boolean
): GradeRow[] {
  return (
    enrollments
      // Remove withdrawn enrollments
      .filter((enrollment) => !enrollment.deleted)
      .map((enrollment): GradeRow => {
        const {
          work,
          workSettings,
          user: { familyName, givenName, name, email, id: userId },
        } = enrollment;

        const groupIndex = groups.findIndex(
          ({ members }) =>
            members?.find(({ id: memberId }) => memberId === userId)
        );

        return {
          "Last name": familyName,
          "First name": givenName,
          "Full name": name,
          Email: email ?? "",
          "Student ID": enrollment.sisUserId,
          "Final grade": work?.final?.result?.grade?.display ?? "",
          "Final similarity": work?.final?.result?.similarity?.display ?? "",
          "Final submit time": work?.final?.submittedAt
            ? formatCsvDate(work.final.submittedAt)
            : "",
          ...(isTimeLimited
            ? {
                "Start time": work?.startDate
                  ? formatCsvDate(work.startDate)
                  : "",
                "Duration (mins)": getFinalWorkDuration(work),
              }
            : undefined),
          "Draft similarity": work?.draft?.result?.similarity?.display ?? "",
          "Draft submit time": work?.draft?.submittedAt
            ? formatCsvDate(work.draft.submittedAt)
            : "",
          "Group ID": groupIndex !== -1 ? groupIndex.toString() : "",
          "Group name": groupIndex !== -1 ? groups[groupIndex]!.name : "",
          "Due extension": workSettings?.dueDate
            ? formatCsvDate(workSettings.dueDate)
            : "",
          ...(isTimeLimited
            ? {
                "Time extension (mins)":
                  workSettings?.timeLimit?.toString() ?? "",
              }
            : undefined),
          "Has accessed": work ? "yes" : "no",
          "Work ID": work?.id ?? "",
        };
      })
  );
}

/**
 * A more specific version of `getWorkDuration`.
 * This is a more convenient just for CSV parsing.
 */
const getFinalWorkDuration = (work: EnrollmentFragment["work"]) =>
  (work?.final &&
    work.startDate &&
    differenceInMinutes(
      new Date(work.final.submittedAt),
      new Date(work.startDate)
    ).toString()) ??
  "";
