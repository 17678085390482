import * as React from "react";

import { Channel, Socket } from "phoenix";

import * as config from "@/config";

/**
 * Wrapper for managing singular instances of Pantheon `Socket` and
 * Assessment `Channel`.
 *
 * An Athena client can only connect to one authenticated Socket and
 * one Assessment channel (it's own).
 *
 * By default, the Socket and Channel are not initialised
 * automatically. Call `this.connect` manually once the Assessment ID
 * and User Token are known and available.
 */
export class PhoenixConnection {
  socket: Socket | null;
  channel: Channel | null;

  constructor() {
    this.socket = null;
    this.channel = null;
  }

  /**
   * Initialise the authenticated Socket and Work channel
   * The socket is created only once, and the channel is joined only once.
   */
  connect = (assessmentId: string, token: string, tenant: string) => {
    const topic = `assessment:${assessmentId}`;
    this.socket = new Socket(config.WS_ENDPOINT, {
      params: { token, role: "lecturer", tenant },
    });

    this.socket.connect();

    this.channel = this.socket.channel(topic);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(this.channel as any).joinedOnce) {
      this.channel.join();
    }
  };
}

/** Global uninitialised PhoenixConnection */
export const connection = new PhoenixConnection();

/** React context wrapper for the global PhoenixConnection. */
export const PhoenixContext = React.createContext(connection);
