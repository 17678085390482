import { styled } from "styled-components";

/**
 * The base container for multi format questions
 */
export const BodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;

export const RootDiv = styled.div<{
  isSubOrChildQuestion?: boolean;
  isSection?: boolean;
  /** Used for sub question. Focus when user input the promptDoc of parent question */
  focused?: boolean;
  hasError?: boolean;
}>`
  border: 1px solid
    ${(p) =>
      p.hasError
        ? p.theme.colors.functionalFail
        : p.focused
          ? p.theme.colors.lilac500
          : "transparent"};
  border-radius: 4px;
  background: ${(p) =>
    p.isSection ? p.theme.colors.lilac200 : p.theme.colors.white100};
  max-width: 882px;
  width: 100%;
  scroll-margin-top: 60px;
  padding: 28px ${(p) => (p.isSubOrChildQuestion ? "18px" : "40px")};
  box-sizing: border-box;

  box-shadow:
    0px 2px 6px 0px rgba(71, 92, 133, 0.04),
    0px 0px 1px 0px rgba(71, 92, 133, 0.04);
`;

export const FormErrorMsgWrapper = styled.div`
  padding: 0px 45px;
`;
