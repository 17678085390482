import { useRef, useState, useTransition } from "react";
import { styled } from "styled-components";

import {
  ActionInput,
  DropdownMenu,
  easeOutCirc,
  Icon,
  IconButton,
  Spacer,
  Text,
  typography,
} from "@vericus/cadmus-ui";

import { EnrollmentTagPill } from "@/ui/shared/EnrollmentTagPill";

import { DisplayTag } from "./types";

export interface Props {
  /**
   * Selectable tags to display in the check list.
   */
  tags: DisplayTag[];
  /**
   * Callback to add a `tag`, default or custom.
   */
  onAddTag: (text: string, custom?: boolean) => void;
  /**
   * Callback to remove a `tag`, existing or custom.
   */
  onRemoveTag: (text: string, custom?: boolean) => void;
  /**
   * Callback to create a new tag
   */
  onCreateTag: (label: string) => void;
}

const MAX_CHARACTERS = 25;

/**
 * Manage multi-tag selection for multiple enrollments, rendered as a check
 * list.
 */
export const TagSelectorBulk = (props: Props) => {
  const { tags, onAddTag, onRemoveTag, onCreateTag } = props;
  const [addingTagMode, setAddingTagMode] = useState(false);
  const [newTagInputValue, setNewTagInputValue] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const addTagButtonRef = useRef<HTMLButtonElement | null>(null);
  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const addTagInputRef = useRef<HTMLInputElement | null>(null);

  const [isPendingTagSelectTransition, startTagSelectTransition] =
    useTransition();

  const validateAndSet = (value: string) => {
    if (value.length <= MAX_CHARACTERS) {
      setNewTagInputValue(value);
    }

    if (value.length >= MAX_CHARACTERS) {
      // Show the validation message
      setValidationMessage("Character limit reached");
    } else {
      // Clear it otherwise
      setValidationMessage("");
    }

    if (value.trim().length === 0) {
      setValidationMessage("Tag name cannot be empty");
    }

    if (
      tags
        .map((tag) => tag.value.trim().toLowerCase())
        .includes(value.trim().toLowerCase())
    ) {
      setValidationMessage("This tag already exists");
    }
  };

  // Add or remove a tag based on the checkbox state
  const handleSelect = (tag: DisplayTag) => {
    startTagSelectTransition(() => {
      if (tag.active) {
        onRemoveTag(tag.value, tag.custom);
      } else {
        onAddTag(tag.value, tag.custom);
      }
    });
  };

  return (
    <DropdownMenu.Content
      ref={dropdownMenuRef}
      onKeyDown={(e) => {
        if (e.key === "Tab") {
          if (addingTagMode) {
            addTagInputRef.current?.focus();
          } else {
            addTagButtonRef.current?.focus();
          }
        }
      }}
    >
      <DropdownMenu.Label>Tag as</DropdownMenu.Label>
      <ScrollItemList>
        {tags.map((tag, index) => {
          return (
            <DropdownMenu.CheckboxItem
              key={tag.value}
              checked={tag.mixed ? "indeterminate" : !!tag.active}
              onSelect={(e) => {
                e.preventDefault();
                handleSelect(tag);
              }}
              style={{
                opacity: isPendingTagSelectTransition ? 0.8 : undefined,
              }}
            >
              <EnrollmentTagPill
                color={tag.color}
                tagName={tag.value}
                key={index}
              />
            </DropdownMenu.CheckboxItem>
          );
        })}
      </ScrollItemList>
      <DropdownMenu.Separator />
      <NewTagInputArea
        data-testid={"NewTagInputArea"}
        onKeyDown={(e) => {
          if (
            e.key === "ArrowUp" ||
            e.key === "Escape" ||
            (e.key === "Tab" && e.shiftKey)
          ) {
            dropdownMenuRef.current?.focus();
            e.stopPropagation();
            e.preventDefault();
          }
        }}
      >
        {!addingTagMode && (
          <StyledIconButton
            data-testid={"TagSelectorBulk.AddCustomTag"}
            onClick={() => setAddingTagMode(true)}
            aria-label="Add tag"
            ref={addTagButtonRef}
          >
            <Icon iconName="AddTag" />
          </StyledIconButton>
        )}
        {addingTagMode && (
          <>
            <StyledActionInput
              ref={addTagInputRef}
              value={newTagInputValue}
              onChange={validateAndSet}
              onKeyDown={(e) => {
                // Key combinations that are handled by NewTagInputArea
                if (
                  e.key === "ArrowUp" ||
                  e.key === "Escape" ||
                  (e.key === "Tab" && e.shiftKey)
                )
                  return;
                // stop the dropdown stealing focus and putting it
                // on items that match
                e.stopPropagation();
                if (
                  !validationMessage &&
                  e.currentTarget.value &&
                  e.key === "Enter"
                ) {
                  onCreateTag(e.currentTarget.value);
                  setNewTagInputValue("");
                }
              }}
              iconName="Tag"
              aria-label="add-tag-input"
              placeholder="Add custom tag..."
              actionName="Add"
              maxLength={MAX_CHARACTERS}
              autofocus
              onAction={(newTag: string) => {
                onCreateTag(newTag);
                setNewTagInputValue("");
              }}
              disabled={
                !!validationMessage || newTagInputValue.trim().length === 0
              }
            />
            <Spacer spacing={4} />
            <CharacterValidationFeedback>
              <Text color={"grey500"} kind={"systemSm"}>
                {validationMessage}
              </Text>
              <Text color={"grey500"} kind={"systemSm"}>
                {newTagInputValue.length ?? 0}/{MAX_CHARACTERS}
              </Text>
            </CharacterValidationFeedback>
          </>
        )}
      </NewTagInputArea>
    </DropdownMenu.Content>
  );
};

/**
 * Item list with overflow to make only the list scroll.
 *
 * Account for 200px of header and footer in the menu which stays fixed. And
 * subtract it from the max height of the dropdown menu.
 */
const ScrollItemList = styled.div`
  display: block;
  max-height: calc(var(--radix-dropdown-menu-content-available-height) - 200px);
  overflow-y: scroll;
`;

const StyledActionInput = styled(ActionInput)((_p) => {
  return {
    ...typography["bodySm"],
  };
});

const CharacterValidationFeedback = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NewTagInputArea = styled.div`
  padding: 4px 12px;
`;

// IconButton with a pre-exisiting dark background
const StyledIconButton = styled(IconButton)`
  background: ${(p) => p.theme.background.action1};

  &:hover,
  &:focus {
    background: ${(p) => p.theme.background.action2};
    transition: background 0.1s ${easeOutCirc};
  }

  /* Darken the background on-cLick */

  &:active {
    background: ${(p) => p.theme.background.action3};
  }
`;
