import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@/data/store";
import { QuestionType } from "@/generated/graphql";

import type { TaskBlockNode, TaskLayoutBlock } from "./types";
import { getSectionPoints } from "./utils";

const createAppSelector = createSelector.withTypes<RootState>();

export const selectTaskLayout = (state: RootState) => state.taskLayout;

export const selectSortedTaskBlocks = createAppSelector(
  [selectTaskLayout],
  (taskLayout) => {
    return taskLayout.sortedTaskBlocks;
  }
);

const selectInProgressRequests = createAppSelector(
  [selectTaskLayout],
  (taskLayout) => taskLayout.requestsInProgress
);

export const selectIsLoading = createSelector(
  [selectInProgressRequests],
  (inProgressRequests) => inProgressRequests.length > 0
);

const selectTaskBlockId = (_state: RootState, taskBlockId: string | null) =>
  taskBlockId;

const selectTaskBlockNodeId = (_state: RootState, nodeId: string) => nodeId;

/**
 * Select a sorted Block by it's primary ID.
 */
export const selectTaskBlockById = createAppSelector(
  [selectSortedTaskBlocks, selectTaskBlockNodeId],
  (sortedTaskBlocks, blockId) =>
    sortedTaskBlocks.find((block) => block.id === blockId)
);

/**
 * Select a sorted Block in the state by it's tree node ID.
 */
export const selectTaskBlockByNodeId = createAppSelector(
  [selectSortedTaskBlocks, selectTaskBlockNodeId],
  (sortedTaskBlocks, nodeId) =>
    sortedTaskBlocks.find((block) => block.nodeId === nodeId)
);

/**
 * Select record of all tree nodes.
 */
export const selectTaskBlocksMeta = createAppSelector(
  [selectTaskLayout],
  (taskLayout) => taskLayout.nodes
);

/**
 * Select label for the sorted Task Block by its Node ID.
 */
export const selectTaskBlockLabel = createAppSelector(
  [selectTaskBlocksMeta, selectTaskBlockNodeId],
  (taskBlocksMeta, nodeId) => taskBlocksMeta[nodeId]?.label ?? ""
);

/**
 * Select the tree depth of the task block node.
 */
export const selectTaskBlockDepth = createAppSelector(
  [selectTaskBlocksMeta, selectTaskBlockNodeId],
  (taskBlocksMeta, nodeId) => {
    let depth = 0;
    let parentNodeId: string | null = null;
    let taskBlock: TaskBlockNode | undefined =
      taskBlocksMeta[nodeId] ?? undefined;
    while (taskBlock) {
      parentNodeId = taskBlock.parentNodeId;
      if (parentNodeId) {
        taskBlock = taskBlocksMeta[parentNodeId];
        if (taskBlock) depth++;
      } else {
        taskBlock = undefined;
      }
    }
    return depth;
  }
);

export const selectIsLastSubTaskBlock = createSelector(
  [selectSortedTaskBlocks, selectTaskBlockNodeId],
  (taskBlocks, nodeId) =>
    taskBlocks.findIndex((block) => block.previousNodeId === nodeId) === -1
);

const getChildren = (
  taskBlocks: TaskLayoutBlock[],
  parentNodeId: string | null
) => taskBlocks.filter((block) => block.parentNodeId === parentNodeId);

/** Select the Blocks under a given parent tree node. */
export const selectChildren = createAppSelector(
  [
    selectSortedTaskBlocks,
    (_state, parentNodeId: string | null) => parentNodeId,
  ],
  (sortedTaskBlocks, parentNodeId) => {
    return sortedTaskBlocks.filter((block) => {
      return block.parentNodeId === parentNodeId;
    });
  }
);

/** Sum the points for children Blocks under a given parent tree NODE ID. */
export const selectChildrenPointsTotal = createSelector(
  [selectChildren],
  (childNodes) =>
    childNodes.reduce(
      (totalPoints, { points }) => totalPoints + (points ?? 0),
      0
    )
);

/** Select the total points of a section */
export const selectSectionTotalPoints = createSelector(
  [selectSortedTaskBlocks, selectTaskBlockNodeId],
  (sortedTaskBlocks, sectionNodeId) =>
    getSectionPoints(sortedTaskBlocks, sectionNodeId)
);

/**
 * Select whether this question can be dragged. Some cases when dragging is disabled:
 * - the taskBlock is the only child of a taskBlock
 */
export const selectCanTaskBlockBeDragged = (
  state: RootState,
  nodeId: string
) => {
  const taskBlock = selectTaskBlockByNodeId(state, nodeId);
  if (taskBlock?.questionType === QuestionType.Overview) return false;
  if (!taskBlock?.parentNodeId) return true;
  // all the parent's children
  const allSiblings = selectChildren(state, taskBlock.parentNodeId);
  // can only drag a child when its parent has more than two children
  return allSiblings.length >= 2;
};

/**
 * Select the current task block being dragged by its node ID.
 */
export const selectTaskBlockBeingDragged = (
  state: RootState
): TaskLayoutBlock | undefined => {
  const { nodeIdBeingDragged } = state.taskLayout;
  if (nodeIdBeingDragged === null) return undefined;
  return selectTaskBlockByNodeId(state, nodeIdBeingDragged);
};

const selectNodeIdBeingDragged = (state: RootState) =>
  state.taskLayout.nodeIdBeingDragged;

/**
 * Select the children being dragged. This may be used for something like a
 * drag overlay.
 */
export const selectChildrenBeingDragged = createSelector(
  [selectNodeIdBeingDragged, selectSortedTaskBlocks],
  (nodeIdBeingDragged, sortedTaskBlocks) => {
    if (nodeIdBeingDragged === null) return [];
    return getChildren(sortedTaskBlocks, nodeIdBeingDragged);
  }
);

export const selectOverview = createSelector(
  selectSortedTaskBlocks,
  (sortedBlocks) => {
    return sortedBlocks.find(
      (block) => block.questionType === QuestionType.Overview
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) satisfies (...args: any) => TaskLayoutBlock | undefined;

/** Select whether taskBlock with parentId has child taskBlock(s) that is not an overview */
export const selectHasNoTaskBlockWithParentId = createSelector(
  [selectSortedTaskBlocks, selectTaskBlockId],
  (sortedTaskBlocks, parentId) =>
    sortedTaskBlocks.filter(
      (t) =>
        t.parentNodeId === parentId && t.questionType !== QuestionType.Overview
    ).length === 0
);

const selectDropzoneParentIdBeingDraggedOver = (state: RootState) =>
  state.taskLayout.dropzoneParentIdBeingDraggedOver;

/** Select whether the passed in block's dropzone is being hovered over */
export const selectIsDropzoneBeingHovered = createSelector(
  [
    selectDropzoneParentIdBeingDraggedOver,
    (_state, blockId: string) => blockId,
  ],
  (dropzoneParentId, blockId) => {
    return blockId === dropzoneParentId;
  }
);

/** Select whether sortedTaskBlocks has an Overview question */
export const selectHasNoOverview = createSelector(
  [selectSortedTaskBlocks],
  (sortedTaskBlocks) =>
    !sortedTaskBlocks.find((t) => t.questionType === QuestionType.Overview)
);

/** Select whether sortedTaskBlocks has an overview with its shuffle
 * property turned on. If the task level shuffle is turned on,
 * then disable shuffle toggles on section and sub question nodes as
 * Pantheon will shuffle these nodes for the student.
 * Has no bearing on MCQ's shuffle toggle.
 */
export const selectIsTaskShuffleOn = createSelector(
  [selectSortedTaskBlocks],
  (sortedTaskBlocks) => {
    const maybeOverview = sortedTaskBlocks.find(
      (t) => t.questionType === QuestionType.Overview
    );
    if (maybeOverview) {
      return maybeOverview.shuffle;
    }
    return false;
  }
);
/** Select whether sortedTaskBlocks has at least one questions */
export const selectHasNoQuestion = createSelector(
  [selectHasNoOverview, selectSortedTaskBlocks],
  (hasNoOverview, sortedTaskBlocks) =>
    sortedTaskBlocks.length === 0 ||
    (sortedTaskBlocks.length === 1 && !hasNoOverview)
);

/** Select blocks without a parent that are able to participate in drag and drop interactions */
export const selectRootBlocks = createSelector(
  [selectSortedTaskBlocks],
  (sortedBlocks) => {
    const blocksWithoutParent = sortedBlocks.filter(
      (block) =>
        !block.parentNodeId &&
        block.questionType !== QuestionType.Overview &&
        !block.hidden &&
        !block.deleted
    );
    return blocksWithoutParent;
  }
);

export const selectIsParentBeingDragged = createSelector(
  [
    selectTaskBlockBeingDragged,
    selectSortedTaskBlocks,
    (_, blockId: string) => blockId,
  ],
  (blockBeingDragged, sortedBlocks, blockId) => {
    const block = sortedBlocks.find(({ id }) => id === blockId);
    if (!block) return false;
    const { parentNodeId: parentId } = block;
    return blockBeingDragged?.id === parentId;
  }
);

export const selectIsDraggingAnotherRootBlock = createSelector(
  [selectTaskBlockBeingDragged, (_, rootBlockId: string) => rootBlockId],
  (blockBeingDragged, rootBlockId) => {
    return (
      blockBeingDragged &&
      !blockBeingDragged.parentNodeId &&
      blockBeingDragged.id !== rootBlockId
    );
  }
);

export const selectIsDraggingAnotherRootBlockChild = createSelector(
  [selectTaskBlockBeingDragged, (_, rootBlockId: string) => rootBlockId],
  (blockBeingDragged, rootBlockId) => {
    return (
      blockBeingDragged &&
      blockBeingDragged.parentNodeId &&
      blockBeingDragged.parentNodeId !== rootBlockId
    );
  }
);

export const selectIsDraggingSibling = createSelector(
  [selectTaskBlockBeingDragged, (_, parentId: string) => parentId],
  (blockBeingDragged, parentId) => {
    return blockBeingDragged && blockBeingDragged.parentNodeId === parentId;
  }
);

/** Select the total points of a task */
export const selectTaskTotalPoints = createSelector(
  [selectSortedTaskBlocks],
  (sortedTaskBlocks) => {
    const parentNodeIds: string[] = [];
    const rootQuestionsTotalPoints = sortedTaskBlocks.reduce(
      (totalPoints, taskBlock) => {
        if (taskBlock.parentNodeId !== null) return totalPoints;
        if (taskBlock.questionType === QuestionType.Sub) {
          parentNodeIds.push(taskBlock.nodeId);
          // When a block is changed from other types to sub question
          // Possiblly will have points
          return totalPoints;
        }
        return totalPoints + (taskBlock.points ?? 0);
      },
      0
    );
    const childrenTotalPoints = parentNodeIds
      .flatMap((parentNodeId) =>
        sortedTaskBlocks.filter(
          (sortedTaskBlock) => sortedTaskBlock.parentNodeId === parentNodeId
        )
      )
      .reduce(
        (totalPoints, childBlock) => totalPoints + (childBlock.points ?? 0),
        0
      );
    return rootQuestionsTotalPoints + childrenTotalPoints;
  }
);
export const selectCurrentTab = createSelector(
  selectTaskLayout,
  (taskLayout) => taskLayout.currentTab
);

/** Select the node id of active task block */
export const selectActiveBlockNodeId = createSelector(
  [selectTaskLayout],
  (state) => state.activeNodeId
);
