import { isBefore } from "date-fns";

import { ExamTiming, InstructionSheetFragment } from "@/generated/graphql";
import { useShowFeedbackFilters } from "@/ui/class/progress/hooks";
import { isBeforeFeedbackDate, isExam } from "@/ui/task";

import { ClassTable } from "../table";
import { ProgressFiltersDropdown, StudentRiskFilter } from "./components";
import { ClassListFilterProps, ClassListFilters } from "./internal";

export interface ProgressListFiltersProps
  extends Omit<
    ClassListFilterProps,
    | "showRightFiltersMenu"
    | "leftFilters"
    | "rightFilters"
    | "showRightFiltersMenu"
  > {
  sheet?: InstructionSheetFragment;
}

export function ProgressListFilters(props: ProgressListFiltersProps) {
  return (
    <ClassListFilters
      {...props}
      showRightFiltersMenu={false}
      leftFilters={
        <LeftProgressListFilters table={props.table} sheet={props.sheet} />
      }
    />
  );
}

/**
 * Left filters applicable to the progress list table.
 */
function LeftProgressListFilters({
  sheet,
  table,
}: {
  sheet?: Pick<
    InstructionSheetFragment,
    | "draftDueDate"
    | "dueDate"
    | "timeLimit"
    | "gradingService"
    | "assessmentType"
    | "examTiming"
    | "examFeedbackDate"
    | "noExamFeedbackDate"
    | "returnDate"
    | "enableExamAccessCode"
  >;
  table: ClassTable;
}) {
  const hasTimeLimit = sheet?.timeLimit !== null;
  const markingDisabled = sheet?.gradingService === "speedgrader";
  const isExamAssessment = !!sheet && isExam(sheet);
  const hasDrafting = !isExamAssessment && !!sheet?.draftDueDate;
  const isBeforeDraftDueDate = sheet?.draftDueDate
    ? isBefore(new Date(), new Date(sheet.draftDueDate))
    : false;
  const isBeforeFinalDueDate = sheet?.dueDate
    ? isBefore(new Date(), new Date(sheet.dueDate))
    : false;
  const showFeedbackFilters = useShowFeedbackFilters({
    instructionSheet: sheet ?? null,
  });
  const enabledAccessCode = sheet?.enableExamAccessCode ?? false;

  return (
    <>
      <ProgressFiltersDropdown
        column={table.getColumn("flags")!}
        hasDrafting={hasDrafting}
        hasTimeLimit={hasTimeLimit}
        isExam={isExamAssessment}
        isWindowExam={
          isExamAssessment && sheet?.examTiming === ExamTiming.Window
        }
        isBeforeDraftDueDate={isBeforeDraftDueDate}
        isBeforeFinalDueDate={isBeforeFinalDueDate}
        showFeedbackFilters={showFeedbackFilters}
        enabledAccessCode={enabledAccessCode}
      />
      <StudentRiskFilter
        column={table.getColumn("progress")!}
        hasDrafting={hasDrafting}
        markingDisabled={markingDisabled}
        isExam={isExamAssessment}
        noFeedbackDate={
          isExamAssessment
            ? !sheet?.examFeedbackDate || sheet?.noExamFeedbackDate
            : !sheet?.returnDate
        }
        isBeforeFeedback={sheet ? isBeforeFeedbackDate(sheet) : false}
      />
    </>
  );
}
