import NiceModal from "@ebay/nice-modal-react";

import { EnrollmentFragment } from "@/generated/graphql";
import { ClassTab, TagHandlers } from "@/ui/class/progress/types";

import {
  ConnectedTagDeleteConfirmModal,
  ConnectedTagDeleteConfirmModalProps,
  TagDeleteIntent,
} from "../TagDeleteConfirmModal";
import { useAddTagMutation, useRemoveTagMutation } from "../useTagMutations";

/**
 * Returns functions to handle adding, removing, and deleting tags to be used in
 * class tables.
 */
export function useTagHandlers(enrollments: EnrollmentFragment[]): TagHandlers {
  const addTag = useAddTagMutation();
  const removeTag = useRemoveTagMutation();
  return {
    addTag,
    removeTag,
    deleteCustomTag: (text: string, tab: ClassTab) => {
      // Open a modal to confirm the intent to delete
      // Find all enrollments with the custom tag to remove.
      const enrollmentsWithTag = enrollments.filter(
        (e) => !!e.tags.find((tag) => tag.text === text)
      );
      const tagDeleteIntent: TagDeleteIntent = {
        tag: text,
        tab,
        enrollments: enrollmentsWithTag,
      };
      NiceModal.show(ConnectedTagDeleteConfirmModal, {
        tagDeleteIntent,
      } satisfies ConnectedTagDeleteConfirmModalProps);
    },
  };
}
