import { createContext, useContext } from "react";

import { Table } from "@tanstack/table-core";

import {
  EnrollmentFragment,
  WorkOutcomeClassFragment,
} from "@/generated/graphql";
import { StudentListRow } from "@/ui/class/progress/types";
import { TagConfig } from "@/ui/StudentLists/TagSelector";

export interface ModerationScoreHandler {
  /** Callback to update the work outcome score as a moderator. */
  onUpdateWorkOutcomeScore?: (workOutcomeId: string, newScore: number) => void;
  /** Callback to download filtered moderation metrics. */
  onDownloadMetricsData?: () => void;
  /** Moderation table instance. */
  table: Table<StudentListRow>;
  /** Unique tag configurations */
  tagConfigs: TagConfig[];
  /** Moderation ready enrollments */
  enrollments: EnrollmentFragment[];
  /** Moderation ready work outcomes */
  workOutcomes: WorkOutcomeClassFragment[];
  /** NO more changes in moderation. */
  isReadOnly: boolean;
}

export const ModerationScoreContext = createContext<ModerationScoreHandler>({
  table: {} as Table<StudentListRow>,
  tagConfigs: [],
  enrollments: [],
  workOutcomes: [],
  isReadOnly: false,
});

export const useModerationScoreContext = () =>
  useContext(ModerationScoreContext);

/**
 * Predicate hook to check if moderation should be readonly.
 */
export function useModerationIsReadOnly() {
  return useModerationScoreContext().isReadOnly;
}
