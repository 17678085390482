import { styled } from "styled-components";

import {
  colors,
  desktop,
  Divider,
  Modal,
  tablet,
  Text,
} from "@vericus/cadmus-ui";

import NiceModal from "@ebay/nice-modal-react";

import { Template, useSelectedCategory, useTemplate } from "@/data/templates";
import {
  AssessmentType,
  ExamTiming,
  RequirementsFragment,
} from "@/generated/graphql";

import { useOnSelectTemplate } from "../templatesHooks";
import {
  DoubleColumnPreview,
  Header,
  References,
  TemplateInfo,
} from "./components";
import {
  Container,
  HeaderContentsContainer,
  InfoContainer,
  PaddedPreviewContainer,
  StickyHeaderContainer,
  useOnBack,
  useOnCloseModal,
} from "./modals-shared";

export interface ConnectedTemplatePreviewModalProps {
  /** The id of the Cadmus template being previewed. */
  templateId: string;
}

export const ConnectedTemplatePreviewModal =
  NiceModal.create<ConnectedTemplatePreviewModalProps>(({ templateId }) => {
    const onGoBack = useOnBack();
    const closeModal = useOnCloseModal();
    const template = useTemplate(templateId);
    const category = useSelectedCategory();
    const onSelectTemplate = useOnSelectTemplate();

    if (!template) {
      return <></>;
    }

    return (
      <TemplatePreviewModal
        template={template}
        headerContents={
          <TemplateHeaderContents
            templateTitle={template.title}
            categoryPreviewDescription={category?.previewDescription ?? ""}
          />
        }
        onConfirm={onSelectTemplate}
        onGoBack={onGoBack}
        onClose={closeModal}
      />
    );
  });

export interface TemplatePreviewModalProps {
  /**
   * The Cadmus template being previewed.
   */
  template: Template;
  /**
   * Populated header with information about the template.
   */
  headerContents: JSX.Element;
  /**
   * Called when the user indicates they want to use the template being previewed.  */
  onConfirm: (templateId: string) => void;
  /** Called when the user wants to go back to where the modal was open from. */
  onGoBack: VoidFunction;
  /** Called when the user wants to close the modal. */
  onClose: VoidFunction;
}

/**
 * Modal to preview a Cadmus template.
 * **NOTE**: does not preview past assessments.
 */
export const TemplatePreviewModal = ({
  template,
  headerContents,
  onConfirm,
  onGoBack,
  onClose,
}: TemplatePreviewModalProps) => {
  const { id, description, content, color, title, references } = template;
  return (
    <Modal.Root
      open
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <StyledModalContent
        withAnimation={false}
        withOverlay={false}
        withCloseButton={false}
      >
        <Container backgroundColor={color}>
          <StickyHeaderContainer>
            <Header
              backgroundColor={color}
              confirmButtonText="Use instructions"
              onConfirm={() => onConfirm(id)}
              onGoBack={onGoBack}
            >
              {headerContents}
            </Header>
          </StickyHeaderContainer>

          <Divider />
          <TemplateInfo about={description} />

          <PaddedPreviewContainer>
            <DoubleColumnPreview
              isHtmlContent
              isTemplatePreview
              title={title}
              requirements={emptyRequirements}
              content={content}
              resources={[]}
            />
          </PaddedPreviewContainer>
          {references && (
            <PaddedReferencesContainer>
              <References document={references} />
            </PaddedReferencesContainer>
          )}
        </Container>
      </StyledModalContent>
    </Modal.Root>
  );
};

const StyledModalContent = styled(Modal.Content)`
  width: 575px;
  ${tablet`
    width: 936px;
  `}
  ${desktop`
    width: 1090px;
  `}
`;

interface TemplateHeaderContentProps {
  templateTitle: string;
  categoryPreviewDescription: string;
}

export const TemplateHeaderContents = ({
  templateTitle,
  categoryPreviewDescription,
}: TemplateHeaderContentProps) => {
  return (
    <HeaderContentsContainer>
      <Text kind="headingFour" style={{ paddingTop: 0 }}>
        {templateTitle}
      </Text>
      <InfoContainer>
        <Text kind="bodySm" style={{ color: colors.grey500 }}>
          {categoryPreviewDescription}
        </Text>
      </InfoContainer>
    </HeaderContentsContainer>
  );
};

const PaddedReferencesContainer = styled.div`
  padding-top: 40px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const emptyRequirements: RequirementsFragment = {
  id: "",
  assessmentType: AssessmentType.Assignment,
  enableExamAutoSubmission: false,
  enableSubmissionPdf: false,
  examEndDate: null,
  examStartDate: null,
  examReadingTime: null,
  examTiming: ExamTiming.Live,
  examType: [],
  examWritingTime: null,
  lateSubmissionTimeLimit: null,
  noExamReadingTime: true,
  dueDate: null,
  returnDate: null,
  maxGrade: 0,
  sViewReports: 0,
  anonymousMarking: null,
  gradingService: "",
  draftDueDate: null,
  noDraft: false,
  draftSViewReports: 0,
  weight: null,
  noWeight: false,
  wordLimit: null,
  noLimit: false,
  timeLimit: null,
  noTimeLimit: false,
  countReferences: false,
  referencingStyle: null,
  noReferencing: false,
  hasChanged: false,
  allowLateResubmission: false,
  examFeedbackDate: null,
  noExamFeedbackDate: true,
  enableExamLdb: null,
  enableExamAccessCode: false,
  restrictMarkingToolPdf: false,
  markingToolPdf: null,
};
