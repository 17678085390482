import client from "client/apollo";
import {
  CreateTaskSaveDocument,
  CreateTaskSaveMutation,
  CreateTaskSaveMutationVariables,
  EditorType,
} from "generated/graphql";

export function onSaveTask(assessmentId: string, content: string) {
  return client.mutate<CreateTaskSaveMutation, CreateTaskSaveMutationVariables>(
    {
      mutation: CreateTaskSaveDocument,
      variables: {
        assessmentId,
        content,
        editor: EditorType.Prosemirror,
      },
    }
  );
}
