import { Fragment, memo } from "react";
import { styled } from "styled-components";

import {
  CadmusEditorContent,
  Editor,
} from "@vericus/cadmus-editor-prosemirror";
import { Divider } from "@vericus/cadmus-ui";

import { EditorWrapper } from "../../../components/editor-wrapper";
import { Dispatch, QuestionFormField } from "../../state";
import { BodyDiv } from "../../styles";
import { BlankItem } from "./blank-item";

interface FillInBlanksQuestionBodyProps {
  editor: Editor | null;
  fields: QuestionFormField[];
  dispatch: Dispatch;

  /** Error when question promptDoc less than 20 characters */
  promptDocTooShort?: boolean;

  /** Ids of blanks that don't have phrases */
  blankWithoutPhrasesIds?: string[];
}

/** Question body for a fill in blank question */
function FillInBlanksQuestionBodyComponent(
  props: FillInBlanksQuestionBodyProps
) {
  const {
    editor,
    fields,
    dispatch,
    promptDocTooShort,
    blankWithoutPhrasesIds,
  } = props;
  return (
    <div>
      <BlanksEditorWrapper hasError={promptDocTooShort}>
        <CadmusEditorContent editor={editor} />
      </BlanksEditorWrapper>
      <Body>
        {fields.length !== 0 && (
          <AcceptResponsesList>
            {fields.map((field, index, arr) => (
              <Fragment key={field.identifier}>
                <BlankItem
                  field={field}
                  order={index + 1}
                  dispatch={dispatch}
                  blankWithoutPhrasesIds={blankWithoutPhrasesIds}
                />
                {index !== arr.length - 1 && <Divider />}
              </Fragment>
            ))}
          </AcceptResponsesList>
        )}
      </Body>
    </div>
  );
}

export const FillInBlanksQuestionBody = memo(FillInBlanksQuestionBodyComponent);

const Body = styled(BodyDiv)`
  row-gap: 16px;
  padding: 0px 45px;
`;

const BlanksEditorWrapper = styled(EditorWrapper)`
  margin: 16px 0px;
`;

const AcceptResponsesList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;
`;
