import { addMinutes, differenceInMinutes, isAfter } from "date-fns";
import {
  EnrollmentFragment,
  ExamTiming,
  WorkFragment,
} from "generated/graphql";
import { datesDuration, toDate } from "utils/datetime";

import { EnrollmentSettings } from "@/graphql/types/EnrollmentSettings";
import { ProgressStage } from "@/ui/class/progress/types";

/**
 * Human readable submission time lateness message, if the submission is late.
 *
 * @param timestamp Submission timestamp
 * @param dueDate Due date for the submission.
 * @returns [the lateness text if true, null otherwise, lateby minutes if true, null otherwise].
 */
export function lateMessage(
  timestamp: string,
  dueDate: Date
): [string, number] | null {
  const timeStampDate = new Date(timestamp);
  const when = datesDuration(timeStampDate, dueDate);
  return when === "on time"
    ? null
    : [`${when} late`, differenceInMinutes(timeStampDate, dueDate)];
}

/**
 * Human readable exam submission time lateness message, if the submission is late.
 *
 * @param work Student work details
 * @param settings consolidated sheet & work Settings
 * @returns Tuple with [the lateness text, number of minutes late] or `null` if not late.
 */
export function examLateMessage(
  work: WorkFragment,
  settings: EnrollmentSettings
): [string, number] | null {
  const {
    readingTime,
    writingTime,
    examTiming,
    alternateExamEndDate,
    alternateExamStartDate,
    sheetExamStartDate,
    sheetExamEndDate,
  } = settings;
  const submittedAt = toDate(work.final?.submittedAt);
  let startDate: Date | null = null;
  let endDate: Date | null = null;
  let cutoffDate: Date | null = null;

  if (!submittedAt) return null;

  if (examTiming === ExamTiming.Window) {
    startDate = toDate(work.startDate);
    endDate = alternateExamEndDate ? alternateExamEndDate : sheetExamEndDate;
  } else {
    startDate = alternateExamStartDate
      ? alternateExamStartDate
      : sheetExamStartDate;
  }

  if (!startDate || !submittedAt) return null;

  const examTime = (readingTime ?? 0) + (writingTime ?? 0);
  cutoffDate = addMinutes(startDate, examTime);
  if (endDate && isAfter(cutoffDate, endDate)) {
    cutoffDate = endDate;
  }
  const when = datesDuration(submittedAt, cutoffDate);

  return when === "on time"
    ? null
    : [`${when} late`, differenceInMinutes(submittedAt, cutoffDate)];
}

/**
 * Exam end date for both live and window exam.
 * @param Settings consolidated sheet & work settings
 * @returns exam end date if exist otherwise null if live exam doesn't have a start
 */
export function getExamEndDate(settings: EnrollmentSettings) {
  const {
    readingTime,
    writingTime,
    examTiming,
    alternateExamEndDate,
    alternateExamStartDate,
    sheetExamStartDate,
    sheetExamEndDate,
  } = settings;
  if (examTiming === ExamTiming.Window) {
    return alternateExamEndDate ? alternateExamEndDate : sheetExamEndDate;
  } else {
    const startDate = alternateExamStartDate
      ? alternateExamStartDate
      : sheetExamStartDate;
    const examTime = (readingTime ?? 0) + (writingTime ?? 0);
    if (startDate) {
      return addMinutes(startDate, examTime);
    }
  }
  return null;
}

/**
 * Human readable submission time overtime message, if the submission is over
 * the time limit.
 *
 * @param timeSpent Time spent in minutes
 * @param timeLimit Total time limit available for the submission
 * @returns the overtime message text if true, null otherwise.
 */
export function overtimeMessage(
  timeSpent: number,
  timeLimit: number
): string | null {
  const overtime =
    timeSpent && timeSpent > timeLimit ? timeSpent - timeLimit : null;

  // TODO format the duration like `lateMessage`
  if (overtime) {
    const now = new Date();
    const when = datesDuration(addMinutes(now, overtime), now);

    return when === "on time" ? null : `${when} overtime`;
  }

  return null;
}

/**
 * Calculate the work duration in minutes
 */
export function getWorkDuration(
  submittedAt: Date,
  startDate: Date
): number | null {
  const duration = differenceInMinutes(submittedAt, startDate);
  if (duration < 0) return null;
  // XXX When startDate is a few seconds after submittedAt, duration = -0
  return Math.abs(duration);
}

/**
 * Ensure the last name is not an empty string by computing it from the full
 * name if necessary.
 *
 * @param user Enrollment user
 * @return non empty string which can be used as the last name.
 */
export function userLastName(user: EnrollmentFragment["user"]) {
  const name = user.name.trim();
  const familyName = user.familyName.trim();

  if (familyName !== "") {
    return familyName;
  }

  const parts = name.split(/\s+/);
  return parts.length > 0 ? parts[parts.length - 1] : "";
}

/**
 * Show a ProgressStage enum as a human-readably string.
 */
export const progressStageToString = (progress: ProgressStage): string => {
  switch (progress) {
    case ProgressStage.Accessed:
      return "Accessed";
    case ProgressStage.Draft:
      return "Draft";
    case ProgressStage.Enrolled:
      return "Enrolled";
    case ProgressStage.FeedbackViewed:
      return "Feedback Viewed";
    case ProgressStage.Final:
      return "Final";
    case ProgressStage.Started:
      return "Started";
  }
};
