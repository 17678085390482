import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GroupFragment, GroupType } from "@/generated/graphql";

import {
  initMarkGroups,
  LoadGroupsPayload,
  MarkingGroup,
} from "./init-mark-groups";

/** State for the markSlice. */
export interface MarkSliceState {
  currentUserId: string | null;
  /** Displayed Question IDs for the current user. */
  currentQuestionIds: string[];
  groups: MarkingGroup[];
  studentWorks: { studentId: string; workId: string }[];
  currentMarkerId: string | null;
  groupType: GroupType | null;
}

export const initialState: MarkSliceState = {
  currentQuestionIds: [],
  currentUserId: null,
  groups: [],
  studentWorks: [],
  currentMarkerId: null,
  groupType: null,
};

export const markSlice = createSlice({
  name: "mark",
  initialState,
  reducers: {
    loadGroups(state, action: PayloadAction<LoadGroupsPayload>) {
      const groups = initMarkGroups(action.payload);
      state.groups = groups;
      state.studentWorks = action.payload.studentWorks;
      state.groupType = action.payload.groupType;
    },
    filterQuestionsByMarkerGroups(
      state,
      action: PayloadAction<{
        groups: GroupFragment[];
        currentMarkerId: string;
        groupType: GroupType | null;
      }>
    ) {
      const { groups, currentMarkerId, groupType } = action.payload;
      if (groupType === GroupType.Question) {
        const filteredGroups = groups.filter(
          (group) => group.markerId === currentMarkerId
        );
        state.currentQuestionIds = filteredGroups.flatMap(
          (group) => group.questionId ?? []
        );
      }
    },
    updateCurrentUserId(state, action: PayloadAction<string | null>) {
      state.currentUserId = action.payload;
    },
    setCurrentMarkerId(state, action: PayloadAction<string | null>) {
      state.currentMarkerId = action.payload;
    },
    setDisplayedQuestionIds(state, action: PayloadAction<string[]>) {
      state.currentQuestionIds = action.payload;
    },
  },
});

export const {
  loadGroups,
  updateCurrentUserId,
  setDisplayedQuestionIds,
  filterQuestionsByMarkerGroups,
  setCurrentMarkerId,
} = markSlice.actions;

export const markReducer = markSlice.reducer;
