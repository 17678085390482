import { createClient } from "contentful";

import * as config from "@/config";

const isPreview = config.DEV || config.CONTENTFUL_PREVIEW === "true";

export const contentfulClient = createClient({
  space: config.CONTENTFUL_SPACE_ID,
  accessToken: isPreview
    ? config.CONTENTFUL_PREVIEW_ACCESS_TOKEN
    : config.CONTENTFUL_ACCESS_TOKEN,
  host: isPreview ? "preview.contentful.com" : undefined,
});
