import { useCallback } from "react";

import NiceModal from "@ebay/nice-modal-react";

import { hideNames, revealNames } from "@/data/class";
import { AppDispatch } from "@/data/store";
import {
  RevealScope,
  RevealStudentConfirmationModal,
  RevealStudentConfirmationModalProps,
} from "@/ui/integrity-assurance/RevealStudentConfirmationModal";

/**
 * Returns functions to handle student anonymity in the class tables.
 */
export function useAnonymityHandlers(dispatch: AppDispatch) {
  const revealEnrollment = useCallback(
    (id: string) => {
      NiceModal.show(RevealStudentConfirmationModal, {
        onReveal: () => dispatch(revealNames([id])),
        scope: RevealScope.ONE,
      } satisfies RevealStudentConfirmationModalProps);
    },
    [dispatch]
  );

  const revealEnrollments = useCallback(
    (ids: string[], scope: RevealScope) => {
      NiceModal.show(RevealStudentConfirmationModal, {
        onReveal: () => dispatch(revealNames(ids)),
        scope: scope,
      } satisfies RevealStudentConfirmationModalProps);
    },
    [dispatch]
  );

  const hideEnrollments = useCallback(
    (ids: string[]) => {
      dispatch(hideNames(ids));
    },
    [dispatch]
  );

  return {
    revealEnrollment,
    revealEnrollments,
    hideEnrollments,
  };
}
