import { ReactNode, useCallback } from "react";

import { DropdownMenu, SelectButton } from "@vericus/cadmus-ui";

import { useJitsu } from "@jitsu/react";
import { Column } from "@tanstack/react-table";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import {
  FilterValue,
  PredicateFilter,
  StudentListRow,
} from "@/ui/class/progress/types";
import { Counter } from "@/ui/shared/Counter";

import { useOnClearFilter } from "../hooks";

interface PredicateFilterDropdownProps {
  /**
   * Select button label
   */
  label: string;
  column: Column<StudentListRow>;
  /**
   * Selection list of predicates.
   */
  items: ReactNode;
}

/**
 * Generic dropdown filters selection for setting `PredicateFilter[]` values on
 * a Class List Table column.
 */
export const PredicateFilterDropdown = ({
  label,
  column,
  items,
}: PredicateFilterDropdownProps) => {
  const filters = (column.getFilterValue() as FilterValue) ?? [];
  const onClear = useOnClearFilter(column, label);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <SelectButton selected={filters.length > 0} activeColor="lilac500">
          {label}{" "}
          {filters?.length > 0 && <Counter active>{filters.length}</Counter>}
        </SelectButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {items}
        <DropdownMenu.LinkItem
          content="Clear filters"
          color="lilac500"
          onSelect={(e) => {
            e.preventDefault();
            onClear();
          }}
        />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

interface PredicateDropdownItemProps {
  /**
   * The filter.
   */
  predicate: PredicateFilter;
  /**
   * Whether the filter is selected/active.
   */
  selected: boolean;
  /**
   * Called when the user toggles the filter, and the filter is not disabled.
   */
  onToggle: (filter: PredicateFilter) => void;
}

/**
 * Renders a predicate filter as a dropdown menu item.
 */
export function PredicateDropdownItem({
  predicate,
  selected,
  onToggle,
}: PredicateDropdownItemProps) {
  return (
    <DropdownMenu.CheckboxItem
      key={predicate.label}
      disabled={predicate.disabled}
      checked={selected}
      onSelect={(e) => {
        e.preventDefault();
        !predicate.disabled && onToggle(predicate);
      }}
      content={predicate.label}
    />
  );
}

/**
 * Toggle a predicate filter on the column.
 */
export const useToggleFilter = (
  column: Column<StudentListRow>,
  filterGroup: string
) => {
  const { track } = useJitsu();
  return useCallback(
    (filter: PredicateFilter) => {
      column.setFilterValue((existing: FilterValue) => {
        let updated: FilterValue = undefined;
        if (existing && existing.find((f) => f.label === filter.label)) {
          updated = existing.filter((f) => f.label !== filter.label);
        } else {
          updated = [filter, ...(existing ?? [])];
        }
        track(JitsuEvent.CLASS_FILTER_UPDATED, {
          filter_group: filterGroup,
          filter_toggled: filter.label,
          filter_value: updated.map((f) => f.label),
          filter_type: "dropdown",
        });
        return updated.length > 0 ? updated : undefined;
      });
    },
    [column, track, filterGroup]
  );
};
