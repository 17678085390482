import { ReactNode } from "react";
import { styled } from "styled-components";

import {
  colors,
  ControlButton,
  desktop,
  DropdownMenu,
  Icon,
  mobile,
  tablet,
} from "@vericus/cadmus-ui";

import { useJitsu } from "@jitsu/react";
import { JitsuEvent } from "data/events/JitsuEvent";
import { GroupFragment } from "generated/graphql";

import * as config from "@/config";
import { useRootNavigate } from "@/router/routing";
import { StudentListMeta } from "@/ui/class/progress/types";

import { FiltersSummary } from "../FiltersSummary";
import { ClassTable } from "../table";
import { GroupFilter, SearchFilter } from "./components";

export interface ClassListFilterProps {
  table: ClassTable;
  groups: GroupFragment[];
  /**
   * Filters specific to the table instance.
   * Rendered to the left.
   */
  leftFilters: ReactNode;
  /**
   * If false, the right filters dropdown won't be rendered.
   */
  showRightFiltersMenu?: boolean;
  /**
   * Additional filters specific to the table instance, if any.
   * Rendered in the dropdown to the right.
   */
  rightFilters?: ReactNode;
}

/**
 * Lays out the filters passed in as props and connects some logic.
 */
export const ClassListFilters = ({
  table,
  groups,
  leftFilters,
  rightFilters,
  showRightFiltersMenu,
}: ClassListFilterProps) => {
  const { track } = useJitsu();
  const rootNavigate = useRootNavigate();

  const { viewAssessmentReport } = table.options.meta! as StudentListMeta;

  return (
    <FiltersRoot data-testid={"ClassListFiltersRoot"}>
      <FiltersGroup>
        <FiltersLeft data-testid={"ClassListFilters-Left"}>
          <FilterIcon />
          <GroupFilter table={table} groups={groups} />
          {leftFilters}
        </FiltersLeft>
        <FiltersRight data-testid={"ClassListFilters-Right"}>
          <StyledSearchFilter table={table} />
          {showRightFiltersMenu && (
            <DropdownMenu.Root
              data-testid={"ClassListFilters.StudentsDropdown"}
            >
              <DropdownMenu.Trigger asChild>
                <ControlButton iconName="More" aria-label="More" />
              </DropdownMenu.Trigger>
              <DropdownMenu.Content align="end" avoidCollisions>
                {rightFilters}
                {config.IA_ENABLED ? (
                  <>
                    <DropdownMenu.Separator />
                    <DropdownMenu.Item
                      data-component={
                        "ClassListFilters.view-assessment-analytics"
                      }
                      iconName="Search"
                      content="View assessment analytics"
                      onSelect={() => {
                        rootNavigate("/integrity-assurance");
                      }}
                    />
                  </>
                ) : (
                  <>
                    <DropdownMenu.Separator />
                    <DropdownMenu.Item
                      data-component={
                        "ClassListFilters.download-assessment-report"
                      }
                      iconName="Download"
                      content="Download Assessment Report"
                      onSelect={() => {
                        viewAssessmentReport();
                        track(JitsuEvent.REPORT_REQUESTED, {
                          report_type: "ASSESSMENT_SUMMARY",
                        });
                      }}
                    />
                  </>
                )}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          )}
        </FiltersRight>
      </FiltersGroup>
      <FiltersSummary table={table} />
      <FilterDivider />
    </FiltersRoot>
  );
};

export const ModerationScoreFiltersLayout = ({
  table,
  groups,
  leftFilters,
  rightFilters,
  showRightFiltersMenu,
}: ClassListFilterProps) => {
  return (
    <FiltersRoot data-testid={"ModerationListRoot"}>
      <FiltersGroup>
        <FiltersLeft data-testid={"ModerationList-Left"}>
          <FilterIcon />
          <GroupFilter table={table} groups={groups} />
          {leftFilters}
        </FiltersLeft>
        <FiltersRight data-testid={"ModerationList-Right"}>
          <StyledSearchFilter table={table} />
          {showRightFiltersMenu && (
            <DropdownMenu.Root data-testid={"ModerationList.StudentsDropdown"}>
              <DropdownMenu.Trigger asChild>
                <ControlButton iconName="More" aria-label="More" />
              </DropdownMenu.Trigger>
              <DropdownMenu.Content align="end" avoidCollisions>
                {rightFilters}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          )}
        </FiltersRight>
      </FiltersGroup>
      <FiltersSummary table={table} />
      <FilterDivider />
    </FiltersRoot>
  );
};

/**
 * Indicates that filters are close to the component.
 */
const FilterIcon = () => (
  <div style={{ width: 36, textAlign: "center" }}>
    <Icon iconName="Filter" iconColor="grey500" />
  </div>
);

const FiltersRoot = styled.div`
  display: flex;
  top: 0;
  flex-direction: column;
  background: white;
  z-index: 1;
`;

const StyledSearchFilter = styled(SearchFilter)``;

const FiltersGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
  justify-content: space-between;
  overflow-x: auto;

  ${desktop`
    margin-top: 16px;
    flex-wrap: wrap;
  `}

  ${tablet`
    flex-direction: column-reverse;
    gap: 0;
    margin-top: 8px;

    & ${StyledSearchFilter} {
      width: 100%;
    }
  `}

  ${mobile`
    flex-direction: column-reverse;
    gap: 0;
    margin-top: 8px;

    & ${StyledSearchFilter} {
      width: 100%;
    }
  `}
`;

const FiltersLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  flex-wrap: wrap;
`;

const FiltersRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;

  width: 100%;

  ${desktop`
    width: auto;
  `}
`;

const FilterDivider = styled.hr`
  background-color: ${colors.grey300};
  border: 0;
  height: 1px;
  margin-top: 8px;
`;
