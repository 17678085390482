import { __GLOBAL_TENANT } from "client/globals";
import { ResourceFragment } from "generated/graphql";

import * as config from "@/config";

/** Validate a URL. */
export const validateLink = (link: string): boolean => {
  const regex = /^https?:\/\/[^\s]+$/;
  const match = link.match(regex);
  return Boolean(match);
};

/** Create the backend URL for accessing a resource. */
export const getResourceUrl = (resourceId: string): string =>
  `${config.API_ENDPOINT}/api/resource/${resourceId}?role=lecturer&tenant=${__GLOBAL_TENANT.current}`;

/** Helper to open a URL programmatically in a new window. */
export const openUrl = (url: string) => {
  window.open(url, "_blank");
};

/** e.g. "psyc-presentation" => "psyc-presentation.pptx" */
export const addExtension = (filename: string, extension: string): string => {
  const shouldAddExtension = !filename.endsWith(extension);
  return shouldAddExtension ? `${filename}.${extension}` : filename;
};

/** e.g. "psyc-presentation.pptx" => "psyc-presentation" */
export const removeExtension = (filename: string): string => {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return filename;
  }
  return filename.slice(0, lastDotIndex);
};

/** Gets an extension from a filename. e.g.:
 * "presentation.pptx" => "pptx"
 */
export const getExtension = (filename: string): string | undefined => {
  if (!filename.includes(".")) {
    return;
  }
  if (filename.lastIndexOf(".") === filename.length - 1) {
    return;
  }
  const splitName = filename.split(".");
  const extension = splitName[splitName.length - 1];
  return extension;
};

export const isLink = (resource: ResourceFragment) => !!resource.url;

/**
 * Formats the number of bytes to something like 2.10KB, 4.90MB, etc.
 * @param size number of bytes
 * @returns
 */
export const formatFileSize = (size: number): string => {
  const units = ["B", "KB", "MB", "GB", "TB"];
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  const formattedBytes = (size / Math.pow(1024, i)).toFixed(2);
  const unit = units[i];
  return `${formattedBytes}${unit}`;
};
