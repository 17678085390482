import { Editor } from "@vericus/cadmus-editor-prosemirror";

import { makeVar, useReactiveVar } from "@apollo/client";

/** State for active prompt Editor. */
export interface ActivePromptEditor {
  /** Task Block ID */
  id: string;
  /** Editor instance for the prompt input in the TaskBlock. */
  editor: Editor;
}

/** Reactive variable storing the current active prompt editor, if any. */
export const activePromptEditorVar = makeVar<ActivePromptEditor | null>(null);

/** Read the current active prompt editor. */
export const useActivePromptEditor = () =>
  useReactiveVar(activePromptEditorVar);

/** Set an editor to be the active prompt editor. */
export function setActivePromptEditor(id: string, editor: Editor | null) {
  const activeEditor = activePromptEditorVar();
  if (activeEditor?.id === id) {
    if (!editor) {
      activePromptEditorVar(null);
    }
    return;
  }
  if (!editor) return;
  activePromptEditorVar({ id, editor });
}
