import { memo } from "react";
import { styled } from "styled-components";

import {
  CadmusEditorContent,
  Editor,
} from "@vericus/cadmus-editor-prosemirror";
import { Text } from "@vericus/cadmus-ui";

import { EditorWrapper } from "../../components/editor-wrapper";
import { BodyDiv } from "../styles";

interface SectionQuestionProps {
  editor: Editor | null;
  totalPoints?: number;
  title?: string;
  /** Error when question promptDoc less than 20 characters */
  promptDocTooShort?: boolean;
}

/**
 * Section question block
 */
const SectionQuestionBodyComponent = ({
  editor,
  title,
  totalPoints,
  promptDocTooShort,
}: SectionQuestionProps) => {
  return (
    <Root>
      <ContentWrapper>
        <Text kind="headingThree" as={"span"}>
          {title ?? "Section"}
        </Text>
        {totalPoints !== undefined && (
          <Text
            kind="headingSix"
            color={totalPoints === 0 ? "navy400" : "navy500"}
            as="span"
          >
            {`${totalPoints} total point${totalPoints > 1 ? "s" : ""}`}
          </Text>
        )}
      </ContentWrapper>
      <SectionEditorWrapper hasError={promptDocTooShort}>
        <CadmusEditorContent editor={editor} />
      </SectionEditorWrapper>
    </Root>
  );
};

export const SectionQuestionBody = memo(SectionQuestionBodyComponent);

const Root = styled(BodyDiv)`
  row-gap: 8px;
`;

const SectionEditorWrapper = styled(EditorWrapper)`
  min-height: 52px;
  margin-top: 4px;
  position: relative;
  border-radius: 4px;
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: 0px 45px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
`;
