import { styled } from "styled-components";

import {
  Button,
  colors,
  Divider,
  Pill,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import {
  DuplicatePastAssessmentCallback,
  PreviewPastAssessmentCallback,
} from "@/data/templates";
import { ExamTiming, SubjectFragment } from "@/generated/graphql";
import { DisplayTag } from "@/ui/StudentLists/TagSelector";
import { formatCondensedDate } from "@/utils/datetime";

/**
 * The display component to show the user's past assessment.
 * Displays some general information about the assessment.
 *
 * The user can action on the card:
 * - Duplicate the assessment
 * - Preview the assessment
 */
export interface PastExamCardProps {
  /**
   * The assessment ID
   */
  assessmentId: string;
  /**
   * The instruction sheet version relating to the assessment in question
   */
  instructionSheetVersionId: string;
  /**
   * The name of the assessment
   */
  name: string;
  /**
   * The subject of the assessment
   */
  subject: SubjectFragment;
  /**
   * The start date/time of the exam
   */
  examStartDate: Date | null;
  /**
   * The exam end date of the exam;
   * This value is displayed to the user if the exam is a window exam.
   * Also, this is used to calculate whether the exam is considered closed.
   */
  examEndDate: Date | null;
  /**
   * Callback for when the user intends to duplicate the exam
   * @param assessmentId
   */
  onDuplicate: DuplicatePastAssessmentCallback;
  /**
   * Callback for when the user intends to preview the exam
   * @param assessmentId
   */
  onPreview: PreviewPastAssessmentCallback;

  /**
   * The timing of the exam. Live or Window
   */
  examTiming: ExamTiming;
}

/**
 * A component to represent a past assessment.
 */
export const PastExamCard = (props: PastExamCardProps) => {
  return (
    <Container>
      <TopContainer>
        <Text kind={"headingFour"}>{props.name}</Text>
        <Text kind={"label"}>{props.subject.name}</Text>
        <Spacer spacing={16} />
        {props.examTiming === ExamTiming.Window && (
          <>
            {props.examStartDate && (
              <Text kind="bodySm">
                <b>Exam open:</b> {formatCondensedDate(props.examStartDate)}
              </Text>
            )}
            {props.examEndDate && (
              <Text kind="bodySm">
                <b>Exam close:</b> {formatCondensedDate(props.examEndDate)}
              </Text>
            )}
          </>
        )}
        {props.examTiming === ExamTiming.Live && (
          <>
            {props.examStartDate && (
              <Text kind="bodySm">
                <b>Exam start:</b> {formatCondensedDate(props.examStartDate)}
              </Text>
            )}
          </>
        )}
        <Spacer spacing={12} />
        <Divider />
        <Spacer spacing={12} />
        <TagsContainer>
          {generateTags(props).map((tag, index) => {
            return (
              <Pill key={index} color={tag.color}>
                <Text kind={"label"}>{tag.value}</Text>
              </Pill>
            );
          })}
        </TagsContainer>
      </TopContainer>
      <BottomContainer>
        <ControlBar>
          <PreviewButton
            kind={"outline"}
            onClick={() =>
              props.onPreview(
                props.assessmentId,
                props.name,
                props.instructionSheetVersionId
              )
            }
          >
            Preview
          </PreviewButton>
          <DuplicateButton
            kind={"secondary"}
            onClick={() =>
              props.onDuplicate(
                props.assessmentId,
                props.name,
                props.instructionSheetVersionId
              )
            }
          >
            Duplicate
          </DuplicateButton>
        </ControlBar>
      </BottomContainer>
    </Container>
  );
};

const TopContainer = styled.div``;
const BottomContainer = styled.div`
  position: relative;
`;

// Default state when mouse is not hovering on the container
const ControlBar = styled.div`
  position: absolute;
  bottom: -10px;
  opacity: 0;
  width: 100%;
  display: flex;
  gap: 8px;
  background-color: white;
  z-index: 1;

  transition: bottom 0.2s ease-in, opacity 0.2s ease-in;

  & > * {
    flex-grow: 1;
  }
`;

const PreviewButton = styled(Button)`
  border-color: ${colors.black200};
  color: ${colors.black200};
  &:not(:disabled):after {
    box-shadow: none;
  }
`;

const DuplicateButton = styled(Button)`
  &:not(:disabled):hover:after {
    opacity: 0.5;
  }
`;

const Container = styled.div`
  border: 1px solid #5973a65c;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;

  &:focus,
  &:active,
  &:hover {
    & ${ControlBar} {
      opacity: 1;
      bottom: 0;
    }
  }
`;

const TagsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const generateTags = (props: PastExamCardProps): DisplayTag[] => {
  const releasedTag: DisplayTag = {
    value: "released",
    active: true,
    color: colors.green200,
  };
  const tags: DisplayTag[] = [releasedTag];

  if (props.examEndDate && new Date() > props.examEndDate) {
    tags.push({
      value: "closed",
      active: true,
      color: colors.grey100,
    });
  }

  return tags;
};
