import { useMemo } from "react";

import { isAfter } from "date-fns";
import {
  AssessmentType,
  EnrollmentFragment,
  ExamTiming,
  ClassTab as GQLClassTab,
  GroupFragment,
  InstructionSheetFragment,
  MarkingSheetFragment,
  ModerationPenaltyRule,
  ReleasedRequirementsFragment,
  WorkOutcomeClassFragment,
} from "generated/graphql";
import { getMaxDate, toDate } from "utils/datetime";

import { getStudentApplicableRulesMap } from "@/features/moderation/pages";
import { EnrollmentSubmitLateByMap } from "@/features/moderation/pages/penalty-setup-page/use-enrollment-submit-lateby";
import { createEnrollmentSettings } from "@/graphql/types/EnrollmentSettings";
import {
  createSubmissionDetails,
  SubmissionDetails,
} from "@/graphql/types/SubmissionDetails";
import { calcProgressStage } from "@/ui/class/progress/components/ProgressPill";
import {
  ClassTab,
  ProgressFlags,
  StudentListRow,
} from "@/ui/class/progress/types";

import { lateMessage, userLastName } from "../helpers";
import { createGlobalTagConfigs, TagConfig } from "../TagSelector";

type ClassListRows = {
  /** Row data to populate a class list table. */
  rows: StudentListRow[];
  /** Global configuration on all known tags (predefined and custom). */
  tagConfigs: TagConfig[];
};

export function useMarkingTableRows(
  enrollments: EnrollmentFragment[],
  groups: GroupFragment[],
  revealedEnrollmentIds: string[] = [],
  sheet?: ReleasedRequirementsFragment
): ClassListRows {
  return useMemo(() => {
    const hasAnonMarking = sheet?.anonymousMarking === true;
    const rows = enrollments
      .map((enrollment, index) =>
        mapClassListRow(index + 1, enrollment, groups, sheet)
      )
      .filter((row) => !!row.final)
      .map((row) => ({
        ...row,
        tab: ClassTab.Finals,
        tags: row.tags.filter((tag) => tag.tab === GQLClassTab.Finals),
      }));

    const potentiallyAnonymisedRows = hasAnonMarking
      ? rows.map((row) => anonymiseStudent(row, revealedEnrollmentIds))
      : rows;
    const tagConfigs = createGlobalTagConfigs(enrollments);
    return {
      rows: potentiallyAnonymisedRows,
      tagConfigs,
    };
  }, [enrollments, groups, sheet, revealedEnrollmentIds]);
}

export function useDraftsTableRows(
  enrollments: EnrollmentFragment[],
  groups: GroupFragment[],
  revealedEnrollmentIds: string[] = [],
  sheet?: InstructionSheetFragment
): ClassListRows {
  return useMemo(() => {
    const hasAnonMarking = sheet?.anonymousMarking === true;
    const rows = enrollments
      .map((enrollment, index) =>
        mapClassListRow(index + 1, enrollment, groups, sheet)
      )
      .filter((row) => !!row.draft)
      .map((row) => ({
        ...row,
        tab: ClassTab.Drafts,
        tags: row.tags.filter((tag) => tag.tab === GQLClassTab.Drafts),
      }));
    const potentiallyAnonymisedRows = hasAnonMarking
      ? rows.map((row) => anonymiseStudent(row, revealedEnrollmentIds))
      : rows;
    const tagConfigs = createGlobalTagConfigs(enrollments);
    return { rows: potentiallyAnonymisedRows, tagConfigs };
  }, [enrollments, groups, sheet, revealedEnrollmentIds]);
}

export function useProgressTableRows(
  enrollments: EnrollmentFragment[],
  groups: GroupFragment[],
  sheet?: ReleasedRequirementsFragment
): ClassListRows {
  return useMemo(() => {
    const rows = enrollments.map((enrollment, index) => ({
      ...mapClassListRow(index + 1, enrollment, groups, sheet),
      tags: [],
    }));
    const tagConfigs = createGlobalTagConfigs(enrollments);
    return {
      rows,
      tagConfigs,
    };
  }, [enrollments, groups, sheet]);
}

export interface ModerationScoreTableHookProps {
  enrollments: EnrollmentFragment[];
  groups: GroupFragment[];
  markingSheet?: MarkingSheetFragment;
  workOutcomes: WorkOutcomeClassFragment[];
  revealedEnrollmentIds: string[];
  moderationPenaltyRules: ModerationPenaltyRule[];
}

/**
 * Filters all the student rows to only include those that have work outcomes
 */
export function useModerationScoreTableRows(
  rows: StudentListRow[],
  workOutcomes: WorkOutcomeClassFragment[],
  penaltyRules: ModerationPenaltyRule[]
): StudentListRow[] {
  return useMemo(() => {
    const minutesLateByMap = rows.reduce((acc, cur) => {
      acc[cur.enrollment.id] = cur.final?.minutesLateBy ?? null;
      return acc;
    }, {} as EnrollmentSubmitLateByMap);

    const studentApplicableRulesMap = getStudentApplicableRulesMap(
      penaltyRules,
      rows.map((row) => row.enrollment),
      minutesLateByMap
    );

    const rowsWithOutcomesAndPenalties = rows.map((row) => {
      let workOutcome = workOutcomes.find((wo) => wo.workId === row.workId);
      if (workOutcome) {
        // When feedback not yet releasd
        // finalScorePenalty would be calculate from penalty rules
        if (workOutcome.feedbackReleasedTimestamp === null) {
          const penalties =
            studentApplicableRulesMap[row.enrollment.user.id] ?? [];
          const penaltyScore = penalties.reduce(
            (acc, cur) => acc + (cur.penaltyScore ?? 0),
            0
          );
          // Trunc the score to 0 if it is negative
          let finalScore =
            workOutcome.score === null ? 0.0 : workOutcome.score - penaltyScore;
          if (finalScore < 0) {
            finalScore = 0;
          }
          workOutcome = {
            ...workOutcome,
            finalScorePenalty: penaltyScore,
            finalScore,
          };
        }

        return {
          ...row,
          workOutcomeSummary: workOutcome,
        };
      }
      return row;
    });

    return rowsWithOutcomesAndPenalties;
  }, [rows, workOutcomes, penaltyRules]);
}
/**
 * Normalise enrollment data on a student to a flatter Class List row type.
 *
 * This type is more suited to rendering and filtering/sorting in the Class List
 * data, and provides all the information needed to render a row.
 *
 * @param enrollment complete backend enrollment data on a student including
 *   user, work, submissions, result .etc.
 * @param rowNumber incrementing row number
 * @param groups all current groups data
 * @param sheet currently released sheet, if any
 */
export function mapClassListRow(
  rowNumber: number,
  enrollment: EnrollmentFragment,
  groups: GroupFragment[],
  sheet?: ReleasedRequirementsFragment
): StudentListRow {
  // Lookup enrollment group
  const group = groups?.find(
    (group) => !!group.members.find(({ id }) => id === enrollment.user.id)
  );

  // Extract and validate enrollment settings
  const enrollmentSettings = createEnrollmentSettings(enrollment, sheet);
  const isExam = sheet?.assessmentType === AssessmentType.Exam;
  const deferred = enrollmentSettings?.deferred ?? false;
  const hasExtraTime =
    Boolean(enrollmentSettings.extraExamWritingTime) ||
    Boolean(enrollmentSettings.extraExamReadingTime);
  const hasAlternativeStart = Boolean(
    enrollmentSettings.alternateExamStartDate
  );
  const hasAlternativeWindow =
    Boolean(enrollmentSettings.alternateExamStartDate) ||
    Boolean(enrollmentSettings.alternateExamEndDate);

  const now = new Date();

  // Compute last activity timestamp for the user based on various event
  // timestamps
  const draftResultAccess = enrollment.work?.draft?.result?.feedbackAccess;
  const finalResultAccess = enrollment.work?.final?.result?.feedbackAccess;
  const lastActivity = getMaxDate([
    enrollment.work?.startDate,
    enrollment.work?.updatedAt,
    enrollment.work?.lastSaveTime,
    draftResultAccess?.lastAccess,
    finalResultAccess?.lastAccess,
    enrollment.work?.draft?.submittedAt,
    enrollment.work?.final?.submittedAt,
  ]);

  // Due dates
  const draftDueDate = toDate(sheet?.draftDueDate);

  let draft: SubmissionDetails | null = null;
  if (draftDueDate && enrollment.work?.draft) {
    const lateness = lateMessage(
      enrollment.work.draft.submittedAt,
      draftDueDate
    );
    draft = {
      id: enrollment.work.draft.id,
      dueDate: draftDueDate,
      dueExtended: false,
      timeLimit: null,
      timeSpentMinutes: null,
      timeExtended: false,
      overtime: null,
      timestamp: new Date(enrollment.work.draft.submittedAt),
      late: lateness ? lateness[0] : null,
      result: enrollment.work.draft.result,
      feedbackLastViewed: toDate(draftResultAccess?.lastAccess),
      feedbackViewCount: draftResultAccess?.accessCount ?? 0,
      forceSubmitted: false,
      hasExtraTime,
      hasAlternativeWindow,
      hasAlternativeStart,
      deferred,
      pdf: enrollment.work.draft.pdf,
      markingPdf: enrollment.work.draft.markingPdf,
      minutesLateBy: lateness ? lateness[1] : null,
    };
  }

  let final: SubmissionDetails | null = null;
  const dueDate =
    enrollmentSettings.finalExtension ?? enrollmentSettings.sheetFinalDue;
  const timeLimit =
    enrollmentSettings.timeExtension ?? enrollmentSettings.sheetTimeLimit;

  if (dueDate && enrollment.work?.final) {
    final = createSubmissionDetails(enrollment, enrollmentSettings);
  }

  let accessFlag = false;
  if (!isExam) {
    accessFlag = !!enrollment.work;
  } else if (sheet.examTiming === ExamTiming.Window) {
    accessFlag = !!enrollment.work?.startDate;
  } else {
    const startDate = hasAlternativeStart
      ? enrollmentSettings?.alternateExamStartDate
      : enrollmentSettings?.sheetExamStartDate;
    if (startDate && isAfter(now, startDate) && enrollment.work) {
      accessFlag = true;
    }
  }

  const graded = (final?.result.grade ?? null) !== null;
  const feedbackViewCount = final?.feedbackViewCount ?? 0;

  // Student progress in the class
  const flags: ProgressFlags = {
    accessed: accessFlag,
    started:
      timeLimit && !isExam
        ? !!enrollment.work?.startDate && accessFlag
        : !!enrollment.work?.lastSaveTime && accessFlag,
    extension: Boolean(
      enrollmentSettings.finalExtension !== null ||
        enrollmentSettings.timeExtension !== null
    ),
    draft: draft !== null,
    lateDraft: Boolean(draft?.late),
    final: final !== null,
    lateFinal: Boolean(final?.late),
    overtimeFinal: Boolean(final?.overtime),
    graded: graded,
    feedbackViews: graded ? feedbackViewCount : 0,
    withdrawn: enrollment.deleted === true,
    forceSubmitted: Boolean(final?.forceSubmitted),
    hasExtraTime,
    hasAlternativeStart,
    hasAlternativeWindow,
    deferred,
    accessCode: Boolean(enrollment?.accessCode),
  };

  const progress = calcProgressStage(
    flags.feedbackViews,
    flags.final,
    flags.draft,
    flags.started,
    flags.accessed
  );

  const fullName = enrollment.user.name.trim();

  return {
    id: enrollment.id,
    withdrawn: enrollment.deleted ?? false,
    withdrawnBy: enrollment.deletedBy?.name,
    deferred,
    tab: ClassTab.Students,
    anonymousIndex: enrollment.anonymousIndex ?? rowNumber,
    workId: enrollment.work?.id ?? null,
    lastName: userLastName(enrollment.user) ?? "",
    fullName: fullName,
    displayName: fullName,
    displayFirstName:
      enrollment.user.givenName || fullName.split(" ", 1)[0] || "",
    displayEmail: enrollment.user.email,
    displaySisId: enrollment.sisUserId,
    anonymous: false,
    email: enrollment.user.email,
    flags,
    progress,
    settings: enrollmentSettings,
    draft,
    final,
    deleted: enrollment.deleted,
    // Do not count groups for withdrawn enrollments
    group:
      group && !enrollment.deleted
        ? {
            id: group.id,
            name: group.name,
          }
        : null,
    tags: enrollment.tags,
    enrollment,
    accessCodeLabel:
      enrollment.accessCode &&
      (enrollment.accessCode.label
        ? enrollment.accessCode.label
        : enrollment.accessCode.code),
    lastActivity,
  };
}

/**
 * Replace the `displayName` and `displayFirstName` with an anonymised name.
 * Additionally, strip the email.
 */
function anonymiseStudent(
  row: StudentListRow,
  revealedIds: string[]
): StudentListRow {
  if (revealedIds.includes(row.id)) return row;
  return {
    ...row,
    displayName: `Student ${row.anonymousIndex}`,
    displayFirstName: "Student",
    displayEmail: null,
    anonymous: true,
  };
}
