import { styled } from "styled-components";

import { colors, LinkButtonV2, Spacer, Text } from "@vericus/cadmus-ui";

import MagnifyingGlass from "@/assets/shared/magnifying-glass.svg";
import { useAppSelector } from "@/data/hooks";
import { IPastAssessmentFilters } from "@/data/templates";
import { selectPastAssessmentFilters } from "@/data/templates/selectors";

interface Props {
  /**
   * Callback for when the user intends to clear the filters.
   */
  onClear: () => void;
  /**
   * Callback for when the user intends to clear the assessment name search query.
   */
  onNameQueryClear: () => void;
}

/**
 * Component for when the user has interacted with the filter but the result is nothing
 */
export const NoFilteredPastAssessments = (props: Props) => {
  const filter = useAppSelector(selectPastAssessmentFilters);
  const reasonContent = noResultReason(filter, props);

  return <Container>{reasonContent}</Container>;
};

const noResultReason = (filter: IPastAssessmentFilters, props: Props) => {
  if (Object.keys(filter).length === 1 && filter.assessmentNameQuery) {
    return (
      <Content>
        <Img src={MagnifyingGlass} alt={"Magnifying glass"} />
        <Spacer spacing={40} />
        <StyledText kind={"headingFour"} textAlign={"center"}>
          {`We couldn't find a match for "${filter.assessmentNameQuery}"`}
        </StyledText>
        <Spacer spacing={12} />
        <Text color={"shade1"} kind="bodyMd">
          Ensure the spelling is correct
        </Text>
        <Spacer spacing={4} />
        <StyledLinkButton
          onClick={() => {
            props.onNameQueryClear();
          }}
        >
          Try searching again
        </StyledLinkButton>
      </Content>
    );
  }

  return (
    <Content>
      <Img src={MagnifyingGlass} alt={"Magnifying glass"} />
      <Spacer spacing={40} />
      <StyledText kind={"headingFour"}>
        No assessments match the current filters
      </StyledText>
      <Spacer spacing={12} />
      <StyledLinkButton
        onClick={() => {
          props.onClear();
        }}
      >
        Clear all
      </StyledLinkButton>
    </Content>
  );
};

const StyledLinkButton = styled(LinkButtonV2)`
  font-size: 16px;
`;

const StyledText = styled(Text)`
  color: #5b6f98;
  word-break: break-all;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

const Container = styled.div`
  width: 100%;
  height: 420px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${() => colors.grey100};
`;

const Img = styled.img`
  display: block;
  width: 70px;
  margin: 0 auto;
  fill: ${() => colors.grey500};
`;
