import { Fragment, useState } from "react";
import styled from "styled-components";

import { Icon } from "@vericus/cadmus-icons";
import { colors, Divider, Pill, Text } from "@vericus/cadmus-ui";

import clsx from "clsx";

import {
  QuestionNavigationArrow,
  QuestionNavigationIcon,
} from "@/features/multi-format";
import { Question, QuestionType } from "@/generated/graphql";

import { formatDecimalNumber } from "../../utils";
import * as styles from "./navigation-item.css";
import { QuestionItem } from "./types";

export type PartialQuestion = Pick<Question, "questionType" | "shortPrompt">;

interface ItemProps extends QuestionItem {
  questionId: string;
  onFoldSection: () => void;
  onUnfoldSection: () => void;
}

/**
 * List item in the question navigation list in Cadmus Marker.
 */
export function NavigationItem(props: ItemProps) {
  const {
    questionType,
    shortPrompt,
    score,
    maxScore,
    parentNodeId,
    orderLabel,
    questionId,
    onFoldSection,
    onUnfoldSection,
  } = props;
  const [folded, setFolded] = useState(false);
  // TODO: Implementation of redux store for isLast and depth
  const depth = parentNodeId ? 1 : 0;
  const isLast = true;

  return (
    <Fragment>
      <li className={styles.container}>
        {questionType === QuestionType.Section ? (
          <>
            <Divider />
            <SectionContainer
              onClick={() => {
                if (folded) {
                  onUnfoldSection();
                  setFolded(false);
                  return;
                }
                onFoldSection();
                setFolded(true);
              }}
            >
              <div
                className={clsx(
                  styles.navigationButton,
                  styles.containerWithHover,
                  depth > 0 && styles.subquestionMargin
                )}
              >
                <SectionContent>
                  <SectionHead>
                    <Text
                      kind="systemMd"
                      color="lilac500"
                      textTransform="uppercase"
                    >
                      Part {orderLabel}
                    </Text>
                    {folded ? (
                      <Icon iconName="UpArrowhead" />
                    ) : (
                      <Icon iconName="DownArrowhead" />
                    )}
                  </SectionHead>
                  {maxScore && (
                    <ScorePill
                      parentNodeId={parentNodeId}
                      score={score}
                      maxScore={maxScore}
                      withBackground={false}
                      bold
                    />
                  )}
                </SectionContent>
              </div>
            </SectionContainer>
          </>
        ) : (
          <a
            className={clsx(
              styles.navigationButton,
              styles.containerWithHover,
              depth > 0 && styles.subquestionMargin
            )}
            href={`#${questionId}`}
          >
            {depth > 0 && (
              <QuestionNavigationArrow depth={depth} isLast={isLast} />
            )}
            <QuestionNavigationIcon questionType={questionType} />
            <div className={styles.titleSection}>
              <Text kind="headingSix" color="navy700" bold>
                Question {orderLabel}
              </Text>
              <Text
                kind="headingSix"
                color="neutralgrey500"
                className={
                  styles.shortPrompt[
                    parentNodeId !== null ? "isChildQuestion" : "base"
                  ]
                }
              >
                {shortPrompt}
              </Text>
            </div>
            {questionType !== QuestionType.Overview && maxScore !== null && (
              <ScorePill
                parentNodeId={parentNodeId}
                score={score}
                maxScore={maxScore}
                withBackground={props.parentNodeId === null}
              />
            )}
          </a>
        )}
      </li>
    </Fragment>
  );
}

interface ScorePillProps {
  score: number | null;
  maxScore: number;
  parentNodeId: string | null;
  withBackground: boolean;
  bold?: boolean;
}

function ScorePill(props: ScorePillProps) {
  const { score, maxScore } = props;
  return (
    <div className={styles.points}>
      <Pill color={props.withBackground ? colors.bgrey400 : " "} basic={true}>
        <Text kind={"systemMd"} bold={props.bold}>
          <Text
            kind={"systemMd"}
            color={score !== null ? "black200" : "red500"}
            as="span"
            bold={props.bold}
          >
            {score !== null ? formatDecimalNumber(score) : "-"}
          </Text>
          /{maxScore}
        </Text>
      </Pill>
    </div>
  );
}

const SectionContainer = styled.button`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  &:focus {
    outline-color: ${(p) => p.theme.colors.focus};
  }
`;

const SectionHead = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;

const SectionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
