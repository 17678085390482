import { useCallback, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";

import {
  Colored,
  FileCard,
  getFileType,
  LinkButton,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import { ResourceAddForm } from "ui/task/resources/ResourceAddForm";

import { DropShadowed } from "./ResourceCard";

interface ResourceActionsProps {
  /** Callback for adding a new valid URL resource. */
  onAdd: (name: string, url: string) => void;
  /** Callback for attaching a new valid file resource. */
  onUpload: (name: string, file: File) => void;
  /** A new resource is being uploaded or saved.  */
  isLinking?: boolean;
  /** Some network error occured. */
  hasError?: boolean;
}

/**
 * Upload a new file resource or attach a new URL resource.
 */
export function ResourceActions({
  onAdd,
  onUpload,
  isLinking,
  hasError,
}: ResourceActionsProps) {
  // Toggle for the URL link form
  const [showForm, setShowForm] = useState(false);
  // List of resource names being linked right now. The `isLinking` and
  // `hasError` track progress of all processing resources.
  const [inProcess, setInProcess] = useState<string[]>([]);

  // Hidden file input hack for opening the file browser
  const fileInput = useRef<HTMLInputElement>(null);

  // Handler for adding a new resource.
  const handleAdd = useCallback(
    (name: string, url: string) => {
      setInProcess((rs) => [...rs, name]);
      onAdd(name, url);
      setShowForm(false);
    },
    [onAdd, setShowForm, setInProcess]
  );

  // Handler for opening the native file selection window.
  const handleOpenFileExplorer = useCallback(() => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  }, [fileInput]);

  // Handler for attaching a new file resource
  const handleFileChange = useCallback(() => {
    const newFile = fileInput.current?.files?.[0];
    if (newFile) {
      setInProcess((rs) => [...rs, newFile.name]);
      onUpload(newFile.name, newFile);
      if (fileInput.current) {
        fileInput.current.value = "";
      }
    }
  }, [onUpload, setInProcess]);

  // Reset the list of resources in process when the linking ends
  useEffect(() => {
    if (!isLinking) {
      setInProcess([]);
    }
  }, [isLinking, setInProcess]);

  return (
    <>
      {hasError && (
        <Row>
          <Col>
            <OverflowText kind="bodySm">
              <Colored color="red500">There was a network error ...</Colored>
            </OverflowText>
          </Col>
        </Row>
      )}
      {isLinking && (
        <>
          {inProcess.map((name, index) => (
            <DropShadowed key={index}>
              <FileCard
                viewOnly
                fileInfo={{ name, type: getFileType(name) }}
                isUploading
              />
            </DropShadowed>
          ))}
          <Spacer spacing={27} />
        </>
      )}
      {showForm ? (
        <ResourceAddForm
          onAdd={handleAdd}
          onCancel={() => setShowForm(false)}
        />
      ) : (
        <>
          <StyledLinkButton
            data-component={"ResourceAction.attach-file"}
            data-testid={"AttachFile"}
            iconName="File"
            kind="primary"
            onClick={handleOpenFileExplorer}
            aria-label="Attach file"
          >
            Attach from computer
          </StyledLinkButton>
          <StyledLinkButton
            iconName="Link"
            kind="primary"
            marginLeft={18}
            onClick={() => setShowForm(!showForm)}
            aria-label="Link website"
          >
            Link to website
          </StyledLinkButton>
        </>
      )}
      <input
        style={{
          position: "absolute",
          overflow: "hidden",
          width: "0.1px",
          height: "0.1px",
          opacity: 0,
          zIndex: -1,
        }}
        aria-hidden="true"
        ref={fileInput}
        onChange={handleFileChange}
        tabIndex={-1}
        type="file"
      />
    </>
  );
}

const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
`;

// Single Resource row container
const Row = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
`;

// Extended Text with ellipsis overflow
const OverflowText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Single full width spanning column in a `<Row />`.
const Col = styled.div`
  flex: auto;
  margin: 0px;
  padding: 9px 9px 9px 0;
  overflow: hidden;
`;
