import { FilterFn, Row } from "@tanstack/react-table";

import {
  PredicateFilter,
  RangeFilter,
  StudentListRow,
} from "@/ui/class/progress/types";

// Simple JS value comparison
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function compareBasic(a: any, b: any) {
  return a === b ? 0 : a > b ? 1 : -1;
}

// Filter a column by applying predicates on the column value. If any of
// the predicates succeed, the row is kept.
export const predicateFilter: FilterFn<StudentListRow> = (
  row,
  columnId,
  filters: PredicateFilter[] | undefined
) => {
  const value = row.getValue(columnId);
  if (value === null || value === undefined || !filters || filters.length === 0)
    return true;
  return filters.some(({ predicate }) => predicate(value));
};

/**
 * Apply some filters to the rows.
 * Used to answer: "if these filters were applied, how many rows would they match?"
 */
export function getMatchingRows(
  rows: Row<StudentListRow>[],
  columnId: string,
  filters: PredicateFilter[]
): Row<StudentListRow>[] {
  return rows.filter((row) => {
    const value = row.getValue(columnId);
    return filters.some(({ predicate }) => predicate(value));
  });
}

// Filter a `value` based on a list of range `filters` which matches if the
// value is in the given lower and upper bounds.
//
// The value is kept if any of the filters matches.
//
// Some of the filters can have a `match` field which overrides the bounds check
// and directly matches the value.
export const rangeFilter: FilterFn<StudentListRow> = (
  row,
  columnId,
  filters: RangeFilter[]
) => {
  const value = row.getValue(columnId) as number;
  return applyRangeFilters(value, filters);
};

// Filter a `value` based on a list of range `filters` which matches if the
// value is in the given lower and upper bounds.
//
// The value is kept if any of the filters matches.
//
// Some of the filters can have a `match` field which overrides the bounds check
// and directly matches the value.
export function applyRangeFilters(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  filters: RangeFilter[] | undefined
) {
  if (filters === undefined || filters.length === 0) return true;

  // apply `match` field override. NOTE `match` value can be a valid `null`.
  const matchFilters = filters.filter((f) => f.match !== undefined);
  const matches = matchFilters.some(({ match }) => value === match);
  if (matches) return true;

  if (value == null) return false;

  return filters.some(({ lower, upper }) => value >= lower && value < upper);
}
