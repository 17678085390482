import { useEffect, useRef } from "react";

import { Userpilot } from "userpilot";

import { useCurrentUser } from "../user/useCurrentUser";

/**
 * Grabs the current user and calls Userpilot.identify once.
 * It sends the Cadmus' user.id
 *
 * Returns a function that has the user's context setup. Just pass in new props about the user
 */
export const useUserpilotIdentify = () => {
  const user = useCurrentUser();
  const identified = useRef<boolean>(false);

  useEffect(() => {
    if (user?.me && user?.institution?.tenant && !identified.current) {
      Userpilot.identify(
        userIdForUserpilot(user.institution.tenant, user.me.id),
        {
          tenant: user.institution.tenant,
        }
      );
      identified.current = true;
    }
  }, [user, identified]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (properties: any) => {
    if (!user) {
      console.warn("useUserpilotIdentify: User has not been established yet!");
      return;
    }

    Userpilot.identify(
      userIdForUserpilot(user.institution.tenant, user.me.id),
      properties
    );
  };
};

const userIdForUserpilot = (tenant: string, id: string) => {
  return `${tenant}-${id}`;
};
