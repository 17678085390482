import * as React from "react";

import { Input, Text } from "@vericus/cadmus-ui";

import moment from "moment";

interface Props {
  defaultTime: string;
  time: moment.Moment | null;
  setTime: (date: moment.Moment) => void;
  disabled?: boolean;
  errorMessage?: string;
}

/**
 * @deprecated because its underlying date functionality is using
 * moment.js (which is deprecated).
 *
 * Please use TimePickerInput instead.
 * */
export function TimePicker(props: Props) {
  const { defaultTime, time, setTime, disabled, errorMessage } = props;
  // Turning time from 'moment.Moment | null' into string as required by html input type="time"
  const stringTime: string = time
    ? `${time.format("HH:mm")}`
    : `${defaultTime}`;

  // Updating the time and not the date
  const handleChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        const newTime = e.target.value === "" ? defaultTime : e.target.value;
        const newMomentTime = moment(newTime, "HH:mm");
        const newFormattedTime = time
          ? moment(time).set({
              h: newMomentTime.get("h"),
              m: newMomentTime.get("m"),
              s: 0,
            })
          : // if a date is not defined set it to today
            moment({
              h: newMomentTime.get("h"),
              m: newMomentTime.get("m"),
              s: 0,
            });
        setTime(newFormattedTime);
      },
      [time, defaultTime, setTime]
    );

  return (
    <Text kind="headingThree" as="span">
      <Input
        type="time"
        aria-label="Due time"
        value={stringTime}
        onChange={handleChange}
        disabled={disabled}
        aria-disabled={disabled ? "true" : "false"}
        errorMessage={errorMessage}
      />
    </Text>
  );
}
