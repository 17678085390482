import { isAfter, isBefore } from "date-fns";

import { inferLiveExamEndDate } from "@/features/task/settings/use-settings-form";
import { AssessmentType, ExamTiming, Sheet } from "@/generated/graphql";

type NarrowedRequirements = Pick<
  Sheet,
  | "examTiming"
  | "examReadingTime"
  | "examWritingTime"
  | "examStartDate"
  | "examEndDate"
>;

/**
 * @returns the ISO 8601 date for when the exam will close submissions.
 *  **NOTE:** does not comsider special considerations or extensions.
 */
export const getExamCloseDate = (
  requirements: NarrowedRequirements
): string | null => {
  const { examReadingTime, examTiming, examEndDate } = requirements;
  if (examTiming === ExamTiming.Live) {
    const liveExamEndDate = inferLiveExamEndDate({
      ...requirements,
      noExamReadingTime: !examReadingTime,
    });
    return liveExamEndDate;
  }
  return examEndDate;
};

/**
 * @returns whether the exam has closed now
 */
export const isExamClosed = (requirements: NarrowedRequirements): boolean => {
  const { examTiming, examStartDate, examEndDate, examReadingTime } =
    requirements;
  if (!examStartDate) return false;
  if (examTiming === ExamTiming.Live) {
    const inferredEndDate = new Date(
      inferLiveExamEndDate({
        ...requirements,
        noExamReadingTime: !examReadingTime,
      })
    );
    return isAfter(new Date(), inferredEndDate);
  } else if (examTiming === ExamTiming.Window) {
    if (!examEndDate) return false;
    return isAfter(new Date(), new Date(examEndDate));
  }
  return false;
};

/**
 * @returns whether an exam is happening right now
 */
export const isExamInProgress = (
  requirements: NarrowedRequirements
): boolean => {
  const { examTiming, examStartDate, examEndDate, examReadingTime } =
    requirements;
  if (!examStartDate) return false;
  if (examTiming === ExamTiming.Live) {
    const inferredEndDate = new Date(
      inferLiveExamEndDate({
        ...requirements,
        noExamReadingTime: !examReadingTime,
      })
    );
    return (
      isAfter(new Date(), new Date(examStartDate)) &&
      isBefore(new Date(), new Date(inferredEndDate))
    );
  } else if (examTiming === ExamTiming.Window) {
    if (!examEndDate) return false;
    return (
      isAfter(new Date(), new Date(examStartDate)) &&
      isBefore(new Date(), new Date(examEndDate))
    );
  }
  return false;
};

/**
 * @returns whether an assessment is an exam.
 */
export const isExam = ({
  assessmentType,
}: {
  assessmentType: AssessmentType;
}): boolean => assessmentType === AssessmentType.Exam;
