import { useMemo } from "react";

import { EnrollmentFragment, GroupFragment } from "generated/graphql";

export function useStudentExtensionGroup(
  enrollments: EnrollmentFragment[],
  groups?: GroupFragment[]
) {
  return useMemo(() => {
    if (!groups) return null;
    const userIds = enrollments.map(({ user: { id } }) => id);
    const group = groups.find(
      ({ members }) => members.find(({ id }) => userIds.includes(id)) !== null
    );

    if (
      !group ||
      group.members.length !== userIds.length ||
      !group.members.every(({ id }) => userIds.includes(id))
    ) {
      return null;
    }

    return group;
  }, [groups, enrollments]);
}
