import { styled } from "styled-components";

import { Anchored, Button, Grid, Spacer, Text } from "@vericus/cadmus-ui";

import * as Sentry from "@sentry/react";
import NotFoundImg from "assets/errors/not-found.svg";

export const NoMatchError = () => {
  Sentry.captureException(new Error("Page not found, invalid route"), {});

  return (
    <Grid>
      <GridImg
        role="presentation"
        src={NotFoundImg}
        alt="An illustration of a woman looking through an empty glass. How is she going to quench her thirst?"
      />
      <GridContent>
        <Text kind="displayTwo">404: Page not found</Text>
        <Text kind="lead">
          The page you’re looking for doesn’t exist. Try accessing Cadmus again
          from your Learning Management System.
        </Text>
        <Spacer spacing={27} />
        <Anchored
          newTab
          href="https://support.cadmus.io/students/404-page-not-found"
        >
          <Button kind="secondary">Learn more about this error</Button>
        </Anchored>
      </GridContent>
    </Grid>
  );
};

const GridImg = styled.img`
  grid-column: 3 / span 5;
  width: 100%;
  margin-top: 72px;

  @media screen and (max-width: 900px) {
    grid-column: 2 / span 7;
  }

  @media screen and (max-width: 630px) {
    grid-column: 1 / span 9;
  }
`;

const GridContent = styled.div`
  grid-column: 3 / span 5;
  text-align: center;
  margin-top: 18px;

  @media screen and (max-width: 900px) {
    grid-column: 1 / span 9;
  }
`;
