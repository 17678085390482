import { ReactNode } from "react";
import { styled } from "styled-components";

import { Button, Modal, Text } from "@vericus/cadmus-ui";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import {
  AssessmentFragment,
  ReleasedRequirementsFragment,
} from "@/generated/graphql";
import {
  ExtensionType,
  useUpdateStudentSettings,
} from "@/ui/class/progress/pages/StudentsSettingsPage";
import {
  COMPACT_DATE_WITH_YEAR_FORMAT,
  formatDate,
  hrAndMinDuration,
  toDate,
} from "@/utils/datetime";

import { ExtensionGrantIntent } from "../StudentSettingsPageModal";

export type ClearExtensionIntent = ExtensionGrantIntent;

export interface ConnectedClearExtensionConfirmModalProps {
  intent: ClearExtensionIntent;
  instructionSheet: ReleasedRequirementsFragment | null;
  assessment: Pick<AssessmentFragment, "id">;
}

export const ConnectedClearExtensionConfirmModal =
  NiceModal.create<ConnectedClearExtensionConfirmModalProps>((props) => {
    const { intent, instructionSheet, assessment } = props;
    const modal = useModal();
    // Callback to update custom settings on selected enrollments
    const { clearDueDates, clearTimeLimits } = useUpdateStudentSettings(
      assessment.id
    );
    return (
      <ClearExtensionConfirmModal
        open={modal.visible}
        onExitComplete={modal.remove}
        onClose={() => modal.hide()}
        onConfirm={() => {
          if (intent.type === ExtensionType.DueDate) {
            clearDueDates(intent.enrollments);
          } else {
            clearTimeLimits(intent.enrollments);
          }
          modal.hide();
        }}
        extensionType={intent.type}
        dueDate={toDate(instructionSheet?.dueDate) ?? undefined}
        timeLimit={instructionSheet?.timeLimit ?? undefined}
        userName={intent.enrollments[0]?.user.name}
      />
    );
  });

export interface ClearExtensionConfirmModalProps {
  /** Extension type being cleared */
  extensionType: ExtensionType;
  open: boolean;
  onExitComplete?: VoidFunction;
  /** Dialog confirmation callback. */
  onConfirm: () => void;
  /** Dialog dismiss callback. */
  onClose: () => void;
  /** Optionally personalise the modal to a single user */
  userName?: string;
  /** Sheet due date */
  dueDate?: Date;
  /** Sheet time limit */
  timeLimit?: number;
}

/**
 * Dialog modal to confirm clearning due date and time extensions on a single or
 * bulk enrollments.
 */
export function ClearExtensionConfirmModal(
  props: ClearExtensionConfirmModalProps
) {
  const {
    extensionType,
    dueDate,
    timeLimit,
    userName,
    onConfirm,
    onClose: onCancel,
    open,
    onExitComplete,
  } = props;

  let content: ReactNode | null = null;
  if (extensionType === ExtensionType.DueDate && dueDate) {
    const dueDateStr = formatDate(dueDate, COMPACT_DATE_WITH_YEAR_FORMAT);
    if (userName) {
      content = `${userName}'s assessment will now be due on ${dueDateStr}`;
    } else {
      content = `Students will now have until ${dueDateStr} to complete this assessment`;
    }
  }

  if (extensionType === ExtensionType.TimeLimit && timeLimit) {
    const timeLimitStr = hrAndMinDuration(timeLimit);
    const contentPrefix = userName ? `${userName}'s` : "Students";
    content = `${contentPrefix} will now have ${timeLimitStr} to complete this assessment`;
  }

  const extensionTypeText =
    extensionType === ExtensionType.DueDate
      ? "due date "
      : extensionType === ExtensionType.TimeLimit
        ? "time limit "
        : "";

  return (
    <Modal.Root
      open={open}
      onOpenChange={(open) => {
        if (!open) onCancel();
      }}
    >
      <Modal.Content onExitComplete={onExitComplete}>
        <Container>
          <Text kind="headingFour">Remove {extensionTypeText}extension?</Text>
          <Text kind="bodyMd">{content}</Text>
          <ButtonContainer>
            <Button onClick={onCancel}>Cancel</Button>
            <ConfirmButton kind="secondary" onClick={onConfirm}>
              Remove
            </ConfirmButton>
          </ButtonContainer>
        </Container>
      </Modal.Content>
    </Modal.Root>
  );
}

const Container = styled.div`
  max-width: 568px;
  padding: 56px 72px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  gap: 12px;
`;

const ConfirmButton = styled(Button)`
  padding: 8px 56px;
`;
