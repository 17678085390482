import { styled } from "styled-components";

import { Grid, Text } from "@vericus/cadmus-ui";

export const DateTimeGrid = styled(Grid)`
  grid-template-columns: repeat(5, minmax(100px, 1fr)) repeat(3, 1fr);
  grid-template-areas: "date date date date date time time time time";
  grid-row-gap: 45px;

  @media screen and (max-width: 945px) {
    grid-template-areas:
      "date date date date date date date date date"
      "time time time time time time time time time";
  }
`;

export const GridDate = styled.div`
  grid-area: date;
  padding-right: 36px;
`;

export const GridTime = styled.div`
  grid-area: time;
`;

export const HeadingGrid = styled(Grid)`
  grid-template-areas:
    "title title title title title title title title title"
    "lead lead lead lead lead lead lead lead lead";
`;

export const GridTitle = styled(Text)`
  grid-area: title;
`;

export const GridLead = styled(Text)`
  grid-area: lead;
`;

export const Footer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
