import * as React from "react";
import { styled } from "styled-components";

import { Color, colors, Spacer, Text } from "@vericus/cadmus-ui";

import tipImg from "assets/shared/tip.svg";

interface Props {
  color?: Color;
  children?: React.ReactNode;
}

/**
 * In-app learning tips.
 *
 * Found all throughout the Athena UI, Tips are designed to link teachers to the
 * Cadmus educational guides (https://cadmus.io/guides). Each tip contains a
 * small snippet of text (as children) with Anchors to particular
 * website guide pages.
 *
 */

export const Tip = ({ color, children }: Props) => (
  <Container color={color}>
    <Img src={tipImg} alt="" />
    <Spacer spacing={9} />
    <Text kind="bodySm">{children}</Text>
  </Container>
);

// An optional Grid when building a section with multiple Tips. This is
// responsive out of the box.
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 27px;
`;

// An inline Anchor to use for the link to the cadmus guide webpage. Opens in a
// new tab.
export const Anchor = styled.a.attrs(() => ({
  target: "_blank",
  rel: "noopener noreferrer",
}))`
  color: inherit;
  text-decoration: none;
  background-image: linear-gradient(
      to right,
      ${(p) => p.theme.text.main} 0%,
      ${(p) => p.theme.text.main} 100%
    ),
    linear-gradient(
      to right,
      ${(p) => p.theme.text.main} 0%,
      ${(p) => p.theme.text.main} 50%,
      transparent 50%,
      transparent 100%
    );

  background-size:
    0% 1px,
    2px 1px;
  background-repeat: no-repeat, repeat-x;
  background-position: bottom left;

  transform: translateZ(0);
  transition: background 0.2s ease-out;

  cursor: pointer;

  &:hover,
  &:focus {
    background-size:
      100% 1px,
      2px 1px;
    opacity: 1;
  }
`;

const Container = styled.div<{ color?: Color }>`
  padding: 18px;
  box-sizing: border-box;
  border-radius: 3px;
  background: ${(p) => (p.color ? colors[p.color] : "transparent")};
  ${Text} {
    font-weight: 600;
  }
`;

const Img = styled("img")`
  width: 24px;
  height: 24px;
`;
