import React, { useCallback } from "react";
import { styled } from "styled-components";

import { desktop, Modal, tablet, Text } from "@vericus/cadmus-ui";

import NiceModal from "@ebay/nice-modal-react";
import { useJitsu } from "@jitsu/react";
import { format } from "date-fns";
import toast from "react-hot-toast";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { useAppSelector } from "@/data/hooks";
import { getAssessmentId } from "@/data/task";
import { DuplicatePastAssessmentCallback } from "@/data/templates";
import {
  AssessmentType,
  Discipline,
  ExamTiming,
  RequirementsFragment,
  ResourceFragment,
  usePastAssessmentQuery,
} from "@/generated/graphql";
import { useCopyAssessment } from "@/ui/templates/templatesHooks";
import { logAppError } from "@/utils/logger";

import { useIsEditorEmpty } from "../editorHooks";
import {
  useCloseAllTemplatesModals,
  useShowEditorContentReplacementModal,
} from "../modals";
import { DoubleColumnPreview, Header } from "./components";
import {
  Container,
  HeaderContentsContainer,
  InfoContainer,
  PaddedPreviewContainer,
  StickyHeaderContainer,
  useOnBack,
  useOnCloseModal,
} from "./modals-shared";
import { PreviewModalSkeleton } from "./PreviewModalSkeleton";

export interface Requirements
  extends Omit<
    RequirementsFragment,
    | "noDraft"
    | "noLimit"
    | "noReferencing"
    | "noTimeLimit"
    | "noWeight"
    | "noExamReadingTime"
    | "__typename"
  > {}
export interface PastAssessment {
  name: string;
  id: string;
  subject: {
    id: string;
    discipline: Discipline | null;
    name: string;
  };
}
export interface PastAssessmentPreviewModalProps {
  /** Populated header contents with information about the past assessment. */
  headerContents: JSX.Element;
  /** The past assessment being previewed. */
  pastAssessment: PastAssessment;
  /** The ID of the instruction sheet that belongs to the assessment in question */
  instructionSheetVersionId: string;
  /** The editor content for this past assessment. */
  editorContent: string;
  /** The resources for this assessment. */
  resources: ResourceFragment[];
  /** The requirements for this assessment. */
  requirements: Requirements;
  /**
   * Called when the user indicates they want to use (duplicate) the past assessment being previewed.  */
  onConfirm: DuplicatePastAssessmentCallback;
  /** Called when the user wants to go back to where the modal was open from. */
  onGoBack: VoidFunction;
  /** Called when the user wants to close the modal. */
  onClose: VoidFunction;
}

export interface ConnectedPastAssessmentPreviewModalProps {
  /** The id of the past assessment being previewed. */
  pastAssessmentId: string;
  /** The ID (version) of the instruction sheet to preview. */
  instructionSheetVersionId: string;
}

export const ConnectedPastAssessmentPreviewModal =
  NiceModal.create<ConnectedPastAssessmentPreviewModalProps>(
    ({ pastAssessmentId, instructionSheetVersionId }) => {
      const closeModal = useOnCloseModal();
      const onGoBack = useOnBack();

      const { loading, data: pastAssessmentData } = usePastAssessmentQuery({
        variables: {
          assessmentId: pastAssessmentId,
          sheetId: instructionSheetVersionId,
        },
      });
      const onConfirm = useOnDuplicatePastAssessment();

      if (loading || !pastAssessmentData?.instructionSheet) {
        return (
          <Modal.Root
            onOpenChange={(open) => {
              if (!open) closeModal();
            }}
          >
            <StyledModalContent>
              <PreviewModalSkeleton />
            </StyledModalContent>
          </Modal.Root>
        );
      }

      /**
       * Remove dates from the preview, as they're not relevant in the event
       * that the user wants to duplicate the assessment.
       */
      const previewRequirements: Requirements = {
        ...pastAssessmentData.instructionSheet,
        allowLateResubmission: false,
        dueDate: null,
        draftDueDate: null,
        returnDate: null,
        hasChanged: true,
        restrictMarkingToolPdf: false,
        markingToolPdf: null,

        // Exam related fields
        examStartDate: null,
        examEndDate: null,
        examFeedbackDate: null,
      };

      let dateLabel = <></>;

      switch (pastAssessmentData.instructionSheet.assessmentType) {
        case AssessmentType.Exam:
          dateLabel =
            pastAssessmentData.instructionSheet.examTiming ===
            ExamTiming.Live ? (
              <>
                <Text kind="bodySm">
                  Exam start:{" "}
                  {pastAssessmentData.instructionSheet.examStartDate &&
                    formatDueDate(
                      pastAssessmentData.instructionSheet.examStartDate
                    )}
                </Text>
              </>
            ) : (
              <>
                <Text kind="bodySm">
                  Exam open:{" "}
                  {pastAssessmentData.instructionSheet.examStartDate &&
                    formatDueDate(
                      pastAssessmentData.instructionSheet.examStartDate
                    )}
                </Text>
              </>
            );
          break;

        case AssessmentType.Assignment:
        default:
          dateLabel = (
            <>
              <Text kind="bodySm">
                Due date:{" "}
                {formatDueDate(pastAssessmentData.instructionSheet.dueDate)}
              </Text>
            </>
          );
      }

      return (
        <PastAssessmentPreviewModal
          headerContents={
            <PastAssessmentHeaderContents
              name={pastAssessmentData.assessment.name}
              subjectName={pastAssessmentData.assessment.subject.name}
              dateLabel={dateLabel}
            />
          }
          pastAssessment={pastAssessmentData.assessment}
          instructionSheetVersionId={
            pastAssessmentData.instructionSheet.serverId
          }
          resources={pastAssessmentData.instructionSheet.resources}
          editorContent={pastAssessmentData.instructionSheet.rawContent ?? ""}
          requirements={previewRequirements}
          onConfirm={onConfirm}
          onGoBack={onGoBack}
          onClose={closeModal}
        />
      );
    }
  );

const formatDueDate = (dueDate: string): string => {
  return format(new Date(dueDate), "d MMM y");
};

const useOnDuplicatePastAssessment = () => {
  const assessmentIdBeingWorkedOn = useAppSelector(getAssessmentId);
  const copyAssessment = useCopyAssessment();
  const closeTemplatesModals = useCloseAllTemplatesModals();
  const isEditorEmpty = useIsEditorEmpty();
  const showContentReplaceModal = useShowEditorContentReplacementModal();
  const { track } = useJitsu();
  return useCallback(
    async (
      pastAssessmentId: string,
      pastAssessmentName: string = "Past assessment",
      instructionSheetVersionId: string
    ) => {
      if (!(pastAssessmentId && assessmentIdBeingWorkedOn)) {
        const message = `One of pastAssessmentId (${pastAssessmentId}) or
          assessmentIdBeingWorkedOn (${assessmentIdBeingWorkedOn})
          not found when trying to copy past assessment`;
        logAppError(message);
        return;
      }

      if (isEditorEmpty) {
        await copyAssessment(
          assessmentIdBeingWorkedOn,
          pastAssessmentName,
          instructionSheetVersionId
        );
        track(JitsuEvent.TEMPLATE_SHEET_USED, {
          assessment_id: pastAssessmentId,
          assessment_name: pastAssessmentName,
          sheet_id: instructionSheetVersionId,
        });
        closeTemplatesModals();
        toast.success(`'${pastAssessmentName}' has been duplicated`);
      } else {
        showContentReplaceModal({
          useCase: {
            type: "past-assessment-replacement",
            instructionSheetVersionId,
            currentAssessmentId: assessmentIdBeingWorkedOn,
            pastAssessmentId,
            pastAssessmentName,
          },
        });
      }
    },
    [
      assessmentIdBeingWorkedOn,
      copyAssessment,
      closeTemplatesModals,
      isEditorEmpty,
      showContentReplaceModal,
      track,
    ]
  );
};

export const PastAssessmentPreviewModal = ({
  pastAssessment,
  instructionSheetVersionId,
  editorContent,
  headerContents,
  requirements,
  resources,
  onClose,
  onConfirm,
  onGoBack,
}: PastAssessmentPreviewModalProps) => {
  const { id: pastAssessmentId, name } = pastAssessment;
  const requirementsFragment: RequirementsFragment = {
    ...requirements,
    __typename: "Requirements",
    noDraft: !requirements.draftDueDate,
    noLimit: !requirements.timeLimit,
    noReferencing: !requirements.referencingStyle,
    noTimeLimit: !requirements.timeLimit,
    noWeight: !requirements.weight,
    noExamReadingTime: !requirements.examReadingTime,
  };

  return (
    <Modal.Root
      open
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <StyledModalContent withAnimation={false} withOverlay={false}>
        <Container backgroundColor="#F5F7FA">
          <StickyHeaderContainer>
            <Header
              backgroundColor="#F5F7FA"
              confirmButtonText="Reuse assessment"
              onConfirm={() =>
                onConfirm(pastAssessmentId, name, instructionSheetVersionId)
              }
              onGoBack={onGoBack}
            >
              {headerContents}
            </Header>
          </StickyHeaderContainer>

          <PaddedPreviewContainer>
            <DoubleColumnPreview
              title={name}
              requirements={requirementsFragment}
              content={editorContent}
              resources={resources}
            />
          </PaddedPreviewContainer>
        </Container>
      </StyledModalContent>
    </Modal.Root>
  );
};

const StyledModalContent = styled(Modal.Content)`
  width: 575px;
  ${tablet`
    width: 936px;
  `}
  ${desktop`
    width: 1090px;
  `}
`;

interface PastAssessmentHeaderContentProps {
  name: string;
  subjectName: string;
  dateLabel: React.ReactNode;
}

export const PastAssessmentHeaderContents = ({
  name,
  subjectName,
  dateLabel,
}: PastAssessmentHeaderContentProps) => {
  return (
    <HeaderContentsContainer>
      <Text kind="headingFour" style={{ paddingTop: 0 }}>
        {name}
      </Text>
      <InfoContainer>
        <Text kind="bodySm">{subjectName}</Text>
        <Text kind="bodySm">·</Text>
        {dateLabel}
      </InfoContainer>
    </HeaderContentsContainer>
  );
};
