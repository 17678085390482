import { Skeleton } from "@vericus/cadmus-ui";

import { DoubleColumnPreviewSkeleton, Header } from "./components";
import {
  Container,
  PaddedPreviewContainer,
  StickyHeaderContainer,
} from "./modals-shared";

/**
 * Loading skeleton for previewing a past assessment.
 */
export const PreviewModalSkeleton = () => (
  <Container backgroundColor="#F5F7FA">
    <StickyHeaderContainer>
      <Header
        backgroundColor="#F5F7FA"
        confirmButtonText="Reuse assessment"
        onConfirm={() => {}}
        onGoBack={() => {}}
      >
        <Skeleton width={40} />
      </Header>
    </StickyHeaderContainer>
    <PaddedPreviewContainer>
      <DoubleColumnPreviewSkeleton />
    </PaddedPreviewContainer>
  </Container>
);
