import { ParsedQuestion } from "@/features/question-bank-file-import/data";
import { AddQuestionBanksToAssessmentInput } from "@/generated/graphql";

export function filterQuestions(
  questions: ParsedQuestion[],
  filter: AddQuestionBanksToAssessmentInput["filter"]
) {
  return questions.filter((q) => {
    let match = false;
    const { questionType, category } = filter;
    if (!category && questionType && q.questionType === questionType) {
      match = true;
    } else if (!questionType && category && q.category === category) {
      match = true;
    } else {
      match = q.questionType === questionType && q.category === category;
    }
    return match;
  });
}
