import { styled } from "styled-components";

import { CloseRoundIcon } from "@vericus/cadmus-icons";
import {
  ControlButton,
  PhraseInput,
  Text,
} from "@vericus/cadmus-ui";
import { useOptionListState } from "./option-list-input";

interface Props {
  value: string[];
  onAddPhrase: (answer: string) => void;
  onDeletePhrase: (answer: string) => void;
}

/**
 * Render current accepted phrases and provide an input for
 * users to create new accepted phrases. A Phrase is a plain text
 * that is shorter than 100 characters.
 */
export const AcceptedPhrasesInput = (props: Props) => {
  const { value, onAddPhrase, onDeletePhrase } = props;
  const {
    inputHasError,
    setInputValue,
    inputValue,
    onAddButtonClick,
    clearError,
  } = useOptionListState(value, onAddPhrase, true, (inputValue: string) => {
    if (inputValue.length > 100) return "";
  });

  return (
    <Root>
      <Label kind="bodySm">Accepted answers</Label>
      {value.length > 0 && (
        <AnswersList>
          {value.map((answer) => (
            <Answer key={answer}>
              <Text kind="bodyMd" color="nteal700">
                {answer}
              </Text>
              <DeleteButton
                aria-label="Delete phrase"
                onClick={() => onDeletePhrase(answer)}
              >
                <CloseRoundIcon label="" size={16} />
              </DeleteButton>
            </Answer>
          ))}
        </AnswersList>
      )}
      <StyledPhraseInput
        placeholder="Type a new accepted phrase"
        value={inputValue}
        charactersLimit={100}
        alertLimit={10}
        onKeyDown={(e) => {
          if (e.key !== "Enter") return;
          onAddButtonClick();
          e.currentTarget.blur();
        }}
        onChange={(e) => {
          if (inputHasError) {
            clearError();
          }
          setInputValue(e.target.value);
        }}
        hasError={inputHasError}
        rightSection={
          <StyledAddButton
            aria-label="Add new accepted phrase"
            size={42}
            onClick={(e) => {
              onAddButtonClick();
              e.currentTarget.blur();
            }}
            iconName="Plus"
          />
        }
      />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  row-gap: 16px;
  flex-direction: column;
`;

const StyledPhraseInput = styled(PhraseInput)`
  padding: 7px 16px;
`;

const StyledAddButton = styled(ControlButton)`
  background: ${(p) => p.theme.colors.neutralgrey50};
`;

const Label = styled(Text)`
  color: #000;
  font-weight: 600;
`;

const AnswersList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;

const Answer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 8px 12px 8px 16px;
  align-items: center;
  border-radius: 16px;
  background: ${(p) => p.theme.colors.teal200};
  word-break: break-word;
`;

const DeleteButton = styled.button`
  border: none;
  background: none;
  padding: 0px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  cursor: pointer;

  color: ${(p) => p.theme.colors.nteal700};
  &:hover {
    color: ${(p) => p.theme.colors.functionalFail};
  }
`;
