import { useCallback } from "react";

import NiceModal from "@ebay/nice-modal-react";

import {
  ConnectedTaskFormatModal,
  ConnectedTaskFormatModalProps,
} from "../components/task-format-modal/TaskFormatModal";

export const useShowTaskFormatModal = () => {
  return useCallback((props: ConnectedTaskFormatModalProps) => {
    NiceModal.show(ConnectedTaskFormatModal, props);
  }, []);
};
