import { useCallback } from "react";
import { styled } from "styled-components";

import { SwitchIcon } from "@vericus/cadmus-icons";
import { ControlAction, ControlButton, Text } from "@vericus/cadmus-ui";

import headerCadmusLogo from "assets/edit/toolbar-cadmus-logo.svg";

import { useAppSelector } from "@/data/hooks";
import { selectIsSaving } from "@/data/task";
import { useShowTaskFormatModal } from "@/features/task/hooks/useShowTaskFormatModal";
import { TaskFormat } from "@/generated/graphql";
import { useRootNavigate } from "@/router/routing";
import { useShowTemplatesModal } from "@/ui/templates/modals";

import { ShelfToolbar } from "./ShelfToolbar";

export interface Props {
  /** Disables the release button. */
  releaseDisabled?: boolean;
  onReleaseClick: VoidFunction;
  format?: TaskFormat;
  taskId?: string;
  multiformatFeatureEnabled?: boolean;
}

/**
 * Top ActionBar containing the Cadmus logo, Templates Gallery, Cadmus home, Student Preview,
 * and the Release Button.
 */
export function ActionBar({
  releaseDisabled,
  onReleaseClick,
  format,
  taskId,
  multiformatFeatureEnabled,
}: Props) {
  // Routing hooks
  const rootNavigate = useRootNavigate();

  const goStudentPreview = useCallback(() => {
    rootNavigate("/task/preview/editing");
  }, [rootNavigate]);

  const goHome = useCallback(() => {
    rootNavigate("/task", {
      replace: false,
    });
  }, [rootNavigate]);

  const showTemplatesModal = useShowTemplatesModal();
  const isSaving = useAppSelector(selectIsSaving);
  const showTaskFormatModal = useShowTaskFormatModal();

  return (
    <ShelfToolbar justifyContent="space-between">
      <CadmusLogo
        src={headerCadmusLogo}
        alt="Cadmus logo"
        onClick={goHome}
        aria-label="Cadmus home"
      />
      <ActionContainer>
        {multiformatFeatureEnabled && (
          <ControlButton
            icon={<SwitchIcon label="" size={24} />}
            aria-label="Switch Format"
            onClick={() => {
              if (taskId) {
                showTaskFormatModal({
                  format: format,
                  taskId: taskId,
                });
              }
            }}
          />
        )}
        {format !== TaskFormat.Multiformat && (
          <ControlButton
            iconName="Template"
            aria-label="Template gallery"
            data-component={"ActionBar.Templates"}
            onClick={() => showTemplatesModal({})}
          />
        )}
        <ControlButton
          iconName="Home"
          aria-label="Cadmus home"
          onClick={goHome}
        />

        <Text kind="bodySm" color="lilac500" style={{ padding: "0.5rem" }}>
          {isSaving ? "saving" : "autosaved"}
        </Text>

        <ControlAction iconName="Show" onClick={goStudentPreview}>
          Student Preview
        </ControlAction>
        <ControlAction
          data-component={"ActionBar.Release"}
          kind="primary"
          iconName="Send"
          disabled={releaseDisabled}
          aria-disabled={releaseDisabled}
          onClick={onReleaseClick}
          title="Release this assessment"
        >
          Release
        </ControlAction>
      </ActionContainer>
    </ShelfToolbar>
  );
}

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  flex: none;
  height: 100%;
`;

const CadmusLogo = styled.img`
  cursor: pointer;
`;
