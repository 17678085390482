import { styled } from "styled-components";

import { Button, Text } from "@vericus/cadmus-ui";

import { FilterCard } from "@/ui/class/components";
import { BigPill } from "@/ui/shared/BigPill";

import { DisplayedFilter } from "./ProgressFiltersBanner";
import {
  EnrolledStudentsContainer,
  filtersConfig,
  FiltersContainer,
  ProgressFilterContainer,
} from "./shared";

export interface ProgressFilterEmptyStateProps {
  /** Filters to show the user the data they could access if they sync the class list. */
  filters: Pick<DisplayedFilter, "filterId">[];
  /** Called when the user wants to sync the class list. */
  onSyncClassList: VoidFunction;
}

/**
 * Shows the users available filters before they have synced the class list.
 * Also shows a CTA to sync the class list.
 */
export const ProgressFilterEmptyState = ({
  filters,
  onSyncClassList,
}: ProgressFilterEmptyStateProps) => {
  return (
    <ProgressFilterContainer>
      <EnrolledStudentsContainer>
        <Text kind="systemSm">Enrolled students</Text>
        <Text kind="headingTwo" color="navy300">
          --
        </Text>
      </EnrolledStudentsContainer>
      <FiltersContainer aria-label="Progress quick filters" aria-disabled>
        {filters.map(({ filterId }) => {
          const filterConfig = filtersConfig[filterId];
          return (
            <FilterCard
              key={filterId}
              count={
                <Text kind="headingThree" color="navy300">
                  -
                </Text>
              }
              label={
                <BigPill
                  backgroundColor={filterConfig.pillBgColor}
                  textColor={filterConfig.pillTextColor}
                  width="grow"
                >
                  {filterConfig.filterDisplay}
                </BigPill>
              }
              onClick={() => {}}
            />
          );
        })}
      </FiltersContainer>
      <SyncCTAContainer>
        <Text kind="bodySm" color="navy700" bold>
          Sync your class list to track progress
        </Text>
        <Button kind="secondary" onClick={onSyncClassList}>
          Sync class list
        </Button>
      </SyncCTAContainer>
    </ProgressFilterContainer>
  );
};

const SyncCTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
