import { useCallback } from "react";

import { useJitsu } from "@jitsu/react";
import { JitsuEvent } from "data/events/JitsuEvent";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";

import {
  EnrollmentFragment,
  SubmissionType,
  TaskFormat,
  useDownloadSubmissionsMutation,
  useDownloadWorkArtifactsMutation,
  UserFragment,
} from "@/generated/graphql";

/** Download mutation request payload */
export interface ActionPayload {
  /** Assessment ID */
  assessmentId: string;
  /** Assessment Name */
  assessmentName: string;
  /** List of enrollments from which to download the submissions. */
  enrollments: EnrollmentFragment[];
  /** Submission type requested for download from the enrollments. */
  submissionType: SubmissionType;
  /**
   * If anonymous marking is enabled? Hides the student name from PDF names.
   * @default false
   */
  anonymousMarking?: boolean;
  /**
   * List of enrollments excluded from anonymising, if anonymous marking is enabled.
   * @default []
   */
  revealedEnrollmentIds?: string[];
  /**
   * Task format of the current sheet
   */
  taskFormat: TaskFormat | null;
}

/**
 * Callback created to download submissions via the mutation
 *
 * Successful mutation calls return a signed S3 URL which will be downloaded
 * client-side and presented as a browser side file save dialog.
 *
 * The mutation success will also trigger a success Toast as a side-effect.
 *
 * Additionally, emits the `Submission Bulk Downloaded` or `Submission
 * Downloaded` event based on the calling context passing the `isBulkOperation`
 * argument in the returned callback.
 *
 * @param payload describe which submissions need to be archived.
 * @param isBulkOperation indicate that the calling context is a bulk operation
 */

export type DownloadSubmissionsCallback = (
  payload: ActionPayload,
  isBulkOperation?: boolean
) => Promise<string | undefined>;

/**
 * Hook to create a callback to trigger the `downloadSubmissions` mutation and
 * save the resulting archive or file through a browser download.
 */
export const useDownloadSubmissions = (): DownloadSubmissionsCallback => {
  const { track } = useJitsu();

  const [mutateV1] = useDownloadSubmissionsMutation({ ignoreResults: true });
  const [mutateV2] = useDownloadWorkArtifactsMutation({ ignoreResults: true });

  return useCallback(
    (payload: ActionPayload, isBulkOperation: boolean = true) => {
      const {
        assessmentId,
        enrollments,
        submissionType,
        assessmentName,
        taskFormat,
      } = payload;

      const inputs = collectSubmissionFiles(
        enrollments,
        submissionType,
        payload.anonymousMarking ?? false,
        payload.revealedEnrollmentIds ?? []
      );

      // No submissions to download
      if (inputs.length === 0) {
        toast.error("No submissions found to download");
        return Promise.resolve(undefined);
      }

      async function download() {
        const filename =
          inputs.length === 1
            ? inputs[0]!.filename
            : `${assessmentName}-${assessmentId}-${submissionType}.zip`;

        if (taskFormat === TaskFormat.Multiformat) {
          const { data } = await mutateV2({
            variables: {
              assessmentId,
              workArtifacts: inputs.flatMap((input) => {
                if (!input.workArtifactId) return [];
                return {
                  id: input.workArtifactId!,
                  filename: input.filename,
                };
              }),
            },
          });

          if (data?.downloadWorkArtifacts) {
            await fetch(data?.downloadWorkArtifacts)
              .then((response) => response.blob())
              .then((blob) => saveAs(blob, filename));
          }
        } else {
          const { data } = await mutateV1({
            variables: {
              assessmentId,
              submissions: inputs.map((input) => ({
                id: input.submissionId,
                name: input.filename,
              })),
              preferPdf: true,
            },
          });

          if (data?.downloadSubmissions) {
            await fetch(data?.downloadSubmissions)
              .then((response) => response.blob())
              .then((blob) => saveAs(blob, filename));
          }

          return filename;
        }
      }

      // Trigger mutation with toast notifications which tracks download
      // progress
      const promise = download();

      trackSubmissionDownloadEvent(
        track,
        isBulkOperation,
        submissionType,
        inputs
      );

      const count = inputs.length;
      const kind = payload.submissionType.toLowerCase();

      toast.promise(promise, {
        loading: `Downloading ${count} ${kind} submissions...`,
        success: `${count} ${kind} submissions have been downloaded`,
        error: "Download failed. Please try again.",
      });

      return promise;
    },
    [mutateV1, mutateV2, track]
  );
};

function trackSubmissionDownloadEvent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track: (typeName: string, payload?: any) => Promise<void>,
  isBulkOperation: boolean,
  submissionType: SubmissionType,
  inputs: SubmissionDetail[]
) {
  if (isBulkOperation) {
    track(JitsuEvent.SUBMISSION_BULK_DOWNLOADED, {
      submission_type: submissionType,
      enrollments: inputs.map((input) => {
        return {
          enrollment_id: input.enrollmentId,
          student_id: input.studentId,
          submission_id: input.submissionId,
        };
      }),
    });
  } else if (inputs.length === 1) {
    track(JitsuEvent.SUBMISSION_DOWNLOADED, {
      submission_type: submissionType,
      submission_id: inputs[0]!.submissionId,
      enrollment_id: inputs[0]!.enrollmentId,
      student_id: inputs[0]!.studentId,
    });
  }
}

function determineSubmissionFileName(
  user: UserFragment,
  submissionId: string,
  hideName: boolean,
  sisUserId: string | null,
  submissionType: SubmissionType
): string {
  const submissionReceipt = submissionId.substring(0, 7);
  const userIdPrefix = user.id.substring(0, 7);

  let submissionName: string;
  if (hideName) {
    const userId = sisUserId !== null ? sisUserId : userIdPrefix;
    submissionName = `${userId}-${submissionReceipt}-${submissionType}.pdf`;
  } else {
    const emailPrefix = user.email
      ? user.email.substring(0, user.email.indexOf("@"))
      : userIdPrefix;
    const userId = sisUserId !== null ? sisUserId : emailPrefix;
    submissionName = `${user.name}-${userId}-${submissionReceipt}-${submissionType}.pdf`;
  }

  return submissionName;
}

type SubmissionDetail = {
  enrollmentId: string;
  studentId: string;
  submissionId: string;
  filename: string;
  workArtifactId?: string;
};

// Create a list of filenames and other details to the corresponding
// submission PDFs for browser download.
//
// Anonymous submissions have no obvious user name or email details.
export function collectSubmissionFiles(
  enrollments: EnrollmentFragment[],
  type: SubmissionType,
  anonymousMarking: boolean = false,
  revealedEnrollmentIds: string[] = []
): SubmissionDetail[] {
  return enrollments.reduce(
    (acc, { id: enrollmentId, user, work, sisUserId }) => {
      // Select a draft or final submission for the enrollment
      const submissionId =
        type === SubmissionType.Final ? work?.final?.id : work?.draft?.id;

      // Select workArtifact for the submission - will be appropriate for Multiformat tasks
      const workArtifactId =
        type === SubmissionType.Final
          ? work?.final?.pdf?.id
          : work?.draft?.pdf?.id;

      if (submissionId) {
        // Should user name be hidden from the pdf filename?
        const hideName =
          anonymousMarking && !revealedEnrollmentIds.includes(enrollmentId);

        const filename = determineSubmissionFileName(
          user,
          submissionId,
          hideName,
          sisUserId,
          type
        );

        return [
          ...acc,
          {
            submissionId,
            filename,
            workArtifactId,
            enrollmentId,
            studentId: user.id,
          },
        ];
      }
      return acc;
    },
    [] as SubmissionDetail[]
  );
}
