import { useState } from "react";

import { Button, Modal, OptionCard, Text } from "@vericus/cadmus-ui";

import { FeedbackLevel, FeedbackVisibilityInput } from "@/generated/graphql";

import { FeedbackVisibilityForm } from "../feedback-visibility-form";
import * as styles from "./feedback-option-modal.css";

interface Props {
  /** If there is an existing custom selection of students for feedback release. */
  hasStudentSelection: boolean;
  /** Current visibility of feedback. */
  feedbackViewLevel: FeedbackLevel;
  /** If pdf download is allowed by student. */
  allowPdfDownload: boolean;
  /** Confirm callback to release feedback to student instantly */
  onConfirmReleaseInstantly: (input: FeedbackVisibilityInput) => void;
  /** Confirm callback of release selectively */
  onConfirmSelectivelyRelease: (input: FeedbackVisibilityInput) => void;
}

/**
 * Input modal for user to set Feedback release timing and visibility.
 *
 * The modal goes through 2 pages:
 *
 *   1. Timing - User selects if they want to release feedback instantly or
 *   selectively.
 *
 *   2. Visibility - User sets visibility of feedback and if pdf download is
 *   allowed.
 *
 * Step (1) will be skipped if there is a custom selection already when opening
 * this Modal.
 */
export function FeedbackOptionModal(props: Props) {
  return (
    <Modal.Root>
      <Modal.Trigger asChild>
        <Button kind="primary" fullWidth>
          Release feedback
        </Button>
      </Modal.Trigger>
      <Modal.Content width={617}>
        <ModalContent {...props} />
      </Modal.Content>
    </Modal.Root>
  );
}

function ModalContent(props: Props) {
  const [step, setStep] = useState<"timing" | "visibility">(
    props.hasStudentSelection ? "visibility" : "timing"
  );
  const [isSelective, setIsSelective] = useState<boolean | null>(
    props.hasStudentSelection ? true : null
  );

  // Feedback visibility form inputs
  const [visibility, setVisibility] = useState(props.feedbackViewLevel);
  const [allowPdfDownload, setAllowPdfDownload] = useState(
    props.allowPdfDownload
  );

  if (step === "timing") {
    return (
      <div className={styles.container}>
        <Text kind="headingThree" as="span" textAlign="center">
          Release feedback to students
        </Text>
        <div className={styles.optionWrapper}>
          <OptionCard
            selected={isSelective === false}
            className={styles.option}
            titleText="Release now for everyone"
            descriptionText="All the students receive their feedback at once, right away"
            onClick={() => setIsSelective(false)}
          />
          <OptionCard
            selected={isSelective === true}
            className={styles.option}
            titleText="Release selectively"
            descriptionText="Schedule, withhold or defer feedback for specific students and groups"
            onClick={() => setIsSelective(true)}
          />
          <Button
            disabled={isSelective === null}
            kind="secondary"
            className={styles.nextButton}
            onClick={() => setStep("visibility")}
          >
            NEXT
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <FeedbackVisibilityForm
          visibility={visibility}
          allowPdfDownload={allowPdfDownload}
          onChangeVisibility={(visibility) => setVisibility(visibility)}
          onChangeAllowPdfDownload={(allowed) => setAllowPdfDownload(allowed)}
        />
        <div className={styles.visibilityFormButtons}>
          <Button onClick={() => setStep("timing")}>back</Button>
          <Modal.Close asChild>
            <Button
              kind="secondary"
              disabled={isSelective === null}
              onClick={() => {
                if (isSelective === true) {
                  props.onConfirmSelectivelyRelease({
                    feedbackViewLevel: visibility,
                    withFeedbackPdf: allowPdfDownload,
                  });
                }
                if (isSelective === false) {
                  props.onConfirmReleaseInstantly({
                    feedbackViewLevel: visibility,
                    withFeedbackPdf: allowPdfDownload,
                  });
                }
              }}
            >
              {isSelective ? "NEXT" : "RELEASE"}
            </Button>
          </Modal.Close>
        </div>
      </div>
    );
  }
}
