import { memo, useCallback } from "react";
import { styled } from "styled-components";

import {
  CadmusEditorContent,
  Editor,
} from "@vericus/cadmus-editor-prosemirror";
import { CloseRoundIcon, ErrorIcon } from "@vericus/cadmus-icons";
import { Radio, Switch, Text, TextArea } from "@vericus/cadmus-ui";

import { nanoid } from "nanoid";

import { SimpleChoiceFragment } from "@/generated/graphql";

import { EditorWrapper } from "../../components/editor-wrapper";
import { MarkAsCorrectButton } from "../../components/mark-as-correct-button";
import { InvalidOption } from "../../task-validation";
import { AdditionalFeedback } from "../question-footer-action/additional-feedback";
import { Dispatch, State } from "../state";
import { BodyDiv } from "../styles";

interface McqQuestionBodyProps {
  state: State;
  /** Question field identifier */
  fieldIdentifier: string;
  /** Question prompt rich text content */
  editor: Editor | null;
  /** Current choices */
  choices: SimpleChoiceFragment[];
  /** Correct choices */
  correctChoices: string[];
  /** Reducer dispatcher */
  dispatch: Dispatch;
  /** Invalid option id with reason */
  invalidOptions?: InvalidOption[];
  /** Error when question promptDoc less than 20 characters */
  promptDocTooShort?: boolean;
}

/**
 * Question body for a multiple choice question.
 */
function McqQuestionBodyComponent(props: McqQuestionBodyProps) {
  const {
    state,
    editor,
    choices,
    correctChoices,
    fieldIdentifier,
    dispatch,
    invalidOptions,
    promptDocTooShort,
  } = props;

  const onMarkAsCorrect = useCallback(
    (id: string) => {
      dispatch({
        type: "ToggleCorrectChoice",
        choiceIdentifier: id,
        fieldIdentifier,
        alowMultipleCorrectChoice: true,
      });
    },
    [dispatch, fieldIdentifier]
  );

  return (
    <Root>
      <McqEditorWrapper hasError={promptDocTooShort}>
        <CadmusEditorContent editor={editor} />
      </McqEditorWrapper>
      <Content>
        <OptionsWrapper>
          {choices.map((choice, index) => {
            const isInvalid = invalidOptions?.find(
              (invalidOption) => invalidOption.id === choice.identifier
            );
            const isCorrect = correctChoices.includes(choice.identifier);
            return (
              <SelectionWrapper key={choice.identifier}>
                <Selection>
                  <SelectionContent
                    key={choice.identifier}
                    selected={isCorrect}
                    hasError={isInvalid ? true : false}
                  >
                    <Radio
                      colorVariant={isInvalid ? "functionalFail" : "primary"}
                      // Need to use onClick, onChange won't be trigger when checked is true
                      onClick={() => {
                        onMarkAsCorrect(choice.identifier);
                      }}
                      onChange={() => {}}
                      checked={isCorrect}
                      aria-checked={isCorrect}
                      data-value="True"
                      aria-label="Radio"
                    />
                    <OptionInputWrapper
                      placeholder={`Option ${index + 1}`}
                      defaultValue={choice.content}
                      renderTextArea={(onContentUpdate) => (
                        <OptionInput
                          placeholder={`Option ${index + 1}`}
                          defaultValue={choice.content}
                          hideFocusRing
                          withAutoWidthWrapper={true}
                          onChange={(e) => {
                            onContentUpdate(e.target.value);
                            dispatch({
                              type: "SetChoice",
                              choiceIdentifier: choice.identifier,
                              content: e.target.value,
                              fieldIdentifier,
                            });
                          }}
                        />
                      )}
                    />
                    <DeleteButton
                      onClick={() =>
                        dispatch({
                          type: "DeleteChoice",
                          choiceIdentifier: choice.identifier,
                          fieldIdentifier,
                        })
                      }
                      aria-label="Delete option"
                    >
                      <CloseRoundIcon label="" size={16} />
                    </DeleteButton>
                  </SelectionContent>
                  <MarkAsCorrectButton
                    isCorrect={isCorrect}
                    onMarkAsCorrect={() => {
                      onMarkAsCorrect(choice.identifier);
                    }}
                  />
                </Selection>
                {isInvalid?.message && (
                  <OptionError>
                    <ErrorIcon label="" size={16} />
                    <Text kind="bodySm" color="functionalFail">
                      {isInvalid.message}
                    </Text>
                  </OptionError>
                )}
              </SelectionWrapper>
            );
          })}
          <AddOptionButton
            onClick={() => {
              dispatch({
                type: "SetChoice",
                choiceIdentifier: nanoid(),
                content: "",
                fieldIdentifier,
              });
            }}
          >
            <Text kind="bodySm" color="lilac500" underline>
              Add option
            </Text>
          </AddOptionButton>
        </OptionsWrapper>
      </Content>
      <Footer>
        <AdditionalFeedback
          key={`${state.questionType}-${state.questionId}`}
          feedback={state.feedback ?? ""}
          onSaveFeedback={(feedback) => {
            dispatch({
              type: "SetFeedback",
              feedback,
            });
          }}
        />
        <VerticalDivider />
        <Switch
          labelPosition="right"
          checked={state.shuffle}
          onClick={() => {
            dispatch({ type: "ToggleShuffle" });
          }}
          onChange={() => {}}
        >
          <Text kind="bodySm" color="navy500">
            Shuffle choice order{" "}
          </Text>
        </Switch>
      </Footer>
    </Root>
  );
}

export const McqQuestionBody = memo(McqQuestionBodyComponent);

const Root = styled(BodyDiv)`
  background: ${(p) => p.theme.colors.white100};
`;

const Footer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 32px;
  padding-left: 45px;
  padding-top: 8px;
`;

export const VerticalDivider = styled.hr`
  border: 0;
  width: 1px;
  height: 31px;
  background-color: ${(p) => p.theme.colors.grey100};
`;

const McqEditorWrapper = styled(EditorWrapper)`
  margin: 16px 0px;
`;

const Content = styled.div`
  padding: 0px 45px;
`;

const OptionsWrapper = styled.div`
  flex-direction: column;
  row-gap: 8px;
  display: inline-flex;
`;

const OptionError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  column-gap: 4px;
`;

const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const Selection = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const SelectionContent = styled.div<{ selected?: boolean; hasError?: boolean }>`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  flex: 1 0 0;
  border-radius: 3px;
  flex: 1 0 0;
  box-sizing: border-box;
  background: ${(p) =>
    p.hasError
      ? p.theme.colors.white100
      : p.selected
        ? p.theme.colors.lilac200
        : "unset"};
  border: ${(p) =>
    p.hasError
      ? `1px solid  ${p.theme.colors.functionalFail}`
      : p.selected
        ? `1px solid  ${p.theme.colors.lilac500}`
        : `1px solid ${p.theme.colors.grey300}`};
  position: relative;
  &:hover {
    border: 1px solid
      ${(p) =>
        p.hasError ? p.theme.colors.functionalFail : p.theme.colors.lilac500};
  }
`;

const OptionInputWrapper = styled(TextArea.AutoWidthWrapper)`
  min-width: 236px;
  font-size: 14px;
`;

const OptionInput = styled(TextArea.Input)`
  border: none;
  background: none;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

const DeleteButton = styled.button`
  position: absolute;
  right: -8px;
  border: none;
  background: none;
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;

  color: ${(p) => p.theme.colors.navy300};

  &:hover {
    color: ${(p) => p.theme.colors.redA500};
  }

  &:focus {
    outline: 0;
    ${(p) => p.theme.focus};
  }
`;

const AddOptionButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 10px 11px 9px 0px;
  cursor: pointer;
  outline: inherit;
  align-self: flex-start;

  &:hover,
  &:focus {
    border-radius: 2px;
    background: ${(p) => p.theme.colors.grey50};
    padding: 1px 9px;
    /** Minus the changed padding to fix button at the same place*/
    margin: 9px 2px 8px -9px;
  }
`;
