import { forwardRef, ReactNode, useId, useTransition } from "react";
import { styled, css } from "styled-components";

import { CuiTheme, mobile, Text, Tooltip } from "@vericus/cadmus-ui";

export interface FilterCardProps {
  label: ReactNode;
  count: ReactNode;
  tooltip?: ReactNode;
  isActive?: boolean;
  disabled?: boolean;
  onClick: VoidFunction;
  /**
   * Whether to only display this card for mobile-sized screens.
   */
  onlyInMobile?: boolean;
}

export const FilterCard = forwardRef<HTMLButtonElement, FilterCardProps>(
  (props, ref) => {
    const { label, count, isActive, disabled, tooltip, onClick, onlyInMobile } =
      props;
    const labelId = useId();
    const [isPending, startTransition] = useTransition();
    return (
      <Tooltip content={tooltip} passThrough={tooltip === undefined}>
        <CardContainer
          ref={ref}
          onClick={() => !disabled && startTransition(() => onClick())}
          isActive={isActive}
          disabled={disabled}
          isPending={isPending}
          onlyInMobile={onlyInMobile}
          role="switch"
          aria-checked={isActive}
          aria-labelledby={labelId}
        >
          <PillContainer id={labelId}>{label}</PillContainer>
          <Text kind="headingThree" color={disabled ? "grey300" : undefined}>
            {count}
          </Text>
        </CardContainer>
      </Tooltip>
    );
  }
);

interface CardContainerProps
  extends Pick<FilterCardProps, "isActive" | "disabled"> {
  isPending?: boolean;
  onlyInMobile?: boolean;
}

export const CardContainer = styled.button<CardContainerProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100px;
  width: 180px;
  padding: 8px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.sgrey50 : theme.colors.white100};
  border-radius: 4px;
  height: 90px; /* used to prevent layout shifting when the card is selected */

  &:focus-visible {
    ${(p) => p.theme.focus};
    outline: none;
  }

  ${cardBorder}
  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${(p) => p.theme.colors.white200};
      }
    `}
  ${(p) =>
    p.disabled &&
    css`
      &::after {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: ${(p) => p.theme.colors.grey100};
        opacity: 15%;
      }
    `}
    ${(p) =>
    p.onlyInMobile &&
    css`
      display: none;
      ${mobile`
        display: flex
      `}
    `}
  user-select: none;
`;

const PillContainer = styled.div`
  box-sizing: border-box;
  font-size: 13px;
  width: 100%;
`;

function cardBorder(p: CardContainerProps & { theme: CuiTheme }) {
  if (p.isPending)
    return css`
      border: 3px solid ${p.theme.colors.lilac300};
    `;
  if (p.isActive)
    return css`
      border: 3px solid ${p.theme.colors.lilac500};
    `;
  return css`
    border: 3px solid ${p.theme.colors.grey100};
  `;
}
