import { useCallback } from "react";

import { useJitsu } from "@jitsu/react";
import toast from "react-hot-toast";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { useAppSelector } from "@/data/hooks";
import { getAssessmentId } from "@/data/task";
import {
  DuplicatePastAssessmentCallback,
  PreviewPastAssessmentCallback,
} from "@/data/templates";
import {
  AssessmentType,
  Discipline,
  ExamTiming,
  TaskFormat,
} from "@/generated/graphql";
import { useInstitutionFeaturesFragment } from "@/graphql/institution-selectors";
import { PastAssessmentsRoot } from "@/ui/templates/MyAssessments/PastAssessmentsRoot";
import {
  useCopyAssessment,
  usePastAssessments,
} from "@/ui/templates/templatesHooks";
import { logAppError } from "@/utils/logger";

import { useIsEditorEmpty } from "./editorHooks";
import {
  useCloseAllTemplatesModals,
  useShowEditorContentReplacementModal,
  useShowPastAssessmentPreviewModal,
} from "./modals";

export interface IPastAssessment {
  assessmentId: string;
  instructionSheetVersionId: string;

  /**
   * The timing for exams. Live or window
   */
  examTiming: ExamTiming;

  /**
   * The start date for exams.
   */
  examStartDate: Date | null;
  /**
   * The end date for exams.
   * NOTE: this may be the date from the API for window exams
   * or the inferred end date for live exams (start + reading + writing).
   */
  examEndDate: Date | null;
  /**
   * The due date for assignments.
   */
  dueDate: Date;
  returnDate: Date;
  name: string;
  subject: {
    id: string;
    discipline: Discipline | null;
    name: string;
  };
  assessmentType: AssessmentType;
  taskFormat: TaskFormat | null;
}

export const ConnectedMyAssessments = () => {
  const currentAssessmentId = useAppSelector(getAssessmentId);
  const onPreview = useOnPreview();
  const { loading, pastAssessments } = usePastAssessments(currentAssessmentId);

  const onDuplicatePastAssessment = useOnDuplicatePastAssessment(
    pastAssessments || []
  );

  const features = useInstitutionFeaturesFragment();
  const examsFeatureEnabled = features?.examsFeatureEnabled ?? false;

  return (
    <PastAssessmentsRoot
      areExamsEnabled={examsFeatureEnabled}
      pastAssessments={pastAssessments ?? []}
      loading={loading}
      onDuplicate={onDuplicatePastAssessment}
      onPreview={onPreview}
    />
  );
};
const useOnPreview = (): PreviewPastAssessmentCallback => {
  const showPreviewModal = useShowPastAssessmentPreviewModal();
  const { track } = useJitsu();
  return useCallback(
    (
      assessmentId: string,
      assessmentName: string,
      instructionSheetVersionId: string
    ) => {
      showPreviewModal({
        pastAssessmentId: assessmentId,
        instructionSheetVersionId,
      });
      track(JitsuEvent.TEMPLATE_SHEET_PREVIEWED, {
        assessment_id: assessmentId,
        assessment_name: assessmentName,
        sheet_id: instructionSheetVersionId,
      });
    },
    [showPreviewModal, track]
  );
};

const useOnDuplicatePastAssessment = (
  pastAssessments: IPastAssessment[]
): DuplicatePastAssessmentCallback => {
  const assessmentIdBeingWorkedOn = useAppSelector(getAssessmentId);
  const copyAssessment = useCopyAssessment();
  const closeTemplatesModals = useCloseAllTemplatesModals();
  const isEditorEmpty = useIsEditorEmpty();
  const showContentReplaceModal = useShowEditorContentReplacementModal();
  const { track } = useJitsu();

  return useCallback(
    (
      pastAssessmentId: string,
      assessmentName: string,
      instructionSheetVersionId: string
    ) => {
      if (!(pastAssessmentId && assessmentIdBeingWorkedOn)) {
        const message = `One of pastAssessmentId (${pastAssessmentId}) or
          assessmentIdBeingWorkedOn (${assessmentIdBeingWorkedOn})
          not found when trying to copy past assessment`;
        logAppError(message);
        return;
      }

      const pastAssessmentName =
        pastAssessments.find((pa) => pa.assessmentId === pastAssessmentId)
          ?.name || "";
      if (isEditorEmpty) {
        copyAssessment(
          assessmentIdBeingWorkedOn,
          pastAssessmentName,
          instructionSheetVersionId
        );
        closeTemplatesModals();
        toast.success(`'${pastAssessmentName}' has been duplicated`);
        track(JitsuEvent.TEMPLATE_SHEET_USED, {
          assessment_id: pastAssessmentId,
          assessment_name: pastAssessmentName,
          sheet_id: instructionSheetVersionId,
        });
      } else {
        showContentReplaceModal({
          useCase: {
            type: "past-assessment-replacement",
            instructionSheetVersionId,
            currentAssessmentId: assessmentIdBeingWorkedOn,
            pastAssessmentName,
            pastAssessmentId,
          },
        });
      }
    },
    [
      assessmentIdBeingWorkedOn,
      copyAssessment,
      closeTemplatesModals,
      isEditorEmpty,
      showContentReplaceModal,
      pastAssessments,
      track,
    ]
  );
};
