import { useCallback, useMemo } from "react";

import {
  NavigateOptions,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { __GLOBAL_ASSESSMENT_ID, __GLOBAL_TENANT } from "@/client/globals";

/**
 * Used to match routes with the useMatch react-router hook.
 */
export const matchPaths = {
  progress: ":tenant/assessment/:assessmentId/class/progress",
  integrityAssurance: ":tenant/assessment/:assessmentId/integrity-assurance",
  drafts: ":tenant/assessment/:assessmentId/class/drafts",
  marking: ":tenant/assessment/:assessmentId/class/marking",
};

/** Global params on the base assessment URL. */
interface Params {
  tenant: string;
  assessmentId: string;
}

/**
 * Build the base path for the current tenant an assessment ID
 * @returns the base Cadmus path including the tenant and assessment ID
 *
 */
export const useBaseCadmusPath = () => {
  const { assessmentId, tenant } = useGlobalParams();
  return `/${tenant}/assessment/${assessmentId}`;
};

/**
 * Navigate to a route relative to the base assessment URL.
 *
 * Use this if relative pathing is not possible, either because you're in a
 * hook or because you're in a component that is not a child of the base.
 *
 * This exists because Athena's basePath is /:tenant/assessment/:assessmentId
 * however, this needs to be dynamic without reloading the entire app for
 * future proofing. Otherwise, we'd be able to call `react-router-dom`'s
 * useNavigate()("/insights");
 *
 * Also use this if the intention is to go back 1 or more hierarchy levels.
 * With useNavigate, you can do useNavigate()("../..") but that's not very
 * readable.
 *
 * @example
 * Given that the user is currently in the `/assessment/1234/task` route
 *
 * const rootNavigate = useRootNavigate();
 * rootNavigate("/insights");
 *
 */
export const useRootNavigate = () => {
  const navigate = useNavigate();
  const baseCadmusPath = useBaseCadmusPath();

  return useCallback(
    (to: string, options?: NavigateOptions) => {
      navigate(baseCadmusPath + to, options);
    },
    [navigate, baseCadmusPath]
  );
};

/** Parse an optional query param key from the URL search string. */
export function useQueryParam(key: string): string | null {
  const [searchParams] = useSearchParams();

  return useMemo(
    () => new URLSearchParams(searchParams).get(key),
    [key, searchParams]
  );
}

/** 
 * Remove query parameters from a URL that are not relevant anymore
 * 
 * For example, it's used to remove query params that are related to templates modals
 * after the modals were shown to the user
 */
export const useRemoveSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useCallback(
    (searchParamsToDelete: string[]) => {
      searchParamsToDelete.forEach((param) => {
        if (searchParams.has(param)) {
          searchParams.delete(param);
        }
      });
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );
};

/** Access the required base params from the URL. */
export function useGlobalParams(): Readonly<Params> {
  const params = useParams();

  if (params.tenant && !__GLOBAL_TENANT.current) {
    __GLOBAL_TENANT.current = params.tenant;
  }
  if (params.assessmentId && !__GLOBAL_ASSESSMENT_ID.current) {
    __GLOBAL_ASSESSMENT_ID.current = params.assessmentId;
  }

  return params as Readonly<Params>;
}

/**
 * Serialise query params into a URL friendly way
 * @returns e.g. "opentemplates=true&subcategory=education"
 */
export function serialiseQueryParams(searchParams: URLSearchParams): string {
  return new URLSearchParams(searchParams).toString();
}
