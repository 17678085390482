import { MarkingGroupPill } from "@/ui/shared/MarkingGroupPill";

interface MarkingGroupDataCellProps {
  /**
   * The name of the marking group.
   */
  groupName: string;
}

/**
 * Renders a marking group name pill, used in the context of a table cell.
 */
export const MarkingGroupDataCell = (props: MarkingGroupDataCellProps) => {
  return <MarkingGroupPill groupName={props.groupName} />;
};
