import { styled } from "styled-components";

import { ErrorIcon, QuestionRoundIcon } from "@vericus/cadmus-icons";
import { Switch, Text, Tooltip } from "@vericus/cadmus-ui";

import { NumberInput } from "../../components/number-input";
import { Dispatch } from "../state";

// Maximum word input limit, just a random large int to prevent possbile input exceed 32 bits
const MaxWordLimit = 200000;

interface Props {
  fieldIdentifier: string;
  limit: number | null;
  defaultLimit: number;
  hasError?: boolean;
  wordLimitEnabled?: boolean;
  dispatch: Dispatch;
  /** Error when wordLimit is enabled but no limit is provided */
  noWordLimit?: boolean;
}

/**
 * Word limit input in the footer area.
 */
export function WordLimitInput({
  limit,
  defaultLimit,
  hasError,
  dispatch,
  noWordLimit,
  wordLimitEnabled,
  fieldIdentifier,
}: Props) {
  return (
    <Root>
      <InputSection>
        <Switch
          labelPosition="right"
          checked={wordLimitEnabled}
          onClick={() => {
            if (wordLimitEnabled) {
              dispatch({
                type: "SetWordLimit",
                wordLimit: null,
                fieldIdentifier,
              });
            } else {
              dispatch({
                type: "SetWordLimit",
                wordLimit: defaultLimit,
                fieldIdentifier,
              });
            }
          }}
          onChange={() => {}}
        >
          <Text kind="bodySm" color="navy500">
            Word limit
          </Text>
          <Tooltip
            content={
              <Text kind="systemMd" as="span">
                Students will be warned when they exceed the word limit.
              </Text>
            }
          >
            <StyledButton aria-label="Word limit tooltip">
              <QuestionRoundIcon label="" size={16} />
            </StyledButton>
          </Tooltip>
        </Switch>
        {wordLimitEnabled && (
          <StyledNumberInput
            type={"number"}
            value={limit ?? ""}
            hasError={hasError}
            onChange={(e) => {
              if (e.target.value === "") {
                dispatch({
                  type: "SetWordLimit",
                  wordLimit: null,
                  fieldIdentifier,
                });
                return;
              }
              const wordLimit = parseInt(e.target.value);
              if (
                !isNaN(wordLimit) &&
                wordLimit > 0 &&
                wordLimit < MaxWordLimit
              ) {
                dispatch({ type: "SetWordLimit", wordLimit, fieldIdentifier });
              }
            }}
          />
        )}
      </InputSection>
      {noWordLimit && (
        <WordLimitError>
          <ErrorIcon label="" size={16} />
          <Text kind="bodySm" color="functionalFail">
            Word limit must be defined.
          </Text>
        </WordLimitError>
      )}
    </Root>
  );
}

const StyledButton = styled.button`
  border: none;
  background: none;
  padding: 0px;
  display: flex;
  align-items: center;
`;

const StyledNumberInput = styled(NumberInput)<{ hasError?: boolean }>`
  border: ${(p) =>
    p.hasError ? `1px solid ${p.theme.colors.functionalFail}` : "none"};
  height: 40px;
  width: 67px;
  margin-left: 8px;
`;

const InputSection = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
`;

const WordLimitError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  column-gap: 4px;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
