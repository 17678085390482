import { styled } from "styled-components";

import { colors } from "@vericus/cadmus-ui";

/**
 * Circular display for a numeric value with a `fg` text color and `bg`
 * background color.
 */
export const Counter = styled.span<{
  fg?: string;
  bg?: string;
  active?: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  border-radius: 100px;
  font-size: 12px;

  min-width: 20px;
  height: 20px;
  margin-left: 4px;
  position: relative;
  bottom: 0.4px;

  background-color: ${(p) =>
    p.active ? colors.lilac500 : p.bg ?? colors.white200};
  color: ${(p) => (p.active ? "white" : p.fg ?? colors.grey500)};
`;
