import { useState } from "react";
import { styled } from "styled-components";

import { Icon } from "@vericus/cadmus-icons";
import {
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
  Button,
  Checkbox,
  colors,
  ControlAction,
  DropdownMenu,
  Popover,
  SelectButton,
  Text,
} from "@vericus/cadmus-ui";

import { GroupType } from "@/generated/graphql";
import { Settings } from "@/ui/app/CadmusBar";

import { MarkingGroup } from "../../mark-slice/init-mark-groups";
import * as styles from "./header.css";
import { getBelongGroupByUserId } from "./utils";

interface HeaderProps {
  assessmentName: string;
  groups: MarkingGroup[];
  selectedUserId?: string;
  onSelect: (userId: string) => void;
  onClickIcon?: () => void;
  groupType: GroupType | null;
  currentMarkerId: string | null;
}
/**
 * Header Component for Marking Page which can navigate to the next
 * student, previous student, and select a student from the dropdown.
 * The header also contains the assessment name and the Cadmus logo.
 */
export function Header(props: HeaderProps) {
  return (
    <div className={styles.container}>
      <div className={styles.leftHandSide}>
        <HeaderTitle
          assessmentName={props.assessmentName}
          onClickIcon={props.onClickIcon}
        />
      </div>
      {props.selectedUserId && (
        <HeaderActions
          groups={props.groups}
          selectedUserId={props.selectedUserId}
          onSelect={props.onSelect}
          groupType={props.groupType}
          currentMarkerId={props.currentMarkerId}
        />
      )}
      <div className={styles.headerRightSection}>
        <SettingsMenu />
      </div>
    </div>
  );
}

function initialiseSelectedGroupIds(
  groups: MarkingGroup[],
  groupType: GroupType | null,
  currentMarkerId: string | null,
  selectedUserId?: string
): string[] {
  // If no user is selected, return an empty array
  if (!selectedUserId) {
    return [];
  }

  switch (groupType) {
    case GroupType.Student: {
      const currentGroup = getBelongGroupByUserId(selectedUserId, groups);
      return currentGroup ? [currentGroup.id] : [];
    }
    case GroupType.Question: {
      return groups
        .filter((group) => group.markerId === currentMarkerId)
        .map((group) => group.id);
    }
    default:
      return [];
  }
}

function HeaderTitle({
  assessmentName,
  onClickIcon,
}: {
  assessmentName: string;
  onClickIcon?: () => void;
}) {
  return (
    <>
      <button onClick={onClickIcon} className={styles.logoButton}>
        <Icon iconName="Cadmus" className={styles.logo} />
      </button>
      <Text kind="displayThree" className={styles.assessmentTitle}>
        {assessmentName}
      </Text>
    </>
  );
}

interface HeaderActionsProps {
  groups: MarkingGroup[];
  selectedUserId: string;
  onSelect: (userId: string) => void;
  groupType: GroupType | null;
  currentMarkerId: string | null;
}
function HeaderActions(props: HeaderActionsProps) {
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>(() =>
    initialiseSelectedGroupIds(
      props.groups,
      props.groupType,
      props.currentMarkerId,
      props.selectedUserId
    )
  );

  const selectedGroups = props.groups.filter((group) =>
    selectedGroupIds.includes(group.id)
  );
  const selectedStudents = selectedGroups.flatMap(
    (selectGroup) => selectGroup.members
  );

  const totalStudentsInGroup = selectedStudents.length ?? 0;
  const currentStudentIndex = selectedStudents.findIndex(
    (member) => member.id === props.selectedUserId
  );

  const onNext = () => {
    const index = selectedStudents.findIndex(
      (member) => member.id === props.selectedUserId
    );

    if (index === selectedStudents.length - 1) {
      if (selectedStudents[0] && selectedGroups[0])
        props.onSelect(selectedStudents[0].id);
    } else {
      const newUserId = selectedStudents[index + 1]?.id;
      const newGroupId = newUserId
        ? getBelongGroupByUserId(newUserId, selectedGroups)?.id
        : undefined;
      if (!newUserId || !newGroupId) return;
      props.onSelect(newUserId);
    }
  };

  const onPrev = () => {
    const index = selectedStudents.findIndex(
      (member) => member.id === props.selectedUserId
    );
    if (index === 0) {
      const newUserId = selectedStudents[selectedStudents.length - 1]?.id;
      const newGroupId = selectedGroups[selectedGroups.length - 1]?.id;
      if (!newUserId || !newGroupId) return;
      props.onSelect(newUserId);
    } else {
      const newUserId = selectedStudents[index - 1]?.id;
      const newGroupId = newUserId
        ? getBelongGroupByUserId(newUserId, selectedGroups)?.id
        : undefined;
      if (newUserId && newGroupId) props.onSelect(newUserId);
    }
  };

  return (
    <div className={styles.headerActions}>
      <EnrollmentDropdown
        groups={props.groups}
        selectedUserId={props.selectedUserId}
        onSelect={(userId, groupId) => {
          props.onSelect(userId);
          setSelectedGroupIds((state) => {
            const selected = state.includes(groupId);
            if (selected) return state;
            return [...state, groupId];
          });
        }}
        selectedStudents={selectedStudents}
        onToggleGroup={(groupId: string) =>
          setSelectedGroupIds((state) => {
            const selected = state.find(
              (selectedGroupId) => selectedGroupId === groupId
            );
            if (selected)
              return state.filter(
                (selectedGroupId) => selectedGroupId !== groupId
              );
            return [...state, groupId];
          })
        }
        selectedGroupIds={selectedGroupIds}
      />
      <div className={styles.column}>
        <StyledButton onClick={onPrev} size="sm">
          <Icon iconName="LeftArrowhead" />
        </StyledButton>

        {currentStudentIndex !== undefined && (
          <Text kind="bodyMd" color="neutralgrey500">
            {`${currentStudentIndex + 1}/${totalStudentsInGroup}`}
          </Text>
        )}
        <StyledButton onClick={onNext} size="sm">
          <Icon iconName="RightArrowhead" />
        </StyledButton>
      </div>
      <Button kind="secondary" onClick={onNext}>
        Next Student
      </Button>
    </div>
  );
}

interface EnrollmentDropdownProps {
  groups: MarkingGroup[];
  selectedStudents: MarkingGroup["members"];
  selectedUserId?: string;
  selectedGroupIds: string[];
  onSelect: (userId: string, groupId: string) => void;
  onToggleGroup: (groudId: string) => void;
}
function EnrollmentDropdown(props: EnrollmentDropdownProps) {
  const { groups, selectedUserId, onSelect, onToggleGroup } = props;
  const [open, setOpen] = useState(false);
  const currentUser = props.selectedStudents.find(
    (member) => member.id === selectedUserId
  );
  // const currentMarkingGroup = currentUser?.id
  //   ? getBelongGroupByUserId(currentUser.id, groups)
  //   : undefined;
  const currentMarkingGroup = props.selectedGroupIds[0]
    ? groups.find((g) => g.id === props.selectedGroupIds[0])
    : undefined;

  return (
    <Popover.Root open={open} onOpenChange={(open) => setOpen(open)}>
      <Popover.Trigger
        asChild
        onKeyDown={(e) => {
          if (!open) return;
          if (e.key === "Tab")
            document.getElementById("marking-group-popover")?.focus();
        }}
      >
        <StyledSelectButton activeColor="lilac500">{`${currentMarkingGroup?.name ?? ""} - ${currentUser?.displayName ?? ""}`}</StyledSelectButton>
      </Popover.Trigger>
      <Popover.Content
        id="marking-group-popover"
        className={styles.popOverContent}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <Popover.Card>
          <AccordionRoot type="multiple" defaultValue={props.selectedGroupIds}>
            {groups.map((group) => (
              <AccordionItem key={group.id} value={group.id}>
                <AccordionTrigger className={styles.accordionTrigger}>
                  <Checkbox
                    content={group.name}
                    onChange={() => {}}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        selectedUserId &&
                        group.members.find(
                          (member) => member.id === selectedUserId
                        )
                      )
                        return;
                      onToggleGroup(group.id);
                    }}
                    checked={props.selectedGroupIds.includes(group.id)}
                  />
                </AccordionTrigger>
                <AccordionContent>
                  {group.members.map((member) => (
                    <DropdownMenu.MenuItemDiv
                      color="lilac700"
                      key={member.id}
                      onClick={() => {
                        onSelect(member.id, group.id);
                        setOpen(false);
                      }}
                    >
                      <Text
                        kind="bodyMd"
                        color={
                          member.id === selectedUserId ? "lilac500" : undefined
                        }
                        bold={member.id === selectedUserId}
                      >
                        {member.displayName}
                      </Text>
                    </DropdownMenu.MenuItemDiv>
                  ))}
                </AccordionContent>
              </AccordionItem>
            ))}
          </AccordionRoot>
        </Popover.Card>
      </Popover.Content>
    </Popover.Root>
  );
}

function SettingsMenu() {
  return (
    <div className={styles.column}>
      <Settings />
      <a
        className={styles.anchor}
        href="https://support.cadmus.io"
        target="_blank"
        rel="noopener noreferrer"
        tabIndex={-1}
      >
        <ControlAction iconName="Book">Help + docs</ControlAction>
      </a>
    </div>
  );
}

const StyledButton = styled(Button)`
  background-color: ${colors.navy200};
  height: 24px;
  width: 24px;
`;

const StyledSelectButton = styled(SelectButton)`
  width: 200px;
`;
