import { styled } from "styled-components";

import {
  Anchored,
  colors,
  desktop,
  Modal,
  Spacer,
  tablet,
  Text,
} from "@vericus/cadmus-ui";

import {
  DuplicatePastAssessmentCallback,
  PreviewPastAssessmentCallback,
} from "@/data/templates";
import { SubjectFragment } from "@/generated/graphql";
import { PastAssessments } from "@/ui/templates/MyAssessments/PastAssessments";
import { PastAssessmentsFilters } from "@/ui/templates/MyAssessments/PastAssessmentsFilters";
import { PastAssessmentSkeletons } from "@/ui/templates/MyAssessments/PastAssessmentSkeletons";

import { IPastAssessment } from "../ConnectedMyAssessments";

export interface PastAssessmentsRootProps {
  loading: boolean;
  pastAssessments: IPastAssessment[];
  /**
   * Whether the exams feature is enabled.
   */
  areExamsEnabled: boolean;
  onDuplicate: DuplicatePastAssessmentCallback;
  onPreview: PreviewPastAssessmentCallback;
}

export const PastAssessmentsRoot = (props: PastAssessmentsRootProps) => {
  const groupedSubjects = new Map<string, SubjectFragment>();
  const subjects = props.pastAssessments.flatMap((pastAssessment) => {
    if (groupedSubjects.has(pastAssessment.subject.id)) {
      return [];
    }

    groupedSubjects.set(pastAssessment.subject.id, pastAssessment.subject);
    return [pastAssessment.subject];
  });

  return (
    <Container>
      <Header>
        <Modal.Title asChild>
          <Text kind={"headingTwo"} textAlign={"center"}>
            Past assessments
          </Text>
        </Modal.Title>

        <Modal.Description asChild>
          <PastAssessmentText kind="bodyMd" as="div">
            <Text kind={"bodyMd"} textAlign={"center"}>
              Save time by using existing instructions and settings. Make
              updates along the way.
            </Text>
            <Anchored
              newTab
              href="https://support.cadmus.io/reusing-past-assessments"
            >
              <LearnMoreLinkText kind="bodyMd" color="blue500" as="span">
                Learn more
              </LearnMoreLinkText>
            </Anchored>
          </PastAssessmentText>
        </Modal.Description>
      </Header>
      <Spacer spacing={32} />
      {!props.loading && props.pastAssessments?.length > 0 && (
        <FiltersContainer>
          <PastAssessmentsFilters
            areExamsEnabled={props.areExamsEnabled}
            subjects={subjects}
          />
        </FiltersContainer>
      )}
      {props.loading ? (
        <PastAssessmentSkeletons count={6} />
      ) : (
        <PastAssessments
          pastAssessments={props.pastAssessments}
          onDuplicate={props.onDuplicate}
          onPreview={props.onPreview}
        />
      )}
    </Container>
  );
};

const LearnMoreLinkText = styled(Text)`
  margin-top: 4px;
  text-align: center;
`;

const PastAssessmentText = styled(Text)`
  text-align: center;
`;

// color: ${colors.black100};
const FiltersContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${colors.white100};
  padding-bottom: 16px;
`;

const Header = styled.div``;

const Container = styled.div<{ px?: number; py?: number }>`
  padding: ${(p) => p.py ?? 64}px ${(p) => p.px ?? 64}px;
  // otherwise, the hover control bar overflows.
  min-width: 260px;
  padding: 48px 20px;
  ${tablet`
    padding: 48px 21px;
  `}
  ${desktop`
    padding: 64px 64px;
  `}
`;
