import { forwardRef } from "react";
import { styled } from "styled-components";

import { Pill, Text } from "@vericus/cadmus-ui";

interface MarkingGroupPillProps {
  /**
   * The name of the marking group.
   */
  groupName: string;
}

export const MarkingGroupPill = forwardRef<
  HTMLButtonElement,
  MarkingGroupPillProps
>((props: MarkingGroupPillProps, ref) => {
  return (
    <StyledPill ref={ref} basic>
      <StyledText kind="bodySm" color="shade1" title={props.groupName}>
        {props.groupName}
      </StyledText>
    </StyledPill>
  );
});

const StyledPill = styled(Pill)`
  // Showing about maximum 30 characters (at font-size: 13px)
  // before ellipsis start showing
  max-width: 230px;
`;

const StyledText = styled(Text)`
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
`;
