import { styled } from "styled-components";

import { colors, Spacer, Text } from "@vericus/cadmus-ui";

import FinalsEmpty from "assets/class/finals-empty.svg";

import { Container, Content } from "./styles";

export const NoFinalSubmissionsTable = () => {
  return (
    <Container>
      <Content>
        <Img src={FinalsEmpty} alt={"Magnifying glass"} />
        <Spacer spacing={34} />
        <Text kind={"headingFour"} color={"navy400"}>
          There are no final submissions yet
        </Text>
        <Spacer spacing={12} />
        <Text kind="bodyMd" color={"navy400"}>
          Once students submit, you’ll be able to view submissions, see their
          similarity scores, and provide feedback
        </Text>
      </Content>
    </Container>
  );
};

const Img = styled.img`
  display: block;
  width: 67px;
  margin: 0 auto;
  fill: ${() => colors.grey500};
`;
