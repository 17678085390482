import { styled } from "styled-components";

import { Button, colors, Spacer, Text } from "@vericus/cadmus-ui";

import PastAssessmentsEmpty from "assets/templates/past-assessments-empty.svg";

import { useOnNewInstructionsClick } from "@/ui/templates/templatesHooks";

/**
 * Component for when the user is trying to look at their past assessments
 * however, they don't have any.
 */
export const NoPastAssessments = () => {
  const onNewInstructionsClicked = useOnNewInstructionsClick();

  return (
    <Container>
      <Content>
        <Img src={PastAssessmentsEmpty} alt={"Sheets of paper"} />
        <Spacer spacing={40} />
        <StyledText kind={"headingThree"} textAlign={"center"}>
          {`You haven't released any assessments yet`}
        </StyledText>
        <StyledText kind="bodyMd" textAlign={"center"}>
          Once you release an assessment, you can duplicate it from here.
        </StyledText>
        <Spacer spacing={24} />
        <Button
          onClick={() => {
            onNewInstructionsClicked();
          }}
          kind={"secondary"}
        >
          Create new instructions
        </Button>
        <Spacer spacing={12} />
      </Content>
    </Container>
  );
};

const StyledText = styled(Text)`
  color: #5b6f98;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  height: 420px;
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${() => colors.grey100};
`;

const Img = styled.img`
  display: block;
  width: 70px;
  margin: 0 auto;
  fill: ${() => colors.grey500};
`;
