import { useMemo } from "react";
import { styled } from "styled-components";

import { colors, Text } from "@vericus/cadmus-ui";

import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES, MARKS } from "@contentful/rich-text-types";

const UL = styled.ul`
  padding-left: 1em;
  margin-bottom: 1.7em;
  margin-top: 0px;
`;

const OL = styled.ol`
  padding-left: 1em;
  margin-bottom: 1.7em;
  margin-top: 0px;
`;

const LI = styled.li`
  margin-bottom: 0.3em;
  padding-left: 0.25em;

  ${Text} {
    margin: 0;
  }
`;

const A = styled.a`
  color: inherit;
  /* text-decoration: none; */
  cursor: pointer;
`;

const STRONG = styled.strong`
  font-weight: bold;
`;

const referenceListRenderer: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Text kind="bodyMd">{children}</Text>
    ),
    [BLOCKS.UL_LIST]: (_node, children) => <UL>{children}</UL>,
    [BLOCKS.OL_LIST]: (_node, children) => <OL>{children}</OL>,
    [BLOCKS.LIST_ITEM]: (_node, children) => <LI>{children}</LI>,
    [INLINES.HYPERLINK]: (node, children) => {
      const link = node.data.uri;
      return (
        <A href={link} target="_blank">
          {children}
        </A>
      );
    },
  },
  renderMark: {
    [MARKS.BOLD]: (text) => <STRONG>{text}</STRONG>,
  },
};

export interface ReferencesProps {
  /** The RFT content from Contentful */
  document: Document;
}

export const References = ({ document }: ReferencesProps) => {
  const contentNodes = useMemo(
    () => documentToReactComponents(document, referenceListRenderer),
    [document]
  );
  return (
    <Container>
      <Text kind="headingThree">Further readings</Text>
      {contentNodes}
    </Container>
  );
};

const Container = styled.div`
  color: ${colors.black100};
  ${Text} {
    color: inherit;
  }
`;
