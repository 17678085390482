import { SearchListItem } from "@vericus/cadmus-ui";

import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@/data/store";

import { ImportedFile, ImportFileStatus, ParsedQuestionBank } from "./types";

export const selectQuestionBanks = (state: RootState) => state.questionBanks;

export const selectIsLoading = createSelector(
  [selectQuestionBanks],
  (questionBanks) => questionBanks.loading
);

export const selectImportFileProgress = createSelector(
  [selectQuestionBanks],
  (questionBanks) => ({
    processing: questionBanks.processingFiles,
    processed: questionBanks.processedFiles,
  })
);

export const selectImportedFiles = createSelector(
  [selectQuestionBanks],
  (questionBanks) =>
    questionBanks.banks
      .map((bank: ParsedQuestionBank) => {
        let status;
        if (!bank.questions) status = ImportFileStatus.FailedToImport;
        const supportedQuestions = bank.questions?.filter(
          (q) => q.questionType !== null
        );
        status =
          supportedQuestions.length === bank.questions?.length
            ? ImportFileStatus.Success
            : ImportFileStatus.PartialImport;

        return {
          fileId: bank.fileOrigin.fileOriginId,
          filename: bank.fileOrigin.filename,
          filepath: bank.fileOrigin.filepath,
          questions: bank.questions,
          status: status,
        } as ImportedFile;
      })
      .filter(({ questions }) => (questions ?? []).length > 0)
);
export const selectQuestionBankFileIds = (
  _state: RootState,
  ids: string[] | null
) => ids;

export const fileOriginsByIds = createSelector(
  [selectQuestionBanks, selectQuestionBankFileIds],
  (questionBanks, fileOriginIds) =>
    questionBanks.banks
      .filter(({ fileOrigin }) =>
        fileOriginIds?.includes(fileOrigin.fileOriginId)
      )
      .map(({ fileOrigin }) => fileOrigin)
);

export const selectSupportedImportedFiles = createSelector(
  [selectImportedFiles, selectQuestionBankFileIds],
  (importedFiles, bankIds) =>
    importedFiles
      .filter((file) => bankIds && file.fileId && bankIds.includes(file.fileId))
      .map(
        (file) =>
          ({
            ...file,
            questions: file.questions.filter((q) => q.questionType !== null),
          }) as ImportedFile
      )
      .filter(({ questions }) => questions.length > 0)
);

export const selectHasImportedFiles = createSelector(
  [selectQuestionBanks],
  (questionBanks) => questionBanks.banks.length > 0
);

export const selectQuestionBankFileId = (_state: RootState, id: string) => id;

export const selectQuestionsByFileIds = createSelector(
  [selectQuestionBanks, selectQuestionBankFileIds],
  (questionBanks, bankIds) =>
    questionBanks.banks
      .filter(
        (bank) => bankIds && bankIds.includes(bank.fileOrigin.fileOriginId)
      )
      .flatMap((bank) => bank.questions)
);

export const selectSupportedQuestionsByFileIds = createSelector(
  [selectQuestionBanks, selectQuestionBankFileIds],
  (questionBanks, bankIds) =>
    questionBanks.banks
      .filter(
        (bank) => bankIds && bankIds.includes(bank.fileOrigin.fileOriginId)
      )
      .flatMap((bank) => bank.questions.filter((q) => q.questionType !== null))
);

export const selectQuestionsByFileId = createSelector(
  [selectQuestionBanks, selectQuestionBankFileId],
  (questionBanks, bankId) =>
    questionBanks.banks.find((bank) => bank.fileOrigin.fileOriginId === bankId)
      ?.questions
);

export const selectSupportedQuestionsByFileId = createSelector(
  [selectQuestionBanks, selectQuestionBankFileId],
  (questionBanks, bankId) =>
    questionBanks.banks
      .find((bank) => bank.fileOrigin.fileOriginId === bankId)
      ?.questions?.filter((q) => q.questionType !== null)
);
export const selectFileIds = createSelector(
  [selectImportedFiles],
  (importedFiles) => importedFiles.map(({ fileId }) => fileId)
);

export const selectCategorySearchListItems = createSelector(
  [selectQuestionBanks],
  (questionBanks) =>
    questionBanks.categories.map((category) => {
      const questionsNum = questionBanks.banks.reduce(
        (acc, bank) =>
          acc + bank.questions.filter((q) => q.category === category).length,
        0
      );
      return {
        value: category,
        label: category,
        description: `${questionsNum} question${questionsNum > 1 ? "s" : ""}`,
      } as SearchListItem;
    })
);

export const selectFileName = createSelector(
  [selectQuestionBanks, selectQuestionBankFileId],
  (questionBanks, bankId) =>
    questionBanks.banks.find((bank) => bank.fileOrigin.fileOriginId === bankId)
      ?.fileOrigin.filename
);
