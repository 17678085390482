import { useCallback } from "react";

import NiceModal from "@ebay/nice-modal-react";
import { useJitsu } from "@jitsu/react";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { EnrollmentFragment, StudentSettingsInput } from "@/generated/graphql";
import { useSpawnToastCallback } from "@/ui/exam-special-considerations/hooks/useSpawnToastCallback";
import { useUpdateExamSpecialConsiderations } from "@/ui/exam-special-considerations/hooks/useUpdateExamSpecialConsiderations";
import { ResetExamTimingModal } from "@/ui/exam-special-considerations/modals/ResetExamTimingModal";
import { SpecialConsiderationFormAction } from "@/ui/exam-special-considerations/SpecialConsiderationFormAction";

export const useConfirmResetExamTiming = (assessmentId: string) => {
  const updateExamSpecialConsiderations = useUpdateExamSpecialConsiderations();
  const { track } = useJitsu();
  const spawnToast = useSpawnToastCallback();

  return useCallback(
    (enrollments: EnrollmentFragment[]) => {
      if (enrollments.length === 0) {
        return;
      }

      NiceModal.show(ResetExamTimingModal, {
        onPositiveAction: async () => {
          await updateExamSpecialConsiderations(
            assessmentId,
            enrollments.flatMap((enrollment) => {
              if (!enrollment.workSettings) {
                return [];
              }

              const {
                __typename,
                id: _id,
                ...currentSpecialConsiderations
              } = enrollment.workSettings;

              return [
                {
                  ...currentSpecialConsiderations,
                  examStartDate: null,
                  examEndDate: null,
                } as StudentSettingsInput,
              ];
            })
          );

          if (enrollments.length === 1) {
            await track(JitsuEvent.EXAM_TIMING_RESET, {
              assessment_id: assessmentId,
              enrollment_id: enrollments[0]!.id,
              student_id: enrollments[0]!.user.id,
            });
          } else {
            await track(JitsuEvent.EXAM_TIMING_BULK_RESET, {
              assessment_id: assessmentId,
              enrollment_id: enrollments.map((e) => e.id),
              student_id: enrollments.map((e) => e.user.id),
            });
          }

          spawnToast(
            enrollments,
            SpecialConsiderationFormAction.REMOVED_START_END_TIME
          );
        },
        enrollments: enrollments,
      });
    },
    [assessmentId, spawnToast, track, updateExamSpecialConsiderations]
  );
};
