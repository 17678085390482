import { Requirements } from "@/generated/graphql";
import { isExam, isExamInProgress } from "@/ui/task";
import { IReqsDifference } from "@/ui/task/requirements";

const settingsToCheck = [
  "wordLimit",
  "referencingStyle",
  "enableExamAutoSubmission",
  "weight",
  "examReadingTime",
  "examWritingTime",
  "examEndDate",

  // these ones should not be able to be changed once students have started
  // working on the exam but just in case
  "assessmentType",
  "examTiming",
  "examStartDate",
] satisfies (keyof IReqsDifference)[];

type SettingsToCheck = (typeof settingsToCheck)[number];

/**
 * @returns whether a modal should warn teachers about making changes
 * to exam settings while the assessment is happening.
 */
export const shouldShowWarning = (
  releasedRequirements: Pick<Requirements, SettingsToCheck> | null,
  diff?: Pick<IReqsDifference, SettingsToCheck>
): boolean => {
  if (!releasedRequirements || !diff) return false;
  if (!isExam(releasedRequirements)) return false;
  if (!isExamInProgress(releasedRequirements)) return false;

  const relevantSettingsChanged = settingsToCheck.some(
    (setting) => diff[setting]
  );
  return relevantSettingsChanged;
};
