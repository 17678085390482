import { arrayMove } from "@dnd-kit/sortable";

import { MoveableBlock } from "./types";

/**
 * Moves the given block to the end of a parent block's
 * children list.
 *
 * @param nodeIdBeingMoved the NODE ID of the block being moved
 * @param parentNodeId the NODE ID of the block that will become the parent
 * @returns a new array of blocks with the movement applied
 */
export function moveBlockIntoParent<Block extends MoveableBlock>(
  blocks: Block[],
  nodeIdBeingMoved: string,
  parentNodeId: string
): Block[] {
  const blockBeingMoved = blocks.find((blk) => blk.nodeId === nodeIdBeingMoved);
  if (!blockBeingMoved) return blocks;

  const newBlocks = blocks.flatMap((block) => {
    // take out the block being moved
    if (block.nodeId === nodeIdBeingMoved) return [];

    // repoint the root blocks pointing to the block being moved
    if (block.previousNodeId === nodeIdBeingMoved) {
      return [{ ...block, previousNodeId: blockBeingMoved.previousNodeId }];
    }
    return [block];
  });

  // get existing children for the parent
  const existingChildren = newBlocks.filter(
    (block) => block.parentNodeId === parentNodeId
  );

  let insertionIndex = null;

  // set up block that's become a child
  blockBeingMoved.parentNodeId = parentNodeId;
  if (existingChildren.length === 0) {
    blockBeingMoved.previousNodeId = null;

    // set the insertion index after the parent
    insertionIndex =
      newBlocks.findIndex((block) => block.nodeId === parentNodeId) + 1;
  } else {
    const lastExistingChild = existingChildren[existingChildren.length - 1]!;
    blockBeingMoved.previousNodeId = lastExistingChild.nodeId;
    // set the insertion index after the last existing child
    insertionIndex = newBlocks.indexOf(lastExistingChild) + 1;
  }

  // insert the new child after the last existing child
  newBlocks.splice(insertionIndex, 0, blockBeingMoved);
  return newBlocks;
}

/**
 * Move a block out of its parent and place immediately after
 * the parent at the root level.
 *
 * @param nodeId the Node ID of the block being moved out
 * @returns a new array of blocks with the movement applied
 */
export function moveBlockOutOfParent<Block extends MoveableBlock>(
  blocks: Block[],
  nodeId: string
): Block[] {
  const blockBeingMoved = blocks.find((blk) => blk.nodeId === nodeId);
  if (!blockBeingMoved) return blocks;

  const { parentNodeId } = blockBeingMoved;
  if (!parentNodeId) return blocks;

  // take out the block being moved
  const newBlocks = blocks.filter((block) => block.nodeId !== nodeId);

  // repoint blocks pointing to the block being moved (e.g. sibling blocks)
  newBlocks.forEach((block) => {
    if (block.previousNodeId === blockBeingMoved.nodeId) {
      block.previousNodeId = blockBeingMoved.previousNodeId;
    }
  });

  // get the latest of:
  // 1. the parent block itself
  // 2. a child block with the same parent
  const lastBlockOfInterest: Block | null = newBlocks.reduce(
    (lastBlockSoFar: Block | null, currentBlock) => {
      if (
        currentBlock.nodeId === parentNodeId ||
        currentBlock.parentNodeId === parentNodeId
      ) {
        return currentBlock;
      }

      return lastBlockSoFar;
    },
    null
  );

  // we should always have a value for this, if not, return the input to be safe
  if (!lastBlockOfInterest) return blocks;

  // update the block being moved
  blockBeingMoved.previousNodeId = parentNodeId;
  blockBeingMoved.parentNodeId = null;

  // repoint other blocks pointing to the parent to the block being moved (e.g. other root blocks)
  newBlocks.forEach((block) => {
    if (block.previousNodeId === parentNodeId) {
      block.previousNodeId = blockBeingMoved.nodeId;
    }
  });

  // insert the block being moved straight after the latest parent (or the parent's child).
  const insertionIndex = newBlocks.indexOf(lastBlockOfInterest) + 1;
  newBlocks.splice(insertionIndex, 0, blockBeingMoved);

  return newBlocks;
}

/**
 * Moves a block within an array.
 *
 * @param blocks list of blocks, e.g. could be only root blocks, or only child blocks
 * @param nodeId NODE ID of the node being moved
 * @param destinationIndex the destination index within the passed array
 * @returns a new array of blocks with the move applied
 */
export function moveBlockInArray<Block extends MoveableBlock>(
  blocks: Block[],
  nodeId: string,
  destinationIndex: number
): Block[] {
  const sourceIndex = blocks.findIndex((blk) => blk.nodeId === nodeId);
  const sourceBlock = blocks[sourceIndex];
  if (!sourceBlock) return blocks;

  // move the block in the array
  const newBlocks = arrayMove([...blocks], sourceIndex, destinationIndex);

  // update the blocks
  const updatedBlocks = newBlocks.map((block, index) => {
    if (index === 0) {
      block.previousNodeId = null;
    } else {
      block.previousNodeId = newBlocks[index - 1]!.nodeId;
    }
    return block;
  });

  return updatedBlocks;
}

/**
 * Merge an array containing children blocks and/or hidden/deleted blocks
 * into an array containing root blocks.
 *
 * An example usage would be:
 *   - move root blocks around in an array only with root blocks
 *   - merge the children back into that list to get out a full sorted array of blocks
 *
 * @param allBlocks all blocks (root and children blocks)
 * @param rootBlocks an array only with root blocks
 * @returns a sorted array only
 */
export function mergeOtherBlocksIntoRootBlocks<Block extends MoveableBlock>(
  allBlocks: Block[],
  rootBlocks: Block[]
): Block[] {
  const rootBlockIds = new Set<string>(
    rootBlocks.map(({ nodeId: blockId }) => blockId)
  );

  /**
   * the blocks that were excluded as a root block for some reason
   * e.g. overview blocks are excluded as a root block because they don't
   * participate in drag and drop
   */
  const excludedRootBlocks = allBlocks.filter(
    ({ parentNodeId, nodeId }) => !parentNodeId && !rootBlockIds.has(nodeId)
  );
  const mergedArray = rootBlocks.reduce(
    (mergedBlocksSoFar: Block[], rootBlock) => {
      const childrenBlocks = allBlocks.filter(
        ({ parentNodeId }) => parentNodeId === rootBlock.nodeId
      );
      mergedBlocksSoFar.push(rootBlock, ...childrenBlocks);
      return mergedBlocksSoFar;
    },
    // start with the excluded blocks so data is not lost when moving root blocks
    excludedRootBlocks
  );
  return mergedArray;
}

/**
 * Replace all child blocks that have the provided parent id with new child blocks.
 **/
export function replaceChildBlocks<Block extends MoveableBlock>(
  allBlocks: Block[],
  parentNodeId: string,
  newChildren: Block[]
): Block[] {
  const parentIndex = allBlocks.findIndex(
    ({ nodeId }) => parentNodeId === nodeId
  );
  const parent: Block | undefined = allBlocks[parentIndex];
  if (!parent) return allBlocks;
  // filter out the old children
  const newBlocks = allBlocks.filter(
    (block) => block.parentNodeId !== parentNodeId
  );

  // insert the new children after the parent
  newBlocks.splice(parentIndex + 1, 0, ...newChildren);
  return newBlocks;
}
