import { colors, Icon, Text, Tooltip } from "@vericus/cadmus-ui";

import { BigPill } from "@/ui/shared/BigPill";

import { ProgressStage } from "../../types";

interface ProgressPillProps {
  /**
   * Latest stage of the student's progress.
   */
  progress: ProgressStage;
  /**
   * Number of times the student has seen the feedback.
   * @default 0
   */
  feedbackViews?: number;
  /**
   * Draft submissions are enabled or not.
   * @default false
   */
  hasDrafting?: boolean;
  /**
   * Whether a draft has been submitted by the student.
   */
  hasSubmittedDraft?: boolean;
  /**
   * Marks are not available in the assessment.
   * @default false
   */
  markingDisabled?: boolean;
  /**
   * Whether assessment has a feedback date or not
   * @default false
   */
  noFeedbackDate?: boolean;
  /** e.g. Daniela, Patrick, Juana */
  userFirstName: string;
}

export const ProgressPill = ({
  progress,
  hasDrafting,
  hasSubmittedDraft,
  userFirstName,
  markingDisabled = false,
  feedbackViews = 0,
  noFeedbackDate = false,
}: ProgressPillProps) => {
  if (
    progress >= ProgressStage.FeedbackViewed &&
    !noFeedbackDate &&
    !markingDisabled &&
    feedbackViews > 0
  ) {
    return (
      <Tooltip
        content={
          <Text kind="bodySm">
            {userFirstName} has viewed their feedback{" "}
            {feedbackViews === 1 ? "once" : <>{feedbackViews} times</>}
          </Text>
        }
      >
        <BigPill backgroundColor="navy500" textColor="white100">
          <Text kind="systemMd" color="white100">
            feedback{" "}
            <Icon
              iconName="Eye"
              style={{
                fill: colors.navy200,
                verticalAlign: "middle",
                marginLeft: 1,
                marginBottom: 2,
                marginRight: 2,
              }}
            />
            {feedbackViews}
          </Text>
        </BigPill>
      </Tooltip>
    );
  }
  if (progress >= ProgressStage.Final) {
    return (
      <BigPill backgroundColor="teal200" textColor="cyan800">
        <Text kind="systemMd">submitted</Text>
      </BigPill>
    );
  }
  if (progress >= ProgressStage.Draft && hasDrafting && hasSubmittedDraft) {
    return (
      <BigPill backgroundColor="blue200" textColor="blue800">
        <Text kind="systemMd">draft</Text>
      </BigPill>
    );
  }
  if (progress >= ProgressStage.Started) {
    return (
      <BigPill backgroundColor="indigo200" textColor="indigo800">
        <Text kind="systemMd">in progress</Text>
      </BigPill>
    );
  }
  if (progress >= ProgressStage.Accessed) {
    return (
      <BigPill backgroundColor="poppy200" textColor="poppy900">
        <Text kind="systemMd">viewed only</Text>
      </BigPill>
    );
  }
  return (
    <BigPill backgroundColor="white200" textColor="grey600">
      <Text kind="systemMd">not accessed</Text>
    </BigPill>
  );
};

export const calcProgressStage = (
  finalFeedbackViewCount: number,
  finalSubmitted: boolean,
  draftSubmitted: boolean,
  startedWork: boolean,
  accessed: boolean
): ProgressStage => {
  let progress: ProgressStage = ProgressStage.Enrolled;
  if (finalFeedbackViewCount > 0) {
    progress = ProgressStage.FeedbackViewed;
  } else if (finalSubmitted) {
    progress = ProgressStage.Final;
  } else if (draftSubmitted) {
    progress = ProgressStage.Draft;
  } else if (startedWork) {
    progress = ProgressStage.Started;
  } else if (accessed) {
    progress = ProgressStage.Accessed;
  }

  return progress;
};

export const displayProgressStage = (progressStage: ProgressStage) => {
  switch (progressStage) {
    case ProgressStage.Accessed:
      return "viewed only";
    case ProgressStage.Started:
      return "in progress";
    case ProgressStage.Draft:
      return "draft";
    case ProgressStage.Final:
      return "submitted";
    case ProgressStage.FeedbackViewed:
      return "feedback";
    case ProgressStage.Enrolled:
    default:
      return "not accessed";
  }
};
