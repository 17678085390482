import { Text, Tooltip } from "@vericus/cadmus-ui";

import { BigPill } from "@/ui/shared/BigPill";

/**
 * Tooltip message for deferred enrollments.
 */
export const DeferredMessage = () => {
  const tooltip = (
    <Text kind="bodySm">
      Deferred students are listed in the class list, but excluded from class
      progress metrics and insights.
    </Text>
  );
  return (
    <Tooltip content={tooltip}>
      <BigPill backgroundColor="grey50" textColor="grey600">
        <Text kind="systemMd">deferred</Text>
      </BigPill>
    </Tooltip>
  );
};
