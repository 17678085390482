import { useEffect, useState } from "react";

import { EditLineIcon, TickIcon } from "@vericus/cadmus-icons";
import {
  ControlButton,
  Icon,
  LimitedNumberInput,
  LinkButton,
  Text,
  Tooltip,
} from "@vericus/cadmus-ui";

import * as styles from "./moderation-cells.css";

interface ModeratedPointsInputProps {
  /** Maximum scores */
  maxScore: number;
  /** Minimum scores */
  minScore?: number;
  /** Callback to save updated points */
  handleUpdateWorkOutcomeScore?: (newScore: number) => void;
  /** Callback to reset changes */
  onReset?: () => void;
  /** Current scores value */
  score?: number;
  /** Callback to redirect to marking tool */
  redirectToMarkingTool?: () => void;
  /** Whether should disable workoutcome score update */
  disableScoreUpdate?: boolean;
}

export function ModeratedPointsInput(props: ModeratedPointsInputProps) {
  const { score = 0 } = props;

  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState<string | null>(score.toString());
  const [prevScore, setPrevScore] = useState(score);

  // Update value if the score prop changes
  useEffect(() => {
    if (prevScore !== score) {
      setPrevScore(score);
      setValue(score.toString());
    }
  }, [score, prevScore]);

  const handleSave = () => {
    if (value === null || value === "") {
      props.handleUpdateWorkOutcomeScore?.(0);
    } else {
      const newScore = parseFloat(value);
      if (
        newScore !== score &&
        newScore <= props.maxScore &&
        (props.minScore === undefined || newScore >= props.minScore)
      ) {
        props.handleUpdateWorkOutcomeScore?.(newScore);
      } else {
        setValue(score.toString());
      }
    }
    setEditing(false);
  };

  const handleReset = () => {
    setEditing(false);
    setValue(score.toString());
    props.onReset?.();
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue === "") {
      setValue(null);
    } else {
      setValue(inputValue);
    }
    e.preventDefault();
  };

  return (
    <div className={styles.pointsInput}>
      {editing ? (
        <>
          <LimitedNumberInput
            max={props.maxScore}
            readOnly={!editing}
            placeholder="0"
            value={
              value
                ? ceilAndFormatDecimalNumber(parseFloat(value)).toString()
                : ""
            }
            onChange={handleValueChange}
            style={{ width: 64 }}
          />
          <Tooltip
            content={<Text kind="systemSm">Confirm</Text>}
            width="auto"
            size="sm"
            side="bottom"
          >
            <button
              className={styles.inputActionButton}
              aria-label="Save"
              onClick={handleSave}
            >
              <TickIcon label={""} size={14} />
            </button>
          </Tooltip>
          <Tooltip
            content={<Text kind="systemSm">Reset changes</Text>}
            width="auto"
            size="sm"
            side="bottom"
          >
            <button
              className={styles.inputActionButton}
              aria-label="Reset changes"
              onClick={handleReset}
            >
              <Icon iconName="Reset" />
            </button>
          </Tooltip>
        </>
      ) : (
        <>
          <LinkButton
            onClick={() => props.redirectToMarkingTool?.()}
            kind="primary"
          >
            <Text kind="bodyMd" as="span" color="lilac500">
              {ceilAndFormatDecimalNumber(props.score || 0).toString()}/
              {props.maxScore}
            </Text>{" "}
            <Icon iconName="Open" iconColor="primary" />
          </LinkButton>
          {!props.disableScoreUpdate && (
            <ControlButton
              aria-label="Edit"
              onClick={() => setEditing(true)}
              icon={<EditLineIcon label={""} size={24} />}
            />
          )}
        </>
      )}
    </div>
  );
}

function ceilAndFormatDecimalNumber(num: number) {
  return Math.ceil(num * 100) / 100;
}
