import { ComponentProps, forwardRef } from "react";

import { DropdownMenu } from "@vericus/cadmus-ui";

import { ExamTiming } from "@/generated/graphql";
import { ExamSettings } from "@/graphql/types/EnrollmentSettings";

interface SpecialConsiderationsMenuItemProps
  extends ComponentProps<typeof DropdownMenu.Item> {
  /**
   * The selected enrollments in question with their calculated exam settings
   */
  enrollmentExamSetting: ExamSettings;

  // Menu items relating to the extra time
  /**
   * Callback for intent to remove the extra time for
   * currently selected enrollment
   */
  removeExtraTime: () => void;

  /**
   * Callback for intent to set the extra time for
   * currently selected enrollment
   */
  setExtraTime: () => void;

  // Menu items relating to alternative start times
  /**
   * Callback for intent to set the alternative exam date for
   * currently selected enrollment.
   */
  setAlternativeExamDate: () => void;

  /**
   * Callback for intent to remove the alternative
   * exam date for selected enrollments.
   */
  removeAlternativeExamDate: () => void;

  /**
   * Callback for intent to mark all
   * currently selected enrollments as deferred.
   */
  markAsDeferred: () => void;

  /**
   * Cancel the enrollment's status 'deferred'
   */
  cancelMarkAsDeferred: () => void;

  /**
   * The exam timing for the exam (Live/Window)
   */
  examTiming: ExamTiming;
}

/**
 * Menu item for the special considerations dropdown menu.
 */
export const SpecialConsiderationsMenuItem = forwardRef<
  HTMLDivElement,
  SpecialConsiderationsMenuItemProps
>((props, ref) => {
  const {
    enrollmentExamSetting,
    removeExtraTime,
    setExtraTime,
    setAlternativeExamDate,
    removeAlternativeExamDate,
    markAsDeferred,
    cancelMarkAsDeferred,
    ...buttonProps
  } = props;

  const updatedExtraTime =
    enrollmentExamSetting?.extraExamReadingTime ||
    enrollmentExamSetting?.extraExamWritingTime;

  const updatedAlternativeExamDate =
    props.examTiming === ExamTiming.Live &&
    enrollmentExamSetting.alternateExamStartDate;
  const updatedAlternativeExamWindow =
    props.examTiming === ExamTiming.Window &&
    (enrollmentExamSetting.alternateExamStartDate ||
      enrollmentExamSetting.alternateExamEndDate);

  const isDeferred = enrollmentExamSetting.deferred;

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger asChild>
        <DropdownMenu.Item
          ref={ref}
          iconName="CalendarCheck"
          content="Add special considerations"
          {...buttonProps}
        />
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent>
        {updatedExtraTime ? (
          <>
            <DropdownMenu.Item
              onSelect={setExtraTime}
              iconName="Time"
              content="Update extra time"
            />
            <DropdownMenu.Item
              onSelect={removeExtraTime}
              iconName="Trash"
              content="Remove extra time"
            />
          </>
        ) : (
          <>
            <DropdownMenu.Item
              onSelect={setExtraTime}
              iconName="Time"
              content="Give extra time"
            />
          </>
        )}

        {updatedAlternativeExamDate && (
          <>
            <DropdownMenu.Item
              onSelect={setAlternativeExamDate}
              iconName="Calendar"
              content="Change exam date"
            />
            <DropdownMenu.Item
              onSelect={removeAlternativeExamDate}
              iconName="Trash"
              content="Reset to main exam timing"
            />
          </>
        )}

        {updatedAlternativeExamWindow && (
          <>
            <DropdownMenu.Item
              onSelect={setAlternativeExamDate}
              iconName="Calendar"
              content="Change exam window"
            />
            <DropdownMenu.Item
              onSelect={removeAlternativeExamDate}
              iconName="Trash"
              content="Reset to main exam timing"
            />
          </>
        )}

        {!updatedAlternativeExamDate &&
          !updatedAlternativeExamWindow &&
          props.examTiming === ExamTiming.Live && (
            <>
              <DropdownMenu.Item
                onSelect={props.setAlternativeExamDate}
                iconName="Calendar"
                content={`Change exam date`}
              />
            </>
          )}

        {!updatedAlternativeExamDate &&
          !updatedAlternativeExamWindow &&
          props.examTiming === ExamTiming.Window && (
            <>
              <DropdownMenu.Item
                onSelect={props.setAlternativeExamDate}
                iconName="Calendar"
                content={`Change exam window`}
              />
            </>
          )}

        {!isDeferred && (
          <>
            <DropdownMenu.Item
              onSelect={markAsDeferred}
              iconName="Unset"
              content="Mark as deferred"
            />
          </>
        )}
        {isDeferred && (
          <>
            <DropdownMenu.Item
              onSelect={cancelMarkAsDeferred}
              iconName="Trash"
              content="Cancel 'mark as deferred'"
            />
          </>
        )}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
});
