import { memo } from "react";
import { styled } from "styled-components";

import {
  CadmusEditorContent,
  Editor,
} from "@vericus/cadmus-editor-prosemirror";

import { EditorWrapper } from "../../components/editor-wrapper";
import { Dispatch } from "../state";
import { BodyDiv } from "../styles";
import { AcceptedPhrasesInput } from "./accepted-phrases-input";

interface ShortAnswerProps {
  /** Question field identifier */
  fieldIdentifier: string;
  /** Rich text content */
  editor: Editor | null;

  /** Reducer dispatcher */
  dispatch: Dispatch;
  /** Phrases that are correct response */
  matchPhrases: string[];
  /** Error when question promptDoc less than 20 characters */
  promptDocTooShort?: boolean;
}

/**
 * Short answer question body with number of accepted answer phrases to auto
 * mark on.
 */
const ShortQuestionBodyComponent = (props: ShortAnswerProps) => {
  const { editor, dispatch, matchPhrases, promptDocTooShort, fieldIdentifier } =
    props;
  return (
    <Root>
      <McqEditorWrapper hasError={promptDocTooShort}>
        <CadmusEditorContent editor={editor} />
      </McqEditorWrapper>
      <Content>
        <AcceptedPhrasesInput
          value={matchPhrases}
          onAddPhrase={(answer) =>
            dispatch({ type: "AddAcceptedAnswer", answer, fieldIdentifier })
          }
          onDeletePhrase={(answer) =>
            dispatch({ type: "RemoveAcceptedAnswer", answer, fieldIdentifier })
          }
        />
      </Content>
    </Root>
  );
};

export const ShortQuestionBody = memo(ShortQuestionBodyComponent);

const Root = styled(BodyDiv)`
  background: ${(p) => p.theme.colors.white100};
  display: flex;
  row-gap: 16px;
  margin-bottom: 8px;
`;

const McqEditorWrapper = styled(EditorWrapper)`
  margin: 16px 0px;
`;

const Content = styled.div`
  padding: 0px 45px;
`;
