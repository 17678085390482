import { styled } from "styled-components";

import { Spacer, Text } from "@vericus/cadmus-ui";

import emptyImg from "assets/edit/resources-empty.svg";

export const ResourceEmpty = () => (
  <EmptyContainer>
    <Spacer spacing={27} />
    <ImgContainer>
      <Img src={emptyImg} alt="" />
    </ImgContainer>
    <Spacer spacing={18} />
    <Text kind="headingFive">Attach some resources</Text>
    <Text kind="bodySm" color="shade1">
      Upload some extra resources to the assessment. Students can access and
      download these from within Cadmus. Please ensure your files are safe.
    </Text>
  </EmptyContainer>
);

const EmptyContainer = styled.div`
  width: 100%;
  max-width: 450px;
  margin: auto;
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  display: block;
  height: 63px;
`;
