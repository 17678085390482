// Attach listeners
import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import "@/data/class/listener";
import "@/data/localFlags/listener";

import { listenerMiddleware } from "./listenerMiddleware";
import { rootReducer } from "./rootReducer";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
});

export type RootState = ReturnType<typeof rootReducer>;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = typeof store.dispatch;

export default store;
