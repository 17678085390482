import { memo } from "react";
import { styled } from "styled-components";

import {
  CadmusEditorContent,
  Editor,
} from "@vericus/cadmus-editor-prosemirror";
import { Radio, Text } from "@vericus/cadmus-ui";

import { EditorWrapper } from "../../components/editor-wrapper";
import { MarkAsCorrectButton } from "../../components/mark-as-correct-button";
import { Dispatch } from "../state";
import { BodyDiv } from "../styles";

interface Props {
  /** Question ID */
  questionId: string;
  /** Question field identifier */
  fieldIdentifier: string;
  /** Editor with question prompt contents. */
  editor: Editor | null;
  /** If the truthy option is correct */
  isTrue?: boolean;
  /** State dispatcher */
  dispatch: Dispatch;
  /** Question promptDoc less than 20 characters */
  promptDocTooShort?: boolean;
}

/**
 * Question body for a TrueFalse question.
 */
function TrueFalseQuestionBodyComponent(props: Props) {
  const {
    fieldIdentifier,
    questionId,
    dispatch,
    editor,
    promptDocTooShort,
    isTrue,
  } = props;

  const isTrueCorrect = isTrue === true;
  const isFalseCorrect = isTrue === false;
  return (
    <Root>
      <StyledEditorWrapper hasError={promptDocTooShort}>
        <CadmusEditorContent editor={editor} />
      </StyledEditorWrapper>
      <Content>
        <OptionsWrapper>
          <Selection>
            <SelectionContent selected={isTrue}>
              <Radio
                name={`option-${questionId}-item`}
                colorVariant="primary"
                onChange={() =>
                  dispatch({
                    type: "ToggleBooleanResponse",
                    isTrue: true,
                    fieldIdentifier,
                  })
                }
                checked={isTrueCorrect}
              >
                <Option kind="bodySm" color="navy700">
                  True
                </Option>
              </Radio>
            </SelectionContent>
            <MarkAsCorrectButton
              isCorrect={isTrueCorrect}
              onMarkAsCorrect={() => {
                dispatch({
                  type: "ToggleBooleanResponse",
                  isTrue: true,
                  fieldIdentifier,
                });
              }}
            />
          </Selection>
          <Selection>
            <SelectionContent selected={isFalseCorrect}>
              <Radio
                name={`option-${questionId}-item`}
                colorVariant="primary"
                onChange={() => {
                  dispatch({
                    type: "ToggleBooleanResponse",
                    isTrue: false,
                    fieldIdentifier,
                  });
                }}
                checked={isFalseCorrect}
              >
                <Option kind="bodySm" color="navy700">
                  False
                </Option>
              </Radio>
            </SelectionContent>
            <MarkAsCorrectButton
              isCorrect={isFalseCorrect}
              onMarkAsCorrect={() => {
                dispatch({
                  type: "ToggleBooleanResponse",
                  isTrue: false,
                  fieldIdentifier,
                });
              }}
            />
          </Selection>
        </OptionsWrapper>
      </Content>
    </Root>
  );
}

export const TrueFalseQuestionBody = memo(TrueFalseQuestionBodyComponent);

const Root = styled(BodyDiv)`
  background: ${(p) => p.theme.colors.white100};
`;

const StyledEditorWrapper = styled(EditorWrapper)`
  margin: 16px 0px;
`;

const Content = styled.div`
  padding: 0px 45px;
`;

const OptionsWrapper = styled.div`
  flex-direction: column;
  row-gap: 8px;
  display: inline-flex;
`;

const Selection = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const SelectionContent = styled.div<{ selected?: boolean }>`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  flex: 1 0 0;
  border-radius: 3px;
  flex: 1 0 0;
  box-sizing: border-box;
  background: ${(p) => (p.selected ? p.theme.colors.lilac200 : "unset")};
  border: ${(p) =>
    p.selected
      ? `1px solid  ${p.theme.colors.lilac500}`
      : `1px solid ${p.theme.colors.grey300}`};
  position: relative;

  &:hover {
    border: 1px solid ${(p) => p.theme.colors.lilac500};
  }
`;

const Option = styled(Text)`
  min-width: 236px;
`;
