import NiceModal from "@ebay/nice-modal-react";

import { useAppSelector } from "@/data/hooks";
import { RootState } from "@/data/store";
import {
  FeedbackReleaseBasePool,
  FeedbackVisibilityInput,
  ModerationQuery,
  useModerationQuery,
  useReleaseFeedbackForEveryoneMutation,
  useUpdateFeedbackVisibilityMutation,
} from "@/generated/graphql";
import { useRootNavigate } from "@/router/routing";

import { useModerationScoreContext } from "../../context/moderation-score-context";
import { getFeedbackReleaseRuleStates } from "../../utils";
import { FeedbackReleaseConfirmModalWithData } from "../feedback-release-confirm-modal";
import { FeedbackReleaseDisclaimerModal } from "../feedback-release-disclaimer";
import { FeedbackReleaseSummary } from "./feedback-release-summary";

interface Props {
  assessmentId: string;
}

/** Feedback release summary component connected to graphql */
export function FeedbackReleaseSummaryWithData(props: Props) {
  return <WithQuery {...props} />;
}

function WithQuery(props: Props) {
  const { data, loading, error } = useModerationQuery({
    variables: { assessmentId: props.assessmentId },
  });

  if (!data || loading || error) return null;

  return <WithMutations assessmentId={props.assessmentId} data={data} />;
}

function WithMutations(props: Props & { data: ModerationQuery }) {
  const {
    feedbackReleaseRules,
    feedbackVisibility,
    workOutcomes,
    moderationPenaltyRules,
  } = props.data;

  // The feedback disclaimer modal has been dismissed before
  const dismissedFeedbackReleaseDisclaimer = useAppSelector(
    (state: RootState) => state.localFlags.dismissedFeedbackReleaseDisclaimer
  );

  // Router
  const navigate = useRootNavigate();

  // Mutations
  const [updateFeedbackVisibility] = useUpdateFeedbackVisibilityMutation();
  const [updateFeedbackForEveryone] = useReleaseFeedbackForEveryoneMutation();
  const releaseFeedbackInstantly = async (input: FeedbackVisibilityInput) => {
    await updateFeedbackForEveryone({
      variables: {
        assessmentId: props.assessmentId,
        feedbackVisibility: input,
      },
    });
    NiceModal.show(FeedbackReleaseConfirmModalWithData, {
      assessmentId: props.assessmentId,
      feedbackReleaseRuleStates: getFeedbackReleaseRuleStates(
        [
          {
            ...ReleaseAllInstantlyRule,
            id: "release-all-rule",
            executedAt: null,
            executedStudentIds: null,
          },
        ],
        enrollments
      ),
    });
  };

  const { table } = useModerationScoreContext();

  const enrollments = table
    .getCoreRowModel()
    .rows.map((row) => row.original.enrollment);

  const releasedFeedbacksCount = workOutcomes.reduce(
    (acc, outcome) =>
      outcome.feedbackReleasedTimestamp !== null ? acc + 1 : acc,
    0
  );

  const pendingRules = feedbackReleaseRules.filter(
    (rule) => rule.scheduledAt !== null && rule.executedAt === null
  );

  return (
    <FeedbackReleaseSummary
      hasStudentSelection={table.getIsSomeRowsSelected()}
      feedbackViewLevel={feedbackVisibility.feedbackViewLevel}
      allowPdfDownload={feedbackVisibility.withFeedbackPdf}
      outcomeCount={enrollments.length}
      releasedOutcomeCount={releasedFeedbacksCount}
      ruleCount={feedbackReleaseRules.length}
      pendingRuleCount={pendingRules.length}
      penaltyCount={moderationPenaltyRules?.length ?? 0}
      onGotoSelectivelyReleaseFeedbackPage={() =>
        navigate("/grader/moderate/feedback")
      }
      onGoToPenaltySetupPage={() => {
        navigate("/grader/moderate/penalty");
      }}
      onConfirmReleaseInstantly={(input) => {
        if (dismissedFeedbackReleaseDisclaimer) {
          releaseFeedbackInstantly(input);
        } else {
          NiceModal.show(FeedbackReleaseDisclaimerModal, {
            onConfirm: () => releaseFeedbackInstantly(input),
          });
        }
      }}
      onConfirmSelectivelyRelease={async (input) => {
        await updateFeedbackVisibility({
          variables: {
            assessmentId: props.assessmentId,
            input,
          },
        });
        navigate("/grader/moderate/feedback");
      }}
    />
  );
}

const ReleaseAllInstantlyRule = {
  basePool: FeedbackReleaseBasePool.All,
  excludedStudentIds: [],
  excludedTags: [],
  id: null,
  includedStudentIds: [],
  includedTags: [],
  scheduledAt: null,
};
