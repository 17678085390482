import { Divider, DropdownMenu, SelectButton, Text } from "@vericus/cadmus-ui";

import clsx from "clsx";

import { QuestionType } from "@/generated/graphql";
import { Counter } from "@/ui/shared/Counter";

import {
  getSectionQuestionCheckedState,
  updateSelectedQuestionIds,
} from "../../utils/question-list-selectors";
import * as styles from "./navigation-item.css";
import { QuestionItem } from "./types";

interface Props {
  /**
   * List of questions to filter
   */
  items: QuestionItem[];
  /**
   * Current list of filtered question IDs.
   */
  filteredQuestionIds: string[];
  /**
   * Callback to update the filtered question Ids.
   */
  setFilteredQuestionIds: (questionIds: string[]) => void;
  /**
   * Extra toggle to show unmarked questions only.
   */
  showUnMarkedOnly: boolean;
  /**
   * Callback to update the showUnMarkedOnly state.
   */
  setShowUnMarkedOnly: (unmarkedOnly: boolean) => void;
}

/**
 * Dropdown with Question list to filter the ones the marker wants to
 * selectively see.
 */
export function NavigationFilter(props: Props) {
  const {
    items,
    filteredQuestionIds,
    setFilteredQuestionIds,
    setShowUnMarkedOnly,
    showUnMarkedOnly,
  } = props;

  // All items or only the markable ones without a score.
  const visibleItems = showUnMarkedOnly
    ? items.filter((item) => item.markable && item.score === null)
    : items;

  const onSelect = (item: QuestionItem) => {
    const updatedQuestionIds = updateSelectedQuestionIds(
      filteredQuestionIds,
      item,
      visibleItems
    );
    setFilteredQuestionIds(updatedQuestionIds);
  };

  // From the visible items, how many are filtered?
  const filterLength = visibleItems.filter((it) =>
    filteredQuestionIds.includes(it.questionId)
  ).length;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <SelectButton activeColor="lilac500">
          Questions
          {filterLength > 0 && <Counter active>{filterLength}</Counter>}
        </SelectButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className={styles.filterDropdown}>
        <div className={styles.filterButtonSection}>
          <DropdownMenu.LinkItem
            asChild
            color="lilac500"
            onSelect={(e) => {
              e.preventDefault();
              setFilteredQuestionIds(items.map((item) => item.questionId));
              setShowUnMarkedOnly(false);
            }}
          >
            <Text
              as="span"
              kind="bodyMd"
              color="lilac500"
              className={styles.filterButton}
            >
              Select all
            </Text>
          </DropdownMenu.LinkItem>
          <DropdownMenu.LinkItem
            color="lilac500"
            onSelect={(e) => {
              e.preventDefault();
              setFilteredQuestionIds([]);
              setShowUnMarkedOnly(false);
            }}
          >
            <Text
              as="span"
              kind="bodyMd"
              color="lilac500"
              className={styles.filterButton}
            >
              Clear all{" "}
            </Text>
          </DropdownMenu.LinkItem>
        </div>
        <Divider className={styles.divider} />
        {visibleItems.map((item) => {
          let checkState: "indeterminate" | boolean =
            filteredQuestionIds.includes(item.questionId);

          if (item.questionType === QuestionType.Section) {
            checkState = getSectionQuestionCheckedState(
              item.questionId,
              items,
              filteredQuestionIds
            );
          }

          return (
            <DropdownMenu.CheckboxItem
              key={item.questionId}
              checked={checkState}
              onSelect={(e) => {
                e.preventDefault();
                onSelect(item);
              }}
              className={clsx(item.parentNodeId && styles.questionDropdownList)}
            >
              <Text kind="bodyMd">
                {item.questionType === QuestionType.Section
                  ? `Part ${item.orderLabel}`
                  : `Question ${item.orderLabel}`}
              </Text>
            </DropdownMenu.CheckboxItem>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
