import { useEffect } from "react";

import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

/**
 * Tracks all pages visited by users and send it
 * to Google Analytics.
 */
export const useGA = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);
};
