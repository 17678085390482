import { ReactNode } from "react";

import {
  CreateSubQuestionIcon,
  FillInBlanksIcon,
  LongAnswerIcon,
  MultipleChoiceIcon,
  QuestionRoundIcon,
  ShortAnswerIcon,
  TrueFalseIcon,
} from "@vericus/cadmus-icons";
import { Text } from "@vericus/cadmus-ui";

import { QuestionType } from "@/generated/graphql";

import { ImportableQuestionType } from "../../data";
import * as styles from "./question-type-pill.css";

const config: Record<
  ImportableQuestionType,
  { icon: ReactNode; label: string }
> = {
  [QuestionType.Mcq]: {
    icon: <MultipleChoiceIcon label="" size={24} />,
    label: "MCQ",
  },
  [QuestionType.Short]: {
    icon: <ShortAnswerIcon label="" size={24} />,
    label: "SHRT",
  },
  [QuestionType.Extended]: {
    icon: <LongAnswerIcon label="" size={24} />,
    label: "EXT.",
  },
  [QuestionType.Truefalse]: {
    icon: <TrueFalseIcon label="" size={24} />,
    label: "T/F",
  },
  [QuestionType.Sub]: {
    icon: <CreateSubQuestionIcon label="" size={24} />,
    label: "SUB",
  },
  [QuestionType.Blanks]: {
    icon: <FillInBlanksIcon label="" size={24} />,
    label: "FILL",
  },
} as const;

export const QuestionTypePill = ({
  questionType,
}: {
  questionType: ImportableQuestionType;
}) => (
  <span className={styles.pill}>
    <span className={styles.icon}>
      {questionType !== null ? (
        config[questionType].icon
      ) : (
        <QuestionRoundIcon label="Question type" size={16} />
      )}
    </span>
    <Text kind="label">
      {questionType !== null ? config[questionType].label : "N/A"}
    </Text>
  </span>
);
