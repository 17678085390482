import { styled } from "styled-components";

export const Container = styled.div`
  height: 100%;
  min-height: 464px;
  background-color: ${(p) => p.theme.colors.bgrey300};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 24px 45px;
  border-radius: 8px;
`;

export const Content = styled.div`
  max-width: 623px;
  text-align: center;
`;
