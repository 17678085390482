import { SectionLabel, Spacer } from "@vericus/cadmus-ui";

import { Anchor, Grid, Tip } from "./Tip";

/**
 * DraftTip
 *
 * A tip found in DraftSettings in the SetttingsPage. Links to the the
 * following guide article:
 *  - Using drafts for formative feedback
 */

export const DraftTip = () => (
  <>
    <Spacer spacing={45} />
    <SectionLabel title="Tip" />
    <Spacer spacing={27} />
    <Grid>
      <Tip color="yellow100">
        Looking for ways to deliver timely, formative feedback?{" "}
        <Anchor href="https://cadmus.io/guides/using-drafts-for-formative-feedback">
          Try using drafting.
        </Anchor>
      </Tip>
    </Grid>
  </>
);
