import React from "react";

import { redirect, RouteObject } from "react-router-dom";

import { App } from "@/ui/app/App";
import { CrashError, NoMatchError, UnauthorizedError } from "@/ui/errors";
import { Logout } from "@/ui/shared/Logout";

import { serialiseQueryParams } from "./routing";

const ClassRoute = React.lazy(() => import("@/ui/class/ClassRoute"));
const TaskRoute = React.lazy(() => import("@/features/task/TaskRoute"));
const InsightsRoute = React.lazy(() => import("@/ui/insights/InsightsRoute"));
const IntegrityAssuranceRoute = React.lazy(
  () => import("@/ui/integrity-assurance/IntegrityAssuranceRoute")
);
const GraderRoute = React.lazy(
  () => import("@/features/marking/routes/grader-route")
);

/**
 * Some typing for the custom route handler.
 * Used to pass in additional information about the route.
 *
 * See <Route handle> in the react-router-dom docs about this.
 * There's currently no guidance on typing this.
 */
export interface CustomRouteHandler {
  /**
   * Whether this route is an Athena root. The current possible root routes are:
   * - Task
   * - Class
   * - Integrity Assurance
   * - Insights
   */
  root?: "task" | "class" | "integrity-assurance" | "insights" | "grader";
}

export default [
  {
    path: "/logout",
    Component: Logout,
    errorElement: <Logout />,
  },
  {
    path: "/goodbye",
    Component: UnauthorizedError,
    errorElement: <CrashError />,
  },
  {
    path: "/:tenant/assessment/:assessmentId/*",
    Component: App,
    errorElement: <CrashError />,
    children: [
      {
        // redirect setup/* to task/*
        path: "setup/*",
        loader: ({ params, request }) => {
          const { tenant, assessmentId } = params;
          const parsedOriginalUrl = new URL(request.url);
          const originalSearchParamsString = serialiseQueryParams(
            parsedOriginalUrl.searchParams
          );
          const urlStart = `/${tenant}/assessment/${assessmentId}/task`;
          // attach any subpaths to the end of the redirect if there are any
          const redirectUrlPath = params["*"]
            ? `${urlStart}/${params["*"]}`
            : urlStart;
          // pass along any query params to the redirected route
          const redirectUrl = `${redirectUrlPath}?${originalSearchParamsString}`;
          return redirect(redirectUrl);
        },
      },
      {
        path: "task/*",
        Component: TaskRoute,
        handle: {
          root: "task",
        } satisfies CustomRouteHandler,
      },
      {
        path: "class/*",
        Component: ClassRoute,
        handle: {
          root: "class",
        } satisfies CustomRouteHandler,
      },
      {
        path: "integrity-assurance/*",
        Component: IntegrityAssuranceRoute,
        handle: {
          root: "integrity-assurance",
        } satisfies CustomRouteHandler,
      },
      {
        path: "insights/*",
        Component: InsightsRoute,
        handle: {
          root: "insights",
        } satisfies CustomRouteHandler,
      },
      {
        path: "grader/*",
        Component: GraderRoute,
        handle: {
          root: "grader",
        } satisfies CustomRouteHandler,
      },
      {
        path: "*", // Catch all
        element: <NoMatchError />,
      },
    ],
  },
  {
    path: "*", // Catch all
    element: <NoMatchError />,
  },
] satisfies RouteObject[];
