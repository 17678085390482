import {
  ReleasedRequirementsFragment,
  RequirementsFragment,
} from "generated/graphql";

/**
 * Convert release requirements back into editable Task requirements.
 */
export function fromReleasedRequirements(
  reqs: ReleasedRequirementsFragment
): RequirementsFragment {
  const {
    id,
    allowLateResubmission,
    dueDate,
    returnDate,
    maxGrade,
    draftDueDate,
    wordLimit,
    timeLimit,
    weight,
    referencingStyle,
    sViewReports,
    draftSViewReports,
    countReferences,
    anonymousMarking,
    gradingService,
    assessmentType,
    enableExamAutoSubmission,
    enableSubmissionPdf,
    examEndDate,
    examReadingTime,
    examStartDate,
    examTiming,
    examType,
    examWritingTime,
    lateSubmissionTimeLimit,
    examFeedbackDate,
    enableExamLdb,
  } = reqs;

  return {
    id,
    dueDate: dueDate,
    returnDate: returnDate,
    allowLateResubmission,

    maxGrade,
    sViewReports,
    anonymousMarking,
    gradingService,

    draftDueDate: draftDueDate ?? null,
    noDraft: !draftDueDate,
    draftSViewReports,

    weight,
    noWeight: !(weight || weight === 0),
    wordLimit,
    noLimit: !(wordLimit || wordLimit === 0),
    timeLimit,
    noTimeLimit: !(timeLimit || timeLimit === 0),
    countReferences: countReferences ?? false,

    referencingStyle,
    noReferencing: !(referencingStyle || referencingStyle === ""),
    hasChanged: true,

    assessmentType: assessmentType,
    enableExamAutoSubmission: enableExamAutoSubmission,
    enableSubmissionPdf: enableSubmissionPdf,
    examEndDate: examEndDate ?? null,
    examReadingTime: examReadingTime ?? null,
    examStartDate: examStartDate,
    examTiming: examTiming,
    examType: examType ?? [],
    examWritingTime: examWritingTime,
    lateSubmissionTimeLimit: lateSubmissionTimeLimit ?? null,
    noExamReadingTime: !(examReadingTime || examReadingTime === 0),

    examFeedbackDate: examFeedbackDate,
    noExamFeedbackDate: !examFeedbackDate,
    enableExamLdb,
    enableExamAccessCode: false,
    restrictMarkingToolPdf: false,
    markingToolPdf: null,
  };
}
