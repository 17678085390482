import { styled } from "styled-components";

import { colors, Text } from "@vericus/cadmus-ui";

export interface TemplateInfoProps {
  about: string;
}

export const TemplateInfo = ({ about }: TemplateInfoProps) => {
  return (
    <Container>
      <Info>
        <Text kind="headingSix" style={{ color: colors.grey500 }}>
          About this template
        </Text>
        <Text kind="bodySm">{about}</Text>
      </Info>
      <Info>
        <Text kind="headingSix" style={{ color: colors.grey500 }}>
          How to use this template
        </Text>
        <Text kind="bodySm">
          Edit the Instructions and Checklist to suit the specifics of your
          task. Add relevant resources, then set key requirements.
        </Text>
      </Info>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px 88px 48px 88px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 100px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-basis: 50%;
`;
