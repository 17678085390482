import { Pill, Tooltip } from "@vericus/cadmus-ui";

interface Props {
  submittedBy: string;
}

/**
 * Pill to indicate the submission was made by some teacher rather than the
 * student who owns the work.
 */
export function ForceSubmittedPill(props: Props) {
  const { submittedBy } = props;

  return (
    <Tooltip content={`Force submitted by ${submittedBy}`}>
      <Pill basic>Force Submitted</Pill>
    </Tooltip>
  );
}
