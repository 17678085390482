import { HtmlHTMLAttributes, ReactNode, useCallback } from "react";
import styled from "styled-components";

import { colors, Icon, Text, Tooltip } from "@vericus/cadmus-ui";

interface Props extends HtmlHTMLAttributes<HTMLButtonElement> {
  /** Button contents */
  children: ReactNode;
  /** Callback to be invoked on click*/
  onSort?: (dir: "asc" | "desc") => void;
  /** Current sort state of the column */
  sorted: "asc" | "desc" | null;
  /** Optional button tooltip */
  tooltip?: ReactNode;
  /** Optional tooltip size */
  tooltipSize?: "sm" | "md";
  /** Optional tooltip width */
  tooltipWidth?: "auto" | number;
}

export const HeaderButton = (
  props: { children: ReactNode } & HtmlHTMLAttributes<HTMLButtonElement>
) => {
  const { children, ...rest } = props;
  return (
    <ButtonRoot {...rest}>
      <Text kind="label">{children}</Text>
    </ButtonRoot>
  );
};

export const SortingHeaderButton = (props: Props) => {
  const {
    children,
    onSort,
    sorted,
    tooltip,
    tooltipSize = "sm",
    tooltipWidth = "auto",
    ...rest
  } = props;

  const onToggle = useCallback(() => {
    if (onSort) {
      const newDir = sorted === null || sorted === "asc" ? "desc" : "asc";
      onSort(newDir);
    }
  }, [onSort, sorted]);

  const button = (
    <ButtonRoot onClick={onToggle} {...rest}>
      <Text kind="label">{children}</Text> <SortCaret sorted={sorted} />
    </ButtonRoot>
  );

  if (tooltip) {
    return (
      <Tooltip size={tooltipSize} width={tooltipWidth} content={tooltip}>
        {button}
      </Tooltip>
    );
  }

  return button;
};

const ButtonRoot = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: -4px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  fill: ${colors.grey500};

  &:not(:disabled) {
    &:hover {
      background-color: ${(p) => p.theme.background.action1};
    }

    &:focus,
    &:active {
      background-color: ${(p) => p.theme.background.action2};
    }
  }
`;

/**
 * Render a sorting caret based on the column's current sort state.
 */
function SortCaret(props: { sorted?: "asc" | "desc" | null }) {
  if (props.sorted === "desc") {
    return <ColoredIcon iconName="UpCaret" />;
  } else return <ColoredIcon iconName="DownCaret" />;
}

const ColoredIcon = styled(Icon)`
  fill: ${(p) => p.theme.colors.lilac500};
`;
