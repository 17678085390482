import { Colored } from "@vericus/cadmus-ui";

import { GridLead, GridTitle, HeadingGrid } from "ui/DatePicker";

import { Step, STEP_CONFIG } from "./internal";

interface Props {
  step: Step;
}

/**
 * Header for the `<TaskTimeline />`.
 *
 * Displays title and subtitle information on the current step.
 */
export const Header = ({ step }: Props) => {
  const config = STEP_CONFIG[step];
  return (
    <HeadingGrid>
      <GridTitle kind="displayTwo">
        {config.number}. Set a
        <Colored color={config.color}> {config.text} </Colored>
        {config.afterText}
      </GridTitle>
      <GridLead kind="lead" color="shade1">
        {config.description}
      </GridLead>
    </HeadingGrid>
  );
};
