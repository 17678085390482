import { createClient } from "@jitsu/react";
import { JitsuClient } from "@jitsu/sdk-js";

import * as config from "@/config";

// initialize Jitsu client
const jitsuClient = createClient({
  tracking_host: `${config.API_ENDPOINT}/ebisu`,
  key: "athena",
  ip_policy: "strict",
  cookie_policy: "strict",
  randomize_url: true,
});

export default jitsuClient;

/**
 * Jitsu client which does not emit anything. Useful for feature flagging and
 * storybooks/tests.
 */
export const EmptyJitsuClient: JitsuClient = {
  track: async () => {},
  id: async () => {},
  init: () => {},
  rawTrack: async () => {},
  _send3p: async () => {},
  interceptAnalytics: () => {},
  set() {},
  unset() {},
};
