import { formatPoints } from "@vericus/cadmus-common";
import { Pill, Popover, Text } from "@vericus/cadmus-ui";

import { CellContext } from "@tanstack/table-core";

import { WorkOutcomeSummary } from "@/features/moderation/types";

import { StudentListRow } from "../../class/progress/types";
import * as Grid from "../InfoGrid";
import * as styles from "./moderation-cells.css";

export function AdjustedPointsCell(
  props: CellContext<StudentListRow, WorkOutcomeSummary | undefined>
) {
  const workOutcomeSummary = props.getValue();
  const adjustedPoints = workOutcomeSummary
    ? calculateAdjustedPoints(workOutcomeSummary)
    : null;

  return (
    <Grid.AdjustedScoreCol>
      {adjustedPoints ? (
        <Popover.Root>
          <Popover.Trigger asChild>
            <Pill color="#FAECE6">
              {formatPoints(adjustedPoints.total, true)}
            </Pill>
          </Popover.Trigger>

          <Popover.Content>
            <Popover.Card width={330}>
              <AdjustedPointsCard adjustedPoints={adjustedPoints} />
            </Popover.Card>
          </Popover.Content>
        </Popover.Root>
      ) : (
        <Text kind="bodySm">--</Text>
      )}
    </Grid.AdjustedScoreCol>
  );
}

function calculateAdjustedPoints(
  summary: WorkOutcomeSummary
): AdjustedPoints | null {
  const byMarker = summary.markerModifier ?? 0;
  const byModerator = summary.moderatorModifier ?? 0;
  const byPenalty = summary?.finalScorePenalty ?? 0;

  if (byMarker === 0 && byModerator === 0 && byPenalty === 0) return null;

  return {
    byMarker,
    byModerator,
    byPenalty,
    total: byMarker + byModerator - byPenalty,
  };
}

interface AdjustedPoints {
  total: number;
  byMarker: number;
  byModerator: number;
  byPenalty: number;
}

interface PointsBreakdownProps {
  adjustedPoints: AdjustedPoints;
}

function AdjustedPointsCard({ adjustedPoints }: PointsBreakdownProps) {
  // Count the number of modifiers that are not 0
  let modifierCount = 0;
  [
    adjustedPoints.byMarker,
    adjustedPoints.byModerator,
    adjustedPoints.byPenalty,
  ].forEach((mod) => {
    if (mod !== 0) {
      modifierCount++;
    }
  });

  return (
    <div className={styles.container}>
      {adjustedPoints.byMarker !== 0 && (
        <div className={styles.item}>
          <Text kind="bodyMd">Adjusted by Marker</Text>
          <Text kind="bodyMd">
            {formatPoints(adjustedPoints.byMarker, true)}
          </Text>
        </div>
      )}

      {adjustedPoints.byModerator !== 0 && (
        <div className={styles.item}>
          <Text kind="bodyMd">Adjusted by Moderator</Text>
          <Text kind="bodyMd">
            {formatPoints(adjustedPoints.byModerator, true)}
          </Text>
        </div>
      )}

      {adjustedPoints.byPenalty !== 0 && (
        <div className={styles.item}>
          <Text kind="bodyMd">Penalties applied</Text>
          <Text kind="bodyMd">{formatPoints(-adjustedPoints.byPenalty)}</Text>
        </div>
      )}

      {modifierCount >= 2 && (
        <>
          <Popover.Separator />
          <div className={styles.item}>
            <Text kind="bodyMd">Total adjusted points</Text>
            <Text kind="bodyMd">{formatPoints(adjustedPoints.total)}</Text>
          </div>
        </>
      )}
    </div>
  );
}
