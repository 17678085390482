import { useCallback, useMemo, useState } from "react";
import { styled } from "styled-components";

import {
  Button,
  Colored,
  Desk,
  Divider,
  Grid,
  Input,
  Text,
} from "@vericus/cadmus-ui";

import { dateColors } from "ui/DatePicker";
import { COMPACT_TIME_FORMAT, hrAndMinDuration } from "utils/datetime";

import { StudentsSettingsPageProps } from "./types";
import { useStudentExtensionGroup } from "./useStudentExtensionGroup";
import { useUpdateStudentSettings } from "./useUpdateStudentSettings";

interface Props extends StudentsSettingsPageProps {
  timeLimit: number;
}

/**
 *
 */
export function StudentsTimeLimitExtensionPage(props: Props) {
  const {
    enrollments,
    timeLimit,
    activeStudentNums,
    groups,
    assessmentId,
    onClose,
  } = props;
  const group = useStudentExtensionGroup(enrollments, groups);
  const timeLimits = enrollments
    .map(({ workSettings }) => workSettings?.timeLimit)
    .filter((x) => x !== null && x !== undefined) as number[];
  const uniqueTimeLimits = Array.from(new Set(timeLimits));
  const [extraTimeLimit, setExtraTimeLimit] = useState(
    uniqueTimeLimits.length === 1 && timeLimit
      ? uniqueTimeLimits[0]! - timeLimit
      : null
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // validation 0 < (val + timeLimit) <= 1_000_000
      const val = parseInt(event.currentTarget.value);
      const total = val + timeLimit;
      if (0 < total && total <= 1_000_000) {
        setExtraTimeLimit(val);
      } else if (isNaN(val)) {
        setExtraTimeLimit(null);
      }
    },
    [setExtraTimeLimit, timeLimit]
  );

  const { updateTimeLimits } = useUpdateStudentSettings(assessmentId, onClose);

  const newTimeLimitUnitText =
    extraTimeLimit === null || extraTimeLimit > 1 ? "minutes" : "minute";

  const totalTime = timeLimit + (extraTimeLimit ?? 0);

  const [headerText, headerSummary, promptPrefix] = useMemo(() => {
    const isWholeClass =
      enrollments.length === activeStudentNums && enrollments.length !== 1;
    let titleText, summary, prefix;
    if (isWholeClass) {
      titleText = "the whole class";
      prefix = "Everyone ";
    } else if (group) {
      titleText = `${group.name}'s marking group (${group.members.length} students)`;
      prefix = `Everyone in {group.name}'s marking group  `;
    } else if (enrollments.length === 1) {
      const name = enrollments[0]!.user.name;
      titleText = name;
      prefix = "They ";
    } else {
      const names = enrollments.map(({ user }) => user.name);
      const last = names.pop();
      titleText = `${enrollments.length} students`;
      if (names.length <= 50) {
        summary = (
          <>
            {" "}
            <strong>{names.join(", ")}</strong> and <strong>{last}</strong>{" "}
          </>
        );
      }
      prefix = "They ";
    }
    return [titleText, summary, prefix];
  }, [group, activeStudentNums, enrollments]);

  return (
    <Desk.Layout>
      <Desk.Table>
        <FlexCenter>
          <TimeGrid>
            <GridTitle kind="headingOne" textAlign="center">
              <Colored color={dateColors.extension}>Extend due date</Colored>{" "}
              for {headerText}
            </GridTitle>
            {headerSummary && (
              <GridLead kind="bodyMd" color="shade1">
                {headerSummary}
              </GridLead>
            )}
            <GridDivider />
            <GridContent>
              <Text kind="bodyMd">
                {promptPrefix} will receive an <b>extra</b>{" "}
                <Input
                  value={extraTimeLimit ?? ""}
                  onChange={handleChange}
                  type="number"
                  min="0"
                  max={1_000_000 - timeLimit}
                  aria-label="time limit"
                />{" "}
                <b>{newTimeLimitUnitText}</b> to complete this assessment. The
                total available time will be{" "}
                <b>{hrAndMinDuration(totalTime, COMPACT_TIME_FORMAT)}</b>.
              </Text>
            </GridContent>
          </TimeGrid>
        </FlexCenter>
      </Desk.Table>
      <Desk.Drawer>
        <Footer>
          <Button marginRight={18} onClick={onClose}>
            Cancel
          </Button>
          <Button
            kind="primary"
            onClick={() =>
              extraTimeLimit &&
              updateTimeLimits(enrollments, timeLimit + extraTimeLimit)
            }
            disabled={extraTimeLimit === null}
            aria-disabled={extraTimeLimit === null ? "true" : "false"}
            marginRight={18}
          >
            Set time extension
          </Button>
        </Footer>
      </Desk.Drawer>
    </Desk.Layout>
  );
}

const TimeGrid = styled(Grid)`
  grid-template-areas:
    ". . title title title title title . ."
    ". lead lead lead lead lead lead lead ."
    ". . . . divider . . . ."
    ". content content content content content content content .";
  grid-row-gap: 36px;
`;

const GridTitle = styled(Text)`
  grid-area: title;
  text-align: center;
`;

const GridLead = styled(Text)`
  grid-area: lead;
  text-align: center;
`;

const GridContent = styled.div`
  grid-area: content;
`;

const GridDivider = styled(Divider)`
  grid-area: divider;
`;

const FlexCenter = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
