import { CorrectRoundIcon, TickRoundIcon } from "@vericus/cadmus-icons";
import { Text, Tooltip } from "@vericus/cadmus-ui";

import * as styles from "./mark-as-correct-button.css";
import MarkAsCorrectIcon from "./mark-as-correct-icon";

interface MarkAsCorrectButtonProps {
  isCorrect: boolean;
  onMarkAsCorrect: () => void;
}

export const MarkAsCorrectButton = ({
  isCorrect,
  onMarkAsCorrect,
}: MarkAsCorrectButtonProps) => (
  <div className={styles.Root}>
    <button
      className={styles.Button}
      onClick={onMarkAsCorrect}
      aria-label="Mark as correct"
    >
      <Tooltip
        content={<MarkAsCorrectIcon label="" />}
        side="right"
        passThrough={isCorrect}
        disableContentStyles
      >
        {isCorrect ? (
          <CorrectRoundIcon label="Currently is correct" size={18} />
        ) : (
          <TickRoundIcon label="Currently is incorrect" size={18} />
        )}
      </Tooltip>
    </button>
    <Text
      kind="bodySm"
      style={{ visibility: isCorrect ? "visible" : "hidden" }}
    >
      Correct answer
    </Text>
  </div>
);
