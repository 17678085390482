import { useCallback } from "react";
import { styled } from "styled-components";

import { colors, DropdownMenu, SelectButton, Text } from "@vericus/cadmus-ui";

import { useJitsu } from "@jitsu/react";
import { Counter } from "ui/shared/Counter";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { RangeFilter } from "@/ui/class/progress/types";

import { ClassTable } from "../../table";
import { useOnClearFilter } from "../hooks";

interface Props {
  table: ClassTable;
}

type FilterValue = RangeFilter[] | undefined;

// Similarity ranges with labels
const SIMILARITY_FILTERS: RangeFilter[] = [
  { label: "75-100%", upper: 101, lower: 75 },
  { label: "50-74%", upper: 75, lower: 50 },
  { label: "25-49%", upper: 50, lower: 25 },
  { label: "<25%", upper: 25, lower: 0 },
];

/**
 * Dropdown multi-select filter for similarity score ranges.
 *
 * Applies the filter values as `RangeFilter[]` on the `similarity` react-table
 column.
 */
export const SimilarityFilter = ({ table }: Props) => {
  const column = table.getColumn("similarity")!;
  const { track } = useJitsu();
  const onClear = useOnClearFilter(column, "Similarity");

  // Toggle a `filter` from the existing list of filters applied on the column.
  const setFilter = useCallback(
    (filter: RangeFilter) => {
      column.setFilterValue((current: RangeFilter[] = []) => {
        let updated: FilterValue = undefined;
        if (current.find((f) => f.label === filter.label)) {
          updated = current.filter((f) => f.label !== filter.label);
        } else {
          updated = [filter, ...current];
        }
        track(JitsuEvent.CLASS_FILTER_UPDATED, {
          filter_group: "Similarity",
          filter_toggled: filter.label,
          filter_value: updated.map((f) => f.label),
          filter_type: "dropdown",
        });
        return updated;
      });
    },
    [column, track]
  );

  const current = column.getFilterValue() as FilterValue;
  const filters: RangeFilter[] = current ?? [];

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <SelectButton
          selected={filters.length > 0}
          activeColor="lilac500"
          style={{ minWidth: 160 }}
        >
          Similarity{" "}
          {filters?.length > 0 && <Counter active>{filters.length}</Counter>}
        </SelectButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {SIMILARITY_FILTERS.map((filter, index) => (
          <DropdownMenu.CheckboxItem
            key={index}
            checked={Boolean(filters.find((f) => f.label === filter.label))}
            onSelect={(e) => {
              e.preventDefault();
              setFilter(filter);
            }}
          >
            <SimilarityContent>
              {index === 0 && <Flag color={colors.redA500} />}
              {index === 1 && <Flag color={colors.orangeA500} />}
              {index === 2 && <Flag color={colors.yellowA500} />}
              <Text kind="bodySm">{filter.label}</Text>
            </SimilarityContent>
          </DropdownMenu.CheckboxItem>
        ))}
        <DropdownMenu.LinkItem
          content="Clear filters"
          onSelect={(e) => {
            e.preventDefault();
            onClear();
          }}
          color="lilac500"
        />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const SimilarityContent = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
`;
const Flag = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: ${(p) => p.color};
`;
