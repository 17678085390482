import { useCallback, useState } from "react";

import { Color } from "@vericus/cadmus-ui";

import {
  differenceInCalendarDays,
  format,
  isAfter,
  isBefore,
  isSameMinute,
} from "date-fns";
import { Moment } from "moment";

/**
 * Colors of dates. All date color styling should take from `dateColors`
 */
export const dateColors = {
  dueDate: "indigo500" as const,
  returnDate: "cyan500" as const,
  draftDueDate: "pink500" as const,
  extension: "redA500" as const,
  scheduledDate: "blue500" as const,
  today: "black200" as const,
};

/**
 * Create a callback to re-render the Datepicker to the current month.
 *
 * Returns a pair of `[todayKey, callback]`, where the 'todayKey' value should
 * be provided to the `<DatePicker />` component which needs to jump. The jump
 * is triggered using the 'callback'.
 *
 * Since the `react-dates` DatePicker provides no such prop to hook into, we
 * trick it to re-render itself which automatically sets it to the current
 * month. The re-renders can be triggered by providing a `key` prop to it, which
 * is updated when calling the returned `jumpToToday` callback.
 */
export function useJumpToToday(): [number, () => void] {
  const [key, setKey] = useState(0);
  const jumpToToday = useCallback(() => {
    setKey((k) => k + 1);
  }, [setKey]);
  return [key, jumpToToday];
}

export type DayHighlight = Record<string, Color[]>;
/**
 * Properties to customise the highlight for a single date.
 * key is the date in ISO8601 (e.g. '2020-04-17' ) and value is color highlight
 */
export const dayHighlightKey = (d: Date) => format(d, "yyyy-MM-dd");

/**
 * @deprecated Use `dayHighlightKey` instead
 * Properties to customise the highlight for a single date.
 * key is the date in ISO8601 (e.g. '2020-04-17' ) and value is color highlight
 */
export const dayHighlightKeyDeprecated = (d: Moment) => d.format("YYYY-MM-DD");

/**
 * Get the dayHighlight for defined dates and today
 */
export function getHighlightDays(dates: {
  dueDate?: string;
  returnDate?: string;
  draftDueDate?: string;
  extensionDate?: string;
  scheduledDate?: string;
}): DayHighlight {
  const dayHighlight: DayHighlight = {};

  // dueDate
  if (dates.dueDate) {
    const key = dayHighlightKey(new Date(dates.dueDate));
    dayHighlight[key] = [...(dayHighlight[key] || []), dateColors.dueDate];
  }

  // returnDate
  if (dates.returnDate) {
    const key = dayHighlightKey(new Date(dates.returnDate));
    dayHighlight[key] = [...(dayHighlight[key] || []), dateColors.returnDate];
  }

  // draftDueDate
  if (dates.draftDueDate) {
    const key = dayHighlightKey(new Date(dates.draftDueDate));
    dayHighlight[key] = [...(dayHighlight[key] || []), dateColors.draftDueDate];
  }

  // extensionDate
  if (dates.extensionDate) {
    const key = dayHighlightKey(new Date(dates.extensionDate));
    dayHighlight[key] = [...(dayHighlight[key] || []), dateColors.extension];
  }

  // scheduledDate
  if (dates.scheduledDate) {
    const key = dayHighlightKey(new Date(dates.scheduledDate));
    dayHighlight[key] = [
      ...(dayHighlight[key] || []),
      dateColors.scheduledDate,
    ];
  }

  // today
  const key = dayHighlightKey(new Date());
  dayHighlight[key] = [...(dayHighlight[key] ?? []), dateColors.today];

  return dayHighlight;
}

/**
 * Creates predicate that will disable dates
 * that are exclusively before `before` and after `after`.
 *
 * note 1: This predicate can be passed as `isOutsideRange` prop for `DatePicker`.
 * note 2: day is of type `any` because react-dates is wrongly typed.
 * note 3: This function could also call `isDateInvalid` function but would
 *          reduce readability.
 */
export const isOutsideRangePredicate =
  (before: Date[], after: Date[]) =>
  (day: Moment): boolean => {
    const currentDay = day.toDate();

    for (let i = 0; i < before.length; i++) {
      const b = before[i]!;
      if (differenceInCalendarDays(currentDay, b) < 0) return true;
    }

    for (let i = 0; i < after.length; i++) {
      const a = after[i]!;
      if (differenceInCalendarDays(currentDay, a) > 0) return true;
    }

    return false;
  };

/**
 * Checks whether date `d` is invalid or not.
 *
 * date is invalid if
 *    1. before (inclusive to minutes) any of the dates in `before`
 *    2. after (inclusive to minutes) any of the dates in `after`
 *    `onBeforeInvalid` and `onAfterInvalid` will also be called respectively.
 */
export const isDateInvalid = (
  d: Date,
  before: Date[],
  after: Date[],
  onBeforeInvalid?: (b: Date) => void,
  onAfterInvalid?: (a: Date) => void
): boolean => {
  for (let i = 0; i < before.length; i++) {
    const b = before[i]!;
    if (isSameMinute(d, b) || isBefore(d, b)) {
      onBeforeInvalid?.(b);
      return true;
    }
  }

  for (let i = 0; i < after.length; i++) {
    const a = after[i]!;
    if (isSameMinute(d, a) || isAfter(d, a)) {
      onAfterInvalid?.(a);
      return true;
    }
  }

  return false;
};
