import { useCallback } from "react";

import { Content } from "@vericus/cadmus-editor-prosemirror";

import {
  QuestionFieldFragment,
  QuestionFragment,
  QuestionType,
  SaveQuestionStateInput,
  SimpleChoiceFragment,
  TaskBlockFragment,
} from "@/generated/graphql";

import {
  DEFAULT_MATCH_SIMILARITY,
  defaultInteraction,
  defaultQuestionFields,
  defaultResponse,
  trueFalseChoices,
} from "../question-form/default-question-fields";
import { InteractionKind, mapFieldInteractionKind } from "../question-form/interaction-kind-utils";
import {
  PromptState,
  QuestionFormField,
  State as QuestionFormState,
  State,
} from "../question-form/state";

/** Deserialize task builder block editor from string to json */
export function deserializeTaskBuilderBlockEditor(
  content: string | null
): Content {
  if (!content) return null;
  try {
    return JSON.parse(content);
  } catch (_e) {
    return content;
  }
}

// Initialisers

interface InitStatePayload
  extends Pick<TaskBlockFragment, "points" | "shuffle" | "hidden" | "id"> {
  parentQuestionId: string | null;
  question: QuestionFragment;
}

export function initQuestionState(
  payload: InitStatePayload
): QuestionFormState {
  const { question, points, shuffle, hidden } = payload;
  let questionFields: QuestionFormField[] = [];
  const existingFields = question.body?.fields ?? [];

  switch (question.questionType) {
    case QuestionType.Overview:
    case QuestionType.Section:
    case QuestionType.Sub:
      break;

    case QuestionType.Extended:
    case QuestionType.Truefalse:
    case QuestionType.Mcq:
    case QuestionType.Short:
    case QuestionType.Blanks:
      if (existingFields.length > 0) {
        questionFields = existingFields.map((field) =>
          mapExistingFieldToQuestionState(field, question.questionType)
        );
      } else {
        questionFields = defaultQuestionFields(question.questionType);
      }
      break;
  }

  return {
    taskBlockId: payload.id,
    questionId: question.id,
    parentQuestionId: payload.parentQuestionId,
    questionType: question.questionType,
    points: points === 0 ? null : points,
    shuffle,
    hidden,
    feedback: question.body?.feedback ?? null,
    questionFields,
  };
}

function mapExistingFieldToQuestionState(
  field: QuestionFieldFragment,
  questionType: QuestionType
): QuestionFormField {
  const response = defaultResponse();
  const interaction = defaultInteraction();

  switch (questionType) {
    case QuestionType.Extended:
      return {
        identifier: field.identifier,
        response: response,
        interaction: {
          ...interaction,
          wordLimit:
            field.interaction.__typename === "ExtendedInteraction"
              ? field.interaction.wordLimit
              : null,
        },
      };
    case QuestionType.Truefalse:
      return {
        identifier: field.identifier,
        response: {
          ...response,
          correctValues: field.response?.correctValues ?? [],
        },
        interaction: {
          ...interaction,
          choices: trueFalseChoices(),
        },
      };
    case QuestionType.Mcq:
      return {
        identifier: field.identifier,
        response: {
          ...response,
          correctValues: field.response?.correctValues ?? [],
        },
        interaction: {
          ...interaction,
          choices:
            field.interaction.__typename === "ChoiceInteraction"
              ? field.interaction.choices.map(
                  (choice: SimpleChoiceFragment) => ({
                    identifier: choice.identifier,
                    content: choice.content,
                  })
                )
              : [],
        },
      };
    case QuestionType.Short:
    case QuestionType.Blanks:
      return {
        identifier: field.identifier,
        response: {
          ...response,
          matchSimilarity: field.response?.matchSimilarity
            ? field.response.matchSimilarity * 100
            : null,
          caseSensitive: field.response?.caseSensitive ?? false,
          correctValues: field.response?.correctValues ?? [],
        },
        interaction: {
          ...interaction,
          kind: mapFieldInteractionKind(field),
          choices:
            field.interaction.__typename === "ChoiceInteraction"
              ? field.interaction.choices.map((choice) => ({
                  identifier: choice.identifier,
                  content: choice.content,
                }))
              : [],
          expectedLength:
            field.interaction.__typename === "TextEntryInteraction"
              ? field.interaction.expectedLength
              : null,
        },
      };
    default:
      return {
        identifier: field.identifier,
        response: response,
        interaction: interaction,
      };
  }
}

/** Given state and promptState, return an memoised SaveQuestionStateInput */
export const useGetSaveQuestionStateInput = (
  state: State,
  promptState: PromptState
) =>
  useCallback(() => {
    const input: SaveQuestionStateInput = {
      feedback: state.feedback ?? "",
      promptDoc: promptState.promptDoc ?? "",
      questionType: state.questionType,
      shortPrompt: promptState.shortPrompt ?? "",
    };

    let inputFields: SaveQuestionStateInput["fields"] = [];

    switch (state.questionType) {
      case QuestionType.Extended:
        inputFields = state.questionFields.map((field) => {
          return {
            identifier: field.identifier,
            response: field.response,
            extendedInteraction: {
              wordLimit: field.interaction.wordLimit,
            },
          };
        });
        break;

      case QuestionType.Mcq:
      case QuestionType.Truefalse:
        inputFields = state.questionFields.map((field) => {
          return {
            identifier: field.identifier,
            response: field.response,
            choiceInteraction: {
              choices: field.interaction.choices,
              maxChoices: field.response.correctValues.length,
            },
          };
        });
        break;
      case QuestionType.Short:
      case QuestionType.Blanks:
        inputFields = state.questionFields.map((field) => {
          if (field.interaction.kind === InteractionKind.Choice) {
            return {
              identifier: field.identifier,
              response: {
                ...field.response,
                matchSimilarity: null,
                correctValues: field.response.correctValues,
              },
              choiceInteraction: {
                choices: field.interaction.choices,
              },
            };
          } else {
            return {
              identifier: field.identifier,
              response: {
                ...field.response,
                matchSimilarity: field.response.matchSimilarity
                  ? field.response.matchSimilarity / 100
                  : DEFAULT_MATCH_SIMILARITY / 100,
              },
              textEntryInteraction: {
                expectedLength: field.interaction.expectedLength,
              },
            };
          }
        });
    }

    return {
      ...input,
      fields: inputFields,
    };
  }, [
    state.questionType,
    state.feedback,
    promptState.promptDoc,
    promptState.shortPrompt,
    state.questionFields,
  ]);
