import { MarkingGroup } from "../../mark-slice/init-mark-groups";

/** Given user id, return the marking group it belong to */
export const getBelongGroupByUserId = (
  userId: string,
  groups: MarkingGroup[]
) =>
  groups.find((group) =>
    group.members.map((member) => member.id).includes(userId)
  );
