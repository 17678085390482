import { CSSProperties, styled } from "styled-components";

import { colors, Spacer, Text } from "@vericus/cadmus-ui";

import MagnifyingGlass from "@/assets/shared/magnifying-glass.svg";

import { Container, Content } from "./styles";

export interface Props {
  clearFilter?: () => void;
  style?: CSSProperties;
  resultName?: string;
}

export const FilteredNoResultTable = (props: Props) => {
  const resultName = props.resultName || "students";
  return (
    <Container style={props.style}>
      <Content>
        <Img src={MagnifyingGlass} alt={"Magnifying glass"} />
        <Spacer spacing={34} />
        <Text kind={"headingFour"} color={"shade1"}>
          No {resultName} match the current filters
        </Text>
        <Spacer spacing={12} />
        {props.clearFilter && (
          <ClearFiltersCTA
            kind="bodySm"
            onClick={props.clearFilter}
            role="button"
          >
            Clear filters
          </ClearFiltersCTA>
        )}
      </Content>
    </Container>
  );
};

const Img = styled.img`
  display: block;
  width: 70px;
  margin: 0 auto;
  fill: ${() => colors.grey500};
`;

const ClearFiltersCTA = styled(Text)`
  color: ${(p) => p.theme.colors.lilac500};

  &:hover {
    cursor: pointer;
  }
`;
