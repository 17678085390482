import { styled } from "styled-components";

import { colors, Spacer, Text } from "@vericus/cadmus-ui";

import DraftEmpty from "assets/class/drafts-empty.svg";

import { Container, Content } from "./styles";

export const NoDraftSubmissionsTable = () => {
  return (
    <Container>
      <Content>
        <Img src={DraftEmpty} alt={"Magnifying glass"} />
        <Spacer spacing={34} />
        <Text kind={"headingFour"} color={"navy400"}>
          There are no draft submissions yet
        </Text>
        <Spacer spacing={12} />
        <Text kind="bodyMd" color={"navy400"}>
          Once draft submissions start rolling in, you’ll be able to view
          submissions, see their similarity scores, and provide feedback
        </Text>
      </Content>
    </Container>
  );
};

const Img = styled.img`
  display: block;
  width: 70px;
  margin: 0 auto;
  fill: ${() => colors.grey500};
`;
