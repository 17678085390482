import { Text } from "@vericus/cadmus-ui";

import * as styles from "./NavBar.css";
import { DisplayedTabs, TabId } from "./types";

export interface NavBarProps {
  /** Currently selected tab */
  selectedTab?: TabId;
  /** A new tab was selected */
  onNewTabSelected: (tab: TabId) => void;
  /** Tabs to display in Nav bar */
  displayedTabs: DisplayedTabs[];
}

/**
 * Main navigation tab bar rendered in the app.
 */
export function NavBar(props: NavBarProps) {
  const { selectedTab, onNewTabSelected, displayedTabs } = props;

  return (
    <div className={styles.navBarContainer} role="tablist" aria-label="Main">
      {displayedTabs.map((t) => (
        <button
          key={t.id}
          className={styles.tab}
          role="tab"
          aria-selected={t.id === selectedTab}
          onClick={() => onNewTabSelected(t.id)}
          data-component={t.dataComponent}
        >
          <Text
            kind="systemMd"
            color={t.id === selectedTab ? "navy700" : "navy500"}
            style={{ fontSize: 14 }}
          >
            {t.display}
          </Text>
        </button>
      ))}
    </div>
  );
}
