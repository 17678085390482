export enum JitsuEvent {
  EMBEDDED_VIDEO_PLAYED = "Embedded Video Played",
  ENROLLMENT_TAG_ADDED = "Enrollment Tag Added",
  ENROLLMENT_TAG_BULK_ADDED = "Enrollment Tag Bulk Added",
  ENROLLMENT_TAG_BULK_REMOVED = "Enrollment Tag Bulk Removed",
  ENROLLMENT_TAG_REMOVED = "Enrollment Tag Removed",
  ENROLLMENTS_REVEALED = "Enrollments Revealed",
  ENROLLMENTS_STATUS_UPDATED = "Enrollments Status Updated",
  EXAM_TIMING_RESET = "Exam Timing Reset",
  EXAM_TIMING_BULK_RESET = "Exam Timing Bulk Reset",
  EXTENSION_BULK_CLEARED = "Extension Bulk Cleared",
  EXTENSION_BULK_UPDATED = "Extension Bulk Updated",
  EXTENSION_CLEARED = "Extension Cleared",
  EXTENSION_UPDATED = "Extension Updated",
  GROUP_CREATED = "Group Created",
  GROUP_DELETED = "Group Deleted",
  GROUP_UPDATED = "Group Updated",
  IA_ONBOARDING_COMPLETED = "Integrity Assurance Onboarding Completed",
  INSTRUCTIONS_CLEARED = "Instructions Cleared",
  EXTRA_TIME_REMOVED = "Extra Time Removed",
  EXTRA_TIME_BULK_REMOVED = "Extra Time Bulk Removed",
  REPORT_REQUESTED = "Report Requested",
  REQUIREMENTS_UPDATED = "Requirements Updated",
  RESOURCE_ADDED = "Resource Added",
  RESOURCE_DELETED = "Resource Deleted",
  RESOURCE_UPDATED = "Resource Updated",
  ROSTER_SYNCED = "Roster Synced",
  SPECIAL_CONSIDERATIONS_UPDATED = "Special Considerations Updated",
  SPECIAL_CONSIDERATIONS_BULK_UPDATED = "Special Considerations Bulk Updated",
  STUDENT_BULK_CONTACTED = "Student Bulk Contacted",
  STUDENT_CONTACTED = "Student Contacted",
  STUDENT_DEFERRAL_CANCELLED = "Student Deferral Cancelled",
  STUDENT_BULK_DEFERRAL_CANCELLED = "Student Bulk Deferral Cancelled",
  STUDENT_DEFERRED = "Student Deferred",
  STUDENT_BULK_DEFERRED = "Student Bulk Deferred",
  SUBMISSION_BULK_DOWNLOADED = "Submission Bulk Downloaded",
  SUBMISSION_DOWNLOADED = "Submission Downloaded",
  SUBMISSIONS_FORCED = "Submissions Forced",
  TEMPLATE_PREVIEWED = "Template Previewed",
  TEMPLATE_SHEET_USED = "Template Sheet Used",
  TEMPLATE_SHEET_PREVIEWED = "Template Sheet Previewed",
  TEMPLATE_SUBCATEGORY_SELECTED = "Template Subcategory Selected",
  TEMPLATE_USED = "Template Used",
  TEMPLATES_MODAL_OPENED = "Templates Modal Opened",
  CLASS_FILTER_UPDATED = "Class Filter Updated",
  CLASS_FILTER_CLEARED = "Class Filter Cleared",
  CLASS_FILTERS_CLEARED = "Class Filters Cleared",
  CLASS_SEARCH_UPDATED = "Class Search Updated",
  CLASS_SEARCH_CLEARED = "Class Search Cleared",
  CLASS_SORTING_UPDATED = "Class Sorting Updated",
  GRADE_SYNCED = "Grade Synced",
  LINK_CLICKED = "Link Clicked",
  TABLE_GROUP_COLLAPSED = "Table Group Collapsed",
  TABLE_GROUP_EXPANDED = "Table Group Expanded",
  TABLE_GROUP_FILTER_CLEARED = "Table Group Filter Cleared",
  TABLE_GLOBAL_FILTER_CLEARED = "Table Global Filter Cleared",
  IA_TOOLTIP_VIEWED = "IA Tooltip Viewed",
  IA_QUICK_FILTERS_UPDATED = "IA Quick Filters Updated",
  TABLE_SORTED = "Table Sorted",
  TABLE_FILTERED = "Table Filtered",
  TABLE_GLOBALLY_FILTERED = "Table Globally Filtered",
  QUESTION_BANK_FILE_IMPORTED = "Question Bank File Imported",
  QUESTION_BANK_QUESTIONS_ADDED = "Question Bank Questions Added",
  QUESTION_DELETED = "Question Deleted",
  QUESTION_DUPLICATED = "Question Duplicated",
}
