import { AnimatePresence, motion } from "framer-motion";

import { useAppDispatch, useAppSelector } from "@/data/hooks";
import {
  EnrollmentFragment,
  GroupFragment,
  ReleasedRequirementsFragment,
  SubmissionType,
} from "@/generated/graphql";
import { ClassTab } from "@/ui/class/progress/types";
import { useFilteredGroups } from "@/ui/groups/helpers";
import { BulkActionsMenu } from "@/ui/StudentLists/BulkActionsMenu";
import { contactStudents } from "@/ui/StudentLists/contactStudents";
import {
  useAnonymityHandlers,
  useExtensionHandlers,
  useTagHandlers,
} from "@/ui/StudentLists/hooks";
import { resetSelectedRowsIfFilterMatches } from "@/ui/StudentLists/StudentLists/shared";
import { useDownloadSubmissions } from "@/ui/StudentLists/useDownloadSubmissions";
import { useUpdateEnrollmentsStatus } from "@/ui/StudentLists/useEnrollmentMutation";

import { useModerationScoreContext } from "../../context/moderation-score-context";

export function ModerationBulkActionsMenu({
  assessmentId,
  assessmentName,
  sheet,
  enrollments,
  groups,
}: {
  assessmentId: string;
  assessmentName: string;
  sheet?: ReleasedRequirementsFragment;
  enrollments: EnrollmentFragment[];
  groups: GroupFragment[];
}) {
  const dispatch = useAppDispatch();

  const { table, tagConfigs } = useModerationScoreContext();

  const { filteredGroups } = useFilteredGroups({ enrollments, groups });
  const anonymousMarking = sheet?.anonymousMarking ?? false;

  const revealedEnrollmentIds = useAppSelector(
    (state) => state.class.revealedEnrollmentIds
  );

  const onDownloadSubmissions = useDownloadSubmissions();
  const downloadSubmissions = (
    enrollments: EnrollmentFragment[],
    submissionType: SubmissionType
  ) => {
    onDownloadSubmissions(
      {
        enrollments,
        submissionType,
        assessmentId,
        assessmentName,
        anonymousMarking,
        revealedEnrollmentIds: revealedEnrollmentIds,
        taskFormat: sheet?.format ?? null,
      },
      // isBulkOperation
      true
    );
  };

  const anonymityHandlers = useAnonymityHandlers(dispatch);
  const tagHandlers = useTagHandlers(enrollments);
  const extensionHandlers = useExtensionHandlers(
    { id: assessmentId, name: assessmentName },
    sheet ?? null,
    filteredGroups
  );

  const updateEnrollmentStatus = useUpdateEnrollmentsStatus(assessmentId);

  const selectedRows = table.getSelectedRowModel().rows;

  return (
    <AnimatePresence>
      {selectedRows.length !== 0 && (
        <AnimatedBulkActionsMenu
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, type: "spring" }}
          exit={{ opacity: 0, y: 10 }}
          selectedAll={table.getIsAllRowsSelected()}
          sheet={sheet}
          rows={selectedRows.map((row) => row.original!)}
          groups={filteredGroups}
          tagConfigs={tagConfigs}
          tab={ClassTab.Finals} //  what TODO: with the tabs
          onChanges={(column) =>
            resetSelectedRowsIfFilterMatches(table, column)
          }
          anonymityManagement={
            anonymousMarking
              ? {
                  revealEnrollments: anonymityHandlers.revealEnrollments,
                  hideEnrollments: anonymityHandlers.hideEnrollments,
                }
              : undefined
          }
          contactStudents={contactStudents}
          downloadSubmissions={downloadSubmissions}
          updateEnrollmentStatus={updateEnrollmentStatus}
          {...extensionHandlers}
          {...tagHandlers}
        />
      )}
    </AnimatePresence>
  );
}

const AnimatedBulkActionsMenu = motion.create(BulkActionsMenu);
