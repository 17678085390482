import { syncClassList } from "data/class";

import { startAppListening } from "../listenerMiddleware";
import { setItem } from "./helpers";
import {
  dismissedFeedbackReleaseDisclaimer,
  dismissedSyncNudge,
  trackSGIntroModalView,
} from "./localFlagsSlice";

// Listen to flag updates and write the updated state to localStorage

startAppListening({
  actionCreator: trackSGIntroModalView,
  effect: (_action, listenerAPI) => {
    const { sgIntroModalViewCount, prefix } = listenerAPI.getState().localFlags;
    // Set items if a prefix is available to write to
    if (prefix) {
      setItem(
        prefix,
        "sgIntroModalViewCount",
        JSON.stringify(sgIntroModalViewCount)
      );
    }
  },
});

startAppListening({
  actionCreator: dismissedSyncNudge,
  effect: (_action, listenerAPI) => {
    const { dismissedSyncNudge, prefix } = listenerAPI.getState().localFlags;
    // Set items if a prefix is available to write to
    if (prefix) {
      setItem(prefix, "dismissedSyncNudge", JSON.stringify(dismissedSyncNudge));
    }
  },
});

startAppListening({
  actionCreator: syncClassList.fulfilled,
  effect: (_action, listenerAPI) => {
    const { rosterSyncClicked, prefix } = listenerAPI.getState().localFlags;
    // Set items if a prefix is available to write to
    if (prefix) {
      setItem(prefix, "rosterSyncClicked", JSON.stringify(rosterSyncClicked));
    }
  },
});

startAppListening({
  actionCreator: dismissedFeedbackReleaseDisclaimer,
  effect: (_action, listenerAPI) => {
    const { prefix, dismissedFeedbackReleaseDisclaimer } =
      listenerAPI.getState().localFlags;
    // Set items if a prefix is available to write to
    if (prefix) {
      setItem(
        prefix,
        "dismissedFeedbackReleaseDisclaimer",
        JSON.stringify(dismissedFeedbackReleaseDisclaimer)
      );
    }
  },
});
