import { styled } from "styled-components";

import {
  colors,
  desktop,
  Divider,
  Skeleton,
  tablet,
  Text,
} from "@vericus/cadmus-ui";

import { Category } from "@/data/templates";

import { ClickableSubcategory } from "./ClickableSubcategory";
import { SidebarButton } from "./SidebarButton";

export interface SidebarProps {
  categories: Category[];
  onDashboardClick: VoidFunction;
  onNewInstructionsClick: VoidFunction;
  onSubcategoryClick: (subcategoryId: string, subcategoryTitle: string) => void;
}

export const Sidebar = ({
  categories,
  onDashboardClick,
  onNewInstructionsClick,
  onSubcategoryClick,
}: SidebarProps) => {
  return (
    <Container>
      <CategoriesContainer>
        {categories.map((category) => (
          <CategoryComponent {...category} key={category.id}>
            {category.subcategories.map((subcategory) => (
              <ClickableSubcategory
                key={subcategory.id}
                isActive={subcategory.isSelected}
                name={subcategory.title}
                onClick={() =>
                  onSubcategoryClick(subcategory.id, subcategory.title)
                }
              />
            ))}
          </CategoryComponent>
        ))}
      </CategoriesContainer>
      <ActionsContainer>
        <Divider />
        <SidebarButton icon="Plus" onClick={onNewInstructionsClick}>
          New Instructions
        </SidebarButton>
        <Divider />
        <SidebarButton
          icon="Home"
          iconColor="blue500"
          onClick={onDashboardClick}
        >
          Dashboard
        </SidebarButton>
      </ActionsContainer>
    </Container>
  );
};

interface CategoryComponentProps extends Category {
  children?: JSX.Element[];
}

const CategoryComponent = ({ title, children }: CategoryComponentProps) => {
  return (
    <CategoryContainer aria-label={`${title} group`}>
      <ResponsiveText kind="label">{title}</ResponsiveText>
      <SubcategoryContainer>{children}</SubcategoryContainer>
    </CategoryContainer>
  );
};

const ResponsiveText = styled(Text)`
  font-size: 10px;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.grey50};
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  padding-left: 20px;
  ${tablet`
    padding-left: 32px;
  `}
  ${desktop`
    padding-left: 32px;
  `}
  padding-right: 20px;
  padding-top: 48px;
  padding-bottom: 48px;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

const SubcategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategorySkeleton = ({ subcategories }: { subcategories: number }) => {
  const subcategorySkeletons = Array(subcategories).fill("_");
  return (
    <div>
      <Skeleton width={40} />
      {subcategorySkeletons.map((_, index) => (
        <Skeleton width={90} key={index} />
      ))}
    </div>
  );
};

export const SidebarSkeleton = () => {
  return (
    <Container>
      <CategoriesContainer>
        <CategorySkeleton subcategories={7} />
        <CategorySkeleton subcategories={1} />
        <CategorySkeleton subcategories={1} />
        <CategorySkeleton subcategories={1} />
        <CategorySkeleton subcategories={1} />
      </CategoriesContainer>
    </Container>
  );
};
