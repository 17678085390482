import { useNavigate } from "react-router-dom";
import {
  ExposedCustomEvent,
  useCustomEvent,
} from "ui/onboarding/useCustomEvent";

/**
 * A scoped exposed method/communication to Cadmus app to navigate somewhere else.
 * This is useful for third-party customisable scripts.
 * Uses DOM events as the messaging mechanism.
 *
 * Example:
 * window.dispatchEvent(new CustomEvent("navigate-to", {
 *   detail: {
 *     route: "classlist"
 *   }
 * }));
 */
export const useExposedNavigator = () => {
  const navigate = useNavigate();

  const goTask = () => {
    navigate("task");
  };
  const goClass = () => {
    navigate("class");
  };
  const goInsights = () => {
    navigate("insights");
  };

  useCustomEvent(true, ExposedCustomEvent.NAVIGATE_TO, (params) => {
    if (!params.detail.route) {
      console.warn("No route specified!");
      return;
    }

    switch (params.detail.route) {
      case "insights":
        goInsights();
        break;
      case "classlist":
        goClass();
        break;
      case "task":
        goTask();
        break;
      default:
        console.warn("Invalid route!");
    }
  });
};
