import { format as formatWithTimezone } from "date-fns-tz";

const CONDENSED_DATE_FORMAT = "d MMM yyyy";

/**
 * Format the date in its condensed form.
 *
 * ✅ This is part of the Cadmus standardised date/duration formatting.
 *
 * {@link https://docs.google.com/spreadsheets/d/1XO8M5bPTAd-uVWCaygIGt9ch1BDOtZCV2zHZxPNtmbg/edit#gid=21065173 Style guide}
 * @example formatCondensedDate(new Date("2023-01-22T13:59:00.000Z"))
 * // returns "22 Jan 2023"
 */
export const formatCondensedDate = (date: Date): string => {
  return `${formatWithTimezone(date, CONDENSED_DATE_FORMAT)}`;
};
