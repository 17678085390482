import { styled } from "styled-components";

import { Button, colors, desktop, tablet, Text } from "@vericus/cadmus-ui";

import { BaseTemplateCardProps } from "./shared";

export interface WideCardProps extends BaseTemplateCardProps {}

export const WideCard = ({
  id,
  color,
  title,
  iconSource,
  iconAlt,
  onPreviewTemplate,
  onSelectTemplate,
}: WideCardProps) => {
  return (
    <Container background={color}>
      <Text kind="headingFour">{title}</Text>
      <Img src={iconSource} alt={iconAlt} />

      <Actions background={color}>
        <PreviewButton
          kind="outline"
          tabIndex={-1}
          onClick={() => onPreviewTemplate(id)}
        >
          Preview
        </PreviewButton>
        <UseButton kind="secondary" onClick={() => onSelectTemplate(id)}>
          Use
        </UseButton>
      </Actions>
    </Container>
  );
};

const PreviewButton = styled(Button)`
  border-color: ${colors.black200};
  color: ${colors.black200};
  &:not(:disabled):after {
    box-shadow: none;
  }
`;

const UseButton = styled(Button)`
  &:not(:disabled):hover:after {
    opacity: 0.5;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 138px;
  object-fit: cover;
  object-position: center top;
`;

const Actions = styled.div<{ background?: string }>`
  flex: none;
  box-sizing: border-box;

  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;

  background: linear-gradient(
    180deg,
    rgba(245, 245, 250, 0) 0%,
    ${(p) => p.background || colors.yellow100} 18.51%
  );

  padding: 24px 20px 16px 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;

  opacity: 0;
  transform: translateY(50%);
  transition: opacity 0.3s ease, transform 0.3s ease;

  button {
    flex-basis: 30%;
    flex-grow: 1;
    flex-shrink: 1;
  }
`;

const Container = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 205px;
  width: 300px;
  ${tablet`
    width: 328px;
  `}
  ${desktop`
    width: 328px;
  `}
  height: 100%;
  position: relative;
  font-weight: 600;

  overflow: hidden;

  padding: 20px 20px 0px 20px;
  box-sizing: border-box;

  background: ${(p) => p.background || colors.yellow100};
  outline: none;

  cursor: pointer;

  &:hover ${Actions}, &:focus ${Actions} {
    opacity: 1;
    transform: translateY(0%);
  }
`;
