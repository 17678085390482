import { Row } from "@tanstack/react-table";

export const oneOf = <T, S>(
  row: Row<T>,
  columnId: string,
  filterValue: S[]
) => {
  const value = row.getValue<S>(columnId);
  if (filterValue.length === 0) return true;
  return filterValue.includes(value);
};
