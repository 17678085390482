import { useCallback, useMemo } from "react";

import { Reference } from "@apollo/client";
import { Skeleton } from "@mantine/core";

import {
  AccessCodeFragmentDoc,
  AccessCodeInput,
  useAssessmentAccessCodesQuery,
  useSetupAccessCodeMutation,
} from "@/generated/graphql";

import { AccessCodeList } from "./AccessCodeList";

export interface AccessCodeListWithDataProps {
  /** Assessment ID */
  assessmentId: string;
  /** Prevent deletion and generation of Access Code */
  disabled?: boolean;
}

/**
 * Table to display and manage access codes.
 */
export function AccessCodeListWithData(props: AccessCodeListWithDataProps) {
  const { assessmentId, disabled = false } = props;
  const [mutateAccessCode] = useSetupAccessCodeMutation({
    update: (cache, { data }) => {
      if (!data?.setupAccessCode) return;
      cache.modify({
        fields: {
          assessmentAccessCodes(existingAccessCodes = []) {
            const newAccessCodeRef = cache.writeFragment({
              fragment: AccessCodeFragmentDoc,
              data: data.setupAccessCode,
            });
            if (
              !existingAccessCodes.some(
                (codeRef: Reference) =>
                  codeRef.__ref === newAccessCodeRef?.__ref
              )
            ) {
              return [...existingAccessCodes, newAccessCodeRef];
            }
            return existingAccessCodes;
          },
        },
      });
    },
  });

  const updateAccessCodeMutation = useCallback(
    (accessCode: AccessCodeInput) => {
      if (!accessCode || !accessCode.label) return;
      mutateAccessCode({
        variables: {
          assessmentId: assessmentId,
          input: accessCode,
        },
        optimisticResponse: {
          setupAccessCode: {
            __typename: "AccessCode",
            code: accessCode.code,
            label: accessCode.label,
            active: accessCode.active,
          },
        },
      });
    },
    [mutateAccessCode, assessmentId]
  );

  const { loading, data } = useAssessmentAccessCodesQuery({
    variables: {
      assessmentId: assessmentId,
    },
    onCompleted: (data) => {
      if (!data.assessmentAccessCodes) return;
      if (data.assessmentAccessCodes.length === 0) generateNewAccessCode();
    },
  });

  const accessCodes = useMemo(() => {
    return data?.assessmentAccessCodes?.filter((code) => code.active) ?? [];
  }, [data]);

  const generateNewAccessCode = useCallback(() => {
    if (generateCode) {
      const newAccessCode = {
        code: generateCode(),
        label: `Exam Code ${(accessCodes?.length || 0) + 1}`,
        active: true,
      };
      // eslint-disable-next-line react-hooks/rules-of-hooks
      updateAccessCodeMutation(newAccessCode);
    }
  }, [accessCodes, updateAccessCodeMutation]);

  const updateAccessCode = (code: string, label: string, active: boolean) => {
    updateAccessCodeMutation({ code: code, label: label, active: active });
  };
  if (loading) return <Skeleton height={150} />;

  return (
    <AccessCodeList
      accessCodes={accessCodes}
      updateAccessCode={updateAccessCode}
      generateNewAccessCode={generateNewAccessCode}
      disabled={disabled}
    />
  );
}

/**
 * Generate a random access code
 */
function generateCode() {
  const now = Date.now();
  const randomComponent = Math.random().toString(36).slice(2, 5).toUpperCase(); // Short random part
  const encodedTimestamp = now.toString(36);
  const sixCharCode = encodedTimestamp.slice(-3).padStart(3, "0").toUpperCase();

  return `${sixCharCode}${randomComponent}`;
}
