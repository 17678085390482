import { v4 as uuid } from "uuid";

import { QuestionType, TaskBlockFragment } from "@/generated/graphql";

import {
  TaskBlockNode,
  TaskLayoutBlock,
  TaskLayoutState,
  UpdatedBlock,
} from "./types";

const ids = new Set();

// generator for unique temporary id for task layout
function* tempId(): Generator<string> {
  while (true) {
    let id = `temp-${uuid()}`;
    while (ids.has(id)) {
      id = `temp-${uuid()}`;
    }
    ids.add(id);
    yield id;
  }
}

const tempIdGenerator = tempId();

/**
 * get next unique temp id
 */
export const getTempId = (): string => tempIdGenerator.next().value;
/**
 * Remove temp ids from the set so the value can be reused
 * for performance reason
 */
export const removeTempId = (id: string) => ids.delete(id);

/**
 * Sort task blocks and populate question numbering(label) for TaskLayout slice
 * This is called on initial load
 */
export function getTaskLayout(
  taskBlocks: TaskBlockFragment[]
): Pick<TaskLayoutState, "nodes" | "sortedTaskBlocks"> {
  const sortedTaskBlocks = sortTaskBlocks(taskBlocks);
  const nodes = {} as Record<string, TaskBlockNode>;
  const sortedBlocks = sortedTaskBlocks.map((taskBlock) => {
    const { question, ...rest } = taskBlock;
    const questionBlock: TaskLayoutBlock = {
      id: rest.id,
      nodeId: rest.nodeId,
      previousNodeId: rest.previousNodeId,
      parentNodeId: rest.parentNodeId,
      hidden: rest.hidden,
      deleted: rest.deleted,
      quantity: rest.quantity,
      questionId: question?.id,
      questionType: question?.questionType,
      points: rest.points,
      shuffle: rest.shuffle,
    };
    const order = getTaskSiblingOrder(sortedTaskBlocks, questionBlock);
    if (order !== null) {
      nodes[taskBlock.nodeId] = {
        label: getQuestionLabel(nodes, questionBlock, order),
        parentNodeId: taskBlock.parentNodeId,
      };
    }
    return questionBlock;
  });
  return { sortedTaskBlocks: sortedBlocks, nodes: nodes };
}

/**
 * Create question label based on the question type, its parent's label and
 * their order(position) under the parent related to its siblings.
 */
export function getQuestionLabel(
  questionsMeta: Record<string, TaskBlockNode>,
  questionBlock: TaskLayoutBlock,
  order: number
): string {
  const quantity = questionBlock?.quantity ?? 1;
  if (questionBlock.questionType === QuestionType.Section) {
    return `PART ${order}`;
  } else if (questionBlock.questionType === QuestionType.Overview) {
    return "Assessment overview";
  } else if (questionBlock.parentNodeId === null) {
    if (quantity === 1) {
      return `Question ${order}`;
    } else {
      return `Question ${order}-${order + quantity - 1}`;
    }
  } else {
    return `${questionsMeta[questionBlock.parentNodeId]?.label}.${order}`;
  }
}

/**
 * get the position of the task related to the siblings
 */
export function getTaskSiblingOrder(
  questionLayoutBlocks: Array<TaskLayoutBlock>,
  taskBlock: TaskLayoutBlock
) {
  const siblings = getSiblings(questionLayoutBlocks, taskBlock);
  if (siblings.length === 0) return 1;
  let index = siblings.findIndex((t) => t.id === taskBlock.id);
  if (index < 0) index = siblings.length;
  if (index === 0) return 1;
  let previousCount = 1;
  for (let i = index - 1; i >= 0; i--) {
    previousCount += siblings[i]?.quantity ?? 1;
  }
  return previousCount;
}

/**
 * get a list of siblings task (has same parent)
 */
export function getSiblings(
  questionLayoutBlocks: Array<TaskLayoutBlock | TaskBlockFragment>,
  taskBlock: TaskLayoutBlock
) {
  return questionLayoutBlocks.filter((t) => {
    const questionType =
      (t as TaskLayoutBlock).questionType ??
      (t as TaskBlockFragment).question?.questionType;
    const sameParent =
      t.parentNodeId === taskBlock.parentNodeId &&
      questionType !== QuestionType.Overview &&
      !t.hidden &&
      !t.deleted;
    if (taskBlock.questionType !== QuestionType.Section) {
      return sameParent && questionType !== QuestionType.Section;
    }
    return sameParent && questionType === QuestionType.Section;
  });
}

/**
 * Sort task blocks Array based on their previousId and parentNodeId
 *
 * Note:
 * - Currently the sorting is done using insertion sort, it might not
 *   be the fastest but given that the amount of blocks is not expected to
 *   be to big, this will do
 * - This doesn't account for invalid list of blocks which might cause
 *   some blocks get discarded
 */
export function sortTaskBlocks<T extends TaskBlockFragment & TaskLayoutBlock>(
  taskBlocks: T[]
) {
  const orderedBlocks: T[] = [];

  // Filter out deleted blocks
  const unOrderedBlocks = taskBlocks.filter((t) => !t.deleted);

  // find and insert overview block if exist
  const overviewIndex = unOrderedBlocks.findIndex((blk) => {
    const questionType = blk.questionType ?? blk.question?.questionType;
    return (
      blk.previousNodeId === null &&
      blk.parentNodeId === null &&
      questionType === QuestionType.Overview
    );
  });
  if (overviewIndex !== -1) {
    orderedBlocks.push(unOrderedBlocks[overviewIndex]!);
    unOrderedBlocks.splice(overviewIndex, 1);
  }

  // Find the head of the task tree
  let blockIndex = unOrderedBlocks.findIndex(
    (blk) => blk.previousNodeId === null && blk.parentNodeId === null
  );
  while (blockIndex > -1 && unOrderedBlocks.length > 0) {
    const taskBlock = unOrderedBlocks[blockIndex]!;
    // removed block from unordered and insert it to the ordered list
    orderedBlocks.push(taskBlock);
    unOrderedBlocks.splice(blockIndex, 1);
    // find index of first child task
    blockIndex = unOrderedBlocks.findIndex(
      (t) => t.parentNodeId === taskBlock.nodeId && t.previousNodeId === null
    );
    // find index of next sibling task
    if (blockIndex === -1) {
      blockIndex = unOrderedBlocks.findIndex(
        (blk) => blk.previousNodeId === taskBlock.nodeId
      );
    }
    // find index of next task of the parent
    if (blockIndex === -1) {
      blockIndex = unOrderedBlocks.findIndex(
        (blk) => blk.previousNodeId === taskBlock.parentNodeId
      );
    }
  }
  return orderedBlocks;
}

/**
 * Get task layout blocks which previousId and/or parentNodeId need to be updated
 * given a task layout block update
 */
export function getUpdatedBlocks(
  taskBlocks: Array<TaskLayoutBlock>,
  updatedBlock: UpdatedBlock
) {
  const { id, nodeId, previousNodeId, deleted, parentNodeId } = updatedBlock;

  const taskBlock = taskBlocks.find((blk) => blk.id === id);
  if (!taskBlock) return [];

  // Update the actual updated block
  const updatedBlocks = [updatedBlock];

  // Get block which previousId pointing to the moved block and update the previousId to
  // the moved block's previousId
  const currentNext = taskBlocks.find((blk) => blk.previousNodeId === nodeId);
  if (currentNext) {
    updatedBlocks.push({
      id: currentNext.id,
      nodeId: currentNext.nodeId,
      previousNodeId: taskBlock.previousNodeId,
      parentNodeId: taskBlock.parentNodeId,
    });
  }
  if (!deleted) {
    // Get block which previousId points to the updated block new previousId and update
    // the block's previousId to point to the updated block
    const newNext = taskBlocks.find(
      (t) =>
        t.previousNodeId === previousNodeId &&
        t.questionType !== QuestionType.Overview &&
        t.parentNodeId === parentNodeId
    );
    if (newNext) {
      updatedBlocks.push({
        id: newNext.id,
        nodeId: newNext.nodeId,
        previousNodeId: taskBlock.nodeId,
        parentNodeId: newNext.parentNodeId,
      });
    }
  }
  return updatedBlocks;
}

/**
 * Get all descendant blocks by traversing through the list until
 * it cannot find any further children.
 */
export function getDescendantBlocks(
  taskBlocks: TaskLayoutBlock[],
  nodeId: string
) {
  const block = taskBlocks.find((blk) => blk.nodeId === nodeId);
  const blocksWithParent = taskBlocks.filter(
    (blk) => blk.parentNodeId !== null
  );

  if (!block || blocksWithParent.length === 0) {
    return [];
  }

  const nodeIds = new Set([block.nodeId]);
  let foundNewDescendants = false;
  // Traversing to find children until it cannot find any further childrens
  do {
    const newDescendants = [];
    blocksWithParent
      .filter((blk) => blk.parentNodeId && nodeIds.has(blk.parentNodeId))
      .forEach((blk) => {
        if (!nodeIds.has(blk.nodeId)) {
          nodeIds.add(blk.nodeId);
          newDescendants.push(blk.nodeId);
        }
      });
    foundNewDescendants = newDescendants.length !== 0;
  } while (foundNewDescendants);

  return blocksWithParent.filter(
    (blk) => blk.nodeId !== block.nodeId && nodeIds.has(blk.nodeId)
  );
}

/**
 * Caclculate the total points for a Section in a sorted task block tree.
 */
export function getSectionPoints(
  taskBlocks: TaskLayoutBlock[],
  sectionNodeId: string
): number {
  // Index of the section block
  const sectionBlockIndex = taskBlocks.findIndex(
    (sortedTaskBlock) => sortedTaskBlock.nodeId === sectionNodeId
  );

  if (sectionBlockIndex === -1) return 0;

  // Flag until next section
  let reachedNextSection = false;
  // All the nodes that have more children to count.
  const parentNodeIds: string[] = [];

  // Accumulate points of the immediate siblings of the section block
  const toplevelPoints = taskBlocks
    // Not include section itself
    .slice(sectionBlockIndex + 1)
    .reduce((totalPoints, { nodeId, parentNodeId, points, questionType }) => {
      if (reachedNextSection || parentNodeId !== null) {
        return totalPoints;
      } else if (questionType === QuestionType.Section) {
        reachedNextSection = true;
        return totalPoints;
      } else if (questionType === QuestionType.Sub) {
        parentNodeIds.push(nodeId);
        // Sub question block itself shouldn't has any points
        return totalPoints;
      }
      return totalPoints + (points ?? 0);
    }, 0);

  // Accumulate points of the childrens
  const childrenPoints = parentNodeIds
    .flatMap((parentNodeId) =>
      taskBlocks.filter(
        (sortedTaskBlock) => sortedTaskBlock.parentNodeId === parentNodeId
      )
    )
    .reduce(
      (totalPoints, childBlock) => totalPoints + (childBlock.points ?? 0),
      0
    );

  return toplevelPoints + childrenPoints;
}

/**
 * Calculate the total points for a Sub question in a sorted task block tree.
 */
export function getSubQuestionPoints(
  taskBlocks: TaskLayoutBlock[],
  subNodeId: string
): number {
  const subBlock = taskBlocks.find((block) => block.nodeId === subNodeId);
  if (!subBlock) return 0;
  return taskBlocks
    .filter((block) => block.parentNodeId === subNodeId)
    .reduce((totalPoints, block) => totalPoints + (block.points ?? 0), 0);
}

/**
 * Map over a sorted list of Task Blocks in the given layout and fill the
 * `points` for each block.
 *
 * Certain blocks don't have their own points and it needs to be derived from
 * siblings and children. This mapping will fill in the those values too.
 */
export function normalisedTaskBlockPoints(
  taskBlocks: TaskLayoutBlock[]
): TaskLayoutBlock[] {
  return taskBlocks.map((block) => {
    if (block.questionType === QuestionType.Section) {
      const points = getSectionPoints(taskBlocks, block.nodeId);
      return { ...block, points };
    } else if (block.questionType === QuestionType.Sub) {
      const points = getSubQuestionPoints(taskBlocks, block.nodeId);
      return { ...block, points };
    }
    return block;
  });
}
