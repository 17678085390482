import { HTMLAttributes, ReactNode } from "react";
import { styled } from "styled-components";

import { ErrorIcon } from "@vericus/cadmus-icons";
import { Divider, Text } from "@vericus/cadmus-ui";

interface EditorWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  hasError?: boolean;
  isOverview?: boolean;
}

/**
 * Cancel the before and after placeholder block of cadmus editor,
 * to fit in question block and provide an error message
 */
export const EditorWrapper = ({
  children,
  hasError,
  isOverview,
  ...rest
}: EditorWrapperProps) => (
  <Root {...rest}>
    {children}
    {hasError && (
      <ErrorSecton>
        <StyledDivider />
        <ErrorPrompt>
          <StyledErrorIcon label="Error:" size={16} />
          <Text kind="bodySm" color="functionalFail">
            {isOverview
              ? "If enabled, the assessment overview cannot be left blank. Please add content or disable this option."
              : "Question must be at least 20 characters long."}
          </Text>
        </ErrorPrompt>
      </ErrorSecton>
    )}
  </Root>
);
export const Root = styled.div`
  width: 100%;
  box-sizing: border-box;
  > * {
    &::before {
      display: none;
      position: absolute;
      z-index: -1;
    }

    &::after {
      display: none;
      position: absolute;
      z-index: -1;
    }
  }
`;
export const StyledErrorIcon = styled(ErrorIcon)`
  padding-top: 3px;
`;

export const ErrorSecton = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 45px;
  row-gap: 8px;
  margin: 16px 0px;
`;

export const StyledDivider = styled(Divider)`
  background-color: ${(p) => p.theme.colors.functionalFail};
`;

export const ErrorPrompt = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
`;
