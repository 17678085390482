import { useRef } from "react";

import { Button, DropdownMenu } from "@vericus/cadmus-ui";

import { useUserpilotConfiguration } from "@/data/onboarding/useUserpilotConfiguration";
import { useUserpilotFlowListener } from "@/data/onboarding/useUserpilotFlowListener";
import { DataComponent } from "@/ui/shared/DataComponent";

interface Props extends DataComponent {
  /**
   * Syncing in progress state.
   */
  syncing: boolean;
  /**
   * Trigger a class list sync.
   */
  syncClassList: () => void;
  /**
   * Trigger a LMS Grade sync if available.
   */
  syncGrades?: () => void;
}

/**
 * Call to Action button to trigger sync operations with the LMS.
 *
 * Renders a Button with an optional dropdown if grade sync operation is
 * possible.
 */
export const LMSSyncCTA = (props: Props) => {
  const { syncing, syncClassList: syncRoster, syncGrades } = props;
  const dropdownMenuRef = useRef<HTMLButtonElement>(null);
  const userpilotConfiguration = useUserpilotConfiguration();
  useUserpilotFlowListener(true, userpilotConfiguration.classListOnboarding, {
    started: () => {
      dropdownMenuRef.current?.click();
    },
  });

  if (syncGrades) {
    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <Button
            ref={dropdownMenuRef}
            kind="secondary"
            disabled={syncing}
            iconName="Reset"
          >
            Sync LMS
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          style={{ zIndex: 10 }}
          align="end"
          avoidCollisions
        >
          <DropdownMenu.Item
            onSelect={syncRoster}
            iconName="Reset"
            content="Sync class list"
            data-testid={"SyncClassList"}
            data-component={`${props["data-component"]}`}
          />
          <DropdownMenu.Item
            onSelect={syncGrades}
            iconName="Reset"
            content="Sync grades"
          />
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    );
  }

  return (
    <Button
      kind="secondary"
      disabled={syncing}
      onClick={syncRoster}
      iconName="Reset"
      data-testid={"SyncClassList"}
      data-component={props["data-component"]}
    >
      Sync with LMS
    </Button>
  );
};
