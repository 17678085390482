import { useCurrentUserQuery } from "generated/graphql";

/**
 * Get the current user + institution.
 *
 * @returns a user object. Returns null if it's not ready or errored out.
 */
export const useCurrentUser = () => {
  const { data, loading, error } = useCurrentUserQuery();

  if (loading || error) {
    return null;
  }

  return data?.me && data?.institution ? data : null;
};
