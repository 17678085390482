import { forwardRef } from "react";
import { styled } from "styled-components";

import { AccessibleIcon, Icon } from "@vericus/cadmus-ui";

export interface HelpIconProps {
  /** Optionally use the primary variant */
  primary?: boolean;
}

export const HelpIcon = forwardRef<HTMLDivElement, HelpIconProps>(
  (props, ref) => {
    return (
      <Root ref={ref} {...props}>
        <AccessibleIcon label="Help information">
          <Icon iconName="Question" />
        </AccessibleIcon>
      </Root>
    );
  }
);

const Root = styled.div<HelpIconProps>`
  position: relative;
  top: 2px;
  display: inline-flex;
  border-radius: 100%;
  cursor: pointer;
  width: 16px;
  height: 16px;
  fill: white;
  background: ${(p) =>
    p.primary ? p.theme.colors.blue500 : p.theme.colors.grey500};
  box-shadow: 0px 1px 1px 0px rgba(71, 92, 133, 0.15),
    0px 0px 1px 0px rgba(71, 92, 133, 0.36);
`;
