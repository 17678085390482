import { styled } from "styled-components";

import { colors, LinkButton } from "@vericus/cadmus-ui";

import { ResultFragment } from "generated/graphql";
import { DataComponent } from "ui/shared/DataComponent";
import { HeraLaunch, useHeraLaunch } from "utils/useHeraLaunch";

import { TiiLabel } from "@/ui/class/progress/types";

export interface SimilarityScoreLinkProps extends DataComponent {
  /** Gradebook result for the submission */
  result: ResultFragment;
  /** Grade link is for a draft submission */
  isDraft: boolean;
}

/**
 * Display the similarity score
 */
export function SimilarityScoreLink(props: SimilarityScoreLinkProps) {
  const { result, isDraft } = props;

  // Create redirect link to send the user to the right integration
  const onHeraLaunch = useHeraLaunch({
    launch: HeraLaunch.Result,
    resultId: result.id,
    isDraft,
  });

  const similarity = result?.similarity;

  // The Launch would not work if the Hera Result ID has not been received by
  // Pantheon yet.
  const launchDisabled = result.heraResultId === null;
  const linkTitle = launchDisabled
    ? "Submission is being processed by Turnitin"
    : "View Turnitin Similarity Report";

  if (similarity === null) {
    return (
      <LinkButton
        data-component={props["data-component"]}
        data-testid={"SimilarityScoreLink"}
        iconName="Turnitin"
        onClick={onHeraLaunch}
        disabled={launchDisabled}
        title={linkTitle}
      >
        View
      </LinkButton>
    );
  }

  return (
    <>
      <TiiFlag label={similarity.label as TiiLabel} />
      <LinkButton
        data-component={props["data-component"]}
        data-testid={"SimilarityScoreLink"}
        onClick={onHeraLaunch}
        disabled={launchDisabled}
        title={linkTitle}
      >{`${similarity.display}%`}</LinkButton>
    </>
  );
}

/**
 * Rendering the Turnitin similarity label as a Flag.
 */
const TiiFlag = ({ label }: { label: TiiLabel }) => {
  switch (label) {
    case TiiLabel.Red:
      return <Flag color={colors.redA500} />;
    case TiiLabel.Orange:
      return <Flag color={colors.orangeA500} />;
    case TiiLabel.Yellow:
      return <Flag color={colors.yellow500} />;
    default:
      return <Flag color="transparent" />;
  }
};

export const Flag = styled.span<{ color?: string }>`
  display: inline-block;
  margin-top: 2px;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 9px;
  background: ${(p) => (p.color ? p.color : colors.grey200)};
`;
