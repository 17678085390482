import { ReactNode } from "react";
import { styled } from "styled-components";

import { ErrorMessage, FilterPill, Spacer, Text } from "@vericus/cadmus-ui";

import { SettingInfoCard } from "../SettingInfoCard";
import { SettingContainer } from "../styles";
import { useSettingsFormContext } from "../use-settings-form";

export interface ExamTypeChoice {
  displayText: string;
  value: string;
}
export const examTypeChoices: Readonly<ExamTypeChoice[]> = [
  { displayText: "Practice exam", value: "practice" },
  { displayText: "Mid-semester", value: "mid-semester" },
  { displayText: "Final exam", value: "final" },
  { displayText: "Supplementary exam", value: "supplementary" },
  { displayText: "Replacement exam", value: "replacement" },
];

/**
 * Allow users to multiselect exam types for more accurate analytics.
 */
export const ConnectedExamTypeSetting = () => {
  const settingsForm = useSettingsFormContext();
  const { values: settings, errors } = settingsForm;
  const { examType: selectedExamTypes } = settings;

  return (
    <ExamTypeSetting
      errorMessage={errors.examType}
      selectedExamTypes={selectedExamTypes}
      onNewSelectedExamTypes={(examType) =>
        settingsForm.setFieldValue("examType", examType)
      }
    />
  );
};

interface ExamTypeSettingProps {
  errorMessage?: ReactNode;
  selectedExamTypes: string[];
  onNewSelectedExamTypes: (newExamTypes: string[]) => void;
}

export const ExamTypeSetting = ({
  errorMessage,
  selectedExamTypes,
  onNewSelectedExamTypes,
}: ExamTypeSettingProps) => {
  return (
    <SettingContainer>
      <Text kind="headingOne">Exam type</Text>
      <SettingInfoCard backgroundColor="blue" showNewPill={false}>
        Get more accurate reporting analytics by identifying the exam type.
        Assessment settings will not be impacted.
      </SettingInfoCard>
      <Spacer spacing={16} />
      <Text kind="bodySm" style={{ color: "#5B6F98" }}>
        Select all that apply.
      </Text>
      <Spacer spacing={16} />
      <ChoicesContainer>
        {examTypeChoices.map((examType) => {
          const isChoiceSelected = selectedExamTypes.includes(examType.value);
          return (
            <FilterPill
              key={examType.value}
              isActive={isChoiceSelected}
              text={examType.displayText}
              onClick={() =>
                onNewSelectedExamTypes(
                  toggleExamType(selectedExamTypes, examType.value)
                )
              }
            />
          );
        })}
      </ChoicesContainer>
      {errorMessage && (
        <>
          <Spacer spacing={16} />
          <ErrorMessage fullWidth position="relative">
            {errorMessage}
          </ErrorMessage>
          <Spacer spacing={16} />
        </>
      )}
    </SettingContainer>
  );
};

/**
 * @returns a callback that can return a new list of selected exam types
 */
const toggleExamType = (
  selectedExamTypes: string[],
  examTypeBeingToggled: string
): string[] => {
  // if not there, add it
  if (!selectedExamTypes.includes(examTypeBeingToggled)) {
    return [...selectedExamTypes, examTypeBeingToggled];
  }
  if (selectedExamTypes.length > 1) {
    return selectedExamTypes.filter(
      (examType) => examType !== examTypeBeingToggled
    );
  }
  return selectedExamTypes;
};

const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
`;
