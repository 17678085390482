import { Checkbox, Pill, Text } from "@vericus/cadmus-ui";

import { AccessorFn, createColumnHelper, Table } from "@tanstack/react-table";
import { ResultFragment, SubmissionType, TaskFormat } from "generated/graphql";
import {
  COMPACT_DATE_FORMAT,
  formatDate,
  hrAndMinDuration,
} from "utils/datetime";

import { SubmissionDetails } from "@/graphql/types/SubmissionDetails";
import { GradeDataCell } from "@/ui/app/Table/DataCells/GradeDataCell";
import { MarkingGroupDataCell } from "@/ui/app/Table/DataCells/MarkingGroupDataCell";
import { ProgressPill } from "@/ui/class/progress/components/ProgressPill";
import {
  ClassTab,
  ProgressStage,
  StudentListRow,
} from "@/ui/class/progress/types";
import { SpecialConsiderationPill } from "@/ui/exam-special-considerations/SpecialConsiderationPill";

import { DeferredMessage } from "./DeferredMessage";
import { DownloadLink } from "./DownloadLink";
import { ExtensionPill } from "./ExtensionPill";
import { applyRangeFilters, compareBasic, predicateFilter } from "./filters";
import { ForceSubmittedPill } from "./ForceSubmittedPill";
import { GradingLink } from "./GradingLink";
import * as Grid from "./InfoGrid";
import { AdjustedPointsCell, ModerationGradeCell } from "./moderation-cells";
import { OptionsCol } from "./OptionsCol";
import { SimilarityScoreLink } from "./SimilarityScoreLink";
import { SortHeaderButton } from "./SortHeaderButton";
import { SubmissionStatusCell } from "./SubmissionStatusCell";
import { WithdrawnMessage } from "./WithdrawnMessage";

// Useful types
export type ClassTable = Table<StudentListRow>;

// Data column creator
const columnHelper = createColumnHelper<StudentListRow>();

export enum ColumnId {
  Select = "select",
  Index = "index",
  Name = "name",
  Email = "email",
  SisUserId = "sisUserId",
  Withdrawn = "withdrawn",
  Flags = "flags",
  Progress = "progress",
  accessCodeLabel = "accessCodeLabel",
  LastActivity = "lastActivity",
  Group = "group",
  Duration = "duration",
  Similarity = "similarity",
  Status = "status",
  Feedback = "feedback",
  Grade = "grade",
  Tags = "tags",
  Options = "options",
  Deferred = "deferred",
  Download = "download",
  LateBy = "lateBy",
  AdjustedScore = "adjustedScore",
  FeedbackReleaseOn = "feedbackReleaseOn",
}

// Selection checkbox display column
const selectColumnDef = columnHelper.display({
  id: ColumnId.Select,
  header: ({ table }) => {
    const allSelected = table.getIsAllRowsSelected();
    const someSelected = table.getIsSomePageRowsSelected();

    return (
      <Checkbox
        checked={someSelected || allSelected}
        mixed={someSelected}
        onChange={table.getToggleAllRowsSelectedHandler()}
        aria-label="Select all"
      />
    );
  },
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      onChange={row.getToggleSelectedHandler()}
      aria-label="Select this row"
    />
  ),
});

// Row index column which uses a CSS counter to render a row number.
const indexColumnDef = columnHelper.display({
  id: ColumnId.Index,
  header: () => (
    <Grid.IndexCol>
      <Text kind="label">#</Text>
    </Grid.IndexCol>
  ),
  cell: () => <Grid.IndexCol counter />,
});

// Student full display name column which can be globally filtered.
const nameColumnDef = columnHelper.accessor("displayName", {
  id: ColumnId.Name,
  header: ({ column }) => (
    <Grid.NameCol>
      <SortHeaderButton column={column}>Name</SortHeaderButton>
    </Grid.NameCol>
  ),
  cell: ({ getValue, row }) => {
    const { displayFirstName, settings, tab, final } = row.original!;

    return (
      <Grid.NameCol>
        <Text
          kind="bodyMd"
          color={row.original?.withdrawn ? "shade1" : undefined}
        >
          {getValue()}
        </Text>

        {!settings.isExam && tab === ClassTab.Students && (
          <ExtensionPill
            userName={displayFirstName}
            settings={settings}
            style={{ marginLeft: 8 }}
          />
        )}

        {settings.hasSpecialConsideration && tab === ClassTab.Students && (
          <SpecialConsiderationPill
            enrollment={row.original.enrollment}
            examSettings={settings}
            anonymised={row.original.anonymous}
          />
        )}
        {final?.forceSubmitted &&
          final?.submittedBy &&
          tab === ClassTab.Students && (
            <ForceSubmittedPill submittedBy={final.submittedBy} />
          )}
      </Grid.NameCol>
    );
  },
  sortingFn: (rowA, rowB) => {
    // Use row number to sort if any row is anonymous
    if (rowA.original!.anonymous || rowB.original!.anonymous) {
      return compareBasic(
        rowA.original!.anonymousIndex,
        rowB.original!.anonymousIndex
      );
    }
    const lastNameA = rowA.original!.lastName;
    const lastNameB = rowB.original!.lastName;

    return compareBasic(lastNameA, lastNameB);
  },
  enableGlobalFilter: true,
});

// Student email column which can be globally filtered.
const emailColumnDef = columnHelper.accessor("displayEmail", {
  id: ColumnId.Email,
  header: () => null,
  cell: () => null,
  enableSorting: false,
  enableGlobalFilter: true,
});

// Student SIS user id column which can be globally filtered.
const sisUserIdColumnDef = columnHelper.accessor("displaySisId", {
  id: ColumnId.SisUserId,
  header: () => null,
  cell: () => null,
  enableSorting: false,
  enableGlobalFilter: true,
});

// Hidden column holding the withdrawn state of the enrollment for sorting and
// filtering.
const withdrawnColumnDef = columnHelper.accessor("deleted", {
  id: ColumnId.Withdrawn,
  header: () => null,
  cell: () => null,
  enableSorting: true,
  enableGlobalFilter: false,
});

// Hidden column holding the withdrawn state of the enrollment for sorting and
// filtering.
const deferredColumnDef = columnHelper.accessor("deferred", {
  id: ColumnId.Deferred,
  header: () => null,
  cell: () => null,
  enableSorting: true,
  enableGlobalFilter: false,
});

// Student status flags column, which is not render-able, but is meant for
// accessing `ProgressFlags` data from other columns, and filtering with predicate
// functions on `ProgressFlags`.
const flagsColumnDef = columnHelper.accessor("flags", {
  id: ColumnId.Flags,
  header: () => null,
  cell: () => null,
  filterFn: predicateFilter,
  enableGlobalFilter: false,
  enableSorting: false,
});

// Student progress column
const desiredOrders: Record<ProgressStage, number> = {
  [ProgressStage.FeedbackViewed]: 0,
  [ProgressStage.Enrolled]: 1 /* shown as not accessed */,
  [ProgressStage.Accessed]: 2,
  [ProgressStage.Started]: 3,
  [ProgressStage.Draft]: 4,
  [ProgressStage.Final]: 5,
};

/**
 * Uses custom stage ordering (prescribed from design) to determine the orders
 * in which to display the stages.
 *
 */
export function sortByProgressStageOrderings(
  stageA: ProgressStage,
  stageB: ProgressStage
): -1 | 0 | 1 {
  return compareBasic(desiredOrders[stageA], desiredOrders[stageB]);
}

/**
 * Used so that the filtering functions can filter out withdrawn and deferred students.
 */
export interface ProgressValue {
  progress: ProgressStage;
  withdrawn?: boolean;
  deferred?: boolean;
}

const progressColumnDef = columnHelper.accessor(
  (row) => ({
    progress: row.progress,
    withdrawn: row.withdrawn,
    deferred: row.deferred,
    withdrawnBy: row.withdrawnBy,
  }),
  {
    id: ColumnId.Progress,
    header: ({ column }) => (
      <Grid.ProgressCol>
        <SortHeaderButton column={column}>Progress</SortHeaderButton>
      </Grid.ProgressCol>
    ),
    cell: ({ getValue, table, row }) => {
      const { progress } = getValue();
      const sheet = table.options.meta?.sheet;
      const {
        deferred,
        withdrawn,
        withdrawnBy,
        final,
        draft,
        settings,
        displayFirstName,
      } = row.original;
      const hasDrafting = !!sheet?.draftDueDate;
      const markingDisabled = sheet?.gradingService === "speedgrader";

      if (withdrawn) {
        return (
          <Grid.ProgressCol>
            <WithdrawnMessage withdrawnBy={withdrawnBy} />
          </Grid.ProgressCol>
        );
      }

      if (deferred) {
        return (
          <Grid.ProgressCol>
            <DeferredMessage />
          </Grid.ProgressCol>
        );
      }

      return (
        <Grid.ProgressCol>
          <ProgressPill
            progress={progress}
            hasDrafting={hasDrafting}
            hasSubmittedDraft={!!draft}
            markingDisabled={markingDisabled}
            feedbackViews={final?.feedbackViewCount}
            noFeedbackDate={
              settings.isExam ? !sheet?.examFeedbackDate : !sheet?.returnDate
            }
            userFirstName={displayFirstName}
          />
        </Grid.ProgressCol>
      );
    },
    enableGlobalFilter: false,
    filterFn: predicateFilter,
    sortingFn: (rowA, rowB) => {
      const [progressA, progressB]: [ProgressValue, ProgressValue] = [
        rowA.getValue("progress"),
        rowB.getValue("progress"),
      ];
      return sortByProgressStageOrderings(
        progressA.progress,
        progressB.progress
      );
    },
  }
);
const accessCodeLabelColumnDef = columnHelper.accessor("accessCodeLabel", {
  id: ColumnId.accessCodeLabel,
  header: ({ column }) => (
    <Grid.AccessCodeLabelCol>
      <SortHeaderButton column={column}>Access Code</SortHeaderButton>
    </Grid.AccessCodeLabelCol>
  ),
  cell: ({ row }) => {
    const { accessCode } = row.original.enrollment;

    return (
      <Grid.AccessCodeLabelCol>
        <Text kind="bodyMd">
          {accessCode && (accessCode.label ?? accessCode.code)}
        </Text>
      </Grid.AccessCodeLabelCol>
    );
  },
  enableSorting: true,
  enableGlobalFilter: true,
  sortingFn: "basic",
});

// Column definition for displaying last activity timestamp
const lastActivityColumnDef = columnHelper.accessor("lastActivity", {
  id: ColumnId.LastActivity,
  header: ({ column }) => (
    <Grid.AccessCol>
      <SortHeaderButton
        column={column}
        tooltip="The last time a student accessed the assessment via the LMS or edited their work in Cadmus"
        tooltipSize="md"
        tooltipWidth={200}
      >
        Last activity
      </SortHeaderButton>
    </Grid.AccessCol>
  ),
  cell: ({ getValue }) => {
    const lastActivity = getValue();

    return (
      <Grid.AccessCol>
        <Text kind="bodySm">
          {lastActivity ? formatDate(lastActivity, COMPACT_DATE_FORMAT) : "n/a"}
        </Text>
      </Grid.AccessCol>
    );
  },
  enableGlobalFilter: false,
  sortingFn: "basic",
});

// Column for the group name the student belongs to
const groupColumnDef = columnHelper.accessor((row) => row.group?.name ?? null, {
  id: ColumnId.Group,
  header: ({ column }) => (
    <Grid.GroupCol>
      <SortHeaderButton column={column}>Marking group</SortHeaderButton>
    </Grid.GroupCol>
  ),
  cell: ({ getValue, row }) => {
    const group = getValue();
    const withdrawn = row.original?.withdrawn ?? false;
    const deferred = row.original?.deferred ?? false;

    if (withdrawn || deferred) return <Grid.GroupCol />;

    return (
      <Grid.GroupCol>
        {group && <MarkingGroupDataCell groupName={group} />}
      </Grid.GroupCol>
    );
  },
  enableGlobalFilter: false,
  filterFn: (row, columnId, filter: string | null) => {
    const value = row.getValue<string | null>(columnId);
    return value === filter;
  },
  sortingFn: (rowA, rowB, columnId) => {
    return compareBasic(
      rowA.getValue<string | null>(columnId) ?? "",
      rowB.getValue<string | null>(columnId) ?? ""
    );
  },
});

const durationColumnDef = columnHelper.accessor(
  (data) => data.final?.timeSpentMinutes ?? undefined,
  {
    id: ColumnId.Duration,
    header: ({ column }) => (
      <Grid.DurationCol data-testid="DurationColumn">
        <SortHeaderButton column={column}>Duration</SortHeaderButton>
      </Grid.DurationCol>
    ),
    cell: ({ getValue }) => {
      const duration = getValue();

      return (
        <Grid.DurationCol>
          <Text kind="bodySm">
            {duration
              ? hrAndMinDuration(
                  duration,
                  { hr: " hr", min: " min" },
                  " ",
                  false
                )
              : "<1 min"}
          </Text>
        </Grid.DurationCol>
      );
    },
    enableGlobalFilter: false,
    sortingFn: "basic",
  }
);

// Draft or Final similarity column creator with Turnitin launch.
// The actual column value is the similarity score value, but the rendering
// reads the full `ResultFragment` field of the selected submission to generate
// a Turnitin link.
const makeSimilarityColumnDef = (
  accessorFn: AccessorFn<StudentListRow, ResultFragment | null>,
  isDraft: boolean = false
) =>
  columnHelper.accessor(accessorFn, {
    id: ColumnId.Similarity,
    header: ({ column }) => (
      <Grid.SimilarityCol>
        <SortHeaderButton column={column} tooltip="Turnitin Similarity Score">
          Similarity
        </SortHeaderButton>
      </Grid.SimilarityCol>
    ),
    cell: ({ getValue, row }) => {
      const result = getValue();
      const withdrawn = row.original?.withdrawn ?? false;
      const deferred = row.original?.deferred ?? false;

      if (!result || withdrawn || deferred) return <Grid.SimilarityCol />;

      return (
        <Grid.SimilarityCol>
          <SimilarityScoreLink
            data-component={"Table.SimilarityScoreLink"}
            result={result}
            isDraft={isDraft}
          />
        </Grid.SimilarityCol>
      );
    },
    sortingFn: (rowA, rowB, columnId) => {
      const resultA = rowA.getValue(columnId) as ResultFragment;
      const resultB = rowB.getValue(columnId) as ResultFragment;
      return compareBasic(
        resultA.similarity?.value ?? -1,
        resultB.similarity?.value ?? -1
      );
    },
    filterFn: (row, columnId, filters) => {
      const value = row.getValue<ResultFragment | null>(columnId)?.similarity
        ?.value;
      return applyRangeFilters(value, filters);
    },
  });

/**
 * Draft or Final submission download column creator.
 */
const makeDownloadColumnDef = (
  accessorFn: AccessorFn<StudentListRow, SubmissionDetails | null>,
  isDraft: boolean = false
) =>
  columnHelper.accessor(accessorFn, {
    id: ColumnId.Download,
    header: ({ column, table }) => {
      const format = table.options.meta?.sheet?.format;

      return (
        <Grid.DownloadCol data-testid="DownloadColumn">
          <SortHeaderButton column={column}>
            {format === TaskFormat.Multiformat
              ? "Auto-marked PDF"
              : "Submission PDF"}
          </SortHeaderButton>
        </Grid.DownloadCol>
      );
    },
    cell: ({ getValue, table, row }) => {
      const submission = getValue();

      return (
        <Grid.DownloadCol>
          <DownloadLink
            submission={submission}
            enrollment={row.original.enrollment}
            sheet={table.options.meta!.sheet ?? null}
            onDownloadSubmission={table.options.meta!.downloadSubmission!}
            submissionType={
              isDraft ? SubmissionType.Draft : SubmissionType.Final
            }
          />
        </Grid.DownloadCol>
      );
    },
  });

// Draft or Final submission status (timestamp, lateness, tags) column creator.
// This column will be submission timestamp sortable.
const makeSubmissionStatusColumnDef = (
  accessorFn: AccessorFn<StudentListRow, SubmissionDetails | null>,
  isDraft: boolean = false,
  lateTagRequired: boolean = true
) =>
  columnHelper.accessor(accessorFn, {
    id: ColumnId.Status,
    header: ({ column }) => {
      return (
        <Grid.StatusCol>
          <SortHeaderButton column={column}>
            {isDraft ? "Draft Submission" : "Final Submission"}
          </SortHeaderButton>
        </Grid.StatusCol>
      );
    },
    cell: (props) => (
      <SubmissionStatusCell
        {...props}
        isDraft={isDraft}
        lateTagRequired={lateTagRequired}
      />
    ),
    sortingFn: (rowA, rowB, columnId) => {
      const valA: SubmissionDetails | null = rowA.getValue(columnId);
      const valB: SubmissionDetails | null = rowB.getValue(columnId);

      return compareBasic(
        valA?.timestamp.getTime() ?? 0,
        valB?.timestamp.getTime() ?? 0
      );
    },
    filterFn: predicateFilter,
    enableGlobalFilter: false,
  });

// Column for Draft submission Feedback action
const feedbackColumnDef = columnHelper.accessor("draft", {
  id: ColumnId.Feedback,
  header: ({ column }) => (
    <Grid.ActionCol onClick={column.getToggleSortingHandler()}>
      <Text kind="label">Feedback</Text>
    </Grid.ActionCol>
  ),
  cell: ({ getValue, table, row }) => {
    const submission = getValue();
    const gradingService = table.options.meta?.sheet?.gradingService ?? null;
    const withdrawn = row.original?.withdrawn ?? false;
    const deferred = row.original?.deferred ?? false;

    if (!submission || !gradingService || withdrawn || deferred) {
      return <Grid.ActionCol />;
    }

    return (
      <Grid.ActionCol>
        <GradingLink
          data-component={"Table.Feedback.GradingLink"}
          result={submission.result}
          gradingService={gradingService}
          isDraft
        />
      </Grid.ActionCol>
    );
  },
});

/**
 * Column to display the grade OR the action to launch connected external
 * grading service.
 */
const gradeColumnDef = columnHelper.accessor("final", {
  id: ColumnId.Grade,
  header: ({ column }) => (
    <Grid.ActionCol>
      <SortHeaderButton column={column}>Grade</SortHeaderButton>
    </Grid.ActionCol>
  ),
  cell: ({ getValue, table, row }) => {
    const submission = getValue();
    const gradingService = table.options.meta?.sheet?.gradingService ?? null;
    const withdrawn = row.original?.withdrawn ?? false;
    const deferred = row.original?.deferred ?? false;
    const format = table.options.meta?.sheet?.format;
    const markingPdfSentAt = submission?.markingPdf?.agsSentAt ?? null;

    return (
      <Grid.ActionCol>
        <GradeDataCell
          deferred={deferred}
          gradingService={gradingService}
          submission={submission}
          withdrawn={withdrawn}
          format={format}
          markingPdfSentAt={markingPdfSentAt}
        />
      </Grid.ActionCol>
    );
  },
  sortingFn: (rowA, rowB, columnId) => {
    const valA: SubmissionDetails | null = rowA.getValue(columnId);
    const valB: SubmissionDetails | null = rowB.getValue(columnId);

    return compareBasic(
      valA?.result.grade?.value ?? -1,
      valB?.result.grade?.value ?? -1
    );
  },
  filterFn: (row, columnId, filters) => {
    const submission = row.getValue(columnId) as SubmissionDetails | null;
    const grade = submission?.result.grade?.value;
    return applyRangeFilters(!(grade == null) ? grade * 100 : null, filters);
  },
});

// Data column to store list of tags for filtering. Does not have any rendering
// associated with it and should be hidden.
const tagsColumnDef = columnHelper.accessor(
  (data) => data.tags.map((t) => t.text),
  {
    id: ColumnId.Tags,
    header: () => null,
    cell: () => null,
    enableSorting: false,
    filterFn: (row, columnId: string, filter: string[] | undefined) => {
      const tags = row.getValue<string[]>(columnId);
      if (!filter || filter.length === 0) return true;
      if (tags.length === 0 && filter) return false;
      return tags.some((tag) => filter.includes(tag));
    },
  }
);

// Row actions as a dropdown
const optionsColumnDef = columnHelper.display({
  id: ColumnId.Options,
  header: () => <Grid.OptionsCol />,
  cell: ({ table, row }) => {
    return <OptionsCol table={table} row={row} />;
  },
});

/**
 * Column displays total points edited by the marker + moderator which are overriding
 * auto-marking scores. (other than extended response type questions)
 */
const adjustedPointsDef = columnHelper.accessor("workOutcomeSummary", {
  id: ColumnId.AdjustedScore,
  header: ({ column }) => (
    <Grid.AdjustedScoreCol>
      <SortHeaderButton column={column}>Adj Score</SortHeaderButton>
    </Grid.AdjustedScoreCol>
  ),
  cell: (props) => <AdjustedPointsCell {...props} />,
});

/**
 * Displays the final grade of the student's work and the form for the moderator
 * to update or reset it
 */
const moderationGradeDef = columnHelper.accessor("workOutcomeSummary", {
  id: ColumnId.Grade,
  header: ({ column }) => (
    <Grid.MarkedScoreCol>
      <SortHeaderButton column={column}>Grade</SortHeaderButton>
    </Grid.MarkedScoreCol>
  ),
  cell: (props) => <ModerationGradeCell {...props} />,
  sortingFn: (rowA, rowB) => {
    const valA = rowA.original.workOutcomeSummary?.score;
    const valB = rowB.original.workOutcomeSummary?.score;

    return compareBasic(valB, valA);
  },
  filterFn: (row, _, filters) => {
    const grade = row.original?.workOutcomeSummary?.score
      ? (row.original.workOutcomeSummary?.score ?? 0) /
        row.original.workOutcomeSummary?.maxScore
      : null;
    return applyRangeFilters(!(grade == null) ? grade * 100 : null, filters);
  },
});

/**
 * How late after due date the student submitted the final
 */
const lateByDef = columnHelper.accessor((data) => data.final?.late, {
  id: ColumnId.LateBy,
  header: ({ column }) => (
    <Grid.LateByCol>
      <SortHeaderButton column={column}>Late By</SortHeaderButton>
    </Grid.LateByCol>
  ),
  cell: ({ getValue }) => {
    const lateBy = getValue();
    return (
      <Grid.LateByCol>
        {lateBy && <Pill basic>{lateBy.replace("late", "")}</Pill>}
      </Grid.LateByCol>
    );
  },
  sortingFn: (rowA, rowB) => {
    const valA = rowA.original.final?.timestamp;
    const valB = rowB.original.final?.timestamp;

    return compareBasic(valB?.getTime() ?? 0, valA?.getTime() ?? 0);
  },
  filterFn: (row, columnId, filter) => {
    const value = row.getValue(columnId) as string | null;
    if (!filter) return true;
    if (!value) return false;
    return value.includes(`${filter.number} ${filter.unit}`);
  },
});

/**
 * Displays when the feedback release is scheduled or has been released for a
 * student.
 */
const feedbackReleaseOnDef = columnHelper.accessor(
  (data) => data.workOutcomeSummary?.feedbackReleasedTimestamp ?? null,
  {
    id: ColumnId.FeedbackReleaseOn,
    header: () => (
      <Grid.ReleaseOnCol>
        <Text kind={"label"}>Release On</Text>
      </Grid.ReleaseOnCol>
    ),
    cell: ({ getValue }) => {
      const timestamp = getValue();
      return (
        <Grid.ReleaseOnCol>
          <Text kind="bodySm" as="span">
            {timestamp ? formatDate(timestamp, COMPACT_DATE_FORMAT) : "--"}
          </Text>
        </Grid.ReleaseOnCol>
      );
    },
  }
);

// Column definitions for the Progress class list
export const PROGRESS_COLUMNS = [
  selectColumnDef,
  indexColumnDef,
  nameColumnDef,
  groupColumnDef,
  progressColumnDef,
  accessCodeLabelColumnDef,
  lastActivityColumnDef,
  optionsColumnDef,
  tagsColumnDef,
  flagsColumnDef,
  emailColumnDef,
  sisUserIdColumnDef,
  withdrawnColumnDef,
  deferredColumnDef,
];

// Column definitions for the Drafts class list
export const DRAFT_COLUMNS = [
  selectColumnDef,
  indexColumnDef,
  nameColumnDef,
  emailColumnDef,
  sisUserIdColumnDef,
  withdrawnColumnDef,
  deferredColumnDef,
  groupColumnDef,
  makeDownloadColumnDef((row) => row.draft, true),
  makeSubmissionStatusColumnDef((row) => row.draft, true),
  makeSimilarityColumnDef((row) => row.draft?.result ?? null, true),
  feedbackColumnDef,
  optionsColumnDef,
  tagsColumnDef,
  flagsColumnDef,
];

// Column definitions for the Finals class list
export const MARKING_COLUMNS = [
  selectColumnDef,
  indexColumnDef,
  nameColumnDef,
  emailColumnDef,
  sisUserIdColumnDef,
  withdrawnColumnDef,
  deferredColumnDef,
  groupColumnDef,
  durationColumnDef,
  makeSubmissionStatusColumnDef((row) => row.final),
  makeSimilarityColumnDef((row) => row.final?.result ?? null),
  makeDownloadColumnDef((row) => row.final),
  gradeColumnDef,
  optionsColumnDef,
  tagsColumnDef,
  flagsColumnDef,
];

// Column definitions for the Moderation class list
export const MODERATION_COLUMNS = [
  selectColumnDef,
  indexColumnDef,
  nameColumnDef,
  groupColumnDef,
  makeSubmissionStatusColumnDef((row) => row.final, false, false),
  makeSimilarityColumnDef((row) => row.final?.result ?? null),
  lateByDef,
  feedbackReleaseOnDef,
  adjustedPointsDef,
  moderationGradeDef,
  // hidden columns for sorting and filtering
  tagsColumnDef,
  sisUserIdColumnDef,
  emailColumnDef,
  withdrawnColumnDef,
  deferredColumnDef,
];
