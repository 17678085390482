import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "@/data/store";

import { onSaveTask } from "./api";
import { serialiseTaskEditor } from "./taskEditor";

export const saveTask = createAsyncThunk(
  "task/save",
  async (payload: TaskSavePayload, _thunkAPI) => {
    const doc = serialiseTaskEditor();
    if (doc) {
      const content = JSON.stringify(doc);
      return onSaveTask(payload.assessmentId, content);
    }
  }
);

interface TrackNetworkRequestPayload {
  /** The promise for the request in progress. */
  requestPromise: Promise<unknown>;
}

/**
 * Takes a network request promise as an in-progress save request.
 */
export const trackNetworkRequest = createAsyncThunk<
  unknown,
  TrackNetworkRequestPayload,
  { state: RootState }
>("task/trackNetworkRequest", async (payload, _thunkAPI) => {
  return payload.requestPromise;
});

interface TaskSavePayload {
  assessmentId: string;
}
