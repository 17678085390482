import * as React from "react";
import { styled } from "styled-components";

import { Icon } from "@vericus/cadmus-icons";
import { colors, IconButton, LinkButton, Text } from "@vericus/cadmus-ui";

import {
  ErrorKind,
  useClearErrorCallback,
  useError,
} from "data/error/useError";
import { useAppDispatch, useAppSelector } from "data/hooks";
import { getAssessmentId, saveTask } from "@/data/task";

/**
 * Bar rendering Error information with optional user actions.
 *
 * The error is pulled from the redux state using the selector `getError`. The
 * error enum can also be supplied as a prop, and this case it will override the
 * redux error state and will auto-dismiss.
 */
export function StatusBar() {
  const dispatch = useAppDispatch();
  const assessmentId = useAppSelector(getAssessmentId);

  // Get the error, if there is one.
  const error = useError();
  const isSaveError = error?.kind === ErrorKind.SaveContent;

  // Callback to request a new save
  const onSave = React.useCallback(() => {
    if (assessmentId) {
      dispatch(
        saveTask({
          assessmentId,
        })
      );
    }
  }, [dispatch, assessmentId]);

  // Callback to dismiss the notification
  const clearError = useClearErrorCallback();

  // Retry saving on an interval on a save error
  React.useEffect(() => {
    if (isSaveError) {
      const intervalId = setInterval(onSave, 2500);
      return () => {
        clearInterval(intervalId);
      };
    }
    return undefined;
  }, [isSaveError, onSave]);

  // Dismiss the error on unmount
  React.useEffect(() => {
    return () => {
      clearError();
    };
  }, [clearError]);

  if (!error) return null;

  return (
    <Container data-testid={"StatusBar"}>
      <Text kind="bodySm">
        <strong>{error.subject}: </strong>
        {error.message}
        {isSaveError && (
          <StyledLinkButton
            inline
            kind="solid"
            onClick={onSave}
            title="Try again"
          >
            Try again now
          </StyledLinkButton>
        )}
      </Text>
      <IconButton
        solid
        onClick={() => {
          clearError();
        }}
        aria-label="Dismiss error"
        title="Dismiss error"
        tooltipPosition="left"
      >
        <Icon iconName="Close" />
      </IconButton>
    </Container>
  );
}

const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
`;

const Container = styled.div`
  width: 100%;
  min-height: 63px;
  padding: 9px 27px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${colors.orange100};
  border-bottom: 1px solid ${(p) => p.theme.divider};

  position: relative;
  z-index: 10;
`;
