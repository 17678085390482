import { styled } from "styled-components";

import { colors, LinkButton } from "@vericus/cadmus-ui";

import {
  EnrollmentFragment,
  InstructionSheetFragment,
  SubmissionType,
  TaskFormat,
} from "@/generated/graphql";
import { SubmissionDetails } from "@/graphql/types/SubmissionDetails";

interface DownloadLinkProps {
  submission: SubmissionDetails | null;
  submissionType: SubmissionType;
  enrollment: EnrollmentFragment;
  onDownloadSubmission: (
    enrollment: EnrollmentFragment,
    type: SubmissionType
  ) => void;
  sheet: InstructionSheetFragment | null;
}

export function DownloadLink({
  submission,
  enrollment,
  onDownloadSubmission,
  submissionType,
  sheet,
}: DownloadLinkProps) {
  const format = sheet?.format ?? TaskFormat.Classic;
  const autoMarked = format === TaskFormat.Multiformat ? "auto-marked " : "";
  const valid = sheet?.serverId === submission?.pdf?.taskHistoryId;

  /**
   *  If the submission is already generated, show the download button.
   *  In case of Multiformat the Work Artifact that is associated with submission for particular sheet should be in submission.pdf field
   *  in case of Classic - just fetching in straight forward way
   */
  if (
    sheet &&
    submission &&
    ((submission.pdf && valid) || format == TaskFormat.Classic)
  ) {
    return (
      <BoldLinkButton
        iconName="Download"
        data-testid={"DownloadLink"}
        title={`Download ${autoMarked}PDF`}
        color="blue500"
        onClick={() => onDownloadSubmission(enrollment, submissionType)}
      >
        Download
      </BoldLinkButton>
    );
  }

  return (
    <LinkButton
      iconName="Reset"
      data-testid={"DownloadLink"}
      title={`Generating ${autoMarked} PDF`}
      disabled={true}
    >
      Calculating...
    </LinkButton>
  );
}

const BoldLinkButton = styled(LinkButton)`
  font-weight: 600;
  fill: ${colors["blue500"]}; // to make icon the same color with the text
`;
