import { styled } from "styled-components";

import { SectionLabel, Spacer } from "@vericus/cadmus-ui";

import { ResourceFragment } from "generated/graphql";
import { getResourceUrl, openUrl } from "ui/task/resources/internal";

import { NotesDisplay } from "./NotesDisplay";
import { ReadonlyResourceCard } from "./ReadonlyResourceCard";

interface Props {
  resources: ResourceFragment[];
}

export const ResourceListReadOnly = (props: Props) => {
  const resources = props.resources.filter((r) => !r.invalidated);

  if (resources.length === 0) {
    return null;
  }

  return (
    <>
      <SectionLabel title="Resources" />
      <Spacer spacing={27} />
      <ListContainer>
        {resources.map((res) => {
          return (
            <ReadonlyResourceCard
              key={res.id}
              resource={res}
              onOpenInBrowser={() => openUrl(getResourceUrl(res.id))}
            >
              {Boolean(res.notes) && <NotesDisplay notes={res.notes} />}
            </ReadonlyResourceCard>
          );
        })}
      </ListContainer>
    </>
  );
};

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
