import { useMemo } from "react";

import {
  ReleasedRequirementsFragment,
  RequirementsFragment,
} from "generated/graphql";

import { fromReleasedRequirements } from "./fromReleasedRequirements";

export interface IReqsDifference {
  allowLateResubmission: boolean;
  dueDate: boolean;
  draftDueDate: boolean;
  weight: boolean;
  wordLimit: boolean;
  timeLimit: boolean;
  referencingStyle: boolean;
  returnDate: boolean;
  maxGrade: boolean;
  similarity: boolean;
  draftSimilarity: boolean;
  anonymousMarking: boolean;
  gradingService: boolean;

  assessmentType: boolean;

  enableExamAutoSubmission: boolean;
  enableExamLdb: boolean;
  enableSubmissionPdf: boolean;
  examEndDate: boolean;
  examStartDate: boolean;
  examReadingTime: boolean;
  examTiming: boolean;
  examType: boolean;
  examWritingTime: boolean;
  lateSubmissionTimeLimit: boolean;

  examFeedbackDate: boolean;
}

/**
 * Compute the difference between the current task requirements and the given
 * previous released requirements.
 */
export function diffRequirements(
  a: RequirementsFragment,
  b: RequirementsFragment
): IReqsDifference {
  const comp = (prop: keyof RequirementsFragment) => !(a[prop] === b[prop]);
  /**
   * If noProp (e.g. noDraft) is false on both requirement,
   * the prop's diff should be false.
   * Even if the optional values are different.
   * This is because there are no change from the users' perspective.
   *
   * i.e.
   * a = {
   *        noTimeLimit: false,
   *        timeLimit: 150
   *     }
   * b = {
   *        noTimeLimit: false,
   *        timeLimit: 100
   *     }
   * should result in { timeLimit: false }
   */
  const compOptional = (
    prop: keyof RequirementsFragment,
    noProp: keyof RequirementsFragment
  ) => (a[noProp] && b[noProp] ? false : comp(prop) || comp(noProp));

  return {
    allowLateResubmission: comp("allowLateResubmission"),
    dueDate: comp("dueDate"),
    draftDueDate: compOptional("draftDueDate", "noDraft"),
    examFeedbackDate: compOptional("examFeedbackDate", "noExamFeedbackDate"),
    weight: compOptional("weight", "noWeight"),
    wordLimit: compOptional("wordLimit", "noLimit"),
    timeLimit: compOptional("timeLimit", "noTimeLimit"),
    referencingStyle:
      compOptional("referencingStyle", "noReferencing") ||
      comp("countReferences"),
    returnDate: comp("returnDate"),
    maxGrade: comp("maxGrade"),
    similarity: comp("sViewReports"),
    draftSimilarity: comp("draftSViewReports"),
    anonymousMarking: comp("anonymousMarking"),
    gradingService: comp("gradingService"),
    assessmentType: comp("assessmentType"),
    enableExamAutoSubmission: comp("enableExamAutoSubmission"),
    enableSubmissionPdf: comp("enableSubmissionPdf"),
    examEndDate: comp("examEndDate"),
    examStartDate: comp("examStartDate"),
    examReadingTime: compOptional("examReadingTime", "noExamReadingTime"),
    examTiming: comp("examTiming"),
    examType: comp("examType"),
    examWritingTime: comp("examWritingTime"),
    lateSubmissionTimeLimit: comp("lateSubmissionTimeLimit"),
    enableExamLdb: comp("enableExamLdb"),
  };
}

/**
 * memoized `diffRequirements` calculation with easier arguments
 */
export const useDiffRequirements = (
  a: RequirementsFragment,
  b?: ReleasedRequirementsFragment | null
) =>
  useMemo(() => {
    return b ? diffRequirements(a, fromReleasedRequirements(b)) : undefined;
  }, [a, b]);
