import * as React from "react";
import { styled } from "styled-components";

import { motion } from "framer-motion";

export const SettingContainer = styled.div`
  padding: 0 45px;
  max-width: 600px;
  margin: 108px auto;
`;

export const FadeIn = ({ children }: React.PropsWithChildren) => {
  return (
    <motion.div
      transition={{ type: "spring", duration: 0.8 }}
      initial={{ opacity: 0, y: -18 }}
      animate={{ opacity: 1, y: 0 }}
    >
      {children}
    </motion.div>
  );
};
