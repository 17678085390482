export enum SpecialConsiderationFormAction {
  // Extra time (reading & writing) + start date
  UPDATED_SPECIAL_CONSIDERATIONS = "UPDATED_SPECIAL_CONSIDERATIONS",

  // Reading + writing time set
  UPDATED_READING_WRITING_TIME = "UPDATED_READING_WRITING_TIME",
  REMOVED_READING_WRITING_TIME = "REMOVED_READING_WRITING_TIME",

  // Start date set
  UPDATED_START_TIME = "UPDATED_START_TIME",
  REMOVED_START_TIME = "REMOVED_START_TIME",

  REMOVED_START_END_TIME = "REMOVED_START_END_TIME",

  // Reading time set
  UPDATED_READING_TIME = "UPDATED_READING_TIME",
  REMOVED_READING_TIME = "REMOVED_READING_TIME",

  // Writing time set
  UPDATED_WRITING_TIME = "UPDATED_WRITING_TIME",
  REMOVED_WRITING_TIME = "REMOVED_WRITING_TIME",

  // ExamDeferred set to true
  UPDATED_DEFERRED = "UPDATED_DEFERRED",

  // ExamDeferred set to false
  REMOVED_DEFERRED = "REMOVED_DEFERRED",
}
