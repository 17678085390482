import { forwardRef } from "react";
import { styled } from "styled-components";

import { Button, colors } from "@vericus/cadmus-ui";

export interface NotesInputProps {
  autoFocus?: boolean;
  notes: string;
  /**
   * @default "Cancel"
   */
  cancelButtonText?: string | false;
  /**
   * @default "Done"
   */
  doneButtonText?: string;
  onNotesChange: (newNotes: string) => void;
  onCancelClick: VoidFunction;
  onDoneClick: VoidFunction;
}

export const NotesInput = forwardRef<HTMLTextAreaElement, NotesInputProps>(
  (
    {
      notes,
      onNotesChange,
      onCancelClick,
      onDoneClick,
      autoFocus,
      cancelButtonText = "Cancel",
      doneButtonText = "Done",
    },
    ref
  ) => {
    return (
      <Container>
        <TransparentTextArea
          ref={ref}
          placeholder="Add a note on how students should engage with this resource"
          maxLength={250}
          autoFocus={autoFocus}
          value={notes}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onDoneClick();
            }
            if (e.key === "Escape") {
              onCancelClick();
            }
          }}
          onChange={(e) => onNotesChange(e.target.value)}
        />
        <ActionsContainer>
          <ButtonWithLessDropShadow onClick={onCancelClick}>
            {cancelButtonText}
          </ButtonWithLessDropShadow>
          <Button kind="secondary" onClick={onDoneClick}>
            {doneButtonText}
          </Button>
        </ActionsContainer>
      </Container>
    );
  }
);

const Container = styled.div`
  background-color: rgba(71, 92, 133, 0.06);
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  width: 100%;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const TransparentTextArea = styled.textarea`
  background: transparent;
  border: none;
  color: ${colors.black100};
  font-size: 14px;
  resize: none;
  width: 100%;
  min-height: 3.5em;
  &:focus,
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: ${colors.grey500};
  }
`;

const ButtonWithLessDropShadow = styled(Button)`
  box-shadow: 0 0 1px 0 rgb(71 92 133 / 58%), 0 2px 6px 0 rgb(71 92 133 / 10%),
    0 9px 9px -6px rgb(71 92 133 / 1%);
`;
