import { useMemo } from "react";

import {
  Enrollment,
  GroupEnrollmentFragment,
  GroupFragment,
  UserFragment,
} from "generated/graphql";

import {
  DisplayGroup,
  GroupListRow,
  MarkerGroup,
  QuestionGroupListRow,
  QuestionItem,
} from "@/features/marking-groups";

/** User ID lists */
export interface UserLists {
  // List of user IDs who are in some groups
  grouped: UserFragment[];
  // List of user IDs who are in multiple groups
  duplicates: UserFragment[];
  // List of user IDs who are in no groups
  ungrouped: UserFragment[];
}

/**
 * Create different lists of user IDs based on their group participation.
 */
export function createUserLists(
  groups: GroupFragment[],
  students: UserFragment[]
): UserLists {
  // Map of ALL user ids to list of groups they are in
  let userMap: Map<string, GroupFragment[]> = new Map();
  const ungrouped: UserFragment[] = [];
  const grouped: UserFragment[] = [];
  const duplicates: UserFragment[] = [];

  userMap = students.reduce((acc, user) => acc.set(user.id, []), userMap);
  userMap = groups.reduce((acc, group) => {
    const { members } = group;
    return members.reduce((userMap, user) => {
      const existing = userMap.get(user.id);
      if (existing) {
        return userMap.set(user.id, [...existing, group]);
      } else {
        return userMap.set(user.id, [group]);
      }
    }, acc);
  }, userMap);

  userMap.forEach((val, key) => {
    const student = students.find((s) => s.id === key);
    if (!student) {
      return;
    }
    if (val.length === 0) {
      ungrouped.push(student);
    } else {
      grouped.push(student);
      if (val.length > 1) {
        duplicates.push(student);
      }
    }
  });

  return {
    ungrouped: ungrouped,
    grouped: grouped,
    duplicates: duplicates,
  };
}

interface FilteredGroupsHookResult {
  /** Groups without withdrawn or deferred students. */
  filteredGroups: GroupFragment[];
  /**
   * The number of students, that are not withdrawn or deferred,
   * that have been assigned to a marking group.
   */
  groupedStudents: number;
  /**
   * The number of students, that are not withdrawn or deferred,
   * that have not been assigned to a marking group.
   */
  ungroupedStudents: number;
}

/**
 * Filter out withdrawn and deferred students from the groups
 * and return new groups.
 */
export function useFilteredGroups({
  groups,
  enrollments,
}: {
  groups: GroupFragment[];
  enrollments: GroupEnrollmentFragment[];
}): FilteredGroupsHookResult {
  return useMemo(() => {
    const activeUsers = enrollments
      .filter((e) => !(e.deleted || e.workSettings?.examDeferred))
      .map((e) => e.user);
    const filteredGroups = filterActiveGroupMembers(groups, activeUsers);

    // map all unique students in the groups
    const groupedStudents = filteredGroups.flatMap((g) => g.members);
    const uniqueGroupedStudentsCount = [
      ...new Map(
        groupedStudents.map((student) => [student.id, student])
      ).values(),
    ].length;

    const ungroupedStudents = activeUsers.length - uniqueGroupedStudentsCount;
    return {
      filteredGroups,
      groupedStudents: uniqueGroupedStudentsCount,
      ungroupedStudents,
    };
  }, [groups, enrollments]);
}

/**
 * Filter the group members for the given `groups` to keep only the active
 * users.
 *
 * @param groups List of groups to filter.
 * @param activeUsers Users with active enrollment.
 * @return The same `groups` but with filtered members.
 */
export function filterActiveGroupMembers(
  groups: GroupFragment[],
  activeUsers: { id: string }[]
): GroupFragment[] {
  if (!groups) {
    return [];
  }
  return groups
    .map((group) => ({
      ...group,
      members: group.members.filter(
        ({ id }) => !!activeUsers.find((user) => user.id === id)
      ),
    }))
    ?.sort((a, b) => {
      const aCode = parseInt(a?.code?.replace("G", "") || "0");
      const bCode = parseInt(b?.code?.replace("G", "") || "0");
      return aCode - bCode;
    });
}

/**
 * Split the array of users into the given number of groups.
 *
 * let members = [u1,u2,u3,u4,u5,u6,u7,u8,u9,u10]
 * splitArrayEvenly(members, 3)
 * [[u1,u2,u3], [u4,u5,u6], [u7,u8,u9,u10]]
 *
 * @param members{UserFragment[] | string[]}
 * @param groupsNum{number}
 * @returns{UserFragment[][]}
 */
export function splitArrayEvenly<T>(members: T[], groupsNum: number): T[][] {
  const baseSize = Math.floor(members.length / groupsNum);
  const remainder = members.length % groupsNum;

  const result = [];
  let start = 0;

  for (let i = 0; i < groupsNum; i++) {
    const chunkSize = baseSize + (i < remainder ? 1 : 0);
    result.push(members.slice(start, start + chunkSize));
    start += chunkSize;
  }

  return result.sort((a, b) => a.length - b.length);
}

/**
 * Split the groups by the given question ids and marker ids.
 *
 * Eg: members = 100 and we have 2 questions with 4 markers
 * then it will return 8 groups with 25 members each
 *
 * 4 group will have 1 marker each for 1st question
 * and another 4 group will have 1 marker each for 2nd question
 *
 * @param questionIds
 * @param markerIds
 * @param members
 */
export function splitGroupsByQuestion(
  questionIds: QuestionItem[],
  markerIds: { id: string | null; name: string }[],
  members: UserFragment[]
): DisplayGroup[] {
  const totalMarkers = markerIds.length;
  const groups = splitArrayEvenly(members, totalMarkers);
  const result = questionIds.flatMap((questionId) => {
    return markerIds.map((marker, index) => {
      const markerFirstName = marker.name.split(" ")[0];
      return {
        markerId: marker.id,
        markerName: markerFirstName,
        totalStudents: groups[index]?.length || 0,
        questionId: questionId.questionId,
        questionLabel: questionId.orderLabel,
      } as DisplayGroup;
    });
  });
  return result;
}
/**
 * Get the students in a group by group id.
 *
 * If groupId is undefined it returns all students
 * If groupId is null it returns all ungrouped students
 * If groupId is not null it returns the students in the group
 */
export function getGroupById(
  groups: GroupFragment[],
  markers: UserFragment[],
  enrollments: Enrollment[],
  ungroupedStudents: UserFragment[],
  groupId?: string | null
): GroupListRow[] {
  if (groupId === undefined) {
    const students = groups.flatMap((group) => {
      const markerName = markers.find((m) => m.id === group.markerId)?.name;
      return group.members.map((member) => {
        return {
          id: member.id,
          user: member,
          enrollment:
            enrollments.find(
              (enrollment) => enrollment.user.id === member.id
            ) ?? null,
          groupName: group.name,
          groupId: group.id,
          markerId: group.markerId,
          markerName: markerName,
        };
      });
    });
    return enrollments.map((e) => {
      const student = students.find((s) => s.id === e.user.id);
      if (!student) {
        return {
          id: e.user.id,
          user: e.user,
          enrollment: e,
          groupName: null,
          groupId: null,
          markerId: null,
          markerName: null,
        };
      }
      return {
        ...student,
        markerId: student.markerId,
        markerName: student.markerName ?? null,
        tags: e.tags,
      };
    });
  }
  if (groupId == null) {
    return ungroupedStudents.map((user) => {
      const enrollment = enrollments.find(
        (enrollment) => enrollment.user.id === user.id
      );
      return {
        id: user.id,
        user: user,
        enrollment: enrollment ?? null,
        groupName: null,
        groupId: null,
        markerId: null,
        markerName: null,
      };
    });
  }

  const group = groups.find((g) => g.id === groupId);
  if (!group) {
    return [];
  }

  const markerName = markers.find((m) => m.id === group.markerId)?.name ?? null;
  return group.members.map((member) => {
    const enrollment = enrollments.find((e) => e.user.id === member.id);
    return {
      id: member.id,
      user: member,
      enrollment: enrollment ?? null,
      groupName: group.name,
      groupId: group.id,
      markerId: group.markerId,
      markerName: markerName,
    };
  });
}

/**
 * Get the marker's groups and unassigned groups.
 */
export function getMarkerGroups(
  groups: GroupFragment[],
  markers: UserFragment[]
): MarkerGroup[] {
  const markerGroups: MarkerGroup[] = markers.map((marker) => ({
    markerId: marker.id,
    markerName: marker.name,
    email: marker.email,
    groups: groups.filter((group) => group.markerId === marker.id),
  }));
  markerGroups.unshift({
    markerId: null,
    markerName: "Unassigned - No Marker",
    email: null,
    groups: groups.filter((group) => group.markerId === null),
  });
  return markerGroups;
}

export function getGroupByStudentMarkers(
  groups: GroupFragment[],
  enrollments: Enrollment[],
  markerId: string | null,
  markers: UserFragment[],
  questions?: QuestionItem[]
): QuestionGroupListRow[] {
  const marker = markers.find((m) => m.id === markerId);
  return groups
    .filter((group) => group.markerId === markerId)
    .map((group) => {
      return {
        markerId: group.markerId,
        groupName: group.name,
        groupId: group.id,
        question: questions?.find((q) => q.questionId === group.questionId),
        markerName: marker?.name || null,
        members: group.members.map((member) => {
          const enrollment = enrollments.find(
            (enrollment) => enrollment.user.id === member.id
          );
          return {
            id: member.id,
            user: member,
            enrollment: enrollment ?? null,
            groupName: group.name,
            groupId: group.id,
            markerId: group.markerId,
            markerName: marker?.name || null,
          };
        }),
      };
    });
}
