import { styled } from "styled-components";

import { Icon } from "@vericus/cadmus-icons";
import {
  colors,
  LinkButton,
  Radio,
  Spacer,
  Switch,
  Text,
} from "@vericus/cadmus-ui";

import { MarkingToolPdf } from "generated/graphql";

import { SettingInfoCard } from "../../settings/SettingInfoCard";

interface RestrictStudentViewProps {
  restrictMarkingToolPdf: boolean;
  setRestrictStudentResultView: (value: boolean) => void;
  markingToolPdf: MarkingToolPdf | null;
  setStudentResultView: (value: MarkingToolPdf) => void;
}
export const RestrictStudentView = (props: RestrictStudentViewProps) => {
  return (
    <>
      <HeaderContainer>
        <legend>
          <Text kind="headingFive" as="span">
            Restrict student result view
          </Text>
        </legend>
        <Switch
          name="restrictMarkingToolPdf"
          checked={props.restrictMarkingToolPdf}
          onChange={() =>
            props.setRestrictStudentResultView(!props.restrictMarkingToolPdf)
          }
        />
      </HeaderContainer>
      <Spacer spacing={16} />
      {props.restrictMarkingToolPdf ? (
        <>
          <SettingInfoCard showNewPill={false} backgroundColor="blue">
            You can control what is visible to the students when they access
            their submissions on Canvas
          </SettingInfoCard>
          <Spacer spacing={16} />

          <Radio
            name="restrictMarkingToolPdf"
            content={`Show un-marked responses only`}
            value={MarkingToolPdf.UnmarkedOnly}
            checked={props.markingToolPdf === MarkingToolPdf.UnmarkedOnly}
            onChange={() =>
              props.setStudentResultView(MarkingToolPdf.UnmarkedOnly)
            }
          />
          <Radio
            name="restrictMarkingToolPdf"
            content={`Show un-marked responses and auto-mark summary`}
            value={MarkingToolPdf.UnmarkedAndAutomarkedSummary}
            checked={
              props.markingToolPdf ===
              MarkingToolPdf.UnmarkedAndAutomarkedSummary
            }
            onChange={() =>
              props.setStudentResultView(
                MarkingToolPdf.UnmarkedAndAutomarkedSummary
              )
            }
          />

          <Spacer spacing={18} />
          <a
            href="https://support.cadmus.io/setting-requirements-canvas#marking-feedback"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledLinkButton kind="solid">
              Accessing auto-marks from the class list on Cadmus{" "}
              <Icon iconName="Right" />
            </StyledLinkButton>
          </a>
        </>
      ) : (
        <SettingInfoCard showNewPill={false} backgroundColor="red">
          <PillContainer>
            <Text kind="label" style={{ color: colors.red500 }} inline>
              Note
            </Text>
          </PillContainer>
          <Text kind="bodySm">
            Keeping this setting OFF would allow students to access their
            submissions with auto-marked grades through Canvas.
            <br />
            <br />
            We recommend keeping this <strong>ON</strong>
          </Text>
        </SettingInfoCard>
      )}
    </>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PillContainer = styled.div`
  background-color: white;
  border: 0px;
  border-radius: 8px;
  padding: 2px 8px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
`;
