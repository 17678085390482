import { InstitutionTool, LtiService } from "generated/graphql";

/** Find the AGS V2 LTI service. */
export function findLTIAdvantageAGS(services: LtiService[]): LtiService | null {
  return (
    services.find(
      (service) => service.version === "v2" && service.name === "ags"
    ) ?? null
  );
}

/**  find the AGS V2 LTI service from Canvas LMS. */
export function findCanvasLTIAdvantageAGS(
  services: LtiService[]
): LtiService | null {
  return services.find(isCanvasLTIAdvantageAGS) ?? null;
}

/** Check if the given LTI AGS is from the Canvas LMS and is LTI Advantage compatible. */
export function isCanvasLTIAdvantageAGS(service: LtiService): boolean {
  return (
    service.version === "v2" &&
    service.name === "ags" &&
    (service.issuer === "https://canvas.instructure.com" ||
      service.issuer === "https://canvas.test.instructure.com" ||
      service.issuer === "https://canvas.beta.instructure.com")
  );
}

/** Check if the LMS the institution uses is Canvas. */
export function isCanvasLMS(tool: InstitutionTool): boolean {
  return tool.familyCode === "canvas";
}
