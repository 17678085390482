import { useEffect, useMemo } from "react";

import { useJitsu } from "@jitsu/react";
import { useMatches } from "react-router-dom";

import { CustomRouteHandler } from "@/router/routes";

import { TabId } from "./types";

export const useSelectedTab = () => {
  const { set, unset } = useJitsu();
  const matches = useMatches();
  const tab = useMemo(() => {
    const matchedRoots = matches.flatMap((match) => {
      const handler = match.handle as CustomRouteHandler;
      return handler && handler.root !== undefined
        ? [{ root: handler.root, path: match.pathname }]
        : [];
    });

    let selectedTab: TabId = "instructions";
    // Figure out which tab should be selected, base on the selected root.
    if (matchedRoots.length === 1) {
      const route = matchedRoots[0]!;

      // Sometimes the root doesn't match the tab name, we'll need to
      // go through a round of mapping
      switch (route.root) {
        case "task":
          selectedTab = "instructions";
          break;

        case "class":
          if (route.path.includes("class/progress")) {
            selectedTab = "progress";
          } else if (route.path.includes("class/marking")) {
            selectedTab = "marking";
          } else if (route.path.includes("class/drafts")) {
            selectedTab = "drafts";
          }
          break;

        case "grader":
          if (route.path.includes("grader/moderate")) {
            selectedTab = "moderation";
          }
          break;

        default:
          selectedTab = route.root || "instructions";
      }
      return selectedTab;
    }
  }, [matches]);
  useEffect(() => {
    set({
      current_tab: tab,
    });
    return () => unset("current_tab", {});
  }, [tab, set, unset]);
  return tab;
};
