import { createSelector } from "@reduxjs/toolkit";
import { areIntervalsOverlapping } from "date-fns";
import isEqual from "lodash/isEqual";

import { initialPastAssessmentFilters } from "@/data/templates/templatesSlice";
import { isExam } from "@/ui/task";
import { IPastAssessment } from "@/ui/templates/ConnectedMyAssessments";

import { RootState } from "../store";
import {
  Category,
  IPastAssessmentFilters,
  Subcategory,
  Template,
  TemplateFilter,
  templateFilters,
} from "./types";
import { findSelectedSubcategory } from "./utils";

export const selectCategories = (state: RootState) =>
  state.templates.categories;
export const selectSelectedFilter = (state: RootState): TemplateFilter =>
  state.templates.selectedFilter;

export const selectSelectedSubcategory = createSelector(
  [selectCategories],
  (categories): Subcategory | undefined =>
    findSelectedSubcategory(categories.data)
);

export const selectViewableTemplates = createSelector(
  [selectSelectedSubcategory, selectSelectedFilter],
  (selectedSubcategory, selectedFilter): Template[] => {
    if (!selectedSubcategory) return [];
    if (selectedFilter === "all") {
      return selectedSubcategory.templates;
    }
    return selectedSubcategory.templates.filter((t) =>
      t.matchesFilters.includes(selectedFilter)
    );
  }
);

export const selectSelectedCategory = createSelector(
  [selectCategories],
  (categories): Category | undefined =>
    categories.data.find((c) => c.subcategories.some((sc) => sc.isSelected))
);

export const selectAvailableFilters = createSelector(
  [selectSelectedSubcategory],
  (selectedSubcategory): TemplateFilter[] => {
    if (!selectedSubcategory) return ["all"];
    const availableTemplateFilters: TemplateFilter[] =
      selectedSubcategory.templates.flatMap((t) => t.matchesFilters);
    return templateFilters.reduce(
      (availableFiltersSoFar, currentFilter) => {
        if (availableTemplateFilters.includes(currentFilter)) {
          availableFiltersSoFar.push(currentFilter);
        }
        return availableFiltersSoFar;
      },
      ["all"] as TemplateFilter[]
    );
  }
);

export const selectTemplate = createSelector(
  [selectCategories, (_state, templateId?: string) => templateId],
  (categories, templateId): Template | undefined => {
    if (!templateId) return undefined;
    return categories.data
      .flatMap((c) => c.subcategories)
      .flatMap((sc) => sc.templates)
      .find((t) => t.id === templateId);
  }
);

export const selectAllTemplates = createSelector(
  [selectCategories],
  (categories): Template[] => {
    return categories.data
      .flatMap((c) => c.subcategories)
      .flatMap((sc) => sc.templates);
  }
);

export const selectShowUiSkeletons = ({
  templates: {
    categories: { isLoading, hasLoaded },
  },
}: RootState): boolean => !hasLoaded || isLoading;

export const selectIsPastAssessmentFiltersInitial = ({
  templates: { pastAssessmentFilters },
}: RootState) => {
  return isEqual(pastAssessmentFilters, initialPastAssessmentFilters);
};

export const selectPastAssessmentFilters = ({
  templates: { pastAssessmentFilters },
}: RootState) => pastAssessmentFilters;

export const selectFilterPastAssessments = createSelector(
  [
    selectPastAssessmentFilters,
    (_state: RootState, pastAssessments: IPastAssessment[]) => pastAssessments,
  ],
  (filters, pastAssessments) => {
    return pastAssessments.flatMap((pastAssessment) =>
      matchesAllFilters(pastAssessment, filters) ? [pastAssessment] : []
    );
  }
);

const matchesAllFilters = (
  pastAssessment: IPastAssessment,
  filters: IPastAssessmentFilters
): boolean =>
  matchesByDate(pastAssessment, filters) &&
  matchesByName(pastAssessment, filters) &&
  matchesBySubject(pastAssessment, filters) &&
  matchesByAssessmentType(pastAssessment, filters);

const matchesByDate = (
  pastAssessment: IPastAssessment,
  filters: IPastAssessmentFilters
): boolean => {
  const [fromDueDateRangeValue, toDueDateRangeValue] = filters.dueDateRange
    ? filters.dueDateRange
    : [null, null];

  if (isExam(pastAssessment)) {
    const startDate = pastAssessment.examStartDate;
    const endDate = pastAssessment.examEndDate;

    if (!(startDate && endDate && fromDueDateRangeValue && toDueDateRangeValue))
      return true; // if missing information just return early

    if (
      !areIntervalsOverlapping(
        { start: startDate, end: endDate },
        {
          start: new Date(fromDueDateRangeValue),
          end: new Date(toDueDateRangeValue),
        }
      )
    ) {
      return false;
    }
  } else {
    const assessmentTime = pastAssessment.dueDate.getTime();

    if (fromDueDateRangeValue && assessmentTime < fromDueDateRangeValue) {
      return false;
    }

    if (toDueDateRangeValue && assessmentTime > toDueDateRangeValue) {
      return false;
    }
  }

  return true;
};

const matchesByName = (
  pastAssessment: IPastAssessment,
  filters: IPastAssessmentFilters
): boolean =>
  filters.assessmentNameQuery
    ? pastAssessment.name
        .trim()
        .toLowerCase()
        .includes(filters.assessmentNameQuery.trim().toLowerCase())
    : true;

const matchesBySubject = (
  pastAssessment: IPastAssessment,
  filters: IPastAssessmentFilters
): boolean =>
  filters.subject ? pastAssessment.subject.id === filters.subject.id : true;

const matchesByAssessmentType = (
  pastAssessment: IPastAssessment,
  filters: IPastAssessmentFilters
): boolean => {
  if (!filters.assessmentType) return true;
  if (filters.assessmentType === "all") return true;
  return filters.assessmentType === pastAssessment.assessmentType;
};
