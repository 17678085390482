import { styled } from "styled-components";

import { colors, Text } from "@vericus/cadmus-ui";

export interface NotesDisplayProps {
  notes?: string | null;
}

export const NotesDisplay = ({ notes }: NotesDisplayProps) => {
  return <PaddedText kind="bodySm">{notes}</PaddedText>;
};

const PaddedText = styled(Text)`
  padding: 12px 20px;
  color: ${colors.black100};
  background-color: ${colors.white100};
  overflow: hidden;
  overflow-wrap: break-word;
`;
