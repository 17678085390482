import { CSSProperties, Fragment, LiHTMLAttributes } from "react";
import { styled } from "styled-components";

import {
  CreateSubQuestionIcon,
  FillInBlanksIcon,
  InfoIcon,
  LongAnswerIcon,
  MultipleChoiceIcon,
  ShortAnswerIcon,
  TrueFalseIcon,
} from "@vericus/cadmus-icons";
import { colors, Divider, Pill, Text } from "@vericus/cadmus-ui";

import { useFragment } from "@apollo/client";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";

import { useAppSelector } from "@/data/hooks";
import { RootState } from "@/data/store";
import {
  QuestionType,
  TaskBlockFragment,
  TaskBlockFragmentDoc,
} from "@/generated/graphql";

import {
  selectActiveBlockNodeId,
  selectCanTaskBlockBeDragged,
  selectChildren,
  selectChildrenPointsTotal,
  selectIsDraggingAnotherRootBlock,
  selectIsDraggingAnotherRootBlockChild,
  selectIsDropzoneBeingHovered,
  selectIsLastSubTaskBlock,
  selectIsParentBeingDragged,
  selectTaskBlockBeingDragged,
  selectTaskBlockByNodeId,
  selectTaskBlockDepth,
  selectTaskBlockLabel,
} from "../../task-layout";
import { DragHandle } from "./drag-handle";
import * as styles from "./navigation-item.css";
import { isParentDropzoneId, ParentDropzone } from "./parent-dropzone";

interface ItemProps extends LiHTMLAttributes<HTMLLIElement> {
  /** Primary key of the task block */
  taskBlockId: string;
  /** Tree node ID for the task block item */
  nodeId: string;
  /** Question type associated with the task block item */
  questionType?: QuestionType;
  /** Optionally render the item as a drop line */
  asDropLine?: boolean;
  /** Truthy when the component is being rendered as a drag overlay */
  asDragOverlay?: boolean;
  containerRefSetter?: (node: HTMLElement | null) => void;
  dragHandleRefSetter?: (node: HTMLElement | null) => void;
  points?: number | undefined;
  isFocused?: boolean;
}

/**
 * Render a navigation item for a question.
 * This component is presentational only so that it can be used in drag and drop overlays.
 */
export const Item = ({
  taskBlockId,
  nodeId,
  questionType,
  asDropLine,
  asDragOverlay,
  containerRefSetter,
  dragHandleRefSetter,
  points,
  isFocused,
  ...liProps
}: ItemProps) => {
  const { data } = useFragment<TaskBlockFragment>({
    fragment: TaskBlockFragmentDoc,
    fragmentName: "TaskBlock",
    from: {
      __typename: "TaskBlock",
      id: taskBlockId,
    },
  });

  const label = useAppSelector((state) => selectTaskBlockLabel(state, nodeId));
  const depth = useAppSelector((state) => selectTaskBlockDepth(state, nodeId));
  const isLast = useAppSelector((state) =>
    selectIsLastSubTaskBlock(state, nodeId)
  );
  const canBeDragged = useAppSelector((state) =>
    selectCanTaskBlockBeDragged(state, nodeId)
  );
  const isDropzoneBeingDraggedOver = useAppSelector((state) =>
    selectIsDropzoneBeingHovered(state, nodeId)
  );
  const blockBeingDragged = useAppSelector(selectTaskBlockBeingDragged);
  return (
    <Fragment>
      <li className={styles.container} ref={containerRefSetter} {...liProps}>
        <div
          className={clsx(
            isFocused === true && styles.containerFocused,
            styles.itemsContainer,
            depth > 0 && styles.subquestionMargin,
            asDragOverlay && styles.dragOverlay,
            !blockBeingDragged && styles.containerWithHover,
            isDropzoneBeingDraggedOver && styles.dropzoneBeingHovered
          )}
        >
          {questionType !== QuestionType.Overview && (
            <DragHandle
              ref={dragHandleRefSetter}
              disabled={!canBeDragged}
              noHover={asDragOverlay}
              data-question-type={questionType}
            />
          )}
          {depth > 0 && (
            <QuestionNavigationArrow depth={depth} isLast={isLast} />
          )}
          <QuestionNavigationIcon questionType={questionType} />
          <span className={styles.label}>{label}</span>
          {!(
            questionType === QuestionType.Overview ||
            questionType === QuestionType.Section
          ) && (
            <span className={styles.text}>{data.question?.shortPrompt}</span>
          )}
          {!(
            questionType === QuestionType.Overview ||
            questionType === QuestionType.Section
          ) && (
            <div className={styles.points}>
              <Pill color={colors.bgrey400} basic={true}>
                <Text kind={"systemMd"} textTransform={"lowercase"}>
                  {points ?? 0} points
                </Text>
              </Pill>
            </div>
          )}
        </div>

        {asDropLine && <div className={styles.dropline}></div>}
      </li>
      {questionType === QuestionType.Overview && !asDropLine && (
        <OverviewDivider />
      )}
    </Fragment>
  );
};

interface SortableItemProps {
  nodeId: string;
  questionType?: QuestionType;
  points?: number | undefined;
  isFocused?: boolean;
  onClick?: () => void;
}

/**
 * Adds drag and drop functionality to a question navigation item.
 */
export const SortableItem = (props: SortableItemProps) => {
  const { nodeId, questionType, points, isFocused } = props;
  const block = useAppSelector((state) =>
    selectTaskBlockByNodeId(state, nodeId)
  );
  const canBeDragged = useAppSelector((state) =>
    selectCanTaskBlockBeDragged(state, nodeId)
  );
  const {
    active,
    setNodeRef,
    setActivatorNodeRef,
    isDragging,
    transform,
    transition,
    attributes,
    listeners,
    over,
  } = useSortable({
    id: nodeId,
    disabled: !canBeDragged,
  });
  const isParentBeingDragged = useAppSelector((state) =>
    selectIsParentBeingDragged(state, nodeId)
  );
  const isRootBlockBeingDragged = useAppSelector((state) =>
    selectIsDraggingAnotherRootBlock(state, nodeId)
  );
  const isAChild = Boolean(block?.parentNodeId);

  const isAnotherBlocksChildBeingDragged = useAppSelector((state) =>
    selectIsDraggingAnotherRootBlockChild(state, nodeId)
  );

  const shouldHaveLessOpacity =
    (isAChild && isRootBlockBeingDragged) ||
    (!isAChild && isAnotherBlocksChildBeingDragged) ||
    isDragging;

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: shouldHaveLessOpacity ? 0.3 : undefined,
  };
  const isOverDropzone =
    active?.id === nodeId && over && isParentDropzoneId(over.id.toString());

  if (!block) return null;
  if (isParentBeingDragged || isOverDropzone) return null;
  return (
    <Item
      taskBlockId={block.id}
      nodeId={nodeId}
      questionType={questionType}
      containerRefSetter={setNodeRef}
      dragHandleRefSetter={setActivatorNodeRef}
      style={style}
      asDropLine={isDragging}
      {...attributes}
      {...listeners}
      points={points}
      isFocused={isFocused}
      onClick={props.onClick}
    />
  );
};

interface ParentItemProps {
  nodeId: string;
  isFocused?: boolean;
  scrollOnClick?: (
    taskBlockId: string,
    nodeId: string,
    isSubParent?: boolean
  ) => void;
}
/**
 * Add functionality to a sortable item with children so that its child list
 * can be reordered too.
 */
export const SortableParentItem = ({
  nodeId,
  isFocused,
  scrollOnClick,
}: ParentItemProps) => {
  const block = useAppSelector((state) =>
    selectTaskBlockByNodeId(state, nodeId)
  );
  const childBlocks = useAppSelector((state) => selectChildren(state, nodeId));
  const parentQuestionPoints = useAppSelector((state: RootState) =>
    selectChildrenPointsTotal(state, nodeId)
  );
  const activeNodeId = useAppSelector(selectActiveBlockNodeId);

  const {
    setNodeRef,
    setActivatorNodeRef,
    isDragging,
    transform,
    transition,
    attributes,
    listeners,
  } = useSortable({ id: nodeId });

  if (!block) return null;

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // TODO: I think changing the way these refs are being set may fix the way a
  //  parent and its items move when root-level questions are dragged
  return (
    <div ref={setNodeRef}>
      <Item
        taskBlockId={block.id}
        nodeId={nodeId}
        questionType={block.questionType}
        dragHandleRefSetter={setActivatorNodeRef}
        style={style}
        asDropLine={isDragging}
        points={parentQuestionPoints}
        isFocused={isFocused}
        {...attributes}
        {...listeners}
        onClick={() => scrollOnClick?.(block.id, block.nodeId, true)}
      />
      <SortableContext
        items={childBlocks.map((blk) => blk.nodeId)}
        strategy={verticalListSortingStrategy}
      >
        {childBlocks.map((childBlock) => (
          <SortableItem
            key={childBlock.nodeId}
            nodeId={childBlock.nodeId}
            questionType={childBlock.questionType}
            isFocused={childBlock.nodeId === activeNodeId}
            points={childBlock.points ?? undefined}
            onClick={() => scrollOnClick?.(childBlock.id, childBlock.nodeId)}
          />
        ))}
      </SortableContext>
      <ParentDropzone parentNodeId={nodeId} />
    </div>
  );
};

export const QuestionNavigationArrow = ({
  depth,
  isLast,
}: {
  depth: number;
  isLast: boolean;
}) => {
  return (
    <span className={styles.arrowContainer}>
      {[...Array(depth).keys()].map((k) => {
        if (k === depth - 1) {
          return (
            <Fragment key={k}>
              {!isLast && <span className={styles.arrowVertical} />}
              <span className={styles.arrowPointer} />
            </Fragment>
          );
        } else {
          return <span key={k} className={styles.arrowVertical} />;
        }
      })}
    </span>
  );
};

export const QuestionNavigationIcon = ({
  questionType,
}: {
  questionType?: QuestionType;
}) => {
  let Icon;
  switch (questionType) {
    case QuestionType.Mcq: {
      Icon = <MultipleChoiceIcon label="Multiple Choice" size={24} />;
      break;
    }
    case QuestionType.Sub: {
      Icon = <CreateSubQuestionIcon label="Sub question" size={24} />;
      break;
    }
    case QuestionType.Short: {
      Icon = <ShortAnswerIcon label="Short answer" size={24} />;
      break;
    }
    case QuestionType.Extended: {
      Icon = <LongAnswerIcon label="Extended response" size={24} />;
      break;
    }
    case QuestionType.Overview: {
      Icon = <InfoIcon label="Assessment overview" size={24} />;
      break;
    }
    case QuestionType.Truefalse: {
      Icon = <TrueFalseIcon label="True/false" size={24} />;
      break;
    }
    case QuestionType.Blanks: {
      Icon = <FillInBlanksIcon label="Fill in blanks" size={24} />;
      break;
    }
    default: {
      Icon = null;
    }
  }

  return <>{Icon && <IconWrapper>{Icon}</IconWrapper>}</>;
};

const OverviewDivider = styled(Divider)`
  margin: 8px 0px;
  width: 90%;
  align-self: center;
`;

const IconWrapper = styled.span`
  display: flex;
`;
