import { Text } from "@vericus/cadmus-ui";

import { useDroppable } from "@dnd-kit/core";
import clsx from "clsx";

import { useAppSelector } from "@/data/hooks";
import { QuestionType } from "@/generated/graphql";

import {
  selectIsDraggingAnotherRootBlock,
  selectIsDraggingSibling,
  selectTaskBlockBeingDragged,
  TaskLayoutBlock,
} from "../../task-layout";
import * as styles from "./parent-dropzone.css";

const DROPZONE_ID_PREFIX = "PARENT-DROPZONE";

/**
 * @returns whether the droppable ID is a parent dropzone's
 **/
export const isParentDropzoneId = (id: string) =>
  id.includes(DROPZONE_ID_PREFIX);

interface ParentDropzoneProps {
  /** The node ID for the parent in which this dropzone is under */
  parentNodeId: string;
}

/**
 * Data available in the drag and drop Context for this dropzone.
 */
export interface DropzoneDndData {
  /** The node ID for the parent in which this dropzone is under */
  parentNodeId: string;
}

/**
 * Provides a place for root level questions to become children or for children
 * to become root level questions.
 */
export const ParentDropzone = ({ parentNodeId }: ParentDropzoneProps) => {
  const dropzoneId = `${DROPZONE_ID_PREFIX}-PARENT-NODE-ID-${parentNodeId}`;
  const { setNodeRef, over } = useDroppable({
    id: dropzoneId,
    data: {
      parentNodeId,
    } satisfies DropzoneDndData,
  });
  const isDraggingRootBlock = useAppSelector((state) =>
    selectIsDraggingAnotherRootBlock(state, parentNodeId)
  );
  const isDraggingSibling = useAppSelector((state) =>
    selectIsDraggingSibling(state, parentNodeId)
  );
  const blockBeingDragged = useAppSelector(selectTaskBlockBeingDragged);
  const canDraggedBlockBecomeAChild =
    blockBeingDragged && canBlockBecomeAChild(blockBeingDragged);
  if (
    !(isDraggingRootBlock || isDraggingSibling) ||
    !canDraggedBlockBecomeAChild
  )
    return null;
  const isOverDropzone = over?.id === dropzoneId;

  if (
    !(isDraggingRootBlock || isDraggingSibling) ||
    !canDraggedBlockBecomeAChild
  )
    return null;

  return (
    <div
      ref={setNodeRef}
      className={clsx(
        isDraggingRootBlock && [
          isOverDropzone
            ? styles.addChildVariants.hovering
            : styles.addChildVariants.atRest,
        ],
        isDraggingSibling && [
          isOverDropzone
            ? styles.moveOutChildVariants.hovering
            : styles.moveOutChildVariants.atRest,
        ]
      )}
    >
      <Text kind="headingSix" color="navy400" textAlign="center">
        {isDraggingRootBlock
          ? "Drop here to make a subquestion"
          : "Drop here to move out of subquestion"}
      </Text>
    </div>
  );
};

function canBlockBecomeAChild(block: TaskLayoutBlock): boolean {
  const nonChildQuestionTypes = [
    QuestionType.Sub,
    QuestionType.Section,
    QuestionType.Overview,
  ];
  return (
    !!block.questionType && !nonChildQuestionTypes.includes(block.questionType)
  );
}
