import { useModerationScoreContext } from "../../context/moderation-score-context";

export type EnrollmentSubmitLateByMap = {
  [enrollmentId: string]: number | null;
};

/**
 * Hook to use map of late by due date in minutes of each
 * enrollment id
 */
export const useEnrollmentSubmitLateBy = (): EnrollmentSubmitLateByMap => {
  const moderationScoreContext = useModerationScoreContext();
  const enrollmentSubmitLateBy: EnrollmentSubmitLateByMap = {};

  moderationScoreContext.table
    .getRowModel()
    .rows.forEach(
      (row) =>
        (enrollmentSubmitLateBy[row.original.enrollment.id] =
          row.original.final?.minutesLateBy ?? null)
    );

  return enrollmentSubmitLateBy;
};
