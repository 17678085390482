import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@/data/store";

export const selectMark = (state: RootState) => state.mark;

export const selectModerate = (state: RootState) => state.moderate;

export const selectFilteredQuestionIds = (state: RootState) =>
  state.moderate.filteredQuestionIds;

export const selectQuestionSortingState = (state: RootState) =>
  state.moderate.sortingState;

export const selectFilterState = (state: RootState) =>
  state.moderate.filterState;

export const selectEditingFeedbackReleaseRuleId = createSelector(
  selectModerate,
  (moderateState) => moderateState.editingFeedbackReleaseRuleId
);

export const selectEditingPenaltyRuleId = createSelector(
  selectModerate,
  (moderateState) => moderateState.editingPenaltyRuleId
);
