import { useCallback, useState } from "react";

import {
  ActionInput,
  Divider,
  Popover,
  SearchList,
  SearchListItem,
  SelectButton,
  Text,
} from "@vericus/cadmus-ui";

import * as styles from "./category-search-select.css";

export interface CategorySearchSelectProps {
  /**
   * All the available items to display and that are searchable
   */
  items: SearchListItem[];

  /**
   * The placeholder text for when there is no search query inputted yet.
   */
  placeholder?: string;

  /**
   * Callback function for when an item is selected from the menu list.
   * @param {SearchListItem} searchListItem the item that was just selected.
   */
  onSelect?: (selectedItem: string) => void;

  /**
   * Callback function for when an intent to add a new categroy is signaled.
   * @param {string} addedItem the input field value from when the action was emitted.
   */
  onAdd?: (addedItem: string) => void;

  /**
   * The current item to be shown as selected.
   */
  selectedItems?: string[];
}

export const CategorySearchSelect = (props: CategorySearchSelectProps) => {
  const { items, onSelect, onAdd, selectedItems } = props;
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [newCategoryInput, setNewCategoryInput] = useState<string>("");
  const onSelectItem = useCallback(
    (searchListItem: SearchListItem) => {
      onSelect?.(searchListItem.value);
      setOpen(false);
    },
    [onSelect, setOpen]
  );
  const addCategory = useCallback(
    (category: string) => {
      onAdd?.(category);
      onSelect?.(category);
      setNewCategoryInput("");
    },
    [onAdd, onSelect, setNewCategoryInput]
  );

  const error = items.map((item) => item.label).includes(newCategoryInput)
    ? "Category name already exists"
    : undefined;

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <SelectButton onClick={(e) => e.stopPropagation()}>
          {selectedItems?.length == 1
            ? selectedItems[0]
            : selectedItems?.length
              ? "Multiple"
              : "Assign Category"}
        </SelectButton>
      </Popover.Trigger>
      <Popover.Content>
        <Popover.Card onClick={(e) => e.stopPropagation()}>
          <div className={styles.searchListContainer}>
            {items.length > 0 && (
              <SearchList
                items={items}
                selectedItems={props.selectedItems}
                placeholder="Search Category"
                queryValue={searchQuery}
                onSelect={onSelectItem}
                onQueryValueChange={setSearchQuery}
              />
            )}
            {items.length === 0 && (
              <div className={styles.emptyCategory}>
                <Text kind="bodySm" color="grey500">
                  {"You don't have any category set up yet. "}
                </Text>
              </div>
            )}
          </div>
          <Divider />
          <div className={styles.actionInputContainer} data-error={!!error}>
            <ActionInput
              className={styles.actionInput}
              value={newCategoryInput}
              onChange={setNewCategoryInput}
              iconName="Plus"
              placeholder="Create New Category"
              onAction={(newCategory) => {
                addCategory(newCategory);
              }}
              onKeyDown={(e) => {
                if (
                  e.code === "Enter" &&
                  !error &&
                  newCategoryInput.trim().length !== 0
                ) {
                  addCategory(newCategoryInput);
                }
              }}
              actionName="Create"
              error={error}
              disabled={!!error || newCategoryInput.trim().length === 0}
            />
          </div>
        </Popover.Card>
      </Popover.Content>
    </Popover.Root>
  );
};
