import { colors, Pill, Spacer, Text, Tooltip } from "@vericus/cadmus-ui";

import { format } from "date-fns";

import { EnrollmentFragment, ExamTiming } from "@/generated/graphql";
import { ExamSettings } from "@/graphql/types/EnrollmentSettings";
import { formatFullSentence } from "@/utils/datetime";

// Props for SpecialConsiderationPill
interface Props {
  /**
   * The enrollment for this pill
   */
  enrollment: EnrollmentFragment;

  /**
   * The exam settings (instruction sheet) for this exam
   */
  examSettings: ExamSettings;

  anonymised?: boolean;
}

const DateFormat = "d MMMM yyyy, h:mm aa";

/**
 * A pill that displays the special consideration status of an enrollment
 * through a tooltip via mouse hover.
 */
export const SpecialConsiderationPill = (props: Props) => {
  const label = (
    <Text kind="bodySm" as="div">
      <TooltipContent {...props} />
    </Text>
  );
  return (
    <Tooltip content={label}>
      <Pill color={colors.white200}>Special Con.</Pill>
    </Tooltip>
  );
};

const TooltipContent = (props: Props) => {
  const { examSettings, enrollment, anonymised } = props;

  const messageAttributes = [
    examSettings.examTiming === ExamTiming.Live ? "live" : "window",
  ];
  if (examSettings?.alternateExamStartDate) {
    messageAttributes.push("startDate");
  }
  if (
    examSettings.examTiming === ExamTiming.Window &&
    examSettings?.alternateExamEndDate
  ) {
    messageAttributes.push("endDate");
  }
  if (examSettings?.extraExamWritingTime) {
    messageAttributes.push("writing");
  }
  if (examSettings?.extraExamReadingTime) {
    messageAttributes.push("reading");
  }
  const messageKey = messageAttributes.join(".");

  const name = anonymised ? "Student" : enrollment.user.givenName;
  const writingTime = examSettings.extraExamWritingTime ?? 0;
  const readingTime = examSettings.extraExamReadingTime ?? 0;
  const totalTime =
    (examSettings.readingTime ?? 0) + (examSettings.writingTime ?? 0);
  const startDate =
    examSettings.alternateExamStartDate ??
    examSettings.sheetExamStartDate ??
    undefined;
  const endDate =
    examSettings.alternateExamEndDate ??
    examSettings.sheetExamEndDate ??
    undefined;

  switch (messageKey) {
    case "window.writing":
    case "live.writing":
      return (
        <>
          <>
            {name} has {writingTime} mins of extra writing time.{" "}
          </>
          <Spacer spacing={8} />
          <>
            Their total exam duration is <strong>{totalTime} mins.</strong>
          </>
        </>
      );
    case "window.reading":
    case "live.reading":
      return (
        <>
          <>
            {name} has {readingTime} mins of extra reading time.
          </>
          <Spacer spacing={8} />
          <>
            Their total exam duration is <strong>{totalTime} mins.</strong>
          </>
        </>
      );
    case "window.writing.reading":
    case "live.writing.reading":
      return (
        <>
          <>
            {name} has {readingTime} mins of extra reading time + {writingTime}{" "}
            mins of extra writing time.
          </>
          <Spacer spacing={8} />
          <>
            Their total exam duration is <strong>{totalTime} mins.</strong>
          </>
        </>
      );
    case "live.startDate":
      return (
        <>
          <>
            {name} has an <strong>alternative exam start</strong> on{" "}
            {startDate && format(startDate, DateFormat)}.
          </>
        </>
      );
    case "live.startDate.writing":
      return (
        <>
          <>
            {name} has an <strong>alternative exam start</strong> on{" "}
            {startDate && format(startDate, DateFormat)} and{" "}
            <strong>{writingTime} mins</strong> of extra writing time.
          </>
          <Spacer spacing={8} />
          <>
            Their total exam duration is <strong>{totalTime} mins.</strong>
          </>
        </>
      );
    case "live.startDate.reading":
      return (
        <>
          <>
            {name} has an <strong>alternative exam start</strong> on{" "}
            {startDate && format(startDate, DateFormat)} and{" "}
            <strong>{readingTime} mins</strong> of extra reading time.
          </>
          <Spacer spacing={8} />
          <>
            Their total exam duration is <strong>{totalTime} mins.</strong>
          </>
        </>
      );
    case "live.startDate.writing.reading":
      return (
        <>
          <>
            {name} has an <strong>alternative exam start</strong> on{" "}
            {startDate && format(startDate, DateFormat)} and{" "}
            <strong>{readingTime} mins</strong> of extra reading time +{" "}
            <strong>{writingTime} mins</strong> of extra writing time.
          </>
          <Spacer spacing={8} />
          <>
            Their total exam duration is <strong>{totalTime} mins.</strong>
          </>
        </>
      );
    case "window.startDate":
    case "window.endDate":
    case "window.startDate.endDate":
      return (
        <>
          {name} has an <strong>alternative exam window</strong> from{" "}
          {startDate && format(startDate, DateFormat)} -{" "}
          {endDate && formatFullSentence(endDate)}
        </>
      );
    case "window.startDate.writing":
    case "window.endDate.writing":
    case "window.startDate.endDate.writing":
      return (
        <>
          <>
            {name} has an <strong>alternative exam window</strong> from{" "}
            {startDate && format(startDate, DateFormat)} -{" "}
            {endDate && formatFullSentence(endDate)} and{" "}
            <strong>{writingTime} mins</strong> of extra writing time.
          </>
          <Spacer spacing={8} />
          <>
            Their total exam duration is <strong>{totalTime} mins.</strong>
          </>
        </>
      );
    case "window.startDate.reading":
    case "window.endDate.reading":
    case "window.startDate.endDate.reading":
      return (
        <>
          <>
            {name} has an <strong>alternative exam window</strong> from{" "}
            {startDate && format(startDate, DateFormat)} -{" "}
            {endDate && formatFullSentence(endDate)} and{" "}
            <strong>{readingTime} mins</strong> of extra reading time.
          </>
          <Spacer spacing={8} />
          <>
            Their total exam duration is <strong>{totalTime} mins.</strong>
          </>
        </>
      );
    case "window.startDate.writing.reading":
    case "window.endDate.writing.reading":
    case "window.startDate.endDate.writing.reading":
      return (
        <>
          <>
            {name} has an <strong>alternative exam window</strong> from{" "}
            {startDate && format(startDate, DateFormat)} -{" "}
            {endDate && formatFullSentence(endDate)} and{" "}
            <strong>{readingTime} mins</strong> of extra reading time +{" "}
            <strong>{writingTime} mins</strong> of extra writing time.
          </>
          <Spacer spacing={8} />
          <>
            Their total exam duration is <strong>{totalTime} mins.</strong>
          </>
        </>
      );
    default:
      return <>{name} has special considerations for this exam.</>;
  }
};
