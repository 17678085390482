import {
  EnrollmentFragment,
  GroupFragment,
  InstructionSheetFragment,
  SubmissionType,
  Tag,
} from "generated/graphql";

import { WorkOutcomeSummary } from "@/features/moderation/types";
import { EnrollmentSettings } from "@/graphql/types/EnrollmentSettings";
import { SubmissionDetails } from "@/graphql/types/SubmissionDetails";
import { ExtensionType } from "@/ui/class/progress/pages/StudentsSettingsPage";
import { RevealScope } from "@/ui/integrity-assurance/RevealStudentConfirmationModal";

import { TagConfig } from "../../StudentLists/TagSelector";

/**
 * Named stage at which the student is currently at. The stages are ordered.
 */
export enum ProgressStage {
  Enrolled = 0,
  Accessed = 1,
  Started = 2,
  Draft = 3,
  Final = 4,
  FeedbackViewed = 5,
}

type Enrollment = EnrollmentFragment;

/**
 * The visibile tabs on the Class List. Lists are grouped by these sections.
 */
export enum ClassTab {
  // Common tabs
  Students = "students", // or Progress tab

  // Releveant for AssessmentType.Assignment
  Drafts = "drafts",
  Finals = "finals",

  // Releveant for AssessmentType.Exam
  Submissions = "submissions",
}

/**
 * Normalised enrollment data model behind each row in the Class List across all
 * tables.
 */
export interface StudentListRow {
  /** Has the enrollment been withdrawn? */
  withdrawn?: boolean;
  withdrawnBy?: string | null;
  /** Has the student got a deferred special consideration. */
  deferred?: boolean;
  /** Unique enrollment id. */
  id: string;
  /** Which tab the row is rendered in */
  tab: ClassTab;
  /** Numeric identifier assigned to the student for anonymous marking. */
  anonymousIndex: number;
  /** Has the student withdrawn the enrollment?  */
  deleted: boolean;
  /** Student last name for filtering. */
  lastName: string;
  /** Student full name for filtering */
  fullName: string;
  /**
   * Name displayed on the class list, can be the `fullName` or anonymised.
   */
  displayName: string;
  /**
   * Email displayed on the class list, can be `null` if email is hidden.
   */
  displayEmail: string | null;
  /**
   * Student first name for display
   */
  displayFirstName: string;
  /**
   * Student SIS ID to display
   */
  displaySisId: string | null;
  /** Should student details be anonymised? */
  anonymous: boolean;
  /** Student email address. */
  email: string | null;
  /** Student work ID if they have started working. */
  workId: string | null;
  /** Latest progress stage */
  progress: ProgressStage;
  /** Flags expanding on the student progress stage. */
  flags: ProgressFlags;
  /** Marking group that the student has been assigned to. */
  group: GroupDetails | null;
  /** DRAFT submission */
  draft: SubmissionDetails | null;
  /** FINAL submission */
  final: SubmissionDetails | null;
  /** Enrollment specific settings */
  settings: EnrollmentSettings;
  /** Contextual and sorted tags assigned to the user */
  tags: Tag[];
  /** Access Code Label */
  accessCodeLabel?: string | null;
  /** Last activity timestamp for the enrollment */
  lastActivity: Date | null;
  /** Underlying enrollment information */
  enrollment: Enrollment;
  /** WorkOutcome summary for the moderation */
  workOutcomeSummary?: WorkOutcomeSummary;
}

/**
 * Status flags reflecting the current status of the student's progress in the
 * assessment.
 *
 * These flags are easy to filter upon.
 */
export interface ProgressFlags {
  accessed: boolean;
  started: boolean;
  draft: boolean;
  lateDraft: boolean;
  final: boolean;
  lateFinal: boolean;
  overtimeFinal: boolean;
  extension: boolean;
  graded: boolean;
  feedbackViews: number;
  withdrawn: boolean;
  forceSubmitted: boolean;
  hasExtraTime: boolean;
  hasAlternativeStart: boolean;
  hasAlternativeWindow: boolean;
  deferred: boolean;
  accessCode: boolean;
}

/**
 * Normalised information on a marking `Group`.
 */
export interface GroupDetails {
  id: string;
  name: string;
}

/** Filter query for a value to be in a given range. */
export type RangeFilter = {
  /** Reference label for the filter */
  label: string;
  /** Inclusive lower bound */
  lower: number;
  /** Inclusive upper bound */
  upper: number;
  /** Optionally override range matching with a direct value match. */
  match?: unknown;
};

/** Filter query for a value to be in a given range for Late By. */
export type LateByFilterValue = {
  /** Number for how many days or hours or minutes */
  number: number;
  /** Unit for how many days or hours or minutes */
  unit: "day" | "hr" | "min";
  /** Reference label for the filter */
  displayUnit: "Days" | "Hrs" | "Mins";
};

// Table filter value modified by the column with a predicate filter.
export type FilterValue = PredicateFilter[] | undefined;

/** Filter query for a value on which a `predicate` is true. */
export type PredicateFilter = {
  /** Reference label for the filter */
  label: string;
  /** Predicate on the value */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  predicate: (value: any) => boolean;
  /**
   * True when the filter is displayed to the user but it's not
   * available to be used.
   */
  disabled?: boolean;
};

/**
 * Copies a filter to say it's disabled.
 */
export function disableFilter(
  filter: PredicateFilter,
  disabled?: boolean
): PredicateFilter {
  return { ...filter, disabled };
}

/**
 * Various levels of similarity score values.
 */
export enum TiiLabel {
  Blue = "blue",
  Green = "green",
  Yellow = "yellow",
  Orange = "orange",
  Red = "red",
}

/**
 * Contains functions which mutate tags and used in the tables Meta data.
 */
export interface TagHandlers {
  /** Add a `tag` to a list of selected `enrollments`. */
  addTag: (enrollments: Enrollment[], text: string, tab: ClassTab) => void;
  /** Remove a `tag` from the list of selected `enrollments`. */
  removeTag: (enrollments: Enrollment[], text: string, tab: ClassTab) => void;
  /** Delete a custom `tag` from all `enrollments`. */
  deleteCustomTag: (text: string, tab: ClassTab) => void;
}

/**
 * Metadata that is attached to the student list table instance, available to all
 * rows.
 */
export interface StudentListMeta extends TagHandlers {
  /** Currently released Instruction Sheet. */
  sheet?: InstructionSheetFragment;
  /** All known groups in the Class. */
  groups: GroupFragment[];
  /** Configurations for all known pre-defined and custom tags. */
  tagConfigs: TagConfig[];
  /** Trigger contact action for a list of student emails. */
  contactStudents: (emails: string[]) => void;
  /** Edit extensions in bulk for the given enrollments. */
  editExtensions: (
    enrollments: Enrollment[],
    type: ExtensionType,
    selectedAll?: boolean
  ) => void;

  updateGroupMemberships: (
    enrollments: Enrollment[],
    groupName: string
  ) => void;
  updateEnrollmentStatus: (
    enrollments: Enrollment[],
    withdrawn: boolean
  ) => void;
  /** Download a single submission of a certain type for an enrollment. */
  downloadSubmission: (enrollment: Enrollment, type: SubmissionType) => void;
  /** Bulk download submissions of a certain type in the enrollments. */
  downloadSubmissions: (
    enrollments: Enrollment[],
    type: SubmissionType
  ) => void;
  /**
   * Bulk force final submission for multiple enrollments.
   *
   * The callback won't be provided if force submissions is not available for
   * the assessment state.
   */
  forceSubmissions?: (enrollments: Enrollment[]) => void;
  /** Download the Grades CSV for the assessment */
  downloadGrades: () => void;
  /** Bulk clear extensions on a list of enrollments. */
  clearExtensions: (enrollment: Enrollment[], ty: ExtensionType) => void;
  /**
   * Generate and redirect to the student activity report.
   *
   * Optionally use anonymous student name across the report.
   */
  viewActivityReport: (enrollment: Enrollment, anonymousName?: string) => void;
  /** Generate and redirect to the student activity report. */
  viewAssessmentReport: () => void;
  /** Trigger roster sync */
  syncClassList: () => void;
  /** Mark enrollments as revealable on an anonymous submission list. */
  revealEnrollment: (enrollmentId: string) => void;
  /** Mark enrollments as revealable on an anonymous submission list.
   * Requires a scope to show the correct messaging to the user */
  revealEnrollments: (enrollmentIds: string[], scope: RevealScope) => void;
  /** Mark enrollments has anonymous if anonymous marking is enabled. */
  hideEnrollments: (enrollmentIds: string[]) => void;
}
