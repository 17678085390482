import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TaskBlockError, TaskValidation } from "./types";

// const initialState
export const initialState: TaskValidation = { taskErrors: {} };

export const taskValidationSlice = createSlice({
  name: "taskValidation",
  initialState,
  reducers: {
    setTaskBlockError(
      state,
      action: PayloadAction<{ taskBlockId: string; error: TaskBlockError }>
    ) {
      state.taskErrors[action.payload.taskBlockId] = action.payload.error;
    },
    clearTaskBlockError(state, action: PayloadAction<{ taskBlockId: string }>) {
      if (!state.taskErrors[action.payload.taskBlockId]) return;
      delete state.taskErrors[action.payload.taskBlockId];
    },
  },
});

export const { setTaskBlockError, clearTaskBlockError } =
  taskValidationSlice.actions;

export const taskValidationReducer = taskValidationSlice.reducer;
