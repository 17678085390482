import { ChangeEvent, useCallback, useState } from "react";
import { styled } from "styled-components";

import { Input, Spacer, Text } from "@vericus/cadmus-ui";

import { TaskFormat } from "@/generated/graphql";

import { ReferencesSelector } from "../AssignmentSettings/ReferencingStyleSetting";
import { SettingInfoCard } from "../SettingInfoCard";
import { FadeIn, SettingContainer } from "../styles";
import { useSettingsFormContext } from "../use-settings-form";
import { ExamSubmissionAdvanceSetting } from "./ExamSubmissionAdvanceSetting";
import { StyledLinkButton } from "./shared";

/**
 * Allow teachers to edit settings for:
 * - word limit
 * - referencing
 * - auto submission
 * - sending PDF copies of the submissions
 */

interface ExamSubmissionSettingProps {
  format?: TaskFormat;
  footnoteReferencingEnabled: boolean;
}

export const ExamSubmissionSetting = (props: ExamSubmissionSettingProps) => {
  const settingsForm = useSettingsFormContext();
  const {
    enableExamAutoSubmission,
    enableSubmissionPdf,
    lateSubmissionTimeLimit,
    noLimit,
    noReferencing,
    referencingStyle,
    wordLimit,
  } = settingsForm.values;

  const isReferencingExpanded = !noReferencing;
  const isWordLimitExpanded = !noLimit;

  const [localWordLimit, setLocalWordLimit] = useState(wordLimit);
  const onBlurWordLimitValidation = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const validatedValue = validateInteger(e.currentTarget.value, wordLimit);
      setLocalWordLimit(validatedValue);
      settingsForm.setFieldValue("wordLimit", validatedValue);
    },
    [wordLimit, settingsForm]
  );

  const [localLateSubmissionTimeLimit, setLocalLateSubmissionTimeLimit] =
    useState(lateSubmissionTimeLimit);
  const onBlurLocalLateSubmissionTimeLimitValidation = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const validatedValue = validateInteger(
        e.currentTarget.value,
        lateSubmissionTimeLimit,
        true
      );
      setLocalLateSubmissionTimeLimit(validatedValue);
      settingsForm.setFieldValue("lateSubmissionTimeLimit", validatedValue);
    },
    [lateSubmissionTimeLimit, settingsForm]
  );

  return (
    <SettingContainer>
      <Text kind="headingOne">Submissions</Text>
      {props.format === TaskFormat.Classic && (
        <>
          <Spacer spacing={60} />

          {/* Word limit */}
          <Text kind="headingFive">
            Word limit is{" "}
            <StyledLinkButton
              inline
              aria-label={`Word limit is ${isWordLimitExpanded ? "on" : "off"}`}
              onClick={() => settingsForm.setFieldValue("noLimit", !noLimit)}
            >
              {isWordLimitExpanded ? "on" : "off"}
            </StyledLinkButton>
          </Text>
          {isWordLimitExpanded && (
            <FadeIn>
              <Text kind="bodySm">
                The exam will have a word limit of{" "}
                <Input
                  type="number"
                  aria-label="Word limit"
                  min={0}
                  value={`${localWordLimit}`}
                  onChange={(e) =>
                    setLocalWordLimit(parseInt(e.currentTarget.value))
                  }
                  onBlur={onBlurWordLimitValidation}
                />{" "}
                words
              </Text>
            </FadeIn>
          )}

          <Spacer spacing={60} />

          {/* Referencing */}
          <Text kind="headingFive">
            Referencing is{" "}
            <StyledLinkButton
              inline
              aria-label={`Referencing is ${
                isReferencingExpanded ? "on" : "off"
              }`}
              onClick={() =>
                settingsForm.setFieldValue("noReferencing", !noReferencing)
              }
            >
              {isReferencingExpanded ? "on" : "off"}
            </StyledLinkButton>
          </Text>
          {isReferencingExpanded && (
            <FadeIn>
              <ReferencesSelector
                setReferencingStyle={(newReferencingStyle) =>
                  settingsForm.setFieldValue(
                    "referencingStyle",
                    newReferencingStyle
                  )
                }
                referencingStyle={referencingStyle}
                footnoteReferencingEnabled={props.footnoteReferencingEnabled}
              />
            </FadeIn>
          )}
        </>
      )}

      <Spacer spacing={60} />

      {/* Auto submissions  */}
      <Text kind="headingFive">
        Auto submission is{" "}
        <StyledLinkButton
          inline
          aria-label={`Auto submission is ${
            enableExamAutoSubmission ? "on" : "off"
          }`}
          color={enableExamAutoSubmission ? "lilac500" : undefined}
          onClick={() =>
            settingsForm.setFieldValue(
              "enableExamAutoSubmission",
              !enableExamAutoSubmission
            )
          }
        >
          {enableExamAutoSubmission ? "on" : "off"}
        </StyledLinkButton>
      </Text>
      <Spacer spacing={16} />
      {enableExamAutoSubmission ? (
        <SettingInfoCard>
          {`At the end of the exam, students' work will be auto submitted. Without `}
          auto submission, students will be prompted to submit their work once
          writing time is up.
        </SettingInfoCard>
      ) : (
        <FadeIn>
          <Text kind="bodySm">
            Students are allowed to submit late up to{" "}
            <StyledInput
              type="number"
              aria-label="Late submission time limit (in minutes)"
              placeholder="unlimited"
              value={`${localLateSubmissionTimeLimit}`}
              min={0}
              onChange={(e) =>
                setLocalLateSubmissionTimeLimit(parseInt(e.currentTarget.value))
              }
              onBlur={onBlurLocalLateSubmissionTimeLimitValidation}
            />{" "}
            minutes after the end time.
            <br />
            After this time, the exam will be closed and they will not be able
            to make a submission.
          </Text>
        </FadeIn>
      )}

      <Spacer spacing={60} />

      {/* PDF copies  */}
      <Text kind="headingFive">
        PDF copy of student submission is{" "}
        <StyledLinkButton
          inline
          aria-label={`PDF copy of student submission is ${
            enableSubmissionPdf ? "on" : "off"
          }`}
          color={enableSubmissionPdf ? "lilac500" : undefined}
          onClick={() =>
            settingsForm.setFieldValue(
              "enableSubmissionPdf",
              !enableSubmissionPdf
            )
          }
        >
          {enableSubmissionPdf ? "on" : "off"}
        </StyledLinkButton>
      </Text>
      <Text kind="bodySm">
        {enableSubmissionPdf
          ? "Students will receive an email confirming they have submitted successfully, with their written submission attached."
          : "Students will receive an email confirming they have submitted successfully, but will not receive a copy of their written submission."}
      </Text>
      <Spacer spacing={60} />
      <ExamSubmissionAdvanceSetting />
    </SettingContainer>
  );
};

const StyledInput = styled(Input)`
  span& {
    min-width: 95px;
    width: 95px;
  }
  input {
    min-width: 95px;
    width: 95px;
  }
`;

/**
 * Given a string value, e.g. from an <input> it returns a valid integer.
 * @returns a valid integer in the range [1, inf]
 */
const validateInteger = (
  value: string,
  fallback: number | null,
  canBeEmpty = false
): number | null => {
  const parsedValue = parseInt(value);
  let validValue = fallback;
  if (canBeEmpty && Number.isNaN(parsedValue)) {
    validValue = null;
  } else if (parsedValue >= 1) {
    validValue = parsedValue;
  } else if (parsedValue < 1) {
    validValue = 1;
  }
  return validValue;
};
