import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  makeVar,
  Operation,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getDirectiveNames, getMainDefinition } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";
import {
  __GLOBAL_ASSESSMENT_ID,
  __GLOBAL_AUTH_TOKEN,
  __GLOBAL_TENANT,
} from "client/globals";
import { connection } from "client/phoenix";
import { TypedTypePolicies } from "generated/apollo-helpers";
import { createClient } from "graphql-ws";
import { delayObservable } from "utils/delayObservable";

import * as config from "@/config";

// Log GraphQL errors (network and resolver errors) to sentry
function logError(type: string, operation: Operation, errors: unknown) {
  if (config.DEV) {
    console.error(type, operation.operationName, operation.variables, errors);
  } else {
    Sentry.captureException(
      `GraphQL ${type} error in operation ${operation.operationName}`,
      {
        extra: {
          errors,
          operationName: operation.operationName,
          variables: operation.variables,
        },
      }
    );
  }
}

/**
 * Error Logger Link to track all GraphQL Errors and logs them to Sentry.
 */
const ErrorLoggerLink = onError(
  ({ operation, graphQLErrors, networkError }) => {
    if (networkError) {
      logError("network", operation, networkError);
    } else if (graphQLErrors) {
      logError("resolver", operation, graphQLErrors);
    }
  }
);

/** The context threaded along with every apollo request. */
interface Context {
  headers?: Record<string, unknown>;
}

/**
 * Records and sets the Phoenix token by intercepting a GraphQL
 * response which has a token.
 *
 * This also connects the global Phoenix "connection" with the parsed
 * token.
 */
const AuthLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: Context) => ({
    headers: {
      ...headers,
      "x-cadmus-role": "lecturer",
      "x-cadmus-tenant": __GLOBAL_TENANT.current,
      "x-cadmus-assessment": __GLOBAL_ASSESSMENT_ID.current,
      "x-cadmus-url": window.location.href,
    },
  }));

  if (!forward) {
    return null;
  }

  // If there is a global Phoenix token, add it to the Authorization
  // header as a Bearer token
  if (__GLOBAL_AUTH_TOKEN.current) {
    const authToken = __GLOBAL_AUTH_TOKEN.current;
    operation.setContext(({ headers }: Context) => ({
      token: authToken,
      headers: {
        ...headers,
        authorization: `Bearer: ${authToken}`,
      },
    }));
  }

  return forward(operation).map((result) => {
    if (
      operation.variables &&
      operation.variables.assessmentId &&
      result.data &&
      result.data.token &&
      __GLOBAL_TENANT.current
    ) {
      // AssessmentQuery returns a token, which can be stored globally
      __GLOBAL_AUTH_TOKEN.current = result.data.token;

      // Connect the global PhoenixConnection
      connection.connect(
        operation.variables.assessmentId,
        result.data.token,
        __GLOBAL_TENANT.current
      );
    }
    return result;
  });
});

/**
 * Pad execution time by `padDelay` context.
 * Thus, the execution will take a minimum of `padDelay` ms to complete.
 *
 * Usage: set `context: {padDelay: <number in ms>, ...other contexts}` as an
 *  option taken by useMutation or useQuery.
 */
const delayLink = new ApolloLink((operation, forward) => {
  if (!forward) {
    return null;
  }
  const padDelay: number | null = operation.getContext().padDelay;
  const obs = forward(operation);
  if (padDelay) {
    return delayObservable(obs, padDelay);
  }
  return obs;
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${config.WS_ENDPOINT}/graphql`,
    shouldRetry: () => true,
    connectionParams: () => {
      return {
        role: "lecturer",
        token: __GLOBAL_AUTH_TOKEN.current,
        tenant: __GLOBAL_TENANT.current,
      };
    },
  })
);

const httpLink = new HttpLink({
  uri: `${config.API_ENDPOINT}/api/graphql`,
  credentials: "include",
});

const NetworkLink = ApolloLink.split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

/**
 * Updates document's title to "Cadmus | <assessment name>".
 * Checks the assessment name whenever it is queried and on
 *  updateAssessmentName mutation.
 */
const DocumentTitleLink = new ApolloLink((operation, forward) =>
  forward(operation).map((result) => {
    const name: string | undefined =
      result.data?.assessment?.name || result.data?.updateAssessmentName?.name;

    if (!name) return result;
    const formattedName = `Cadmus | ${name}`;

    if (document.title !== formattedName) {
      document.title = formattedName;
    }

    return result;
  })
);

/**
 * Endpoint for most graphql queries.
 *  Hence, it is the default link.
 */
const PantheonLink = ApolloLink.from([
  DocumentTitleLink,
  AuthLink,
  delayLink,
  ErrorLoggerLink,
  NetworkLink,
]);

/**
 * Endpoint for Hermes (data related data)
 */
const HermesLink = ApolloLink.from([
  ErrorLoggerLink,
  new HttpLink({
    uri: `${config.HERMES_ENDPOINT}/api/graphql`,
  }),
]);

/**
 * Available links depending on directives. This essentially
 *  allow apollo client to have multiple graphql endpoints.
 *
 * e.g. query Insights($assessmentId: ID!) @hermes { ...
 */
const directiveLinks = {
  hermes: HermesLink,
  default: PantheonLink,
};

const DirectiveLinks = new ApolloLink((op) => {
  const directiveNames = getDirectiveNames(op.query);

  // no directives found
  if (directiveNames.length === 0) return directiveLinks.default.request(op);

  // ensure directive match our predefined directives
  const directiveName =
    directiveNames.find((d) => d in directiveLinks) ?? "default";

  if (directiveName === "hermes") {
    return directiveLinks.hermes.request(op);
  }
  return directiveLinks.default.request(op);
});

export const freshlyUploadedFileVar = makeVar<string | undefined>(undefined);
export const usersTagVar = makeVar<Array<string>>([]);

const typePolicies: TypedTypePolicies = {
  Institution: {
    keyFields: [],
  },
  User: {
    fields: {
      tags: {
        read() {
          return usersTagVar();
        },
      },
    },
  },
  // An Enrollment `Tag` is keyed by it's text and tab.
  Tag: {
    keyFields: ["text", "tab"],
  },
  Group: {
    fields: {
      members: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
  UserSettings: {
    keyFields: [],
  },
  AccessCode: {
    keyFields: ["code"],
  },
  Resource: {
    fields: {
      // client side only apollo state's field to determine whether a file resource
      // was uploaded recently
      isFreshlyUploaded: {
        read(_, { readField }) {
          const id = readField<string>("id");
          return freshlyUploadedFileVar() === id;
        },
      },
    },
  },
  Question: {
    keyFields: ["cacheId"],
  },
  CanvasApi: {
    merge: true,
  },
  Choice: {
    keyFields: false,
  },
  Blank: {
    keyFields: false,
  },
};

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies,
  }),
  link: DirectiveLinks,
});

export default client;
