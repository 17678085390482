import { Table } from "@tanstack/react-table";
import { AnimatePresence } from "framer-motion";
import {
  AssessmentType,
  EnrollmentFragment,
  GroupFragment,
  InstructionSheetFragment,
} from "generated/graphql";

import {
  ClassTab,
  StudentListMeta,
  StudentListRow,
} from "@/ui/class/progress/types";

import { ProgressListFilters } from "../ClassListFilters";
import { EmptyTable } from "../EmptyTable";
import * as Grid from "../InfoGrid";
import { TableSkeleton } from "../TableSkeleton";
import {
  AnimatedBulkActionsMenu,
  resetSelectedRowsIfFilterMatches,
  StudentListLayout,
  TableControl,
  TableEmptyState,
} from "./shared";

interface ProgressListProps {
  /**
   * Complete list of ALL the enrollments.
   */
  enrollments: EnrollmentFragment[];
  /**
   * Complete list of ALL the groups with their members
   */
  groups: GroupFragment[];
  sheet?: InstructionSheetFragment;
  /**
   * Table data is being synced
   */
  syncing?: boolean;
  table: Table<StudentListRow>;
  meta: StudentListMeta;
}

/**
 * Progress table with filters.
 */
export const ProgressList = (props: ProgressListProps) => {
  const { enrollments, groups, sheet, syncing, table, meta } = props;
  const isExam = sheet?.assessmentType === AssessmentType.Exam;
  const enableExamAccessCode = sheet?.enableExamAccessCode;
  const selectedRows = table.getSelectedRowModel().rows;

  return (
    <>
      <TableControl>
        <ProgressListFilters table={table} groups={groups} sheet={sheet} />
      </TableControl>
      <Grid.Root>
        {syncing ? (
          <TableSkeleton />
        ) : enrollments.length === 0 ? (
          <EmptyTable
            syncClassList={meta.syncClassList}
            mainText="Sync your students into Cadmus to monitor their progress throughout the assessment"
            secondaryText="You can sync your class list before releasing the assessment"
          />
        ) : (
          <StudentListLayout
            emptyState={<TableEmptyState table={table} />}
            table={table}
            template={
              isExam && enableExamAccessCode ? "code-progress" : "progress"
            }
          />
        )}
      </Grid.Root>
      <AnimatePresence>
        {selectedRows.length !== 0 && (
          <AnimatedBulkActionsMenu
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, type: "spring" }}
            exit={{ opacity: 0, y: 10 }}
            rows={selectedRows.map((row) => row.original!)}
            tab={ClassTab.Students /** TODO: get rid of the concept of tabs */}
            onChanges={(column) =>
              resetSelectedRowsIfFilterMatches(table, column)
            }
            // Just explicitly saying that we don't want to show the anonymity
            // management menu items here, as all students are revealed here.
            anonymityManagement={undefined}
            selectedAll={table.getIsAllRowsSelected()}
            {...meta}
          />
        )}
      </AnimatePresence>
    </>
  );
};
