import { ReactNode } from "react";
import styled from "styled-components";

import { AddCircleIcon } from "@vericus/cadmus-icons";
import { LinkButton, Text, Tooltip } from "@vericus/cadmus-ui";

import { ResponseDistribution } from "@/features/moderation/types";

import * as styles from "./text-response-metrics.css";

export function ResponseTooltip(props: {
  children: ReactNode;
  response: string;
}) {
  return (
    <Tooltip
      side="bottom"
      width="auto"
      sideOffset={12}
      content={
        <Text kind="systemMd" as="span">
          &quot;{props.response}&quot;
        </Text>
      }
    >
      {props.children}
    </Tooltip>
  );
}

export const WrappedTextResponse = styled(Text)<{ type?: string }>`
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  color: ${(p) =>
    p.type === "original"
      ? p.theme.colors.nteal700
      : p.theme.colors.neutralBlack500};
`;

export function MostCommonIncorrectResponse(props: {
  responseWithRate: { response: string | null; percent: number } | null;
  openUpdateRubricModal?: (initialInputValue?: string) => void;
  readOnly: boolean;
}) {
  if (!props.responseWithRate || !props.responseWithRate.response)
    return <Text kind="headingSix">--</Text>;

  const { percent, response } = props.responseWithRate;
  const percentage = percent.toString() + "%";

  return (
    <div>
      <div className={styles.responsesContainer}>
        <div className={styles.response.incorrect} tabIndex={0}>
          <ResponseTooltip response={props.responseWithRate.response}>
            <WrappedTextResponse kind="bodyMd" as="span">
              {response}
            </WrappedTextResponse>
          </ResponseTooltip>
          <span className={styles.percentage}>
            <Text kind="bodyMd" as="span">
              {percentage}
            </Text>
          </span>
        </div>
      </div>
      {!props.readOnly && (
        <div className={styles.container}>
          <LinkButton
            color="navy500"
            icon={<AddCircleIcon label={""} size={24} />}
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {
              props.openUpdateRubricModal?.(response);
            }}
          >
            Add as accepted answer
          </LinkButton>
        </div>
      )}
    </div>
  );
}

export function ResponseDistributionBar(props: {
  distribution: ResponseDistribution | null;
}) {
  if (!props.distribution) return <Text kind="headingSix">--</Text>;

  const {
    acceptedResponsesPercent,
    mostCommonIncorrect,
    restResponsesPercent,
  } = props.distribution;

  const parts = [
    {
      label: "Correct responses",
      percent: acceptedResponsesPercent,
      className: styles.part.correct,
    },
    {
      label: "Most common incorrect response",
      percent: mostCommonIncorrect.percent,
      className: styles.part.incorrect,
    },
    {
      label: "Other incorrect responses",
      percent: restResponsesPercent,
      className: styles.part.rest,
    },
  ];

  return (
    <div className={styles.distribution}>
      {parts.map(({ label, percent, className }) => (
        <Tooltip
          key={label}
          content={
            <Text kind="systemMd">
              {label} - {percent}%
            </Text>
          }
          side="bottom"
          width="auto"
          sideOffset={12}
        >
          <div className={className} style={{ flex: percent }}>
            {className === styles.part.correct && (
              <Text kind="bodySm" bold>
                {percent}%
              </Text>
            )}
          </div>
        </Tooltip>
      ))}
    </div>
  );
}
