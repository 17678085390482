import { styled } from "styled-components";

export interface Props {
  /**
   * The number of skeletons to render
   */
  count: number;
}

/**
 * Skeleton for the past assessment view.
 * Generally used in a loading state.
 */
export const PastAssessmentSkeletons = (props: Props) => {
  const skeletons = [...Array(props.count).keys()].map((_, index) => (
    <Skeleton data-testid={"Skeleton"} key={index} />
  ));

  return <SkeletonContainer>{skeletons}</SkeletonContainer>;
};

const SkeletonContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const Skeleton = styled.div`
  padding-top: 32px;
  width: 328px;
  height: 184px;
  background: linear-gradient(
    90.21deg,
    rgba(89, 115, 166, 0.1) 0%,
    rgba(89, 115, 166, 0.06) 100%
  );
  flex-grow: 2;
`;
