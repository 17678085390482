/** A top-level category for templates, e.g. Discipline */
import { Document } from "@contentful/rich-text-types";

import { AssessmentType, SubjectFragment } from "@/generated/graphql";

export interface Category {
  id: string;
  title: string;
  previewDescription: string;
  hasFilters: boolean;
  subcategories: Subcategory[];
  helpLink: string | undefined;
}

/** e.g. Design, Architecture & Building */
export interface Subcategory {
  id: string;
  title: string;
  /**
   * Displayed above the templates grid when the subcategory is selected.
   */
  heading: string;
  description: string;
  templates: Template[];
  isSelected?: boolean;
  showAs: ShowAs;
  slug: string;
  discipline: string[];
}

export interface Template {
  id: string;
  title: string;
  description: string;
  /** Academic references used to build this template.
   * Is structured as [Contentful's RTF JSON format](https://www.contentful.com/developers/docs/concepts/rich-text/).
   */
  references?: Document;
  /** The URL to the icon. */
  iconSource: string;
  /** The alternative text for the icon. */
  iconAlt?: string;
  /** Editor content as HTML. */
  content: string;
  /** Color for the card and preview backgrounds. */
  color: string;
  matchesFilters: TemplateFilter[];
}

export const templateFilters = [
  "all", // not in Contentful
  "assignments",
  "formative-tasks",
  "timed-assessments",
] as const;

export type TemplateFilter = (typeof templateFilters)[number];

export const showAs = ["square", "wide"] as const;

export type ShowAs = (typeof showAs)[number];

export interface DueDateRange {
  fromDueDateRange: string;
  toDueDateRange: string;
}

export interface IPastAssessmentFilters {
  subject?: SubjectFragment;
  dueDateRange?: SerializedDateRangePickerValue;
  assessmentNameQuery?: string;
  assessmentType?: AssessmentType | "all";
}

export type SerializedDateRangePickerValue = [number | null, number | null];

export const reduxDateSerialiser = (date: Date) => {
  return date.getTime();
};

export const reduxDateDeserialiser = (timestamp: number) => new Date(timestamp);

/**
 * Callback function for when the user intends to preview the selected
 * assessment.
 * @param assessmentId - the ID of the assessment selected.
 * @param assessmentName - the name of the assessment selected.
 * @param instructionSheetVersionId - the instruction sheet version for the assessment in question
 */
export type PreviewPastAssessmentCallback = (
  assessmentId: string,
  assessmentName: string,
  instructionSheetVersionId: string
) => void;

/**
 * Callback function for when the user intends to duplicate the selected
 * assessment.
 * @param assessmentId - the ID of the assessment selected.
 * @param assessmentName - the name of the assessment selected.
 * @param instructionSheetVersionId - the instruction sheet version for the assessment in question
 */
export type DuplicatePastAssessmentCallback = (
  assessmentId: string,
  assessmentName: string,
  instructionSheetVersionId: string
) => void;
