import { useEffect } from "react";

import { Userpilot } from "userpilot";

export interface FlowCallbacks {
  started?: () => void;
  steps?: { [key: string]: () => void };
  completed?: () => void;
  dismissed?: () => void;
}

/**
 * A listener configuration for Userpilot experiences.
 * This exists because Userpilot's default callback are generally overwritten and
 * we need a way for multiple parts of the application to respond to different events.
 * This listener will start listening on mount and stop listening (unregisters itself) when unmounting.
 * This will listen to the following Userpilot experience events:
 *
 * `started` - when the experience is started
 * `step` - when the user has progressed through the flow
 * `completed` - when the user has completed a flow
 * `dismissed` - when the user has dismissed the flow (eg. closing the component or escaping it)
 *
 * UserpilotContext.Provider is required at the top of the stack to save/consolidate all the callbacks.
 *
 * Example:
 *
 * useUserpilotFlowListener(true, "experience_id", {
 *     started: () => {
 *       // Say something to welcome the user
 *     },
 *     step: {
 *       1: () => { // It's the first step, do something },
 *       2: () => { // It's the second step, do something },
 *       4: () => { // It's the fourth step, do something },
 *     }
 *     completed: () => {
 *       // Be excited that the user completed the experience/flow
 *     },
 *     dismissed: () => {
 *       // Be sad that the user didn't want to continue
 *     },
 *   })
 *
 * @param experienceId - The experienceId this configuration is applicable to
 * @param flowCallbacks - The callbacks that are relevant to the lifecycle of the experience
 */

/**
 * Consolidates/saves all the callbacks that are currently relevant/present
 * - Functions are indexed by flow.
 * - Each of them will have started, steps, dismissed, completed callbacks.
 */
const callbacks: Map<string, FlowCallbacks[]> = new Map();

export const useUserpilotFlowListener = (
  enabled: boolean,
  experienceId: string,
  flowCallbacks: FlowCallbacks
) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    // Register itself to the UserpilotContext
    if (!callbacks.has(experienceId)) {
      callbacks.set(experienceId, []);
    }

    callbacks.get(experienceId)!.push(flowCallbacks);

    // On unmount, then unregister itself from the context
    // We'll always be replacing Userpilot's callbacks as more and more of these hooks are called.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Userpilot.on("started", (event: any) => {
      if (experienceId === event.token) {
        callbacks.get(experienceId)!.forEach((flowCallback) => {
          if (flowCallback.started) {
            flowCallback.started();
          }
        });
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Userpilot.on("step", (event: any) => {
      // Check if we have the right experienceId saved.
      if (experienceId === event.token) {
        callbacks.get(experienceId)!.forEach((flowCallback) => {
          if (flowCallback.steps && flowCallback.steps[event.step]) {
            flowCallback.steps[event.step]?.();
          }
        });
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Userpilot.on("completed", (event: any) => {
      if (experienceId === event.token) {
        callbacks.get(experienceId)!.forEach((flowCallback) => {
          if (flowCallback.completed) {
            flowCallback.completed();
          }
        });
      }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Userpilot.on("dismissed", (event: any) => {
      if (experienceId === event.token) {
        callbacks.get(experienceId)!.forEach((flowCallback) => {
          if (flowCallback.dismissed) {
            flowCallback.dismissed();
          }
        });
      }
    });

    return () => {
      const newList = callbacks.get(experienceId)!.filter((flowCallback) => {
        return flowCallback !== flowCallbacks;
      });
      callbacks.set(experienceId, newList);
    };
  });
};
