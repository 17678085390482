import { useCallback } from "react";

import { useJitsu } from "@jitsu/react";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { EnrollmentFragment, StudentSettingsInput } from "@/generated/graphql";
import { useSpawnToastCallback } from "@/ui/exam-special-considerations/hooks/useSpawnToastCallback";
import { useUpdateExamSpecialConsiderations } from "@/ui/exam-special-considerations/hooks/useUpdateExamSpecialConsiderations";
import { SpecialConsiderationFormAction } from "@/ui/exam-special-considerations/SpecialConsiderationFormAction";

/**
 * Callback that to cancel the deferred status of the given enrollments.
 *
 * Fires a mutation to cancel the user's deferred status and subsequently spawns a toast.
 * @param assessmentId
 */
export const useCancelDeferred = (assessmentId: string) => {
  const updateExamSpecialConsiderations = useUpdateExamSpecialConsiderations();
  const { track } = useJitsu();
  const spawnToast = useSpawnToastCallback();

  return useCallback(
    async (enrollments: EnrollmentFragment[]) => {
      if (enrollments.length === 0) {
        return;
      }

      await updateExamSpecialConsiderations(
        assessmentId,
        enrollments.flatMap((enrollment) => {
          // If the enrollment doesn't have work settings, we don't need to cancel/remove anything
          if (!enrollment.workSettings) {
            return [];
          }

          const {
            __typename,
            id: _id,
            ...currentSpecialConsiderations
          } = enrollment.workSettings;

          return [
            {
              ...currentSpecialConsiderations,
              examDeferred: false,
            } as StudentSettingsInput,
          ];
        })
      );

      if (enrollments.length === 1) {
        await track(JitsuEvent.STUDENT_DEFERRAL_CANCELLED, {
          assessment_id: assessmentId,
          enrollment_id: enrollments[0]!.id,
          student_id: enrollments[0]!.user.id,
        });
      } else {
        await track(JitsuEvent.STUDENT_BULK_DEFERRAL_CANCELLED, {
          assessment_id: assessmentId,
          enrollment_id: enrollments.map((e) => e.id),
          student_id: enrollments.map((e) => e.user.id),
        });
      }

      spawnToast(enrollments, SpecialConsiderationFormAction.REMOVED_DEFERRED);
    },
    [assessmentId, spawnToast, track, updateExamSpecialConsiderations]
  );
};
