/**
 * Capture and expose build-time configuration for runtime usage.
 */

///////////////////////////////////////////////////////////////////////////////
// Pantheon                                                                 //
//////////////////////////////////////////////////////////////////////////////

export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;
export const HERMES_ENDPOINT = import.meta.env.VITE_HERMES_ENDPOINT!;
export const HERMESV2_ENDPOINT = import.meta.env.VITE_HERMESV2_ENDPOINT!;
export const SUGGESTION_ENDPOINT = import.meta.env.VITE_SUGGESTION_ENDPOINT!;
export const WS_ENDPOINT = import.meta.env.VITE_WS_ENDPOINT!;

//////////////////////////////////////////////////////////////////////////////
// Feature flagging                                                         //
//////////////////////////////////////////////////////////////////////////////

export const IA_ENABLED = import.meta.env.VITE_IA_ENABLED === "true";

//////////////////////////////////////////////////////////////////////////////
// Contentful                                                               //
//////////////////////////////////////////////////////////////////////////////

export const CONTENTFUL_ACCESS_TOKEN = import.meta.env
  .VITE_CONTENTFUL_ACCESS_TOKEN!;
export const CONTENTFUL_PREVIEW_ACCESS_TOKEN = import.meta.env
  .VITE_CONTENTFUL_PREVIEW_ACCESS_TOKEN!;
export const CONTENTFUL_PREVIEW = import.meta.env.VITE_CONTENTFUL_PREVIEW!;
export const CONTENTFUL_SPACE_ID = import.meta.env.VITE_CONTENTFUL_SPACE_ID!;

//////////////////////////////////////////////////////////////////////////////
// Google Analytics                                                         //
//////////////////////////////////////////////////////////////////////////////

export const GA_DEBUG = import.meta.env.VITE_GA_DEBUG!;
export const GA_KEY = import.meta.env.VITE_GA_KEY!;

//////////////////////////////////////////////////////////////////////////////
// Sentry                                                                   //
//////////////////////////////////////////////////////////////////////////////

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN!;

//////////////////////////////////////////////////////////////////////////////
// Application Environment                                                  //
//////////////////////////////////////////////////////////////////////////////

export const DEV = import.meta.env.DEV!;
export const DEPLOY_ENV = parseDeployEnv();
export const RELEASE_HASH = import.meta.env.VITE_RELEASE_HASH!;
export const DEFAULT_LOCALE = import.meta.env.VITE_DEFAULT_LOCALE ?? "en-AU";

/** Deployment environment as a validated enum. */
export enum DeployEnv {
  LOCAL = "local",
  STAGING = "staging",
  NEXT = "next",
  PRODUCTION_AU = "production",
  PRODUCTION_UK = "uk",
  PRODUCTION_US = "us",
}

function parseDeployEnv(): DeployEnv {
  const rawDeployEnv = import.meta.env.VITE_DEPLOY_ENV;
  switch (rawDeployEnv) {
    // XXX Using `DeployEnv` during build time does not work with jest. Have to use the string values to match.
    case "local":
    case "staging":
    case "next":
    case "production":
    case "uk":
    case "us":
      return rawDeployEnv as DeployEnv;
    default:
      throw new Error("Not a valid DEPLOY_ENV");
  }
}

//////////////////////////////////////////////////////////////////////////////
// User Pilot                                                               //
//////////////////////////////////////////////////////////////////////////////

export const USERPILOT_TOKEN = import.meta.env.VITE_USERPILOT_TOKEN!;

const userpilotConfigurations: Record<
  DeployEnv,
  { setupOnboardingNewInstructions: string; classListOnboarding: string }
> = {
  local: {
    setupOnboardingNewInstructions: "1665359781uWth1575",
    classListOnboarding: "1665038223bNpu2666",
  },
  staging: {
    setupOnboardingNewInstructions: "1665359781uWth1575",
    classListOnboarding: "1665038223bNpu2666",
  },
  next: {
    setupOnboardingNewInstructions: "1665359781uWth1575",
    classListOnboarding: "1665038223bNpu2666",
  },
  production: {
    setupOnboardingNewInstructions: "1666669801jKxa7439",
    classListOnboarding: "1666669807jDoj4143",
  },
  uk: {
    setupOnboardingNewInstructions: "1666669801jKxa7439",
    classListOnboarding: "1666669807jDoj4143",
  },
  us: {
    setupOnboardingNewInstructions: "1666669801jKxa7439",
    classListOnboarding: "1666669807jDoj4143",
  },
};

// Selected userpilot configuration for the current deployment environment.
export const USERPILOT_CONFIG = userpilotConfigurations[DEPLOY_ENV];
