import { produce } from "immer";

import {
  Blank,
  Choice,
  QuestionType,
  SimpleChoiceFragment,
} from "@/generated/graphql";

import {
  DEFAULT_MATCH_SIMILARITY,
  defaultFieldForQuestion,
  defaultQuestionFields,
} from "./default-question-fields";
import {
  defaultBlankFieldInteractionKind,
  InteractionKind,
} from "./interaction-kind-utils";

/**
 * QuestionForm state.
 */
export interface State {
  taskBlockId: string;
  questionId: string;
  parentQuestionId: string | null;
  questionType: QuestionType;
  points: number | null;
  shuffle: boolean;
  hidden: boolean;
  feedback: string | null;
  questionFields: QuestionFormField[];
}

// Internal reducer action dispatcher
export type Dispatch = (value: Action) => void;

export interface QuestionFormField {
  identifier: string;
  response: {
    partialScoring: boolean | null;
    matchSimilarity: number | null;
    correctValues: string[];
    caseSensitive?: boolean;
  };
  interaction: {
    /**
     * The interaction kind must be set if the question can have multiple
     * interaction type
     */
    kind: InteractionKind | null;
    choices: SimpleChoiceFragment[];
    label?: string;
    expectedLength: number | null;
    wordLimit: number | null;
  };
}

/** QuestionForm response state */
export interface Response {
  wordLimit: number | null;
  isTrue?: boolean;
  choices: Choice[];
  matchSimilarity: number | null;
  matchPhrases: string[];
  matchBlanks: Blank[];
  partialScoring: boolean | null;
  caseSensitive?: boolean;
}

// Actions
export type Action =
  //question level
  | { type: "SetQuestionType"; questionType: QuestionType }
  | {
      type: "SetInteractionKind";
      fieldIdentifier: string;
      kind: InteractionKind;
    }
  | { type: "SetPoints"; points?: number }
  | { type: "ToggleHideQuestion" }
  | { type: "ToggleShuffle" }
  | { type: "SetFeedback"; feedback?: string }
  | { type: "ToggleCaseSensitivity" }
  | { type: "TogglePartialScoring" }
  | {
      type: "SetMatchSimilarity";
      similarity?: number;
    }
  | { type: "SetWordLimit"; fieldIdentifier: string; wordLimit: number | null }
  | { type: "ToggleBooleanResponse"; fieldIdentifier: string; isTrue: boolean }
  | {
      type: "SetChoice";
      fieldIdentifier: string;
      choiceIdentifier: string;
      content: string;
    }
  | { type: "DeleteChoice"; fieldIdentifier: string; choiceIdentifier: string }
  | {
      type: "ToggleCorrectChoice";
      fieldIdentifier: string;
      choiceIdentifier: string;
      alowMultipleCorrectChoice: boolean;
    }
  | { type: "ClearChoices"; fieldIdentifier: string }
  | { type: "AddAcceptedAnswer"; fieldIdentifier: string; answer: string }
  | { type: "RemoveAcceptedAnswer"; fieldIdentifier: string; answer: string }
  | { type: "ClearAcceptedAnswers"; fieldIdentifier: string }
  | {
      type: "SyncEditorBlanks";
      editorBlankIds: string[];
    };

// Reducer
export const reducer = produce((state: State, action: Action) => {
  switch (action.type) {
    case "SetQuestionType":
      state.questionType = action.questionType;
      switch (action.questionType) {
        case QuestionType.Overview:
        case QuestionType.Section:
        case QuestionType.Sub:
          state.points = null;
          break;
      }
      // reset question fields to a default field based on the question type
      // template
      state.questionFields = defaultQuestionFields(action.questionType);
      break;

    case "SetInteractionKind": {
      const fieldIdx = state.questionFields.findIndex(
        (field) => field.identifier === action.fieldIdentifier
      );

      if (fieldIdx !== -1) {
        const defaultField = defaultFieldForQuestion(state.questionType);
        const newField = {
          ...defaultField,
          identifier: action.fieldIdentifier,
          interaction: {
            ...defaultField.interaction,
            kind: action.kind,
          },
        };
        state.questionFields[fieldIdx] = newField;
      }
      break;
    }

    case "SetPoints":
      state.points = action.points ?? null;
      break;

    case "ToggleHideQuestion":
      state.hidden = !state.hidden;
      break;

    case "ToggleShuffle":
      state.shuffle = !state.shuffle;
      break;

    case "SetFeedback":
      state.feedback = action.feedback ?? null;
      break;

    case "ToggleCaseSensitivity":
      // change caseSensitivity for all fields
      state.questionFields.forEach((field) => {
        field.response.caseSensitive = !field.response.caseSensitive;

        if (field.response.caseSensitive === true) {
          field.response.matchSimilarity = 100;
        }
      });
      break;

    case "TogglePartialScoring":
      // change partialScoring for all fields
      state.questionFields.forEach((field) => {
        field.response.partialScoring = !field.response.partialScoring;
      });
      break;

    case "SetMatchSimilarity":
      // change matchSimilarity for all fields
      state.questionFields.forEach((field) => {
        field.response.matchSimilarity = action.similarity ?? null;
      });
      break;

    case "SetWordLimit": {
      const field = state.questionFields.find(
        (field) => field.identifier === action.fieldIdentifier
      );
      if (field) {
        field.interaction.wordLimit = action.wordLimit;
      }
      break;
    }
    case "ToggleBooleanResponse": {
      const field = state.questionFields.find(
        (field) => field.identifier === action.fieldIdentifier
      );
      if (field) {
        const correctValue = action.isTrue ? "true" : "false";
        field.response.correctValues = [correctValue];
      }
      break;
    }
    case "SetChoice": {
      const field = state.questionFields.find(
        (field) => field.identifier === action.fieldIdentifier
      );
      if (field) {
        const choice = field.interaction.choices.find(
          (c) => c.identifier === action.choiceIdentifier
        );
        if (choice) {
          choice.content = action.content;
        } else {
          field.interaction.choices.push({
            identifier: action.choiceIdentifier,
            content: action.content,
          });
        }
      }
      break;
    }
    case "DeleteChoice": {
      const field = state.questionFields.find(
        (field) => field.identifier === action.fieldIdentifier
      );
      if (field) {
        field.interaction.choices = field.interaction.choices.filter(
          (choice) => choice.identifier !== action.choiceIdentifier
        );
        field.response.correctValues = field.response.correctValues.filter(
          (choice) => choice !== action.choiceIdentifier
        );
      }
      break;
    }
    case "ToggleCorrectChoice": {
      const field = state.questionFields.find(
        (field) => field.identifier === action.fieldIdentifier
      );

      if (field) {
        const choice = field.interaction.choices.find(
          (c) => c.identifier === action.choiceIdentifier
        );
        if (!choice) return state;

        const correctValues = field.response.correctValues;
        const index = correctValues.indexOf(choice.identifier);
        // if choice is not already correct, add it
        if (index === -1) {
          if (!action.alowMultipleCorrectChoice) {
            field.response.correctValues = [action.choiceIdentifier];
          } else {
            field.response.correctValues.push(choice.identifier);
          }
        } else {
          field.response.correctValues.splice(index, 1);
        }
      }
      break;
    }

    case "ClearChoices": {
      const field = state.questionFields.find(
        (field) => field.identifier === action.fieldIdentifier
      );

      if (field) {
        field.response.correctValues = [];
        field.interaction.choices = [];
      }
      break;
    }
    case "AddAcceptedAnswer": {
      const field = state.questionFields.find(
        (field) => field.identifier === action.fieldIdentifier
      );
      if (field) {
        if (field.response.correctValues.some((v) => v === action.answer)) {
          return;
        } else {
          field.response.correctValues.push(action.answer);
        }
      }
      break;
    }
    case "RemoveAcceptedAnswer": {
      const field = state.questionFields.find(
        (field) => field.identifier === action.fieldIdentifier
      );
      if (field) {
        field.response.correctValues = field.response.correctValues.filter(
          (value) => value !== action.answer
        );
      }
      break;
    }
    case "ClearAcceptedAnswers": {
      const field = state.questionFields.find(
        (field) => field.identifier === action.fieldIdentifier
      );
      if (field) {
        field.response.correctValues = [];
      }
      break;
    }

    /**
     * Sync the editor blanks to the question fields. Invoked on every editor
     * prompt update. If the new blank is found - insert the new field in a
     * default state.
     */
    case "SyncEditorBlanks":
      state.questionFields = action.editorBlankIds.map(
        (editorBlankId): QuestionFormField => {
          const fieldIdx = state.questionFields.findIndex(
            (field) => field.identifier === editorBlankId
          );

          if (fieldIdx === -1) {
            // find the match similarity in existing fields or use 80 as default
            const matchSimilarity =
              state.questionFields.find(
                (f) => f.response.matchSimilarity !== null
              )?.response.matchSimilarity ?? DEFAULT_MATCH_SIMILARITY;

            return {
              identifier: editorBlankId,
              response: {
                partialScoring: null,
                matchSimilarity,
                correctValues: [],
              },
              interaction: {
                choices: [],
                expectedLength: null,
                wordLimit: null,
                kind: defaultBlankFieldInteractionKind(),
              },
            };
          }
          return state.questionFields[fieldIdx]!;
        }
      );

      break;
  }
});

// PromptState
export interface PromptState {
  promptDoc: string | null;
  shortPrompt: string | null;
}
