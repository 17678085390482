import styled from "styled-components";

import { Skeleton } from "@vericus/cadmus-ui";

export const PercentageSkeleton = styled(Skeleton)`
  width: 32px;
  height: 24px;
  border-radius: 8px;
  margin-bottom: 0px;
`;

export const ResponseSkeleton = styled(Skeleton)`
  height: 40px;
  border-radius: 8px;
  margin-bottom: 0px;
`;
