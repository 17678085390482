import { styled } from "styled-components";

import { colors, Radio, Text, Tooltip } from "@vericus/cadmus-ui";

import { AssessmentType } from "@/generated/graphql";

import { SettingContainer } from "./styles";

export interface AssessmentTypeSettingProps {
  isDisabled?: boolean;
  selectedAssessmentType: AssessmentType;
  onSelectAssessmentType: (newtype: AssessmentType) => void;
}

export const AssessmentTypeSetting = ({
  isDisabled,
  selectedAssessmentType,
  onSelectAssessmentType,
}: AssessmentTypeSettingProps) => {
  const isExam = selectedAssessmentType === AssessmentType.Exam;
  const isAssignment = selectedAssessmentType === AssessmentType.Assignment;

  const cards = (
    <OptionCardsContainer role="radiogroup">
      <OptionCard
        tabIndex={0}
        role="radio"
        isSelected={isAssignment}
        isDisabled={isDisabled}
        onClick={() =>
          !isDisabled && onSelectAssessmentType(AssessmentType.Assignment)
        }
        onKeyDown={(e) =>
          !isDisabled &&
          e.key === "Enter" &&
          onSelectAssessmentType(AssessmentType.Assignment)
        }
        aria-labelledby="assignment-label"
        aria-checked={isAssignment}
        data-value="True"
      >
        <StyledRadioButton
          role="presentation"
          tabIndex={-1}
          disabled={isDisabled}
          readOnly
          checked={isAssignment}
          sizeVariant="lg"
          colorVariant="blue"
        />
        <InfoContainer as="label" id="assignment-label">
          <Text kind="headingFive" as="span">
            Assignment
          </Text>
          <Text kind="bodySm">
            For extended pieces of work or non-timed tasks.
          </Text>
        </InfoContainer>
      </OptionCard>

      <OptionCard
        tabIndex={0}
        role="radio"
        isSelected={isExam}
        isDisabled={isDisabled}
        onClick={() =>
          !isDisabled && onSelectAssessmentType(AssessmentType.Exam)
        }
        onKeyDown={(e) =>
          !isDisabled &&
          e.key === "Enter" &&
          onSelectAssessmentType(AssessmentType.Exam)
        }
        aria-labelledby="exam-label"
        aria-checked={isExam}
        data-value="True"
      >
        <StyledRadioButton
          role="presentation"
          tabIndex={-1}
          disabled={isDisabled}
          readOnly
          checked={isExam}
          sizeVariant="lg"
          colorVariant="blue"
          onFocus={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
        <InfoContainer as="label" id="exam-label">
          <Text kind="headingFive" as="span">
            Exam
          </Text>
          <Text kind="bodySm">
            For timed exams and tests. Add auto submission or reading time,
            manage special considerations, and access exam-specific analytics.
          </Text>
        </InfoContainer>
      </OptionCard>
    </OptionCardsContainer>
  );

  return (
    <SettingContainer>
      <Text kind="headingOne">Assessment type</Text>
      {isDisabled ? (
        <Tooltip
          content={
            "Assessment type can't be changed once students have started working"
          }
        >
          {cards}
        </Tooltip>
      ) : (
        cards
      )}
    </SettingContainer>
  );
};

const OptionCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const OptionCard = styled.div<{ isSelected?: boolean; isDisabled?: boolean }>`
  position: relative;
  padding: 16px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid ${(p) => (p.isSelected ? colors.blue500 : colors.grey400)};
  border-radius: 2px;
  background-color: ${(p) => p.isSelected && colors.blue100};
  cursor: pointer;
  &:hover {
    background-color: ${(p) => !p.isDisabled && colors.blue100};
    border: ${(p) => !p.isDisabled && `1px solid${colors.blue500}`};
  }

  // disabled state overlay
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${colors.blue100};
    opacity: ${(p) => (p.isDisabled ? 0.5 : 0)};
  }
`;

const StyledRadioButton = styled(Radio)`
  cursor: pointer; // without this there is a square area outside the radio that doesn't use the pointer cursor
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
