import { styled } from "styled-components";

import {
  Button,
  colors,
  desktop,
  DesktopAndTablet,
  mobile,
  MobileOnly,
  Skeleton,
  tablet,
  Text,
} from "@vericus/cadmus-ui";

import { Subcategory } from "@/data/templates";

import { BaseTemplateCardProps } from "./shared";

export interface SquareCardProps extends BaseTemplateCardProps {
  subcategory?: Subcategory;
}

export const SquareCard = (props: SquareCardProps) => {
  const {
    color,
    iconSource,
    iconAlt,
    title,
    subcategory,
    id,
    onSelectTemplate,
    onPreviewTemplate,
  } = props;

  return (
    <CardContainer background={color} tabIndex={0}>
      <Content>
        <Text kind="label" style={{ fontSize: "10px" }}>
          {subcategory?.title ?? ""}
        </Text>
        <MobileOnly>
          <Title lineClamp={2} kind={"headingSix"}>
            {title}
          </Title>
        </MobileOnly>
        <DesktopAndTablet>
          <Title lineClamp={3} kind={"headingFour"}>
            {title}
          </Title>
        </DesktopAndTablet>
      </Content>
      <Img
        role="presentation"
        src={iconSource}
        alt={iconAlt}
        draggable="false"
      />

      <Actions background={color}>
        <PreviewButton
          kind="outline"
          tabIndex={-1}
          fullWidth
          onClick={() => onPreviewTemplate(id)}
        >
          Preview
        </PreviewButton>
        <UseButton kind="secondary" fullWidth onClick={() => onSelectTemplate(id)}>
          Use
        </UseButton>
      </Actions>
    </CardContainer>
  );
};

const PreviewButton = styled(Button)`
  border-color: ${colors.black200};
  color: ${colors.black200};
  &:not(:disabled):after {
    box-shadow: none;
    background: none;
  }
  ${mobile`
    height: 30px;
  `}
`;

const UseButton = styled(Button)`
  &:not(:disabled):hover:after {
    opacity: 0.5;
  }
  ${mobile`
    height: 30px;
  `}
`;

const Title = styled(Text)<{ lineClamp?: number }>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(p) => p.lineClamp ?? 3};
  display: -webkit-box;
  overflow: hidden;
`;

const Content = styled.div`
  flex: auto;
  transition: transform 0.4s ease;
  transition-duration: 0.3s;
  box-sizing: border-box;
`;

const Actions = styled.div<{ background?: string }>`
  box-sizing: border-box;
  flex: none;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 250, 0) 0%,
    ${(p) => p.background || colors.yellow100} 18.51%
  );

  padding: 24px 18px 18px 18px;

  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;

  display: flex;
  flex-direction: column;
  opacity: 0;
  gap: 8px;
  transform: translateY(50%);
  transition: opacity 0.3s ease, transform 0.3s ease;
`;

// TODO: use CSS variable for --size?
const Img = styled.img`
  width: 32px;
  height: 32px;

  ${tablet`
    width: 45px;
    height: 45px;
  `}

  ${desktop`
    width: 45px;
    height: 45px;
  `}

  position: absolute;
  bottom: 18px;

  opacity: 1;
  transform: scale(1);

  transition: opacity 0.3s ease, transform 0.3s ease;
`;

const CardContainer = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: column;
  width: 156px;
  height: 156px;
  ${tablet`
    width: 212px;
    height: 212px;
  `}
  ${desktop`
    width: 212px;
    height: 212px;
  `}
  border-radius: 3px;
  position: relative;
  font-weight: 600;

  overflow-y: hidden;

  padding: 18px;
  box-sizing: border-box;

  background: ${(p) => p.background || colors.yellow100};
  outline: none;

  cursor: pointer;

  &:hover ${Actions}, &:focus ${Actions} {
    opacity: 1;
    transform: translateY(0%);
  }

  &:hover ${Img}, &:focus ${Img} {
    opacity: 0;
    transform: scale(0.75);
  }
`;

export const SquareCardSkeleton = styled(Skeleton)`
  border-radius: 8px;
  width: 212px;
  min-height: 212px;
  box-sizing: border-box;
`;
