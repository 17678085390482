import { useCallback } from "react";
import { styled } from "styled-components";

import { Text } from "@vericus/cadmus-ui";

import {
  TemplatesModalId,
  useAppModal,
  useShowTemplatesModal,
} from "../modals";

export const useOnCloseModal = () => {
  const showTemplatesModal = useShowTemplatesModal();
  const templatePreviewModal = useAppModal(TemplatesModalId.TemplatePreview);
  const pastAssessmentPreviewModal = useAppModal(
    TemplatesModalId.PastAssessmentPreview
  );

  return useCallback(() => {
    showTemplatesModal({ noAnimation: true });
    templatePreviewModal.remove();
    pastAssessmentPreviewModal.remove();
  }, [showTemplatesModal, templatePreviewModal, pastAssessmentPreviewModal]);
};

export const useOnBack = () => {
  const showTemplatesModal = useShowTemplatesModal();
  const templatePreviewModal = useAppModal(TemplatesModalId.TemplatePreview);
  const pastAssessmentPreviewModal = useAppModal(
    TemplatesModalId.PastAssessmentPreview
  );

  return useCallback(() => {
    showTemplatesModal({ noAnimation: true });
    templatePreviewModal.remove();
    pastAssessmentPreviewModal.remove();
  }, [showTemplatesModal, templatePreviewModal, pastAssessmentPreviewModal]);
};

export const Container = styled.div<{ backgroundColor: string }>`
  position: relative;
  box-sizing: border-box;
  background-color: ${(p) => p.backgroundColor};
  padding-bottom: 64px;

  height: 90vh;
  overflow: auto;
`;

export const StickyHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
`;

export const PaddedPreviewContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  box-sizing: border-box;
`;

export const HeaderContentsContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;

  ${Text} {
    line-height: 100%;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 4px;
`;
