import { styled } from "styled-components";

import { Color } from "@vericus/cadmus-ui";

import {
  ProgressFilterId,
  StudentRiskFilterId,
} from "@/ui/StudentLists/ClassListFilters/components";

export type BannerFilterId =
  | Extract<
      ProgressFilterId,
      | "feedback-viewed"
      | "has-extension"
      | "submitted-final"
      | "submitted-draft"
      | "in-progress"
      | "viewed-only"
    >
  | Extract<StudentRiskFilterId, "not-accessed">
  | "special-consideration";

type FilterConfig = {
  pillBgColor: Color;
  pillTextColor: Color;
  /** A user readable representation of the filter. */
  filterDisplay: string;
};

export const filtersConfig: Record<BannerFilterId, FilterConfig> = {
  "not-accessed": {
    filterDisplay: "not accessed",
    pillBgColor: "white200",
    pillTextColor: "grey600",
  },
  "viewed-only": {
    filterDisplay: "viewed only",
    pillBgColor: "poppy200",
    pillTextColor: "wine800",
  },
  "in-progress": {
    filterDisplay: "in progress",
    pillBgColor: "indigo200",
    pillTextColor: "indigo800",
  },
  "submitted-draft": {
    filterDisplay: "draft",
    pillBgColor: "blue200",
    pillTextColor: "blue800",
  },
  "submitted-final": {
    filterDisplay: "submitted",
    pillBgColor: "cyan200",
    pillTextColor: "cyan800",
  },
  "has-extension": {
    filterDisplay: "extension",
    pillBgColor: "grey100",
    pillTextColor: "grey500",
  },
  "special-consideration": {
    filterDisplay: "special con.",
    pillBgColor: "grey100",
    pillTextColor: "grey500",
  },
  "feedback-viewed": {
    filterDisplay: "feedback viewed",
    pillBgColor: "navy500",
    pillTextColor: "white200",
  },
};

export const ProgressFilterContainer = styled.div<{ flexGrow?: number }>`
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-grow: ${(p) => p.flexGrow ?? 1};
  background-color: ${(p) => p.theme.colors.bgrey300};
  padding: 20px 32px;
  min-width: 258px;
  gap: 26px;
`;

export const FiltersContainer = styled.div.attrs({ role: "group" })`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
`;

export const EnrolledStudentsContainer = styled.div``;
