import { styled } from "styled-components";

import { Button, Spacer, Text } from "@vericus/cadmus-ui";

interface SpecialConsiderationsBannerProps {
  /**
   * Callback for when the intent to manage special considerations
   */
  onManageClick: () => void;
}

/**
 * Informational banner used/displayed on the ClassList
 * page when the assessment type is an exam.
 *
 * Also shows a button for the user to launch into
 * managing special considerations.
 *
 */
export const SpecialConsiderationsBanner = (
  props: SpecialConsiderationsBannerProps
) => {
  return (
    <BannerContainer>
      <Text kind={"headingFive"}>Special considerations</Text>
      <Spacer spacing={2} />
      <Text kind="bodyMd">
        Manage extensions, flexible time arrangements or deferrals
      </Text>
      <Spacer spacing={12} />
      <StyledButton
        onClick={props.onManageClick}
        kind={"secondary"}
        size={"md"}
      >
        Manage
      </StyledButton>
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 165px;
  max-width: 295px;
  background-color: ${(p) => p.theme.colors.white100};
  border: 1px solid ${(p) => p.theme.colors.grey100};
  border-radius: 4px;
  padding: 20px 24px;
`;

const StyledButton = styled(Button)`
  max-width: 174px;
  width: 100%;
`;
