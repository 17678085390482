import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { syncClassList } from "data/class";

import { boolParser, getItem, intParser } from "./helpers";

/**
 * All flag names that will be stored and loaded from localStorage.
 */
interface LocalFlagsState {
  /**
   * Key prefix for flags, to scope the flags to some namespace.
   *
   * The prefix is set once flags are loaded from the underlying localStorage.
   */
  prefix: string | null;
  /** How many times has the user seen the SG model ? */
  sgIntroModalViewCount: number;
  /** Has the user clicked the roster sync button ? */
  rosterSyncClicked: boolean;
  /** Whether the user manually dismissed the sync nudge (without syncing). */
  dismissedSyncNudge: boolean;
  /** Whether the user manually dismissed feedback release disclaimer */
  dismissedFeedbackReleaseDisclaimer: boolean;
}

export const initialState: LocalFlagsState = {
  prefix: null,
  sgIntroModalViewCount: 0,
  rosterSyncClicked: false,
  dismissedSyncNudge: false,
  dismissedFeedbackReleaseDisclaimer: false,
};

/**
 * Initilisation action to read flags from localStorage from under a given
 * `prefix`.
 *
 * Since loading flags require access to localStorage, flags are loaded only
 * once per `prefix`. Flags will only be re-read if the `prefix` provided in is
 * different than the already loaded prefix.
 */
export const loadFlags = createAction("loadFlags", (prefix: string) => ({
  payload: {
    prefix,
    sgIntroModalViewCount: getItem(prefix, "sgIntroModalViewCount", intParser),
    rosterSyncClicked: getItem(prefix, "rosterSyncClicked", boolParser),
    dismissedSyncNudge: getItem(prefix, "dismissedSyncNudge", boolParser),
    dismissedFeedbackReleaseDisclaimer: getItem(
      prefix,
      "dismissedFeedbackReleaseDisclaimer",
      boolParser
    ),
  },
}));

/**
 * Redux state slice managing the localStorage based user flags.
 *
 * All flags are stored under keys with a `state.prefix` prefix. For example,
 * this `prefix` can be used to scope the flags to a user in an assessment.
 */
export const localFlagsSlice = createSlice({
  name: "localFlags",
  initialState,
  reducers: {
    /** Increment the sgIntroModalView count by 1 or `payload`. */
    trackSGIntroModalView(state, action: PayloadAction<number | undefined>) {
      state.sgIntroModalViewCount += action.payload ?? 1;
    },
    dismissedSyncNudge(state) {
      state.dismissedSyncNudge = true;
    },
    dismissedFeedbackReleaseDisclaimer(state) {
      state.dismissedFeedbackReleaseDisclaimer = true;
    },
  },
  extraReducers: (builder) => {
    // NOTE Since loading flags require access to localStorage, flags are loaded only
    // once per `prefix`.
    builder.addCase(loadFlags, (state, action) => {
      const { prefix } = action.payload;
      if (state.prefix === prefix) {
        return state;
      }

      state.prefix = prefix;
      state.sgIntroModalViewCount =
        action.payload.sgIntroModalViewCount ?? state.sgIntroModalViewCount;
      state.rosterSyncClicked =
        action.payload.rosterSyncClicked ?? state.rosterSyncClicked;
      state.dismissedSyncNudge =
        action.payload.dismissedSyncNudge ?? state.dismissedSyncNudge;
      state.dismissedFeedbackReleaseDisclaimer =
        action.payload.dismissedFeedbackReleaseDisclaimer ??
        state.dismissedFeedbackReleaseDisclaimer;
    });

    builder.addCase(syncClassList.fulfilled, (state, _action) => {
      state.rosterSyncClicked = true;
    });
  },
});

// Export Actions
export const {
  trackSGIntroModalView,
  dismissedSyncNudge,
  dismissedFeedbackReleaseDisclaimer,
} = localFlagsSlice.actions;

// Export Reducer
export const localFlagsReducer = localFlagsSlice.reducer;
