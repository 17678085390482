import { ReactNode } from "react";

import { Icon, Text, Tooltip } from "@vericus/cadmus-ui";

import { Column } from "@tanstack/react-table";

import * as styles from "./sortable-header.css";

export const SortCaret = ({ column }: { column: Column<unknown, unknown> }) => {
  const sorted = column.getIsSorted();
  switch (sorted) {
    case false: {
      return <Icon iconName="UpCaret" iconColor="grey400" />;
    }
    case "asc": {
      return <Icon className={styles.activeSort} iconName="UpCaret" />;
    }
    case "desc": {
      return <Icon className={styles.activeSort} iconName="DownCaret" />;
    }
    default: {
      return null;
    }
  }
};

export interface SortableHeaderProps {
  column: Column<unknown, unknown>;
  /** Optional button tooltip */
  tooltip?: ReactNode;
  /** Button contents */
  children: ReactNode;
  /** Optional tooltip size */
  tooltipSize?: "sm" | "md";
  /** Optional tooltip width */
  tooltipWidth?: "auto" | number;
}

export const SortableHeader = (props: SortableHeaderProps) => {
  const {
    children,
    column,
    tooltip,
    tooltipSize = "sm",
    tooltipWidth = "auto",
  } = props;
  const button = (
    <button
      className={styles.buttonContainer}
      onClick={column.getToggleSortingHandler()}
    >
      <Text kind="label">{children}</Text> <SortCaret column={column} />
    </button>
  );
  if (tooltip) {
    return (
      <Tooltip size={tooltipSize} width={tooltipWidth} content={tooltip}>
        {button}
      </Tooltip>
    );
  }
  return button;
};
