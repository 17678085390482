import { useCallback } from "react";
import { styled } from "styled-components";

import { Icon } from "@vericus/cadmus-icons";
import { Color, colors, IconButton } from "@vericus/cadmus-ui";

import moment, { Moment } from "moment";
import { DayPickerSingleDateController } from "react-dates";
import "react-dates/initialize";

import { DayHighlight, dayHighlightKeyDeprecated } from "./utils";

import "react-dates/lib/css/_datepicker.css";

export interface DatePickerProps {
  /** Current date already selected, if any.  */
  date: Moment | null;
  /** Callback to set the current date. */
  setDate: (date: Moment) => void;
  /** Background color of the selected date */
  selectedColor: Color;
  /**
   * Days to highlight on the picker calendar.
   * Also see `getHighlightDays`
   */
  highlightDays: DayHighlight;
  /**
   * Predicate to disable selectable dates.
   * Also see `isOutsideRangePredicate`
   */
  isOutsideRange?: (day: Moment) => boolean;
  /** Render extra footer information on the date picker, */
  calendarInfo?: () => string | JSX.Element;
  /** React classname to style the date picker. */
  className?: string;
  /**
   * Key used to force date picker to re-render for the current visible
   * month.
   * Also see `useJumpToday`
   */
  todayKey?: number;
}

/**
 * @deprecated because its underlying date functionality is using
 * moment.js (which is deprecated).
 * Calendar based Date picker.
 * */
export function DeprecatedDatePicker({
  date,
  setDate,
  isOutsideRange,
  selectedColor,
  highlightDays,
  calendarInfo,
  className,
  todayKey,
}: DatePickerProps) {
  /**
   * Callback to highlight a given date if it's present in the `highlightDays`
   * prop.
   */
  const renderDayContents = useCallback(
    (day: Moment) => {
      const highlights = highlightDays[dayHighlightKeyDeprecated(day)];

      if (!highlights) return day.format("D");

      const Indicators = highlights.map((c, i) => (
        <HighlightDay key={i} color={c} />
      ));

      return (
        <>
          {day.format("D")}
          <HighlightDayContainer>{Indicators}</HighlightDayContainer>
        </>
      );
    },
    [highlightDays]
  );

  /**
   * Handle date updates.
   *
   * Only sets the date, does not update the time. The time is read from the
   * TimePicker instead. Hence, if the current Date value has a time, it is kept as-is.
   */
  const handleDateChange = useCallback(
    (newDate: Moment | null) => {
      if (newDate) {
        if (date) {
          setDate(
            moment(newDate).set({ h: date.get("h"), m: date.get("m"), s: 0 })
          );
        } else {
          setDate(moment(newDate).set({ h: 23, m: 59, s: 0 }));
        }
      }
    },
    [date, setDate]
  );

  return (
    <DatePickerContainer selectedColor={selectedColor} className={className}>
      <DayPickerSingleDateController
        // this key is used to force re-render for initialVisibleMonth to be triggered again
        key={todayKey}
        // renders the month where the return date value is
        initialVisibleMonth={() => moment()}
        date={date}
        onDateChange={handleDateChange}
        focused={true}
        onFocusChange={() => null}
        renderDayContents={renderDayContents}
        isOutsideRange={isOutsideRange}
        renderCalendarInfo={calendarInfo}
        numberOfMonths={2}
        hideKeyboardShortcutsPanel
        noBorder
        navPrev={
          <PrevMonth>
            <IconButton aria-label="Back a month">
              <Icon iconName="Left" />
            </IconButton>
          </PrevMonth>
        }
        navNext={
          <NextMonth>
            <IconButton aria-label="Forward a month">
              <Icon iconName="Right" />
            </IconButton>
          </NextMonth>
        }
      />
    </DatePickerContainer>
  );
}

// overriding react-dates classes
const DatePickerContainer = styled.div<{ selectedColor: Color }>`
  transform: translateX(-24px);

  /* An individual day */
  .CalendarDay {
    border: none;
    border-radius: 50%;
    position: relative;

    &:hover {
      background: ${(p) => p.theme.background.action2};
      border: none;
    }
  }

  /* The background container */
  .DayPicker__horizontal,
  .CalendarMonthGrid,
  .CalendarMonth,
  .CalendarDay__default {
    background: transparent;
  }

  /* Month caption (aka. title) */
  .CalendarMonth_caption {
    color: ${(p) => p.theme.text.heading};
    font-size: 20px;
    letter-spacing: 0.4px;
  }

  /* Su Mo Tu We Th Fr Sa */
  .DayPicker_weekHeader {
    color: ${(p) => p.theme.text.shade1};
  }

  /* Blocked days */
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    cursor: not-allowed;
    background: none;
    color: #cacccd;
  }

  /* Selected day */
  .CalendarDay__selected,
  .CalendarDay__selected:hover {
    background: ${(p) => colors[p.selectedColor]};
    color: white;
  }
`;

const HighlightDayContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-top: 3px;

  width: 100%;
  padding: 2px 0 1px 0;

  display: flex;
  justify-content: center;

  background: white;
`;

const HighlightDay = styled.div<{ color: Color }>`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin: 0 1px;

  background: ${(p) => colors[p.color]};
`;

// previous month and next month icon containers
const PrevMonth = styled.span`
  position: absolute;
  left: 18px;
  top: 18px;
`;

const NextMonth = styled.span`
  position: absolute;
  right: 24px;
  top: 18px;
`;
