import * as React from "react";

import { Checkbox, Input, LinkButton, Spacer, Text } from "@vericus/cadmus-ui";

import { FadeIn, SettingContainer } from "../styles";

interface Props {
  wordLimit: number | null;
  noLimit: boolean;
  countReferences: boolean;
  setWordLimit: (value: number | null) => void;
  setNoLimit: (value: boolean) => void;
  setCountReferences: (value: boolean) => void;
}

/**
 * View and set optional Word Limit requirements using /noLimit/ and
 * /wordLimit/, and also /countReferences/.
 */
export function WordLimitSetting(props: Props) {
  const { noLimit, setNoLimit } = props;

  return (
    <SettingContainer>
      <Text kind="headingOne">
        Word limit is{" "}
        <LinkButton
          inline
          onClick={() => setNoLimit(!noLimit)}
          color={!noLimit ? "lilac500" : undefined}
        >
          {noLimit ? "off" : "on"}
        </LinkButton>
      </Text>
      <Text kind="bodyLg" color="shade1">
        Tell students what the word limit is for this assignment.
      </Text>
      {!noLimit && <Content {...props} />}
    </SettingContainer>
  );
}

const Content = (props: Props) => {
  const { wordLimit, setWordLimit, countReferences, setCountReferences } =
    props;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // validation 0 < val
      const val = parseInt(event.currentTarget.value);
      if (val > 0) {
        setWordLimit(val);
      } else if (isNaN(val)) {
        setWordLimit(null);
      }
    },
    [setWordLimit]
  );

  return (
    <FadeIn>
      <Spacer spacing={36} />
      <Text kind="headingFive">Word Limit</Text>
      <Text kind="bodySm">
        <Input
          value={wordLimit || ""}
          onChange={handleChange}
          type="number"
          min="1"
          aria-label="word limit"
        />{" "}
        words; {countReferences ? "including" : "excluding"} references
      </Text>
      <Spacer spacing={36} />
      <Text kind="headingFive">More options:</Text>
      <Checkbox
        content="Include references in the word limit"
        checked={countReferences}
        onChange={() => setCountReferences(!countReferences)}
      />
    </FadeIn>
  );
};
