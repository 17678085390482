import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { QuestionType, TaskBlockFragment } from "@/generated/graphql";

import {
  add,
  updateHiddenTaskBlock,
  updateTaskBlocks,
  updateTempTaskBlock,
} from "./actions";
import { TaskLayoutBlock, TaskLayoutState } from "./types";
import {
  getDescendantBlocks,
  getQuestionLabel,
  getTaskLayout,
  getTaskSiblingOrder,
} from "./utils";

export const initialState: TaskLayoutState = {
  sortedTaskBlocks: [],
  nodes: {},
  nodeIdBeingDragged: null,
  requestsInProgress: [],
  activeNodeId: null,
  currentTab: "settings",
};

export const taskLayoutSlice = createSlice({
  name: "taskLayout",
  initialState,
  reducers: {
    /** Keep track of the node being dragged. */
    startDragging(state, action: PayloadAction<{ nodeId: string }>) {
      state.nodeIdBeingDragged = action.payload.nodeId;
    },

    setFocusBlock(
      state,
      action: PayloadAction<{ nodeId: string; isFocused: boolean }>
    ) {
      const { nodeId, isFocused } = action.payload;
      if (isFocused) {
        state.activeNodeId = nodeId;
        state.currentTab = "questions";
      } else if (nodeId === state.activeNodeId) {
        state.activeNodeId = null;
      }
    },

    /** Clear any dragging nodes */
    stopDragging(state) {
      state.nodeIdBeingDragged = null;
      state.dropzoneParentIdBeingDraggedOver = undefined;
    },
    /** Keep record of which parent ID's dropzone is being dragged over. */
    startDragOverDropzone(
      state,
      action: PayloadAction<{ parentNodeId: string }>
    ) {
      state.dropzoneParentIdBeingDraggedOver = action.payload.parentNodeId;
    },
    stopDragOverDropzone(state) {
      state.dropzoneParentIdBeingDraggedOver = undefined;
    },

    /** Hydrate the slice state using graphql fetched Task blocks. */
    loadBlocks(state, action: PayloadAction<{ blocks: TaskBlockFragment[] }>) {
      const layout = getTaskLayout(action.payload.blocks);
      state.sortedTaskBlocks = layout.sortedTaskBlocks;
      state.nodes = layout.nodes;
    },

    /** Update the slice state by merging block updates. */
    updateSortedBlocks(
      state,
      action: PayloadAction<{ blocks: TaskLayoutBlock[] }>
    ) {
      state.sortedTaskBlocks = action.payload.blocks;

      // re-label questions
      state.sortedTaskBlocks.forEach((block) => {
        const node = state.nodes[block.nodeId];
        if (node) {
          const order = getTaskSiblingOrder(state.sortedTaskBlocks, block);
          node.label = getQuestionLabel(state.nodes, block, order);
          node.parentNodeId = block.parentNodeId;
        }
      });

      // delete blocks from task blocks meta that don't exist anymore
      const blockNodeIds = new Set<string>(
        state.sortedTaskBlocks.map((block) => block.nodeId)
      );
      Object.keys(state.nodes).forEach((nodeId) => {
        if (!blockNodeIds.has(nodeId)) {
          delete state.nodes[nodeId];
        }
      });
    },

    /** Update attributes of a single sorted TaskBlock. */
    updateSortedBlock(
      state,
      action: PayloadAction<{
        taskBlockId: string;
        points?: number;
        hidden?: boolean;
        shuffle?: boolean;
        questionType?: QuestionType;
      }>
    ) {
      const taskBlockId = action.payload.taskBlockId;
      const taskBlock = state.sortedTaskBlocks.find(
        (sortedTaskBlock) => sortedTaskBlock.id === taskBlockId
      );
      if (!taskBlock) return;

      if (action.payload.points !== undefined) {
        taskBlock.points = action.payload.points;
      }
      if (action.payload.shuffle !== undefined) {
        taskBlock.shuffle = action.payload.shuffle;
      }
      if (action.payload.hidden !== undefined) {
        taskBlock.hidden = action.payload.hidden;
      }
      if (action.payload.questionType !== undefined) {
        taskBlock.questionType = action.payload.questionType;
      }
    },

    /** Set the current tab. */
    setCurrentTab(state, action: PayloadAction<"settings" | "questions">) {
      state.currentTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      add,
      (state, action: PayloadAction<{ blocks: TaskBlockFragment[] }>) => {
        action.payload.blocks.map((block) => {
          const { question, ...rest } = block;
          const newBlock = {
            ...rest,
            questionId: question?.id,
            questionType: question?.questionType,
          };
          const order = getTaskSiblingOrder(state.sortedTaskBlocks, newBlock);
          if (newBlock.questionType === QuestionType.Overview) {
            state.sortedTaskBlocks.splice(0, 0, newBlock);
          } else if (
            newBlock.previousNodeId === null &&
            newBlock.parentNodeId === null
          ) {
            if (
              state.sortedTaskBlocks[0]?.questionType === QuestionType.Overview
            ) {
              state.sortedTaskBlocks.splice(1, 0, newBlock);
            } else {
              state.sortedTaskBlocks.splice(0, 0, newBlock);
            }
          } else if (newBlock.previousNodeId === null) {
            // insert after last child of a parent node
            const parentIndex = state.sortedTaskBlocks.findIndex(
              (t) => t.nodeId === newBlock.parentNodeId
            );
            const childCount = state.sortedTaskBlocks.filter(
              (t) => t.parentNodeId === newBlock.parentNodeId
            ).length;
            state.sortedTaskBlocks.splice(
              parentIndex + childCount + 1,
              0,
              newBlock
            );
          } else {
            let previousIndex = state.sortedTaskBlocks.findIndex(
              (t) => t.nodeId === newBlock.previousNodeId
            );
            if (previousIndex > -1) {
              const previousBlock = state.sortedTaskBlocks[previousIndex]!;
              if (previousBlock.questionType === QuestionType.Sub) {
                previousIndex = state.sortedTaskBlocks
                  .map((t) => t.parentNodeId)
                  .lastIndexOf(previousBlock.nodeId);
              }
              state.sortedTaskBlocks.splice(previousIndex + 1, 0, newBlock);
            }
          }
          state.nodes[newBlock.nodeId] = {
            label: getQuestionLabel(state.nodes, newBlock, order),
            parentNodeId: rest.parentNodeId,
          };
        });
      }
    );
    // update taskLayout's temp id and question's id coming from optimistic response
    builder.addCase(updateTempTaskBlock, (state, action) => {
      const block = state.sortedTaskBlocks.find(
        (t) => t.id === action.payload.id
      );
      if (block) {
        block.id = action.payload.block.id;
        block.questionId = action.payload.block.question?.id;
      }
    });
    builder.addCase(updateTaskBlocks, (state, action) => {
      action.payload.forEach((updatedBlock) => {
        const { previousNodeId, parentNodeId } = updatedBlock;
        const currentIndex = state.sortedTaskBlocks.findIndex(
          (block) => block.id === updatedBlock.id
        );
        let block: TaskLayoutBlock | undefined;
        let childrens: Array<TaskLayoutBlock> = [];
        if (currentIndex > -1) {
          block = state.sortedTaskBlocks[currentIndex];
        }
        if (block) {
          let previousIndex = -1;
          childrens = getDescendantBlocks(state.sortedTaskBlocks, block.nodeId);
          // delete the block with its childrens from the old position
          state.sortedTaskBlocks.splice(currentIndex, 1 + childrens.length);
          if (previousNodeId) {
            previousIndex = state.sortedTaskBlocks
              .map((t) => t.parentNodeId)
              .lastIndexOf(previousNodeId);
            if (previousIndex === -1) {
              previousIndex = state.sortedTaskBlocks.findIndex(
                (t) =>
                  t.parentNodeId === parentNodeId && previousNodeId === t.nodeId
              );
            }
          } else if (parentNodeId === null) {
            // if first task is an overview then set previousIndex to 0 so task will be inserted on position 1
            // otherwise position 0 if parentNodeId and previousNodeId is null
            if (
              state.sortedTaskBlocks[0]?.questionType === QuestionType.Overview
            ) {
              previousIndex = 0;
            }
          } else {
            previousIndex = state.sortedTaskBlocks.findIndex(
              (blk) => blk.nodeId === parentNodeId
            );
          }
          if (!updatedBlock.deleted) {
            // insert the block to the new position with its children with its updated state
            // if exist
            state.sortedTaskBlocks.splice(
              previousIndex + 1,
              0,
              {
                ...block,
                ...updatedBlock,
              },
              ...childrens
            );
          }
        }
      });
      // re-label questions
      state.sortedTaskBlocks.forEach((block) => {
        const node = state.nodes[block.nodeId];
        if (node) {
          const order = getTaskSiblingOrder(state.sortedTaskBlocks, block);
          node.label = getQuestionLabel(state.nodes, block, order);
          node.parentNodeId = block.parentNodeId;
        }
      });
      const taskLayoutNodeIds = state.sortedTaskBlocks.map(
        (block) => block.nodeId
      );
      Object.keys(state.nodes).forEach((nodeId) => {
        if (!taskLayoutNodeIds.includes(nodeId)) {
          delete state.nodes[nodeId];
        }
      });
    });
    builder.addCase(updateHiddenTaskBlock, (state, action) => {
      const block = state.sortedTaskBlocks.find(
        (blk) => blk.id === action.payload.id
      );
      if (block) {
        block.hidden = action.payload.hidden;
      }
      // re-label questions
      state.sortedTaskBlocks.forEach((block) => {
        const node = state.nodes[block.nodeId];
        if (node) {
          const order = getTaskSiblingOrder(state.sortedTaskBlocks, block);
          node.label = getQuestionLabel(state.nodes, block, order);
          node.parentNodeId = block.parentNodeId;
        }
      });
    });
  },
});

export const {
  loadBlocks,
  startDragging,
  stopDragging,
  startDragOverDropzone,
  stopDragOverDropzone,
  updateSortedBlocks,
  updateSortedBlock,
  setFocusBlock,
  setCurrentTab,
} = taskLayoutSlice.actions;

export const taskLayoutReducer = taskLayoutSlice.reducer;
