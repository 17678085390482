import { styled } from "styled-components";

import { LinkButton, Radio, Spacer, Text } from "@vericus/cadmus-ui";

import { DraftTip } from "ui/shared/Tips";
import { formatDate } from "utils/datetime";
import { HeraLaunch, useHeraLaunch } from "utils/useHeraLaunch";

import { useInstitutionFeaturesFragment } from "@/graphql/institution-selectors";

import { FadeIn, SettingContainer } from "../styles";

interface Props {
  assessmentId: string;
  draftDueDate: string | null;
  noDraft: boolean;
  draftSViewReports: number;
  onDateClick: () => void;
  setDraftSViewReports: (value: number) => void;
  setNoDraft: (value: boolean) => void;
  hasCanvasAGS: boolean;
  gradingService: string;
  isMultiFormat: boolean;
}

/**
 * View and set Drafting requirements like /noDraft/ and /draftDueDate/.
 *
 * Drafting is disabled by default from the server, via /noDraft/. Therefore
 * this section toggles it's main content visibility based on that value.
 *
 * Setting the actual draft due date is done using the `TaskTimeline`
 * picker. Note that there could be a draft due date set but not used if the
 * /noDraft/ value is true.
 */
export function DraftSetting(props: Props) {
  const { noDraft, setNoDraft, hasCanvasAGS } = props;

  const { moodlegraderFeatureEnabled: hasMoodleGrader } =
    useInstitutionFeaturesFragment();

  return (
    <SettingContainer>
      <Text kind="headingOne">
        Drafting is{" "}
        <StyledLinkButton
          inline
          onClick={() => setNoDraft(!noDraft)}
          color={!noDraft ? "lilac500" : undefined}
        >
          {noDraft ? "off" : "on"}
        </StyledLinkButton>
      </Text>
      {!hasCanvasAGS && !hasMoodleGrader && (
        <Text kind="bodyLg" color="shade1">
          Set a draft due date to allow students to submit a work in progress.
          You can provide feedback on draft submissions using Turnitin’s
          Feedback Studio. After the draft due date passes, students can access
          this feedback within Cadmus.
        </Text>
      )}
      {hasCanvasAGS && (
        <Text kind="bodyLg" color="shade1">
          Set a draft due date to allow students to submit a work in progress.
          You can provide feedback on draft submissions using your chosen
          Marking Tool (Turnitin or SpeedGrader). After the draft due date
          passes, students can access feedback through Cadmus.{" "}
        </Text>
      )}
      {hasMoodleGrader && !hasCanvasAGS && (
        <Text kind="bodyLg" color="shade1">
          Set a draft due date to allow students to submit a work in progress.
          You can provide feedback on draft submissions using your chosen
          Marking Tool (Turnitin or Moodle Grader). After the draft due date
          passes, students can access feedback through Cadmus.{" "}
        </Text>
      )}
      {!noDraft && <Content {...props} />}
      <DraftTip />
    </SettingContainer>
  );
}

const Content = (props: Props) => {
  const { draftDueDate, onDateClick } = props;
  const onHeraLaunch = useHeraLaunch({
    launch: HeraLaunch.Settings,
    assessmentId: props.assessmentId,
    isDraft: true,
  });

  return (
    <FadeIn>
      <Spacer spacing={36} />
      <Text kind="headingFive">Draft Due Date</Text>
      <Text kind="bodySm">
        You may submit a draft before{" "}
        <StyledLinkButton inline onClick={onDateClick}>
          {draftDueDate ? formatDate(draftDueDate) : "set a due date"}
        </StyledLinkButton>
      </Text>
      <DraftSViewReports
        view={props.draftSViewReports}
        setView={props.setDraftSViewReports}
        canViewReportBeforeDueDate={
          props.gradingService === "turnitin" && props.isMultiFormat
            ? false
            : true
        }
      />
      <Spacer spacing={36} />
      <StyledLinkButton iconName="Turnitin" onClick={onHeraLaunch}>
        View advanced Turnitin settings
      </StyledLinkButton>
    </FadeIn>
  );
};

interface DraftSViewReportsProps {
  // Current view level, one of `0`, `1`, or `2`.
  view: number;
  // Set new view level.
  setView: (value: number) => void;
  // Is the option to show the similarity report to students before the due date
  // available i.e. is level `2` allowed to be selected in the radio list.
  canViewReportBeforeDueDate: boolean;
}

// Visibility options for the similarity report generation for draft
// submissions.
const options = [
  // NOTE This level will be disabled if `props.canViewReportBeforeDueDate` is false
  {
    content: "Allow students to view similarity before the draft due date",
    level: 2,
  },
  {
    content: "Allow students to view similarity after the draft due date",
    level: 1,
  },
  {
    content: "Don’t allow students to view similarity for draft submissions",
    level: 0,
  },
];

function DraftSViewReports(props: DraftSViewReportsProps) {
  return (
    <>
      <Spacer spacing={45} />
      <FieldSet>
        <legend>
          <Text kind="headingFive">Similarity Reports for Drafts:</Text>
        </legend>
        <Text kind="bodySm" color="shade1">
          Control how students access similarity within Cadmus for draft
          submissions
        </Text>
        <Spacer spacing={9} />
        {options.map(({ content, level }) => (
          <Radio
            key={level}
            name="draftGradeSimilarity"
            content={content}
            value={level.toString()}
            checked={props.view === level}
            onChange={() => props.setView(level)}
            disabled={level === 2 && !props.canViewReportBeforeDueDate}
          />
        ))}
      </FieldSet>
    </>
  );
}

const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
`;

const FieldSet = styled.fieldset`
  border: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
`;
