import { useRef, useState } from "react";

import { Row, Table } from "@tanstack/react-table";
import { useUserpilotConfiguration } from "data/onboarding/useUserpilotConfiguration";
import { useUserpilotFlowListener } from "data/onboarding/useUserpilotFlowListener";
import {
  ExposedCustomEvent,
  useCustomEvent,
} from "ui/onboarding/useCustomEvent";
import { Userpilot } from "userpilot";

import { StudentListMeta, StudentListRow } from "@/ui/class/progress/types";

import * as Grid from "./InfoGrid";
import { StudentDropdown } from "./StudentDropdown";

interface Props {
  table: Table<StudentListRow>;
  row: Row<StudentListRow>;
}

export const OptionsCol = (props: Props) => {
  const studentDropdownRef = useRef<HTMLButtonElement>(null);
  const [showDropdown, setShowDropdown] = useState<boolean | undefined>(
    undefined
  );

  const firstRow = props.table.getRowModel().rows[0]!.id === props.row.id;

  useCustomEvent(firstRow, ExposedCustomEvent.OPEN_STUDENT_DROPDOWN, () => {
    if (studentDropdownRef.current) {
      studentDropdownRef.current.click();
      setShowDropdown(true);
      Userpilot.next();
    }
  });
  const userpilotConfiguration = useUserpilotConfiguration();
  useUserpilotFlowListener(
    firstRow,
    userpilotConfiguration.classListOnboarding,
    {
      steps: {
        7: () => {
          setShowDropdown(false);
        },
        8: () => {
          setShowDropdown(true);
        },
        9: () => {
          setShowDropdown(false);
        },
      },
      dismissed: () => {
        setShowDropdown(undefined);
      },
      completed: () => {
        setShowDropdown(undefined);
      },
    }
  );

  const meta = props.table.options.meta!;

  return (
    <Grid.OptionsCol
      data-component={firstRow ? `Table.StudentDropdown` : undefined}
      data-testid={"OptionsCol"}
    >
      <StudentDropdown
        open={showDropdown}
        ref={studentDropdownRef}
        row={props.row.original!}
        {...(meta as StudentListMeta)}
      />
    </Grid.OptionsCol>
  );
};
