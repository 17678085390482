import { EnrollmentFragment } from "@/generated/graphql";

/**
 * Given an enrollment and a list of tags, return whether this enrollments has
 * any tags that are included in the provided tags list
 */
export const getEnrollmentHasTagIncluded = (
  enrollment: Pick<EnrollmentFragment, "tags">,
  tags: string[] | readonly string[]
) => {
  return enrollment.tags.some((tag) => tags.includes(tag.text));
};
