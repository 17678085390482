import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";

import { Discipline } from "@/generated/graphql";

import { refreshTemplates } from "./actions";
import {
  Category,
  IPastAssessmentFilters,
  Subcategory,
  TemplateFilter,
} from "./types";
import {
  findSelectedSubcategory,
  findSubcategoryByDiscipline,
  findSubcategoryBySlug,
  makeSubcategorySelected,
  makeSubcategorySelectedBySlug,
} from "./utils";

interface DefaultSubcategory {
  subcategory?: Subcategory;
  discipline: Discipline;
}

export interface TemplatesState {
  /**
   * Template categories.
   */
  categories: {
    data: Category[];
    isLoading: boolean;
    hasLoaded: boolean;
    defaultSubCategory?: DefaultSubcategory;
  };
  selectedFilter: TemplateFilter;
  pastAssessmentFilters: IPastAssessmentFilters;
  /**
   * Used to store the desired subcategory slug if the subcategory data has not loaded yet.
   * (The slug given by URL query params may be parsed before the templates data loads).
   * Will be cleared after templates load.
   */
  selectedSubcategorySlug?: string;
  defaultDiscipline?: Discipline;
}

export const initialPastAssessmentFilters = {};

export const initialState: TemplatesState = {
  categories: {
    data: [],
    isLoading: false,
    hasLoaded: false,
  },
  selectedFilter: "all",
  pastAssessmentFilters: initialPastAssessmentFilters,
};

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    updatedDefaultSubcategoryByDiscipline(
      state,
      action: PayloadAction<{ discipline: Discipline }>
    ) {
      const { discipline } = action.payload;
      state.defaultDiscipline = discipline;
    },
    updateSelectedSubcategory(
      state,
      action: PayloadAction<{ subcategoryId: string }>
    ) {
      const { subcategoryId } = action.payload;
      state.categories.data = makeSubcategorySelected(
        state.categories.data,
        subcategoryId
      );
      state.selectedFilter = "all";
    },
    /**
     * If the templates data has already loaded, just select the subcategory by slug.
     * If it has not yet loaded, store it temporarily.
     */
    updateSelectedSubcategorySlug(
      state,
      action: PayloadAction<{ slug: string }>
    ) {
      const { slug } = action.payload;
      if (state.categories.hasLoaded) {
        state.categories.data = makeSubcategorySelectedBySlug(
          state.categories.data,
          slug
        );
        state.selectedFilter = "all";
      }
      state.selectedSubcategorySlug = slug;
    },
    updateSelectedFilter(
      state,
      action: PayloadAction<{ newFilter: TemplateFilter }>
    ) {
      const { newFilter } = action.payload;
      state.selectedFilter = newFilter;
    },
    updatePastAssessmentsFilter(
      state,
      action: PayloadAction<IPastAssessmentFilters>
    ) {
      const filter = {
        ...action.payload,
      };
      // Check if for empty string. If empty, let's just remove it
      if (!filter.assessmentNameQuery) {
        delete filter.assessmentNameQuery;
      }

      if (isEqual(filter.dueDateRange, [null, null])) {
        delete filter.dueDateRange;
      }

      state.pastAssessmentFilters = filter;
    },
    resetPastAssessmentFilter(state, _action: PayloadAction<null>) {
      state.pastAssessmentFilters = initialPastAssessmentFilters;
    },
    resetPastAssessmentNameQueryFilter(state, _action: PayloadAction<null>) {
      const { assessmentNameQuery: _, ...rest } = state.pastAssessmentFilters;

      state.pastAssessmentFilters = rest;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshTemplates.pending, (state) => {
      state.categories.isLoading = true;
    });
    builder.addCase(refreshTemplates.fulfilled, (state, action) => {
      state.categories.isLoading = false;

      // Get all the different types of subcategories
      const previouslySelectedSubcategory: Subcategory | undefined =
        findSelectedSubcategory(state.categories.data);
      const firstSubcategory: Subcategory | undefined =
        action.payload[0]?.subcategories[0];
      const subcategorySelectedBySlug: Subcategory | undefined =
        state.selectedSubcategorySlug
          ? findSubcategoryBySlug(action.payload, state.selectedSubcategorySlug)
          : undefined;
      const defaultDisciplinedSubcategory: Subcategory | undefined =
        state.defaultDiscipline
          ? findSubcategoryByDiscipline(action.payload, state.defaultDiscipline)
          : undefined;

      const subcategoryToSelect: Subcategory | undefined =
        previouslySelectedSubcategory ??
        subcategorySelectedBySlug ??
        defaultDisciplinedSubcategory ??
        state.categories.defaultSubCategory?.subcategory ??
        firstSubcategory;

      // Set the first category -> subcategory selected
      const newCategories = makeSubcategorySelected(
        action.payload,
        subcategoryToSelect?.id || ""
      );
      state.categories.data = newCategories;

      state.categories.hasLoaded = true;
      state.selectedSubcategorySlug = undefined; // clear the temporary storage of the slug when the initial data loads
    });
    builder.addCase(refreshTemplates.rejected, (state) => {
      state.categories.isLoading = false;
    });
  },
});

export const templatesReducer = templatesSlice.reducer;
export const {
  updateSelectedSubcategory,
  updateSelectedSubcategorySlug,
  updateSelectedFilter,
  updatePastAssessmentsFilter,
  resetPastAssessmentFilter,
  resetPastAssessmentNameQueryFilter,
  updatedDefaultSubcategoryByDiscipline,
} = templatesSlice.actions;
