import { useCallback } from "react";
import { styled } from "styled-components";

import {
  Block,
  Desk,
  DesktopAndTablet,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import { useJitsu, usePageView } from "@jitsu/react";
import type { Column } from "@tanstack/react-table";

import { closeModals, syncClassList } from "@/data/class";
import { JitsuEvent } from "@/data/events/JitsuEvent";
import { useAppDispatch, useAppSelector } from "@/data/hooks";
import { ClassQuery, ExamTiming } from "@/generated/graphql";
import { selectIsExam } from "@/graphql/sheet-selectors";
import { useRootNavigate } from "@/router/routing";
import { AssessmentHeader } from "@/ui/app/AssessmentHeader";
import { CadmusBar } from "@/ui/app/CadmusBar";
import { PurpleArea } from "@/ui/app/NavBar";
import {
  BannerFilterId,
  DisplayedFilter,
  ProgressFilterEmptyState,
  ProgressFiltersBanner,
  SpecialConsiderationsBanner,
} from "@/ui/class/progress/components";
import { FilterValue, PredicateFilter } from "@/ui/class/progress/types";
import { StatusBar } from "@/ui/errors/StatusBar";
import { useFilteredGroups } from "@/ui/groups/helpers";
import { useStudentListMeta } from "@/ui/StudentLists";
import {
  progressFilters,
  studentRiskFilters,
} from "@/ui/StudentLists/ClassListFilters/components";
import { ProgressSkeleton } from "@/ui/StudentLists/ProgressSkeleton";
import { ReportEmailModal } from "@/ui/StudentLists/ReportEmailModal";
import { ProgressList } from "@/ui/StudentLists/StudentLists/ProgressList";
import { useProgressTable } from "@/ui/StudentLists/StudentLists/table-instance-hooks";

import { EnrolledStudentsContainer } from "../components/ProgressFilterBanner/shared";
import {
  ProgressStats,
  usePreFilterTableData,
  useShowFeedbackFilters,
} from "../hooks";
import { StudentListRow } from "../types";

interface ProgressPageProps {
  classData: ClassQuery;
}

/**
 * Athena Progress page with the progress list.
 *
 * Runs the `ClassQuery` query to provide data to the whole page.
 */
export const ProgressPage = (props: ProgressPageProps) => {
  const { classData } = props;

  // Track page view via jitsu
  usePageView();

  const {
    me: { email: teacherEmail },
    class: { students: enrollments, groups },
    instructionSheet,
    assessment,
  } = classData;

  // Assessment information
  const assessmentId = assessment.id;

  // Sheet settings
  const isExam = !!instructionSheet && selectIsExam(instructionSheet);

  // Routing callbacks
  const rootNavigate = useRootNavigate();
  const goToExamSpecialConsiderationsPage = () => {
    rootNavigate("/class/marking/examSpecialConsiderations");
  };

  // Groups with withdrawn and deferred enrollments filtered out
  const { filteredGroups } = useFilteredGroups({
    groups,
    enrollments,
  });

  // State for the Assessment report email confirmation modal
  const reportEmail = useAppSelector((state) => state.class.reportEmailModal);

  const syncing = useAppSelector((state) => state.class.syncing);
  const revealedEnrollmentIds = useAppSelector(
    (state) => state.class.revealedEnrollmentIds
  );
  const dispatch = useAppDispatch();

  // Callback to dispatch sync class list action
  const onSyncClassList = useCallback(() => {
    dispatch(syncClassList({ assessmentId }));
  }, [dispatch, assessmentId]);

  const metaWithoutTagConfigs = useStudentListMeta({
    assessment,
    enrollments,
    groups,
    instructionSheet,
    revealedEnrollmentIds,
    teacherEmail,
  });
  const hasDrafting = !isExam && !!instructionSheet?.draftDueDate;
  const showFeedbackFilters = useShowFeedbackFilters({ instructionSheet });

  const { meta, table } = useProgressTable({
    enrollments,
    metaWithoutTagConfigs,
  });

  const flagsColumn = table.getColumn("flags")!;
  const progressColumn = table.getColumn("progress")!;

  const onFilterSelected = useOnFilterSelected(
    flagsColumn,
    progressColumn,
    hasDrafting,
    instructionSheet?.examTiming
  );

  const progressStats = usePreFilterTableData(
    table.getPreFilteredRowModel().flatRows,
    hasDrafting
  );

  const filters = makeDispayableFilters(
    progressStats,
    isExam,
    showFeedbackFilters,
    !isExam && !!instructionSheet?.draftDueDate,
    (id: BannerFilterId) =>
      isBannerFilterActive(
        id,
        flagsColumn,
        progressColumn,
        hasDrafting,
        instructionSheet?.examTiming
      )
  );

  return (
    <Desk.Layout>
      {reportEmail && (
        <ReportEmailModal
          email={reportEmail}
          onClose={() => dispatch(closeModals())}
        />
      )}
      <StatusBar />
      <Block>
        <CadmusBar />
      </Block>
      <Desk.Table>
        <AssessmentHeader assessment={assessment} sheet={instructionSheet} />
        <PurpleArea>
          <Block>
            {syncing && <ProgressSkeleton />}
            {!syncing && enrollments.length > 0 && (
              <FlexSeparated>
                <ProgressFiltersBanner
                  beforeFilters={
                    <EnrolledStudentsContainer>
                      <Text kind="systemSm">Enrolled students</Text>
                      <Text kind="headingTwo">{progressStats.enrolled}</Text>
                    </EnrolledStudentsContainer>
                  }
                  growHorizontally
                  filters={filters}
                  onFilterSelected={onFilterSelected}
                />
                {showFeedbackFilters && (
                  <DesktopAndTablet>
                    <ProgressFiltersBanner
                      filters={[
                        {
                          filterId: "feedback-viewed",
                          count: progressStats.feedback,
                          active: isBannerFilterActive(
                            "feedback-viewed",
                            flagsColumn,
                            progressColumn,
                            hasDrafting,
                            instructionSheet?.examTiming
                          ),
                        },
                      ]}
                      showFeedbackInAnyDevice
                      growHorizontally={false}
                      onFilterSelected={onFilterSelected}
                    />
                  </DesktopAndTablet>
                )}
                {!showFeedbackFilters && isExam && (
                  <SpecialConsiderationsBanner
                    onManageClick={goToExamSpecialConsiderationsPage}
                  />
                )}
              </FlexSeparated>
            )}
            {!syncing && enrollments.length === 0 && (
              <ProgressFilterEmptyState
                filters={filters}
                onSyncClassList={onSyncClassList}
              />
            )}
          </Block>
        </PurpleArea>

        <Spacer spacing={36} />
        <Block>
          <Text kind="headingThree" color="navy500">
            Class progress
          </Text>
          <ProgressList
            table={table}
            meta={meta}
            enrollments={enrollments}
            groups={filteredGroups}
            sheet={instructionSheet ?? undefined}
            syncing={syncing}
          />
        </Block>
        <Spacer spacing={150} />
      </Desk.Table>
    </Desk.Layout>
  );
};

/**
 * Takes progress statistics and reshapes it to be displayed to the user.
 */
function makeDispayableFilters(
  progressStats: ProgressStats,
  isExam: boolean,
  includeFeedbackFilter: boolean,
  hasDraftingEnabled: boolean,
  /** Given a banner filter id finds out whether it's active */
  isActiveLookup: (id: BannerFilterId) => boolean
): DisplayedFilter[] {
  const maybeFilters: (DisplayedFilter | boolean)[] = [
    {
      filterId: "not-accessed",
      count: progressStats.notAccessed,
      active: isActiveLookup("not-accessed"),
    },
    {
      filterId: "viewed-only",
      count: progressStats.viewedOnly,
      active: isActiveLookup("viewed-only"),
    },
    {
      filterId: "in-progress",
      count: progressStats.started,
      active: isActiveLookup("in-progress"),
    },
    hasDraftingEnabled && {
      filterId: "submitted-draft",
      count: progressStats.drafts,
      active: isActiveLookup("submitted-draft"),
    },
    {
      filterId: "submitted-final",
      count: progressStats.finals,
      active: isActiveLookup("submitted-final"),
    },
    isExam
      ? {
          filterId: "special-consideration",
          count: progressStats.specialConsiderations,
          active: isActiveLookup("special-consideration"),
        }
      : {
          filterId: "has-extension",
          count: progressStats.extensions,
          active: isActiveLookup("has-extension"),
        },
    includeFeedbackFilter && {
      filterId: "feedback-viewed",
      count: progressStats.feedback,
      active: isActiveLookup("feedback-viewed"),
    },
  ];
  return maybeFilters.filter(
    (maybeFilter): maybeFilter is DisplayedFilter => !!maybeFilter
  );
}

/**
 * Toggles a predicate filter in the given column.
 */
function useToggleFilter(
  column: Column<StudentListRow, unknown>,
  filterGroup: string
) {
  const { track } = useJitsu();
  return useCallback(
    (filter: PredicateFilter) => {
      column.setFilterValue((existing: FilterValue): FilterValue => {
        let updated: FilterValue = undefined;
        if (existing && existing.find((f) => f.label === filter.label)) {
          updated = existing.filter((f) => f.label !== filter.label);
        } else {
          updated = [filter, ...(existing ?? [])];
        }
        track(JitsuEvent.CLASS_FILTER_UPDATED, {
          filter_group: filterGroup,
          filter_toggled: filter.label,
          filter_value: updated.map((f) => f.label),
          filter_type: "banner",
        });
        return updated.length > 0 ? updated : undefined;
      });
    },
    [column, track, filterGroup]
  );
}

/**
 * @returns whether the banner filter is active, using the progress table's state
 */
function isBannerFilterActive(
  filterKey: BannerFilterId,
  flagsColumn: Column<StudentListRow, unknown>,
  progressColumn: Column<StudentListRow, unknown>,
  hasDrafting: boolean,
  examTiming?: ExamTiming
) {
  /** The predicate filters (the ones the table works with) associated with the banner filter. */
  let predicateFilters: PredicateFilter[] = [];
  let columnToLookForFilterIn = flagsColumn;

  switch (filterKey) {
    case "viewed-only":
    case "in-progress":
    case "submitted-final":
    case "submitted-draft":
    case "feedback-viewed":
    case "has-extension":
      predicateFilters = [progressFilters[filterKey](hasDrafting)];
      break;
    case "not-accessed":
      predicateFilters = [studentRiskFilters["not-accessed"](hasDrafting)];
      columnToLookForFilterIn = progressColumn;
      break;
    case "special-consideration":
      if (!examTiming) {
        predicateFilters = [];
        break;
      }
      predicateFilters = [
        progressFilters["has-extra-time"](hasDrafting),
        examTiming === ExamTiming.Window
          ? progressFilters["has-alternative-window"](hasDrafting)
          : progressFilters["has-alternative-start"](hasDrafting),
      ];
      break;
    default:
      filterKey satisfies never; // exhaustive check
  }
  const activeFilters = columnToLookForFilterIn.getFilterValue() as FilterValue;
  if (!activeFilters) return false;
  return predicateFilters.every(
    (filter) =>
      !!activeFilters.find(
        (activeFilter) => activeFilter.label === filter.label
      )
  );
}

/**
 * Translate the filters selected in the banner to filters applicable to the progress table,
 * then toggle each filter in the table.
 */
const useOnFilterSelected = (
  flagsColumn: Column<StudentListRow, unknown>,
  progressColumn: Column<StudentListRow, unknown>,
  hasDrafting: boolean,
  examTiming?: ExamTiming
) => {
  const toggleStudentAtRiskFilter = useToggleFilter(
    progressColumn,
    "Students at risk"
  );
  const toggleProgressFilter = useToggleFilter(flagsColumn, "Progress status");
  return useCallback(
    (filter: BannerFilterId) => {
      const selectedFilters = mapBannerFilterToPredicateFilters(
        filter,
        hasDrafting,
        examTiming
      );
      selectedFilters.forEach((predicateFilter) => {
        if (
          predicateFilter.label ===
          studentRiskFilters["not-accessed"](hasDrafting).label
        ) {
          toggleStudentAtRiskFilter(predicateFilter);
          return;
        }
        toggleProgressFilter(predicateFilter);
      });
    },
    [examTiming, hasDrafting, toggleProgressFilter, toggleStudentAtRiskFilter]
  );
};

/**
 * Map the selected banner filter to a shape the student list table uses.
 */
function mapBannerFilterToPredicateFilters(
  filterKey: BannerFilterId,
  hasDrafting: boolean,
  examTiming?: ExamTiming
): PredicateFilter[] {
  switch (filterKey) {
    // progress filters
    case "submitted-draft":
    case "viewed-only":
    case "in-progress":
    case "submitted-final":
    case "has-extension":
    case "feedback-viewed":
      return [progressFilters[filterKey](hasDrafting)];
    case "not-accessed":
      return [studentRiskFilters["not-accessed"](hasDrafting)];
    case "special-consideration":
      if (!examTiming) return [progressFilters["has-extra-time"](hasDrafting)];
      return [
        progressFilters["has-extra-time"](hasDrafting),
        examTiming === ExamTiming.Window
          ? progressFilters["has-alternative-window"](hasDrafting)
          : progressFilters["has-alternative-start"](hasDrafting),
      ];
    default:
      filterKey satisfies never; // exhaustive check
      return [];
  }
}

const FlexSeparated = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
`;
