import { TaskFormat } from "@/generated/graphql";
import { IReqsDifference } from "@/ui/task/requirements";

import { SetDatesStepCallback } from "../SettingsPage";
import { Step } from "../TaskTimeline";
import { useSettingsFormContext } from "../use-settings-form";
import { DateSetting } from "./DateSetting";
import { DraftSetting } from "./DraftSetting";
import { GradeSetting } from "./GradeSetting";
import { ReferencingStyleSetting } from "./ReferencingStyleSetting";
import { WeightSetting } from "./WeightSetting";
import { WordLimitSetting } from "./WordLimitSetting";

const lateResubmissionsDocsLinks = {
  canvas:
    "https://support.cadmus.io/setting-requirements-canvas#multiple-late-submissions",
  others:
    "https://support.cadmus.io/setting-requirements#multiple-late-submissions",
};

export interface AssignmentSettingsProps {
  assessmentId: string;
  diff?: IReqsDifference;
  /** Whether the backing LMS is Canvas. */
  hasCanvasAGS: boolean;
  /** Whether students have started working on the assignment already. */
  haveStudentsStarted: boolean;
  /** Whether submissions for the assessment have been made already. */
  hasSubmissions: boolean;
  /** Callback for when the user wants to change due dates. */
  setDatesStep: SetDatesStepCallback;
  /** Due date that is set in LMS */
  lmsDueDate: string | null;
  /** Feedback return date that is set in LMS */
  lmsGradingDueDate: string | null;
  /** Task Format of Assignment.*/
  format: TaskFormat;
  /** Whether to show referencing styles that require footnotes*/
  footnoteReferencingEnabled: boolean;
}

/**
 * This page allows updating of the local copy of assignment settings.
 */
export const AssignmentSettings = ({
  assessmentId,
  hasCanvasAGS,
  hasSubmissions,
  haveStudentsStarted,
  setDatesStep,
  lmsDueDate,
  lmsGradingDueDate,
  format,
  ...props
}: AssignmentSettingsProps) => {
  const form = useSettingsFormContext();
  const { values: settings } = form;

  return (
    <>
      <DateSetting
        dueDate={settings.dueDate}
        onDueDateClick={() => setDatesStep(Step.Final)}
        returnDate={settings.returnDate}
        onReturnDateClick={() => setDatesStep(Step.Return)}
        timeLimit={settings.timeLimit}
        noTimeLimit={settings.noTimeLimit}
        setTimeLimit={(timeLimit) => form.setFieldValue("timeLimit", timeLimit)}
        setNoTimeLimit={(noTimeLimit) =>
          form.setFieldValue("noTimeLimit", noTimeLimit)
        }
        allowLateResubmissions={settings.allowLateResubmission}
        lateResubmissionsLearnMoreLink={
          hasCanvasAGS
            ? lateResubmissionsDocsLinks.canvas
            : lateResubmissionsDocsLinks.others
        }
        onAllowLateResubmissionsClick={() =>
          form.setFieldValue(
            "allowLateResubmission",
            !settings.allowLateResubmission
          )
        }
        haveStudentsStarted={haveStudentsStarted}
        lmsDueDate={lmsDueDate}
        lmsGradingDueDate={lmsGradingDueDate}
        onToggleLmsDueDate={(date) => form.setValues({ dueDate: date })}
        onToggleLmsGradingDueDate={(date) =>
          form.setValues({ returnDate: date })
        }
        onDisableDraftSetting={() => {
          form.setFieldValue("noDraft", true);
          form.setFieldValue("draftDueDate", null);
        }}
      />
      <GradeSetting
        maxGrade={settings.maxGrade}
        sViewReports={settings.sViewReports}
        setMaxGrade={(maxGrade) => form.setFieldValue("maxGrade", maxGrade)}
        setSViewReports={(sViewReports) =>
          form.setFieldValue("sViewReports", sViewReports)
        }
        restrictMarkingToolPdf={settings.restrictMarkingToolPdf}
        setRestrictStudentResultView={(restrictMarkingToolPdf) =>
          form.setFieldValue("restrictMarkingToolPdf", restrictMarkingToolPdf)
        }
        markingToolPdf={settings.markingToolPdf}
        setStudentResultView={(markingToolPdf) =>
          form.setFieldValue("markingToolPdf", markingToolPdf)
        }
        assessmentId={assessmentId}
        anonymousMarking={settings.anonymousMarking}
        setAnonymousMarking={(anonymousMarking) =>
          form.setFieldValue("anonymousMarking", anonymousMarking)
        }
        hasSubmission={hasSubmissions}
        gradingService={settings.gradingService}
        setGradingService={(gradingService) => {
          form.setFieldValue("gradingService", gradingService);
        }}
        hasCanvasAGS={hasCanvasAGS}
        format={format}
      />
      {settings.noTimeLimit && (
        <DraftSetting
          assessmentId={assessmentId}
          noDraft={settings.noDraft}
          draftDueDate={settings.draftDueDate}
          draftSViewReports={settings.draftSViewReports}
          setNoDraft={(noDraft) => form.setFieldValue("noDraft", noDraft)}
          setDraftSViewReports={(draftSViewReports) =>
            form.setFieldValue("draftSViewReports", draftSViewReports)
          }
          onDateClick={() => setDatesStep(Step.Draft)}
          hasCanvasAGS={hasCanvasAGS}
          gradingService={settings.gradingService}
          isMultiFormat={format === TaskFormat.Multiformat}
        />
      )}
      <WeightSetting
        noWeight={settings.noWeight}
        weight={settings.weight}
        setWeight={(weight) => form.setFieldValue("weight", weight)}
        setNoWeight={(noWeight) => form.setFieldValue("noWeight", noWeight)}
      />
      {format === TaskFormat.Classic && (
        <>
          <WordLimitSetting
            noLimit={settings.noLimit}
            wordLimit={settings.wordLimit}
            countReferences={settings.countReferences}
            setWordLimit={(wordLimit) =>
              form.setFieldValue("wordLimit", wordLimit)
            }
            setNoLimit={(noLimit) => form.setFieldValue("noLimit", noLimit)}
            setCountReferences={(countReferences) =>
              form.setFieldValue("countReferences", countReferences)
            }
          />
          <ReferencingStyleSetting
            noReferencing={settings.noReferencing}
            referencingStyle={settings.referencingStyle}
            setReferencingStyle={(referencingStyle) =>
              form.setFieldValue("referencingStyle", referencingStyle)
            }
            setNoReferencing={(noReferencing) =>
              form.setFieldValue("noReferencing", noReferencing)
            }
            footnoteReferencingEnabled={props.footnoteReferencingEnabled}
          />
        </>
      )}
    </>
  );
};
