import { styled } from "styled-components";

import { Button, Modal, Spacer, Text, Tooltip } from "@vericus/cadmus-ui";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import SpeedGraderIntro from "@/assets/class/speedgrader-intro.svg";
import { HelpIcon } from "@/ui/shared/HelpIcon";

interface SpeedGraderIntroProps {
  /**
   * Callback when the delete button was pressed
   */
  onConfirm: () => void;
  /**
   * Callback when the cancel button was pressed
   */
  onCancel: () => void;
}

export const SpeedGraderIntroModal = NiceModal.create<SpeedGraderIntroProps>(
  (props) => {
    const modal = useModal();

    return (
      <Modal.Root
        open={modal.visible}
        onOpenChange={(open) => {
          if (!open) {
            props.onCancel();
            modal.hide();
          }
        }}
      >
        <Modal.Content
          closeButtonColor="white100"
          onExitComplete={modal.remove}
        >
          <Container>
            <img
              src={SpeedGraderIntro}
              alt="outline of speed grader filter settings"
            />
            <Spacer spacing={40} />
            <Content>
              <Text kind={"headingFive"}>
                Ensure SpeedGrader is showing All Sections
              </Text>
              <Text kind="bodyMd">
                To make sure you’re always directed to the correct student
                submission, check that SpeedGrader is set to ‘
                <b>Show All Sections</b>’ on the submission before you start
                marking
                <Tooltip
                  content={
                    "Canvas defaults to the last Sections filter you applied in the Gradebook or SpeedGrader."
                  }
                >
                  <HelpIcon />
                </Tooltip>
              </Text>
            </Content>
            <Spacer spacing={24} />
            <div>
              <Button
                onClick={() => {
                  props.onConfirm();
                  modal.hide();
                }}
                kind={"secondary"}
              >
                Continue to submission
              </Button>
            </div>
            <Spacer spacing={40} />
          </Container>
        </Modal.Content>
      </Modal.Root>
    );
  }
);

const Container = styled.div`
  display: flex;
  width: 618px;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    border-radius: 16px 16px 0px 0px;
  }
`;

const Content = styled.div`
  width: 464px;
`;
