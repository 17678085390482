import { useCallback, useState } from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";

import { EnrollmentFragment } from "@/generated/graphql";
import { TanStackTableMeta } from "@/typings/global";
import { StudentListMeta, StudentListRow } from "@/ui/class/progress/types";
import { isExam } from "@/ui/task";

import { useTagHandlers } from "../hooks";
import {
  DRAFT_COLUMNS,
  MARKING_COLUMNS,
  MODERATION_COLUMNS,
  PROGRESS_COLUMNS,
} from "../table";
import {
  ModerationScoreTableHookProps,
  useDraftsTableRows,
  useMarkingTableRows,
  useModerationScoreTableRows,
  useProgressTableRows,
} from "./useClassListRows";

/**
 * Augment a tanstack hook to keep track of table state such
 * as visible columns, selected rows, sorting, etc.
 */
function useTableInstanceWithState(
  data: StudentListRow[],
  // `any` instead of `unknown` for ColumnDef as we have columns with multiple types of value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<StudentListRow, any>[],
  meta: TanStackTableMeta,
  options: {
    visibilityState?: Partial<VisibilityState>;
    sortingState?: SortingState;
  } = {}
) {
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>(
    options.sortingState ?? []
  );
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    // default hidden columns
    email: false,
    flags: false,
    tags: false,
    ...options.visibilityState,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  // Update the sorting state but ensure that the withdrawn students are always
  // sorted at the end.
  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    (update) => {
      if (typeof update === "function") {
        setSorting((old) => {
          const updated = update(old);
          return [{ id: "withdrawn", desc: false }].concat(updated);
        });
      } else {
        setSorting([{ id: "withdrawn", desc: false }].concat(update));
      }
    },
    [setSorting]
  );

  return useReactTable<StudentListRow>({
    data,
    columns,
    meta,
    state: {
      rowSelection,
      sorting,
      columnVisibility,
      columnFilters,
      globalFilter,
    },
    filterFns: {},
    sortingFns: {},
    globalFilterFn: (row, columnId, filterValue) => {
      const value = row.getValue(columnId);
      const regex = new RegExp(filterValue, "ui");
      return !!(value && typeof value === "string" && regex.test(value));
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    enableMultiSort: true,
    enableSorting: true,
  });
}

export interface MarkingTableHookProps {
  /**
   * Complete list of ALL the enrollments.
   */
  enrollments: EnrollmentFragment[];
  /**
   * Enrollment IDs revealed by the teacher
   */
  revealedEnrollmentIds: string[];
  metaWithoutTagConfigs: Omit<StudentListMeta, "tagConfigs">;
}

/**
 * @returns a preconfigured table instance to display final submissions information.
 */
export function useMarkingTable({
  enrollments,
  revealedEnrollmentIds,
  metaWithoutTagConfigs,
}: MarkingTableHookProps) {
  const { rows, tagConfigs } = useMarkingTableRows(
    enrollments,
    metaWithoutTagConfigs.groups,
    revealedEnrollmentIds,
    metaWithoutTagConfigs.sheet
  );
  const meta: StudentListMeta = {
    ...metaWithoutTagConfigs,
    tagConfigs,
  };

  const hasTimeLimit = !!metaWithoutTagConfigs.sheet?.timeLimit;

  const table = useTableInstanceWithState(rows, MARKING_COLUMNS, meta, {
    visibilityState: {
      duration: hasTimeLimit ? true : false,
    },
    sortingState: [
      { id: "withdrawn", desc: false },
      { id: "deferred", desc: false },
      { id: "status", desc: false },
    ],
  });

  return { table, meta };
}

export interface DraftsTableHookProps {
  /**
   * Complete list of ALL the enrollments.
   */
  enrollments: EnrollmentFragment[];
  /**
   * Enrollment IDs revealed by the teacher
   */
  revealedEnrollmentIds: string[];
  metaWithoutTagConfigs: Omit<StudentListMeta, "tagConfigs">;
}

/**
 * @returns a preconfigured table instance to display draft submissions information.
 */
export function useDraftsTable({
  enrollments,
  revealedEnrollmentIds,
  metaWithoutTagConfigs,
}: DraftsTableHookProps) {
  const { rows, tagConfigs } = useDraftsTableRows(
    enrollments,
    metaWithoutTagConfigs.groups,
    revealedEnrollmentIds,
    metaWithoutTagConfigs.sheet
  );
  const meta: StudentListMeta = {
    ...metaWithoutTagConfigs,
    tagConfigs,
  };
  const table = useTableInstanceWithState(rows, DRAFT_COLUMNS, meta, {
    sortingState: [
      { id: "withdrawn", desc: false },
      { id: "deferred", desc: false },
      { id: "status", desc: false },
    ],
  });

  return { table, meta };
}

export interface ProgressTableHookProps {
  /**
   * Complete list of ALL the enrollments.
   */
  enrollments: EnrollmentFragment[];
  metaWithoutTagConfigs: Omit<StudentListMeta, "tagConfigs">;
}
/**
 * @returns a preconfigured table instance to display students' progress information.
 */
export function useProgressTable({
  enrollments,
  metaWithoutTagConfigs,
}: ProgressTableHookProps) {
  const { rows, tagConfigs } = useProgressTableRows(
    enrollments,
    metaWithoutTagConfigs.groups,
    metaWithoutTagConfigs.sheet
  );
  const isExamAssessment =
    !!metaWithoutTagConfigs.sheet && isExam(metaWithoutTagConfigs.sheet);
  const enabledAccessCode =
    metaWithoutTagConfigs.sheet?.enableExamAccessCode || false;

  const meta: StudentListMeta = {
    ...metaWithoutTagConfigs,
    tagConfigs,
  };
  const table = useTableInstanceWithState(rows, PROGRESS_COLUMNS, meta, {
    visibilityState: {
      accessCodeLabel: enabledAccessCode && isExamAssessment ? true : false,
    },
    sortingState: [
      { id: "withdrawn", desc: false },
      { id: "deferred", desc: false },
      { id: "progress", desc: false },
    ],
  });

  return { table, meta };
}

/**
 * @returns a pre-configured table instance to display students' moderation
 * score information
 */
export function useModerationScoreTable({
  enrollments,
  groups,
  markingSheet,
  workOutcomes,
  revealedEnrollmentIds,
  moderationPenaltyRules,
}: ModerationScoreTableHookProps) {
  // We assume that we work with Final submissions on Moderation page
  const { rows: studentListRows, tagConfigs } = useMarkingTableRows(
    enrollments,
    groups,
    revealedEnrollmentIds,
    markingSheet
  );

  // add work outcome summary data
  const rows = useModerationScoreTableRows(
    studentListRows,
    workOutcomes,
    moderationPenaltyRules
  );

  const tagHandlers = useTagHandlers(enrollments);

  // Tag handlers from table meta are still used in submission status column
  const meta = {
    tagConfigs,
    ...tagHandlers,
  };

  const table = useTableInstanceWithState(rows, MODERATION_COLUMNS, meta, {
    sortingState: [
      { id: "withdrawn", desc: false },
      { id: "deferred", desc: false },
      { id: "status", desc: false },
    ],
  });

  return { table, tagConfigs };
}
