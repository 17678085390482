import { styled } from "styled-components";

import {
  ControlAction,
  ControlButton,
  DropdownMenu,
  Text,
} from "@vericus/cadmus-ui";

import { useNavigate } from "react-router-dom";

import CadmusLogo from "@/assets/cadmus-logo.svg";

import * as styles from "./CadmusBar.css";

/**
 * A bar with:
 * - the Cadmus logo
 * - a settings menu
 * - a link to documentation
 */
export const CadmusBar = () => {
  return (
    <div className={styles.container}>
      <img className={styles.logo} src={CadmusLogo} alt="" />
      <div className={styles.rightHandSide}>
        <Settings />
        <a
          className={styles.anchor}
          href="https://support.cadmus.io"
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={-1}
        >
          <ControlAction iconName="Book">Help + docs</ControlAction>
        </a>
      </div>
    </div>
  );
};

/** Settings menu */
export const Settings = () => {
  const navigate = useNavigate();

  const onLogout = () => {
    navigate("/logout");
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <SettingsButton aria-label={"Settings"} iconName={"Settings"} />
      </DropdownMenu.Trigger>

      <DropdownMenu.Content>
        <DropdownMenu.Label>Settings</DropdownMenu.Label>
        <DropdownMenu.Item onSelect={onLogout}>
          <FailText kind="bodySm">Log out and Exit</FailText>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const FailText = styled(Text)`
  color: ${(p) => p.theme.fail};
`;

const SettingsButton = styled(ControlButton)`
  height: 39px;
`;
