import { Checkbox, Radio, Text } from "@vericus/cadmus-ui";

import { FeedbackLevel } from "@/generated/graphql";

import * as styles from "./feedback-visibility-form.css";

interface FeedbackVisibilityFormProps {
  /** Feedback visibility */
  visibility?: FeedbackLevel;
  onChangeVisibility: (visibility: FeedbackLevel) => void;
  allowPdfDownload?: boolean;
  onChangeAllowPdfDownload: (allowed: boolean) => void;
  readonly?: boolean;
}

/** Input form of feedback visbility */
export const FeedbackVisibilityForm = (props: FeedbackVisibilityFormProps) => (
  <div className={styles.visibilityForm}>
    <Text kind="headingThree" as="span" textAlign="center">
      Feedback visibility for students{" "}
    </Text>
    <Text kind="lead">
      You can control what students are permitted to see when they view the
      released feedback.
    </Text>
    <div className={styles.visibilityOptions}>
      <Radio
        name="visibility-input"
        value={FeedbackLevel.Mark}
        colorVariant="lilac500"
        disabled={props.readonly}
        onChange={() => {
          if (!props.readonly) props.onChangeVisibility(FeedbackLevel.Mark);
        }}
        checked={props.visibility === FeedbackLevel.Mark}
      >
        <Text kind="bodyMd" color="neutralBlack300">
          Students can see all answers, comments and marks
        </Text>
      </Radio>
      <Radio
        name="visibility-input"
        colorVariant="lilac500"
        value={FeedbackLevel.Feedback}
        disabled={props.readonly}
        onChange={() => {
          if (!props.readonly) props.onChangeVisibility(FeedbackLevel.Feedback);
        }}
        checked={props.visibility === FeedbackLevel.Feedback}
      >
        <Text kind="bodyMd" color="neutralBlack300">
          Students can see only their answers and comments
        </Text>
      </Radio>
      <Radio
        name="visibility-input"
        colorVariant="lilac500"
        value={FeedbackLevel.Answer}
        disabled={props.readonly}
        onChange={() => {
          if (!props.readonly) props.onChangeVisibility(FeedbackLevel.Answer);
        }}
        checked={props.visibility === FeedbackLevel.Answer}
      >
        <Text kind="bodyMd" color="neutralBlack300">
          Students can see only their marks
        </Text>
      </Radio>
    </div>
    <Checkbox
      colorVariant="lilac500"
      checked={props.allowPdfDownload}
      onChange={(e) => {
        props.onChangeAllowPdfDownload(e.target.checked);
      }}
      disabled={props.readonly}
      readOnly={props.readonly}
    >
      <Text kind="bodyMd" color="neutralBlack500">
        Allow students to download a PDF (Optional)
      </Text>
    </Checkbox>
  </div>
);
