import { styled } from "styled-components";

import {
  Button,
  Colored,
  colors,
  Divider,
  LinkButton,
  Modal,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import ReportConfirmationIllu from "@/assets/class/report-confirmation.png";

interface Props {
  /** User confirmation email */
  email: string;
  /** Callback to close the modal. */
  onClose: () => void;
}

/**
 * Modal shown on confirming a report generation job which results in the report
 * being sent to the user via email.
 */
export const ReportEmailModal = (props: Props) => {
  const { onClose, email } = props;

  return (
    <Modal.Root
      open
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <StyledModalContent width={542}>
        <IllustrationContainer>
          <Illustration
            src={ReportConfirmationIllu}
            alt="Abstract illustration of a teacher holding a magnifying glass over a long scroll of paper."
          />
        </IllustrationContainer>

        <Container>
          <Text kind="headingThree">
            Your Assessment Report is being generated
          </Text>
          <Text kind="bodyMd">
            Once it’s ready, we will email the report to:{" "}
            <LinkButton>{email}</LinkButton>
          </Text>
          <Text kind="bodySm" color="shade1">
            <strong>Note:</strong> Reports for large classes can take up to 10
            minutes to come through.
          </Text>
          <Spacer spacing={2} />
          <Divider />
          <Text kind="headingFive" color="shade1">
            Understand how to use{" "}
            <Colored color="main">Assessment Reports</Colored> for{" "}
            <Colored color="main">Academic Integrity</Colored>
          </Text>
          <Button
            onClick={() => {
              const win = window.open(
                "https://cadmus.io/getting-started/teachers/using-analytics-for-academic-integrity",
                "_blank"
              );
              if (win) {
                win.focus();
              }
            }}
            kind="secondary"
          >
            Watch Demo
          </Button>
        </Container>
      </StyledModalContent>
    </Modal.Root>
  );
};

const IllustrationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${colors.indigo100};
  border-radius: 16px 16px 0px 0px;
`;

const Illustration = styled.img`
  width: 320px;
`;

const StyledModalContent = styled(Modal.Content)`
  padding: 0px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: center;
  padding: 34px 70px 48px 70px;
`;
