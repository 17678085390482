import { Document, Element } from "domhandler";
import {
  existsOne,
  find,
  findOne,
  getAttributeValue,
  isTag,
  textContent,
} from "domutils";
import JSZip, { JSZipObject } from "jszip";
import { parseInt } from "lodash";
import { v4 as uuid } from "uuid";

import { Cardinality } from "@/generated/graphql";

import { ParsedQuestion } from "../../data";
import { convertQTIQuestion } from "./common";
import {
  ConditionVar,
  QTIFeedback,
  QTIMaterial,
  QTIMetadata,
  QTIOutcomes,
  QTIQuestion,
  QTIResponse,
  QTIResponseCondition,
  QTIResponseContent,
} from "./types";

/**
 * Parse QTIMetadata from qti files in both blackboard package file
 * and QTI1.x file
 **/
function parseMetadata(rawQuestion: Element): QTIMetadata {
  const itemmetadata = findOne(
    (e) => isTag(e) && e.tagName.toLowerCase() === "itemmetadata",
    rawQuestion.childNodes,
    false
  );
  if (!itemmetadata) return {};
  const qtiMetadataFields = find(
    (e) => isTag(e) && e.tagName.toLowerCase() === "qtimetadatafield",
    itemmetadata.childNodes,
    true,
    Number.MAX_SAFE_INTEGER
  ) as Element[];
  const bbMetadataFields = find(
    (e) => isTag(e) && /(bbmd|qmd)/i.test(e.tagName),
    itemmetadata.childNodes,
    false,
    Number.MAX_SAFE_INTEGER
  ) as Element[];
  if (qtiMetadataFields.length > 0) {
    return qtiMetadataFields.reduce((metadatas, metadata) => {
      const label = findOne(
        (e) => e.tagName.toLowerCase() === "fieldlabel",
        metadata.childNodes,
        false
      );
      const value = findOne(
        (e) => e.tagName.toLowerCase() === "fieldentry",
        metadata.childNodes,
        false
      );
      if (label && value) {
        metadatas[textContent(label)] = textContent(value);
      }
      return metadatas;
    }, {} as QTIMetadata);
  } else if (bbMetadataFields.length > 0) {
    return bbMetadataFields.reduce((metadatas, metadata) => {
      metadatas[metadata.tagName.toLowerCase().replace(/(bbmd|qmd)_/, "")] =
        textContent(metadata);
      return metadatas;
    }, {} as QTIMetadata);
  }
  return {};
}
// Find zero or one direct presentation element child of a parentElement
function getPresentation(parentElement: Element) {
  return findOne(
    (e) => e.tagName === "presentation",
    parentElement.childNodes,
    false
  );
}

// Map response's element rcardinality attribute to Cardinality enum
function getResponseCardinality(response: Element) {
  const rawCardinality = getAttributeValue(response, "rcardinality");
  switch (rawCardinality?.toLowerCase()) {
    case "multiple": {
      return Cardinality.Multiple;
    }
    case "ordered": {
      return Cardinality.Ordered;
    }
    case "single":
    default:
      return Cardinality.Single;
  }
}

// Parse feedbacks from qti's itemfeedback element(s)
function parseFeedbacks(rawQuestion: Element) {
  return find(
    (e) => isTag(e) && e.tagName.toLowerCase() === "itemfeedback",
    rawQuestion.childNodes,
    false,
    Number.MAX_SAFE_INTEGER
  ).map((e) => {
    const element = e as Element;
    return {
      id: getAttributeValue(element, "ident") ?? "",
      contents: getMaterials(element),
    } satisfies QTIFeedback;
  });
}

/*
 * Parse choices, word limit, shuffle from qti responses
 **/
function parseResponses(question: Element) {
  const presentation = getPresentation(question);
  if (!presentation) return [];
  const responses = find(
    (e) =>
      isTag(e) &&
      /response_(lid|xy|str|num|grp|extension|na)/.test(
        e.tagName.toLowerCase()
      ),
    presentation.childNodes,
    true,
    Number.MAX_SAFE_INTEGER
  ) as Element[];
  return responses.map((r) => {
    const id = getAttributeValue(r, "ident") ?? uuid();
    const cardinality = getResponseCardinality(r);
    const type = r.tagName.toLowerCase().split("_")?.[1];
    const wordLimitMax = getAttributeValue(r, "word_limit_max");
    const response: QTIResponse = {
      id,
      cardinality,
      type,
      wordLimit: wordLimitMax ? parseInt(wordLimitMax, 10) : undefined,
    };
    const choicesElement = findOne(
      (e) => isTag(e) && e.tagName.toLowerCase() === "render_choice",
      r.childNodes,
      false
    );
    if (choicesElement) {
      const shuffle = getAttributeValue(choicesElement, "shuffle");
      const responseLabels = find(
        (e) => isTag(e) && e.tagName.toLowerCase() === "response_label",
        choicesElement.childNodes,
        true,
        Number.MAX_SAFE_INTEGER
      ) as Element[];
      response.choiceResponse = {
        shuffle: shuffle && shuffle === "Yes" ? true : false,
        choices: responseLabels.flatMap((rl) => parseResponse(rl)),
      };
    }
    return response;
  });
}

/**
 * Recursively parse nested QTI materials
 **/
function getMaterials(element: Element): QTIMaterial[] {
  const maybeMaterials = find(
    (e) =>
      isTag(e) &&
      ["flow", "flow_mat", "flow_label", "material", "material_ref"].includes(
        e.tagName.toLowerCase()
      ),
    element.childNodes,
    false,
    Number.MAX_SAFE_INTEGER
  ) as Element[];
  return maybeMaterials.flatMap((m) => {
    if (m.tagName.toLowerCase() === "material") {
      return parseMaterial(m);
    }
    // TODO: parse material_ref as well
    return getMaterials(m);
  });
}

function parseResponse(responseLabel: Element): QTIResponseContent[] {
  const rawMaterial = getMaterials(responseLabel);
  if (!rawMaterial) return [];
  return [
    {
      id: getAttributeValue(responseLabel, "ident") ?? uuid(),
      contents: rawMaterial.flat(),
    },
  ];
}

function getResprocessing(rawQuestion: Element) {
  return findOne(
    (e) => isTag(e) && e.tagName.toLowerCase() === "resprocessing",
    rawQuestion.childNodes,
    false
  );
}

function parseOutcomes(
  resprocessing: Element,
  metadata: QTIMetadata
): QTIOutcomes {
  const absoluteMaxPoints = (metadata?.["absolutescore_max"] ??
    metadata?.["points_possible"]) as unknown as string | undefined;
  const decVars = find(
    (e) => isTag(e) && e.tagName.toLowerCase() === "decvar",
    resprocessing.childNodes,
    true,
    Number.MAX_SAFE_INTEGER
  ) as Element[];

  const outcomes = decVars.reduce((outcomes, decVar) => {
    const name = getAttributeValue(decVar, "varname");
    const type = getAttributeValue(decVar, "vartype");
    const minvalue = getAttributeValue(decVar, "minvalue");
    const maxvalue = getAttributeValue(decVar, "maxvalue");
    const defaultvalue = getAttributeValue(decVar, "defaultval");
    const cutValue = getAttributeValue(decVar, "cutvalue");
    if (name && type) {
      outcomes[name] = {
        type,
        min: parseNumberVar(type, minvalue),
        max: parseNumberVar(type, maxvalue),
        default: parseNumberVar(type, defaultvalue) ?? 0,
        cut: parseNumberVar(type, cutValue),
      };
    }
    return outcomes;
  }, {} as QTIOutcomes);
  const outcomesTotalPoints = Object.entries(outcomes).reduce(
    (acc, [_name, { max }]) => acc + (max ?? 0),
    0
  );
  return Object.entries(outcomes).reduce((acc, [name, outcome]) => {
    acc[name] = {
      ...outcome,
      max:
        absoluteMaxPoints && outcome.max && outcomesTotalPoints
          ? (outcome.max / outcomesTotalPoints) * parseFloat(absoluteMaxPoints)
          : !outcome.max || outcome.max >= 100
            ? 1
            : outcome.max,
    };
    return acc;
  }, {} as QTIOutcomes);
}

function parseNumberVar(type: string, decVar?: string): number | undefined {
  if (!decVar) return;
  switch (type) {
    case "Decimal": {
      return parseFloat(decVar);
    }
    case "integer": {
      return parseInt(decVar, 10);
    }
    default: {
      return;
    }
  }
}

function parseRespConditions(resprocessing: Element): QTIResponseCondition[] {
  const respConditions = find(
    (e) => isTag(e) && e.tagName.toLowerCase() === "respcondition",
    resprocessing.childNodes,
    false,
    Number.MAX_SAFE_INTEGER
  ) as Element[];

  return respConditions.flatMap((r) => {
    if (
      !existsOne(
        (e) => isTag(e) && e.tagName.toLowerCase() === "setvar",
        r.childNodes
      )
    ) {
      return [];
    }
    const respCondition = parseRespCondition(r);
    return respCondition ? [respCondition] : [];
  });
}

function parseRespCondition(
  respCondition: Element
): QTIResponseCondition | undefined {
  const setVars = find(
    (e) => isTag(e) && e.tagName.toLowerCase() === "setvar",
    respCondition.childNodes,
    false,
    Number.MAX_SAFE_INTEGER
  ) as Element[];
  const conditionVars = find(
    (e) => isTag(e) && e.tagName.toLowerCase() === "conditionvar",
    respCondition.childNodes,
    false,
    Number.MAX_SAFE_INTEGER
  ) as Element[];
  if (setVars?.length === 0) return;
  return {
    id: getAttributeValue(respCondition, "ident"),
    vars: setVars.map((v) => ({
      name: getAttributeValue(v, "varname") ?? "SCORE",
      value: textContent(v),
      action: getAttributeValue(v, "action"),
    })),
    conditions: conditionVars.flatMap((c) => parseRespConditionCondition(c)),
  };
}

function parseRespConditionCondition(
  conditionElement: Element
): ConditionVar[] {
  return find(
    (e) =>
      isTag(e) &&
      [
        "and",
        "or",
        "not",
        "varequal",
        "varlt",
        "varlte",
        "vargt",
        "vargte",
      ].includes(e.tagName.toLowerCase()),
    conditionElement.childNodes,
    false,
    Number.MAX_SAFE_INTEGER
  ).map((e) => {
    const element = e as Element;
    const tagName = element.tagName.toLowerCase();
    if (["and", "or", "not"].includes(tagName)) {
      return {
        operation: tagName,
        conditions: parseRespConditionCondition(element),
      };
    } else {
      return {
        condition: {
          value: textContent(element),
          id: getAttributeValue(element, "respident") ?? "",
          comparator: getRespComparator(tagName),
        },
      };
    }
  });
}

function getRespComparator(varElementTagName: string) {
  if (varElementTagName === "varequal") {
    return "eq";
  } else if (
    ["vargt", "varlt", "vargte", "varlte"].includes(varElementTagName)
  ) {
    return varElementTagName.replace("var", "");
  }
  return;
}

function parseMaterial(material: Element): QTIMaterial[] {
  const materials = find(
    (e) =>
      isTag(e) &&
      /(mattext|matemtext|matbreak|matimage|mataudio|matvideo|matapplet|matapplication|mat_extension|mat_formattedtext|altmaterial)/i.test(
        e.tagName
      ),
    material.childNodes,
    false,
    Number.MAX_SAFE_INTEGER
  ) as Element[];
  return materials.flatMap((m) => {
    const tagName = m.tagName.toLowerCase();
    if (["mat_extension", "altmaterial"].includes(tagName)) {
      return parseMaterial(m);
    } else if (
      ["mattext", "matemtext", "mat_formattedtext"].includes(tagName)
    ) {
      const type = ["mat_formattedtext", "mattext"].includes(tagName)
        ? "text"
        : "emphasized text";
      const textType =
        tagName === "mat_formattedtext"
          ? getAttributeValue(m, "type")
          : getAttributeValue(m, "texttype");
      const text = textContent(m);
      return [
        {
          type,
          textType,
          text,
        },
      ] satisfies QTIMaterial[];
    } else {
      return [
        {
          type: tagName.replace("mat", ""),
        },
      ] satisfies QTIMaterial[];
    }
  });
}

/**
 * Parse individual qti's item as QTIQuestion
 **/
function parseQTIQuestion(rawQuestion: Element): QTIQuestion | undefined {
  const responses = parseResponses(rawQuestion);
  const metadata = parseMetadata(rawQuestion);
  const presentation = getPresentation(rawQuestion);
  const resprocessing = getResprocessing(rawQuestion);
  if (!presentation || !resprocessing) return undefined;
  const contents = getMaterials(presentation);
  const outcomes = parseOutcomes(resprocessing, metadata);
  const respConditions = parseRespConditions(resprocessing);
  const feedbacks = parseFeedbacks(rawQuestion);
  return {
    responses,
    metadata,
    contents,
    outcomes,
    respConditions,
    feedbacks,
  };
}

async function parseSection(
  section: Element,
  file?: JSZipObject | null,
  zip?: JSZip
) {
  if (!section) return [];
  const questions: Omit<ParsedQuestion, "fileOriginId">[] = [];
  const questionElements = find(
    (e) => isTag(e) && e.tagName === "item",
    section.childNodes,
    false,
    Number.MAX_SAFE_INTEGER
  ) as Element[];
  for await (const currQuestion of questionElements) {
    const qtiQuestion = parseQTIQuestion(currQuestion);
    const parsedQuestion = await convertQTIQuestion(qtiQuestion, file, zip);
    if (parsedQuestion) {
      questions.push(parsedQuestion);
    }
  }
  return questions;
}

/**
 * parse section like(section, objectbank & assessment) objects from qti xml
 **/
async function parseQuestions(
  parsedXML: Document,
  file?: JSZipObject | null,
  zip?: JSZip
) {
  const root = findOne(
    (e) => e.tagName === "questestinterop",
    parsedXML.childNodes,
    false
  );
  if (!root) return [];
  const assessments = find(
    (e) => isTag(e) && e.tagName === "assessment",
    root.childNodes,
    false,
    Number.MAX_SAFE_INTEGER
  ) as Element[];
  if (assessments && Array.isArray(assessments) && assessments.length > 0) {
    return await Promise.all(
      assessments.flatMap(async (assessment) => {
        const section = findOne(
          (e) => e.tagName === "section",
          assessment.childNodes,
          false
        );
        return section ? await parseSection(section, file, zip) : [];
      })
    );
  } else {
    const sectionOrQuestionBank = findOne(
      (e) => ["section", "objectbank"].includes(e.tagName),
      root.childNodes,
      false
    );
    return sectionOrQuestionBank
      ? await parseSection(sectionOrQuestionBank, file, zip)
      : [];
  }
}

export const parser = {
  parseQuestions,
};
