import {
  generateActivityReport,
  generateAssessmentReport,
  syncClassList,
} from "@/data/class";
import { useAppDispatch } from "@/data/hooks";
import {
  AssessmentFragment,
  EnrollmentFragment,
  GroupFragment,
  InstructionSheetFragment,
} from "@/generated/graphql";
import { StudentListMeta } from "@/ui/class/progress/types";
import { exportGrades } from "@/ui/class/progress/utils";

import { useFilteredGroups } from "../groups/helpers";
import { isExam } from "../task";
import { contactStudents } from "./contactStudents";
import {
  useAnonymityHandlers,
  useExtensionHandlers,
  useGroupMembershipHandler,
  useTagHandlers,
} from "./hooks";
import { useDownloadSubmissions } from "./useDownloadSubmissions";
import { useUpdateEnrollmentsStatus } from "./useEnrollmentMutation";
import { useForceSubmissions } from "./useForceSubmissions";

type StudentListMetaWithoutTagConfigs = Omit<StudentListMeta, "tagConfigs">;
interface StudentListMetaProps {
  assessment: Pick<AssessmentFragment, "id" | "name">;
  enrollments: EnrollmentFragment[];
  instructionSheet: InstructionSheetFragment | null;
  groups: GroupFragment[];
  revealedEnrollmentIds: string[];
  teacherEmail: string | null;
}

export function useStudentListMeta({
  assessment,
  enrollments,
  instructionSheet,
  groups,
  revealedEnrollmentIds,
  teacherEmail,
}: StudentListMetaProps): StudentListMetaWithoutTagConfigs {
  const dispatch = useAppDispatch();

  // Download submissions mutation as a callback
  const downloadSubmissions = useDownloadSubmissions();

  const updateEnrollmentStatus = useUpdateEnrollmentsStatus(assessment.id);

  // Callback to force final submissions if allowed
  const forceSubmissions = useForceSubmissions(
    assessment.id,
    instructionSheet?.format
  );

  const { onUpdateGroupMemberships } = useGroupMembershipHandler(
    assessment.id,
    groups,
    dispatch
  );

  const { filteredGroups } = useFilteredGroups({ groups, enrollments });

  // Tags
  const tagHandlers = useTagHandlers(enrollments);

  // Extensions
  const { clearExtensions, editExtensions } = useExtensionHandlers(
    assessment,
    instructionSheet,
    filteredGroups
  );

  // Anonymity
  const { revealEnrollment, revealEnrollments, hideEnrollments } =
    useAnonymityHandlers(dispatch);

  // Callback to generate and view the Student Activity Report
  const viewActivityReport = (
    enrollment: EnrollmentFragment,
    anonymousName?: string
  ) => {
    if (enrollment.work) {
      dispatch(
        generateActivityReport({
          assessmentId: assessment.id,
          workId: enrollment.work.id,
          anonymousName,
        })
      );
    }
  };

  // Callback to generate and view the Assessment Summary Report
  const viewAssessmentReport = () => {
    dispatch(
      generateAssessmentReport({
        assessmentId: assessment.id,
        email: teacherEmail ?? "",
      })
    );
  };

  // Callback to download the grades CSV
  const downloadGrades = () => {
    exportGrades(
      assessment.name,
      enrollments,
      groups,
      !!instructionSheet?.timeLimit
    );
  };

  const metaWithoutTagConfigs: StudentListMetaWithoutTagConfigs = {
    groups: filteredGroups,
    sheet: instructionSheet ?? undefined,
    contactStudents,
    editExtensions,
    clearExtensions,
    ...tagHandlers,
    updateGroupMemberships: onUpdateGroupMemberships,
    downloadSubmission: (enrollment, submissionType) => {
      downloadSubmissions(
        {
          enrollments: [enrollment],
          submissionType,
          assessmentId: assessment.id,
          assessmentName: assessment.name,
          anonymousMarking: instructionSheet?.anonymousMarking === true,
          revealedEnrollmentIds: revealedEnrollmentIds,
          taskFormat: instructionSheet?.format ?? null,
        },
        // isBulkOperation
        false
      );
    },
    downloadSubmissions: (enrollments, submissionType) =>
      downloadSubmissions(
        {
          enrollments,
          submissionType,
          assessmentId: assessment.id,
          assessmentName: assessment.name,
          anonymousMarking: instructionSheet?.anonymousMarking === true,
          revealedEnrollmentIds: revealedEnrollmentIds,
          taskFormat: instructionSheet?.format ?? null,
        },
        // isBulkOperation
        true
      ),
    forceSubmissions:
      !!instructionSheet && isExam(instructionSheet)
        ? forceSubmissions
        : undefined,
    downloadGrades,
    viewActivityReport,
    viewAssessmentReport,
    syncClassList: () => {
      dispatch(syncClassList({ assessmentId: assessment.id }));
    },
    revealEnrollment,
    revealEnrollments,
    hideEnrollments,
    updateEnrollmentStatus,
  };
  return metaWithoutTagConfigs;
}
