import { StrictMode, Suspense, useEffect } from "react";

import "@vericus/cadmus-common/dist/style.css";
import { CssBaseline, CUIProvider } from "@vericus/cadmus-ui";
import "@vericus/cadmus-ui/dist/index.css";

import { ApolloProvider } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { JitsuProvider } from "@jitsu/react";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "core-js/features/string/match-all";
import "core-js/features/string/replace-all";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import "regenerator-runtime/runtime";
import { Userpilot } from "userpilot";

import apolloClient from "@/client/apollo";
import jitsuClient from "@/client/jitsu";
import { connection, PhoenixContext } from "@/client/phoenix";
import * as config from "@/config";
import reduxStore from "@/data/store";
import routes from "@/router/routes";
import { CrashError } from "@/ui/errors";
import { Loading } from "@/ui/shared/Loading";

import { theme } from "./index.css";
import { MantineProvider } from "./styles/mantine";
import { registerTemplatesModals } from "./ui/templates";

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "mathlive/dist/mathlive-static.css";
import "styles/third-party.css";

// Create Connection to Sentry
Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: config.DEPLOY_ENV,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.8,
  release: config.RELEASE_HASH,
});

// Google Analytics
const gaKey = config.GA_KEY;
if (gaKey) {
  ReactGA.initialize(gaKey);
} else {
  // local dummy setup
  ReactGA.initialize("UA-000000-01", {
    debug: config.GA_DEBUG === "true",
  });
}

// Initialise userpilot for the deployed environment.
const userpilotToken = config.USERPILOT_TOKEN;
if (userpilotToken) {
  Userpilot.initialize(userpilotToken);
}

// Default CUI theme is athena's blue theme.

// Register template modals with modal state management
registerTemplatesModals();

// React root
const container = document.getElementById("root");
const root = createRoot(container!);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes);

const queryClient = new QueryClient();

if (config.DEV) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

root.render(
  <StrictMode>
    <MantineProvider>
      <CUIProvider theme={theme}>
        <Sentry.ErrorBoundary fallback={<CrashError />}>
          <PhoenixContext.Provider value={connection}>
            <JitsuProvider client={jitsuClient}>
              <ApolloProvider client={apolloClient}>
                <Provider store={reduxStore}>
                  <QueryClientProvider client={queryClient}>
                    <Suspense fallback={<Loading />}>
                      <CssBaseline />
                      <RouterProvider router={router} />
                    </Suspense>
                  </QueryClientProvider>
                </Provider>
              </ApolloProvider>
            </JitsuProvider>
          </PhoenixContext.Provider>
        </Sentry.ErrorBoundary>
      </CUIProvider>
    </MantineProvider>
  </StrictMode>
);
