import { ChangeEvent, useCallback, useState } from "react";
import { styled } from "styled-components";

import { Icon } from "@vericus/cadmus-icons";
import { Input, Radio, Spacer, Text } from "@vericus/cadmus-ui";

import { startOfMinute } from "date-fns";

import { useAppSelector } from "@/data/hooks";
import { selectTaskTotalPoints } from "@/features/multi-format/task-layout";
import { MarkingToolPdf, TaskFormat } from "@/generated/graphql";
import { useInstitutionFeaturesFragment } from "@/graphql/institution-selectors";
import { HeraLaunch, useHeraLaunch } from "@/utils/useHeraLaunch";

import { RestrictStudentView } from "../../components/restrict-student-view";
import { MarkingToolRadio } from "../AssignmentSettings/MarkingToolRadio";
import { SettingInfoCard } from "../SettingInfoCard";
import { FadeIn, SettingContainer } from "../styles";
import { useSettingsFormContext } from "../use-settings-form";
import { DateTimePicker } from "./DateTimePicker";
import { StyledLinkButton } from "./shared";

export interface ExamMarkingSettingProps {
  hasCanvasAGS?: boolean;
  assessmentId?: string;
  hasSubmissions?: boolean;
  format?: TaskFormat;
}

/**
 * Allow teachers to change these marking settings:
 * - Marking tool
 * - Total points
 * - Weighting
 * - Anonymous marking
 * - Feedback return date
 */
export const ExamMarkingSetting = ({
  hasCanvasAGS,
  assessmentId,
  hasSubmissions,
  format,
}: ExamMarkingSettingProps) => {
  const settingsForm = useSettingsFormContext();

  const {
    examFeedbackDate,
    noExamFeedbackDate,
    anonymousMarking,
    noWeight,
    gradingService,
    maxGrade,
    weight,
    restrictMarkingToolPdf,
    markingToolPdf,
  } = settingsForm.values;

  const taskTotalPoints = useAppSelector(selectTaskTotalPoints);

  const onHeraLaunch = useHeraLaunch({
    launch: HeraLaunch.Settings,
    assessmentId,
  });

  const {
    moodlegraderFeatureEnabled,
    turnitinGraderFeatureEnabled,
    cadmusGraderFeatureEnabled,
  } = useInstitutionFeaturesFragment();

  const isWeightingExpanded = !noWeight;
  const isAnonymouslyMarked = anonymousMarking ?? false;
  const feedbackReturnDate = examFeedbackDate
    ? startOfMinute(new Date(examFeedbackDate))
    : undefined;

  const [localMaxGrade, setLocalMaxGrade] = useState(maxGrade);
  const validateMaxGradeOnBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const validatedValue = validateInteger(e.currentTarget.value, maxGrade);
      setLocalMaxGrade(validatedValue);
      settingsForm.setFieldValue("maxGrade", validatedValue);
      settingsForm.validateField("maxGrade");
    },
    [settingsForm, setLocalMaxGrade, maxGrade]
  );

  const [localWeight, setLocalWeight] = useState(weight);
  const validateWeightOnBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const validatedValue = validateInteger(e.currentTarget.value, weight);

      setLocalWeight(validatedValue);
      settingsForm.setFieldValue("weight", validatedValue);
    },
    [settingsForm, weight]
  );

  return (
    <SettingContainer>
      <Text kind="headingOne">Marking</Text>
      <MarkingToolRadio
        gradingService={gradingService}
        setGradingService={(gradingService) => {
          settingsForm.setFieldValue("gradingService", gradingService);
        }}
        hasSpeedGrader={hasCanvasAGS}
        hasMoodleGrader={moodlegraderFeatureEnabled}
        hasTurnitinGrader={turnitinGraderFeatureEnabled}
        hasCadmusGrader={
          cadmusGraderFeatureEnabled && format === TaskFormat.Multiformat
        }
      />
      <Spacer spacing={36} />
      {gradingService === "turnitin" && (
        <>
          <Text kind="headingFive">Total points</Text>
          <Text kind="bodySm">
            The exam will be marked out of{" "}
            <StyledInput
              type="number"
              aria-label="total points in the exam"
              aria-invalid={!!settingsForm.errors.maxGrade}
              value={`${
                format === TaskFormat.Multiformat
                  ? taskTotalPoints
                  : localMaxGrade
              }`}
              disabled={format === TaskFormat.Multiformat}
              min={0}
              max={1000}
              onChange={(e) => setLocalMaxGrade(parseInt(e.target.value))}
              onBlur={(event) => validateMaxGradeOnBlur(event)}
              errorMessage={settingsForm.errors.maxGrade}
            />{" "}
            points
          </Text>
          <Spacer spacing={60} />
        </>
      )}

      <Text kind="headingFive">
        Weighting is{" "}
        <StyledLinkButton
          inline
          aria-label={`Weighting is ${!noWeight ? "on" : "off"}`}
          onClick={() => settingsForm.setFieldValue("noWeight", !noWeight)}
        >
          {isWeightingExpanded ? "on" : "off"}
        </StyledLinkButton>
      </Text>
      <Spacer spacing={16} />
      <SettingInfoCard backgroundColor="blue" showNewPill={false}>
        Inform students of how much the exam is worth. This does not affect how
        grades are returned to the Learning Management System (e.g. Grade
        Centre)
      </SettingInfoCard>
      {isWeightingExpanded && (
        <FadeIn>
          <Spacer spacing={16} />
          <Text kind="bodySm">
            This exam is worth{" "}
            <StyledInput
              type="number"
              aria-label="Exam weighting (percentage of the unit)"
              min={0}
              value={`${localWeight}`}
              onChange={(e) => setLocalWeight(parseInt(e.currentTarget.value))}
              onBlur={validateWeightOnBlur}
            />{" "}
            % of the unit
          </Text>
        </FadeIn>
      )}

      <Spacer spacing={60} />

      <AnonymousMarking
        hasSubmissions={hasSubmissions ?? false}
        isAnonymouslyMarked={isAnonymouslyMarked}
        setAnonymousMarking={(anonymousMarking) =>
          settingsForm.setFieldValue("anonymousMarking", anonymousMarking)
        }
        gradingService={gradingService}
      />
      <Spacer spacing={60} />

      {format === TaskFormat.Multiformat &&
        ["speedgrader", "moodlegrader"].includes(gradingService) && (
          <>
            <RestrictStudentView
              restrictMarkingToolPdf={restrictMarkingToolPdf}
              setRestrictStudentResultView={(value: boolean) =>
                settingsForm.setFieldValue("restrictMarkingToolPdf", value)
              }
              markingToolPdf={markingToolPdf}
              setStudentResultView={(value: MarkingToolPdf) =>
                settingsForm.setFieldValue("markingToolPdf", value)
              }
            />
            <Spacer spacing={36} />
          </>
        )}

      <Spacer spacing={60} />
      <StyledLinkButton iconName="Turnitin" onClick={onHeraLaunch}>
        View advanced Turnitin settings
      </StyledLinkButton>

      <Spacer spacing={60} />

      {gradingService !== "cadmusgrader" && (
        <>
          <Text kind="headingFive">
            Written feedback return is{" "}
            <StyledLinkButton
              inline
              aria-label={`Feedback return is ${
                !noExamFeedbackDate ? "on" : "off"
              }`}
              onClick={() => {
                if (!noExamFeedbackDate) {
                  settingsForm.setFieldValue("examFeedbackDate", null);
                }
                settingsForm.setFieldValue(
                  "noExamFeedbackDate",
                  !noExamFeedbackDate
                );
              }}
            >
              {!noExamFeedbackDate ? "on" : "off"}
            </StyledLinkButton>
          </Text>

          {!noExamFeedbackDate ? (
            <FadeIn>
              <Text kind="bodySm" as="span">
                Students can access written feedback and grades via Cadmus after{" "}
              </Text>
              <DateTimePicker
                width={195}
                aria-label="Feedback return date"
                onChange={(date) =>
                  settingsForm.setFieldValue(
                    "examFeedbackDate",
                    date.toISOString()
                  )
                }
                onBlur={() => settingsForm.validateField("examFeedbackDate")}
                value={feedbackReturnDate}
                errorMessage={settingsForm.errors.examFeedbackDate}
              />
            </FadeIn>
          ) : (
            <Text kind="bodySm">
              Only grades will be returned to the LMS, students will not be
              accessing written feedback via Cadmus.
            </Text>
          )}
        </>
      )}
    </SettingContainer>
  );
};

interface AnonymousMarkingProps {
  hasSubmissions: boolean;
  isAnonymouslyMarked: boolean;
  setAnonymousMarking: (anonymous: boolean | null) => void;
  gradingService: string;
}

const AnonymousMarking = (props: AnonymousMarkingProps) => {
  const { hasSubmissions, setAnonymousMarking, isAnonymouslyMarked } = props;

  // Addendum on passing anonymous marking setting to Turnitin
  const feedbackStudio =
    props.gradingService === "turnitin" ? " and Feedback Studio" : "";

  return (
    <>
      <Text kind="headingFive">Anonymous marking</Text>
      <Spacer spacing={16} />
      <SettingInfoCard backgroundColor="blue" showNewPill={false}>
        Hide student names on submissions with anonymous marking. Anonymity
        settings cannot be changed once students have submitted.
      </SettingInfoCard>
      <Spacer spacing={16} />
      <div role="radiogroup" aria-label="Anonymous marking">
        <Radio
          disabled={hasSubmissions}
          checked={!isAnonymouslyMarked}
          content={`Show student names in the class list${feedbackStudio}`}
          onChange={() => setAnonymousMarking(false)}
        />
        <Radio
          disabled={hasSubmissions}
          checked={isAnonymouslyMarked}
          content={`Hide student names for anonymity in the class list${feedbackStudio}`}
          onChange={() => setAnonymousMarking(true)}
        />
      </div>
      {props.gradingService === "speedgrader" && (
        <>
          <Spacer spacing={18} />
          <a
            href="https://support.cadmus.io/setting-requirements-canvas#marking-feedback"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledLinkButton kind="solid">
              {`Remember to ${
                props.isAnonymouslyMarked ? "hide" : "show"
              } student names in SpeedGrader `}
              <Icon iconName="Right" />
            </StyledLinkButton>
          </a>
        </>
      )}
    </>
  );
};

const StyledInput = styled(Input)`
  span& {
    min-width: 89px;
    width: 89px;
  }

  input {
    min-width: 89px;
    width: 89px;
  }
`;

/**
 * Given a string value, e.g. from an <input> it returns a valid integer.
 * @returns a valid integer in the range [0, 100]
 */
const validateInteger = <ValidT extends number | null>(
  value: string,
  fallback: ValidT
): ValidT => {
  const parsedValue = parseInt(value);
  let validValue: ValidT = fallback;
  if (parsedValue >= 0 && parsedValue <= 100) {
    validValue = parsedValue as ValidT;
  } else if (parsedValue > 100) {
    validValue = 100 as ValidT;
  } else if (parsedValue < 0) {
    validValue = 0 as ValidT;
  }
  return validValue;
};
