import { useCallback } from "react";
import { styled } from "styled-components";

import {
  Anchored,
  colors,
  desktop,
  Divider,
  Modal,
  Skeleton,
  tablet,
  Text,
} from "@vericus/cadmus-ui";

import { useJitsu } from "@jitsu/react";
import toast from "react-hot-toast";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { useAppSelector } from "@/data/hooks";
import {
  Category,
  ShowAs,
  Subcategory,
  Template,
  TemplateFilter,
  useFindTemplate,
  useSelectedCategory,
  useSelectedFilter,
  useSelectedSubcategory,
  useShowUiSkeletons,
  useUpdateTemplatesFilter,
  useViewableTemplates,
} from "@/data/templates";
import { selectAvailableFilters } from "@/data/templates/selectors";
import { logAppError } from "@/utils/logger";

import { SquareCard, SquareCardSkeleton, WideCard } from "./Cards";
import { useInsertHtmlContent, useIsEditorEmpty } from "./editorHooks";
import {
  useShowEditorContentReplacementModal,
  useShowTemplatePreviewModal,
} from "./modals";
import { TemplatesFilters, TemplatesFiltersSkeleton } from "./TemplatesFilters";
import { useOnCloseTemplatesModal } from "./TemplatesModal";

export interface TemplatesProps {
  isLoading?: boolean;
  templates: Template[];
  selectedCategory?: Category;
  selectedSubcategory?: Subcategory;
  availableFilters: readonly TemplateFilter[];
  selectedFilter?: TemplateFilter;
  onUpdateSelectedFilter: (newFilter: TemplateFilter) => void;
  onSelectTemplate: (templateId: string) => void;
  onPreviewTemplate: (templateId: string) => void;
}

export const Templates = ({
  availableFilters,
  isLoading,
  selectedCategory,
  selectedFilter,
  selectedSubcategory,
  templates,
  onPreviewTemplate,
  onSelectTemplate,
  onUpdateSelectedFilter,
}: TemplatesProps) => {
  const showAs: ShowAs = selectedSubcategory?.showAs || "square";
  if (isLoading) {
    return <TemplatesSkeleton />;
  }

  return (
    <Container>
      <Modal.Title asChild>
        <Text kind="headingThree" textAlign="center">
          {selectedSubcategory?.heading}
        </Text>
      </Modal.Title>

      <Modal.Description asChild>
        <SubcategoryText kind="bodyMd" as="div">
          {selectedSubcategory?.description ?? ""}
          <br />
          <Anchored newTab href={generateLearnMoreLink(selectedCategory)}>
            <LearnMoreLinkText
              kind="bodyMd"
              color="blue500"
              textAlign="center"
              as="span"
            >
              Learn more
            </LearnMoreLinkText>
          </Anchored>
        </SubcategoryText>
      </Modal.Description>

      {selectedCategory?.hasFilters && (
        <TemplateFiltersContainer>
          <TemplatesFilters
            availableFilters={availableFilters}
            onFilterSelected={onUpdateSelectedFilter}
            activeFilter={selectedFilter}
          />
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
        </TemplateFiltersContainer>
      )}

      {showAs === "square" && (
        <SquareCardGrid>
          {templates.map((template) => (
            <SquareCard
              key={template.id}
              {...template}
              onSelectTemplate={onSelectTemplate}
              onPreviewTemplate={onPreviewTemplate}
              subcategory={selectedSubcategory}
            />
          ))}
        </SquareCardGrid>
      )}
      {showAs === "wide" && (
        <WideCardGrid>
          {templates.map((template) => (
            <WideCard
              key={template.id}
              {...template}
              onSelectTemplate={onSelectTemplate}
              onPreviewTemplate={onPreviewTemplate}
            />
          ))}
        </WideCardGrid>
      )}
    </Container>
  );
};

const LearnMoreLinkText = styled(Text)`
  margin-top: 4px;
  font-size: 14px;
  ${desktop`
    font-size: 16px;
  `}
`;

export const ConnectedTemplates = () => {
  const templates = useViewableTemplates();
  const category = useSelectedCategory();
  const subcategory = useSelectedSubcategory();
  const updateSelectedFilter = useUpdateTemplatesFilter();

  const isLoading = useShowUiSkeletons();
  const onSelectTemplate = useOnSelectTemplate();
  const onPreviewTemplate = useOnPreviewTemplate();
  const selectedFilter = useSelectedFilter();
  const availableFilters = useAppSelector(selectAvailableFilters);
  return (
    <Templates
      isLoading={isLoading}
      availableFilters={availableFilters}
      templates={templates}
      selectedCategory={category}
      selectedSubcategory={subcategory}
      onUpdateSelectedFilter={updateSelectedFilter}
      onSelectTemplate={onSelectTemplate}
      onPreviewTemplate={onPreviewTemplate}
      selectedFilter={selectedFilter}
    />
  );
};

const useOnPreviewTemplate = () => {
  const showPreviewModal = useShowTemplatePreviewModal();
  const { track } = useJitsu();
  const findTemplate = useFindTemplate();
  const selectedSubcategory = useSelectedSubcategory();

  return useCallback(
    (templateId: string) => {
      showPreviewModal({ templateId });

      const template = findTemplate(templateId);
      if (template && selectedSubcategory) {
        track(JitsuEvent.TEMPLATE_PREVIEWED, {
          template_id: templateId,
          template_title: template.title,
          subcategory_id: selectedSubcategory.id,
          subcategory_title: selectedSubcategory.title,
        });
      }
    },
    [showPreviewModal, track, selectedSubcategory, findTemplate]
  );
};

const useOnSelectTemplate = () => {
  const selectedSubcategory = useSelectedSubcategory();
  const insertContent = useInsertHtmlContent();
  const showEditorContentReplacementModal =
    useShowEditorContentReplacementModal();
  const isEditorEmpty = useIsEditorEmpty();
  const closeTemplatesModal = useOnCloseTemplatesModal();
  const { track } = useJitsu();

  return useCallback(
    (templateId: string) => {
      const template = selectedSubcategory?.templates.find(
        (t) => t.id === templateId
      );
      if (!template) {
        logAppError(`Could not find template with id ${templateId}`);
        return;
      }
      if (isEditorEmpty) {
        insertContent(template.content);
        closeTemplatesModal();
        toast.success(`'${template.title}' template has been added`);
        if (selectedSubcategory) {
          track(JitsuEvent.TEMPLATE_USED, {
            template_id: template.id,
            template_title: template.title,
            subcategory_id: selectedSubcategory.id,
            subcategory_title: selectedSubcategory.title,
          });
        }
        return;
      }
      showEditorContentReplacementModal({
        useCase: { type: "template-replacement", template },
      });
    },
    [
      showEditorContentReplacementModal,
      isEditorEmpty,
      insertContent,
      selectedSubcategory,
      closeTemplatesModal,
      track,
    ]
  );
};

const generateLearnMoreLink = (category?: Category) => {
  return category?.helpLink
    ? category.helpLink
    : "https://www.cadmus.io/guides/understanding-cadmus-templates";
};

const SubcategoryText = styled(Text)`
  text-align: center;
  color: ${colors.black100};
  font-size: 14px;
  ${desktop`
    font-size: 16px;
  `}
`;

const Container = styled.div`
  padding: 48px 26px;
  ${tablet`
    padding: 48px 28px;
  `}
  ${desktop`
    padding: 64px 64px;
  `}

  width: 100%;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
`;

export const SquareCardGrid = styled.div<{
  gridGap?: number;
}>`
  display: grid;
  grid-template-columns: repeat(auto-fill, 156px);
  grid-gap: 12px;
  ${tablet`
    grid-template-columns: repeat(auto-fill, 212px);
  `}
  ${desktop`
    grid-template-columns: repeat(auto-fill, 212px);
    grid-gap: 18px;
  `}

  margin-top: 27px;
  justify-content: center;
  justify-items: center;
`;

export const WideCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  ${tablet`
    grid-template-columns: repeat(auto-fill, 328px);
  `}
  ${desktop`
    grid-template-columns: repeat(auto-fill, 328px);
  `}
  grid-gap: 18px;
  margin-top: 27px;
  justify-content: center;
  justify-items: center;
`;

const CardsSkeleton = ({ cards }: { cards: number }) => {
  const cardSkeletons = Array(cards).fill("_");
  return (
    <SquareCardGrid>
      {cardSkeletons.map((_, index) => (
        <SquareCardSkeleton key={index} />
      ))}
    </SquareCardGrid>
  );
};

const SubcategoryTitleSkeleton = styled(Skeleton)`
  height: 40px;
  margin-left: auto;
  margin-right: auto;
`;

const SubcategoryDescriptionSkeleton = styled(Skeleton)`
  height: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
`;

const TemplateFiltersContainer = styled.div`
  position: sticky;
  top: 0;
  padding-top: 36px;
  z-index: 1;
  background-color: ${colors.white100};
`;

const TemplatesSkeleton = () => {
  return (
    <Container>
      <SubcategoryTitleSkeleton width={80} />
      <SubcategoryDescriptionSkeleton width={90} />
      <TemplatesFiltersSkeleton filters={4} />
      <CardsSkeleton cards={9} />
    </Container>
  );
};
