import { useCallback } from "react";
import { styled } from "styled-components";

import { LinkButton, Text, Tooltip } from "@vericus/cadmus-ui";

import NiceModal from "@ebay/nice-modal-react";
import { useAppDispatch, useAppSelector } from "data/hooks";
import { trackSGIntroModalView } from "data/localFlags";
import { ResultFragment, TaskFormat } from "generated/graphql";
import { DataComponent } from "ui/shared/DataComponent";
import { OutlineButton } from "ui/shared/OutlineButton";
import { HeraLaunch, useHeraLaunch } from "utils/useHeraLaunch";

import { SpeedGraderIntroModal } from "./SpeedGraderIntroModal";

export interface Props extends DataComponent {
  /** Gradebook result for the submission */
  result: ResultFragment;
  /** Grading integration selected in the requriements */
  gradingService: string;
  /** Grade link is for a draft submission */
  isDraft: boolean;
  /**
   * Kind of CTA action to use: button or a link. Default is "button".
   */
  kind?: "button" | "link";
  /**
   * Assessment format
   */
  format?: TaskFormat;
  /**
   * Optional: When was the marking pdf sent to the grade service? (multiformat
   * pdf submission pipeline only).
   */
  markingPdfSentAt?: string | null;
}

// Maximum number of times a user should see the SG Intro Modal
const SPEED_GRADER_VIEW_COUNT_THRESHOLD = 2;

/**
 * Renders a link to launch the feedback studio for a result using
 * Hera.
 *
 * For Draft results, no grade is displayed.
 * For Final results, if there is a grade, it will be shown.
 */
export function GradingLink(props: Props) {
  const { isDraft, result, format, kind = "button" } = props;
  const dispatch = useAppDispatch();

  // Don't show the SG intro modal if the modal has been viewed enough times
  const skipSpeedGraderModal = useAppSelector(
    (state) =>
      state.localFlags.sgIntroModalViewCount >=
      SPEED_GRADER_VIEW_COUNT_THRESHOLD
  );

  const { active, service } = checkIntegration(props);

  // Create redirect link to send the user to the right integration
  const onHeraLaunch = useHeraLaunch({
    launch: service || HeraLaunch.Result,
    resultId: result.id,
    isDraft,
  });

  const onGradingLaunch = useCallback(() => {
    if (service === HeraLaunch.SpeedGrader && !skipSpeedGraderModal) {
      NiceModal.show(SpeedGraderIntroModal, {
        onCancel: () => {
          dispatch(trackSGIntroModalView());
        },
        onConfirm: () => {
          dispatch(trackSGIntroModalView(SPEED_GRADER_VIEW_COUNT_THRESHOLD));
          onHeraLaunch();
        },
      });
    } else {
      onHeraLaunch();
    }
  }, [onHeraLaunch, dispatch, service, skipSpeedGraderModal]);

  // Tooltip
  let tooltip: string | null = null;
  if (active) {
    tooltip = null;
  } else {
    if (format === TaskFormat.Classic) {
      tooltip = "Submission is being processed by the grading integration.";
    } else {
      tooltip =
        "You can start marking after the assessment due-date has passed and auto-marking has been calculated.";
    }
  }

  // The React Element to render as the Grading CTA action
  // It is either a link with the grades for final, or dependent on the `kind`
  // prop.
  let actionEl = null;

  // Show the grade for the final submission if it exists
  if (!isDraft && result.grade !== null) {
    actionEl = (
      <BoldLinkButton
        data-component={props["data-component"]}
        data-testid={"LaunchExternalFeedbackTool"}
        color="black200"
        onClick={onGradingLaunch}
        disabled={!active}
        aria-label="Mark submission"
      >
        {`${result.grade.display}/${result.grade.max}`}
      </BoldLinkButton>
    );
  } else {
    switch (kind) {
      case "link":
        actionEl = (
          <BoldLinkButton
            data-component={props["data-component"]}
            data-testid={"LaunchExternalFeedbackTool"}
            color="black200"
            onClick={onGradingLaunch}
            disabled={!active}
            aria-label="Open submission in marking tool"
          >
            Submission
          </BoldLinkButton>
        );
        break;

      case "button":
      default:
        actionEl = (
          <OutlineButton
            data-component={props["data-component"]}
            data-testid={"LaunchExternalFeedbackTool"}
            onClick={onGradingLaunch}
            disabled={!active}
            aria-label="Mark submission"
          >
            {isDraft ? "Feedback" : "Mark"}
          </OutlineButton>
        );
        break;
    }
  }

  if (!actionEl) {
    return null;
  }

  return (
    <Tooltip
      passThrough={tooltip === null}
      content={<Text kind="systemSm">{tooltip}</Text>}
    >
      {actionEl}
    </Tooltip>
  );
}

// Check if a grading integration is active for the result.
function checkIntegration(props: Props): {
  active: boolean;
  service: HeraLaunch | null;
} {
  const { result, gradingService, format } = props;

  let active = false;

  let service: HeraLaunch | null = null;
  switch (gradingService) {
    case "turnitin":
      active = result.heraResultId !== null;
      service = HeraLaunch.Result;
      break;
    case "speedgrader":
      active = result.canvasResultUrl !== null;
      service = HeraLaunch.SpeedGrader;
      break;
    case "moodlegrader":
      active = result.moodleGraderUrl !== null;
      service = HeraLaunch.MoodleGrader;
      break;
    case "cadmusgrader":
      active = true;
      service = HeraLaunch.CadmusGrader;
      break;
  }

  // Active status for external marking integrations is affected by the AGS Sent At timestamp
  if (
    format === TaskFormat.Multiformat &&
    service !== HeraLaunch.CadmusGrader
  ) {
    active = active && props.markingPdfSentAt !== null;
  }

  return { active, service };
}

const BoldLinkButton = styled(LinkButton)`
  font-weight: 600;
`;
