import {
  EnrollmentFragment,
  ResultFragment,
  WorkArtifactFragment,
} from "@/generated/graphql";
import { EnrollmentSettings } from "@/graphql/types/EnrollmentSettings";
import {
  examLateMessage,
  getWorkDuration,
  lateMessage,
  overtimeMessage,
} from "@/ui/StudentLists/helpers";
import { toDate } from "@/utils/datetime";

/**
 * Normalised information on a DRAFT or FINAL `Submission`.
 */
export interface SubmissionDetails {
  /** Submission ID */
  id: string;
  /** Submission timestamp */
  timestamp: Date;
  /** Due date for the submission */
  dueDate: Date;
  /** Total available time limit */
  timeLimit: number | null;
  /** Time spent in minutes in a time limited assessment */
  timeSpentMinutes: number | null;
  /** Human readable lateness status. e.g. "2 days late" */
  late: string | null;
  /** Human readable overtime status. e.g: "2 min overtime" */
  overtime: string | null;
  /** The submission has a due date extension. */
  dueExtended: boolean;
  /** The submission has a time extension */
  timeExtended: boolean;
  /** Gradebook data on the submission. */
  result: ResultFragment;
  /** When did the student last view the result? */
  feedbackLastViewed: Date | null;
  /** Feedback view count by students. */
  feedbackViewCount: number;
  /** If extra time is granted as a special consideration */
  hasExtraTime: boolean;
  /** The submission was forced submitted */
  forceSubmitted: boolean;
  /** User that submitted the work */
  submittedBy?: string | null;
  /** Student have an alternate starting date for the exam. */
  hasAlternativeStart: boolean;
  /** Student have an alternate start/end date for the window exam. */
  hasAlternativeWindow: boolean;
  /** Student is marked as deferred for the exam. */
  deferred: boolean;
  /**  PDF artifact associated for the submission. */
  pdf: WorkArtifactFragment | null;
  /**  Marking PDF artifact associated for the submission. */
  markingPdf: WorkArtifactFragment | null;
  /** Submit time late by due date in minutes */
  minutesLateBy: number | null;
}

/**
 * Build a convenient object for displaying submission details.
 *
 * Takes in a bunch of fact objects, considers the individual enrollment's
 * work settings and calculates overall submission details.
 *
 * Returns `null` if the enrollment does not have a final submission yet.
 * @param enrollment
 * @param enrollmentSettings
 */
export const createSubmissionDetails = (
  enrollment: EnrollmentFragment,
  enrollmentSettings: EnrollmentSettings
): SubmissionDetails | null => {
  const dueDate =
    enrollmentSettings.finalExtension ?? enrollmentSettings.sheetFinalDue;

  if (dueDate && enrollment.work?.final) {
    const isExam = enrollmentSettings.isExam;
    const timeLimit =
      enrollmentSettings.timeExtension ?? enrollmentSettings.sheetTimeLimit;
    const finalForceSubmitted = isExam
      ? !!enrollment.work?.final?.forceSubmitted
      : false;
    const finalResultAccess = enrollment.work?.final?.result?.feedbackAccess;
    const deferred = enrollmentSettings?.deferred ?? false;
    const hasExtraTime =
      Boolean(enrollmentSettings.extraExamWritingTime) ||
      Boolean(enrollmentSettings.extraExamReadingTime);
    const hasAlternativeStart = Boolean(
      enrollmentSettings.alternateExamStartDate
    );
    const hasAlternativeWindow =
      Boolean(enrollmentSettings.alternateExamStartDate) ||
      Boolean(enrollmentSettings.alternateExamEndDate);

    const timestamp = new Date(enrollment.work.final.submittedAt);
    const timeSpentMinutes =
      enrollment.work.startDate && enrollmentSettings.sheetTimeLimit !== null
        ? getWorkDuration(timestamp, new Date(enrollment.work.startDate))
        : null;

    let lateness: [string, number] | null = null;
    if (!isExam) {
      lateness = lateMessage(enrollment.work.final.submittedAt, dueDate);
    } else if (!finalForceSubmitted) {
      lateness = examLateMessage(enrollment.work, enrollmentSettings);
    }

    return {
      id: enrollment.work.final.id,
      dueDate,
      timeLimit,
      timeSpentMinutes,
      dueExtended: !isExam && enrollmentSettings.finalExtension !== null,
      timeExtended: !isExam && enrollmentSettings.timeExtension !== null,
      timestamp,
      late: lateness ? lateness[0] : null,
      overtime:
        timeLimit !== null && timeSpentMinutes !== null && !isExam
          ? overtimeMessage(timeSpentMinutes, timeLimit)
          : null,
      result: enrollment.work.final.result,
      feedbackLastViewed: toDate(finalResultAccess?.lastAccess),
      feedbackViewCount: finalResultAccess?.accessCount ?? 0,
      forceSubmitted: finalForceSubmitted,
      submittedBy: enrollment.work.final.submittedBy?.name,
      hasExtraTime,
      deferred,
      hasAlternativeStart,
      hasAlternativeWindow,
      pdf: enrollment.work.final.pdf,
      markingPdf: enrollment.work.final.markingPdf,
      minutesLateBy: lateness ? lateness[1] : null,
    };
  }

  return null;
};
