import { styled } from "styled-components";

import {
  CadmusEditorContent,
  Editor,
} from "@vericus/cadmus-editor-prosemirror";

import { EditorWrapper } from "../../components/editor-wrapper";
import { BodyDiv } from "../styles";

interface SubQuestionBodyProps {
  /** PromptDoc editor of parent question */
  editor: Editor | null;
  /** Children questions to this question. */
  childQuestionIds?: string[];
  /** Render function to render a child question. */
  renderChildQuestion?: (questionId: string) => JSX.Element;
  /** Callback function for sub question introduction on focus */
  onIntroductionFocus?: (focused: boolean) => void;
  /** Question promptDoc less than 20 characters */
  promptDocTooShort?: boolean;
}

/**
 * Question body of multi format
 */
export const SubQuestionBody = ({
  editor,
  onIntroductionFocus,
  childQuestionIds,
  renderChildQuestion,
  promptDocTooShort,
}: SubQuestionBodyProps) => {
  return (
    <Root>
      <SubQuestionEditorWrapper hasError={promptDocTooShort}>
        <CadmusEditorContent
          id="subQuestionIntroductionInput"
          editor={editor}
          onFocus={() => onIntroductionFocus?.(true)}
          onBlur={() => onIntroductionFocus?.(false)}
        />
      </SubQuestionEditorWrapper>
      {renderChildQuestion &&
        childQuestionIds &&
        childQuestionIds?.map((qid) => (
          <SubQuestionWrapper key={qid}>
            {renderChildQuestion(qid)}
          </SubQuestionWrapper>
        ))}
    </Root>
  );
};

const Root = styled(BodyDiv)`
  background: ${(p) => p.theme.colors.white100};
  flex-direction: column;
  row-gap: 32px;
  align-items: center;
`;

const SubQuestionEditorWrapper = styled(EditorWrapper)`
  margin-top: 16px;
  max-width: 800px;
`;

const SubQuestionWrapper = styled.div`
  width: 100%;
  border: 1px solid ${(p) => p.theme.colors.navy200};
  border-radius: 4px;

  &:focus-within {
    border: 1px solid transparent;
  }
`;
