import { AlertDialog } from "@vericus/cadmus-ui";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { QuestionType } from "@/generated/graphql";

export interface QuestionDeleteDialogProps {
  questionType: QuestionType;
  onConfirmation: () => void;
}

/**
 * Alert confirmation delete shown to confirm question or section deletion.
 */
export const QuestionDeleteDialog = NiceModal.create(
  ({ questionType, onConfirmation }: QuestionDeleteDialogProps) => {
    const modal = useModal();
    const content = getDialogContents(questionType);

    return (
      <AlertDialog.Root
        open={modal.visible}
        onOpenChange={(open) => {
          if (!open) {
            modal.hide();
          }
        }}
      >
        <AlertDialog.Content>
          <AlertDialog.Title>{content.title}</AlertDialog.Title>
          <AlertDialog.Description>
            {content.description}
          </AlertDialog.Description>
          <AlertDialog.Actions>
            <AlertDialog.Cancel
              onClick={() => {
                modal.hide();
              }}
            >
              Cancel
            </AlertDialog.Cancel>
            <AlertDialog.Action
              onClick={() => {
                onConfirmation();
                modal.hide();
              }}
            >
              Yes, Delete
            </AlertDialog.Action>
          </AlertDialog.Actions>
        </AlertDialog.Content>
      </AlertDialog.Root>
    );
  }
);

function getDialogContents(questionType: QuestionType) {
  switch (questionType) {
    case QuestionType.Section:
      return {
        title: "Delete Section?",
        description:
          "Are you sure you want to delete this section? Note: Deleting the section will not remove its questions.",
      };
    default:
      return {
        title: "Delete Question?",
        description:
          "Are you sure you want to delete this question from the assessment? This action will remove it permanently.",
      };
  }
}
