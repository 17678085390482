import { useMemo } from "react";
import styled from "styled-components";

import { Text } from "@vericus/cadmus-ui";

import { nanoid } from "nanoid";

import {
  defaultBlankFieldInteractionKind,
  InteractionKind,
} from "../../interaction-kind-utils";
import { Dispatch, QuestionFormField } from "../../state";
import { OptionListInput } from "../option-list-input";
import { InteractionTypeDropdown } from "./interaction-kind-dropdown";

interface Props {
  order: number;
  field: QuestionFormField;
  dispatch: Dispatch;
  blankWithoutPhrasesIds?: string[];
}

/**
 * Renders a single blank item in a fill-in-blanks question that can be either
 * text entry kind or dropdown choices type of interaction.
 */
export function BlankItem(props: Props) {
  const { order, field, dispatch } = props;

  const selectedInteractionKind =
    field.interaction.kind ?? defaultBlankFieldInteractionKind();

  const onSelectInteractionKind = (kind: InteractionKind) => {
    dispatch({
      type: "SetInteractionKind",
      fieldIdentifier: field.identifier,
      kind,
    });
  };

  const isBlankWithoutPhrases =
    props.blankWithoutPhrasesIds?.includes(field.identifier) ?? false;

  return (
    <OptionListWrapper>
      <BlankLabel
        kind="bodyMd"
        color="neutralBlack300"
      >{`${order}.`}</BlankLabel>
      <div style={{ marginTop: 3 }}>
        <InteractionTypeDropdown
          selected={selectedInteractionKind}
          onSelectInteractionKind={onSelectInteractionKind}
        />
      </div>
      {selectedInteractionKind === InteractionKind.TextEntry ? (
        <TextEntryInteractionBlank
          field={field}
          dispatch={dispatch}
          isBlankWithoutPhrases={isBlankWithoutPhrases}
        />
      ) : (
        <ChoiceInteractionBlank
          field={field}
          dispatch={dispatch}
          isBlankWithoutCorrectAnswer={isBlankWithoutPhrases}
        />
      )}
    </OptionListWrapper>
  );
}

const OptionListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  column-gap: 6px;
`;

const BlankLabel = styled(Text)`
  margin-top: 0.5rem;
`;

function TextEntryInteractionBlank(props: {
  field: QuestionFormField;
  dispatch: Dispatch;
  isBlankWithoutPhrases: boolean;
}) {
  const { field, dispatch } = props;
  const options = useMemo(
    () =>
      field.response?.correctValues.map((answer) => ({
        identifier: answer,
        content: answer,
      })),
    [field.response?.correctValues]
  );
  return (
    <OptionListInput
      options={options}
      onAddOption={(answer) =>
        dispatch({
          type: "AddAcceptedAnswer",
          fieldIdentifier: field.identifier,
          answer,
        })
      }
      additionalErrorMsg={
        props.isBlankWithoutPhrases ? "This cannot be left empty" : undefined
      }
      onDeleteOption={(answer) =>
        dispatch({
          type: "RemoveAcceptedAnswer",
          fieldIdentifier: field.identifier,
          answer,
        })
      }
      onDeleteButtonClick={() => {
        dispatch({
          type: "ClearAcceptedAnswers",
          fieldIdentifier: field.identifier,
        });
      }}
      inputPlaceHolder="Add accepted answer"
    />
  );
}

function ChoiceInteractionBlank(props: {
  field: QuestionFormField;
  dispatch: Dispatch;
  isBlankWithoutCorrectAnswer: boolean;
}) {
  const { field, dispatch } = props;
  return (
    <OptionListInput
      options={field.interaction.choices}
      correctAnswerIds={field.response.correctValues}
      onAddOption={(content) =>
        dispatch({
          type: "SetChoice",
          choiceIdentifier: nanoid(),
          content,
          fieldIdentifier: field.identifier,
        })
      }
      onToggleCorrectOption={(choiceIdentifier) =>
        dispatch({
          type: "ToggleCorrectChoice",
          fieldIdentifier: field.identifier,
          choiceIdentifier,
          alowMultipleCorrectChoice: false,
        })
      }
      additionalErrorMsg={
        props.isBlankWithoutCorrectAnswer ? "Add correct answer" : undefined
      }
      onDeleteOption={(choiceIdentifier) =>
        dispatch({
          type: "DeleteChoice",
          fieldIdentifier: field.identifier,
          choiceIdentifier,
        })
      }
      onDeleteButtonClick={() => {
        dispatch({
          type: "ClearChoices",
          fieldIdentifier: field.identifier,
        });
      }}
      inputPlaceHolder="Add choice"
    />
  );
}
