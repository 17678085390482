import { useState } from "react";
import { styled } from "styled-components";

import { PlusRoundIcon } from "@vericus/cadmus-icons";
import { Button, Icon, Modal, Text, TextArea } from "@vericus/cadmus-ui";

interface AdditionalFeedbackProps {
  feedback: string;
  onSaveFeedback: (feedback: string) => void;
}

/**
 * Feedback input section of footer.
 */
export const AdditionalFeedback = ({
  feedback,
  onSaveFeedback,
}: AdditionalFeedbackProps) => {
  const [localFeedback, setLocalFeedback] = useState(feedback);

  return (
    <Modal.Root>
      <Trigger>
        {feedback ? (
          <HasFeedbackButton>
            <Icon iconName="Tick" />
            <FeedbackText kind="bodySm" color="green800">
              {feedback}
            </FeedbackText>
            <Icon
              iconName="Edit"
              color="green500"
              style={{ marginLeft: "12px" }}
            />
          </HasFeedbackButton>
        ) : (
          <AddFeedbackButton>
            <PlusRoundIcon label="Add" size={36} />
            <Text kind="bodySm" color="navy500">
              Additional feedback
            </Text>
          </AddFeedbackButton>
        )}
      </Trigger>
      <ModalContent>
        <InputFormTitle kind="headingFour" as={"div"}>
          Add additional feedback
        </InputFormTitle>
        <label htmlFor="feedback">
          <InputFormDescription kind="bodyMd">
            You can provide more context for the correct answers. Students will
            see this after the assessment, once the results are released.
          </InputFormDescription>
        </label>
        <Input
          id="feedback"
          placeholder="Write feedback..."
          minRows={3}
          maxRows={3}
          style={{ marginBottom: "24px" }}
          value={localFeedback}
          onChange={(e) => setLocalFeedback(e.target.value)}
        />
        <ActionsBar>
          <Modal.Close asChild>
            <Button onClick={() => setLocalFeedback(feedback)}>
              <Text kind="label">cancel</Text>
            </Button>
          </Modal.Close>
          <Modal.Close asChild>
            <Button kind="secondary" onClick={() => onSaveFeedback(localFeedback)}>
              <Text kind="label" color="white100" style={{ width: "160px" }}>
                Save
              </Text>
            </Button>
          </Modal.Close>
        </ActionsBar>
      </ModalContent>
    </Modal.Root>
  );
};

const Trigger = styled(Modal.Trigger)`
  border: none;
  padding: 0px;
  background: none;
`;

const Input = styled(TextArea.Input)`
  border-radius: 3px;
  border: 1px solid ${(p) => p.theme.colors.grey300};
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 8px 12px;
  resize: vertical;
  min-height: 58px;

  &:focus {
    outline: none;
  }
`;

const InputFormDescription = styled(Text)`
  text-align: center;
  margin-bottom: 32px;
  color: ${(p) => p.theme.colors.grey500};
`;

const ActionsBar = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  column-gap: 12px;
  margin-top: 32px;
`;

const ModalContent = styled(Modal.Content)`
  display: flex;
  width: 648px;
  padding: 52px 64px 56px 64px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
`;

const InputFormTitle = styled(Text)`
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
  padding: 0px;
`;

const HasFeedbackButton = styled.div`
  padding: 8px 12px;
  border: none;
  display: inline-flex;
  border-radius: 4px;
  background: ${(p) => p.theme.colors.green100};
  margin-right: 32px;
  cursor: pointer;
`;

const AddFeedbackButton = styled.div`
  border: none;
  padding: 0px;
  display: inline-flex;
  align-items: center;
  background: none;
  column-gap: 12px;
  cursor: pointer;
`;

const FeedbackText = styled(Text)`
  margin-left: 8px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
