import { forwardRef, useCallback, useState } from "react";

import { ControlButton, DropdownMenu, Modal, Text } from "@vericus/cadmus-ui";

import { useJitsu } from "@jitsu/react";
import { JitsuEvent } from "data/events/JitsuEvent";
import { isBefore } from "date-fns";
import { AssessmentType, SubmissionType } from "generated/graphql";
import { useLocation, useParams } from "react-router-dom";

import { createExamSettings } from "@/graphql/types/EnrollmentSettings";
import { useRootNavigate } from "@/router/routing";
import { ExtensionType } from "@/ui/class/progress/pages";
import {
  ClassTab,
  StudentListMeta,
  StudentListRow,
} from "@/ui/class/progress/types";
import { useCancelDeferred } from "@/ui/exam-special-considerations/hooks/useCancelDeferred";
import { useConfirmRemoveExtraTime } from "@/ui/exam-special-considerations/hooks/useConfirmRemoveExtraTime";
import { useConfirmResetExamTiming } from "@/ui/exam-special-considerations/hooks/useConfirmResetExamTiming";
import { useDeferEnrollments } from "@/ui/exam-special-considerations/hooks/useDeferEnrollments";
import { SpecialConsiderationsMenuItem } from "@/ui/StudentLists/StudentDropdown/SpecialConsiderationsMenuItem";

import { GroupBulkEditCard } from "../BulkActionsMenu";
import { getExamEndDate, progressStageToString } from "../helpers";
import { ExtensionMenuItem } from "./ExtensionMenuItem";

interface StudentDropdownProps extends StudentListMeta {
  /** Class list row data */
  row: StudentListRow;
  open?: boolean | undefined;
}

/**
 * Context-aware options dropdown to trigger actions on class list rows.
 */
export const StudentDropdown = forwardRef<
  HTMLButtonElement,
  StudentDropdownProps
>((props, ref) => {
  const {
    row,
    contactStudents,
    editExtensions,
    downloadSubmission,
    forceSubmissions,
    viewActivityReport,
    revealEnrollment,
    hideEnrollments,
    sheet,
    updateGroupMemberships,
    updateEnrollmentStatus,
    clearExtensions,
  } = props;
  const {
    enrollment,
    settings,
    tab,
    final,
    draft,
    email,
    displayEmail,
    displayName,
    displaySisId,
    anonymous,
  } = row;

  // Group bulk edit modal state
  const [showGroupBulkModal, setShowGroupBulkModal] = useState(false);
  const { track } = useJitsu();

  const { assessmentId } = useParams<{
    assessmentId: string;
    workId: string;
    tenant: string;
  }>();

  if (!assessmentId) {
    throw new Error("assessmentId is undefined");
  }

  const location = useLocation();
  const rootNavigate = useRootNavigate();
  const goToExamSpecialConsiderationsEditPage = useCallback(() => {
    rootNavigate("/class/marking/examSpecialConsiderations/edit", {
      state: {
        replace: false,
        enrollments: [enrollment],
        from: location,
      },
    });
  }, [enrollment, location, rootNavigate]);

  const confirmResetExamTiming = useConfirmResetExamTiming(assessmentId);
  const confirmRemoveExtraTime = useConfirmRemoveExtraTime(assessmentId);
  const cancelDeferred = useCancelDeferred(assessmentId);
  const deferEnrollment = useDeferEnrollments(assessmentId);

  const hasAnonymousMarking = sheet?.anonymousMarking === true;
  const hasDueDate = !!sheet?.dueDate;
  const inGroup = row.group
    ? props.groups.find((group) => group.name === row.group?.name)
    : undefined;

  const finalExtended = row.settings.finalExtension !== null;
  const hasTimeLimit = !!sheet?.timeLimit;

  const handleEditGroup = (groupName: string) => {
    updateGroupMemberships([enrollment], groupName);
  };

  const cutoffDate = getExamEndDate(settings);

  // All conditions for force submitting the current enrollment
  const canForceSubmit =
    forceSubmissions !== undefined &&
    tab === ClassTab.Students &&
    enrollment.work &&
    !enrollment.work.final &&
    enrollment.work.lastSaveTime &&
    !settings?.deferred &&
    cutoffDate &&
    isBefore(cutoffDate, new Date());

  return (
    <>
      <Modal.Root
        open={showGroupBulkModal}
        onOpenChange={setShowGroupBulkModal}
      >
        <Modal.Content>
          <GroupBulkEditCard
            groups={props.groups}
            onConfirm={(groupName) => {
              handleEditGroup(groupName);
              setShowGroupBulkModal(false);
            }}
            onCancel={() => setShowGroupBulkModal(false)}
            selected={inGroup}
          />
        </Modal.Content>
      </Modal.Root>
      <DropdownMenu.Root open={props.open}>
        <DropdownMenu.Trigger asChild>
          <ControlButton ref={ref} iconName="More" aria-label="More" />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content align="end">
          <DropdownMenu.Label style={{ wordWrap: "break-word" }}>
            <Text kind="headingSix" inline>
              {displayName}
            </Text>
            {displaySisId && (
              <Text kind="bodySm" inline color="shade1">
                Student ID {displaySisId}
              </Text>
            )}
            {displayEmail && (
              <Text kind="bodySm" inline color="lilac500">
                {displayEmail}
              </Text>
            )}
          </DropdownMenu.Label>
          <DropdownMenu.Item
            iconName="Mail"
            content="Contact student"
            disabled={!displayEmail || !email}
            onSelect={() => {
              email && contactStudents([email]);

              track(JitsuEvent.STUDENT_CONTACTED, {
                enrollment_id: enrollment.id,
                student_id: enrollment.user.id,
                progress_stage: progressStageToString(row.progress),
              });
            }}
          />
          {sheet?.assessmentType === AssessmentType.Assignment &&
            !hasTimeLimit &&
            !finalExtended && (
              <DropdownMenu.Item
                iconName="CalendarCheck"
                content="Set extension"
                onSelect={() =>
                  editExtensions([enrollment], ExtensionType.DueDate)
                }
              />
            )}
          {sheet?.assessmentType === AssessmentType.Assignment &&
            !(!hasTimeLimit && !finalExtended) &&
            hasDueDate && (
              <ExtensionMenuItem
                row={row}
                editExtensions={editExtensions}
                clearExtensions={clearExtensions}
                sheet={sheet}
              />
            )}

          {(!sheet || sheet.assessmentType === AssessmentType.Assignment) && (
            <DropdownMenu.Item
              iconName="Unset"
              content={enrollment.deleted ? "Cancel withdrawal" : "Withdraw"}
              onSelect={() =>
                updateEnrollmentStatus([enrollment], !enrollment.deleted)
              }
            />
          )}

          {sheet?.assessmentType === AssessmentType.Exam && (
            <>
              <DropdownMenu.Separator />
              <SpecialConsiderationsMenuItem
                enrollmentExamSetting={createExamSettings(enrollment, sheet)}
                examTiming={sheet.examTiming}
                removeExtraTime={() => {
                  confirmRemoveExtraTime([enrollment]);
                }}
                setExtraTime={() => {
                  goToExamSpecialConsiderationsEditPage();
                }}
                setAlternativeExamDate={() => {
                  goToExamSpecialConsiderationsEditPage();
                }}
                removeAlternativeExamDate={() => {
                  confirmResetExamTiming([enrollment]);
                }}
                markAsDeferred={async () => {
                  await deferEnrollment([enrollment]);
                }}
                cancelMarkAsDeferred={async () => {
                  await cancelDeferred([enrollment]);
                }}
              />
              {canForceSubmit && (
                <DropdownMenu.Item
                  iconName="Send"
                  onSelect={() => forceSubmissions([enrollment])}
                  content="Force Submit"
                />
              )}
              <DropdownMenu.Item
                iconName="Unset"
                content={enrollment.deleted ? "Cancel withdrawal" : "Withdraw"}
                onSelect={() =>
                  updateEnrollmentStatus([enrollment], !enrollment.deleted)
                }
              />
              <DropdownMenu.Separator />
            </>
          )}

          <DropdownMenu.Item
            onSelect={() => setShowGroupBulkModal(true)}
            iconName="AddGroup"
            content="Add to marking group"
          />
          {tab !== ClassTab.Students && hasAnonymousMarking && (
            <DropdownMenu.Item
              iconName="Person"
              content={anonymous ? "Reveal name" : "Hide name"}
              onSelect={() =>
                anonymous
                  ? revealEnrollment(enrollment.id)
                  : hideEnrollments([enrollment.id])
              }
            />
          )}
          <DropdownMenu.Separator />
          <DropdownMenu.Item
            data-component={"StudentDropdown.view-activity-report"}
            iconName="Graph"
            content="View activity report"
            onSelect={() => {
              viewActivityReport(
                enrollment,
                row.anonymous ? row.displayName : undefined
              );
              track(JitsuEvent.REPORT_REQUESTED, {
                report_type: "STUDENT_ACTIVITY",
                student_id: row.enrollment.user.id,
                student_anonymous_name: row.anonymous
                  ? row.displayName
                  : undefined,
              });
            }}
          />
          {tab === ClassTab.Drafts && draft !== null && (
            <DropdownMenu.Item
              iconName="Download"
              content="Download draft submission"
              onSelect={() => {
                downloadSubmission(enrollment, SubmissionType.Draft);
              }}
            />
          )}
          {tab !== ClassTab.Drafts && final !== null && (
            <DropdownMenu.Item
              iconName="Download"
              content="Download submission"
              onSelect={() => {
                downloadSubmission(enrollment, SubmissionType.Final);
              }}
            />
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
});
