import { useCallback, useState } from "react";
import { styled } from "styled-components";

import {
  ActionInput,
  Divider,
  easeOutCirc,
  Icon,
  IconButton,
  Popover,
  Text,
  typography,
} from "@vericus/cadmus-ui";

import { EnrollmentTagPill } from "@/ui/shared/EnrollmentTagPill";

import { DisplayTag } from "./types";

export interface TagProps {
  /**
   * Selectable tags to display as a list of pills.
   */
  tags: DisplayTag[];
  /**
   * Callback to add a `tag`.
   */
  onAddTag: (text: string, isCustom?: boolean) => void;
  /**
   * Callback to remove a `tag`.
   */
  onRemoveTag: (text: string, isCustom?: boolean) => void;
  /**
   * Callback to create a new tag.
   */
  onCreateTag: (text: string) => void;
  /**
   * Callback to delete an existing tag.
   */
  onDeleteTag: (text: string) => void;
}

const MAX_CHARACTERS = 25;

/**
 * Manage multi-tag selection for a single enrollment, rendered a list of Pills
 * to toggle, add, and delete custom tags.
 */
export const TagSelectorIndividual = (props: TagProps) => {
  const { tags, onAddTag, onRemoveTag, onCreateTag, onDeleteTag } = props;
  const [addingTagMode, setAddingTagMode] = useState(false);
  const [newTagInputValue, setNewTagInputValue] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [error, setError] = useState(false);

  const validateAndSet = useCallback(
    (value: string) => {
      let didError = false;

      if (value.length <= MAX_CHARACTERS) {
        setNewTagInputValue(value);
      }

      if (value.length >= MAX_CHARACTERS) {
        // Show the validation message
        setValidationMessage("Character limit reached");
      } else {
        // Clear it otherwise
        setValidationMessage("");
      }

      if (value.trim().length === 0) {
        setValidationMessage("Tag name cannot be empty");
        didError = true;
      }

      if (
        tags
          .map((tag) => tag.value.trim().toLowerCase())
          .includes(value.trim().toLowerCase())
      ) {
        setValidationMessage("This tag already exists");
        didError = true;
      }

      setError(didError);
    },
    [tags]
  );

  // Add or remove a tag based on the checkbox state
  const handleSelect = (tag: DisplayTag) => {
    if (tag.active) {
      onRemoveTag(tag.value, tag.custom);
    } else {
      onAddTag(tag.value, tag.custom);
    }
  };

  return (
    <CardRoot>
      <CardHeading kind={"headingSix"}>Tag as</CardHeading>
      <TagsGridOverflow>
        <TagsGrid>
          {tags.map((tag) => {
            return tag.custom ? (
              <EnrollmentTagPill
                key={tag.value}
                color={tag.active ? tag.color : undefined}
                onClick={() => handleSelect(tag)}
                onDelete={() => {
                  onDeleteTag(tag.value);
                }}
                tagName={tag.value}
              />
            ) : (
              <EnrollmentTagPill
                key={tag.value}
                color={tag.active ? tag.color : undefined}
                onClick={() => handleSelect(tag)}
                tagName={tag.value}
              />
            );
          })}
        </TagsGrid>
      </TagsGridOverflow>
      <Divider />
      <NewTagInputArea data-testid={"NewTagInputArea"}>
        {!addingTagMode && (
          <StyledIconButton
            data-testid={"TagSelectorIndividual.AddCustomTag"}
            onClick={() => {
              setAddingTagMode(true);
            }}
          >
            <Icon iconName={"AddTag"} />
          </StyledIconButton>
        )}
        {addingTagMode && (
          <>
            <StyledActionInput
              autofocus
              data-testid={"CustomTag.ActionInput"}
              value={newTagInputValue}
              onChange={validateAndSet}
              onKeyDown={(e) => {
                if (!error && e.currentTarget.value && e.key === "Enter") {
                  onCreateTag(e.currentTarget.value);
                  setNewTagInputValue("");
                  setValidationMessage("");
                }
              }}
              iconName="Tag"
              aria-label="add-tag-input"
              placeholder="Add custom tag..."
              actionName="Add"
              onAction={(stagedInput) => {
                onCreateTag(stagedInput);
                setNewTagInputValue("");
                setValidationMessage("");
              }}
              disabled={error || newTagInputValue.trim().length === 0}
            />
            <CharacterValidationFeedback>
              <Text color={"grey500"} kind={"systemSm"}>
                {validationMessage}
              </Text>
              <Text color={"grey500"} kind={"systemSm"}>
                {newTagInputValue.length ?? 0}/{MAX_CHARACTERS}
              </Text>
            </CharacterValidationFeedback>
          </>
        )}
      </NewTagInputArea>
    </CardRoot>
  );
};

const StyledActionInput = styled(ActionInput)((_p) => {
  return {
    ...typography["bodySm"],
  };
});

const CharacterValidationFeedback = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardRoot = styled(Popover.Card)`
  display: flex;
  flex-direction: column;
`;

const CardHeading = styled(Text)`
  padding: 8px 16px;
`;

const TagsGridOverflow = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

/**
 * Item list with overflow to make only the list scroll.
 *
 * Account for 100px of header (27px) and footer (63px) in the menu which stays fixed. And
 * subtract it from the max height of the dropdown menu.
 */
const TagsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 12px 16px;
  margin-bottom: 8px;
`;

const NewTagInputArea = styled.div`
  padding: 8px 12px 0px 12px;
  box-sizing: border-box;
  min-height: 63px;
  display: grid;
  align-items: center;
`;

// IconButton with a pre-exisiting dark background
const StyledIconButton = styled(IconButton)`
  background: ${(p) => p.theme.background.action1};

  &:hover,
  &:focus {
    background: ${(p) => p.theme.background.action2};
    transition: background 0.1s ${easeOutCirc};
  }

  /* Darken the background on-cLick */

  &:active {
    background: ${(p) => p.theme.background.action3};
  }
`;
