import { styled } from "styled-components";

import { DropdownMenu, Toolbar } from "@vericus/cadmus-ui";

import { ExamTiming } from "@/generated/graphql";
import { ExamSettings } from "@/graphql/types/EnrollmentSettings";

interface SpecialConsiderationsBulkMenuItemProps {
  /**
   * The selected enrollments in question with their calculated exam settings
   */
  enrollmentExamSettings: ExamSettings[];

  /**
   * The function to render for the dropdown menu item
   * (the one that opens the menu).
   */
  renderControl: () => JSX.Element;

  // Menu items relating to the extra time

  /**
   * Callback for intent to remove the extra time for the currently
   * selected students
   */
  removeExtraTime: () => void;

  /**
   * Callback for intent to set the extra time for
   * currently selected enrollments
   */
  setExtraTime: () => void;

  // Menu items relating to alternative start times

  /**
   * Callback for intent to set the alternative exam date for
   * currently selected enrollments.
   */
  setAlternativeExamDate: () => void;

  /**
   * Callback for intent to remove the alternative
   * exam date for selected enrollments.
   */
  removeAlternativeExamDate: () => void;

  /**
   * Callback for intent to mark all
   * currently selected enrollments as deferred.
   */
  markAsDeferred: () => void;

  /**
   * Cancel the enrollment's status 'deferred'
   */
  cancelMarkAsDeferred: () => void;

  /**
   * The exam timing for the exam (Live/Window)
   */
  examTiming: ExamTiming;
}

export const SpecialConsiderationsBulkMenuItem = (
  props: SpecialConsiderationsBulkMenuItemProps
) => {
  const updatedExtraTime = props.enrollmentExamSettings.some(
    (enrollmentExamSetting) =>
      enrollmentExamSetting.extraExamReadingTime ||
      enrollmentExamSetting.extraExamWritingTime
  );
  const updatedAlternativeExamDate =
    props.examTiming === ExamTiming.Live &&
    props.enrollmentExamSettings.some((enrollmentExamSetting) => {
      return Boolean(enrollmentExamSetting.alternateExamStartDate);
    });
  const updatedAlternativeExamWindow =
    props.examTiming === ExamTiming.Window &&
    props.enrollmentExamSettings.some((enrollmentExamSetting) => {
      return (
        Boolean(enrollmentExamSetting.alternateExamStartDate) ||
        Boolean(enrollmentExamSetting.alternateExamEndDate)
      );
    });

  const isDeferred = props.enrollmentExamSettings.some(
    (enrollmentExamSetting) => {
      return enrollmentExamSetting.deferred;
    }
  );

  return (
    <DropdownMenu.Root>
      <Toolbar.Button asChild>
        <DropdownMenu.Trigger asChild>
          {props.renderControl()}
        </DropdownMenu.Trigger>
      </Toolbar.Button>
      <DropdownMenu.Content>
        <DropdownMenu.Label>Special considerations</DropdownMenu.Label>
        <DropdownMenu.Label>Time allocation</DropdownMenu.Label>
        {updatedExtraTime ? (
          <>
            <StyledMenuItem
              onSelect={() => {
                props.setExtraTime();
              }}
              iconName="Time"
            >
              Update extra time
            </StyledMenuItem>
            <StyledMenuItem
              onSelect={() => {
                props.removeExtraTime();
              }}
              iconName="Trash"
            >
              Remove extra time
            </StyledMenuItem>
          </>
        ) : (
          <>
            <StyledMenuItem
              onSelect={() => {
                props.setExtraTime();
              }}
              iconName="Time"
            >
              Give extra time
            </StyledMenuItem>
          </>
        )}
        <DropdownMenu.Separator />
        <DropdownMenu.Label>Flexible arrangement</DropdownMenu.Label>
        {updatedAlternativeExamDate && (
          <>
            <StyledMenuItem
              onSelect={() => {
                props.setAlternativeExamDate();
              }}
              iconName="Calendar"
            >
              Change exam date
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                props.removeAlternativeExamDate();
              }}
              iconName="Trash"
            >
              Reset to main exam timing
            </StyledMenuItem>
          </>
        )}

        {updatedAlternativeExamWindow && (
          <>
            <StyledMenuItem
              onSelect={() => {
                props.setAlternativeExamDate();
              }}
              iconName="Calendar"
            >
              Change alternative exam window
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                props.removeAlternativeExamDate();
              }}
              iconName="Trash"
            >
              Reset to main exam timing
            </StyledMenuItem>
          </>
        )}

        {!updatedAlternativeExamDate && !updatedAlternativeExamWindow && (
          <>
            <StyledMenuItem
              onSelect={() => {
                props.setAlternativeExamDate();
              }}
              iconName="Calendar"
            >
              <>
                {props.examTiming === ExamTiming.Live && (
                  <>Set alternative start time</>
                )}
                {props.examTiming === ExamTiming.Window && (
                  <>Set alternative window</>
                )}
              </>
            </StyledMenuItem>
          </>
        )}
        {!isDeferred && (
          <>
            <DropdownMenu.Separator />
            <StyledMenuItem
              onSelect={() => {
                props.markAsDeferred();
              }}
              iconName="Unset"
              content="Mark as deferred"
            />
          </>
        )}
        {isDeferred && (
          <>
            <DropdownMenu.Separator />
            <StyledMenuItem
              onSelect={() => {
                props.cancelMarkAsDeferred();
              }}
              iconName="Trash"
              content="Cancel 'mark as deferred'"
            />
          </>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const StyledMenuItem = styled(DropdownMenu.Item)`
  font-size: 14px;
  text-align: left;
`;
