/** All the sub-paths under the toplevel `/task` route.  */
export enum TaskPath {
  Home = "/",
  Edit = "/edit",
  EditClassic = "/edit/classic",
  EditMultiformat = "/edit/multiformat",
  Preview = "/preview",
  Release = "/release",
  Settings = "/settings",
}

export enum PreviewView {
  // currently editing sheet
  Editing = "editing",
  // current released sheet
  Current = "current",
  // scheduled released sheet
  Future = "future",
}
