import { useCallback } from "react";

import { useTaskEditor } from "@/data/task";

/**
 * Create a callback to replace the current contents of the body editor with
 * content encoded as HTML.
 */
export function useInsertHtmlContent() {
  const editor = useTaskEditor();
  return useCallback(
    (content: string) => {
      editor?.chain().setContent(content).focus().setTextSelection(0).run();
    },
    [editor]
  );
}

/**
 * Create a callback to clear the content of the body editor.
 */
export function useClearEditorContents() {
  const editor = useTaskEditor();
  return useCallback(() => {
    editor?.chain().clearContent().focus().setTextSelection(0).run();
  }, [editor]);
}

export function useIsEditorEmpty(): boolean {
  const editor = useTaskEditor();
  return editor?.isEmpty ?? true;
}
