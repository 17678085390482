import { styled } from "styled-components";

import { ErrorIcon } from "@vericus/cadmus-icons";
import { Switch, Text, Tooltip } from "@vericus/cadmus-ui";

import { HelpIcon } from "@/ui/shared/HelpIcon";

import { SimilarityInput } from "../similarity-input";
import { Dispatch } from "../state";

interface MCQAutoMarkConditionProps {
  /**
   * Disable all the interactive fields. Used in moderation view of questions.
   * @default false
   */
  disabled?: boolean;

  /**
   * If interactive elements are enabled, then dispatch function must be passed
   */
  dispatch?: Dispatch;
  partialScoring: boolean | null;
}

/**
 * Render configuration settings for MCQ automarking.
 */
export function MCQAutoMarkCondition(props: MCQAutoMarkConditionProps) {
  const { dispatch, partialScoring, disabled = false } = props;
  return (
    <Root>
      <Text kind="bodySm" bold as="span">
        Automark conditions{"  "}
        <Tooltip
          content={
            <Text kind={"systemMd"} as="span">
              When this is turned on, students must choose all correct answers
              to get any points. If they miss any, they receive zero points.
              When turned off, students get partial credit for each correct
              answer they select.
            </Text>
          }
        >
          <HelpIcon />
        </Tooltip>
      </Text>
      <Switch
        checked={!partialScoring}
        onChange={() => dispatch?.({ type: "TogglePartialScoring" })}
        content={"Require all correct answers"}
        labelPosition="left"
        disabled={disabled}
      />
    </Root>
  );
}

interface ShortBlanksAutoMarkConditionProps {
  /**
   * Disable all the interactive fields. Used in moderation view of questions.
   * @default false
   */
  disabled?: boolean;

  /**
   * If interactive elements are enabled, then dispatch function must be passed
   */
  dispatch?: Dispatch;

  /** Error when user hasn't assigned required similarity */
  noRequiredSimilarity?: boolean;

  /**
   * Required similarity of an answer to be marked as correct response.
   * Will be number between 0-100
   */
  matchSimilarity: number | null;

  /** Case sensitivity check for answer */
  caseSensitive?: boolean;

  /**
   *  Input error when user input requried similarity that is less than requirement
   */
  similarityTooSmall?: boolean;
}

export const ShortBlanksAutoMarkCondition = ({
  matchSimilarity,
  caseSensitive,
  dispatch,
  noRequiredSimilarity,
  similarityTooSmall,
  disabled = false,
}: ShortBlanksAutoMarkConditionProps) => {
  const similarityErrorMsg = noRequiredSimilarity
    ? "Required similarity % must be defined."
    : similarityTooSmall
      ? "Similarity must be 50% or more"
      : undefined;

  return (
    <ConditionSection>
      <Tooltip
        content={
          <Text kind="systemMd" as="span">
            Set a similarity percentage between 50%-100% for automatic grading.
            Answers with a similarity equal to or above this range are marked
            correct.
          </Text>
        }
      >
        <StyledButton>
          <Text kind="bodySm" bold>
            Automark conditions
          </Text>
          <HelpIcon />
        </StyledButton>
      </Tooltip>
      <SimilarityInput
        disabled={disabled}
        hasError={noRequiredSimilarity}
        similarityValue={matchSimilarity}
        caseSensitive={caseSensitive ?? false}
        onChange={(e) => {
          if (e.target.value === "") dispatch?.({ type: "SetMatchSimilarity" });
          const similarity = parseFloat(e.target.value);
          if (Number.isNaN(similarity)) return;
          if (similarity >= 0 && similarity <= 100)
            dispatch?.({ type: "SetMatchSimilarity", similarity });
        }}
        onCaseSensitiveChange={() =>
          dispatch?.({ type: "ToggleCaseSensitivity" })
        }
      />
      {similarityErrorMsg && (
        <SimilarityError>
          <ErrorIcon label="" size={16} />
          <Text kind="bodySm" color="functionalFail">
            {similarityErrorMsg}
          </Text>
        </SimilarityError>
      )}
    </ConditionSection>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ConditionSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const SimilarityError = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  column-gap: 4px;
`;

const StyledButton = styled.button`
  border: none;
  background: none;
  padding: 0px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  align-self: flex-start;
`;
