import { KeyboardEventHandler, useCallback, useRef, useState } from "react";

import { ControlButton, Text } from "@vericus/cadmus-ui";

import { useImportedFilesTableContext } from "./context";
import * as styles from "./file-name-cell.css";

export interface FileNameCellProps {
  fileOriginId: string | undefined;
  filename?: string;
}

export interface FileNameCellWithDataProps extends FileNameCellProps {
  editing: boolean;
  onEdit: () => void;
  onUpdate: (filename: string) => void;
}

/**
 * Table Cell displaying the filename or a placeholder, with an action to edit
 * the filename using an input field.
 */
export const FileNameCell = (props: FileNameCellProps) => {
  const { fileOriginId, filename } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [editing, setEditing] = useState(false);

  const { onUpdateFilename } = useImportedFilesTableContext();

  const onConfirm = useCallback(() => {
    if (
      fileOriginId &&
      inputRef.current?.value &&
      inputRef.current.value !== filename
    ) {
      onUpdateFilename?.(fileOriginId, inputRef.current.value);
    }
    setEditing(false);
  }, [onUpdateFilename, inputRef, fileOriginId, filename, setEditing]);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.key === "Enter") {
        onConfirm();
      }
    },
    [onConfirm]
  );

  if (!fileOriginId) return null;

  return (
    <span className={styles.inputContainer}>
      {editing && (
        <input
          className={styles.input}
          ref={inputRef}
          placeholder="File Name"
          defaultValue={filename}
          onBlur={onConfirm}
          onKeyDown={onKeyDown}
          autoFocus
        />
      )}
      {!editing && (
        <>
          <span>
            {(filename ?? "").trim().length > 0 ? filename : "File Name"}
          </span>
          <span className={styles.hoverContainer}>
            <ControlButton
              iconName="Edit"
              onClick={(evt) => {
                evt.stopPropagation();
                setEditing(true);
              }}
              disableChildrenMinWidth
            >
              <Text kind="label">Rename</Text>
            </ControlButton>
          </span>
        </>
      )}
    </span>
  );
};
