import { useCallback, useState } from "react";
import styled from "styled-components";

import {
  Button,
  colors,
  Divider,
  DropdownMenu,
  Input,
  SelectButton,
  Text,
} from "@vericus/cadmus-ui";

import { LateByFilterValue } from "@/ui/class/progress/types";

import { ClassTable } from "../../table";
import { useOnClearFilter } from "../hooks";

interface Props {
  table: ClassTable;
}

type FilterValue = LateByFilterValue | undefined;

export const LATE_BY_DEFAULT_FILTERS = (): LateByFilterValue[] => {
  return [
    { number: 15, unit: "day", displayUnit: "Days" },
    { number: 30, unit: "min", displayUnit: "Mins" },
    { number: 1, unit: "hr", displayUnit: "Hrs" },
  ];
};

/**
 * Filter for the late by column.
 * Allows the user to filter by a range of days or hours or minutes.
 */
export function LateByFilter({ table }: Props) {
  const column = table.getColumn("lateBy")!;
  const currentValue = column.getFilterValue() as FilterValue;

  const onClear = useOnClearFilter(column, "Late By");

  const onFilterSelect = useCallback(
    (value: FilterValue) => {
      const currentValue = column.getFilterValue() as FilterValue;
      if (
        value &&
        value.number === currentValue?.number &&
        value.unit == currentValue?.unit
      ) {
        onClear();
      } else {
        column.setFilterValue(value);
      }
    },
    [column, onClear]
  );

  return (
    <FilterDropdowns
      currentFilterValue={currentValue ?? null}
      onFilterClear={onClear}
      onFilterSelect={onFilterSelect}
    />
  );
}

const FilterDropdowns = (props: {
  currentFilterValue: LateByFilterValue | null;
  onFilterSelect: (filter: LateByFilterValue) => void;
  onFilterClear: () => void;
}) => {
  const [customFilter, setCustomFilter] = useState<boolean>(false);
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <SelectButton
          aria-label="Filter by late by"
          selected={props.currentFilterValue !== null}
          activeColor="lilac500"
          style={{ minWidth: 160 }}
        >
          Late by{" "}
          {props.currentFilterValue
            ? `${props.currentFilterValue.number} ${props.currentFilterValue.displayUnit}`
            : ""}
        </SelectButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {LATE_BY_DEFAULT_FILTERS().map((filter) => (
          <DropdownMenu.Item
            key={`${filter.number}-${filter.unit}`}
            onSelect={(e) => {
              e.preventDefault();
              props.onFilterSelect(filter);
            }}
            data-testid={"LateByFilter.CheckMenuItem"}
          >
            <Text kind="bodySm">
              {filter.number} {filter.unit}
            </Text>
          </DropdownMenu.Item>
        ))}
        <Divider />
        {customFilter ? (
          <CustomLateByFilter
            onClick={props.onFilterSelect}
            currentValue={props.currentFilterValue}
          />
        ) : (
          <DropdownMenu.MenuItemDiv onClick={() => setCustomFilter(true)}>
            <Text kind="bodySm">Custom...</Text>
          </DropdownMenu.MenuItemDiv>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

interface CustomLateByFilterProps {
  onClick: (filter: LateByFilterValue) => void;
  currentValue: LateByFilterValue | null;
}

type TimeUnit = {
  unit: "hr" | "day" | "min";
  displayUnit: "Hrs" | "Days" | "Mins";
};

function CustomLateByFilter(props: CustomLateByFilterProps) {
  const displayedValue = props.currentValue ?? {
    number: 3,
    unit: "hr",
    displayUnit: "Hrs",
  };
  const [selectedTimeUnit, setSelectedTimeUnit] =
    useState<TimeUnit>(displayedValue);
  const [selectedNumber, setSelectedNumber] = useState<number>(
    displayedValue.number
  );
  const timeUnits: TimeUnit[] = [
    { unit: "hr", displayUnit: "Hrs" },
    { unit: "day", displayUnit: "Days" },
    { unit: "min", displayUnit: "Mins" },
  ];

  return (
    <InputContainer>
      <Grouped>
        <StyledInput
          id="late-by-number"
          type={"number"}
          value={selectedNumber}
          min={0}
          onChange={(e) => setSelectedNumber(Number(e.target.value))}
        />
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <StyledSelectButton
              data-testid={"GroupFilter"}
              data-component={"GroupFilter.marking-group"}
              selected={false}
            >
              <span style={{ color: colors.navy500 }}>
                {selectedTimeUnit.displayUnit}
              </span>
            </StyledSelectButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {timeUnits.map(({ unit, displayUnit }) => (
              <DropdownMenu.Item
                key={unit}
                onSelect={() => {
                  setSelectedTimeUnit({ unit, displayUnit });
                }}
              >
                <Text kind={"bodySm"}>{displayUnit}</Text>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Grouped>
      <Button
        onClick={() => {
          props.onClick({
            number: selectedNumber,
            unit: selectedTimeUnit.unit,
            displayUnit: selectedTimeUnit.displayUnit,
          });
        }}
        disabled={selectedNumber <= 0}
        kind="secondary"
      >
        Select
      </Button>
    </InputContainer>
  );
}

const StyledSelectButton = styled(SelectButton)`
  min-width: initial;
`;
const InputContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: inline-column;
  font-size: 14px;
  width: 300px;
  margin: 12px;

  input {
    &:focus {
      border: 1px solid ${colors.grey300};
      box-shadow: none;
    }
  }
`;

const StyledInput = styled(Input)`
  border-right: 1px solid ${colors.grey300};
  min-width: initial;
  width: auto;

  & input {
    min-width: initial;
    min-height: initial;
    padding: 8px 12px;
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    line-height: 1.15;
    border-right: none;
  }
`;

const Grouped = styled.div<{ disabled?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid ${colors.grey300};
  border-radius: 4px;

  ${(p) => {
    if (p.disabled) {
      return `
        opacity: 0.5;
        pointer-events: none;
      `;
    }
  }}
  input {
    border: none;
    color: ${colors.navy500};

    &:focus {
      background: hsl(0, 0%, 100%);
      border: none;
      box-shadow: none;
    }
  }

  button {
    border: none;
  }
`;
