import { useMemo } from "react";

import { useAppSelector } from "@/data/hooks";

import { selectQuestionsByFileId } from "../../data";
import { CategorySearchSelect } from "./category-search-select";
import { useQuestionBanksCategoryContext } from "./context";

export const FileCategoryAssignCell = ({ fileId }: { fileId: string }) => {
  const { categories, onAdd, onSelect } = useQuestionBanksCategoryContext();
  const questions = useAppSelector((state) =>
    selectQuestionsByFileId(state, fileId)
  );
  const selectedCategories = useMemo(
    () => [
      ...new Set(questions?.flatMap((q) => (q.category ? [q.category] : []))),
    ],
    [questions]
  );
  return (
    <CategorySearchSelect
      items={categories ?? []}
      selectedItems={selectedCategories ?? []}
      onAdd={onAdd}
      onSelect={(selectedItem) => onSelect?.(selectedItem, fileId)}
    />
  );
};
