import { EnrollmentTagPill } from "@/ui/shared/EnrollmentTagPill";

import { TagConfig } from "./types";

interface Props {
  tags: string[];
  tagConfigs: TagConfig[];
}

/**
 * Render a list of Tags given the ordering and configuration.
 */
export const TagList = (props: Props) => {
  const { tags, tagConfigs } = props;

  const filteredTags = tagConfigs.filter(
    (config) =>
      !!tags.find((text) => text.toLowerCase() === config.value.toLowerCase())
  );

  return (
    <>
      {filteredTags.map((config) => (
        <EnrollmentTagPill
          basic
          key={config.value}
          color={config.color}
          tagName={config.value}
        />
      ))}
    </>
  );
};
