import {
  add,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  format,
  formatDistanceStrict,
  formatDuration,
  intervalToDuration,
  isAfter,
  isBefore,
  isSameMinute,
  max,
  setDefaultOptions,
} from "date-fns";
import { format as formatWithTimezone } from "date-fns-tz";
import enAU from "date-fns/locale/en-AU";
import enGB from "date-fns/locale/en-GB";

import { DEFAULT_LOCALE } from "@/config";

// Set date-fns locale based on the build-time DEFAULT_LOCALE value.
export const locale =
  DEFAULT_LOCALE === "en-AU" ? enAU : DEFAULT_LOCALE === "en-GB" ? enGB : enAU;
setDefaultOptions({ locale });

/**
 * There's a copy style guide whenever we mention things about date(s)
 *
 * {@link https://docs.google.com/spreadsheets/d/1XO8M5bPTAd-uVWCaygIGt9ch1BDOtZCV2zHZxPNtmbg/edit#gid=21065173 Style guide}
 *
 * Where possible, we'll leave a comment for each of the formatting function
 * that is known to be compliant with the documented formats mentioned above.
 *
 * eg. "✅ This is part of the Cadmus standardised date/duration formatting."
 */

/** Format a JS Date into a standard client format.  */
export function formatDate(
  date: Date | string,
  dateFormat: string = "E, do MMMM 'at' h:mma"
): string {
  return format(new Date(date), dateFormat);
}

/** Coalesce a nullish or optional string to a Date or null */
export const toDate = (s: string | Date | null | undefined): Date | null =>
  typeof s === "string" ? new Date(s) : null;

export const COMPACT_DATE_FORMAT = "d MMM, h:mm aaa";
export const COMPACT_DATE_WITH_YEAR_FORMAT = "d MMM yyyy, h:mm aaa";

/**
 * Human-readable datetime comparison between two dates, used for lateness & overtime message.
 */
export function datesDuration(firstDate: Date, secondDate: Date) {
  if (isSameMinute(firstDate, secondDate) || isBefore(firstDate, secondDate)) {
    return "on time";
  } else if (
    differenceInMinutes(firstDate, secondDate) < 60 &&
    isAfter(firstDate, secondDate)
  ) {
    return `${differenceInMinutes(firstDate, secondDate)} min`;
  } else if (
    differenceInHours(firstDate, secondDate, { roundingMethod: "ceil" }) <= 1 &&
    isAfter(firstDate, secondDate)
  ) {
    return "1 hr";
  } else if (
    differenceInHours(firstDate, secondDate, { roundingMethod: "floor" }) < 2 &&
    isAfter(firstDate, secondDate)
  ) {
    return ">1 hr";
  } else if (
    differenceInHours(firstDate, secondDate, { roundingMethod: "ceil" }) <= 2 &&
    isAfter(firstDate, secondDate)
  ) {
    return "2 hr";
  } else if (
    differenceInHours(firstDate, secondDate, { roundingMethod: "floor" }) <
      24 &&
    isAfter(firstDate, secondDate)
  ) {
    return ">2 hr";
  } else if (
    differenceInHours(firstDate, secondDate, { roundingMethod: "ceil" }) <=
      24 &&
    isAfter(firstDate, secondDate)
  ) {
    return "1 day";
  } else if (
    differenceInHours(firstDate, secondDate, { roundingMethod: "floor" }) <
      48 &&
    isAfter(firstDate, secondDate)
  ) {
    return ">1 day";
  } else if (
    differenceInHours(firstDate, secondDate, { roundingMethod: "ceil" }) <=
      48 &&
    isAfter(firstDate, secondDate)
  ) {
    return "2 days";
  }

  // if difference is >= 30 days -> "x days"
  const daysDifference = differenceInCalendarDays(firstDate, secondDate);
  if (daysDifference >= 30) return `${daysDifference} days`;

  return formatDistanceStrict(firstDate, secondDate);
}

/**
 * Human-readable hours and minutes duration given the minutes.
 *
 * @deprecated Please use `formatCadmusDuration` instead.
 * Reason being that we want to start using date/duration formats that
 * are consistent across the app and documented.
 *
 * @example hrAndMinDuration(90)
 * // returns "1 hour and 30 minutes"
 *
 * @example hrAndMinDuration(90, { hr: " hr", min: " min" }, " ")
 * // returns "1 hr 30 mins"
 *
 * @example hrAndMinDuration(90, { hr: " hr", min: " min" }, " ", false)
 * // returns "1 hr 30 min"
 *
 * @example hrAndMinDuration(26.76666666, { hr: " hr", min: " min" }, " ", false)
 * // returns "27 min"
 */
export function hrAndMinDuration(
  minutes: number,
  format = { hr: " hour", min: " minute" },
  delimiter = " and ",
  pluralise = true
) {
  if (minutes < 1) return `0${format.min}`;

  const hr = Math.floor(minutes / 60);
  const hrUnit = format.hr + (hr > 1 && pluralise ? "s" : "");
  const hrText = hr === 0 ? "" : `${hr}${hrUnit}`;

  const min = Math.round(minutes % 60);
  const minUnit = format.min + (min > 1 && pluralise ? "s" : "");
  const minText = min === 0 ? "" : `${min}${minUnit}`;

  const hrAndMin = hr !== 0 && min !== 0 ? delimiter : "";

  return `${hrText}${hrAndMin}${minText}`;
}

/**
 * Return the latest date from an array of date/date string/ null / undefined
 * values
 */
export function getMaxDate(
  dates: Array<string | Date | null | undefined>
): Date | null {
  const validDates = dates.flatMap((input) => {
    const value = toDate(input);
    return value ? [value] : [];
  });
  if (!validDates.length) return null;
  return max(validDates);
}

export const COMPACT_TIME_FORMAT = { hr: "hr", min: "min" };

/**
 * Human-readable format that describes the date range between two dates.
 *
 * Example: 22 Jan at 11:00 AM – 23 Jan at 6:00 PM AEDT
 * Example: 28 Feb at 2:52 PM - 1 Mar at 2:52 PM UTC
 * @param fromDate
 * @param toDate
 */
export const formatDateRange = (
  fromDate: string | null | Date,
  toDate: string | null | Date
) => {
  if (!fromDate || !toDate) return "";

  const fromDateInstance =
    fromDate instanceof Date ? fromDate : new Date(fromDate);
  const toDateInstance = toDate instanceof Date ? toDate : new Date(toDate);

  return `${formatWithTimezone(fromDateInstance, `d MMM 'at' h:mm a`, {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })} – ${formatWithTimezone(toDateInstance, `d MMM 'at' h:mm a z`, {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })}`;
};

/**
 * Given a duration, return normalised duration.
 *
 * Useful if the duration looks something like
 *
 * { seconds: 70 } -> { minutes: 1, seconds: 10 }
 *
 * and we want to spread that duration out into hours, minutes and seconds.
 * @param duration
 */
export function normaliseDuration(duration: Duration) {
  const baseDate = new Date("2022-01-01");
  const normalisedDate = add(baseDate, duration);

  return intervalToDuration({
    start: baseDate,
    end: normalisedDate,
  });
}

export const compareDurations = (
  durationA: Duration,
  durationB: Duration
): -1 | 0 | 1 => {
  const normalisedDurationA = normaliseDuration(durationA);
  const normalisedDurationB = normaliseDuration(durationB);

  if (
    JSON.stringify(normalisedDurationA) === JSON.stringify(normalisedDurationB)
  ) {
    return 0;
  }

  const baseDate = new Date("2022-01-01");

  return isAfter(
    add(baseDate, normalisedDurationA),
    add(baseDate, normalisedDurationB)
  )
    ? 1
    : -1;
};

interface FormattedDurationParams {
  duration: Duration;
  pluralise?: boolean;
}

/**
 * This handy function will calculate the duration between the 2 days provided
 * and nicely format the duration that's most relevant.
 *
 * Example outputs:
 * - 15 min
 * - 1 hr and 10 min
 * - 3 day (when over 24 hours, just round to days)
 * @param duration - The date-fn Duration object
 * @param pluralise - Whether to pluralise the unit
 */

export const formattedDuration = ({
  duration,
  pluralise = false,
}: FormattedDurationParams) => {
  const format = duration.days ? ["days"] : ["hours", "minutes"];
  return shortHandTimeUnits(formatDuration(duration, { format }), pluralise);
};

/**
 * This functions shorthands time units.
 * Shorthand (symbol) reference: None. We need to figure out which standard to follow
 * TODO: figure out whether we should commit to (eg. 2 hours = 2hrs|2hr|2h)
 * TODO: review the plural versions of these abbreviations
 *
 * 'years' = 'yr'
 * 'months' = 'mth'
 * 'weeks' = 'wk'
 * 'days' = 'day'
 * 'hours' = 'hr'
 * 'minutes' = 'min'
 * 'seconds' = 's'
 * @param formattedDuration - The string of the formatted date
 * @param plural - whether to pluralise the shorthand unit. Defaults to `false`
 */
export const shortHandTimeUnits = (
  formattedDuration: string,
  plural: boolean = false
): string => {
  return formattedDuration
    .replaceAll("years", plural ? "yrs" : "yr")
    .replaceAll("year", plural ? "yr" : "yr")
    .replaceAll("months", plural ? "mths" : "mth")
    .replaceAll("month", plural ? "mth" : "mth")
    .replaceAll("weeks", plural ? "wks" : "wk")
    .replaceAll("week", plural ? "wk" : "wk")
    .replaceAll("days", plural ? "days" : "day")
    .replaceAll("day", plural ? "day" : "day")
    .replaceAll("hours", plural ? "hrs" : "hr")
    .replaceAll("hour", plural ? "hr" : "hr")
    .replaceAll("minutes", plural ? "mins" : "min")
    .replaceAll("minute", plural ? "min" : "min")
    .replaceAll("seconds", plural ? "s" : "s")
    .replaceAll("seconds", plural ? "s" : "s");
};
