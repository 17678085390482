import { styled } from "styled-components";

import { Button, Modal, Spacer, Text } from "@vericus/cadmus-ui";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { EnrollmentFragment } from "@/generated/graphql";

export interface IResetExamTimingModalProps {
  /**
   * Callback for when the user cancels the modal
   */
  onCancel?: () => void;

  /**
   * Callback for when the user confirms the modal
   */
  onPositiveAction: () => void;

  /**
   * The enrollments to cancel the deferred status for
   */
  enrollments: EnrollmentFragment[];
}

export const ResetExamTimingModal = NiceModal.create(
  (props: IResetExamTimingModalProps) => {
    const modal = useModal();

    return (
      <Modal.Root
        open={modal.visible}
        onOpenChange={(open) => {
          if (!open) {
            props.onCancel && props.onCancel();
            modal.hide();
          }
        }}
      >
        <Modal.Content onExitComplete={() => modal.remove()}>
          <Container>
            <Content>
              <Text kind={"headingFour"}>{`Reset to main exam timing${
                props.enrollments.length === 1
                  ? ""
                  : ` for ${props.enrollments.length} students`
              }?`}</Text>
              <Spacer spacing={16} />
              <Text kind="bodyMd">{`${
                props.enrollments.length === 1
                  ? props.enrollments[0]!.user.givenName
                  : `They`
              } will have the same exam timing as the main exam`}</Text>
            </Content>
            <Spacer spacing={24} />
            <ButtonContainer>
              <Button
                onClick={() => {
                  props.onCancel && props.onCancel();
                  modal.hide();
                }}
                style={{
                  flexGrow: 1,
                }}
              >
                Cancel
              </Button>
              <Button
                kind={"secondary"}
                onClick={() => {
                  props.onPositiveAction();
                  modal.hide();
                }}
                style={{
                  flexGrow: 2,
                }}
              >
                Reset
              </Button>
            </ButtonContainer>
          </Container>
        </Modal.Content>
      </Modal.Root>
    );
  }
);

const Container = styled.div`
  padding: 56px;
  max-width: 568px;
  width: 100%;
  box-sizing: border-box;
`;

const Content = styled.div`
  text-align: center;
  max-width: 414px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  max-width: 345px;
  margin: 0 auto;
`;
