import { ReactNode } from "react";

import { FilterCard } from "@/ui/class/components";
import { BigPill } from "@/ui/shared/BigPill";

import {
  BannerFilterId,
  filtersConfig,
  FiltersContainer,
  ProgressFilterContainer,
} from "./shared";

export interface DisplayedFilter {
  filterId: BannerFilterId;
  /** Whether the filter is currently being used. */
  active?: boolean;
  /** The number of results the filter matches, wehether active or not. */
  count: number;
}

export interface ProgressFiltersBannerProps {
  /**
   * Optionally show this component before the filters.
   */
  beforeFilters?: ReactNode;
  /** Whether to flex grow the container. */
  growHorizontally?: boolean;
  filters: DisplayedFilter[];
  onFilterSelected: (newFilter: BannerFilterId) => void;
  /**
   * Whether to display the feedback filter, regardless of device.
   * Note that parents to the component may still conditionally
   * render this component.
   */
  showFeedbackInAnyDevice?: boolean;
}

// TOOD: improve the accessibility of the filters :)
export const ProgressFiltersBanner = ({
  beforeFilters,
  growHorizontally,
  filters,
  onFilterSelected,
  showFeedbackInAnyDevice,
}: ProgressFiltersBannerProps) => {
  return (
    <ProgressFilterContainer flexGrow={growHorizontally ? 1 : 0}>
      {beforeFilters}
      <FiltersContainer aria-label="Progress quick filters">
        {filters.map(({ filterId: filter, count, active }) => {
          const filterConfig = filtersConfig[filter];
          const isFeedbackFilter = filter === "feedback-viewed";
          const onlyShowInMobile = isFeedbackFilter && !showFeedbackInAnyDevice;
          return (
            <FilterCard
              key={filter}
              count={count}
              isActive={active}
              label={
                <BigPill
                  backgroundColor={filterConfig.pillBgColor}
                  textColor={filterConfig.pillTextColor}
                  width="grow"
                >
                  {filterConfig.filterDisplay}
                </BigPill>
              }
              onlyInMobile={onlyShowInMobile}
              onClick={() => onFilterSelected(filter)}
            />
          );
        })}
      </FiltersContainer>
    </ProgressFilterContainer>
  );
};
