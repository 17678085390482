/**
 * Ceil and format given decimal number
 *
 * If the number is more than two decimal places, it will be cut to 2 two decimal
 * places. Then the function will rounds it up and returns
 * the smallest integer greater than or equal to it
 */
export function ceilAndFormatDecimalNumber(num: number) {
  return Math.ceil(num * 100) / 100;
}

/**
 * Format given number
 *
 * Truncate decimal number to two decimal places, and ignore 0 at the end */
export const formatDecimalNumber = (num: number) =>
  num.toFixed(2).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
