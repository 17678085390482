import { useCallback } from "react";

import { useAppDispatch } from "@/data/hooks";
import { QuestionType } from "@/generated/graphql";

import { resetFilterState, setFilterState } from "../moderate-slice";
import { FilterState, ScoredQuestion } from "../types";

export enum FilterId {
  QuestionType,
  ShowWithChangesOnly,
  QuestionId,
}

/**
 * Hook to help managing the filter state for the question list.
 */
export function useFilterHandlers(): {
  setSelectedQuestionTypes: (questionTypes: QuestionType[]) => void;
  setSelectedQuestionIds: (questionIds: string[]) => void;
  resetFilters: (filter?: FilterId) => void;
} {
  const dispatch = useAppDispatch();

  const setSelectedQuestionIds = useCallback(
    (questionIds: string[]) => {
      dispatch(
        setFilterState({
          selectedQuestionIds: questionIds,
        })
      );
    },
    [dispatch]
  );

  const setSelectedQuestionTypes = useCallback(
    (questionTypes: QuestionType[]) => {
      dispatch(
        setFilterState({
          selectedQuestionTypes: questionTypes,
        })
      );
    },
    [dispatch]
  );

  const resetFilters = useCallback(
    (filter?: FilterId) => {
      switch (filter) {
        case FilterId.QuestionType:
          dispatch(
            setFilterState({
              selectedQuestionTypes: [],
            })
          );
          break;
        case FilterId.ShowWithChangesOnly:
          dispatch(
            setFilterState({
              showOnlyWithChanges: false,
            })
          );
          break;
        case FilterId.QuestionId:
          dispatch(
            setFilterState({
              selectedQuestionIds: [],
            })
          );
          break;
        default:
          dispatch(resetFilterState());
      }
    },
    [dispatch]
  );

  return {
    setSelectedQuestionTypes,
    setSelectedQuestionIds,
    resetFilters,
  };
}

export function filterQuestions(
  questions: ScoredQuestion[],
  filterState: FilterState
) {
  const filteredQuestions = questions.filter((q) => {
    const matchesQuestionFilter =
      filterState.selectedQuestionIds.length === 0 ||
      filterState.selectedQuestionIds.includes(q.questionId);

    const isSubQuestion = q.parentNodeId !== null;
    const selectedTypesIncludeSub = filterState.selectedQuestionTypes.includes(
      QuestionType.Sub
    );
    const matchesTypeFilter =
      filterState.selectedQuestionTypes.length === 0 ||
      filterState.selectedQuestionTypes.includes(q.questionType) ||
      (selectedTypesIncludeSub && isSubQuestion);

    const matchesMarkerChanges =
      !filterState.showOnlyWithChanges || q.hasMarkerChanges;

    return matchesQuestionFilter && matchesTypeFilter && matchesMarkerChanges;
  });
  return filteredQuestions;
}

export function selectHasActiveFilters(filterState: FilterState) {
  return (
    filterState.selectedQuestionIds.length > 0 ||
    filterState.selectedQuestionTypes.length > 0 ||
    filterState.showOnlyWithChanges
  );
}
