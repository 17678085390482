import * as React from "react";

import { __GLOBAL_TENANT } from "client/globals";

import * as config from "@/config";

const HERA_ENDPOINT = `${config.API_ENDPOINT}/api/hera`;

/** All possible Hera access points. */
export enum HeraLaunch {
  Setup = "setup",
  Settings = "settings",
  Result = "result",
  SpeedGrader = "speedgrader",
  MoodleGrader = "moodlegrader",
  CadmusGrader = "cadmusgrader",
}

/** Props for HeraLink. */
interface LaunchSettings {
  launch: HeraLaunch;
  resultId?: string;
  assessmentId?: string;
  isDraft?: boolean;
}

/** Create a URL instance for a Hera launch endpoint. */
export function useHeraUrl(settings: LaunchSettings) {
  const { launch, resultId, assessmentId, isDraft } = settings;

  /** Create the pantheon endpoint URL to make the request to. */
  const url = React.useMemo(() => {
    const url = new URL(`${HERA_ENDPOINT}/${launch}`);
    url.searchParams.append("role", "lecturer");

    if (resultId) {
      url.searchParams.append("result_id", resultId);
    }
    if (assessmentId) {
      url.searchParams.append("assessment_id", assessmentId);
    }
    if (__GLOBAL_TENANT.current) {
      url.searchParams.append("tenant", __GLOBAL_TENANT.current);
    }
    if (isDraft) {
      url.searchParams.append("is_draft", "true");
    }
    return url;
  }, [resultId, assessmentId, isDraft, launch]);

  return url;
}

/** Create a 'onClick' callback to open a new window which launches into Hera. */
export function useHeraLaunch(settings: LaunchSettings) {
  const url = useHeraUrl(settings);

  /** Callback to open a new window for Hera launch. */
  const handleClick = React.useCallback(() => {
    const win = window.open(url.href, "_blank");
    if (win) {
      win.focus();
    }
  }, [url]);

  return handleClick;
}
