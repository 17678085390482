import { GroupFragment, GroupType, UserFragment } from "@/generated/graphql";

/** Group id of students without marking group */
export const DefaultGroupId = "default-group-id";

export interface LoadGroupsPayload {
  groups: GroupFragment[];
  allStudents: (UserFragment & { anonymousIndex: number | null })[];
  studentWorks: { studentId: string; workId: string }[];
  groupType: GroupType | null;
  anonymousMarkingEnabled: boolean;
}

export type MarkingGroup = {
  id: string;
  name: string;
  markerId: string | null;
  code: string | null;
  questionId: string | null;
  marker: UserFragment | null;
  members: {
    id: string;
    displayName: string;
  }[];
};

/**
 * Given marking groups, all Students,  studentId-WorkId keyValue pairs of an assessment,
 * current Work Id of marking tool, return the groups state for display that is used
 * in markingTool slice
 *
 * @param payload LoadGroupsPayload
 * @returns Groups state for display that is used in markingTool slice
 */
export function initMarkGroups(payload: LoadGroupsPayload): MarkingGroup[] {
  const studentWithOutcomeIds = payload.studentWorks.map(
    (studentWork) => studentWork.studentId
  );

  const groupedStudentIds = payload.groups.flatMap((group) =>
    group.members.map((member) => member.id)
  );

  const ungroupedStudents = payload.allStudents
    .filter(
      (student) =>
        !groupedStudentIds.includes(student.id) &&
        studentWithOutcomeIds.includes(student.id)
    )
    .map((student) =>
      addDisplayNameToStudent(student, payload.anonymousMarkingEnabled)
    );

  const processedGroups = payload.groups
    .map((group) => {
      const membersWithOutcomes = group.members.filter((student) =>
        studentWithOutcomeIds.includes(student.id)
      );
      return {
        ...group,
        members: addDisplayNameToMembers(
          membersWithOutcomes,
          payload.allStudents,
          payload.anonymousMarkingEnabled
        ),
      };
    })
    .filter((group) => group.members.length > 0);

  const groups =
    ungroupedStudents.length > 0
      ? [
          ...processedGroups,
          {
            id: DefaultGroupId,
            name: "Ungrouped",
            members: ungroupedStudents,
            markerId: null,
            code: null,
            questionId: null,
            marker: null,
          },
        ]
      : processedGroups;

  return groups;
}

function addDisplayNameToMembers(
  members: UserFragment[],
  allStudents: (UserFragment & {
    anonymousIndex: number | null;
  })[],
  anonymousMarkingEnabled: boolean
) {
  return members.map((member) => {
    const studentInfo = allStudents.find((s) => s.id === member.id);
    return studentInfo
      ? addDisplayNameToStudent(studentInfo, anonymousMarkingEnabled)
      : { ...member, displayName: member.name };
  });
}

function addDisplayNameToStudent(
  student: {
    id: string;
    anonymousIndex: number | null;
    name: string;
  },
  anonymousMarkingEnabled: boolean
) {
  return {
    ...student,
    displayName: anonymousMarkingEnabled
      ? `Student ${student.anonymousIndex}`
      : student.name,
  };
}
