import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@/data/store";

export const selectMark = (state: RootState) => state.mark;

export const selectCurrentMarkUserId = (state: RootState) =>
  state.mark.currentUserId;

export const selectCurrentQuestionIds = (state: RootState) =>
  state.mark.currentQuestionIds;

export const selectMarkingGroups = (state: RootState) => state.mark.groups;

export const selectStudentWorks = (state: RootState) => state.mark.studentWorks;

export const selectCurrentMarkUser = createSelector(
  [selectMark, selectCurrentMarkUserId],
  (markingToolState, currentUserId) => {
    const allUsers = markingToolState.groups.flatMap((group) => group.members);
    return allUsers.find((allUser) => allUser.id === currentUserId);
  }
);

export const selectCurrentMarkerId = (state: RootState) =>
  state.mark.currentMarkerId;

export const selectGroupType = (state: RootState) => state.mark.groupType;
