import { useCallback } from "react";
import { styled } from "styled-components";

import { desktop, Modal, tablet } from "@vericus/cadmus-ui";

import NiceModal from "@ebay/nice-modal-react";

import {
  Category,
  useCategories,
  useContentfulTemplates,
  useSelectedCategory,
  useShowUiSkeletons,
  useUpdateSelectedSubcategory,
} from "@/data/templates";
import { useRemoveSearchParams, useRootNavigate } from "@/router/routing";
import { ConnectedMyAssessments } from "@/ui/templates/ConnectedMyAssessments";
import { useOnNewInstructionsClick } from "@/ui/templates/templatesHooks";

import { TemplatesModalId, useAppModal } from "./modals";
import { Sidebar, SidebarSkeleton } from "./Sidebar";
import { ConnectedTemplates } from "./Templates";

export interface TemplatesModalProps {
  isVisible: boolean;
  onClose: VoidFunction;
  onDashboardClick: VoidFunction;
  onNewInstructionsClick: VoidFunction;
  onSubcategoryClick: (subcategoryId: string, subcategoryTitle: string) => void;
  categories: Category[];
  /** Templates grid */
  children: JSX.Element;
  isLoading?: boolean;
  /** Whether to prevent the animation when the modal is shown. */
  noAnimation?: boolean;
}

/** A purely presentational modal to display templates. */
export const TemplatesModal = ({
  isVisible,
  categories,
  children,
  onClose,
  onDashboardClick,
  onNewInstructionsClick,
  onSubcategoryClick,
  isLoading,
  noAnimation,
}: TemplatesModalProps) => {
  const sidebar = isLoading ? (
    <SidebarSkeleton />
  ) : (
    <Sidebar
      categories={categories}
      onSubcategoryClick={onSubcategoryClick}
      onDashboardClick={onDashboardClick}
      onNewInstructionsClick={onNewInstructionsClick}
    />
  );

  return (
    <Modal.Root
      open={isVisible}
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
    >
      <StyledModalContent
        withCloseButton
        withAnimation={!noAnimation}
        withOverlay="bypass-radix"
      >
        <SidebarContainer>{sidebar}</SidebarContainer>
        <TemplatesContainer>{children}</TemplatesContainer>
      </StyledModalContent>
    </Modal.Root>
  );
};

const StyledModalContent = styled(Modal.Content)`
  display: flex;
  justify-content: flex-start;
  height: 90vh;
  width: 40rem;
  ${tablet`
    width: 59rem;
  `}
  ${desktop`
    width: 68rem;
  `}
`;

export const useOnCloseTemplatesModal = () => {
  const modal = useAppModal(TemplatesModalId.Templates);
  const removeSearchParams = useRemoveSearchParams();

  return useCallback(() => {
    removeSearchParams(["opentemplates", "createnew", "subcategoryslug"]);
    modal.remove();
  }, [modal, removeSearchParams]);
};

const useOnDashboardClick = () => {
  const rootNavigate = useRootNavigate();
  const goHome = useCallback(() => {
    rootNavigate("/task", {
      replace: true,
    });
  }, [rootNavigate]);
  const closeModal = useOnCloseTemplatesModal();
  return useCallback(() => {
    goHome();
    closeModal();
  }, [goHome, closeModal]);
};

export interface ConnectedTemplatesModalProps {
  /** Whether to prevent the animation when the modal is shown. */
  noAnimation?: boolean;
}

/** Provides data and business logic to the templates modal. */
export const ConnectedTemplatesModal =
  NiceModal.create<ConnectedTemplatesModalProps>(({ noAnimation }) => {
    // Populate Contentful templates
    useContentfulTemplates();

    const updateSelectedSubcategory = useUpdateSelectedSubcategory();
    const { data: categories } = useCategories();

    const modal = useAppModal(TemplatesModalId.Templates);
    const onClose = useOnCloseTemplatesModal();
    const onDashboardClick = useOnDashboardClick();

    const showUiSkeletons = useShowUiSkeletons();
    const onNewInstructionsClick = useOnNewInstructionsClick();

    const selectedCategory = useSelectedCategory();

    return (
      <TemplatesModal
        isLoading={showUiSkeletons}
        isVisible={modal.visible}
        onClose={onClose}
        onDashboardClick={onDashboardClick}
        onNewInstructionsClick={onNewInstructionsClick}
        onSubcategoryClick={updateSelectedSubcategory}
        categories={categories}
        noAnimation={noAnimation}
      >
        {renderView(selectedCategory)}
      </TemplatesModal>
    );
  });

const renderView = (category?: Category) => {
  // Rendering strategy determined by ID allow-list might not be sustainable in a dynamic environment.
  if (category?.id === "my-assessments") return <ConnectedMyAssessments />;
  return <ConnectedTemplates />;
};

const SidebarContainer = styled.div`
  flex-shrink: 0;
  overflow-y: auto;
  height: 100%;
  width: 180px;
  ${tablet`
    width: 273px;
  `}
  ${desktop`
    width: 273px;
  `}
`;

const TemplatesContainer = styled.div`
  overflow-y: auto;
  width: 100%;
`;
