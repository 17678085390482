import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import client from "client/apollo";
import { produce } from "immer";
import { v4 as uuid } from "uuid";

import { RootState } from "@/data/store";
import { trackNetworkRequest } from "@/data/task/actions";
import {
  CreateTaskBlockDocument,
  CreateTaskBlockInput,
  CreateTaskBlockMutation,
  CreateTaskBlockMutationVariables,
  QuestionType,
  TaskBlockFragment,
  TaskBlockFragmentDoc,
  UpdateTaskBlockDocument,
  UpdateTaskBlockMutation,
  UpdateTaskBlockMutationVariables,
  UpdateTaskBlocksDocument,
  UpdateTaskBlocksMutation,
  UpdateTaskBlocksMutationVariables,
} from "@/generated/graphql";

import { clearTaskBlockError } from "../task-validation";
import {
  mergeOtherBlocksIntoRootBlocks,
  moveBlockInArray,
  moveBlockIntoParent,
  moveBlockOutOfParent,
  replaceChildBlocks,
} from "./local-mutations";
import { selectRootBlocks, selectSortedTaskBlocks } from "./selector";
import { updateSortedBlocks } from "./slice";
import { UpdatedBlock } from "./types";
import {
  getDescendantBlocks,
  getTempId,
  getUpdatedBlocks,
  removeTempId,
} from "./utils";

interface AddTaskPayload extends CreateTaskBlockInput {
  taskId: string;
  questionType: QuestionType;
}

interface DeleteTaskPayload {
  id: string;
}

interface UpdateHiddenPayload {
  id: string;
  hidden: boolean;
}

export const addTaskBlock = createAsyncThunk<
  unknown,
  AddTaskPayload,
  { state: RootState }
>("taskLayout/addTaskBlock", async (payload, thunkAPI) => {
  const { taskId, questionType, parentNodeId, shuffle, points, hidden } =
    payload;
  const taskBlockId = getTempId();
  const questionId = getTempId();
  const nodeId = uuid();

  const sortedBlocks = thunkAPI.getState().taskLayout.sortedTaskBlocks;
  let previousNodeId: string | null = null;
  // get previousNodeId for the inserted block
  const previousIndex = sortedBlocks
    .map((t) => t.parentNodeId)
    .lastIndexOf(parentNodeId ?? null);
  if (previousIndex === -1 || questionType === QuestionType.Overview) {
    previousNodeId = null;
  } else {
    const block = sortedBlocks[previousIndex]!;
    if (block.questionType === QuestionType.Overview) {
      previousNodeId = null;
    } else {
      previousNodeId = block.nodeId;
    }
  }
  const mutationPromise = client.mutate<
    CreateTaskBlockMutation,
    CreateTaskBlockMutationVariables
  >({
    mutation: CreateTaskBlockDocument,
    variables: {
      taskId,
      input: {
        questionType,
        nodeId,
        parentNodeId,
        previousNodeId,
        shuffle,
        hidden,
      },
    },
    optimisticResponse: () => {
      const block = {
        id: taskBlockId,
        nodeId,
        previousNodeId: previousNodeId,
        parentNodeId: parentNodeId ?? null,
        quantity: 1,
        hidden: false,
        deleted: false,
        shuffle: shuffle ?? false,
        points: points ?? 0,
        order: null,
        orderLabel: null,
        question: {
          id: questionId,
          cacheId: `${questionId}:task`,
          questionType,
          shortPrompt: null,
          body: null,
        },
      };
      thunkAPI.dispatch(add({ blocks: [block] }));
      return {
        createTaskBlock: {
          __typename: "TaskBlock",
          ...block,
          question: {
            __typename: "Question",
            ...block.question,
          },
        },
      };
    },
    update: (cache, { data }) => {
      if (!data?.createTaskBlock) return;
      if (data.createTaskBlock.id !== taskBlockId) {
        thunkAPI.dispatch(
          updateTempTaskBlock({ id: taskBlockId, block: data.createTaskBlock })
        );
        removeTempId(questionId);
        removeTempId(taskBlockId);
        cache.modify({
          id: `Task:${taskId}`,
          fields: {
            blocks(existingBlocks = []) {
              const newBlockRef = cache.writeFragment({
                data: data.createTaskBlock,
                fragment: TaskBlockFragmentDoc,
                fragmentName: "TaskBlock",
              });
              return [...existingBlocks, newBlockRef];
            },
          },
        });
      }
    },
  });
  thunkAPI.dispatch(trackNetworkRequest({ requestPromise: mutationPromise }));
  return mutationPromise;
});

export const remove = createAsyncThunk<
  unknown,
  DeleteTaskPayload,
  { state: RootState }
>("taskLayout/remove", async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const { sortedTaskBlocks: sortedQuestions } = state.taskLayout;
  const block = sortedQuestions.find((t) => t.id === payload.id);
  if (block) {
    const updatedBlocks = getUpdatedBlocks(sortedQuestions, {
      id: block.id,
      nodeId: block.nodeId,
      parentNodeId: block.parentNodeId,
      previousNodeId: block.previousNodeId,
      deleted: true,
    });
    thunkAPI.dispatch(updateTaskBlocks(updatedBlocks));
    thunkAPI.dispatch(clearTaskBlockError({ taskBlockId: block.id }));

    return await client.mutate<
      UpdateTaskBlocksMutation,
      UpdateTaskBlocksMutationVariables
    >({
      mutation: UpdateTaskBlocksDocument,
      variables: {
        taskBlocksList: [
          ...updatedBlocks,
          ...getDescendantBlocks(sortedQuestions, block.nodeId).map((t) => ({
            id: t.id,
            nodeId: t.nodeId,
            parentNodeId: t.parentNodeId,
            previousNodeId: t.previousNodeId,
            deleted: true,
          })),
        ],
      },
    });
  }
});

interface MoveRootBlocksPayload {
  /** The ID of the block. */
  nodeId: string;
  /** The index in the array of root blocks. */
  destinationIndex: number;
}

/**
 * Handles moving questions around at the root level.
 * Does not handle parent<->child transitions.
 */
export const moveRootBlocks = createAsyncThunk<
  unknown,
  MoveRootBlocksPayload,
  { state: RootState }
>("taskLayout/moveRootBlocks", async (payload, thunkAPI) => {
  const { nodeId, destinationIndex } = payload;
  const state = thunkAPI.getState();
  const originalSortedBlocks = state.taskLayout.sortedTaskBlocks;

  // here we use the draft state so that the functions can write into the block objects
  // note that we only pass the sortedTaskBlocks to an actual reducer via an action payload
  const newState = produce(state, (draft) => {
    const allBlocks = selectSortedTaskBlocks(draft);
    const rootBlocks = selectRootBlocks(draft);
    const movedRootBlocks = moveBlockInArray(
      rootBlocks,
      nodeId,
      destinationIndex
    );
    const newSortedBlocks = mergeOtherBlocksIntoRootBlocks(
      allBlocks,
      movedRootBlocks
    );
    draft.taskLayout.sortedTaskBlocks = newSortedBlocks;
  });

  const newBlocks = newState.taskLayout.sortedTaskBlocks;
  const movedBlock = newBlocks.find((block) => block.nodeId === nodeId);
  if (!movedBlock) return;
  const updateBlock: UpdatedBlock = {
    id: movedBlock.id,
    nodeId: movedBlock.nodeId,
    parentNodeId: movedBlock.parentNodeId,
    previousNodeId: movedBlock.previousNodeId,
    deleted: movedBlock.deleted,
  };
  const movedBlocks = getUpdatedBlocks(originalSortedBlocks, updateBlock);

  thunkAPI.dispatch(updateSortedBlocks({ blocks: newBlocks }));
  return client.mutate<
    UpdateTaskBlocksMutation,
    UpdateTaskBlocksMutationVariables
  >({
    mutation: UpdateTaskBlocksDocument,
    variables: {
      taskBlocksList: movedBlocks,
    },
  });
});

interface MoveChildBlocksPayload {
  nodeId: string;
  /** The new index in the child list for the same parent. */
  destinationIndex: number;
}

export const moveChildBlocks = createAsyncThunk<
  unknown,
  MoveChildBlocksPayload,
  { state: RootState }
>("taskLayout/moveChildBlocks", async (payload, thunkAPI) => {
  const { nodeId, destinationIndex } = payload;
  const state = thunkAPI.getState();
  const originalSortedBlocks = state.taskLayout.sortedTaskBlocks;

  // here we use the draft state so that the functions can write into the block objects
  // note that we only pass the sortedTaskBlocks to an actual reducer via an action payload
  const newState = produce(state, (draft) => {
    const allBlocks = selectSortedTaskBlocks(draft);
    const movedBlock = allBlocks.find((block) => block.nodeId === nodeId);
    if (!movedBlock || !movedBlock.parentNodeId) return;

    const childBlocks = allBlocks.filter(
      (block) => block.parentNodeId === movedBlock.parentNodeId
    );

    const movedChildBlocks = moveBlockInArray(
      childBlocks,
      nodeId,
      destinationIndex
    );

    const newSortedBlocks = replaceChildBlocks(
      allBlocks,
      movedBlock.parentNodeId,
      movedChildBlocks
    );

    draft.taskLayout.sortedTaskBlocks = newSortedBlocks;
  });

  const newBlocks = newState.taskLayout.sortedTaskBlocks;
  const movedBlock = newBlocks.find((block) => block.nodeId === nodeId);
  if (!movedBlock) return;
  const updateBlock: UpdatedBlock = {
    id: movedBlock.id,
    nodeId: movedBlock.nodeId,
    parentNodeId: movedBlock.parentNodeId,
    previousNodeId: movedBlock.previousNodeId,
    deleted: movedBlock.deleted,
  };
  const movedBlocks = getUpdatedBlocks(originalSortedBlocks, updateBlock);

  thunkAPI.dispatch(updateSortedBlocks({ blocks: newBlocks }));
  return client.mutate<
    UpdateTaskBlocksMutation,
    UpdateTaskBlocksMutationVariables
  >({
    mutation: UpdateTaskBlocksDocument,
    variables: {
      taskBlocksList: movedBlocks,
    },
  });
});

interface MoveRootBlockIntoParent {
  /** The ID of the block being moved */
  nodeId: string;
  /** The ID of the parent accepting the block being moved */
  parentNodeId: string;
}

export const moveRootBlockIntoParent = createAsyncThunk<
  unknown,
  MoveRootBlockIntoParent,
  { state: RootState }
>("taskLayout/moveRootBlockIntoParent", async (payload, thunkAPI) => {
  const { nodeId, parentNodeId } = payload;
  const state = thunkAPI.getState();
  const originalSortedBlocks = state.taskLayout.sortedTaskBlocks;

  // here we use the draft state so that the functions can write into the block objects
  // note that we only pass the sortedTaskBlocks to an actual reducer via an action payload
  const newState = produce(state, (draft) => {
    const sortedTaskBlocks = selectSortedTaskBlocks(draft);
    const newBlocks = moveBlockIntoParent(
      sortedTaskBlocks,
      nodeId,
      parentNodeId
    );
    draft.taskLayout.sortedTaskBlocks = newBlocks;
  });

  const newBlocks = newState.taskLayout.sortedTaskBlocks;

  const movedBlock = newBlocks.find((block) => block.nodeId === nodeId);
  if (!movedBlock) return;
  const updateBlock: UpdatedBlock = {
    id: movedBlock.id,
    nodeId: movedBlock.nodeId,
    parentNodeId: movedBlock.parentNodeId,
    previousNodeId: movedBlock.previousNodeId,
    deleted: movedBlock.deleted,
  };

  const movedBlocks = getUpdatedBlocks(originalSortedBlocks, updateBlock);

  thunkAPI.dispatch(updateSortedBlocks({ blocks: newBlocks }));

  return client.mutate<
    UpdateTaskBlocksMutation,
    UpdateTaskBlocksMutationVariables
  >({
    mutation: UpdateTaskBlocksDocument,
    variables: {
      taskBlocksList: movedBlocks,
    },
  });
});

interface MoveChildBlockOutOfParentPayload {
  /** The ID of the child being moving out of their parent block. */
  nodeId: string;
}

export const moveChildBlockOutOfParent = createAsyncThunk<
  unknown,
  MoveChildBlockOutOfParentPayload,
  { state: RootState }
>("taskLayout/moveChildBlockOutOfParent", (payload, thunkAPI) => {
  const { nodeId } = payload;
  const state = thunkAPI.getState();
  const originalSortedBlocks = state.taskLayout.sortedTaskBlocks;

  // here we use the draft state so that the functions can write into the block objects
  // note that we only pass the sortedTaskBlocks to an actual reducer via an action payload
  const newState = produce(state, (draft) => {
    const sortedTaskBlocks = selectSortedTaskBlocks(draft);
    const newBlocks = moveBlockOutOfParent(sortedTaskBlocks, nodeId);
    draft.taskLayout.sortedTaskBlocks = newBlocks;
  });

  const newBlocks = newState.taskLayout.sortedTaskBlocks;
  const movedBlock = newBlocks.find((block) => block.nodeId === nodeId);
  if (!movedBlock) return;
  const updateBlock: UpdatedBlock = {
    id: movedBlock.id,
    nodeId: movedBlock.nodeId,
    parentNodeId: movedBlock.parentNodeId,
    previousNodeId: movedBlock.previousNodeId,
    deleted: movedBlock.deleted,
  };
  const movedBlocks = getUpdatedBlocks(originalSortedBlocks, updateBlock);

  thunkAPI.dispatch(updateSortedBlocks({ blocks: newBlocks }));

  return client.mutate<
    UpdateTaskBlocksMutation,
    UpdateTaskBlocksMutationVariables
  >({
    mutation: UpdateTaskBlocksDocument,
    variables: {
      taskBlocksList: movedBlocks,
    },
  });
});

export const updateHiddenOverview = createAsyncThunk<
  unknown,
  UpdateHiddenPayload,
  { state: RootState }
>("taskLayout/updateHiddenOverview", async (payload, thunkAPI) => {
  const { id, hidden } = payload;
  thunkAPI.dispatch(updateHiddenTaskBlock(payload));
  return await client.mutate<
    UpdateTaskBlockMutation,
    UpdateTaskBlockMutationVariables
  >({
    mutation: UpdateTaskBlockDocument,
    variables: {
      taskBlockId: id,
      input: {
        hidden,
      },
    },
  });
});

export const add = createAction<{ blocks: TaskBlockFragment[] }>(
  "taskLayout/add"
);

export const updateTempTaskBlock = createAction<{
  id: string;
  block: TaskBlockFragment;
}>("taskLayout/updateTempTaskBlock");

export const updateTaskBlocks = createAction<Array<UpdatedBlock>>(
  "taskLayout/updateTaskBlocks"
);

export const updateHiddenTaskBlock = createAction<UpdateHiddenPayload>(
  "taskLayout/updateTaskBlockHidden"
);
