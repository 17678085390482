import { forwardRef, HTMLAttributes, ReactNode } from "react";
import { styled } from "styled-components";

import { Color } from "@vericus/cadmus-ui";

export interface BigPillProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  backgroundColor: Color;
  textColor: Color;
  /** Used to optionally pass in values to override props. */
  overrides?: {
    /** Use non-CUI background color */
    backgroundColor?: string;
    /** Use non-CUI text color */
    textColor?: string;
  };
  /**
   * Whether the pill fits its contents or grows to fill its container.
   * @default "fit-content"
   */
  width?: "fit-content" | "grow" | string;
}

export const BigPill = forwardRef<HTMLDivElement, BigPillProps>(
  ({ children, width = "fit-content", ...otherProps }, ref) => {
    return (
      <Container {...otherProps} width={width} ref={ref}>
        {children}
      </Container>
    );
  }
);

const Container = styled.div<
  Pick<BigPillProps, "textColor" | "backgroundColor" | "width" | "overrides">
>`
  box-sizing: border-box;
  display: flex;
  padding: 4px 8px;
  width: ${({ width }) => (width === "grow" ? "100%" : width)};
  min-width: 60px;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({ theme: { colors }, backgroundColor, overrides }) =>
    overrides?.backgroundColor ?? colors[backgroundColor]};
  color: ${({ theme: { colors }, textColor, overrides }) =>
    overrides?.textColor ?? colors[textColor]};
  cursor: pointer;
  text-align: center;
  border: 0;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px ${(p) => p.theme.colors.blue500};
  }
`;
