import { useCallback } from "react";

import { useJitsu } from "@jitsu/react";
import { JitsuEvent } from "data/events/JitsuEvent";
import {
  ClassDocument,
  ClassQuery,
  EnrollmentFragment,
  StudentSettingsInput,
  useUpdateStudentSettingsMutation,
} from "generated/graphql";
import { produce } from "immer";
import toast from "react-hot-toast";

/**
 * Create callbacks to update and clear due date and time limit extension
 * settings for a student enrollment.
 */
export function useUpdateStudentSettings(
  assessmentId: string,
  onCompleted?: () => void
) {
  const { track } = useJitsu();
  const [updateStudentSettingsMutation] = useUpdateStudentSettingsMutation({
    ignoreResults: true,
  });

  const updateStudentSettings = useCallback(
    (
      enrollments: EnrollmentFragment[],
      input: Partial<StudentSettingsInput>,
      overwrite = false
    ) => {
      const { dueDate, timeLimit } = input;
      const settings = enrollments.map(
        (enrollment) =>
          ({
            studentId: enrollment.user.id,
            dueDate:
              overwrite && dueDate !== undefined
                ? dueDate
                : dueDate ?? enrollment.workSettings?.dueDate ?? null,
            timeLimit:
              overwrite && timeLimit !== undefined
                ? timeLimit
                : timeLimit ?? enrollment.workSettings?.timeLimit ?? null,
          }) as StudentSettingsInput
      );

      return updateStudentSettingsMutation({
        variables: {
          assessmentId,
          settings,
        },
        update: (proxy, { data }) => {
          if (data?.updateStudentSettings) {
            const cacheData = proxy.readQuery<ClassQuery>({
              query: ClassDocument,
              variables: { assessmentId },
            });
            if (cacheData) {
              // Using immer to update the cache
              const newData = produce(cacheData, (draft) => {
                for (const workSettings of data.updateStudentSettings) {
                  const studentId = workSettings?.studentId;
                  const enrollmentIndex = draft.class.students.findIndex(
                    ({ user: { id } }) => id === studentId
                  );
                  if (enrollmentIndex !== -1) {
                    draft.class.students[enrollmentIndex]!.workSettings =
                      workSettings;
                  }
                }
              });

              proxy.writeQuery<ClassQuery>({
                query: ClassDocument,
                variables: { assessmentId },
                data: newData,
              });
            }
          }
        },
        onError: () => {
          toast.error("Failed to update extensions. Please try again.");
        },
        onCompleted: () => {
          onCompleted?.();

          const bulkOperation = enrollments.length > 1;

          // Success toasts
          if (dueDate) {
            // for dueDate updates
            const msg = !bulkOperation
              ? `${enrollments[0]!.user.name}'s due date`
              : "Due date";
            toast.success(`${msg} has been extended`);
          } else if (timeLimit) {
            // or timeLimit updates
            const msg = !bulkOperation
              ? `${enrollments[0]!.user.name}'s time limit`
              : "Time limit";
            toast.success(`${msg} has been extended`);
          } else if (dueDate === null) {
            // or dueDate removal
            const msg = !bulkOperation
              ? `${enrollments[0]!.user.name}'s due date extension`
              : "Due date extensions";
            toast.success(`${msg} has been removed`);
          } else if (timeLimit === null) {
            // or timeLimit removal
            const msg = !bulkOperation
              ? `${enrollments[0]!.user.name}'s time limit extension`
              : "Time limit extensions";
            toast.success(`${msg} has been removed`);
          }

          // Success event logging
          if (dueDate || timeLimit) {
            if (enrollments.length === 1) {
              const enrollment = enrollments[0]!;
              track(JitsuEvent.EXTENSION_UPDATED, {
                student_id: enrollment.user.id,
                enrollment_id: enrollment.id,
                due_date: dueDate ?? undefined,
                time_limit: timeLimit ?? undefined,
                prev_time_limit:
                  enrollment.workSettings?.timeLimit ?? undefined,
                prev_due_date: enrollment.workSettings?.dueDate ?? undefined,
              });
            } else {
              track(JitsuEvent.EXTENSION_BULK_UPDATED, {
                due_date: dueDate ?? undefined,
                timeLimit: timeLimit ?? undefined,
                enrollments: enrollments.map((e) => ({
                  enrollment_id: e.id,
                  student_id: e.user.id,
                  prev_due_date: e.workSettings?.dueDate ?? undefined,
                  prev_time_limit: e.workSettings?.timeLimit ?? undefined,
                })),
              });
            }
          } else if (dueDate === null || timeLimit === null) {
            if (enrollments.length === 1) {
              const enrollment = enrollments[0]!;
              track(JitsuEvent.EXTENSION_CLEARED, {
                student_id: enrollment.user.id,
                enrollment_id: enrollment.id,
                due_date_cleared: dueDate === null ? true : false,
                time_limit_cleared: timeLimit === null ? true : false,
                prev_time_limit:
                  enrollment.workSettings?.timeLimit ?? undefined,
                prev_due_date: enrollment.workSettings?.dueDate ?? undefined,
              });
            } else {
              track(JitsuEvent.EXTENSION_BULK_CLEARED, {
                due_date_cleared: dueDate === null ? true : false,
                time_limit_cleared: timeLimit === null ? true : false,
                enrollments: enrollments.map((e) => ({
                  student_id: e.user.id,
                  enrollment_id: e.id,
                  prev_due_date: e.workSettings?.dueDate ?? undefined,
                  prev_time_limit: e.workSettings?.timeLimit ?? undefined,
                })),
              });
            }
          }
        },
      });
    },
    [updateStudentSettingsMutation, assessmentId, track, onCompleted]
  );

  // Callback to update due date extension on multiple enrollments.
  const updateDueDates = useCallback(
    (enrollments: EnrollmentFragment[], dueDate: string) => {
      if (!enrollments.length) return;
      return updateStudentSettings(enrollments, { dueDate });
    },
    [updateStudentSettings]
  );

  // Callback to update time limit extensions on multiple enrollments
  const updateTimeLimits = useCallback(
    (enrollments: EnrollmentFragment[], timeLimit: number) => {
      if (!enrollments.length) return;
      return updateStudentSettings(enrollments, { timeLimit });
    },
    [updateStudentSettings]
  );

  // Clear any extended due date on multiple enrollments
  const clearDueDates = useCallback(
    (enrollments: EnrollmentFragment[]) => {
      if (!enrollments.length) return;
      return updateStudentSettings(enrollments, { dueDate: null }, true);
    },
    [updateStudentSettings]
  );

  // Callback to clear extended time limits on enrollments
  const clearTimeLimits = useCallback(
    (enrollments: EnrollmentFragment[]) => {
      if (!enrollments.length) return;
      return updateStudentSettings(enrollments, { timeLimit: null }, true);
    },
    [updateStudentSettings]
  );

  return {
    updateDueDates,
    updateTimeLimits,
    clearDueDates,
    clearTimeLimits,
  };
}
