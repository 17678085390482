import { useCallback } from "react";

import { useJitsu } from "@jitsu/react";
import toast from "react-hot-toast";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { useCurrentUser } from "@/data/user/useCurrentUser";
import {
  EnrollmentFragment,
  useUpdateEnrollmentsStatusMutation,
} from "@/generated/graphql";

/**
 * A thin wrapper of `updateEnrollmentsStatusMutation` that optimistically update the
 * `deletedBy` & `deleted` field of enrollment and notify user through
 * a toast when the update is successful
 **/
export function useUpdateEnrollmentsStatus(assessmentId: string) {
  const [mutate] = useUpdateEnrollmentsStatusMutation();
  const user = useCurrentUser();
  const { track } = useJitsu();
  return useCallback(
    async (enrollments: EnrollmentFragment[], withdrawn: boolean) => {
      if (!user?.me) return;
      await mutate({
        variables: {
          enrollmentIds: enrollments.map((enrollment) => enrollment.id),
          withdrawn,
          assessmentId,
        },
        optimisticResponse: {
          updateEnrollmentsStatus: enrollments.map((enrollment) => ({
            ...enrollment,
            deleted: withdrawn,
            deletedBy: withdrawn ? user.me : null,
          })),
        },
      });
      track(JitsuEvent.ENROLLMENTS_STATUS_UPDATED, {
        deleted: withdrawn,
        enrollment_ids: enrollments.map(({ id }) => id),
      });
      if (enrollments.length === 1) {
        const name =
          enrollments[0]!.user.givenName || enrollments[0]!.user.name;
        if (withdrawn) {
          toast.success(`${name} has been manually withdrawn`);
        } else {
          toast.success(`${name} is no longer withdrawn from the class`);
        }
      } else {
        const numberOfStudents = enrollments.length;
        if (withdrawn) {
          toast.success(
            `${numberOfStudents} students have been manually withdrawn`
          );
        } else {
          toast.success(
            `${numberOfStudents} students are no longer withdrawn from the class`
          );
        }
      }
    },
    [mutate, assessmentId, user, track]
  );
}
