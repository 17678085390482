import * as Sentry from "@sentry/react";

import { DEV } from "@/config";

/**
 * Log errors to the console when developing, or to Sentry otherwise.
 */
export const logAppError = (...args: unknown[]) => {
  if (DEV) {
    console.error(args);
  } else {
    Sentry.captureException(args);
  }
};
