import { useEffect, useReducer } from "react";

import { ResourceFragment } from "generated/graphql";
import { produce } from "immer";

import {
  addExtension,
  getExtension,
  isLink,
  removeExtension,
} from "../internal";

interface ResourceBeingEdited extends ResourceFragment {
  nameBeingEdited: string;
}

interface State {
  resource?: ResourceBeingEdited;
}

type Action =
  | { type: "update-notes"; notes: string }
  | { type: "set-resource"; resource: ResourceFragment }
  | { type: "clear-resource" }
  | { type: "update-name"; name: string };

const initialState: State = {};

function immerReducer(draft: State, action: Action): State | void {
  switch (action.type) {
    case "clear-resource":
      return {};
    case "set-resource": {
      const resourceName = action.resource.name ?? "";
      const nameBeingEdited = isLink(action.resource)
        ? resourceName
        : removeExtension(resourceName);
      draft.resource = {
        ...action.resource,
        nameBeingEdited,
      };
      return;
    }
    case "update-notes":
      if (draft.resource) {
        draft.resource.notes = action.notes;
      }
      return;
    case "update-name":
      if (!draft.resource) {
        return;
      }
      draft.resource.nameBeingEdited = action.name;
      return;
    default:
      return;
  }
}

const reducer = produce(immerReducer);

export const useResourceBeingEdited = (resources: ResourceFragment[]): Hook => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setResourceBeingEdited = (resource: ResourceFragment) =>
    dispatch({
      type: "set-resource",
      resource: { ...resource },
    });
  const clearResourceBeingEdited = () => dispatch({ type: "clear-resource" });

  const freshlyUploadedFile = resources.find((r) => r.isFreshlyUploaded);
  useEffect(() => {
    if (freshlyUploadedFile) {
      setResourceBeingEdited(freshlyUploadedFile);
      return;
    }
    clearResourceBeingEdited();
  }, [freshlyUploadedFile]);

  return {
    clearResourceBeingEdited,
    isBeingEdited: (resource) => resource.id === state.resource?.id,
    nameBeingEdited: state.resource?.nameBeingEdited || "",
    notesBeingEdited: state.resource?.notes || "",
    setResourceBeingEdited,
    updateName: (newName) => dispatch({ type: "update-name", name: newName }),
    updateNotes: (notes) => dispatch({ type: "update-notes", notes }),
    validatedName: getValidatedName(state),
  };
};

/**
 * Returns a resource name that could be submitted to the API.
 * This adds back the file extension for file resources.
 */
const getValidatedName = (state: State): string => {
  if (!state.resource) {
    return "";
  }
  if (!state.resource.nameBeingEdited) {
    return state.resource.name || "";
  }
  const canChangeNameFreely = isLink(state.resource);
  if (canChangeNameFreely) {
    return state.resource.nameBeingEdited;
  } else {
    return addExtension(
      state.resource.nameBeingEdited,
      getExtension(state.resource.name ?? "") || ""
    );
  }
};

export interface Hook {
  isBeingEdited: (resource: ResourceFragment) => boolean;
  clearResourceBeingEdited: VoidFunction;
  updateNotes: (newNotes: string) => void;
  updateName: (newName: string) => void;
  setResourceBeingEdited: (resource: ResourceFragment) => void;
  notesBeingEdited: string;
  nameBeingEdited: string;
  validatedName: string;
}
