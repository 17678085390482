import { memo, useCallback, useState } from "react";
import { styled } from "styled-components";

import {
  Divider,
  Icon,
  IconButton,
  Switch,
  Text,
  Tooltip,
} from "@vericus/cadmus-ui";

import NiceModal from "@ebay/nice-modal-react";

import { QuestionType } from "@/generated/graphql";

import {
  QuestionDeleteDialog,
  QuestionDeleteDialogProps,
} from "../components/question-delete-dialog";
import { TaskBlockError } from "../task-validation";
import { InteractionKind } from "./interaction-kind-utils";
import { AddSubQuestionButton } from "./question-footer-action/add-sub-question-button";
import { AdditionalFeedback } from "./question-footer-action/additional-feedback";
import {
  MCQAutoMarkCondition,
  ShortBlanksAutoMarkCondition,
} from "./question-footer-action/automark-condition";
import { WordLimitInput } from "./question-footer-action/word-limit-input";
import { Dispatch, State } from "./state";

interface Props {
  /** Form state. */
  state: State;
  /** Form state dispatcher. */
  dispatch: Dispatch;
  /** Input error */
  error: TaskBlockError;

  /** Show a top divider. @default true */
  withDivider?: boolean;

  /**
   * Callback to delete the current question. If not provided the delete icon
   * will not be shown.
   */
  onDeleteQuestion?: () => void;

  /**
   * Callback to add a new child question.
   */
  onAddChildQuestion?: (ty: QuestionType) => void;

  /**
   * Callback triggered when copy question is clicked. If not provided the copy
   * icon will not be shown.
   */
  onDuplicateQuestion?: () => void;

  /**
   * Whether task level shuffle is on.
   *
   * In which case, disable the section and sub question nodes` shuffle on
   * question forms. Has no bearing on MCQ or any other question types other
   * than `section` and `sub` question.
   */
  isTaskShuffleOn?: boolean;
}

/**
 * Footer area of the Question form with common actions like delete, duplicate .etc.
 */
function QuestionFormFooterComponent(props: Props) {
  const { withDivider = true, onDeleteQuestion, onDuplicateQuestion } = props;

  // Throttle state for the duplicate action
  const [disableDuplicate, setDisableDuplicate] = useState(false);

  const questionType = props.state.questionType;

  const handleDeleteQuestion = useCallback(() => {
    if (onDeleteQuestion) {
      NiceModal.show(QuestionDeleteDialog, {
        questionType: questionType,
        onConfirmation: onDeleteQuestion,
      } satisfies QuestionDeleteDialogProps);
    }
  }, [questionType, onDeleteQuestion]);

  const handleDuplicate = useCallback(() => {
    if (onDuplicateQuestion && !disableDuplicate) {
      setDisableDuplicate(true);
      onDuplicateQuestion();
      setTimeout(() => {
        setDisableDuplicate(false);
      }, 500);
    }
  }, [onDuplicateQuestion, disableDuplicate]);

  return (
    <FooterRoot>
      {withDivider && <FooterDivider />}
      <Content>
        <Action {...props} />{" "}
        <RightActionsWrapper>
          {onDuplicateQuestion && (
            <IconButton
              onClick={handleDuplicate}
              disabled={disableDuplicate}
              aria-label="Duplicate question"
            >
              <Icon iconName="Copy" />
            </IconButton>
          )}
          {onDeleteQuestion && (
            <IconButton
              onClick={handleDeleteQuestion}
              aria-label="Delete question"
            >
              <Icon iconName="Trash" />
            </IconButton>
          )}
        </RightActionsWrapper>
      </Content>
    </FooterRoot>
  );
}

function Action(props: Props) {
  const { state, dispatch, onAddChildQuestion, error } = props;

  /** NOTE: For question type where only one field expected, we use the first field */
  const singleField = state.questionFields[0];

  /** NOTE: Response declaration is currently similar for all the fields */
  const responseDeclaration = singleField ? singleField.response : null;

  const wordLimitForExtended = state.questionFields[0]
    ? state.questionFields[0].interaction.wordLimit
    : null;

  const textEntryField = state.questionFields.find(
    (f) => f.interaction.kind === InteractionKind.TextEntry
  );

  // NOTE: currently similarity is the same for all text entry fields
  const matchSimilarity = textEntryField?.response.matchSimilarity ?? null;
  const caseSensitive = textEntryField?.response.caseSensitive;

  switch (state.questionType) {
    case QuestionType.Mcq:
      return (
        <MCQAutoMarkCondition
          dispatch={dispatch}
          partialScoring={responseDeclaration?.partialScoring ?? true}
        />
      );
    case QuestionType.Short:
      return (
        <ShortBlanksAutoMarkCondition
          matchSimilarity={matchSimilarity}
          caseSensitive={caseSensitive}
          dispatch={dispatch}
          noRequiredSimilarity={error.noRequiredSimilarity}
          similarityTooSmall={error.similarityTooSmall}
        />
      );
    case QuestionType.Blanks:
      return (
        <ShortBlanksAutoMarkCondition
          matchSimilarity={matchSimilarity}
          caseSensitive={caseSensitive}
          dispatch={dispatch}
          noRequiredSimilarity={error.noRequiredSimilarity}
          similarityTooSmall={error.similarityTooSmall}
          disabled={textEntryField === undefined}
        />
      );
    case QuestionType.Truefalse:
      //  Change the key based on question type
      //  So when question type is changed, internal state will be re-initialize
      //  to be align with the new empty feedback in task builder state
      return (
        <AdditionalFeedback
          key={`${state.questionType}-${state.questionId}`}
          feedback={state.feedback ?? ""}
          onSaveFeedback={(feedback) => {
            dispatch({
              type: "SetFeedback",
              feedback,
            });
          }}
        />
      );
    case QuestionType.Extended:
      if (singleField) {
        return (
          <WordLimitInput
            dispatch={dispatch}
            limit={wordLimitForExtended}
            defaultLimit={800}
            hasError={error.noWordLimit}
            noWordLimit={error.noWordLimit}
            wordLimitEnabled={wordLimitForExtended !== null}
            fieldIdentifier={singleField?.identifier}
          />
        );
      }
      break;
    case QuestionType.Sub:
      if (onAddChildQuestion) {
        return (
          <LeftActionsWrapper>
            <AddSubQuestionButton onAddChildQuestion={onAddChildQuestion} />
            <ShuffleSwitchFooter
              content="Shuffle question order"
              dispatch={dispatch}
              shuffle={state.shuffle}
              disableShuffle={props.isTaskShuffleOn}
            />
          </LeftActionsWrapper>
        );
      }
      return null;
    case QuestionType.Section:
      return (
        <ShuffleSwitchFooter
          content="Shuffle questions"
          dispatch={dispatch}
          shuffle={state.shuffle}
          disableShuffle={props.isTaskShuffleOn}
        />
      );
  }

  return null;
}

export const QuestionFormFooter = memo(QuestionFormFooterComponent);

interface ShuffleSwitchFooterProps {
  disableShuffle?: boolean;
  shuffle: boolean;
  content: string;
  dispatch: Dispatch;
}

const ShuffleSwitchFooter = ({
  disableShuffle,
  content,
  shuffle,
  dispatch,
}: ShuffleSwitchFooterProps) => (
  <Tooltip
    passThrough={!disableShuffle}
    content={
      <Text kind="systemSm" color="black100">
        To shuffle specific sections instead of the entire assessment, disable
        the shuffle beneath the Assessment name at the top of this page.
      </Text>
    }
  >
    <StyledDiv>
      <Switch
        disabled={disableShuffle}
        checked={shuffle}
        onClick={() => {
          dispatch({ type: "ToggleShuffle" });
        }}
        content={content}
        onChange={() => {}}
      />
    </StyledDiv>
  </Tooltip>
);

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  padding-top: auto;
  padding-bottom: auto;
`;

const FooterRoot = styled.div`
  padding: 16px 45px 0px 45px;
`;

const FooterDivider = styled(Divider)`
  margin-bottom: 16px;
`;

const Content = styled.div<{ rowReversed?: boolean }>`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

const LeftActionsWrapper = styled.div`
  display: flex;
  column-gap: 30px;
`;

const RightActionsWrapper = styled.div`
  display: flex;
  gap: 4px;
`;
