import {
  Dispatch,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { styled } from "styled-components";

import { Icon } from "@vericus/cadmus-icons";
import {
  Button,
  ControlButton,
  DropdownMenu,
  IconButton,
  Modal,
  Popover,
  SearchList,
  SearchListItem,
  SelectButton,
  Spacer,
  Text,
  Tooltip,
} from "@vericus/cadmus-ui";

import { NumberInput } from "@/features/multi-format/components/number-input";
import { QUESTION_CONFIG } from "@/features/multi-format/components/question-type-dropdown-menu";
import { HelpIcon } from "@/ui/shared/HelpIcon";
import shuffleArray from "@/utils/shuffleArray";

import { ImportableQuestionType, ParsedQuestion } from "../../../data";
import { QuestionTypePill } from "../../tables/question-type-pill";
import * as styles from "../style.css";
import { SelectionMode } from "./constant";
import * as filterStyle from "./select-question-category.css";
import type { Action, CategorySelectFilter } from "./types";
import { filterQuestions } from "./utils";

interface Props {
  questions: ParsedQuestion[];
  setSelectionMode: (selectionMode: SelectionMode | null) => void;
  filters: CategorySelectFilter[];
  dispatch: Dispatch<Action>;
}

export function SelectQuestionCategory(props: Props) {
  const { questions, filters, setSelectionMode, dispatch } = props;
  const usedQuestionBankIds = useMemo(
    () => filters.flatMap((f) => f.questionBankIds ?? []),
    [filters]
  );
  const hasRemainingQuestions = useMemo(
    () =>
      questions.filter(
        ({ questionBankId }) =>
          questionBankId && !usedQuestionBankIds.includes(questionBankId)
      ).length > 0,
    [usedQuestionBankIds, questions]
  );
  return (
    <div className={styles.container}>
      <Modal.Title asChild>
        <Text kind="headingFour">Select questions by category</Text>
      </Modal.Title>
      <div className={styles.content}>
        <Spacer spacing={21} />
        <table className={filterStyle.tableContainer}>
          <thead className={filterStyle.tableHeader}>
            <tr>
              <th
                className={filterStyle.tableHeaderCell}
                style={{ width: "auto" }}
              >
                <Text kind="bodySm" as="span" color="navy400" bold>
                  FROM (CATEGORY)
                </Text>
              </th>
              <th
                className={filterStyle.tableHeaderCell}
                style={{ width: 240 }}
              >
                <Text kind="bodySm" as="span" color="navy400" bold>
                  QUESTION TYPE
                </Text>
              </th>
              <th
                className={filterStyle.tableHeaderCell}
                style={{ width: 200 }}
              >
                <Text kind="bodySm" as="span" color="navy400" bold>
                  NO. OF QUESTIONS{" "}
                  <Tooltip content="This value is capped at the maximum number of questions available to select within this category, after any previous selections, to prevent duplication of questions.">
                    <HelpIcon primary />
                  </Tooltip>
                </Text>
              </th>
              <th
                className={filterStyle.tableHeaderCell}
                style={{ width: 45 }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {filters.map((filter) => (
              <FilterRow
                key={filter.id}
                questions={questions}
                filters={filters}
                filter={filter}
                dispatch={dispatch}
              />
            ))}
            <tr className={filterStyle.tableRow}>
              <td colSpan={4}>
                <Button
                  iconName="Plus"
                  size="sm"
                  disabled={!hasRemainingQuestions}
                  onClick={() => dispatch({ type: "add-filter" })}
                >
                  Add More
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.buttonContainer}>
          <Button onClick={() => setSelectionMode(null)}>CANCEL</Button>
          <Button
            disabled={usedQuestionBankIds.length === 0}
            onClick={() => setSelectionMode(SelectionMode.randomQuestion)}
            kind="secondary"
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
}

interface FilterRowProps {
  questions: ParsedQuestion[];
  filters: CategorySelectFilter[];
  filter: CategorySelectFilter;
  dispatch: Dispatch<Action>;
}

const FilterRow = (props: FilterRowProps) => {
  const { filter, filters, questions, dispatch } = props;
  const availableQuestions = useMemo(() => {
    const questionBankIds = filters
      .filter(({ id }) => filter.id !== id)
      .flatMap((f) => f.questionBankIds ?? []);
    return questions.filter(
      (q) => !questionBankIds.includes(q.questionBankId!)
    );
  }, [filters, filter, questions]);
  const filteredQuestions = useMemo(
    () => filterQuestions(availableQuestions, filter.filter),
    [filter.filter, availableQuestions]
  );
  const updateFilter = useCallback(
    (newFilter: CategorySelectFilter) => {
      const { questionsNum } = newFilter;
      const { questionBankIds } = filter;
      const selectableQuestions = filterQuestions(
        availableQuestions,
        newFilter.filter
      );
      if (!questionsNum) {
        dispatch({
          type: "set-filter",
          id: filter.id,
          filter: {
            ...newFilter.filter,
          },
        });
      } else {
        const newQuestionsNum =
          selectableQuestions.length < questionsNum
            ? selectableQuestions.length
            : questionsNum;
        if (
          questionBankIds?.length !== newQuestionsNum ||
          !questionBankIds.every(
            (id) =>
              selectableQuestions.findIndex(
                ({ questionBankId }) => questionBankId === id
              ) !== -1
          )
        ) {
          dispatch({
            type: "set-filter",
            id: filter.id,
            filter: {
              ...newFilter.filter,
            },
            questionsNum: newQuestionsNum,
            questionBankIds: shuffleArray(selectableQuestions)
              .map((q) => q.questionBankId!)
              .slice(0, newQuestionsNum),
          });
        }
      }
    },
    [dispatch, availableQuestions, filter]
  );
  return (
    <tr key={props.filter.id} className={filterStyle.tableRow}>
      <td>
        <CategoryFilter
          {...props}
          questions={availableQuestions}
          updateFilter={updateFilter}
        />
      </td>
      <td>
        <QuestionTypeFilter
          {...props}
          questions={availableQuestions}
          updateFilter={updateFilter}
        />
      </td>
      <td>
        <NumQuestionsFilter
          {...props}
          questions={filteredQuestions}
          updateFilter={updateFilter}
        />
      </td>
      <td>
        <IconButton
          disabled={filters.length <= 1}
          onClick={() => dispatch({ type: "remove-filter", id: filter.id })}
        >
          <Icon iconName="Trash" />
        </IconButton>
      </td>
    </tr>
  );
};

interface FilterCellProps extends FilterRowProps {
  updateFilter: (filter: CategorySelectFilter) => void;
}

const CategoryFilter = (props: FilterCellProps) => {
  const { questions, filter, updateFilter } = props;
  const [open, setOpen] = useState(false);
  const [localCategory, setLocalCategory] = useState(filter.filter.category);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const categories: SearchListItem[] = useMemo(
    () =>
      [
        ...new Set([
          ...questions.map((q) => q.category!),
          ...(filter.filter.category ? [filter.filter.category] : []),
        ]),
      ].map((c) => ({ value: c, label: c })),
    [questions, filter.filter.category]
  );
  const onSelect = useCallback(
    (item: SearchListItem) => {
      setLocalCategory(item.value);
      setSearchQuery("");
      updateFilter({
        ...filter,
        filter: { ...filter.filter, category: item.value },
      });
      setOpen(false);
    },
    [setSearchQuery, setLocalCategory, updateFilter, filter, setOpen]
  );
  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <SelectButton className={filterStyle.fullWidthSelect}>
          {localCategory ? localCategory : "Select Category"}
        </SelectButton>
      </Popover.Trigger>
      <Popover.Content>
        <Popover.Card>
          <div>
            <SearchList
              items={categories}
              placeholder="Select Category"
              selectedItems={localCategory ? [localCategory] : []}
              queryValue={searchQuery}
              onSelect={onSelect}
              onQueryValueChange={setSearchQuery}
            />
          </div>
        </Popover.Card>
      </Popover.Content>
    </Popover.Root>
  );
};

const QuestionTypeFilter = (props: FilterCellProps) => {
  const { filter, questions, updateFilter } = props;
  const questionType = (filter.filter.questionType ??
    null) as ImportableQuestionType | null;
  const [localType, setLocalType] = useState<ImportableQuestionType | null>(
    questionType ?? null
  );
  const questionTypes = useMemo(
    () => [
      ...new Set([
        ...questions.map((q) => q.questionType!),
        ...(questionType ? [questionType] : []),
      ]),
    ],
    [questionType, questions]
  );
  const onSelect = useCallback(
    (questionType: ImportableQuestionType) => {
      if (!questionType) return;
      setLocalType(questionType);
      updateFilter({
        ...filter,
        filter: { ...filter.filter, questionType },
      });
    },
    [filter, setLocalType, updateFilter]
  );
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        {localType && QUESTION_CONFIG[localType] ? (
          <ControlButton size={44} withDownCaret>
            <QuestionTypePill
              questionType={QUESTION_CONFIG[localType].questionType}
            />
          </ControlButton>
        ) : (
          <ControlButton size={44} withDownCaret>
            Question Type
          </ControlButton>
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {questionTypes.map((questionType) => (
          <DropdownMenu.Item
            key={questionType}
            onClick={() => onSelect(questionType)}
            selected={questionType === localType}
            leftSection={QUESTION_CONFIG[questionType].icon}
            content={QUESTION_CONFIG[questionType].text}
          />
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const NumQuestionsFilter = (props: FilterCellProps) => {
  const { filter, questions, updateFilter } = props;
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [localQuestionsNum, setLocalQuestionsNum] = useState<string | null>(
    filter.questionsNum?.toString() ?? null
  );
  const numOptions = useMemo(
    () =>
      Array.from({ length: questions.length }).map((_v, index) => ({
        value: `${index + 1}`,
        label: `${index + 1}`,
      })),
    [questions]
  );
  const onSelect = useCallback(
    (item: SearchListItem) => {
      setLocalQuestionsNum(item.value);
      setSearchQuery("");
      updateFilter({
        ...filter,
        filter: { ...filter.filter },
        questionsNum: parseInt(item.value),
      });
      setOpen(false);
    },
    [setLocalQuestionsNum, setSearchQuery, filter, updateFilter, setOpen]
  );
  useEffect(() => {
    if (
      filter.questionsNum &&
      filter.questionsNum.toString() !== localQuestionsNum
    ) {
      setLocalQuestionsNum(filter.questionsNum.toString());
    }
  }, [filter.questionsNum, localQuestionsNum]);
  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <SelectButton className={filterStyle.fullWidthSelect}>
          {localQuestionsNum ? localQuestionsNum : "Select"}
        </SelectButton>
      </Popover.Trigger>
      <Popover.Content>
        <Popover.Card width={254}>
          <SearchList
            className={filterStyle.numberInputPanel}
            items={numOptions}
            selectedItems={localQuestionsNum ? [localQuestionsNum] : []}
            queryValue={searchQuery}
            onSelect={onSelect}
            onQueryValueChange={setSearchQuery}
            placeholder="Input the number of questions"
            renderInput={(props: InputHTMLAttributes<HTMLInputElement>) => (
              <StyledNumberInput {...props} />
            )}
          />
        </Popover.Card>
      </Popover.Content>
    </Popover.Root>
  );
};

const StyledNumberInput = styled(NumberInput)`
  padding: 10px 12px;
  margin-bottom: 12px;
  text-align: left;
`;
