import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "client/apollo";
import {
  CreateGroupsDocument,
  CreateGroupsMutation,
  CreateGroupsMutationVariables,
  GenerateReportDocument,
  GenerateReportMutation,
  GenerateReportMutationVariables,
  SyncRosterDocument,
  SyncRosterMutation,
  SyncRosterMutationVariables,
  UpdateGroupMembershipsDocument,
  UpdateGroupMembershipsMutation,
  UpdateGroupMembershipsMutationVariables,
} from "generated/graphql";

//////////////////////////////////////////////////////////////////////////////
// Roster Sync mutation                                                     //
//////////////////////////////////////////////////////////////////////////////

/** Trigger roster sync */
export interface SyncClassListActionPayload {
  assessmentId: string;
  /** Prevent progress toasts from showing in the UI */
  supressToasts?: boolean;
}
export const syncClassList = createAsyncThunk(
  "class/sync",
  async (payload: SyncClassListActionPayload, _thunkAPI) => {
    const { assessmentId } = payload;
    return client.mutate<SyncRosterMutation, SyncRosterMutationVariables>({
      mutation: SyncRosterDocument,
      context: { padDelay: 1000 },
      variables: {
        assessmentId,
      },
    });
  }
);

//////////////////////////////////////////////////////////////////////////////
// Assessment report generation                                             //
//////////////////////////////////////////////////////////////////////////////

export const generateAssessmentReport = createAsyncThunk(
  "class/generateAssessmentReport",
  async (payload: { assessmentId: string; email: string }, _thunkAPI) => {
    const { assessmentId, email } = payload;

    await client.mutate<
      GenerateReportMutation,
      GenerateReportMutationVariables
    >({
      mutation: GenerateReportDocument,
      variables: {
        kind: "assessment_summary",
        assessmentId,
        workId: null,
        anonymousName: null,
      },
    });

    return email;
  }
);

interface GenerateActivityReportPayload {
  assessmentId: string;
  workId: string;
  anonymousName?: string;
}

/**
 * Trigger mutation to generate a Student Activity report and open a new tab to
 * the successfully generated report URL.
 */
export const generateActivityReport = createAsyncThunk(
  "class/generateActivityReport",
  async (payload: GenerateActivityReportPayload, thunkAPI) => {
    const { assessmentId, workId, anonymousName } = payload;
    const { data } = await client.mutate<
      GenerateReportMutation,
      GenerateReportMutationVariables
    >({
      mutation: GenerateReportDocument,
      variables: {
        kind: "student_activity",
        assessmentId,
        workId,
        anonymousName: anonymousName ?? null,
      },
    });

    const resultUrl = data?.generateReport?.resultUrl;

    if (resultUrl) {
      const win = window.open(resultUrl);
      if (win) {
        win.focus();
      }

      return resultUrl;
    }

    return thunkAPI.rejectWithValue("Unable to generate report URL");
  }
);

//////////////////////////////////////////////////////////////////////////////
// Bulk groups update mutataion                                             //
//////////////////////////////////////////////////////////////////////////////

/**
 * Move students into an existing or new group, updating multiple group
 * memberships as needed.
 */
export const updateGroupMemberships = createAsyncThunk(
  "class/updateGroupMemberships",
  async (
    payload: {
      /** Cadmus Assessment ID */
      assessmentId: string;
      /** Existing or new group to move students into. */
      group: { kind: "existing"; id: string } | { kind: "new"; name: string };
      /** Student Ids whose group membership is changing */
      studentIds: string[];
    },
    _thunkAPI
  ) => {
    const { assessmentId, group, studentIds } = payload;

    // Move students to an exsiting group
    if (group.kind === "existing") {
      return updateMembershipsMutation(assessmentId, group.id, studentIds);
    }
    // Move students to a new group by first creating it
    const result = await client.mutate<
      CreateGroupsMutation,
      CreateGroupsMutationVariables
    >({
      mutation: CreateGroupsDocument,
      variables: {
        assessmentId,
        groups: [{ name: group.name, members: [], code: null, groupSet: null }],
      },
    });

    const newGroup = result?.data?.createGroups?.find(
      (g) => g.name === group.name
    );
    const groupId = newGroup?.id;

    if (groupId) {
      return updateMembershipsMutation(assessmentId, groupId, studentIds);
    }

    return null;
  }
);

async function updateMembershipsMutation(
  assessmentId: string,
  groupId: string,
  memberIds: string[]
) {
  const memberships = memberIds.map((userId) => ({
    groupId,
    userId,
  }));

  const result = await client.mutate<
    UpdateGroupMembershipsMutation,
    UpdateGroupMembershipsMutationVariables
  >({
    mutation: UpdateGroupMembershipsDocument,
    variables: {
      assessmentId,
      memberships,
    },
  });

  return result;
}
