import * as React from "react";
import { styled } from "styled-components";

import { Button, LinkButton } from "@vericus/cadmus-ui";

import { Moment } from "moment";
import { Footer as Container } from "ui/DatePicker";

import { Step } from "./internal";

interface Props {
  dueDate: Moment | null;
  returnDate: Moment | null;
  draftDueDate: Moment | null;
  /// Current step
  step: Step;
  /// Is drafting disabled?
  noDraft: boolean;
  /// Should the stateful update actions be disabled?
  disabled: boolean;
  /// Callback to change the current step
  setStep(step: Step): void;
  /// Callback called on the "Confirm Changes" button
  onConfirm(): void;
  /// Callback called on the "Cancel" button
  onCancel(): void;
}

/**
 * Footer for `<TaskTimeline />`.
 *
 * Renders information on the current and other steps, as well as action buttons
 * to cancel, change step, and confirm changes.
 */
export function Footer(props: Props) {
  const {
    step,
    setStep,
    onConfirm,
    onCancel,
    noDraft,
    disabled,
    dueDate,
    returnDate,
    draftDueDate,
  } = props;

  let actions: React.ReactElement | null;
  switch (step) {
    case Step.Final:
      actions = (
        <Button
          kind="primary"
          marginRight={27}
          onClick={() => setStep(Step.Return)}
          disabled={disabled}
          aria-disabled={disabled ? "true" : "false"}
        >
          Next, Set Return
        </Button>
      );
      break;
    case Step.Return:
      actions = (
        <Container>
          <Button marginRight={18} onClick={() => setStep(Step.Final)}>
            Back, Set Final
          </Button>
          {noDraft ? (
            <Button
              kind="primary"
              marginRight={27}
              disabled={disabled}
              aria-disabled={disabled ? "true" : "false"}
              onClick={onConfirm}
            >
              Confirm Dates
            </Button>
          ) : (
            <Button
              kind="primary"
              marginRight={27}
              onClick={() => setStep(Step.Draft)}
              disabled={disabled}
              aria-disabled={disabled ? "true" : "false"}
            >
              Next, Set Draft
            </Button>
          )}
        </Container>
      );
      break;
    case Step.Draft:
      actions = (
        <Container>
          <Button onClick={() => setStep(Step.Return)} marginRight={18}>
            Back, Set Return
          </Button>
          <Button
            kind="primary"
            marginRight={27}
            disabled={disabled}
            aria-disabled={disabled ? "true" : "false"}
            onClick={onConfirm}
          >
            Confirm Dates
          </Button>
        </Container>
      );
      break;
    default:
      actions = null;
      break;
  }

  return (
    <Container>
      <StyledLinkButton
        kind="solid"
        iconName={dueDate ? "Tick" : "Close"}
        marginRight={18}
        onClick={() => setStep(Step.Final)}
      >
        Final Due
      </StyledLinkButton>
      <StyledLinkButton
        kind="solid"
        iconName={returnDate ? "Tick" : "Close"}
        marginRight={18}
        onClick={() => setStep(Step.Return)}
      >
        Return Date
      </StyledLinkButton>
      {!noDraft && (
        <StyledLinkButton
          kind="solid"
          iconName={draftDueDate ? "Tick" : "Close"}
          marginRight={18}
          onClick={() => setStep(Step.Draft)}
        >
          Draft Due
        </StyledLinkButton>
      )}
      <Button marginRight={18} onClick={onCancel}>
        Cancel
      </Button>
      {actions}
    </Container>
  );
}

const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
`;
