import { ComponentProps } from "react";
import { styled } from "styled-components";

import { Anchored, colors } from "@vericus/cadmus-ui";

import { useJitsu } from "@jitsu/react";

import { JitsuEvent } from "@/data/events/JitsuEvent";

export interface TrackedAnchorProps extends ComponentProps<typeof Anchored> {
  /**
   * ID of the button to be tracked.
   */
  linkId: string;
}

export const TrackedAnchor = (props: TrackedAnchorProps) => {
  const { children, linkId, ...anchorProps } = props;
  const { track } = useJitsu();

  return (
    <StyledAnchor
      onClick={() => {
        track(JitsuEvent.LINK_CLICKED, {
          id: linkId,
        });
      }}
      {...anchorProps}
    >
      {children}
    </StyledAnchor>
  );
};

const StyledAnchor = styled(Anchored)`
  color: ${colors.lilac500};
`;
