import { useEffect } from "react";

import { matchRoutes, useLocation } from "react-router-dom";

/**
 * Adds the route's name to the <body>. Its value will be placed in the attribute "data-route"
 */
export const useExposedLocation = () => {
  const location = useLocation();

  // This returns the first match it sees, therefore the routes list needs to be in the right order,
  // where high fidelity comes first.
  const matches = matchRoutes(routes, location);
  const matchedRoute = routes.find(
    (route) => matches && route.path === matches[0]?.route?.path
  );

  useEffect(() => {
    if (!matchedRoute || !matchedRoute.name) {
      return;
    }

    document.body.setAttribute("data-route", matchedRoute.name);

    return () => {
      document.body.removeAttribute("data-route");
    };
  }, [location, matchedRoute]);
};

const baseAssessmentPath = "/:tenant/assessment/:assessmentId";
// This is forked from utils/routing. It's slightly different, particularly with the ordering,
// which is used for route matching
const routes = [
  { name: "editInstructions", path: `${baseAssessmentPath}/task/edit` },
  { name: "releaseInstructions", path: `${baseAssessmentPath}/task/release` },
  {
    name: "settingsInstructions",
    path: `${baseAssessmentPath}/task/settings`,
  },
  { name: "task", path: `${baseAssessmentPath}/task` },
  {
    name: "examSpecialConsiderationsEdit",
    path: `${baseAssessmentPath}/class/marking/examSpecialConsiderations/edit`,
  },
  {
    name: "examSpecialConsiderations",
    path: `${baseAssessmentPath}/class/marking/examSpecialConsiderations`,
  },
  { name: "progress", path: `${baseAssessmentPath}/class/progress` },
  {
    name: "groupsCanvasSync",
    path: `${baseAssessmentPath}/marking/groups/canvas-sync`,
  },
  { name: "groups", path: `${baseAssessmentPath}/class/marking/groups/*` },
  { name: "insights", path: `${baseAssessmentPath}/insights` },
];
