import { styled } from "styled-components";

import { LinkButton } from "@vericus/cadmus-ui";

import copy from "copy-to-clipboard";
import toast from "react-hot-toast";

/**
 * Copy student emails and open a contact confirmation toast.
 */
export function contactStudents(emails: string[]) {
  if (emails.length === 0) {
    return undefined;
  }

  copy(emails.join(";"));

  toast.success(
    (t) => <ContactToast emails={emails} onClose={() => toast.dismiss(t.id)} />,
    {
      duration: 3_000,
      id: "contact-students-toast",
    }
  );
}

interface ContactToastProps {
  /** Emails copied in the clipboard to contact */
  emails: string[];
  /** Callback to dismiss the modal */
  onClose?: () => void;
}

/**
 * Toast message contents confirming email addresses to contact.
 */
export const ContactToast = ({ emails, onClose }: ContactToastProps) => {
  const launchMailApp = () => {
    const newTarget =
      emails.length > 1
        ? `mailto:?bcc=${emails.join(";")}`
        : `mailto:${emails.join(";")}`;
    const newWindow = window.open(newTarget, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
    onClose?.();
  };

  return (
    <div>
      {emails.length > 1 ? `${emails.length} email addresses` : "Email address"}{" "}
      copied to clipboard{" "}
      <ContactLinkButton inline color="blueA500" onClick={launchMailApp}>
        Open mail
      </ContactLinkButton>
    </div>
  );
};

const ContactLinkButton = styled(LinkButton)`
  margin-left: 12px;
`;
