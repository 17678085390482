import { useEffect } from "react";

import { colors, levels, typography } from "@vericus/cadmus-ui";

import toast, { Toaster as HotToaster, useToasterStore } from "react-hot-toast";

const TOAST_LIMIT = 2;

/**
 * Customised react-hot-toast Toaster.
 */
export const Toaster = () => {
  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((tt) => tt.visible)
      .filter((_, i) => i >= TOAST_LIMIT)
      .forEach((tt) => toast.dismiss(tt.id));
  }, [toasts]);

  return (
    <HotToaster
      position="bottom-center"
      containerStyle={{
        marginTop: 56,
      }}
      toastOptions={{
        duration: 2_000,
        style: {
          padding: "8px 8px 8px 20px",
          fontFamily: typography.headingFive.fontFamily,
          fontWeight: typography.headingFive.fontWeight,
          fontSize: 16,
          lineHeight: typography.headingFive.lineHeight,
          background: colors.black200,
          color: colors.white100,
          boxShadow: levels.three,
          maxWidth: "initial",
          alignItems: "baseline",
          gap: 6,
          boxSizing: "border-box",
        },
        success: {
          iconTheme: {
            primary: "#00A854",
            secondary: colors.white100,
          },
        },
      }}
    />
  );
};
