import * as Sentry from "@sentry/react";
import isArray from "lodash/isArray";

import { contentfulClient } from "@/client/contentful";

import {
  Category,
  showAs,
  Subcategory,
  Template,
  templateFilters,
} from "./types";

export const loadContentfulTemplates = async (): Promise<Category[]> => {
  return fetchTemplates();
};

const fetchTemplates = async (): Promise<Category[]> => {
  const data = await contentfulClient.getEntries({
    content_type: "template-category",
    include: 2, // included levels of linked entries, e.g. subcategories and the templates within
    order: "fields.order",
  });

  const categories: Category[] = data.items
    .map((i) => {
      try {
        return parseContentfulTemplateCategory(i);
      } catch (err) {
        if (import.meta.env.DEV) {
          console.error(err);
        }

        Sentry.captureException(
          `Error parsing Contentful template categories`,
          {
            extra: {
              err,
            },
          }
        );
        return null;
      }
    })
    .filter((c): c is Category => c !== null);
  return categories;
};

type ExpectedType = "string" | "boolean" | "undefined";

type TypeFromTypeString<T> = T extends "string"
  ? string
  : T extends "boolean"
    ? boolean
    : never;

const propertyTypeCheck = <T extends ExpectedType>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  expectedType: T | T[],
  entityType: "Template" | "Subcategory" | "Category",
  entityTitle: string,
  fieldName: string
): TypeFromTypeString<T> => {
  if (isArray(expectedType)) {
    // Go through each possible type and check if it matches
    for (const entityTypeTest of expectedType) {
      if (typeof value === entityTypeTest) return value;
    }
  } else {
    // We just have the one type, check that
    if (typeof value === expectedType) return value;
  }

  throw TypeError(
    `${entityType} ${entityTitle} - field ${fieldName} - expected string but found ${typeof value}.`
  );
};

const literalEnumTypeCheck = <
  ArrayType extends readonly unknown[],
  ElementType = ArrayType extends readonly (infer R)[] ? R : never,
>(
  acceptableValues: ArrayType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  entityType: "Template" | "Subcategory",
  entityTitle: string,
  fieldName: string
): ElementType => {
  if (acceptableValues.includes(value)) return value as ElementType;
  throw TypeError(
    `${entityType} ${entityTitle} - field ${fieldName} - expected one of [${acceptableValues.join(
      ", "
    )}] but found ${typeof value}.`
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseContentfulTemplateCategory = (data: any): Category => {
  const categoryTitle: string = data.fields.title;

  return {
    id: propertyTypeCheck(
      data.sys.id,
      "string",
      "Category",
      categoryTitle,
      "id"
    ),
    title: propertyTypeCheck(
      data.fields.title,
      "string",
      "Category",
      categoryTitle,
      "title"
    ),
    previewDescription: propertyTypeCheck(
      data.fields.previewDescription,
      "string",
      "Category",
      categoryTitle,
      "previewDescription"
    ),
    hasFilters: propertyTypeCheck(
      data.fields.hasFilters,
      "boolean",
      "Category",
      categoryTitle,
      "hasFilters"
    ),
    subcategories: data.fields.subcategories.map(
      parseContentfulTemplateSubcategory
    ),
    helpLink: propertyTypeCheck(
      data.fields.helpLink,
      ["string", "undefined"],
      "Category",
      categoryTitle,
      "helpLink"
    ),
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseContentfulTemplateSubcategory = (data: any): Subcategory => {
  const subcategoryTitle: string = data.fields.title;
  return {
    id: propertyTypeCheck(
      data.sys.id,
      "string",
      "Subcategory",
      subcategoryTitle,
      "id"
    ),
    title: propertyTypeCheck(
      data.fields.title,
      "string",
      "Subcategory",
      subcategoryTitle,
      "title"
    ),
    heading: propertyTypeCheck(
      data.fields.heading,
      "string",
      "Subcategory",
      subcategoryTitle,
      "heading"
    ),
    description: propertyTypeCheck(
      data.fields.description,
      "string",
      "Subcategory",
      subcategoryTitle,
      "description"
    ),
    showAs: literalEnumTypeCheck(
      showAs,
      data.fields.showAs,
      "Subcategory",
      subcategoryTitle,
      "showAs"
    ),
    slug: propertyTypeCheck(
      data.fields.slug,
      "string",
      "Subcategory",
      subcategoryTitle,
      "slug"
    ),
    templates: data.fields.templates.map(parseContentfulTemplate),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    discipline: data.fields.discipline?.map((eachDiscipline: any) => {
      return propertyTypeCheck(
        eachDiscipline,
        "string",
        "Subcategory",
        subcategoryTitle,
        "discipline"
      );
    }),
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseContentfulTemplate = (data: any): Template => {
  const templateTitle: string = data.fields.title;

  return {
    id: propertyTypeCheck(
      data.sys.id,
      "string",
      "Template",
      templateTitle,
      "id"
    ),
    color: propertyTypeCheck(
      data.fields.color,
      "string",
      "Template",
      templateTitle,
      "color"
    ),
    content: propertyTypeCheck(
      data.fields.copyHtml,
      "string",
      "Template",
      templateTitle,
      "content"
    ),
    description: propertyTypeCheck(
      data.fields.description,
      "string",
      "Template",
      templateTitle,
      "description"
    ),
    matchesFilters:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.fields.matchesFilters?.map((filter: any) =>
        literalEnumTypeCheck(
          templateFilters,
          filter,
          "Template",
          templateTitle,
          "matchesFilters"
        )
      ) || [],
    iconAlt: data.fields.icon.fields.description || undefined,
    iconSource: propertyTypeCheck(
      `https:${data.fields.icon.fields.file.url}`,
      "string",
      "Template",
      templateTitle,
      "iconSource"
    ),
    references: data.fields.references || undefined,
    title: propertyTypeCheck(
      data.fields.title,
      "string",
      "Template",
      templateTitle,
      "title"
    ),
  };
};
