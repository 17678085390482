import { useMemo } from "react";

import { Row } from "@tanstack/react-table";

import {
  progressFilters,
  studentRiskFilters,
} from "@/ui/StudentLists/ClassListFilters/components";
import { getMatchingRows } from "@/ui/StudentLists/filters";

import { StudentListRow } from "../types";

export interface ProgressStats {
  /**
   * Total number of students enrolled
   */
  enrolled: number;
  viewedOnly: number;
  /**
   * Total number of students who have started, and not submitted, the assessment
   */
  started: number;

  /**
   * Total number of students who have submitted a draft
   */
  drafts: number;

  /**
   * Total number of students who have accessed(?) their feedback
   */
  feedback: number;

  /**
   * Total number of students who have submitted a final
   */
  finals: number;

  /**
   * Total number of students who have had their work graded
   */
  graded: number;
  /**
   * Total number of students who have had an extension applied.
   * Applicable to assignments.
   */
  extensions: number;
  /**
   * Total number of students who have had a special consideration applied.
   * Applicable to exams.
   */
  specialConsiderations: number;
  /**
   * Total number of students who have not accessed the assessment.
   */
  notAccessed: number;
}

/**
 * Pre-filter the rows to find out how many students would match
 * the banner filters if they each were applied.
 */
export const usePreFilterTableData = (
  rows: Row<StudentListRow>[],
  hasDraftsOn: boolean
) => useMemo(() => preFilterTableData(rows, hasDraftsOn), [rows, hasDraftsOn]);

/**
 * Pre-filter the rows to find out how many students would match
 * the banner filters if they each were applied.
 */
function preFilterTableData(
  rows: Row<StudentListRow>[],
  hasDraftsOn: boolean
): ProgressStats {
  const enrolled = rows.filter(
    ({ original: { deferred, withdrawn } }) => !(deferred || withdrawn)
  ).length;

  // progress filters in the UI use flags column
  // student risk filters in the UI use progress column
  const stats: ProgressStats = {
    viewedOnly: getMatchingRows(rows, "flags", [
      progressFilters["viewed-only"](hasDraftsOn),
    ]).length,
    started: getMatchingRows(rows, "flags", [
      progressFilters["in-progress"](hasDraftsOn),
    ]).length,
    drafts: getMatchingRows(rows, "flags", [
      progressFilters["submitted-draft"](hasDraftsOn),
    ]).length,
    finals: getMatchingRows(rows, "flags", [
      progressFilters["submitted-final"](hasDraftsOn),
    ]).length,
    graded: getMatchingRows(rows, "flags", [
      progressFilters["graded"](hasDraftsOn),
    ]).length,
    feedback: getMatchingRows(rows, "flags", [
      progressFilters["feedback-viewed"](hasDraftsOn),
    ]).length,
    extensions: getMatchingRows(rows, "flags", [
      progressFilters["has-extension"](hasDraftsOn),
      progressFilters["has-extra-time"](hasDraftsOn),
    ]).length,
    specialConsiderations: getMatchingRows(rows, "flags", [
      progressFilters["has-alternative-start"](hasDraftsOn),
      progressFilters["has-alternative-window"](hasDraftsOn),
      progressFilters["has-extra-time"](hasDraftsOn),
    ]).length,
    notAccessed: getMatchingRows(rows, "progress", [
      studentRiskFilters["not-accessed"](hasDraftsOn),
    ]).length,
    enrolled,
  };
  return stats;
}
