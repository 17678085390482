import { isBefore } from "date-fns";

import {
  EnrollmentFragment,
  FeedbackReleaseBasePool,
  FeedbackReleaseRuleFragment,
  FeedbackReleaseRuleInput,
} from "@/generated/graphql";

import { FeedbackReleaseRuleState, LocalFeedbackReleaseRule } from "../types";
import { getEnrollmentHasTagIncluded } from "./get-enrollment-has-tag-included";

/**
 * Predicate to check if feedback has been released from any rules.
 *
 * @param rules rules to check
 * @returns boolean
 */
export function getFeedbackHasReleased(rules: FeedbackReleaseRuleFragment[]) {
  return rules.some((rule) => rule.executedAt !== null);
}

/**
 * Categorises enrollment ids into received feedback,
 * schedule to have feedback, not included in any feedback release plan groups
 *
 * @param includeReleaseNowToReleased whether include enrollments of releaseNow
 * rule and schedule rules whose schedule time is before current time into
 * release enrollments
 *
 * @returns Object
 * remainingEnrollments -  enrollments who haven't received or scheduled to receive feedback
 * releasedEnrollments - enrollments who have received feedback
 * scheduledEnrollments - enrollments who are scheduled to receive feedback
 */
export function getEnrollmentIdsReleaseState(
  feedbackReleaseRuleStates: FeedbackReleaseRuleState[],
  includeReleaseNowToReleased?: boolean
) {
  let releasedEnrollmentIds: string[] = [];
  let scheduledEnrollmentIds: string[] = [];
  let remainingEnrollmentIds: string[] = [];

  if (feedbackReleaseRuleStates.length === 0) {
    return {
      releasedEnrollmentIds,
      scheduledEnrollmentIds,
      remainingEnrollmentIds,
    };
  }

  // Current remaining enrollment ids
  const lastFeedbackReleaseState =
    feedbackReleaseRuleStates[feedbackReleaseRuleStates.length - 1];
  if (lastFeedbackReleaseState) {
    remainingEnrollmentIds =
      lastFeedbackReleaseState.availableEnrollmentIds.filter(
        (availableEnrollmentId) =>
          !lastFeedbackReleaseState.appliedEnrollmentIds.includes(
            availableEnrollmentId
          )
      );
  }

  for (const feedbackReleaseRuleState of feedbackReleaseRuleStates) {
    let released = feedbackReleaseRuleState.executedAt !== null;

    if (includeReleaseNowToReleased) {
      // include enrollments in "release now"
      // rules and "schedule release" rule whose schedule time is before current time
      // into released enrollments.
      let releaseAtBeforeCurrentTime = false;
      if (feedbackReleaseRuleState.scheduledAt)
        releaseAtBeforeCurrentTime = isBefore(
          new Date(feedbackReleaseRuleState.scheduledAt),
          new Date()
        );

      released =
        feedbackReleaseRuleState.executedAt !== null ||
        feedbackReleaseRuleState.scheduledAt === null ||
        releaseAtBeforeCurrentTime;
    }

    if (released)
      releasedEnrollmentIds = [
        ...releasedEnrollmentIds,
        ...feedbackReleaseRuleState.appliedEnrollmentIds,
      ];
    else
      scheduledEnrollmentIds = [
        ...scheduledEnrollmentIds,
        ...feedbackReleaseRuleState.appliedEnrollmentIds,
      ];
  }

  return {
    scheduledEnrollmentIds,
    releasedEnrollmentIds,
    remainingEnrollmentIds,
  };
}

/** Convert feedback release rule states to  InputFeedbackReleaseRule*/
export const getInputFeedbackReleaseRules = (
  feedbackReleaseRuleStates: FeedbackReleaseRuleState[]
): FeedbackReleaseRuleInput[] => {
  return feedbackReleaseRuleStates.map((feedbackReleaseRuleState) => ({
    basePool: feedbackReleaseRuleState.basePool,
    excludedStudentIds: feedbackReleaseRuleState.excludedStudentIds,
    excludedTags: feedbackReleaseRuleState.excludedTags,
    // If this is a new rule, id need to be null to inform server that
    // it is a new rule
    id: feedbackReleaseRuleState.isNew ? null : feedbackReleaseRuleState.id,
    includedStudentIds: feedbackReleaseRuleState.includedStudentIds,
    includedTags: [],
    scheduledAt: feedbackReleaseRuleState.scheduledAt,
  }));
};

/**
 * Given feedback release rules and enrollments, return feedback release
 * states that are used in redux mark slice. Feedback release state will have two extra
 * fields availableEnrollmentIds and appliedEnrollmentIds, availableEnrollmentIds is the ids of
 * enrollments that can be include in rule. In the other words,
 * it is the exclusion of the previous rules. AppliedEnrollmentIds
 * stands for ids of enrollments that are included in rule.
 */
export const getFeedbackReleaseRuleStates = (
  feedbackReleaseRules: (
    | (FeedbackReleaseRuleFragment & { isNew?: boolean })
    | LocalFeedbackReleaseRule
  )[],
  enrollments: EnrollmentFragment[]
) => {
  const feedbackReleaseStates: FeedbackReleaseRuleState[] = [];
  let availableEnrollments = enrollments;

  for (const feedbackReleaseRule of feedbackReleaseRules) {
    const excludedTags =
      feedbackReleaseRule.excludedTags?.flatMap(
        (excludedTag) => excludedTag ?? []
      ) ?? [];
    const excludedStudentIds =
      feedbackReleaseRule.excludedStudentIds?.flatMap(
        (excludedStudentId) => excludedStudentId ?? []
      ) ?? [];
    const includedStudentIds =
      feedbackReleaseRule.includedStudentIds?.flatMap(
        (includedStudentId) => includedStudentId ?? []
      ) ?? [];
    const workOutcomeWithFeedbackStudentIds =
      feedbackReleaseRule.executedStudentIds ?? [];

    const appliedEnrollmentIds = getIncludedEnrollments(
      feedbackReleaseRule.basePool,
      availableEnrollments,
      excludedTags,
      excludedStudentIds,
      includedStudentIds,
      feedbackReleaseRule.executedAt,
      workOutcomeWithFeedbackStudentIds
    ).map((enrollment) => enrollment.id);

    feedbackReleaseStates.push({
      ...feedbackReleaseRule,
      appliedEnrollmentIds,
      // Store the ids of enrollments that can be included in this
      // feedback release rule
      availableEnrollmentIds: availableEnrollments.map(
        (availableEnrollment) => availableEnrollment.id
      ),
      isNew: feedbackReleaseRule.isNew ?? false,
    });

    // Update available enrollments for next rule
    availableEnrollments = getExcludedEnrollments(
      feedbackReleaseRule.basePool,
      availableEnrollments,
      excludedTags,
      excludedStudentIds,
      includedStudentIds,
      feedbackReleaseRule.executedAt,
      workOutcomeWithFeedbackStudentIds
    );
  }

  return feedbackReleaseStates;
};

/**
 * Given enrollments, return excluded enrollments based on excluded tags,
 * excluded student ids. included student ids, feedbackReleaseRuleStatus,
 * workOutcomeWithFeedbackStudentIds. If feedback is released, excluded enrollments
 * would the one whose work outcome doesn't have feedback. Otherwise excluded enrollments
 * would be
 * `set(students who have excluded tags, students who are excluded by id) - students who are included by id`
 */
export const getExcludedEnrollments = (
  basePool: FeedbackReleaseBasePool,
  enrollments: EnrollmentFragment[],
  excludedTags: string[] | readonly string[],
  excludedStudentIds: string[] | readonly string[],
  includedStudentIds: string[] | readonly string[],
  executedAt: string | null,
  workOutcomeWithFeedbackStudentIds: string[]
) => {
  // Released feedback
  if (executedAt !== null) {
    return enrollments.filter(
      (enrollment) =>
        !workOutcomeWithFeedbackStudentIds.includes(enrollment.user.id)
    );
  }
  // Release feedback to included students
  if (basePool === FeedbackReleaseBasePool.Empty) {
    return enrollments.filter(
      (enrollment) => !includedStudentIds.includes(enrollment.user.id)
    );
  }
  // Release feedback to students that are not excluded
  return enrollments.filter((enrollment) =>
    enrollmentIsExcluded(
      enrollment,
      excludedTags,
      excludedStudentIds,
      includedStudentIds
    )
  );
};

/**
 * Given enrollments, return included enrollments based on excluded tags,
 * excluded student ids. included student ids, feedbackReleaseRuleStatus,
 * workOutcomeWithFeedbackStudentIds. If feedback is released, included enrollments
 * would be the one whose work outcome has feedback. Otherwise included enrollments
 * would be
 * `enrollments - set(students who have excluded tags, students who are excluded by id) + students who are included by id`
 */
export const getIncludedEnrollments = (
  basePool: FeedbackReleaseBasePool,
  enrollments: EnrollmentFragment[],
  excludedTags: string[] | readonly string[],
  excludedStudentIds: string[] | readonly string[],
  includedStudentIds: string[] | readonly string[],
  executedAt: string | null,
  workOutcomeWithFeedbackStudentIds: string[]
) => {
  // Released feedback
  if (executedAt !== null) {
    return enrollments.filter((enrollment) =>
      workOutcomeWithFeedbackStudentIds.includes(enrollment.user.id)
    );
  }
  // Release feedback to included students
  if (basePool === FeedbackReleaseBasePool.Empty) {
    return enrollments.filter((enrollment) =>
      includedStudentIds.includes(enrollment.user.id)
    );
  }
  // Release feedback to students that are not excluded
  return enrollments.filter(
    (enrollment) =>
      !enrollmentIsExcluded(
        enrollment,
        excludedTags,
        excludedStudentIds,
        includedStudentIds
      )
  );
};

/**
 * Given enrollment, excluded tags, ids of excluded student,
 * included student ids, return whether enrollment is excluded
 */
export function enrollmentIsExcluded(
  enrollment: EnrollmentFragment,
  excludedTags: string[] | readonly string[],
  excludedStudentIds: string[] | readonly string[],
  includedStudentIds: string[] | readonly string[]
) {
  return (
    (excludedStudentIds.includes(enrollment.user.id) ||
      getEnrollmentHasTagIncluded(enrollment, excludedTags)) &&
    !includedStudentIds.includes(enrollment.user.id)
  );
}
