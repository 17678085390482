import { useEffect } from "react";

import { useJitsu } from "@jitsu/react";
import { useCurrentUser } from "data/user/useCurrentUser";

const jitsuIdentified = false;

export function useJitsuIdentify(assessmentId: string) {
  const { id, set } = useJitsu();
  const user = useCurrentUser();

  useEffect(() => {
    if (!jitsuIdentified && user) {
      set({
        cadmus: {
          tenant: user.institution.tenant,
          assessment_id: assessmentId,
          session_id: user.sessionId,
        },
      });
      // ID the user but do not send the event to the server
      id({ id: user.me.id, role: "lecturer" }, true);
    }
  }, [user, assessmentId, id, set]);
}
