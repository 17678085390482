import { useCallback } from "react";

import { CellContext } from "@tanstack/react-table";

import { useModerationScoreContext } from "@/features/moderation";
import { WorkOutcomeSummary } from "@/features/moderation/types";
import { useBaseCadmusPath } from "@/router/routing";

import { StudentListRow } from "../../class/progress/types";
import * as Grid from "../InfoGrid";
import { ModeratedPointsInput } from "./moderated-points-input";

/**
 * React table cell renderer for displaying the moderation grade.
 */
export function ModerationGradeCell(
  props: CellContext<StudentListRow, WorkOutcomeSummary | undefined>
) {
  const outcome = props.getValue();
  const { onUpdateWorkOutcomeScore } = useModerationScoreContext();
  const basePath = useBaseCadmusPath();

  const workOutcomeId = outcome?.id;
  const score =
    typeof outcome?.finalScore === "number"
      ? outcome.finalScore
      : outcome?.score ?? 0;
  const maxScore = outcome?.maxScore ?? 0;
  const readOnly = outcome?.feedbackReleasedTimestamp ? true : false;

  const handleUpdateWorkOutcomeScore = (newScore: number) => {
    if (workOutcomeId) {
      onUpdateWorkOutcomeScore?.(workOutcomeId, newScore);
    }
  };

  const handleReset = () => {
    const currentScore = outcome?.score ?? 0;
    const moderatorChanges = outcome?.moderatorModifier ?? 0;
    const newScore = currentScore - moderatorChanges;

    if (workOutcomeId) {
      onUpdateWorkOutcomeScore?.(workOutcomeId, newScore);
    }
  };

  const redirectToMarkingTool = useCallback(() => {
    if (outcome?.workId) {
      window.open(`${basePath}/grader/mark/${outcome.workId}`, "_blank");
    }
  }, [outcome, basePath]);

  return (
    <Grid.MarkedScoreCol>
      <ModeratedPointsInput
        maxScore={maxScore}
        handleUpdateWorkOutcomeScore={handleUpdateWorkOutcomeScore}
        score={score}
        onReset={handleReset}
        redirectToMarkingTool={redirectToMarkingTool}
        disableScoreUpdate={readOnly}
      />
    </Grid.MarkedScoreCol>
  );
}
