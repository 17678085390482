import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "data/hooks";

import { clearError, ErrorState, notifyError } from "./errorSlice";
import { getError } from "./selectors";
import { ErrorKind } from "./types";

// Types
export { ErrorKind };

/** Access the current error from the redux state, if any. */
export function useError(): ErrorState | null {
  return useAppSelector(getError);
}

/** Create a callback to dispatch the given kind of errors. */
export function useErrorCallback(kind: ErrorKind) {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(notifyError(kind)), [dispatch, kind]);
}

/**
 * Create a callback to clear all current errors with an optional
 * continuation.
 */
export function useClearErrorCallback(callback?: () => void) {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    dispatch(clearError());
    callback && callback();
  }, [dispatch, callback]);
}
