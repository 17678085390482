import { useMemo } from "react";
import { styled } from "styled-components";

import {
  CadmusEditorContent,
  useCadmusEditor,
} from "@vericus/cadmus-editor-prosemirror";

import * as Sentry from "@sentry/react";

import { deserialiseTaskSaveContent, useTaskEditor } from "@/data/task";

/**
 * Component rendering the main Task editor with editable contents.
 */
export function TaskEditorContent() {
  // Connect to the Task editor
  const editor = useTaskEditor();

  return (
    <Sentry.ErrorBoundary>
      <CadmusEditorContent editor={editor} />
    </Sentry.ErrorBoundary>
  );
}

interface ReadOnlyProps {
  content: string | null;
  /** Whether the supplied content is passed in as HTML. */
  isHtmlContent?: boolean;
}

const InstructionContent = styled(CadmusEditorContent)`
  &:after {
    display: none;
  }
`;

/**
 * Readonly editor which renders the given `props.content`.
 */
export function TaskEditorContentReadOnly({
  content,
  isHtmlContent,
}: ReadOnlyProps) {
  const editorContent = useMemo(() => {
    if (!content) return "";
    if (isHtmlContent) return content;
    return deserialiseTaskSaveContent(content);
  }, [content, isHtmlContent]);

  const editor = useCadmusEditor(
    {
      editorId: "task-readonly",
      editorA11yLabel: "Assessment task (read-only)",
      content: editorContent,
      editable: false,
    },
    [content]
  );

  return <InstructionContent editor={editor} />;
}
