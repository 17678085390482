import { styled } from "styled-components";

import { DropdownMenu, Icon, Text } from "@vericus/cadmus-ui";

import { QuestionType } from "@/generated/graphql";

import { QUESTION_CONFIG } from "../../components/question-type-dropdown-menu";

const { [QuestionType.Sub]: _subQuestion, ...SUB_QUESTION_CONFIG } =
  QUESTION_CONFIG;

interface Props {
  /** Add a new empty sub question */
  onAddChildQuestion: (questionType: QuestionType) => void;
}

/**
 *  Action button to create empty sub question
 */
export const AddSubQuestionButton = ({ onAddChildQuestion }: Props) => (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild>
      <TriggerButton>
        <Icon iconName="Plus" />
        <Text kind="systemSm" color="navy500" bold>
          add sub-question
        </Text>
      </TriggerButton>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content>
      {Object.values(SUB_QUESTION_CONFIG).map((option) => (
        <DropdownMenu.Item
          key={option.text}
          content={option.text}
          leftSection={option.icon}
          onSelect={() => {
            onAddChildQuestion(option.questionType);
          }}
        />
      ))}
    </DropdownMenu.Content>
  </DropdownMenu.Root>
);

const TriggerButton = styled.button`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  padding: 8px 20px 6px 20px;
  border-radius: 3px;
  background: ${(p) => p.theme.colors.grey50};
  border: none;
  cursor: pointer;
`;
