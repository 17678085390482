import { add, isBefore } from "date-fns";

export enum FEEDBACK_SCHEDULE_INPUT_ERROR {
  MISSING,
  SCHEDULE_BEFORE_CURRENT_TIME,
}

export const validateFeedbackScheduleTime = (
  scheduleDate?: Date,
  scheduleTime?: Duration
) => {
  if (!scheduleDate || !scheduleTime) {
    return {
      scheduleDate: scheduleDate ? null : FEEDBACK_SCHEDULE_INPUT_ERROR.MISSING,
      scheduleTime: scheduleTime ? null : FEEDBACK_SCHEDULE_INPUT_ERROR.MISSING,
    };
  }

  const releasedTime = add(scheduleDate, scheduleTime);
  if (isBefore(releasedTime, new Date())) {
    return {
      scheduleDate: FEEDBACK_SCHEDULE_INPUT_ERROR.SCHEDULE_BEFORE_CURRENT_TIME,
      scheduleTime: FEEDBACK_SCHEDULE_INPUT_ERROR.SCHEDULE_BEFORE_CURRENT_TIME,
    };
  }

  return {
    scheduleDate: null,
    scheduleTime: null,
  };
};
