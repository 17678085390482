import { ComponentProps, ReactNode } from "react";

import { colors, Pill, Spacer, Text, Tooltip } from "@vericus/cadmus-ui";

import { intervalToDuration } from "date-fns";
import {
  COMPACT_DATE_WITH_YEAR_FORMAT,
  formatDate,
  formattedDuration,
} from "utils/datetime";

import { EnrollmentSettings } from "@/graphql/types/EnrollmentSettings";

export interface Props extends ComponentProps<typeof Pill> {
  /**
   * User name to display.
   */
  userName: string;
  /**
   * Enrollment settings
   */
  settings: EnrollmentSettings;
}

const messages = {
  "extension.due": (props: {
    name: string;
    extension: string;
    until: string;
  }) => (
    <>
      {props.name} has a <strong>{props.extension}</strong> extension until{" "}
      {props.until}.
    </>
  ),
  "extension.time": (props: {
    name: string;
    extension: string;
    total: string;
  }) => (
    <>
      {props.name} has <strong>{props.extension}</strong> of extra time. The
      total available time is {props.total}.
    </>
  ),
  "extension.all": (props: {
    name: string;
    dueExtension: string;
    dueUntil: string;
    timeExtension: string;
    timeTotal: string;
  }) => (
    <>
      {props.name} has a <strong>{props.dueExtension}</strong> extension and{" "}
      <strong>{props.timeExtension}</strong> of extra time.
      <Spacer spacing={8} as="span" />
      They can work for {props.timeTotal} until {props.dueUntil}.
    </>
  ),
};

/**
 * Pill showing the Extension "tag" with extra information on the date and time
 * extensions in a Tooltip.
 */
export const ExtensionPill = (props: Props) => {
  const { userName, settings, ...pillProps } = props;
  const { sheetFinalDue, finalExtension, sheetTimeLimit, timeExtension } =
    settings;

  let tooltipMessage: ReactNode | undefined;

  // Due date AND time limit extension message
  if (
    sheetFinalDue !== null &&
    finalExtension !== null &&
    sheetTimeLimit !== null &&
    timeExtension !== null
  ) {
    tooltipMessage = messages["extension.all"]({
      dueExtension: formattedDuration({
        duration: intervalToDuration({
          start: sheetFinalDue,
          end: finalExtension,
        }),
      }),
      dueUntil: formatDate(finalExtension, COMPACT_DATE_WITH_YEAR_FORMAT),
      name: userName,
      timeExtension: formattedDuration({
        duration: { minutes: timeExtension - sheetTimeLimit },
        pluralise: true,
      }),
      timeTotal: formattedDuration({
        duration: { minutes: timeExtension },
        pluralise: true,
      }),
    });
  } else if (sheetFinalDue !== null && finalExtension !== null) {
    // Due Date extension message only message
    tooltipMessage = messages["extension.due"]({
      extension: formattedDuration({
        duration: intervalToDuration({
          start: sheetFinalDue,
          end: finalExtension,
        }),
      }),
      until: formatDate(finalExtension, COMPACT_DATE_WITH_YEAR_FORMAT),
      name: userName,
    });
  }
  // Time limit extension only message
  else if (sheetTimeLimit !== null && timeExtension !== null) {
    tooltipMessage = messages["extension.time"]({
      extension: formattedDuration({
        duration: { minutes: timeExtension - sheetTimeLimit },
        pluralise: true,
      }),
      total: formattedDuration({
        duration: { minutes: timeExtension },
        pluralise: true,
      }),
      name: userName,
    });
  } else {
    return null;
  }

  const tooltip = <Text kind="bodySm">{tooltipMessage}</Text>;

  return (
    <Tooltip content={tooltip}>
      <Pill {...pillProps} color={colors.white200}>
        Extension
      </Pill>
    </Tooltip>
  );
};
