import { Text, Tooltip } from "@vericus/cadmus-ui";

import { BigPill } from "@/ui/shared/BigPill";

/**
 * Tooltip message for withdrawn enrollments.
 */
export const WithdrawnMessage = ({
  withdrawnBy,
}: {
  withdrawnBy?: string | null;
}) => {
  let tooltip = (
    <Text kind="bodySm">
      Withdrawn students are listed in the class list, but excluded from class
      progress metrics and insights.
    </Text>
  );
  if (withdrawnBy) {
    tooltip = <Text kind="bodySm">Manually withdrawn by {withdrawnBy}</Text>;
  }
  return (
    <Tooltip content={tooltip}>
      <BigPill backgroundColor="grey50" textColor="grey600">
        <Text kind="systemMd">withdrawn</Text>
      </BigPill>
    </Tooltip>
  );
};
