import { ComponentProps, forwardRef } from "react";
import { styled } from "styled-components";

import { Pill } from "@vericus/cadmus-ui";

interface EnrollmentTagPillProps
  extends Pick<
    ComponentProps<typeof Pill>,
    "color" | "basic" | "onClick" | "onDelete"
  > {
  /**
   * The name of the enrollment tag to display
   */
  tagName: string;
}

/**
 * A smarter pill component to display the name of an enrollment tag.
 * This pill has an approximate maximum character of 25 before ellipsis start showing.
 */
export const EnrollmentTagPill = forwardRef<
  HTMLButtonElement,
  EnrollmentTagPillProps
>((props: EnrollmentTagPillProps, ref) => {
  const { tagName, ...pillProps } = props;

  return (
    <StyledPill ref={ref} title={props.tagName} {...pillProps}>
      <StyledSpan>{tagName}</StyledSpan>
    </StyledPill>
  );
});

const StyledPill = styled(Pill)`
  // Showing about maximum 25 characters (at font-size: 13px)
  // before ellipsis start showing
  max-width: 230px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
`;

const StyledSpan = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
`;
