import {
  COMPACT_TIME_FORMAT,
  formatFullSentence,
  hrAndMinDuration,
} from "utils/datetime";

import { ActionableRequirementRow, RequirementRow } from "./shared";

enum ViewLevel {
  Never = 0, // least flexible
  After = 1, // middle ground
  Before = 2, // most flexible, allowed anytime
}

/** Feedback return date requirement. */
export function ReturnDate(props: {
  returnDate: string;
  hasChanged?: boolean;
}) {
  return (
    <RequirementRow
      title="Return date"
      text={`Grades and feedback will be returned to students on ${formatFullSentence(
        new Date(props.returnDate)
      )}`}
      hasChanged={props.hasChanged}
    />
  );
}

/** Final due date requirement. */
export function DueDate(props: {
  dueDate: string | null;
  timeLimit: number | null;
  hasChanged?: boolean;
  setDate?: VoidFunction;
}) {
  const { dueDate, timeLimit, hasChanged, setDate } = props;
  if (!dueDate)
    return (
      <ActionableRequirementRow
        title="Final Due"
        text={"Select date"}
        hasChanged={hasChanged}
        onAction={setDate}
      />
    );

  const formattedDueDate = formatFullSentence(new Date(dueDate));
  const text =
    timeLimit === null
      ? `Your final is due on ${formattedDueDate}`
      : `This assessment has a time limit of ${hrAndMinDuration(
          timeLimit
        )}. You should complete it before ${formattedDueDate}`;
  return (
    <RequirementRow title="Final Due" text={text} hasChanged={hasChanged} />
  );
}

/** Draft due date requirement. */
export function DraftDueDate(props: {
  draftDueDate: string;
  hasChanged?: boolean;
}) {
  return (
    <RequirementRow
      title="Draft Due"
      text={`You may submit a draft before ${formatFullSentence(
        new Date(props.draftDueDate)
      )}`}
      hasChanged={props.hasChanged}
    />
  );
}

/**
 * Combined display of the draft and final similarity report view levels.
 *
 * The expected `value` prop is of the form `[draftSViewReports, sViewReports]`.
 */
export function Similarity(props: {
  sViewReports: ViewLevel;
  draftSViewReports: ViewLevel;
  noDraft: boolean;
  hasChanged?: boolean;
}) {
  let finalTxt: string;
  switch (props.sViewReports) {
    case ViewLevel.Never:
      finalTxt = "Students cannot view similarity for their finals.";
      break;
    case ViewLevel.After:
      finalTxt =
        "Students can view similarity for their finals after the final due date.";
      break;
    case ViewLevel.Before:
      finalTxt =
        "Students can view similarity for their finals before the final due date.";
      break;
  }

  let draftTxt = "";

  if (!props.noDraft) {
    switch (props.draftSViewReports) {
      case ViewLevel.Never:
        draftTxt = "They cannot view similarity for their drafts.";
        break;
      case ViewLevel.After:
        draftTxt =
          "They can view similarity for their drafts after the draft due date.";
        break;
      case ViewLevel.Before:
        draftTxt =
          "They can view similarity for their drafts before the draft due date.";
        break;
    }
  }

  return (
    <RequirementRow
      title="Similarity"
      text={`${finalTxt} ${draftTxt}`}
      hasChanged={props.hasChanged}
    />
  );
}

/** Display the time limit requirement. */
export function TimeLimit(props: { timeLimit: number; hasChanged?: boolean }) {
  return (
    <RequirementRow
      title="Time limit"
      text={`Students have ${hrAndMinDuration(
        props.timeLimit,
        COMPACT_TIME_FORMAT
      )} to complete the assessment`}
      hasChanged={props.hasChanged}
    />
  );
}
/** Display the allow late resubmissions setting. */
export function AllowLateResubmission(props: { hasChanged?: boolean }) {
  return (
    <RequirementRow
      title="Late resubmissions"
      text="Students can make multiple late resubmissions after the final due date"
      hasChanged={props.hasChanged}
    />
  );
}
