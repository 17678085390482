import { styled } from "styled-components";

import {
  Anchored,
  Button,
  Grid,
  LinkButton,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import UnauthorisedImg from "assets/errors/unauthorised.png";

export function UnauthorizedError() {
  return (
    <Grid>
      <GridImg
        src={UnauthorisedImg}
        alt="An illustration of a woman reading a map"
      />
      <GridContent>
        <Text kind="displayTwo">
          {"You're"} not logged into this Cadmus Assessment
        </Text>
        <Text kind="lead">
          Go to your Learning Management System (Blackboard, Canvas, Moodle or
          D2L) and click the Cadmus Assessment link. If that {"doesn't"} work,
          you may be using an unsupported browser. Try switching to{" "}
          <Anchored
            newTab
            href="https://www.google.com/chrome/browser/desktop/index.html"
          >
            <LinkButton inline>Chrome</LinkButton>
          </Anchored>
        </Text>
        <Spacer spacing={27} />
        <Anchored newTab href="https://support.cadmus.io/kb-tickets/new">
          <Button kind="secondary">Contact us for help</Button>
        </Anchored>
        <Spacer spacing={72} />
      </GridContent>
    </Grid>
  );
}

const GridImg = styled.img`
  grid-column: 3 / span 5;
  width: 100%;
  margin-top: 72px;

  @media screen and (max-width: 900px) {
    grid-column: 2 / span 7;
  }

  @media screen and (max-width: 630px) {
    grid-column: 1 / span 9;
  }
`;

const GridContent = styled.div`
  grid-column: 3 / span 5;
  text-align: center;
  margin-top: 18px;

  @media screen and (max-width: 900px) {
    grid-column: 1 / span 9;
  }
`;
