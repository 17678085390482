import { useCallback } from "react";

import toast from "react-hot-toast";

import { updateGroupMemberships } from "@/data/class";
import { AppDispatch } from "@/data/store";
import { EnrollmentFragment, GroupFragment } from "@/generated/graphql";

/**
 * Returns callback to update group memberships for enrollments in bulk
 */
export function useGroupMembershipHandler(
  assessmentId: string,
  groups: GroupFragment[],
  dispatch: AppDispatch
) {
  const onUpdateGroupMemberships = useCallback(
    (enrollments: EnrollmentFragment[], groupName: string) => {
      const maybeGroup = groups.find((g) => g.name === groupName);
      const promise = dispatch(
        updateGroupMemberships({
          assessmentId,
          group: maybeGroup
            ? { kind: "existing", id: maybeGroup.id }
            : { kind: "new", name: groupName },
          studentIds: enrollments.map((e) => e.user.id),
        })
      );

      promise.unwrap().then(() => {
        handleGroupMembershipNotification(enrollments, groupName);
      });
    },
    [assessmentId, dispatch, groups]
  );

  return { onUpdateGroupMemberships };
}

function handleGroupMembershipNotification(
  enrollments: EnrollmentFragment[],
  groupName: string
) {
  if (enrollments.length === 1) {
    const name = enrollments[0]!.user.givenName || enrollments[0]!.user.name;
    toast.success(`${name} has been added to marking group (${groupName})`);
  } else {
    const numberOfStudents = enrollments.length;
    toast.success(
      `${numberOfStudents} students have been added to marking group (${groupName})`
    );
  }
}
