import {
  Content,
  SlimKit,
  useEditor,
} from "@vericus/cadmus-editor-prosemirror";

import { debounce } from "ts-debounce";

interface Props {
  editorId: string;
  feedbackSectionId: string;
  content?: string;
  onFeedbackChange?: (feedbackSectionId: string, value: string) => void;
  editable?: boolean;
}

export const useFeedbackEditor = (props: Props) => {
  return useEditor({
    content: deserialise(props.content ?? null),
    extensions: [
      SlimKit.configure({
        editorId: props.editorId,
        editorA11yLabel: "Question feedback editor",
        contentPlaceholder: "Write feedback...",
      }),
    ],
    onUpdate: ({ editor }) => {
      const content = JSON.stringify(editor.getJSON());
      debouncedOnChange(
        content,
        props.feedbackSectionId,
        props.onFeedbackChange
      );
    },
    editable: props.editable,
  });
};

const debouncedOnChange = debounce(
  (
    content: string,
    feedbackSectionId: string,
    callback?: (feedbackSectionId: string, content: string) => void
  ) => {
    callback?.(feedbackSectionId, content);
  },
  350
);

function deserialise(content: string | null): Content {
  if (!content) return null;
  try {
    return JSON.parse(content);
  } catch (e) {
    console.error("Error parsing feedback", e);
    return null;
  }
}
