import { format as formatWithTimezone } from "date-fns-tz";

const LEAN_DATETIME_WITH_DATETIME_FORMAT = "d MMM, h:mm a z";

/**
 * Format the date time in its lean datetime with time zone form.
 *
 * ✅ This is part of the Cadmus standardised date/duration formatting.
 *
 * {@link https://docs.google.com/spreadsheets/d/1XO8M5bPTAd-uVWCaygIGt9ch1BDOtZCV2zHZxPNtmbg/edit#gid=21065173 Style guide}
 * @example formatLeanDateTimeWithTimezone(new Date("2021-09-17T13:59:00.000Z"))
 * // returns "17 Sep, 11:59 PM UTC"
 */
export const formatLeanDatetimeWithTimezone = (date: Date): string => {
  return formatWithTimezone(date, LEAN_DATETIME_WITH_DATETIME_FORMAT);
};
