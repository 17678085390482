import { styled } from "styled-components";

import { Skeleton } from "@vericus/cadmus-ui";

/**
 * A loading state for the progress statistics cards.
 */
export const ProgressSkeleton = () => (
  <SkeletonRoot>
    <BoxSkeleton />
    <BoxSkeleton />
    <BoxSkeleton />
    <BoxSkeleton />
    <BoxSkeleton />
    <BoxSkeleton />
  </SkeletonRoot>
);

const SkeletonRoot = styled.div`
  display: flex;
  padding: 20px 32px;
  gap: 8px;
  background-color: ${(p) => p.theme.colors.white100};
`;

const BoxSkeleton = styled(Skeleton)`
  border-radius: 8px;
  height: 88px;
`;
