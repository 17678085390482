import { memo, ReactNode } from "react";
import { styled } from "styled-components";

import { Divider, SelectButton, Text } from "@vericus/cadmus-ui";

import { QuestionType } from "@/generated/graphql";

import { NumberInput } from "../components/number-input";
import {
  QuestionBlockType,
  QuestionTypeDropdownMenu,
} from "../components/question-type-dropdown-menu";
import { Dispatch } from "./state";

interface Props {
  questionType?: QuestionBlockType;
  dispatch: Dispatch;
  points?: number;
  childrenTotalPoints?: number;
  isChildQuestion?: boolean;
  questionTitle?: string;
  actionComponent?: ReactNode;
  actionPosition?: "left" | "right";
  /** Error when user hasn't assigned points to question*/
  noAssignedPoint?: boolean;
}

/**
 * Question Form Header with question change selector and actions.
 */
const QuestionFormHeaderComponent = ({
  dispatch,
  questionType,
  questionTitle,
  points,
  childrenTotalPoints,
  isChildQuestion = false,
  actionComponent,
  actionPosition = "left",
  noAssignedPoint,
}: Props) => {
  return (
    <Root isSubQuestion={questionType === QuestionType.Sub}>
      <Content>
        <LeftContentWrapper>
          <Title kind="headingFive" color="navy700" as="span">
            {questionTitle}
          </Title>
          <QuestionTypeDropdownMenu
            excludedKeys={isChildQuestion ? [QuestionType.Sub] : []}
            questionType={questionType}
            triggerButton={(text: string) => (
              <QuestionTypeButton>{text}</QuestionTypeButton>
            )}
            onSelectQuestionType={(questionType) =>
              dispatch({ type: "SetQuestionType", questionType })
            }
          />
        </LeftContentWrapper>
        <ActionWrapper position={actionPosition}>
          {actionComponent}
        </ActionWrapper>
        {questionType && (
          <RightContentWrapper>
            <PointInputWrapper>
              <Text kind="bodySm" color="navy500">
                Points
              </Text>
              {/* TODO: Add error prompt to points input */}
              {questionType === QuestionType.Sub ? (
                <PointsInput
                  key={questionType}
                  readOnly
                  size={1}
                  placeholder="0"
                  value={childrenTotalPoints ?? 0}
                />
              ) : (
                <PointsInput
                  name="PointsInput"
                  key={questionType}
                  size={1}
                  placeholder="0"
                  hasError={noAssignedPoint}
                  value={points ?? ""}
                  onChange={(e) => {
                    let points = 0;
                    if (e.target.value === "") dispatch({ type: "SetPoints" });
                    points = parseFloat(e.target.value);
                    if (Number.isNaN(points)) return;
                    if (points >= 0 && points <= 100)
                      dispatch({ type: "SetPoints", points });
                  }}
                />
              )}
            </PointInputWrapper>
          </RightContentWrapper>
        )}
      </Content>
      {questionType && <HeaderDivider />}
    </Root>
  );
};

export const QuestionFormHeader = memo(QuestionFormHeaderComponent);

const Root = styled.div<{ isSubQuestion?: boolean }>`
  display: flex;
  width: 100%;
  height: 65px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  background: ${(p) => p.theme.colors.white100};
  padding: 0px ${(p) => (p.isSubQuestion ? "67px" : "45px")};
  box-sizing: border-box;
  border-radius: 4px;
`;

const Title = styled(Text)`
  margin-right: 24px;
`;

const HeaderDivider = styled(Divider)`
  margin: 8px 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const LeftContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RightContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 32px;
`;

const QuestionTypeButton = styled(SelectButton)`
  padding: 8px 6px 8px 12px;
  min-width: 165px;
  box-sizing: border-box;
  border: none;
  background: ${(p) => p.theme.colors.grey50};
`;

const PointsInput = styled(NumberInput)`
  height: 40px;
  min-width: 44px;
`;

const PointInputWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const ActionWrapper = styled.div<{ position: "left" | "right" }>`
  position: relative;
  margin-left: ${(p) => (p.position === "left" ? "12px" : "auto")};
  margin-right: ${(p) => (p.position === "left" ? "auto" : "32px")};
`;
