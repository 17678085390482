import { Icon } from "@vericus/cadmus-ui";

import {
  isAsyncThunkAction,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import jitsuClient from "@/client/jitsu";
import { revealNames, updateLastClassSyncedAt } from "@/data/class/classSlice";
import { EnrollmentFragment } from "@/generated/graphql";
import { ConnectedSyncNudge, NarrowedEnrollment } from "@/ui/class/components";
import { ToastContent } from "@/ui/notifications";

import { JitsuEvent } from "../events/JitsuEvent";
import { startAppListening } from "../listenerMiddleware";
import { dismissedSyncNudge } from "../localFlags";
import {
  generateActivityReport,
  generateAssessmentReport,
  syncClassList,
  SyncClassListActionPayload,
} from "./actions";

// Toasts for sync class list operations
startAppListening({
  matcher: isAsyncThunkAction(syncClassList),
  effect: (action) => {
    if (!isAsyncThunkAction(action)) return;
    const payload = action.meta.arg as SyncClassListActionPayload;
    if (isPending(action)) {
      if (payload.supressToasts) return;
      toast.loading("Syncing class list from your LMS...", {
        id: action.meta.requestId,
      });
    }
    if (isFulfilled(action)) {
      // what the async thunk resolves to
      const syncedEnrollments: EnrollmentFragment[] =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (action.payload as any).data.syncRoster ?? [];
      const assessmentId = payload.assessmentId;

      jitsuClient.track(JitsuEvent.ROSTER_SYNCED, {
        assessment_id: assessmentId,
        enrollments_synced: syncedEnrollments?.length,
      });

      if (payload.supressToasts) return;
      if (!syncedEnrollments?.length) {
        toast.success(
          () => (
            <ToastContent
              message="No students are enrolled in this subject"
              description="Please re-sync once enrolments are active"
            />
          ),
          {
            icon: "⚠️",
            id: action.meta.requestId,
            position: "bottom-right",
            style: {
              marginRight: 20,
              marginBottom: 20,
            },
          }
        );
        return;
      }
      toast.success("All synced", {
        id: action.meta.requestId,
        duration: 2000,
      });
    }
    if (isRejected(action)) {
      if (payload.supressToasts) return;
      toast.error("Something went wrong. Please try again.", {
        id: action.meta.requestId,
      });
    }
  },
});

// Toasts for report generation operations
startAppListening({
  matcher: isAsyncThunkAction(generateActivityReport, generateAssessmentReport),
  effect: (action) => {
    if (isPending(action)) {
      toast.loading("Generating Report...", {
        id: action.meta.requestId,
      });
    } else if (isRejected(action)) {
      toast.error("Something went wrong. Please try again.", {
        id: action.meta.requestId,
      });
    } else if (isFulfilled(action)) {
      toast.dismiss(action.meta.requestId);
    }
  },
});

startAppListening({
  actionCreator: revealNames,
  effect: (action) => {
    const revealedEnrollmentIds = action.payload;

    jitsuClient.track(JitsuEvent.ENROLLMENTS_REVEALED, {
      enrollment_ids: revealedEnrollmentIds,
    });
  },
});

const SYNC_NUDGE_TOAST_ID = "class-list-sync-nudge";

startAppListening({
  actionCreator: updateLastClassSyncedAt,
  effect: (action, listenerAPI) => {
    const { assessmentId } = action.payload;

    /* const shouldShowToast = shouldShowRosterSyncNudge(enrollments); */

    // XXX Removing the nudge temporarily to finesse the timing and logic of
    // when the nudge should show, instead of using local storage
    // TODO Use database course level setting to determine if a nudge should be shown
    const shouldShowToast = false;
    if (!shouldShowToast) return;

    // don't show the toast if the user manually dismissed it
    if (listenerAPI.getState().localFlags.dismissedSyncNudge) return;

    setTimeout(() => {
      toast.success(
        <ToastContent
          message="Sync class list with the LMS"
          description={<ConnectedSyncNudge />}
          action="Sync class"
          onAction={() => {
            listenerAPI.dispatch(syncClassList({ assessmentId }));
            toast.dismiss(SYNC_NUDGE_TOAST_ID);
          }}
          onDismiss={() => {
            toast.dismiss(SYNC_NUDGE_TOAST_ID);
            listenerAPI.dispatch(dismissedSyncNudge());
          }}
        />,
        {
          id: SYNC_NUDGE_TOAST_ID,
          icon: (
            <div style={{ marginTop: 12 }}>
              <Icon iconName="Reset" iconColor="white100" />
            </div>
          ),
          duration: Infinity,
          style: {
            alignItems: "flex-start",
            marginRight: 20,
            marginBottom: 20,
          },
          position: "bottom-right",
        }
      );
    }, 2000);
  },
});

export function shouldShowRosterSyncNudge(
  enrollments: NarrowedEnrollment[]
): boolean {
  // We do not exclude withdrawn students as this is still useful for teachers to see in the app
  const enrollmentsOfInterest = enrollments.filter(
    (e) => !e.workSettings?.examDeferred
  );
  const hasANonRosterSyncedStudent = enrollmentsOfInterest.some(
    (e) => !e.syncedAt
  );
  const haveStudentsStartedWorking = enrollmentsOfInterest.some(
    (e) => !!e.work?.lastSaveTime
  );
  return hasANonRosterSyncedStudent && haveStudentsStartedWorking;
}
