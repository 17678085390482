import React, { forwardRef, ReactNode } from "react";

import { DropdownMenu } from "@vericus/cadmus-ui";

import { InstructionSheetFragment } from "generated/graphql";

import { ExtensionType } from "@/ui/class/progress/pages/StudentsSettingsPage";
import { StudentListMeta, StudentListRow } from "@/ui/class/progress/types";

interface ExtensionMenuItemProps
  extends React.ComponentProps<typeof DropdownMenu.Item> {
  /** Class list row data */
  row: StudentListRow;
  /** Currently released Instruction Sheet. */
  sheet?: InstructionSheetFragment;
  // Callback to edit extensions for an enrollment
  editExtensions: StudentListMeta["editExtensions"];
  // Clear an extension on an enrollment
  clearExtensions: StudentListMeta["clearExtensions"];
}

/**
 * StudentDropdown's sub-menu item for extensions.
 */
export const ExtensionMenuItem = forwardRef<
  HTMLDivElement,
  ExtensionMenuItemProps
>((props, ref) => {
  const { row, sheet, editExtensions, clearExtensions, ...buttonProps } = props;
  const { enrollment } = row;

  const finalExtended = row.settings.finalExtension !== null;
  const timeExtended = row.settings.timeExtension !== null;
  const extended = finalExtended || timeExtended;
  const hasTimeLimit = !!sheet?.timeLimit;

  // Menu items for due date extensions
  const dueExtensionItems = (
    <>
      <DropdownMenu.Item
        iconName="Calendar"
        content={`${finalExtended ? "Update" : "Extend"} due date`}
        onSelect={() => editExtensions([enrollment], ExtensionType.DueDate)}
      />
      {finalExtended && (
        <DropdownMenu.Item
          iconName="Trash"
          content="Remove due date extension"
          onSelect={() => clearExtensions([enrollment], ExtensionType.DueDate)}
        />
      )}
    </>
  );

  // Menu items for time limit extension
  let timeExtensionItems: ReactNode = null;
  if (hasTimeLimit) {
    timeExtensionItems = (
      <>
        <DropdownMenu.Item
          iconName="Time"
          content={`${timeExtended ? "Update" : "Extend"} time limit`}
          onSelect={() => editExtensions([enrollment], ExtensionType.TimeLimit)}
        />
        {timeExtended && (
          <DropdownMenu.Item
            iconName="Trash"
            content="Remove time limit extension"
            onSelect={() =>
              clearExtensions([enrollment], ExtensionType.TimeLimit)
            }
          />
        )}
      </>
    );
  }

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger asChild>
        <DropdownMenu.Item
          ref={ref}
          iconName="CalendarCheck"
          content={`${extended ? "Update" : "Set"} extension`}
          {...buttonProps}
        />
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent>
        {dueExtensionItems}
        {timeExtensionItems !== null && <DropdownMenu.Separator />}
        {timeExtensionItems}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
});
