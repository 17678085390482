import { useCallback, useMemo } from "react";
import { styled } from "styled-components";

import {
  Alert,
  Checkbox,
  Input,
  LinkButton,
  Spacer,
  Text,
  Tooltip,
} from "@vericus/cadmus-ui";

import { compareAsc } from "date-fns";
import { formatDate } from "utils/datetime";

import { SettingInfoCard } from "../SettingInfoCard";
import { FadeIn, SettingContainer } from "../styles";

interface DateSettingProps {
  dueDate: string | null;
  onDueDateClick: VoidFunction;

  returnDate: string | null;
  onReturnDateClick: VoidFunction;

  timeLimit: number | null;
  noTimeLimit: boolean;
  setTimeLimit: (tl: number | null) => void;
  setNoTimeLimit: (ntl: boolean) => void;

  allowLateResubmissions: boolean;
  onAllowLateResubmissionsClick: VoidFunction;
  lateResubmissionsLearnMoreLink: string;

  haveStudentsStarted: boolean;
  lmsDueDate: string | null;
  onToggleLmsDueDate: (date: string | null) => void;
  lmsGradingDueDate: string | null;
  onToggleLmsGradingDueDate: (date: string | null) => void;

  /** Callback to disable draft settings. */
  onDisableDraftSetting?: () => void;
}

/**
 * View and set key Task dates like the final due date and return date. These
 * dates are "key" because they are required to be set before releasing.
 *
 * The actual setting of the dates are done using the `TaskTimeline` component,
 * this component only calls the respective click handlers passed as props.
 */
export const DateSetting = ({
  dueDate,
  onDueDateClick,
  returnDate,
  onReturnDateClick,
  timeLimit,
  noTimeLimit,
  setTimeLimit,
  setNoTimeLimit,
  allowLateResubmissions,
  lateResubmissionsLearnMoreLink,
  onAllowLateResubmissionsClick,
  haveStudentsStarted,
  lmsDueDate,
  onToggleLmsDueDate,
  lmsGradingDueDate,
  onToggleLmsGradingDueDate,
  onDisableDraftSetting,
}: DateSettingProps) => {
  // If date is provided by lms, its setting will be disabled by default
  const disableDueDateSetting = useMemo(
    () =>
      lmsDueDate && dueDate
        ? compareAsc(new Date(lmsDueDate), new Date(dueDate)) === 0
        : false,
    [lmsDueDate, dueDate]
  );
  const disableFeedbackDateSetting = useMemo(
    () =>
      lmsGradingDueDate && returnDate
        ? compareAsc(new Date(lmsGradingDueDate), new Date(returnDate)) === 0
        : false,
    [lmsGradingDueDate, returnDate]
  );
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // validation 0 < val <= 1_000_000
      const val = parseInt(event.currentTarget.value);
      if (0 < val && val <= 1_000_000) {
        setTimeLimit(val);
      } else if (isNaN(val)) {
        setTimeLimit(null);
      }
    },
    [setTimeLimit]
  );

  // Handler to toggle time limit option
  const handleNoTimeLimit = useCallback(() => {
    const turnOffTimeLimit = !noTimeLimit;
    // If time limit is turned off, set it to null
    if (turnOffTimeLimit) {
      setTimeLimit(null);
    } else {
      // If time limit is turned on, disable draft settings
      onDisableDraftSetting?.();
    }
    setNoTimeLimit(turnOffTimeLimit);
  }, [setNoTimeLimit, setTimeLimit, noTimeLimit, onDisableDraftSetting]);

  const timeLimitUnitText =
    timeLimit === null || timeLimit > 1 ? "minutes" : "minute";

  return (
    <SettingContainer>
      <Text kind="headingOne">Assignment key dates</Text>
      <Text kind="lead" color="shade1">
        Set a final due date and a feedback return date for this assignment. You
        can also set an optional time limit. Before the due date, students can
        resubmit as many times as they like.
      </Text>
      <Spacer spacing={45} />
      <Text kind="headingFive" as="span">
        Final Due Date
      </Text>
      <Text kind="bodySm">
        Your final is due on{" "}
        <Tooltip
          passThrough={!disableDueDateSetting}
          size="sm"
          width={"auto"}
          content="Date received from the LMS"
        >
          <StyledLinkButton
            inline
            onClick={onDueDateClick}
            disabled={disableDueDateSetting}
            color={!noTimeLimit ? "lilac500" : undefined}
          >
            {dueDate ? formatDate(dueDate) : "set a final due date"}
          </StyledLinkButton>
        </Tooltip>
      </Text>
      <Spacer spacing={8} />
      {lmsDueDate && (
        <Checkbox
          checked={disableDueDateSetting}
          onChange={(e) =>
            onToggleLmsDueDate(e.target.checked ? lmsDueDate : null)
          }
        >
          <Text kind="bodySm">Use LMS provided date</Text>
        </Checkbox>
      )}
      <Spacer spacing={45} />
      <Text kind="headingFive" as="span">
        Feedback Return Date
      </Text>
      <Text kind="bodySm">
        Feedback can be accessed by students from{" "}
        <Tooltip
          passThrough={!disableFeedbackDateSetting}
          size="sm"
          width={"auto"}
          content="Date received from the LMS"
        >
          <StyledLinkButton
            inline
            onClick={onReturnDateClick}
            disabled={disableFeedbackDateSetting}
          >
            {returnDate ? formatDate(returnDate) : "set a return date"}
          </StyledLinkButton>
        </Tooltip>
      </Text>
      <Spacer spacing={8} />
      {lmsGradingDueDate && (
        <Checkbox
          checked={disableFeedbackDateSetting}
          onChange={(e) =>
            onToggleLmsGradingDueDate(
              e.target.checked ? lmsGradingDueDate : null
            )
          }
        >
          <Text kind="bodySm">Use LMS provided date</Text>
        </Checkbox>
      )}
      <Spacer spacing={45} />
      <Text kind="headingFive" as="span">
        Time limit is{" "}
        <StyledLinkButton
          inline
          onClick={handleNoTimeLimit}
          color={!noTimeLimit ? "lilac500" : undefined}
          disabled={haveStudentsStarted}
        >
          {noTimeLimit ? "off" : "on"}
        </StyledLinkButton>
      </Text>
      {!noTimeLimit && (
        <FadeIn>
          <Text kind="bodySm">
            You have{" "}
            <Input
              value={timeLimit ?? ""}
              onChange={handleChange}
              type="number"
              min="0"
              max="1000000"
              aria-label="time limit"
              disabled={haveStudentsStarted}
            />{" "}
            {timeLimitUnitText} to complete the assessment
          </Text>
        </FadeIn>
      )}
      {haveStudentsStarted && (
        <>
          <Spacer spacing={9} />
          <Alert severity="warning">
            Time limit cannot be changed once students have started writing
          </Alert>
        </>
      )}
      <Spacer spacing={45} />
      <Text kind="headingFive" as="span">
        Multiple late submissions is{" "}
        <StyledLinkButton
          inline
          onClick={onAllowLateResubmissionsClick}
          color={allowLateResubmissions ? "lilac500" : undefined}
        >
          {allowLateResubmissions ? "on" : "off"}
        </StyledLinkButton>
      </Text>
      <Spacer spacing={16} />
      <SettingInfoCard learnMoreLink={lateResubmissionsLearnMoreLink}>
        Typically, only one late submission is allowed after the due date.
        Enabling this setting will allow students to make multiple late
        resubmissions.
      </SettingInfoCard>
      <Spacer spacing={45} />
    </SettingContainer>
  );
};

const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
`;
