import { useCallback, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";

import { Button, Desk, Spacer, Text } from "@vericus/cadmus-ui";

import NiceModal from "@ebay/nice-modal-react";
import { useJitsu } from "@jitsu/react";
import {
  AssessmentType,
  Requirements,
  RequirementsInput,
  TaskFormat,
  TaskQuery,
  useUpdateRequirementsMutation,
} from "generated/graphql";
import { produce } from "immer";

import {
  ErrorKind,
  useClearErrorCallback,
  useErrorCallback,
} from "@/data/error/useError";
import { JitsuEvent } from "@/data/events/JitsuEvent";
import {
  DateSettings,
  Step,
  TaskTimeline,
} from "@/features/task/settings/TaskTimeline";
import { findCanvasLTIAdvantageAGS } from "@/graphql/helpers";
import { useRootNavigate } from "@/router/routing";
import { StatusBar } from "@/ui/errors/StatusBar";
import { Loading } from "@/ui/shared/Loading";
import { isExam } from "@/ui/task";
import { RequirementView, useDiffRequirements } from "@/ui/task/requirements";
import { useExamRequirementErrors } from "@/ui/task/requirements/RequirementView/ExamRequirements";

import { AssessmentTypeSetting } from "./AssessmentTypeSetting";
import { AssignmentSettings } from "./AssignmentSettings";
import { ExamSettings } from "./ExamSettings";
import {
  ConnectedMidExamUpdateWarningModal,
  MidExamUpdateWarningModalProps,
  shouldShowWarning,
} from "./MidExamUpdatesWarningModal";
import { SettingContainer } from "./styles";
import { SettingsFormProvider, useSettingsForm } from "./use-settings-form";
import {
  useSettingsLocationState,
  useSettingsNavigation,
} from "./useSettingsNavigation";

interface SettingsPageProps {
  institution: TaskQuery["institution"];
  assessment: TaskQuery["assessment"];
  instructionSheet: TaskQuery["instructionSheet"];
  task: TaskQuery["task"];
}

/** Callback to update the steps through the date picking timeline for assignment dates. */
export type SetDatesStepCallback = (newStep: Step) => void;

/**
 * Edit current Task Requirements.
 *
 * This is a full page component with sub-pages of datetime pickers for various
 * task dates. All requirements are updated locally with a side preview, and can
 * be updated on the server with a graphql mutation.
 *
 * A `Setting` is the local, stateful version of a `Requirement`. A Setting
 * value can be updated on the client, through this page. The updated values are
 * collectively used as the input to the `updateRequirements` mutation when the
 * changes are confirmed.
 *
 * The page gets its state and state updaters from the form context
 * wrapping it. Its children are passed down the state and updaters.
 *
 * The 'Confirm Changes' button will call the mutation to update the Task
 * requirements. However, if the assessment is an exam, validation on all
 * fields will run first and let the user know to correct the errors.
 */
export function SettingsPage({
  institution,
  task,
  assessment,
  instructionSheet,
}: SettingsPageProps) {
  const { id: assessmentId } = assessment;
  const form = useSettingsForm(task.requirements);
  const { track } = useJitsu();

  // Feature flags parsed from institution features config
  const examsFeatureEnabled = institution.examsFeatureEnabled;

  // Flag for Canvas LMS and an enabled LTI 1.3 AGS
  const hasCanvasAGS =
    findCanvasLTIAdvantageAGS(assessment.ltiServices) !== null;

  // Set initial states based on the location state set when navigating to this page
  const locationState = useSettingsLocationState();
  const initialTimelineStep = locationState?.initialTimelineStep ?? null;
  // Switch to task date selection pages.
  const [datesStep, setDatesStep] = useState<Step | null>(initialTimelineStep);

  // Navigation callbacks
  const { onCancel, onConfirm } = useSettingsNavigation();
  const rootNavigate = useRootNavigate();

  // Go to the task edit route
  const onError = useErrorCallback(ErrorKind.UpdateSettings);
  const clearErrors = useClearErrorCallback();

  // Mutation to update the Task requirements directly.
  const [mutateRequirements, { loading }] = useUpdateRequirementsMutation({
    onError,
    onCompleted: (data) => {
      track(JitsuEvent.REQUIREMENTS_UPDATED, {
        assessment_id: assessmentId,
        task_id: task.id,
        task_history_id: instructionSheet?.serverId,
        requirements: data.updateRequirements,
      });
      clearErrors();
      onConfirm();
    },
  });

  const { values: settings, validate: validateSettings } = form;
  const diff = useDiffRequirements(settings, instructionSheet);

  const {
    scrollToErrorContainerRef,
    scroll,
    scrollToExamTiming,
    scrollToExamTimingRef,
  } = useControlledScroll();

  /**
   * Callback for when the user wants to confirm their changes to settings.
   *
   * If an exam, the settings are validated before the API mutation is
   * called with the current settings
   *
   * NOTE assignment settings are currently not validated.
   */
  const onUpdateRequirements = useCallback(
    (input?: Partial<Requirements>) => {
      const reqInput: Partial<Requirements> = {
        ...settings,
        ...input,
        // enableSubmissionPdf is set to true for assignments
        enableSubmissionPdf:
          settings.assessmentType === AssessmentType.Assignment
            ? true
            : settings.enableSubmissionPdf,
      };

      // Prevent the sViewReports and draftSViewReports to be set to 2
      // If Multi-Form AND Assignment AND Turnitin-marking
      // As we should not allow students to view turnitin reports and see the
      // automarking on the PDF that is sent to Turnitin.
      // This situation can occur if the grading service is changed away from
      // Turnitin, the view level settings set to 2, and then the grading service
      // is changed back to Turnitin.
      if (
        task.format === TaskFormat.Multiformat &&
        reqInput.assessmentType === AssessmentType.Assignment &&
        reqInput.gradingService === "turnitin"
      ) {
        if (reqInput.sViewReports === 2) {
          reqInput.sViewReports = 1;
        }
        if (reqInput.draftSViewReports === 2) {
          reqInput.draftSViewReports = 1;
        }
      }

      const sendRequirementsMutation = () => {
        mutateRequirements({
          variables: {
            assessmentId,
            requirements: produce(reqInput, (draft) => {
              delete draft.id;
              delete draft.__typename;
              delete draft.hasChanged;
            }) as RequirementsInput,
          },
        });
      };

      const { hasErrors } = validateSettings();
      if (hasErrors && isExam(settings)) {
        scroll();
        return;
      }

      if (shouldShowWarning(instructionSheet, diff)) {
        NiceModal.show(ConnectedMidExamUpdateWarningModal, {
          useCase: "updateSettings",
          onNegativeAction: () => {
            NiceModal.hide(ConnectedMidExamUpdateWarningModal);
          },
          onPositiveAction: () => {
            sendRequirementsMutation();
            NiceModal.hide(ConnectedMidExamUpdateWarningModal);
          },
        } satisfies MidExamUpdateWarningModalProps);
        return;
      }

      sendRequirementsMutation();
    },
    [
      mutateRequirements,
      assessmentId,
      settings,
      validateSettings,
      scroll,
      diff,
      instructionSheet,
      task.format,
    ]
  );

  // Time line navigation actions
  const onTimelineCancel = useCallback(() => {
    setDatesStep(null);
    // If we came to this page to set only a date in timeline, we can go back to
    // the edit page.
    if (initialTimelineStep !== null) {
      rootNavigate("/task/edit");
      return;
    }
  }, [initialTimelineStep, rootNavigate]);

  const onTimelineConfirm = useCallback(
    (dateSettings: DateSettings) => {
      if (!initialTimelineStep) {
        setDatesStep(null);
        return;
      }
      // If is coming from release attempt, save date settings and
      // go to release page
      onUpdateRequirements(dateSettings);
    },
    [initialTimelineStep, onUpdateRequirements]
  );

  const requirementsViewErrors = useExamRequirementErrors(form);

  const isExamSettings = isExam(settings);

  if (loading) return <Loading message="Updating requirements" />;

  if (datesStep !== null) {
    return (
      <TaskTimeline
        dueDate={settings.dueDate}
        returnDate={settings.returnDate}
        draftDueDate={settings.draftDueDate}
        noDraft={settings.noDraft}
        setDates={({ draftDueDate, dueDate, returnDate }) =>
          form.setValues({ draftDueDate, dueDate, returnDate })
        }
        onCancel={onTimelineCancel}
        onConfirm={onTimelineConfirm}
        initialStep={datesStep}
      />
    );
  }

  return (
    <SettingsFormProvider form={form}>
      <Desk.Layout>
        <StatusBar />
        <Desk.SplitTable>
          <Desk.Left width="half">
            <SettingContainer>
              <Text kind="displayOne">Set your requirements and settings</Text>
            </SettingContainer>

            {(examsFeatureEnabled || isExamSettings) && (
              <AssessmentTypeSetting
                isDisabled={assessment.hasSave} // can not change assessment type when students have started work
                selectedAssessmentType={settings.assessmentType}
                onSelectAssessmentType={(assessmentType) => {
                  if (isExam({ assessmentType })) {
                    // we want PDF copy off by default for exams
                    form.setFieldValue("enableSubmissionPdf", false);
                  }
                  form.setFieldValue("assessmentType", assessmentType);
                }}
              />
            )}

            {isExamSettings ? (
              <ExamSettings
                ref={scrollToErrorContainerRef}
                examTimingRef={scrollToExamTimingRef}
                assessmentId={assessment.id}
                hasCanvasAGS={hasCanvasAGS}
                haveStudentsStarted={assessment.hasSave}
                hasSubmissions={assessment.hasSubmission}
                format={task.format ?? TaskFormat.Classic}
                accessCodeFeatureEnabled={institution.accessCodeFeatureEnabled}
                footnoteReferencingEnabled={
                  institution.footnoteReferencingEnabled
                }
              />
            ) : (
              <AssignmentSettings
                assessmentId={assessment.id}
                hasCanvasAGS={hasCanvasAGS}
                haveStudentsStarted={assessment.hasSave}
                hasSubmissions={assessment.hasSubmission}
                setDatesStep={setDatesStep}
                lmsDueDate={assessment.dueDate}
                lmsGradingDueDate={assessment.gradingDueDate}
                format={task.format ?? TaskFormat.Classic}
                footnoteReferencingEnabled={
                  institution.footnoteReferencingEnabled
                }
              />
            )}

            <Spacer spacing={270} />
          </Desk.Left>
          <StyledDeskRight
            background={isExamSettings ? "cyan100" : "orange100"}
          >
            <Container>
              <RequirementView
                requirements={settings}
                diff={diff}
                showHidden
                showLegend
                examErrors={requirementsViewErrors}
                setExamStart={scrollToExamTiming}
                setFinalDueDate={() => setDatesStep(Step.Final)}
                format={task.format ?? TaskFormat.Classic}
              />
              <Spacer spacing={90} />
            </Container>
          </StyledDeskRight>
        </Desk.SplitTable>
        <Desk.Drawer>
          <DrawerContainer>
            <Button marginRight={18} onClick={onCancel}>
              Cancel
            </Button>
            <Button kind="primary" onClick={() => onUpdateRequirements()}>
              Confirm Changes
            </Button>
          </DrawerContainer>
        </Desk.Drawer>
      </Desk.Layout>
    </SettingsFormProvider>
  );
}

const DrawerContainer = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 27px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 100%;
  max-width: 800px;
  padding: 72px 45px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
`;

const StyledDeskRight = styled(Desk.Right)`
  transition: background-color 0.3s ease-in-out;
`;

/**
 * Use state and an effect to control scrolling to the first error.
 *
 * NOTE: This exists because calling the function directly after validating the form
 * doesn't scroll the page as React hasn't updated the DOM yet, flushSync didn't
 * fix it.
 */
const useControlledScroll = () => {
  const scrollToErrorContainerRef = useRef<HTMLDivElement>(null);
  const scrollToExamTimingRef = useRef<HTMLDivElement>(null);

  const scrollToExamTiming = () => {
    scrollToExamTimingRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const [shouldScroll, setShouldScroll] = useState(false);
  useEffect(() => {
    if (shouldScroll) {
      scrollToErrorContainerRef.current &&
        scrollToFirstErrorMessage(scrollToErrorContainerRef.current);
      setShouldScroll(false);
    }
  }, [shouldScroll]);
  return {
    scrollToErrorContainerRef,
    scroll: () => setShouldScroll(true),
    scrollToExamTimingRef,
    scrollToExamTiming,
  };
};

const scrollToFirstErrorMessage = (element: Element) => {
  const firstErrorElement = element.querySelector(
    "[data-error='true']"
  )?.parentElement;
  firstErrorElement?.scrollIntoView({ behavior: "smooth", block: "center" });
};
