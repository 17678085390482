import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  addProcessedFiles,
  addProcessingFiles,
  assignCategory,
  loadFiles,
  updateFilename,
} from "./actions";
import { QuestionBankSlice } from "./types";

export const initialState: QuestionBankSlice = {
  loading: false,
  processingFiles: 0,
  processedFiles: 0,
  categories: [],
  banks: [],
};

export const questionBanksSlice = createSlice({
  name: "questionBanks",
  initialState,
  reducers: {
    clear() {
      return initialState;
    },
    addCategory(state, action: PayloadAction<string>) {
      state.categories.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addProcessingFiles, (state, action) => {
      state.processingFiles += action.payload.filesCount;
    });
    builder.addCase(addProcessedFiles, (state, action) => {
      state.processedFiles += action.payload.filesCount;
    });
    builder.addCase(loadFiles.pending, (state, action) => {
      state.loading = true;
      state.processedFiles = 0;
      state.processingFiles = action.meta?.arg?.length ?? 0;
      state.banks = [];
    });
    builder.addCase(loadFiles.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadFiles.fulfilled, (state, action) => {
      state.banks = action.payload.flat();
      const categories = new Set(
        state.banks.flatMap((f) => f.questions).flatMap((q) => q.category ?? [])
      );
      state.categories = [...categories];
      state.loading = false;
    });
    builder.addCase(updateFilename.pending, (state, action) => {
      const bank = state.banks.find(
        (bank) => bank.fileOrigin.fileOriginId === action.meta.arg.fileOriginId
      );
      if (bank) {
        bank.fileOrigin.filename = action.meta.arg.filename;
        bank.fileOrigin.loading = true;
      }
    });
    builder.addCase(updateFilename.fulfilled, (state, action) => {
      const bank = state.banks.find(
        (bank) => bank.fileOrigin.fileOriginId === action.meta.arg.fileOriginId
      );
      if (bank) {
        bank.fileOrigin.filename = action.meta.arg.filename;
        bank.fileOrigin.id = action.payload?.id;
        bank.fileOrigin.loading = false;
      }
    });
    builder.addCase(updateFilename.rejected, (state, action) => {
      const bank = state.banks.find(
        (bank) => bank.fileOrigin.fileOriginId === action.meta.arg.fileOriginId
      );
      if (bank) {
        bank.fileOrigin.loading = false;
        bank.fileOrigin.error = action.payload?.error;
        bank.fileOrigin.filename = action.payload?.filename;
      }
    });
    builder.addCase(assignCategory.pending, (state, action) => {
      state.banks.forEach(({ questions, fileOrigin }) => {
        if (action.meta.arg.questionIds) {
          questions.forEach((q) => {
            if (action.meta.arg.questionIds?.includes(q.questionId)) {
              q.loading = true;
            }
          });
        } else if (fileOrigin.fileOriginId === action.meta.arg.fileOriginId) {
          questions.forEach((q) => (q.loading = true));
        }
      });
    });
    builder.addCase(assignCategory.rejected, (state, action) => {
      state.banks.forEach(({ questions, fileOrigin }) => {
        if (action.meta.arg.questionIds) {
          questions.forEach((q) => {
            if (action.meta.arg.questionIds?.includes(q.questionId)) {
              q.loading = false;
            }
          });
        } else if (fileOrigin.fileOriginId === action.meta.arg.fileOriginId) {
          questions.forEach((q) => (q.loading = false));
        }
      });
    });
    builder.addCase(assignCategory.fulfilled, (state, action) => {
      state.banks.forEach(({ questions, fileOrigin }) => {
        questions.forEach((q) => {
          let question;
          if (action.meta.arg.questionIds?.includes(q.questionId)) {
            question = action.payload?.find(
              ({ questionId }) => questionId === q.questionId
            );
          } else if (fileOrigin.fileOriginId === action.meta.arg.fileOriginId) {
            question = action.payload?.find(
              ({ questionId }) => questionId === q.questionId
            );
          }
          if (question) {
            const { id, saveId, category, serverQuestionId } = question;
            if (question.fileOrigin?.id) {
              fileOrigin.id = question.fileOrigin.id;
            }
            if (saveId) {
              q.saveId = saveId;
            }
            if (serverQuestionId) {
              q.id = serverQuestionId;
            }
            if (id) {
              q.questionBankId = id;
            }
            q.category = category;
            q.loading = false;
          }
        });
      });
    });
  },
});

export const { addCategory, clear } = questionBanksSlice.actions;

export const questionBanksReducer = questionBanksSlice.reducer;
