import { styled } from "styled-components";

import { Block, Spacer, Text } from "@vericus/cadmus-ui";

import { syncClassList } from "@/data/class";
import { useAppDispatch, useAppSelector } from "@/data/hooks";
import {
  AssessmentOverviewFragment,
  ReleasedRequirementsFragment,
} from "@/generated/graphql";
import {
  selectExamCloseDate,
  selectIsExam,
  selectIsExamInProgress,
} from "@/graphql/sheet-selectors";
import { LMSSyncCTA } from "@/ui/StudentLists/LMSSyncCTA";
import { useSyncGrades } from "@/ui/StudentLists/useSyncGrades";

import { ConnectedNavBar } from "../NavBar";
import * as styles from "./AssessmentHeader.css";
import { ExamInProgressPill } from "./ExamInProgressPill";

export interface AssessmentHeaderProps {
  assessment: AssessmentOverviewFragment;
  sheet: ReleasedRequirementsFragment | null;
  /**
   * Optionally hide the app navbar.
   * @default false
   */
  hideNavBar?: boolean;
}

export const AssessmentHeader = (props: AssessmentHeaderProps) => {
  const { assessment, sheet, hideNavBar = false } = props;

  const dispatch = useAppDispatch();

  // Callback to dispatch sync class list action
  const onSyncClassList = () => {
    dispatch(syncClassList({ assessmentId: assessment.id }));
  };
  // Callback to sync grades to the LMS
  const syncGrades = useSyncGrades(assessment.id);
  const syncing = useAppSelector((state) => state.class.syncing);

  // Select exam progress
  let examInProgressUntil: string | null = null;
  if (sheet && selectIsExam(sheet) && selectIsExamInProgress(sheet)) {
    examInProgressUntil = selectExamCloseDate(sheet);
  }

  return (
    <>
      <TitleBlock>
        <div className={styles.titleBlockTitle}>
          <Text
            kind="displayTwo"
            color="navy700"
            style={{ fontSize: 39, lineHeight: "54px" }}
          >
            {assessment.name}
          </Text>
          <Text kind="label">{assessment.subject.name}</Text>
          {examInProgressUntil && (
            <>
              <Spacer spacing={20} />
              <ExamInProgressPill examEndDate={examInProgressUntil} />
            </>
          )}
        </div>
        <div className={styles.pageButtonGroup}>
          <LMSSyncCTA
            data-component={"AssessmentHeader.sync-lms"}
            syncing={syncing}
            syncClassList={onSyncClassList}
            syncGrades={syncGrades}
          />
        </div>
      </TitleBlock>
      <Spacer spacing={64} />
      {!hideNavBar && (
        <Block>
          <ConnectedNavBar sheet={sheet} />
        </Block>
      )}
    </>
  );
};

const TitleBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 20px;

  @media screen and (min-width: 950px) {
    flex-direction: row;
    align-items: center;
    gap: 64px;
  }
`;
