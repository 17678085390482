import { Fragment, ReactNode } from "react";
import { styled } from "styled-components";

import { flexRender, Table } from "@tanstack/react-table";
import { motion } from "framer-motion";

import { StudentListRow } from "@/ui/class/progress/types";

import { BulkActionsMenu } from "../BulkActionsMenu";
import {
  FilteredNoResultTable,
  NoDraftSubmissionsTable,
  NoFinalSubmissionsTable,
  SearchNoResultTable,
} from "../EmptyStatesTable";
import * as Grid from "../InfoGrid";
import { ColumnId } from "../table";

/**
 * If table is being filtered by group or or tags.
 * Reset the selected rows in the table.
 * @param column the column the change is based on
 */
export const resetSelectedRowsIfFilterMatches = (
  table: Table<StudentListRow>,
  column: ColumnId.Group | ColumnId.Tags
) => {
  const shouldReset =
    (table.getColumn(ColumnId.Group)!.getIsFiltered() &&
      column === ColumnId.Group) ||
    (table.getColumn(ColumnId.Tags)!.getIsFiltered() &&
      column === ColumnId.Tags);
  if (shouldReset) table.resetRowSelection();
};

export interface StudentListTableProps {
  table: Table<StudentListRow>;
  /**
   * Determines the layout using flex grid.
   */
  template:
    | "progress"
    | "timed-submission"
    | "submission"
    | "code-progress"
    | "moderation-score";
  /**
   * If null, no empty state will be rendered.
   */
  emptyState: ReactNode | null;
}

/**
 * Encapsulates the logic to get an empty state (or null) for the class list table.
 */
export const TableEmptyState = (props: {
  table: Table<StudentListRow>;
  isDrafts?: boolean;
}) => {
  const { table, isDrafts } = props;

  // there are no submissions
  if (table.getPreFilteredRowModel()?.rows?.length === 0) {
    return isDrafts ? <NoDraftSubmissionsTable /> : <NoFinalSubmissionsTable />;
  }

  const { globalFilter, columnFilters } = table.getState();
  const hasGlobalFilter = !!globalFilter;
  const hasColumnFilter = columnFilters.length > 0;

  // Empty states due to filters
  const emptyBecauseOfFilters = table.getRowModel()?.rows?.length === 0;
  if (!emptyBecauseOfFilters) return null;
  if (hasGlobalFilter) {
    // No rows because of global filter
    return (
      <SearchNoResultTable
        clearSearchFilter={() => {
          document.getElementById("class-list-search")?.focus();
          table.setGlobalFilter("");
        }}
        searchQuery={globalFilter}
      />
    );
  }
  // No rows because of column filters
  if (!hasColumnFilter) return null;
  return (
    <FilteredNoResultTable clearFilter={() => table.resetColumnFilters()} />
  );
};

/**
 * Render Table Grid UI for the students class list tab. Also used in Moderation
 * score list table.
 */
export const StudentListLayout = (props: StudentListTableProps) => {
  const { table, template, emptyState } = props;

  return (
    <>
      <Grid.Row template={template} header>
        {table.getFlatHeaders().map((header) => (
          <Fragment key={header.id}>
            {flexRender(header.column.columnDef.header, header.getContext())}
          </Fragment>
        ))}
      </Grid.Row>
      {emptyState}
      {table.getRowModel()?.rows?.map((row, index) => (
        <Grid.Row
          key={row.id}
          template={template}
          selected={row.getIsSelected()}
          data-testid={"StudentListRow"}
          data-component={index === 0 ? `StudentListRow` : undefined}
        >
          {row.getVisibleCells().map((cell) => (
            <Fragment key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Fragment>
          ))}
        </Grid.Row>
      ))}
    </>
  );
};

export const AnimatedBulkActionsMenu = motion.create(BulkActionsMenu);

export const TableControl = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  margin-left: -45px;
  margin-right: -45px;
  padding-left: 45px;
  padding-right: 45px;
`;
