import * as React from "react";

import { Input, LinkButton, Spacer, Text } from "@vericus/cadmus-ui";

import { FadeIn, SettingContainer } from "../styles";

interface Props {
  weight: number | null;
  noWeight: boolean;
  setWeight: (value: number | null) => void;
  setNoWeight: (value: boolean) => void;
}

/**
 * View and set optional grade Weighting requirements with /noWeight/ and /weight/.
 */
export function WeightSetting(props: Props) {
  const { noWeight, setNoWeight } = props;

  return (
    <SettingContainer>
      <Text kind="headingOne">
        Weighting is{" "}
        <LinkButton
          inline
          onClick={() => setNoWeight(!noWeight)}
          color={!noWeight ? "lilac500" : undefined}
        >
          {noWeight ? "off" : "on"}
        </LinkButton>
      </Text>
      <Text kind="bodyLg" color="shade1">
        Tell students what the weighting is for this assignment. This doesn’t
        affect how grades are returned to the Learning Management System (e.g.
        Grade Centre).
      </Text>
      {!noWeight && <Content {...props} />}
    </SettingContainer>
  );
}

const Content = (props: Props) => {
  const { weight, setWeight } = props;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // validation 0 <= val <= 100
      const val = parseInt(event.currentTarget.value);
      if (0 <= val && val <= 100) {
        setWeight(val);
      } else if (isNaN(val)) {
        setWeight(null);
      }
    },
    [setWeight]
  );

  return (
    <FadeIn>
      <Spacer spacing={36} />
      <Text kind="headingFive">Weighting</Text>
      <Text kind="bodySm">
        {`It's worth `}
        <Input
          value={weight ?? ""}
          onChange={handleChange}
          type="number"
          min="0"
          max="100"
          aria-label="weighting"
        />{" "}
        % of the unit
      </Text>
    </FadeIn>
  );
};
