import { FileCard, getFileType, LinkCard } from "@vericus/cadmus-ui";

import copy from "copy-to-clipboard";
import { ResourceFragment } from "generated/graphql";

import {
  formatFileSize,
  getExtension,
  isLink,
  removeExtension,
} from "./internal";
import { DropShadowed } from "./ResourceCard";

export interface ReadonlyResourceCardProps {
  resource: ResourceFragment;
  onOpenInBrowser: VoidFunction;
  children?: JSX.Element | false;
}

export const ReadonlyResourceCard = ({
  children,
  resource,
  onOpenInBrowser,
}: ReadonlyResourceCardProps) => {
  const component = isLink(resource) ? (
    <LinkCard
      viewOnly
      linkTitle={resource.name ?? ""}
      onOpenInBrowser={onOpenInBrowser}
      onCopyLink={() => resource.url && copy(resource.url)}
    />
  ) : (
    <FileCard
      viewOnly
      fileInfo={{
        name: removeExtension(resource.name ?? ""),
        type: getFileType(resource.name ?? ""),
        size: resource.fileSize ? formatFileSize(resource.fileSize) : "",
        extension: getExtension(resource.name ?? ""),
      }}
      onOpenInBrowser={onOpenInBrowser}
    />
  );
  return (
    <DropShadowed>
      {component}
      {children}
    </DropShadowed>
  );
};
