import { useCallback } from "react";

import { useJitsu } from "@jitsu/react";
import { isEqual } from "date-fns";
import { produce } from "immer";
import toast from "react-hot-toast";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import {
  ClassDocument,
  ClassQuery,
  EnrollmentFragment,
  SubmissionType,
  TaskFormat,
  useForceSubmissionsMutation,
  useForceSubmissionsV2Mutation,
} from "@/generated/graphql";
import { toDate } from "@/utils/datetime";

/**
 * Create a callback to force submitted (FINAL submission) a list of Enrollments
 * on behalf of their students.
 *
 * Submissions are skipped for enrollments which don't have a work to submit.
 */
export function useForceSubmissions(assessmentId: string, format?: TaskFormat) {
  const { track } = useJitsu();
  const [mutateV1] = useForceSubmissionsMutation();
  const [mutateV2] = useForceSubmissionsV2Mutation();

  return useCallback(
    (enrollments: EnrollmentFragment[]) => {
      // NOOP if no enrollments
      if (enrollments.length < 1) return;

      // Group the enrollments and its works by whether they have the work authority feature
      const groupedEnrollments = enrollments.reduce(
        (acc, value) => {
          return value.work?.features.enableWorkAuthority ||
            format === TaskFormat.Multiformat
            ? {
                ...acc,
                authority: [...acc.authority, value],
              }
            : {
                ...acc,
                save: [...acc.save, value],
              };
        },
        {
          authority: [] as EnrollmentFragment[],
          save: [] as EnrollmentFragment[],
        }
      );

      const now = new Date();

      if (groupedEnrollments.save.length > 0) {
        mutateV1({
          variables: {
            assessmentId,
            // We should have workIds at this point. If not, something's gone wrong.
            workIds: groupedEnrollments.save.map(
              (enrollment) => enrollment.work!.id
            ),
            type: SubmissionType.Final,
            submittedAt: now.toISOString(),
          },
          update: (proxy, { data }) => {
            if (data?.forceSubmissions) {
              const cacheData = proxy.readQuery<ClassQuery>({
                query: ClassDocument,
                variables: { assessmentId },
              });
              if (cacheData) {
                const newData = produce(cacheData, (draft) => {
                  for (const final of data.forceSubmissions) {
                    if (final === null) {
                      continue;
                    }

                    // Find the matching enrollment and mutate its "final" field.
                    const workId = final.workId;
                    const enrollmentIndex = draft.class.students.findIndex(
                      ({ work }) => work?.id === workId
                    );
                    if (enrollmentIndex !== -1) {
                      const work = draft.class.students[enrollmentIndex]!.work;
                      if (work) {
                        work.final = final;
                      }
                    }
                  }
                });
                proxy.writeQuery<ClassQuery>({
                  query: ClassDocument,
                  variables: { assessmentId },
                  data: newData,
                });
              }
            }
          },
          onCompleted: (data) => {
            if (data.forceSubmissions) {
              const forceSubmitted = data.forceSubmissions.filter(
                (submission) => {
                  const submittedAt = toDate(submission?.submittedAt);
                  if (submittedAt) {
                    return !isEqual(submittedAt, now);
                  }
                  return false;
                }
              );

              // Completion toast notifications
              if (forceSubmitted.length === 1) {
                const enrollment = enrollments.find(
                  ({ work }) => work?.id === forceSubmitted[0]?.workId
                );
                if (enrollment) {
                  toast.success(
                    `${enrollment.user.givenName}'s submission has been force submitted`
                  );
                }
              } else {
                toast.success(
                  `${forceSubmitted.length} submissions have been force submitted`
                );
              }

              // Completion event tracking
              if (forceSubmitted.length > 0) {
                const workIds = forceSubmitted
                  .map((submission) => submission?.workId)
                  .filter((w) => !!w);
                track(JitsuEvent.SUBMISSIONS_FORCED, {
                  work_ids: workIds,
                  assessment_id: assessmentId,
                });
              }
            }
          },
        });
      }

      if (groupedEnrollments.authority.length > 0) {
        mutateV2({
          variables: {
            assessmentId,
            // We should have workIds at this point. If not, something's gone wrong.
            workIds: groupedEnrollments.authority.map(
              (enrollment) => enrollment.work!.id
            ),
            type: SubmissionType.Final,
            submittedAt: now.toISOString(),
          },
          update: (proxy, { data }) => {
            if (data?.forceSubmissionsV2) {
              const cacheData = proxy.readQuery<ClassQuery>({
                query: ClassDocument,
                variables: { assessmentId },
              });
              if (cacheData) {
                const newData = produce(cacheData, (draft) => {
                  for (const final of data.forceSubmissionsV2) {
                    if (final === null) {
                      continue;
                    }

                    // Find the matching enrollment and mutate its "final" field.
                    const workId = final.workId;
                    const enrollmentIndex = draft.class.students.findIndex(
                      ({ work }) => work?.id === workId
                    );
                    if (enrollmentIndex !== -1) {
                      const work = draft.class.students[enrollmentIndex]!.work;
                      if (work) {
                        work.final = final;
                      }
                    }
                  }
                });
                proxy.writeQuery<ClassQuery>({
                  query: ClassDocument,
                  variables: { assessmentId },
                  data: newData,
                });
              }
            }
          },
          onCompleted: (data) => {
            if (data.forceSubmissionsV2) {
              const forceSubmitted = data.forceSubmissionsV2.filter(
                (submission) => {
                  const submittedAt = toDate(submission?.submittedAt);
                  if (submittedAt) {
                    return !isEqual(submittedAt, now);
                  }
                  return false;
                }
              );

              // Completion toast notifications
              if (forceSubmitted.length === 1) {
                const enrollment = enrollments.find(
                  ({ work }) => work?.id === forceSubmitted[0]?.workId
                );
                if (enrollment) {
                  toast.success(
                    `${enrollment.user.givenName}'s submission has been force submitted`
                  );
                }
              } else {
                toast.success(
                  `${forceSubmitted.length} submissions have been force submitted`
                );
              }

              // Completion event tracking
              if (forceSubmitted.length > 0) {
                const workIds = forceSubmitted
                  .map((submission) => submission?.workId)
                  .filter((w) => !!w);
                track(JitsuEvent.SUBMISSIONS_FORCED, {
                  work_ids: workIds,
                  assessment_id: assessmentId,
                });
              }
            }
          },
        });
      }
    },
    [mutateV1, mutateV2, assessmentId, track, format]
  );
}
