import { format as formatWithTimezone } from "date-fns-tz";

import { locale } from "./datetime";

const FULL_SENTENCE_FORMAT = "EEEE do MMMM yyyy 'at' h:mm a zzz";

/**
 * ✅ This is part of the Cadmus standardised date/duration formatting.
 * Format the date to a full sentence with its timezone.
 * @example formatFullSentence(new Date("2022-05-04 00:00))
 * // returns "Thursday 4th May at 12:00 AM AEST"
 */
export function formatFullSentence(date: Date): string {
  /*
    NOTE: in the future we could use a user specific locale (e.g. from a
      browser API or user settings) and dynamic imports to more accurately
      format timezones. Currently, enAU may display time zones correctly
      for Australian time zones but these may not be formatted correctly
      for someone with an enGB locale.
   */
  return formatWithTimezone(date, FULL_SENTENCE_FORMAT, {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    locale: locale,
  });
}
