import { useCallback } from "react";
import { styled } from "styled-components";

import { useJitsu } from "@jitsu/react";
import { DebouncedInput } from "ui/shared/DebouncedInput";

import { JitsuEvent } from "@/data/events/JitsuEvent";

import { ClassTable } from "../../table";

interface Props {
  table: ClassTable;
  className?: string;
}

export const SearchFilter = (props: Props) => {
  const { table } = props;
  const { track } = useJitsu();

  const globalFilter = table.getState().globalFilter;

  const onChange = useCallback(
    (value: string) => {
      table.setGlobalFilter(value);
      if (value === "") {
        track(JitsuEvent.CLASS_SEARCH_CLEARED);
      } else {
        track(JitsuEvent.CLASS_SEARCH_UPDATED, {
          search_value: value,
        });
      }
    },
    [table, track]
  );

  return (
    <SearchInputRoot className={props.className}>
      <StyledDeferredInput
        id="class-list-search"
        aria-label="Student Search"
        placeholder="Student name, ID or email"
        initialValue={globalFilter}
        onChange={onChange}
        fullWidth
        clearable={globalFilter.length > 0}
        iconName="Search"
      />
    </SearchInputRoot>
  );
};

const SearchInputRoot = styled.div`
  width: 298px;
  margin: 0 3px 0 auto;
`;

const StyledDeferredInput = styled(DebouncedInput)`
  height: 36px;
  font-size: 14px;

  input {
    border-radius: 3px;
  }
`;
