import { ReactNode } from "react";

import { defaultTheme, fontFamily } from "@vericus/cadmus-ui";

import {
  MantineProvider as BaseMantineProvider,
  createTheme,
  CSSVariablesResolver,
} from "@mantine/core";

export const mantineTheme = createTheme({
  fontFamily: fontFamily,
  fontSmoothing: false,
});

export const mantineVarsResolver: CSSVariablesResolver = () => ({
  variables: {},
  light: {
    "--mantine-color-text": defaultTheme.text.main,
    "--mantine-color-body": defaultTheme.background.default,
  },
  dark: {},
});

interface ProviderProps {
  children: ReactNode;
}

export const MantineProvider = ({ children }: ProviderProps) => (
  <BaseMantineProvider
    theme={mantineTheme}
    forceColorScheme="light"
    cssVariablesResolver={mantineVarsResolver}
  >
    {children}
  </BaseMantineProvider>
);
