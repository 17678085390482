import { ButtonHTMLAttributes, forwardRef } from "react";

import clsx from "clsx";

import * as styles from "./drag-handle.css";

interface DragHandleProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** Disable hover styles if true. */
  noHover?: boolean;
  /** Whether to hide the handle. Useful to reserve its space but not render anything. */
  hidden?: boolean;
}

export const DragHandle = forwardRef<HTMLButtonElement, DragHandleProps>(
  ({ noHover, hidden, ...buttonProps }, ref) => (
    <button
      ref={ref}
      {...buttonProps}
      className={clsx(
        hidden && styles.containerHidden,
        !hidden && (noHover ? styles.container : styles.containerWithHover)
      )}
      aria-label="Drag handle"
    >
      <svg
        width="17"
        height="20"
        viewBox="0 0 17 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="6.02344" cy="3.5" r="1.5" fill="#B7C0D0" />
        <circle cx="11.0234" cy="3.5" r="1.5" fill="#B7C0D0" />
        <circle cx="6.02344" cy="9.5" r="1.5" fill="#B7C0D0" />
        <circle cx="11.0234" cy="9.5" r="1.5" fill="#B7C0D0" />
        <circle cx="6.02344" cy="15.5" r="1.5" fill="#B7C0D0" />
        <circle cx="11.0234" cy="15.5" r="1.5" fill="#B7C0D0" />
      </svg>
    </button>
  )
);
