import { styled } from "styled-components";

import { LinkButton } from "@vericus/cadmus-ui";

import moment from "moment";

import { Step } from "./internal";

interface Props {
  /** Current step */
  step: Step;
  /** Final due date */
  dueDate: moment.Moment | null;
  /** Change the current step's date */
  setDate: (date: moment.Moment | null) => void;
  /** Callback to jump the visible month to today */
  jumpToToday(): void;
}
/**
 * Date jump actions for the currently rendered `<DatePicker />`.
 */
export function QuickOptions(props: Props) {
  const { step, dueDate, setDate, jumpToToday } = props;
  if (step === Step.Final) {
    return (
      <>
        <StyledLinkButton marginRight={18} onClick={jumpToToday}>
          Jump to this month
        </StyledLinkButton>
        <StyledLinkButton
          marginRight={18}
          onClick={() => setDate(moment().add(2, "w"))}
        >
          2 weeks from today
        </StyledLinkButton>
        <StyledLinkButton onClick={() => setDate(moment().add(4, "w"))}>
          4 weeks from today
        </StyledLinkButton>
      </>
    );
  }

  if (step === Step.Return) {
    return (
      <>
        <StyledLinkButton marginRight={18} onClick={jumpToToday}>
          Jump to this month
        </StyledLinkButton>
        <StyledLinkButton
          marginRight={18}
          onClick={() => setDate(moment(dueDate ?? undefined).add(1, "w"))}
        >
          1 week <em>after</em> final
        </StyledLinkButton>
        <StyledLinkButton
          onClick={() => dueDate && setDate(moment(dueDate).add(2, "w"))}
        >
          2 weeks <em>after</em> final
        </StyledLinkButton>
      </>
    );
  }

  const oneWeekBeforeFinal = dueDate && moment(dueDate).subtract(1, "w");
  const twoWeeksBeforeFinal = dueDate && moment(dueDate).subtract(2, "w");

  return (
    <>
      <StyledLinkButton marginRight={18} onClick={jumpToToday}>
        Jump to this month
      </StyledLinkButton>
      <StyledLinkButton
        marginRight={18}
        onClick={() => oneWeekBeforeFinal && setDate(oneWeekBeforeFinal)}
        // disabled if it's not a viable option
        disabled={!oneWeekBeforeFinal || !oneWeekBeforeFinal.isAfter(moment())}
        aria-disabled={
          !oneWeekBeforeFinal || !oneWeekBeforeFinal.isAfter(moment())
            ? "true"
            : "false"
        }
      >
        1 week <em>before</em> final
      </StyledLinkButton>
      <StyledLinkButton
        onClick={() => twoWeeksBeforeFinal && setDate(twoWeeksBeforeFinal)}
        // disabled if it's not a viable option
        disabled={
          !twoWeeksBeforeFinal || !twoWeeksBeforeFinal.isAfter(moment())
        }
        aria-disabled={
          !twoWeeksBeforeFinal || !twoWeeksBeforeFinal.isAfter(moment())
            ? "true"
            : "false"
        }
      >
        <span>
          2 weeks <em>before</em> final
        </span>
      </StyledLinkButton>
    </>
  );
}

const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
`;
