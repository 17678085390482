import { useEffect, useRef } from "react";
import { styled } from "styled-components";

import { colors, Icon } from "@vericus/cadmus-ui";

import { TimeInput, TimeInputProps } from "@mantine/dates";
import { add, format } from "date-fns";

/**
 * Our props give us more useful objects to play with than the original
 */
interface TimePickerProps extends Omit<TimeInputProps, "value" | "onChange"> {
  /**
   * The current time value, represented in Duration.
   */
  value?: Duration;

  /**
   * Callback for when the time value changes
   * @param value {Duration} The new time value
   */
  onChange: (value: Duration) => void;
  /** Whether input is invalid */
  withError?: boolean;
}

/**
 * A time picker input that uses the Mantine TimeInput component, themed
 * and styled for Cadmus. The component under the hood gives us back a string.
 * This wrapper helps us convert that string into a Duration.
 */
export const TimePickerInput = (props: TimePickerProps) => {
  const { onBlur, onChange, value, withError, ...rest } = props;

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current && value) {
      // Use a fake date for date-fn.format'ing purposes
      ref.current.value = format(
        add(new Date(1990, 1, 1, 0, 0, 0), value),
        "HH:mm"
      );
    }
  }, [value]);

  return (
    <TimeInput
      ref={ref}
      onBlur={onBlur}
      onChange={(event) => {
        if (!ref.current) {
          return null;
        }

        let input = event.target.value;
        /* When user starts typing with '0' -> input becomes an empty string */
        if (input === "") input = `00:${value?.minutes ?? "00"}`;

        const [hours, minutes] = input.split(":");

        if (hours && minutes) {
          const duration: Duration = {
            hours: parseInt(hours),
            minutes: parseInt(minutes),
          };

          onChange(duration);
        }
      }}
      styles={{
        input: {
          border: `1px solid ${withError ? colors.functionalFail : colors.grey400}`,
          borderRadius: "2px",
          padding: "8px 12px",
        },
      }}
      rightSection={
        <StyledIcon
          onClick={() => {
            ref.current?.showPicker();
          }}
          iconName={"Time"}
        />
      }
      {...rest}
    />
  );
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
