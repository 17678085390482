import { DropdownMenu, Toolbar } from "@vericus/cadmus-ui";

import { ReleasedRequirementsFragment } from "@/generated/graphql";
import { ExtensionType } from "@/ui/class/progress/pages/StudentsSettingsPage";
import { StudentListMeta, StudentListRow } from "@/ui/class/progress/types";

interface Props {
  renderControl: (label: string, onClick?: () => void) => JSX.Element;
  /**
   * Currently selected NON-EMPTY list of rows data on which the bulk actions
   * are applied
   */
  rows: StudentListRow[];
  sheet?: ReleasedRequirementsFragment;
  editExtensions: StudentListMeta["editExtensions"];
  clearExtensions: StudentListMeta["clearExtensions"];
  selectedAll?: boolean;
}

/**
 * BulkActionsMenu's sub-menu item for extensions editing in bulk.
 */
export const ExtensionBulkMenuItem = (props: Props) => {
  const {
    renderControl,
    rows,
    sheet,
    editExtensions,
    clearExtensions,
    selectedAll,
  } = props;
  const finalExtended = rows.some(
    (row) => row.settings.finalExtension !== null
  );
  const timeExtended = rows.some((row) => row.settings.timeExtension !== null);
  const extended = finalExtended || timeExtended;
  const hasTimeLimit = !!sheet?.timeLimit;

  const enrollments = rows.map((row) => row.enrollment);
  const label = `${extended ? "Update" : "Set"} extension`;

  return (
    <DropdownMenu.Root>
      <Toolbar.Button asChild>
        <DropdownMenu.Trigger asChild>
          {renderControl(label)}
        </DropdownMenu.Trigger>
      </Toolbar.Button>
      <DropdownMenu.Content>
        <DropdownMenu.Label>Set Extension</DropdownMenu.Label>
        <DropdownMenu.Item
          iconName="Calendar"
          content={`${finalExtended ? "Update" : "Extend"} due date`}
          onSelect={() =>
            editExtensions(enrollments, ExtensionType.DueDate, selectedAll)
          }
        />
        {finalExtended && (
          <DropdownMenu.Item
            iconName="Trash"
            content="Remove due date extension"
            onSelect={() => clearExtensions(enrollments, ExtensionType.DueDate)}
          />
        )}
        {hasTimeLimit && (
          <>
            <DropdownMenu.Separator />
            <DropdownMenu.Item
              iconName="Time"
              content={`${timeExtended ? "Update" : "Extend"} time limit`}
              onSelect={() =>
                editExtensions(enrollments, ExtensionType.TimeLimit)
              }
            />
            {timeExtended && (
              <DropdownMenu.Item
                iconName="Trash"
                content="Remove time limit extension"
                onSelect={() =>
                  clearExtensions(enrollments, ExtensionType.TimeLimit)
                }
              />
            )}
          </>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
