import { DropdownMenu } from "@vericus/cadmus-ui";

import { Column } from "@tanstack/react-table";

import {
  disableFilter,
  FilterValue,
  PredicateFilter,
  ProgressFlags,
  StudentListRow,
} from "@/ui/class/progress/types";

import {
  PredicateDropdownItem,
  PredicateFilterDropdown,
  useToggleFilter,
} from "./PredicateFilterDropdown";

interface ProgressFilterProps {
  column: Column<StudentListRow>;
  /**
   * Whether feedback filters should be displayed at all.
   */
  showFeedbackFilters: boolean;
  /** Drafting is enabled in the assessment */
  hasDrafting: boolean;
  /** Assessment has time limits @default false */
  hasTimeLimit?: boolean;
  /**
   * Whether the assessment is an exam or not
   * @default false
   */
  isExam?: boolean;
  /**
   * Whether the assessment is a window exam or not
   * @default false
   */
  isWindowExam?: boolean;
  isBeforeFinalDueDate?: boolean;
  isBeforeDraftDueDate?: boolean;
  enabledAccessCode?: boolean;
}

export type ProgressFilterId =
  | "in-progress"
  | "viewed-only"
  | "has-extension"
  | "has-extra-time"
  | "late-draft-submission"
  | "has-alternative-window"
  | "has-alternative-start"
  | "submitted-final"
  | "submitted-draft"
  | "late-final"
  | "overtime-final"
  | "graded"
  | "not-graded"
  | "feedback-viewed"
  | "deferred"
  | "withdrawn"
  | "access-code";

/** Used to provide different labels under different conditions. */
type GetFilter = (hasDraftingOn: boolean) => PredicateFilter;

export const progressFilters: Record<ProgressFilterId, GetFilter> = {
  "viewed-only": () => ({
    label: "Viewed only",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) &&
      value.accessed &&
      !(value.started || value.draft || value.final),
  }),
  "in-progress": () => ({
    label: "Writing in progress",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && value.started && !value.final,
  }),
  "has-extension": () => ({
    label: "Has extension",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && value.extension,
  }),
  "has-extra-time": () => ({
    label: "Has extra time",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && value.hasExtraTime,
  }),
  "late-draft-submission": () => ({
    label: "Late draft submissions",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && value.lateDraft,
  }),
  "has-alternative-window": () => ({
    label: "Has alternative exam window",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && value.hasAlternativeWindow,
  }),
  "access-code": () => ({
    label: "Used access code",
    predicate: (value: ProgressFlags) => value.accessCode,
  }),
  "has-alternative-start": () => ({
    label: "Has alternative exam start",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && value.hasAlternativeStart,
  }),
  "submitted-final": (hasDraftingOn) => ({
    label: hasDraftingOn ? "Submitted final" : "Submitted",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && value.final,
  }),
  "submitted-draft": () => ({
    label: "Submitted draft",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && value.draft,
  }),
  "late-final": () => ({
    label: "Late final submissions",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && value.lateFinal,
  }),
  "overtime-final": () => ({
    label: "Overtime final submissions",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && value.overtimeFinal,
  }),
  graded: () => ({
    label: "Marked",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && value.graded,
  }),
  "not-graded": () => ({
    label: "Not yet marked",
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && !value.graded,
  }),
  "feedback-viewed": () => ({
    label: `Feedback viewed`,
    predicate: (value: ProgressFlags) =>
      !(value.withdrawn || value.deferred) && value.feedbackViews > 0,
  }),
  deferred: () => ({
    label: "Deferred",
    predicate: (value: ProgressFlags) => value.deferred === true,
  }),
  withdrawn: () => ({
    label: "Withdrawn",
    predicate: (value: ProgressFlags) => value.withdrawn === true,
  }),
};

/**
 * Dropdown filters for student progress.
 */
export const ProgressFiltersDropdown = ({
  column,
  hasDrafting,
  hasTimeLimit = false,
  isExam = false,
  isWindowExam = false,
  showFeedbackFilters,
  isBeforeDraftDueDate,
  isBeforeFinalDueDate,
  enabledAccessCode,
}: ProgressFilterProps) => {
  const activeFilters = (column.getFilterValue() as FilterValue) ?? [];
  const getFilter = (filterId: ProgressFilterId): PredicateFilter =>
    progressFilters[filterId](hasDrafting);
  const isFilterSelected = (filterId: ProgressFilterId) =>
    activeFilters.find((f) => f.label === getFilter(filterId).label) !==
    undefined;
  const toggleFilter = useToggleFilter(column, "Progress status");

  // Dynamicallly decide the filters
  const items = (
    <>
      <PredicateDropdownItem
        predicate={getFilter("viewed-only")}
        selected={isFilterSelected("viewed-only")}
        onToggle={toggleFilter}
      />
      {isExam && enabledAccessCode && (
        <PredicateDropdownItem
          predicate={getFilter("access-code")}
          selected={isFilterSelected("access-code")}
          onToggle={toggleFilter}
        />
      )}
      <PredicateDropdownItem
        predicate={getFilter("in-progress")}
        selected={isFilterSelected("in-progress")}
        onToggle={toggleFilter}
      />
      {!isExam && hasDrafting && (
        <PredicateDropdownItem
          predicate={getFilter("submitted-draft")}
          selected={isFilterSelected("submitted-draft")}
          onToggle={toggleFilter}
        />
      )}
      <PredicateDropdownItem
        predicate={getFilter("submitted-final")}
        selected={isFilterSelected("submitted-final")}
        onToggle={toggleFilter}
      />
      {showFeedbackFilters && (
        <PredicateDropdownItem
          predicate={getFilter("feedback-viewed")}
          selected={isFilterSelected("feedback-viewed")}
          onToggle={toggleFilter}
        />
      )}

      <DropdownMenu.Separator />

      {!isExam && (
        <PredicateDropdownItem
          predicate={getFilter("has-extension")}
          selected={isFilterSelected("has-extension")}
          onToggle={toggleFilter}
        />
      )}
      {!isExam && hasDrafting && (
        <PredicateDropdownItem
          predicate={disableFilter(
            getFilter("late-draft-submission"),
            isBeforeDraftDueDate
          )}
          selected={isFilterSelected("late-draft-submission")}
          onToggle={toggleFilter}
        />
      )}
      {!isExam && (
        <PredicateDropdownItem
          predicate={disableFilter(
            getFilter("late-final"),
            isBeforeFinalDueDate
          )}
          selected={isFilterSelected("late-final")}
          onToggle={toggleFilter}
        />
      )}
      {!isExam && hasTimeLimit && (
        <PredicateDropdownItem
          predicate={getFilter("overtime-final")}
          selected={isFilterSelected("overtime-final")}
          onToggle={toggleFilter}
        />
      )}

      {isExam && (
        <PredicateDropdownItem
          predicate={getFilter("has-extra-time")}
          selected={isFilterSelected("has-extra-time")}
          onToggle={toggleFilter}
        />
      )}

      {isExam && isWindowExam && (
        <PredicateDropdownItem
          predicate={getFilter("has-alternative-window")}
          selected={isFilterSelected("has-alternative-window")}
          onToggle={toggleFilter}
        />
      )}
      {isExam && !isWindowExam && (
        <PredicateDropdownItem
          predicate={getFilter("has-alternative-start")}
          selected={isFilterSelected("has-alternative-start")}
          onToggle={toggleFilter}
        />
      )}

      <DropdownMenu.Separator />

      {isExam && (
        <PredicateDropdownItem
          predicate={getFilter("deferred")}
          selected={isFilterSelected("deferred")}
          onToggle={toggleFilter}
        />
      )}

      <PredicateDropdownItem
        predicate={getFilter("withdrawn")}
        selected={isFilterSelected("withdrawn")}
        onToggle={toggleFilter}
      />

      <DropdownMenu.Separator />
    </>
  );

  return (
    <PredicateFilterDropdown
      column={column}
      label="Progress status"
      items={items}
    />
  );
};
