import { ReactNode } from "react";

import { formatReferencingStyle } from "@vericus/cadmus-common";

import { useAppSelector } from "@/data/hooks";
import { selectTaskTotalPoints } from "@/features/multi-format/task-layout";
import { TaskFormat } from "@/generated/graphql";

import { RequirementRow } from "./shared";

/** Display the weighting requirement. */
export function Weight(props: { weight: number; hasChanged?: boolean }) {
  return (
    <RequirementRow
      title="Weighting"
      text={`${props.weight}% of the unit`}
      hasChanged={props.hasChanged}
    />
  );
}

/** Display the word limit requirement. */
export function WordLimit(props: {
  wordLimit: number;
  countReferences: boolean;
  hasChanged?: boolean;
  hideIncludeReferences?: boolean;
}) {
  const formattedWords = `${props.wordLimit} word${
    props.wordLimit !== 1 ? "s" : ""
  }`;
  const text = props.hideIncludeReferences
    ? formattedWords
    : `${formattedWords}; ${
        props.countReferences ? "including references" : "excluding references"
      }`;
  return (
    <RequirementRow
      title="Word limit"
      text={text}
      hasChanged={props.hasChanged}
    />
  );
}

/** Display the referencing style requirement. */
export function ReferencingStyle(props: {
  referencingStyle: string;
  hasChanged?: boolean;
}) {
  const formatted = formatReferencingStyle(props.referencingStyle);
  return (
    <RequirementRow
      title="Referencing"
      text={`You are required to follow ${formatted}`}
      hasChanged={props.hasChanged}
    />
  );
}

/** Show Whether the anonymous marking is turned on or off.   */
export function AnonymousMarking(props: {
  anonymousMarking: boolean | null;
  hasChanged?: boolean;
}) {
  return props.anonymousMarking ? (
    <RequirementRow
      title="Anonymous Marking"
      text="Hide student names"
      hasChanged={props.hasChanged}
    />
  ) : null;
}

/** Shows the selecting grading service.   */
export function GradingService(props: {
  gradingService: string;
  hasChanged?: boolean;
}) {
  let serviceName: string | null = null;
  if (props.gradingService === "speedgrader") {
    serviceName = "Canvas SpeedGrader";
  }
  if (props.gradingService === "turnitin") {
    serviceName = "Turnitin Feedback Studio";
  }
  if (props.gradingService === "moodlegrader") {
    serviceName = "Moodle Grader";
  }
  if (props.gradingService === "cadmusgrader") {
    serviceName = "Cadmus Grader";
  }

  return props.gradingService && serviceName ? (
    <RequirementRow
      title="Marking Tool"
      text={serviceName}
      hasChanged={props.hasChanged}
    />
  ) : null;
}

/** Show the possible max points requirement, or the default.   */
export function MaxGrade(props: {
  maxGrade: number | null;
  errorMessage?: ReactNode;
  hasChanged?: boolean;
  format?: TaskFormat;
}) {
  const taskTotalPoints = useAppSelector(selectTaskTotalPoints);
  const points =
    props.format === TaskFormat.Multiformat
      ? taskTotalPoints
      : props.maxGrade === null
        ? 100
        : props.maxGrade;
  const text =
    props.errorMessage ?? `The assessment is out of ${points} points`;
  return (
    <RequirementRow
      title="Points"
      text={text}
      hasError={!!props.errorMessage}
      hasChanged={props.hasChanged}
    />
  );
}
