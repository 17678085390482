import { useCallback } from "react";

import { useLocation } from "react-router-dom";

import { useRootNavigate } from "@/router/routing";

import { Step } from "./TaskTimeline";

/**
 * Router location state flags that can be optionally set while navigating to
 * the SettingsPage.
 * */
export interface SettingsLocationState {
  /** Arrived to current page from the Progress page */
  fromProgressPage?: boolean;
  /** Arrived to current page from the Home/Instructions page */
  fromHomePage?: boolean;
  /** Arrived to current page from the Task Release page */
  fromReleasePage?: boolean;
  /** Set the initial Assignment date step using the Settings timeline view. */
  initialTimelineStep?: Step;
}

/**
 * Typecast the current router location state with SettingsPage known flags.
 */
export function useSettingsLocationState(): SettingsLocationState | undefined {
  const location = useLocation();
  const state = location.state as SettingsLocationState | undefined;
  return state;
}

/**
 * Hook to create confirm and cancel navigation callbacks for the Settings page,
 * depending on the current location state that was set when the user arrived on
 * the current page.
 */
export function useSettingsNavigation() {
  const rootNavigate = useRootNavigate();

  // Parsing location state
  const locationState = useSettingsLocationState();
  const fromProgressPage = locationState?.fromProgressPage ?? false;
  const fromHomePage = locationState?.fromHomePage ?? false;
  const fromReleasePage = locationState?.fromReleasePage ?? false;

  const goProgress = useCallback(() => {
    rootNavigate("/class/progress");
  }, [rootNavigate]);

  const goHome = useCallback(() => {
    rootNavigate("/task");
  }, [rootNavigate]);

  const goRelease = useCallback(() => {
    rootNavigate("/task/release", {
      state: {
        fromProgressPage,
        fromHomePage,
      },
    });
  }, [fromProgressPage, fromHomePage, rootNavigate]);

  const goEdit = useCallback(() => {
    rootNavigate("/task/edit");
  }, [rootNavigate]);

  const onConfirm = useCallback(() => {
    if (fromProgressPage || fromHomePage || fromReleasePage) {
      goRelease();
    } else {
      goEdit();
    }
  }, [fromProgressPage, fromHomePage, fromReleasePage, goRelease, goEdit]);

  const onCancel = useCallback(() => {
    if (fromProgressPage) return goProgress();
    if (fromHomePage) return goHome();
    return goEdit();
  }, [fromProgressPage, fromHomePage, goProgress, goHome, goEdit]);

  return {
    onConfirm,
    onCancel,
  };
}
