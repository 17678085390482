import { Fragment } from "react";
import { styled } from "styled-components";

import {
  DesktopAndTablet,
  FilterPill,
  MobileOnly,
  Skeleton,
} from "@vericus/cadmus-ui";

import { TemplateFilter } from "@/data/templates";

export interface TemplatesFiltersProps {
  activeFilter?: TemplateFilter;
  /** The filters that the user can select from. */
  availableFilters: readonly TemplateFilter[];
  onFilterSelected: (newFilter: TemplateFilter) => void;
}

export const TemplatesFilters = ({
  activeFilter,
  availableFilters,
  onFilterSelected,
}: TemplatesFiltersProps) => {
  return (
    <Container>
      {availableFilters.map((filter) => (
        <Fragment key={filter}>
          <MobileOnly>
            <FilterPill
              text={formatFilter[filter]}
              size={"small"}
              onClick={() => onFilterSelected(filter)}
              isActive={filter === activeFilter}
            />
          </MobileOnly>
          <DesktopAndTablet>
            <FilterPill
              text={formatFilter[filter]}
              size={"large"}
              onClick={() => onFilterSelected(filter)}
              isActive={filter === activeFilter}
            />
          </DesktopAndTablet>
        </Fragment>
      ))}
    </Container>
  );
};

const FilterPillSkeleton = styled(Skeleton)`
  border-radius: 100px;
  display: inline;
  height: 36px;
`;

export const TemplatesFiltersSkeleton = ({ filters }: { filters: number }) => {
  const filterSkeletons = Array(filters).fill("_");
  return (
    <Container>
      {filterSkeletons.map((_, index) => (
        <FilterPillSkeleton key={index} width={22} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const formatFilter: Record<TemplateFilter, string> = {
  all: "All assessments",
  assignments: "Assignments",
  "formative-tasks": "Formative tasks",
  "timed-assessments": "Timed assessments",
};
