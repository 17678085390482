import React, { ReactNode, useCallback } from "react";
import { styled } from "styled-components";

import { colors, Icon, Text, Tooltip } from "@vericus/cadmus-ui";

import { useJitsu } from "@jitsu/react";
import { Column } from "@tanstack/react-table";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { StudentListRow } from "@/ui/class/progress/types";

interface Props {
  /** Button contents */
  children: ReactNode;
  /** Table column with sorting helpers */
  column: Column<StudentListRow, unknown>;
  /** Optional button tooltip */
  tooltip?: ReactNode;
  /** Optional tooltip size */
  tooltipSize?: "sm" | "md";
  /** Optional tooltip width */
  tooltipWidth?: "auto" | number;
}

/**
 * Button for toggling sorting on a column.
 */
export const SortHeaderButton = (props: Props) => {
  const {
    children,
    column,
    tooltip,
    tooltipSize = "sm",
    tooltipWidth = "auto",
  } = props;
  const { track } = useJitsu();
  const onToggle = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!column.getCanSort()) return;
      const sortingOrder = column.getNextSortingOrder();
      column.getToggleSortingHandler()?.(e);
      track(JitsuEvent.CLASS_SORTING_UPDATED, {
        column: column.id,
        sorting_order: !sortingOrder ? "unsorted" : sortingOrder,
      });
    },
    [column, track]
  );
  const button = (
    <ButtonRoot onClick={onToggle}>
      <Text kind="label">{children}</Text> <SortCaret column={column} />
    </ButtonRoot>
  );

  if (tooltip) {
    return (
      <Tooltip size={tooltipSize} width={tooltipWidth} content={tooltip}>
        {button}
      </Tooltip>
    );
  }

  return button;
};

const ButtonRoot = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: -4px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  fill: ${colors.grey500};

  &:not(:disabled) {
    &:hover {
      background-color: ${(p) => p.theme.background.action1};
    }

    &:focus,
    &:active {
      background-color: ${(p) => p.theme.background.action2};
    }
  }
`;

/**
 * Render a sorting caret based on the column's current sort state.
 */
function SortCaret({ column }: { column: Column<StudentListRow, unknown> }) {
  const sorted = column.getIsSorted();

  if (sorted === false) {
    return <Icon iconName="UpCaret" iconColor="grey400" />;
  }

  if (sorted === "asc") {
    return <ColoredIcon iconName="UpCaret" />;
  }

  if (sorted === "desc") {
    return <ColoredIcon iconName="DownCaret" />;
  }

  return null;
}

const ColoredIcon = styled(Icon)`
  fill: ${(p) => p.theme.colors.lilac500};
`;
