import { ReactNode } from "react";
import { styled } from "styled-components";

import { Colored, colors, LinkButton, Text } from "@vericus/cadmus-ui";

export interface IRequirementRowProps {
  title: string;
  text: ReactNode;
  hasChanged?: boolean;
  hasError?: boolean;
}

export interface IActionableRequirementRow extends IRequirementRowProps {
  /**
   * Callback for the action button.
   *
   * When this is not set, the button is rendered as disabled.
   */
  onAction?: () => void;
}

// Generic display of a single Requirement row.
export const RequirementRow = (props: IRequirementRowProps) => (
  <Row>
    <Label>
      <Text kind="bodySm">
        <strong>
          {props.title}
          {props.hasChanged && <Colored color="redA500">*</Colored>}
        </strong>
      </Text>
    </Label>
    <Text
      kind="bodySm"
      style={{ color: props.hasError ? colors.red500 : undefined }}
    >
      {props.text}
    </Text>
  </Row>
);

// Generic display of a single Requirement row, with the option to action on it.
export const ActionableRequirementRow = (props: IActionableRequirementRow) => (
  <Row>
    <Label>
      <Text kind="bodySm">
        <strong>
          {props.title}{" "}
          {props.hasChanged && <Colored color="redA500">*</Colored>}
        </strong>
      </Text>
    </Label>
    <StyledLinkButton
      disabled={!props.onAction}
      kind="primary"
      onClick={props.onAction}
    >
      {props.text}
    </StyledLinkButton>
  </Row>
);

const Row = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
  gap: 20px;
`;

const Label = styled.div`
  flex: none;
  width: 140px;
`;

const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
`;
