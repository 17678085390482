import { styled } from "styled-components";

import { Color, colors, Text } from "@vericus/cadmus-ui";

import { format } from "date-fns";

import { dateColors } from "./utils";

interface Props {
  dueDate: Date | null;
  returnDate: Date | null;

  /**
   * undefined means don't show while null
   * means date is not defined yet
   */
  draftDueDate?: Date | null;
  extension?: Date | null;
  scheduledDate?: Date | null;
}

/**
 * Legend for Date Picker.
 */
export const Legend = (props: Props) => {
  const dateProps = sortedDateProps(props);

  return (
    <Container>
      {dateProps.map((dp, i) => (
        <SingleDate key={i} {...dp} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 72px;

  @media screen and (max-width: 900px) {
    margin-top: 18px;
  }
`;

interface SingleDateProps {
  date?: Date | null;
  color: Color;
  label: string;
}
/**
 * A single date legend with colored dot, label, and
 *  formatted date if defined
 */
const SingleDate = (props: SingleDateProps) => {
  const { date, color, label } = props;
  if (date === undefined) return null;

  return (
    <SingleDateContainer>
      <Label kind="bodySm">
        <Flag color={color} />
        {label}
      </Label>
      <DateLabel kind="bodySm" italic textAlign="right" color="shade1">
        {date ? format(date, "do MMMM, h:mma") : "Unset"}
      </DateLabel>
    </SingleDateContainer>
  );
};

const SingleDateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Flag = styled.span<{ color: Color }>`
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-right: 9px;

  border-radius: 50%;
  background: ${(p) => colors[p.color]};
`;

const Label = styled(Text)`
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DateLabel = styled(Label)`
  margin-left: 9px;
`;

// ------------------------- helper functions -------------------------

/**
 * Parse dates, attach extra information, and sort by date
 * also includes today.
 */
const sortedDateProps = (props: Props): SingleDateProps[] => {
  const { dueDate, returnDate, draftDueDate, extension, scheduledDate } = props;

  const datesWithExtraData: Array<{
    date: Date | null;
    color: Color;
    label: string;
  }> = [
    {
      date: new Date(),
      color: dateColors.today,
      label: "Today",
    },
    {
      date: dueDate,
      color: dateColors.dueDate,
      label: "Final due date",
    },
    {
      date: returnDate,
      color: dateColors.returnDate,
      label: "Feedback return date",
    },
  ];

  draftDueDate !== undefined &&
    datesWithExtraData.push({
      date: draftDueDate,
      color: dateColors.draftDueDate,
      label: "Draft due date",
    });

  extension !== undefined &&
    datesWithExtraData.push({
      date: extension,
      color: dateColors.extension,
      label: "Extension date",
    });

  scheduledDate !== undefined &&
    datesWithExtraData.push({
      date: scheduledDate,
      color: dateColors.scheduledDate,
      label: "Scheduled date",
    });

  return datesWithExtraData.sort((a, b) => {
    if (!b.date) return -1;
    if (!a.date) return 1;

    return a.date.getTime() - b.date.getTime();
  });
};
