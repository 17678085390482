import { useCallback, useTransition } from "react";
import { styled } from "styled-components";

import { Text } from "@vericus/cadmus-ui";

import { useJitsu } from "@jitsu/react";

import { JitsuEvent } from "@/data/events/JitsuEvent";

import { ClassTable } from "./table";

interface Props {
  table: ClassTable;
}

export const FiltersSummary = ({ table }: Props) => {
  const [isTransitioning, startTransition] = useTransition();
  const { track } = useJitsu();
  const onClearFilters = useCallback(() => {
    startTransition(() => {
      table.resetColumnFilters();
      table.setGlobalFilter("");
      track(JitsuEvent.CLASS_FILTERS_CLEARED);
    });
  }, [table, track]);
  return (
    <SummaryContainer
      isShowing={hasFilters(table)}
      opacity={isTransitioning ? 0.5 : undefined}
    >
      <Text kind="bodySm" color="grey500">
        {displayMatchedStudents(table)}
      </Text>
      <Text kind="bodySm" color="grey500">
        ·
      </Text>
      <ClearFiltersButton onClick={onClearFilters}>
        <Text kind="bodySm" color="lilac500">
          Clear filters
        </Text>
      </ClearFiltersButton>
    </SummaryContainer>
  );
};

const hasFilters = (table: ClassTable) => {
  const state = table.getState();
  return state.columnFilters.length > 0 || state.globalFilter;
};

const displayMatchedStudents = (table: ClassTable) => {
  const filteredStudents = table.getRowModel().rows.length;
  if (filteredStudents === 0) return "No students found";
  if (filteredStudents === 1) return "1 student found";
  return `${filteredStudents} students found`;
};

const SummaryContainer = styled.div<{ isShowing: boolean; opacity?: number }>`
  box-sizing: border-box;
  display: ${(p) => (p.isShowing ? "flex" : "none")};
  align-items: center;
  gap: 8px;
  padding-left: 44px;
  height: 36px;
  opacity: ${(p) => p.opacity};
`;

const ClearFiltersButton = styled.button`
  display: inline;
  border: none;
  background: none;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;
