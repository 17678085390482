import { styled } from "styled-components";

import { OverviewQuestionBody } from "./overview-question-body";

interface OverviewPlaceHolderProps {
  onAddOverview: () => void;
}

export const OverviewPlaceholder = ({
  onAddOverview,
}: OverviewPlaceHolderProps) => {
  return (
    <Root>
      <OverviewQuestionBody
        editor={null}
        hidden={true}
        dispatch={onAddOverview}
      />
    </Root>
  );
};

const Root = styled.div`
  max-width: 882px;
  width: 100%;
  padding: 28px 20px;
  background: ${(p) => p.theme.colors.white100};
  box-sizing: border-box;
  border: 1px solid transparent;
`;
