import { Desk } from "@vericus/cadmus-ui";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import {
  AssessmentFragment,
  EnrollmentFragment,
  GroupFragment,
  InstructionSheetFragment,
} from "@/generated/graphql";
import {
  ExtensionType,
  StudentsSettingsPage,
} from "@/ui/class/progress/pages/StudentsSettingsPage";

/**
 * Describes which enrollments the user wants to grant some extension on.
 */
export interface ExtensionGrantIntent {
  // Enrollments selected for extension granting
  enrollments: EnrollmentFragment[];
  // Type of extension that the user wants to grant
  type: ExtensionType;
}

export interface ConnectedStudentSettingsPageModalProps {
  assessment: Pick<AssessmentFragment, "id">;
  groups: GroupFragment[];
  intent: ExtensionGrantIntent;
  enrollments: EnrollmentFragment[];
  selectedAll?: boolean;
  instructionSheet: Pick<
    InstructionSheetFragment,
    "dueDate" | "returnDate" | "timeLimit"
  >;
}

export const ConnectedStudentSettingsPageModal =
  NiceModal.create<ConnectedStudentSettingsPageModalProps>((props) => {
    const { assessment, intent, groups, enrollments, instructionSheet } = props;
    const modal = useModal();
    if (!modal.visible) return null;
    return (
      <Desk.Modal>
        <StudentsSettingsPage
          assessmentId={assessment.id}
          enrollments={intent.enrollments}
          extensionType={intent.type}
          dueDate={instructionSheet.dueDate}
          returnDate={instructionSheet.returnDate}
          timeLimit={instructionSheet.timeLimit}
          onClose={() => modal.remove()}
          selectedAll={props.selectedAll}
          groups={groups}
          activeStudentNums={
            enrollments.filter(
              ({ deleted, workSettings }) =>
                !deleted && !workSettings?.examDeferred
            ).length
          }
        />
      </Desk.Modal>
    );
  });
