import { useCallback, useMemo } from "react";
import { styled } from "styled-components";

import {
  Button,
  Card,
  DropdownMenu,
  Popover,
  SelectButton,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import { useJitsu } from "@jitsu/react";
import { GroupFragment } from "generated/graphql";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { useRootNavigate } from "@/router/routing";

import { ClassTable } from "../../table";
import { useOnClearFilter } from "../hooks";

interface Props {
  table: ClassTable;
  groups: GroupFragment[];
}

/**
 * Dropdown filters for groups.
 */
export const GroupFilter = ({ table, groups }: Props) => {
  const { track } = useJitsu();
  const column = table.getColumn("group")!;
  const selectedGroup = column.getFilterValue() as string | null | undefined;
  const onClear = useOnClearFilter(column, "Group");

  const setFilter = useCallback(
    (group: string | null) => {
      const selectedGroup = column.getFilterValue() as
        | string
        | null
        | undefined;
      if (group === selectedGroup) {
        onClear();
      } else {
        column.setFilterValue(group);
        track(JitsuEvent.CLASS_FILTER_UPDATED, {
          filter_group: "Group",
          filter_type: "dropdown",
          filter_value: group,
        });
      }
    },
    [track, column, onClear]
  );

  const numberOfUngroupedStudents = useMemo(
    () =>
      column
        .getFacetedRowModel()
        .flatRows.filter(
          ({ original: { withdrawn, deferred, group } }) =>
            !(withdrawn || deferred) && !group
        ).length,
    [column]
  );

  const label =
    selectedGroup === undefined
      ? "Marking group"
      : selectedGroup === null
        ? "Ungrouped students"
        : selectedGroup;

  if (groups.length === 0) {
    return <EmptyGroups />;
  }

  // Unique counts of the group column
  const uniqueGroups = column.getFacetedUniqueValues() as Map<
    string | null,
    number
  >;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <SelectButton
          data-testid={"GroupFilter"}
          data-component={"GroupFilter.marking-group"}
          selected={column.getIsFiltered()}
          activeColor="lilac500"
          style={{ minWidth: 160 }}
        >
          {label}
        </SelectButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Item
          onSelect={() => setFilter(null)}
          selected={selectedGroup === null}
        >
          <StudentGroupContainer>
            <Text kind="bodyLine" textAlign="left" color="black100">
              Students not in a group
            </Text>
            <Text kind="systemMd" textAlign="left" color="grey500">
              {numberOfUngroupedStudents}{" "}
              {numberOfUngroupedStudents === 1 ? "student" : "students"}
            </Text>
          </StudentGroupContainer>
        </DropdownMenu.Item>

        <DropdownMenu.Separator />

        {groups
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((group) => {
            const numberOfStudents = uniqueGroups.get(group.name) ?? 0;
            const studentPostfix =
              numberOfStudents === 1 ? "student" : "students";
            return (
              <DropdownMenu.Item
                key={group.id}
                onSelect={() => setFilter(group.name)}
                selected={group.name === selectedGroup}
              >
                <StudentGroupContainer>
                  <Text kind="bodyLine" textAlign="left" color="black100">
                    {group.name}
                  </Text>
                  <Text kind="systemMd" textAlign="left" color="grey500">
                    {numberOfStudents} {studentPostfix}
                  </Text>
                </StudentGroupContainer>
              </DropdownMenu.Item>
            );
          })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const EmptyGroups = () => {
  const rootNavigate = useRootNavigate();
  const goToGroupsPage = useCallback(() => {
    rootNavigate("/class/marking/groups");
  }, [rootNavigate]);

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <SelectButton
          data-testid={"GroupFilter"}
          data-component={"GroupFilter.marking-group"}
        >
          Marking group
        </SelectButton>
      </Popover.Trigger>
      <Popover.Content>
        <EmptyGroupsCard>
          <Text kind="headingSix">Marking Groups</Text>
          <Text kind="bodySm">
            Large class? Set up marking groups to quickly filter the class list
            by group name.
          </Text>
          <Spacer spacing={24} />
          <Button kind="primary" onClick={() => goToGroupsPage()}>
            Set Up Marking Groups
          </Button>
        </EmptyGroupsCard>
      </Popover.Content>
    </Popover.Root>
  );
};

const StudentGroupContainer = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const EmptyGroupsCard = styled(Card)`
  width: 256px;
  position: relative;
  z-index: 10;
  padding: 24px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;
