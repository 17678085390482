import { colors } from "@vericus/cadmus-ui";

import { EnrollmentFragment, Tag } from "generated/graphql";

import { DisplayTag, TagConfig } from "./types";

/**
 * Ordered configuration of pre-existing tags.
 */
export const CADMUS_TAGS: TagConfig[] = [
  {
    value: "Review similarity",
    color: "#FDE7E7",
    custom: false,
  },
  {
    value: "Investigate Further",
    color: "#FAECE6",
    custom: false,
  },
  {
    value: "Contact student",
    color: colors.yellow200,
    custom: false,
  },
  {
    value: "Re-grade",
    color: "#DEEBF7",
    custom: false,
  },
  {
    value: "Double Marked",
    color: colors.cyan200,
    custom: false,
  },
  {
    value: "Benchmarked",
    color: colors.lime200,
    custom: false,
  },
  {
    value: "Resolved",
    color: "#DBDFE7",
    custom: false,
  },
];

/**
 * Create a list of displayable tag information with `active` and `mixed` flags
 * computed from the multiple list of Tags.
 *
 * All known tag configurations in the `configs` will be converted to a
 * `DisplayTag` with the flags:
 *
 *   - `active` if any of the enrollment has the tag
 *   - `mixed` the active state is `mixed` or indeterminate if not all
 *     enrollments have that tag.
 *
 * @param tagLists List of tags from multiple enrollments
 * @param configs Known Tag configurations as a whitelist
 *
 * @returns list of `DisplayTag` mapped from the known tag configurations.
 */
export function bulkDisplayTags(
  tagLists: Tag[][],
  configs: TagConfig[]
): DisplayTag[] {
  return configs.map((config) => {
    // The tag is active if any enrollment has the tag
    const active = tagLists.some((tags) => hasTag(tags, config.value));
    // The tag's active state is mixed if not all enrollments have that tag as active
    const mixed = !tagLists.every((tags) => hasTag(tags, config.value));

    const displayTag: DisplayTag = {
      value: config.value,
      color: config.color,
      custom: config.custom,
      active,
      mixed: active && mixed,
    };

    return displayTag;
  });
}

/**
 * Create a list of diplayble tag information for a list of active tags.
 *
 * @param tagTexts Tag values which are considered active
 * @param configs known Tag configurations as a whitelist
 *
 * @returns list of `DisplayTag` mapped from the known tag configurations,
 *   overlayed with the active `tagTexts` information.
 */
export function singleDisplayTags(
  tagTexts: string[],
  configs: TagConfig[]
): DisplayTag[] {
  // From all the known tags, highlight the ones which are active
  return configs.map((config) => {
    // The tag is active if any enrollment has the tag
    const active = !!tagTexts.find(
      (text) => text.toLowerCase() === config.value.toLowerCase()
    );

    const displayTag: DisplayTag = {
      value: config.value,
      color: config.color,
      custom: config.custom,
      active,
      mixed: false,
    };

    return displayTag;
  });
}

/**
 * Create a merged ordered list of Tag configurations including pre-defined
 * Cadmus tags and found custom tags from the `enrollments`.
 *
 * @param enrollments
 * @param [options]
 * @param [options.customTags] - A list of custom tags to be forced into presentation.
 *  You may want to use this in the event the list of enrollments and its discovery
 *  of custom tags does not suffice.
 */
export function createGlobalTagConfigs(
  enrollments: EnrollmentFragment[],
  options?: {
    customTags?: string[];
  }
): TagConfig[] {
  const customTagTexts = findCustomTags(enrollments);
  const customTagConfigs = Array.from(new Set(customTagTexts))
    .sort()
    .map((text) => ({
      value: text,
      custom: true,
      color: colors.purple200,
    }));

  const forcedCustomTags =
    options?.customTags?.map((text) => ({
      value: text,
      custom: true,
      color: colors.purple200,
    })) ?? [];

  return [...CADMUS_TAGS, ...customTagConfigs, ...forcedCustomTags];
}

// Find the custom tag texts from all the given `enrollments`. A Custom tag is
// any tag which does not feature in the pre-defined Cadmus tags list.
function findCustomTags(enrollments: EnrollmentFragment[]): string[] {
  const customTags: string[] = [];
  const existingTags = CADMUS_TAGS.map((config) => config.value.toLowerCase());

  for (const enrollment of enrollments) {
    for (const tag of enrollment.tags) {
      if (!existingTags.includes(tag.text.toLowerCase())) {
        customTags.push(tag.text);
      }
    }
  }

  return customTags;
}

// Case-insensitive find predicate for a `target` in the `tags` list.
const hasTag = (tags: Tag[], target: string) =>
  !!tags.find((tag) => tag.text.toLowerCase() === target.toLowerCase());
