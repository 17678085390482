import { styled, css } from "styled-components";

export interface Props {
  justifyContent?: string;
}

export const ShelfToolbar = styled.div<Props>`
  width: 100%;
  height: 100%;
  user-select: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 18px;

  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}

  .Controlbar {
    flex: auto;
  }
`;
