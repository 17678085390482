import { AnyNode, Document, DomHandler, Element } from "domhandler";
import {
  filter,
  find,
  findOne,
  getAttributeValue,
  hasAttrib,
  isTag,
  textContent,
} from "domutils";
import { Parser } from "htmlparser2";
import type JSZip from "jszip";
import { JSZipObject } from "jszip";
import { v4 as uuid } from "uuid";

import type { PackagedQuestionBankParser } from "../types";
import { getElementByPath } from "../utils";
import { parser as QTI1Parser } from "./qti1x-parser";

export enum QTIVersion {
  QTI1_x = "QTI1.x",
  QTI2_x = "QTI2.x",
  QTI3_x = "QTI3.x",
  QTI_x = "QTI.x",
}

const getParsedXml = (xmlString: string) => {
  const handler = new DomHandler(undefined, { xmlMode: true });
  const parser = new Parser(handler, { xmlMode: true });
  parser.end(xmlString);
  return handler.root;
};

const packageFileVersion: PackagedQuestionBankParser<QTIVersion>["packageFileVersion"] =
  async (file) => {
    let content: string;
    if (file instanceof File) {
      content = await file.text();
    } else {
      content = file;
    }
    const parsed = getParsedXml(content);
    if (!parsed) return null;
    const manifest = findOne(
      (e) => e.tagName == "manifest",
      parsed.childNodes,
      false
    );
    if (!manifest) return null;
    const metadata = findOne(
      (e) => e.tagName == "metadata",
      manifest.childNodes,
      false
    );
    // Support QTI import form bb content packaging
    if (hasAttrib(manifest, "xmlns:bb")) return QTIVersion.QTI_x;
    if (!metadata) return null;
    const schemaElement = findOne(
      (e) => e.tagName === "schema",
      metadata.childNodes,
      false
    );
    const schemaVersion = findOne(
      (e) => e.tagName === "schemaversion",
      metadata.childNodes,
      false
    );
    if (!schemaElement) return null;
    const schema = textContent(schemaElement);
    if (
      schema === "IMS Content" &&
      schemaVersion &&
      /^1\..*/.test(textContent(schemaVersion))
    ) {
      return QTIVersion.QTI1_x;
    } else if (/^qtiv2\..*/i.test(schema)) {
      return QTIVersion.QTI2_x;
    } else if (
      /^qti.*/i.test(schema) &&
      schemaVersion &&
      /^3\..*/i.test(textContent(schemaVersion))
    ) {
      return QTIVersion.QTI3_x;
    }
    return null;
  };

const fileVersion = async (xml: Document) => {
  if (findOne((e) => e.tagName == "questestinterop", xml.childNodes, false)) {
    return QTIVersion.QTI1_x;
  } else if (
    findOne((e) => e.tagName == "assessmentItem", xml.childNodes, false)
  ) {
    return QTIVersion.QTI2_x;
  } else if (
    findOne((e) => e.tagName == "qti-assessment-item", xml.childNodes, false)
  ) {
    return QTIVersion.QTI3_x;
  }
  return null;
};

const getManifestFile: PackagedQuestionBankParser<QTIVersion>["getManifestFile"] =
  async (zip) => {
    return (await zip.file("imsmanifest.xml")?.async("text")) ?? null;
  };

const isPackageFileSupported: PackagedQuestionBankParser<QTIVersion>["isPackageFileSupported"] =
  async (zip) => {
    let isSupported = false;
    const manifest = await getManifestFile(zip);
    if (manifest) {
      const version = await packageFileVersion(manifest);
      isSupported = version !== null;
    } else {
      for (const [_, zipEntry] of Object.entries(zip.files)) {
        if (zipEntry.name.includes(".xml") || zipEntry.name.includes(".dat")) {
          try {
            const xml = await zipEntry.async("text");
            const parsed = getParsedXml(xml);
            const version = await fileVersion(parsed);
            if (version === QTIVersion.QTI1_x) {
              isSupported = true;
              break;
            }
          } catch (_e) {
            continue;
          }
        }
      }
    }
    return isSupported;
  };

const packageQTIResources = (manifestContent: string) => {
  const handler = new DomHandler(undefined, { xmlMode: true });
  const parser = new Parser(handler, { xmlMode: true });
  parser.end(manifestContent);
  const parsed = handler.root;
  const resourcesElement = getElementByPath(parsed.childNodes, [
    "manifest",
    "resources",
  ]);
  if (!resourcesElement) return [];
  const resources = find(
    (e) => isTag(e) && e.tagName === "resource",
    resourcesElement.childNodes,
    false,
    Number.MAX_SAFE_INTEGER
  );
  return filter(
    (e) => {
      if (!isTag(e)) return false;
      if (hasAttrib(e, "type")) {
        return /(qti|associatedcontent\/imscc_xmlv1p1\/learning-application-resource)/i.test(
          getAttributeValue(e, "type") ?? ""
        );
      }
      return false;
    },
    resources,
    false
  );
};

const parse: PackagedQuestionBankParser<QTIVersion>["parse"] = async (
  file,
  onProcessingFile,
  onProcessedFile,
  fileVersion
) => {
  if (file instanceof File && file.type === "application/zip") {
    const parsedQuestions = [];
    const { default: JSZip } = await import("jszip");
    const zip = await JSZip.loadAsync(file);
    if (await isPackageFileSupported(zip)) {
      const manifest = await getManifestFile(zip);
      if (manifest) {
        const version = fileVersion ?? (await packageFileVersion(manifest));
        const qtiXmls = packageQTIResources(manifest);
        onProcessingFile(qtiXmls.length);
        onProcessedFile(1);
        await Promise.all(
          qtiXmls.map(async (qtixml: AnyNode) => {
            const element = qtixml as Element;
            let filePath: string | undefined;
            if (hasAttrib(element, "bb_file")) {
              filePath = getAttributeValue(element, "bb_file");
            } else if (hasAttrib(element, "bb:file")) {
              filePath = getAttributeValue(element, "bb:file");
            } else {
              const file = findOne(
                (e) => e.tagName === "file" && hasAttrib(e, "href"),
                element.childNodes,
                false
              );
              filePath = file ? getAttributeValue(file, "href") : undefined;
            }
            if (!filePath) return;
            const xmlContent = (await zip.file(filePath)?.async("text")) ?? "";
            const questions = await parseQTIXml(
              xmlContent,
              zip.file(filePath),
              zip,
              version
            );
            onProcessedFile(1);
            if (questions) {
              parsedQuestions.push(questions);
            }
          })
        );
        onProcessedFile(1);
      } else {
        for (const [_, zipEntry] of Object.entries(zip.files)) {
          if (
            zipEntry.name.includes(".xml") ||
            zipEntry.name.includes(".dat")
          ) {
            const xml = await zipEntry.async("text");
            onProcessingFile(1);
            const questions = await parseQTIXml(xml, zipEntry, zip);
            onProcessedFile(1);
            if (questions) {
              parsedQuestions.push(questions);
            }
          }
        }
        onProcessedFile(1);
      }
    }
    return parsedQuestions.flatMap((f) => (f ? [f] : []));
  } else {
    let content: string;
    if (file instanceof File) {
      content = await file.text();
    } else {
      content = file;
    }
    return [await parseQTIXml(content)].flatMap((f) => (f ? [f] : []));
  }
};

const parseQTIXml = async (
  xml: string,
  file?: JSZipObject | null,
  zip?: JSZip,
  version?: QTIVersion | null
) => {
  const handler = new DomHandler(undefined, { xmlMode: true });
  const parser = new Parser(handler, { xmlMode: true });
  parser.end(xml);
  const parsed = handler.root;
  try {
    const qtiVersion = version ? version : await fileVersion(parsed);
    if (!qtiVersion) return null;
    if ([QTIVersion.QTI1_x, QTIVersion.QTI_x].includes(qtiVersion)) {
      const questions = await QTI1Parser.parseQuestions(parsed, file, zip);
      const fileOriginId = uuid();
      return {
        fileOrigin: {
          fileOriginId,
          filepath: file?.name ?? "",
          filename: file?.name?.split("/").slice(-1)[0] ?? "",
        },
        questions: questions.flat().map((question) => {
          return {
            ...question,
            fileOriginId,
          };
        }),
      };
    }
    return null;
  } catch (_e) {
    return {
      fileOrigin: {
        fileOriginId: uuid(),
        filepath: file?.name ?? "",
        filename: file?.name ?? "",
      },
      questions: [],
    };
  }
};

export const parser: PackagedQuestionBankParser<QTIVersion> = {
  isPackageFileSupported,
  packageFileVersion,
  getManifestFile,
  parse,
};
