import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FilterState, QuestionSortingState } from "../types";

export interface ModerateSliceState {
  /** Sorting state for question list */
  sortingState: QuestionSortingState;
  /** Filtering state for question list */
  filterState: FilterState;
  /** Resulting filtered question IDs to display */
  filteredQuestionIds: string[];
  /** Id of feedback release rule that is currently editing */
  editingFeedbackReleaseRuleId: string | null;
  /** Id of penalty rule that is currently editing */
  editingPenaltyRuleId: string | null;
}

const initialFilterState: FilterState = {
  selectedQuestionIds: [],
  selectedQuestionTypes: [],
  showOnlyWithChanges: false,
};

export const initialState: ModerateSliceState = {
  filteredQuestionIds: [],
  sortingState: {
    column: null,
    order: null,
  },
  filterState: initialFilterState,
  editingFeedbackReleaseRuleId: null,
  editingPenaltyRuleId: null,
};

export const moderateSlice = createSlice({
  name: "moderate",
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<QuestionSortingState>) => {
      state.sortingState = action.payload;
    },
    resetSorting(state) {
      state.sortingState = initialState.sortingState;
    },
    setFilterState: (state, action: PayloadAction<Partial<FilterState>>) => {
      state.filterState = { ...state.filterState, ...action.payload };
    },
    toggleFilterOnlyWithChanges: (state) => {
      state.filterState.showOnlyWithChanges =
        !state.filterState.showOnlyWithChanges;
    },
    resetFilterState: (state) => {
      state.filterState = initialFilterState;
    },
    applyQuestionFilters: (state, action: PayloadAction<string[]>) => {
      state.filteredQuestionIds = action.payload;
    },
    updateEditingFeedbackReleaseRuleId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.editingFeedbackReleaseRuleId = action.payload;
    },
    updateEditingPenaltyRuleId: (state, action: PayloadAction<string>) => {
      state.editingPenaltyRuleId = action.payload;
    },
  },
});

export const {
  setSorting,
  resetSorting,
  setFilterState,
  applyQuestionFilters,
  resetFilterState,
  toggleFilterOnlyWithChanges,
  updateEditingFeedbackReleaseRuleId,
  updateEditingPenaltyRuleId,
} = moderateSlice.actions;

export const moderateReducer = moderateSlice.reducer;
