import { useCallback } from "react";

import { useJitsu } from "@jitsu/react";
import toast from "react-hot-toast";
import { useMatch } from "react-router-dom";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { useAppDispatch } from "@/data/hooks";
import { deserialiseTaskEditor, useTaskEditor } from "@/data/task";
import {
  DuplicatePastAssessmentCallback,
  updateSelectedSubcategorySlug,
  useSelectedSubcategory,
} from "@/data/templates";
import {
  TaskFormat,
  useCopyAssessmentMutation,
  usePastAssessmentsQuery,
} from "@/generated/graphql";
import { useQueryParam } from "@/router/routing";
import { IPastAssessment } from "@/ui/templates/ConnectedMyAssessments";
import {
  useInsertHtmlContent,
  useIsEditorEmpty,
} from "@/ui/templates/editorHooks";

import {
  useCloseAllTemplatesModals,
  useShowEditorContentReplacementModal,
  useShowTemplatesModal,
} from "./modals";
import { useOnCloseTemplatesModal } from "./TemplatesModal";

/** Open the modal to ask the user to confirm they want to overwrite their editor content with a template. */
export const useOnSelectTemplate = () => {
  const showEditorContentReplacementModal =
    useShowEditorContentReplacementModal();
  const selectedSubcategory = useSelectedSubcategory();
  const isEditorEmpty = useIsEditorEmpty();
  const insertContent = useInsertHtmlContent();
  const { track } = useJitsu();
  const closeTemplatesModals = useCloseAllTemplatesModals();
  return useCallback(
    (templateId: string) => {
      const template = selectedSubcategory?.templates.find(
        (t) => t.id === templateId
      );
      if (template) {
        if (isEditorEmpty) {
          insertContent(template.content);
          closeTemplatesModals();
          toast.success(`'${template.title}' template has been added`);
          if (selectedSubcategory) {
            track(JitsuEvent.TEMPLATE_USED, {
              template_id: template.id,
              template_title: template.title,
              subcategory_id: selectedSubcategory.id,
              subcategory_title: selectedSubcategory.title,
            });
          }
          return;
        }
        showEditorContentReplacementModal({
          useCase: { type: "template-replacement", template },
        });
      }
    },
    [
      showEditorContentReplacementModal,
      selectedSubcategory,
      isEditorEmpty,
      track,
      insertContent,
      closeTemplatesModals,
    ]
  );
};

export const useCopyAssessment = (): DuplicatePastAssessmentCallback => {
  const [mutate] = useCopyAssessmentMutation();
  const taskEditor = useTaskEditor();

  return useCallback(
    (
      /** Current assessment ID, the one that is receiving the past assessment. */
      destinationAssessmentId: string,
      _assessmentName: string,
      sourceInstructionSheetId: string
    ) => {
      mutate({
        variables: {
          destinationAssessmentId,
          sourceInstructionSheetId,
        },
        onCompleted: (data) => {
          taskEditor
            ?.chain()
            .setContent(deserialiseTaskEditor(data.copyAssessment.saveState))
            .run();
        },
      });
    },
    [mutate, taskEditor]
  );
};

export const useOnNewInstructionsClick = () => {
  const closeTemplatesModal = useOnCloseTemplatesModal();
  const showEditorContentReplaceModal = useShowEditorContentReplacementModal();
  const isEditorEmpty = useIsEditorEmpty();
  const { track } = useJitsu();
  
  return useCallback(() => {
    if (isEditorEmpty) {
      track(JitsuEvent.INSTRUCTIONS_CLEARED);
      closeTemplatesModal()
      return;
    }
    showEditorContentReplaceModal({
      useCase: { type: "empty-content-replacement" },
    });
  }, [isEditorEmpty, closeTemplatesModal, showEditorContentReplaceModal, track]);
};

/**
 * Check whether the entered route contains any request to additionally open
 * the templates modal.
 *
 * Setting the `subcategoryslug` in the query param will preselect this
 * subcategory before the launch.
 *
 * Setting the `opentemplates` to `true` in the query param will open the modal.
 *
 * If the user is not in the specified route, null will be returned.
 * @returns Callback if there is a request to open the modal. `null` if nothing is provided.
 */
export const useOpenTemplatesFromQueryParams = () => {
  const showTemplatesModal = useShowTemplatesModal();
  const openTemplates = useQueryParam("opentemplates");
  const subcategorySlug = useQueryParam("subcategoryslug");
  const dispatch = useAppDispatch();
  const matches = useMatch({
    caseSensitive: false,
    end: false,
    path: ":tenant/assessment/:assessment_id/task/*",
  });
  const callback = useCallback(() => {
    if (subcategorySlug) {
      dispatch(updateSelectedSubcategorySlug({ slug: subcategorySlug }));
    }
    if (openTemplates === "true") {
      showTemplatesModal({});
    }
  }, [openTemplates, dispatch, subcategorySlug, showTemplatesModal]);

  return (openTemplates || subcategorySlug) && matches ? callback : null;
};

/**
 * Get the past assessments by doing a GraphQL query for the current user's past assessments.
 *
 * @param currentAssessmentId The ID of the current assessment. This will be filtered out of the results.
 */
export const usePastAssessments = (currentAssessmentId: string | null) => {
  const { loading, data } = usePastAssessmentsQuery();

  const pastAssessments: IPastAssessment[] | undefined =
    data?.pastInstructionSheets
      .filter(
        (pastInstructionSheet) =>
          currentAssessmentId !== pastInstructionSheet.assessment.id
      )
      .map(({ assessment, sheet }) => {
        return {
          assessmentId: assessment.id,
          instructionSheetVersionId: sheet.serverId,
          examStartDate: sheet.examStartDate
            ? new Date(sheet.examStartDate)
            : null,
          /**
           * The due date is actually the calculated examEndDate for both
           * live (considers reading and writing time) and window exams.
           */
          examEndDate: new Date(sheet.dueDate),
          dueDate: new Date(sheet.dueDate),
          returnDate: new Date(sheet.returnDate),
          name: assessment.name,
          subject: assessment.subject,
          assessmentType: sheet.assessmentType,
          examTiming: sheet.examTiming,
          taskFormat: sheet.format || TaskFormat.Classic,
        };
      });

  return {
    loading,
    pastAssessments,
  };
};
