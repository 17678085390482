import { styled } from "styled-components";

import { Text } from "@vericus/cadmus-ui";

import { formatLeanDatetimeWithTimezone } from "@/utils/datetime";

interface Props {
  /**
   * ISO8601 date until exam ends
   */
  examEndDate: string;
}

export function ExamInProgressPill({ examEndDate }: Props) {
  const formattedDate = formatLeanDatetimeWithTimezone(new Date(examEndDate));
  return (
    <Container>
      <Circle role="presentation" />
      <Text
        kind="bodySm"
        color="cyan800"
        style={{ fontWeight: 600, lineHeight: "21px" }}
      >
        Exam is live
      </Text>
      <Text kind="systemSm" color="grey600" style={{ lineHeight: "21px" }}>
        until {formattedDate}
      </Text>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${({ theme: { colors } }) => colors.green100};
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 4px 16px;
  border-radius: 100px;
`;

const Circle = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme: { colors } }) => colors.green500};
  border-radius: 100%;
`;
