import { useFragment } from "@apollo/client";

import {
  ClassListEnrollmentsFragment,
  ClassListEnrollmentsFragmentDoc,
  FeedbackVisibilityFragment,
  FeedbackVisibilityFragmentDoc,
} from "@/generated/graphql";

export function useClassListEnrollmentsFragment(assessmentId: string) {
  return useFragment<ClassListEnrollmentsFragment>({
    fragment: ClassListEnrollmentsFragmentDoc,
    fragmentName: "ClassListEnrollments",
    from: {
      __typename: "ClassList",
      id: assessmentId,
    },
  });
}

export function useFeedbackVisibilityFragment(assessmentId: string) {
  return useFragment<FeedbackVisibilityFragment>({
    fragment: FeedbackVisibilityFragmentDoc,
    fragmentName: "FeedbackVisibility",
    from: {
      __typename: "FeedbackVisibility",
      id: assessmentId,
    },
  });
}
