import { ExclamationCircleIcon } from "@vericus/cadmus-icons";
import {
  Checkbox,
  colors,
  Icon,
  Pill,
  Text,
  VisuallyHidden,
} from "@vericus/cadmus-ui";

import {
  Column,
  createColumnHelper,
  DisplayColumnDef,
} from "@tanstack/react-table";
import { ErrorIcon } from "react-hot-toast";

import { ImportedFile, ImportFileStatus, ParsedQuestion } from "../../data";
import { FileCategoryAssignCell } from "./file-assign-category-cell";
import { FileNameCell } from "./file-name-cell";
import { oneOf } from "./filters";
import { QuestionCategoryAssignCell } from "./question-assign-category-cell";
import { QuestionTypePill } from "./question-type-pill";
import { SortableHeader } from "./sortable-header";
import * as styles from "./styles.css";

function getRowSelectColumn<T>(): DisplayColumnDef<T> {
  return {
    id: "rowSelector",
    size: 20,
    header: ({ table }) => (
      <Checkbox
        onChange={() => table.toggleAllRowsSelected()}
        mixed={table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
        checked={table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onChange={() => row.toggleSelected()}
      />
    ),
  };
}

function getExpanderColumn<T>(): DisplayColumnDef<T> {
  return {
    id: "expander",
    size: 20,
    header: () => null,
    cell: ({ row }) => {
      return (
        <span className={styles.expandCell}>
          <Icon
            iconName={row.getIsExpanded() ? "UpArrowhead" : "DownArrowhead"}
            style={{ fill: "#5B6F98" }}
          />
          <VisuallyHidden.Root>
            {row.getIsExpanded() ? "Collapse" : "Expand"}
          </VisuallyHidden.Root>
        </span>
      );
    },
  };
}

function getIndexColumn<T>(): DisplayColumnDef<T> {
  return {
    id: "index",
    minSize: 20,
    size: 20,
    header: () => (
      <div className={styles.leftAlignCell}>
        <Text kind="label">#</Text>
      </div>
    ),
    cell: () => (
      <div className={styles.leftAlignCell}>
        <span className={styles.indexCell} />
      </div>
    ),
  };
}

const importedFileColumnHelper = createColumnHelper<ImportedFile>();

const fileNameColumn = importedFileColumnHelper.accessor(
  (row) => row.filename ?? row.filepath,
  {
    id: "filename",
    minSize: 50,
    enableSorting: true,
    header: ({ column }) => (
      <div className={styles.leftAlignCell}>
        <SortableHeader column={column as Column<unknown, unknown>}>
          FILE NAME
        </SortableHeader>
      </div>
    ),
    cell: ({ row }) => {
      return (
        <FileNameCell
          fileOriginId={row.original.fileId}
          filename={row.original.filename}
        />
      );
    },
  }
);

const allQuestionsCountColumn = importedFileColumnHelper.accessor(
  (row) => {
    const supportedQuestions = row.questions?.filter(
      (q) => q.questionType !== null
    );
    if (!supportedQuestions) return "---";
    return `${supportedQuestions.length}/${(row.questions ?? []).length}`;
  },
  {
    id: "questionsNum",
    size: 120,
    enableSorting: true,
    header: ({ column }) => (
      <div className={styles.rightAlignCell}>
        <SortableHeader column={column as Column<unknown, unknown>}>
          QUESTIONS
        </SortableHeader>
      </div>
    ),
    cell: ({ getValue }) => (
      <div className={styles.rightAlignCell}>{getValue()}</div>
    ),
  }
);

const supportedQuestionsCountColumn = importedFileColumnHelper.accessor(
  (row) => {
    const supportedQuestions = row.questions?.filter(
      (q) => q.questionType !== null
    );
    return `${supportedQuestions.length}`;
  },
  {
    id: "questionsNum",
    size: 120,
    enableSorting: true,
    header: ({ column }) => (
      <div className={styles.rightAlignCell}>
        <SortableHeader column={column as Column<unknown, unknown>}>
          QUESTIONS
        </SortableHeader>
      </div>
    ),
    cell: ({ getValue }) => (
      <div className={styles.rightAlignCell}>{getValue()}</div>
    ),
  }
);

const questionsStatusColumn = importedFileColumnHelper.accessor(
  (row) => row.status,
  {
    id: "questionsStatus",
    size: 200,
    header: ({ column }) => (
      <div className={styles.rightAlignCell}>
        <SortableHeader column={column as Column<unknown, unknown>}>
          STATUS
        </SortableHeader>
      </div>
    ),
    cell: ({ row, getValue }) => (
      <div className={styles.rightAlignCell}>
        {getValue() === ImportFileStatus.Success ? (
          <Text kind="bodySm">Success</Text>
        ) : getValue() === ImportFileStatus.PartialImport ? (
          <>
            <ExclamationCircleIcon label="Partial" size={20} />
            <Text kind="bodySm">Partial Import</Text>
          </>
        ) : (
          <>
            <ErrorIcon />
            <Text kind="bodySm" color="red500">
              Failed to import
            </Text>
          </>
        )}

        {getValue() !== ImportFileStatus.FailedToImport && (
          <span className={styles.expandCell}>
            <Icon
              iconName={row.getIsExpanded() ? "UpArrowhead" : "DownArrowhead"}
              style={{ fill: "#5B6F98" }}
            />
            <VisuallyHidden.Root>
              {row.getIsExpanded() ? "Collapse" : "Expand"}
            </VisuallyHidden.Root>
          </span>
        )}
      </div>
    ),
  }
);

const parsedQuestionsColumnHelper = createColumnHelper<ParsedQuestion>();

const questionTypeColumn = parsedQuestionsColumnHelper.accessor(
  (row) => row.questionType,
  {
    id: "questionType",
    minSize: 50,
    size: 80,
    header: ({ column }) => (
      <div className={styles.leftAlignCell}>
        <SortableHeader column={column as Column<unknown, unknown>}>
          TYPE
        </SortableHeader>
      </div>
    ),
    cell: ({ row }) => {
      return <QuestionTypePill questionType={row.original.questionType!} />;
    },
    enableColumnFilter: true,
  }
);

const questionDifficultyColumn = parsedQuestionsColumnHelper.accessor(
  (row) => row.difficulty,
  {
    id: "questionDifficulty",
    minSize: 100,
    size: 150,
    header: ({ column }) => (
      <SortableHeader column={column as Column<unknown, unknown>}>
        DIFFICULTY
      </SortableHeader>
    ),
    cell: ({ getValue }) => getValue(),
    filterFn: oneOf<ParsedQuestion, string>,
    enableColumnFilter: true,
  }
);

const questionPromptColumn = parsedQuestionsColumnHelper.accessor(
  (row) => row.shortPrompt,
  {
    id: "questionShortPrompt",
    minSize: 150,
    header: () => (
      <div className={styles.leftAlignCell}>
        <Text kind="label">QUESTION</Text>
      </div>
    ),
    cell: ({ getValue }) => (
      <div className={styles.leftAlignCell}>
        <Text kind="bodySm">{getValue()}</Text>
      </div>
    ),
  }
);

const questionSupportedColumn = parsedQuestionsColumnHelper.accessor(
  (row) => (row.questionType ? true : false),
  {
    id: "questionSupported",
    size: 200,
    header: () => (
      <div className={styles.rightAlignCell}>
        {" "}
        <Text kind="label">STATUS</Text>
      </div>
    ),
    cell: ({ getValue }) => (
      <div className={styles.rightAlignCell}>
        <Pill
          color={getValue() ? colors.teal200 : colors.grey100}
          textColor={getValue() ? colors.teal800 : colors.neutralBlack300}
        >
          {getValue() ? "Success" : "unsupported"}
        </Pill>
      </div>
    ),
    enableColumnFilter: true,
    filterFn: (row, columnId, hideUnsupported) => {
      if (hideUnsupported === true && row.getValue(columnId) === false)
        return false;
      return true;
    },
  }
);

const questionCategoryAssignColumn = parsedQuestionsColumnHelper.accessor(
  (row) => row.category,
  {
    id: "questionCategory",
    size: 200,
    header: ({ column }) => (
      <div className={styles.rightAlignCell}>
        <SortableHeader column={column as Column<unknown, unknown>}>
          CATEGORY
        </SortableHeader>
      </div>
    ),
    cell: ({ row, getValue }) => (
      <div className={styles.rightAlignCell}>
        <QuestionCategoryAssignCell
          questionId={row.original.questionId}
          category={getValue()}
        />
      </div>
    ),
  }
);

const fileQuestionCategoryColumn = importedFileColumnHelper.accessor(
  () => null,
  {
    id: "fileCategory",
    size: 200,
    header: ({ column }) => (
      <div className={styles.rightAlignCell}>
        <SortableHeader column={column as Column<unknown, unknown>}>
          CATEGORY
        </SortableHeader>
      </div>
    ),
    cell: ({ row }) => (
      <div className={styles.rightAlignCell}>
        <FileCategoryAssignCell fileId={row.original.fileId!} />
      </div>
    ),
  }
);

const questionCategoryColumn = parsedQuestionsColumnHelper.accessor(
  (row) => row.category,
  {
    id: "questionCategory",
    size: 200,
    header: ({ column }) => (
      <div className={styles.rightAlignCell}>
        <SortableHeader column={column as Column<unknown, unknown>}>
          CATEGORY
        </SortableHeader>
      </div>
    ),
    cell: ({ getValue }) => (
      <>
        {getValue() && (
          <div className={styles.rightAlignCell}>
            <Pill>{getValue()}</Pill>
          </div>
        )}
      </>
    ),
    filterFn: oneOf<ParsedQuestion, string>,
    enableColumnFilter: true,
  }
);

const fileOriginIdColumn = parsedQuestionsColumnHelper.accessor(
  (row) => row.fileOriginId.toString(),
  {
    enableColumnFilter: true,
    id: "fileOriginId",
    filterFn: oneOf<ParsedQuestion, string>,
  }
);

export const fileAllImportColumns = [
  importedFileColumnHelper.display(getRowSelectColumn<ImportedFile>()),
  importedFileColumnHelper.display(getIndexColumn<ImportedFile>()),
  fileNameColumn,
  allQuestionsCountColumn,
  questionsStatusColumn,
];

export const fileSupportedImportColumns = [
  importedFileColumnHelper.display(getRowSelectColumn<ImportedFile>()),
  importedFileColumnHelper.display(getIndexColumn<ImportedFile>()),
  fileNameColumn,
  supportedQuestionsCountColumn,
  fileQuestionCategoryColumn,
  importedFileColumnHelper.display(getExpanderColumn<ImportedFile>()),
];

export const questionsCategoryAssignColumns = [
  parsedQuestionsColumnHelper.display(getRowSelectColumn<ParsedQuestion>()),
  parsedQuestionsColumnHelper.display(getIndexColumn<ParsedQuestion>()),
  questionTypeColumn,
  questionPromptColumn,
  questionCategoryAssignColumn,
  parsedQuestionsColumnHelper.display(getExpanderColumn<ParsedQuestion>()),
];
export const questionsAllColumns = [
  parsedQuestionsColumnHelper.display(getIndexColumn<ParsedQuestion>()),
  questionTypeColumn,
  questionPromptColumn,
  questionSupportedColumn,
  parsedQuestionsColumnHelper.display(getExpanderColumn<ParsedQuestion>()),
];

export const fileQuestionsColumns = [
  parsedQuestionsColumnHelper.display(getIndexColumn<ParsedQuestion>()),
  questionTypeColumn,
  questionPromptColumn,
  questionSupportedColumn,
];

export const fileSupportedQuestionsColumns = [
  parsedQuestionsColumnHelper.display(getIndexColumn<ParsedQuestion>()),
  questionTypeColumn,
  questionPromptColumn,
  questionDifficultyColumn,
];

export const questionsPreviewColumns = [
  fileOriginIdColumn,
  parsedQuestionsColumnHelper.display(getRowSelectColumn<ParsedQuestion>()),
  parsedQuestionsColumnHelper.display(getIndexColumn<ParsedQuestion>()),
  questionTypeColumn,
  questionPromptColumn,
  questionDifficultyColumn,
  questionCategoryColumn,
  parsedQuestionsColumnHelper.display(getExpanderColumn<ParsedQuestion>()),
];
