import { styled } from "styled-components";

import { Button, Modal, Text } from "@vericus/cadmus-ui";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useJitsu } from "@jitsu/react";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { EnrollmentFragment } from "@/generated/graphql";
import { ClassTab } from "@/ui/class/progress/types";

import { useRemoveTagMutation } from "../useTagMutations";

/**
 * Describes which custom tag(s) a user wants to delete from the enrollments.
 */
export interface TagDeleteIntent {
  // Tag the user wants to delete.
  tag: string;
  // The tab from which the tag should be removed from.
  tab: ClassTab;
  // Enrollments from which the tag will be removed
  enrollments: EnrollmentFragment[];
}

export interface ConnectedTagDeleteConfirmModalProps {
  tagDeleteIntent: TagDeleteIntent;
}

export const ConnectedTagDeleteConfirmModal =
  NiceModal.create<ConnectedTagDeleteConfirmModalProps>((props) => {
    const { tagDeleteIntent } = props;
    const modal = useModal();
    const removeTag = useRemoveTagMutation();
    const { track } = useJitsu();
    return (
      <TagDeleteConfirmModal
        open={modal.visible}
        onExitComplete={modal.remove}
        onClose={() => modal.hide()}
        affectedEnrollments={tagDeleteIntent.enrollments.length}
        onConfirm={async () => {
          await removeTag(
            tagDeleteIntent.enrollments,
            tagDeleteIntent.tag,
            tagDeleteIntent.tab
          );
          modal.hide();
          track(JitsuEvent.ENROLLMENT_TAG_BULK_REMOVED, {
            tag_name: tagDeleteIntent.tag,
            class_tab: tagDeleteIntent.tab,
            is_custom: true,
            enrollments: tagDeleteIntent.enrollments.map((enrollment) => {
              return {
                enrollment_id: enrollment.id,
                student_id: enrollment.user.id,
              };
            }),
          });
        }}
      />
    );
  });

interface TagDeleteConfirmModalProps {
  open: boolean;
  onExitComplete?: VoidFunction;
  /**
   * Callback when the delete button was pressed
   */
  onConfirm: () => void;
  /**
   * Callback when the user requests to close/cancel the modal.
   */
  onClose: () => void;
  /**
   * How many enrollments are affected.
   */
  affectedEnrollments: number;
}

/**
 * Confirmation modal to ensure the user really wants to delete a custom tag
 * from multiple enrollments and from the ClassList as a result.
 */
export const TagDeleteConfirmModal = (props: TagDeleteConfirmModalProps) => {
  return (
    <Modal.Root
      open={props.open}
      onOpenChange={(open) => {
        if (!open) props.onClose();
      }}
    >
      <ModalContent onExitComplete={props.onExitComplete} width={568}>
        <Text kind="headingFour">Delete this tag?</Text>
        <Text kind="bodyMd">
          This tag has been applied to {props.affectedEnrollments}{" "}
          {props.affectedEnrollments > 1 ? "submissions" : "submission"}.
          Deleting the tag will remove it from all the submissions.
        </Text>
        <ButtonContainer>
          <Button onClick={props.onClose}>Cancel</Button>
          <ConfirmButton kind="secondary" onClick={props.onConfirm}>
            Yes, Delete
          </ConfirmButton>
        </ButtonContainer>
      </ModalContent>
    </Modal.Root>
  );
};

const ModalContent = styled(Modal.Content)`
  padding: 56px 72px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  gap: 12px;
`;

const ConfirmButton = styled(Button)`
  padding: 8px 56px;
`;
