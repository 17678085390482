import { createAsyncThunk } from "@reduxjs/toolkit";

import { Category } from "@/data/templates/types";

import { loadContentfulTemplates } from "./contentful";

/**
 * Asks contentful for the latest set of categories and sub-categories to display.
 */
export const refreshTemplates = createAsyncThunk(
  "templates/refreshTemplates",
  async (_thunkAPI) => {
    const contentfulCategories = await loadContentfulTemplates();
    return [...contentfulCategories, ...staticCategories()];
  }
);

const staticCategories = () => {
  return [myAssessmentsCategory];
};

// Hard-coded category that will always exist on the sidebar.
const myAssessmentsCategory: Category = {
  id: "my-assessments",
  title: "My Assessments",
  // Note: this variable is currently unused. It's hard coded in PastAssessmentsRoot.
  helpLink: "https://support.cadmus.io/reusing-past-assessments",
  previewDescription: "Past assessments", // not used
  subcategories: [
    {
      id: "my-assessments/past-assessments",
      title: "Past assessments",
      slug: "past-assessments",
      // Attributes below are not actually being utilised during the render process
      templates: [],
      heading: "Past assessments",
      showAs: "wide",
      description: "some description",
      discipline: [],
    },
  ],
  hasFilters: false,
};
