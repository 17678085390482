import { Dispatch, SetStateAction, useEffect, useState } from "react";

/**
 * Debounce a `dispatch` function call on a value which can be changed
 * independently.
 *
 * Returns a normal `useState` return tuple which can be used transparently. The
 * 'dispatch' callback will be called debounced by `debounceMs`.
 *
 */
export function useDebouncedDispatch<T>(
  value: T,
  dispatch: Dispatch<T>,
  debounceMs: number = 400
): [T, Dispatch<SetStateAction<T>>] {
  const [internalValue, setInternalValue] = useState(value);

  // Update the internal value when the parent value changes
  useEffect(() => setInternalValue(value), [value, setInternalValue]);

  // Debounced dispatch call with a "changed" internal value
  useEffect(() => {
    const timer = setTimeout(() => {
      if (value !== internalValue) {
        dispatch(internalValue);
      }
    }, debounceMs);
    return () => clearTimeout(timer);
  }, [internalValue, value, dispatch, debounceMs]);

  // The hook caller only needs to set and update the internal value
  // transparently
  return [internalValue, setInternalValue];
}
