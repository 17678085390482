import { useEffect } from "react";

export enum ExposedCustomEvent {
  OPEN_SAMPLE_REPORT = "open-sample-report",
  SHOW_STUDENTS_DROPDOWN = "show-students-dropdown",
  OPEN_STUDENT_DROPDOWN = "open-student-dropdown",
  NAVIGATE_TO = "navigate-to",
}

/** Uses DOM events to receive events. Useful for third party script messaging. */
export const useCustomEvent = (
  enabled: boolean,
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (params: any) => void
) => {
  useEffect(() => {
    if (enabled) {
      window.addEventListener(eventName, callback);
    }

    return () => {
      if (enabled) {
        window.removeEventListener(eventName, callback);
      }
    };
  }, [eventName, callback, enabled]);
};
