import { ChevronDownIcon } from "@vericus/cadmus-icons";
import { DropdownMenu } from "@vericus/cadmus-ui";

import { InteractionKind } from "../../interaction-kind-utils";
import * as styles from "./fill-in-blanks-question-body.css";

export function InteractionTypeDropdown(props: {
  selected: InteractionKind;
  onSelectInteractionKind: (kind: InteractionKind) => void;
}) {
  const icon =
    props.selected === InteractionKind.TextEntry ? (
      <TextIcon />
    ) : (
      <DropdownIcon />
    );

  const adjustedWidth = { minWidth: 80, maxWidth: 80 };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className={styles.trigger} style={adjustedWidth}>
          {icon}
          <div className={styles.dropdownIcon}>
            <ChevronDownIcon label="" />
          </div>
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content style={{ minWidth: 150, maxWidth: 150 }}>
        <DropdownMenu.Item
          leftSection={<TextIcon />}
          selected={props.selected === InteractionKind.TextEntry}
          onSelect={() => {
            if (props.selected === InteractionKind.TextEntry) return;
            props.onSelectInteractionKind(InteractionKind.TextEntry);
          }}
          content="Text"
        />
        <DropdownMenu.Item
          leftSection={<DropdownIcon />}
          onSelect={() => {
            if (props.selected === InteractionKind.Choice) return;
            props.onSelectInteractionKind(InteractionKind.Choice);
          }}
          content="Dropdown"
          selected={props.selected === InteractionKind.Choice}
        />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

function DropdownIcon() {
  return (
    <svg
      width="24"
      height="12"
      viewBox="0 0 24 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4255 7.2248C18.6005 7.3998 18.8755 7.3998 19.0505 7.2248L20.7255 5.5498C21.0005 5.2748 20.8005 4.7998 20.4255 4.7998H17.0505C16.6505 4.7998 16.4755 5.2748 16.7505 5.5498L18.4255 7.2248Z"
        fill="#6355F6"
      />
      <path
        d="M23.25 0.875H0.75C0.4 0.875 0.125 1.15 0.125 1.5V10.5C0.125 10.85 0.4 11.125 0.75 11.125H23.25C23.6 11.125 23.875 10.85 23.875 10.5V1.5C23.875 1.15 23.6 0.875 23.25 0.875ZM13.6 9.875H1.4V2.125H13.625V9.875H13.6ZM22.6 9.875H14.875V2.125H22.6V9.875Z"
        fill="#6355F6"
      />
      <path
        d="M11.125 3.875H3.875C3.525 3.875 3.25 4.15 3.25 4.5C3.25 4.85 3.525 5.125 3.875 5.125H11.125C11.475 5.125 11.75 4.85 11.75 4.5C11.75 4.15 11.475 3.875 11.125 3.875Z"
        fill="#6355F6"
      />
      <path
        d="M11.125 6.875H3.875C3.525 6.875 3.25 7.15 3.25 7.5C3.25 7.85 3.525 8.125 3.875 8.125H11.125C11.475 8.125 11.75 7.85 11.75 7.5C11.75 7.15 11.475 6.875 11.125 6.875Z"
        fill="#6355F6"
      />
    </svg>
  );
}

function TextIcon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.39574 2.90655H12.244V4.83068H14.1681V1.94449C14.1681 1.68933 14.0668 1.44463 13.8863 1.2642C13.7059 1.08378 13.4612 0.982422 13.2061 0.982422H1.66128C1.40613 0.982422 1.16142 1.08378 0.981001 1.2642C0.800579 1.44463 0.699219 1.68933 0.699219 1.94449V4.83068H2.62335V2.90655H6.47161V15.0927H3.9061V17.0168H10.9612V15.0927H8.39574V2.90655Z"
        fill="#6355F6"
      />
      <path
        d="M16.0912 14.3907V11.8861H18.9774V9.96199H16.0912V7.39648H14.1671V9.96199H11.6016V11.8861H14.1671V14.3907C14.1671 15.0873 14.4438 15.7553 14.9363 16.2479C15.4289 16.7404 16.0969 17.0171 16.7935 17.0171H19.2981V15.093H16.7935C16.6072 15.093 16.4286 15.019 16.2969 14.8873C16.1652 14.7556 16.0912 14.577 16.0912 14.3907Z"
        fill="#6355F6"
      />
    </svg>
  );
}
