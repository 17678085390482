import { Fragment } from "react";
import { styled } from "styled-components";

import {
  Button,
  Color,
  colors,
  ControlButton,
  Input,
  LinkButton,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import copy from "copy-to-clipboard";

import { AccessCodeFragment } from "@/generated/graphql";
import { useDebouncedDispatch } from "@/utils/useDebouncedDispatch";

export interface AccessCodeListProps {
  /** List of access codes */
  accessCodes: AccessCodeFragment[];
  updateAccessCode: (code: string, label: string, isActive: boolean) => void;
  generateNewAccessCode?: () => void;
  /** Prevent deletion and generation of Access Code */
  disabled?: boolean;
}

export function AccessCodeList(props: AccessCodeListProps) {
  const { accessCodes, updateAccessCode, generateNewAccessCode, disabled } =
    props;

  const copyAllCodes = () => {
    const codes = accessCodes
      .map((item) => `${item.label}\t${item.code}`)
      .join("\n");
    copy(codes, { format: "text/plain" });
  };

  return (
    <>
      <Grid>
        <Text kind="label" color="grey600" as="div">
          Code
        </Text>
        <Text kind="label" color="grey600" as="div">
          Label
        </Text>
        {accessCodes.map((item, index) => (
          <Fragment key={item.code}>
            <CodeText code={item.code} />
            <Column>
              <LabelInput
                label={item.label ?? ""}
                onUpdate={(label) =>
                  updateAccessCode(item.code, label, item.active)
                }
              />
              <ControlButton
                iconName="Copy"
                onClick={() =>
                  copy(`${item.label}\t${item.code}`, {
                    format: "text/plain",
                  })
                }
              />
              {index > 0 && !disabled && (
                <ControlButton
                  iconName="Trash"
                  onClick={() =>
                    updateAccessCode(item.code, item.label || "", false)
                  }
                />
              )}
            </Column>
          </Fragment>
        ))}
      </Grid>
      <Spacer spacing={12} />
      <FooterButtons
        disabled={disabled}
        copyAllCodes={copyAllCodes}
        generateNewAccessCode={generateNewAccessCode}
      />
    </>
  );
}

interface LabelInputProps {
  label: string;
  onUpdate: (label: string) => void;
}

function LabelInput(props: LabelInputProps) {
  const [value, setValue] = useDebouncedDispatch(
    props.label,
    props.onUpdate,
    400
  );
  return (
    <Input
      placeholder="Room label"
      value={value}
      maxLength={20}
      error={value.length === 0}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
}

interface FooterButtonsProps {
  generateNewAccessCode?: () => void;
  disabled?: boolean;
  copyAllCodes: () => void;
}

function FooterButtons(props: FooterButtonsProps) {
  const { generateNewAccessCode, disabled, copyAllCodes } = props;
  return (
    <FooterButtonContainer>
      {generateNewAccessCode && !disabled && (
        <Button
          kind="outline"
          size="md"
          onClick={generateNewAccessCode}
          iconName="Plus"
        >
          Generate more
        </Button>
      )}
      <LinkButton onClick={copyAllCodes} kind="primary">
        Copy all codes
      </LinkButton>
    </FooterButtonContainer>
  );
}

interface CodeTextProps {
  code: string;
  bgColor?: Color;
  size?: number;
}

export const CodeText = (props: CodeTextProps) => {
  return (
    <Container>
      {props.code.split("").map((char, index) => (
        <CodeLetter
          key={index}
          value={char}
          disabled
          bgColor={props.bgColor}
          size={props.size}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const CodeLetter = styled.input<{
  bgColor?: Color;
  size?: number;
}>`
  --size: ${(p) => p.size ?? 26}px;
  margin-right: 8px;
  width: var(--size);
  height: var(--size);
  display: inline-flex;
  text-align: center;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 2px;
  color: ${colors["blue800"]};
  background: ${(p) =>
    p.bgColor ? colors[p.bgColor] : "hsla(210, 45%, 96%, 0.5)"};
  border: 0;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FooterButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
