import { css, styled } from "styled-components";

import { colors } from "@vericus/cadmus-ui";

export const Root = styled.div`
  flex: none;
  min-height: 562px;
  /* A CSS counter reset for row numbers */
  counter-reset: class-list;

  /* Pull the class table out -45px so that the row bg can extend beyond the row */
  margin-left: -45px;
  margin-right: -45px;
`;

export const Row = styled.div<{
  // Use the row as a header row
  header?: boolean;
  // Row selection state
  selected?: boolean;
  // Select a grid column template config
  template?:
    | "progress"
    | "submission"
    | "timed-submission"
    | "exam-extra-time"
    | "alternative-exam-start"
    | "code-progress"
    | "moderation-score"
    | "deferred";
}>`
  display: grid;
  gap: 12px;

  align-items: center;
  min-height: 44px;
  width: 100%;
  padding: 16px 45px 4px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  background: white;

  grid-template-columns: ${(p) => gridTemplate(p.template)};
  grid-auto-flow: column;

  /* On hover, show the hidden row controls class */
  &:hover .hidden-controls {
    visibility: visible !important;
  }

  ${(p) =>
    !p.header &&
    css`
      background: ${p.selected ? "#EDF4FB" : "white"};
      min-height: 54px;
      padding: 8px 45px;

      &:hover {
        background: ${colors.grey50};
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${colors.grey50};
      }
    `}
`;

// Determine the named column grid template
const gridTemplate = (
  template:
    | "progress"
    | "submission"
    | "timed-submission"
    | "exam-extra-time"
    | "alternative-exam-start"
    | "code-progress"
    | "moderation-score"
    | "deferred" = "progress"
) => {
  switch (template) {
    case "progress":
      return "[select] 20px [index] 35px [name] minmax(100px, 1fr) [group] 210px [progress] 1fr [access] 250px [options] 40px";
    case "code-progress":
      return "[select] 20px [index] 35px [name] minmax(100px, 1fr) [group] 210px [progress] minmax(100px, 1fr) [accessCodeLabel] 210px [access] 250px [options] 40px";
    case "submission":
      return "[select] 20px [index] 35px [name] minmax(100px, 1fr) [group] minmax(150px, 210px) [status] minmax(150px, 1fr) [similarity] minmax(100px, 160px) [download] 160px [action] 108px [options] 40px";
    case "timed-submission":
      return "[select] 20px [index] 35px [name] minmax(100px, 1fr) [group] minmax(150px, 210px) [duration] 100px [status] minmax(150px, 1fr) [similarity] 98px [download] 150px [action] 108px [options] 40px";
    case "exam-extra-time":
      return "[name] 300px [additional-time] 2fr [action] 100px [options] 40px";
    case "alternative-exam-start":
      return "[name] 210px [date] minmax(100px, 1fr) [action] 100px [options] 40px";
    case "moderation-score":
      return "[select] 20px [index] 35px [name] minmax(120px, 1fr) [group] minmax(150px,210px) [status] minmax(150px, 1fr) [similarity] minmax(90px, 120px) [late-by] 120px [released-on] 120px [adjusted-score] 100px [marked-score] 150px;";
    case "deferred":
      return "[name] auto [deferred] 100px [action] 70px";
  }
};

export const SelectCol = styled.div`
  grid-column-start: select;
`;

export const IndexCol = styled.div<{ counter?: boolean }>`
  grid-column-start: index;
  text-align: right;

  ${(p) =>
    p.counter
      ? css`
          &:before {
            counter-increment: class-list;
            content: counter(class-list) ".";
            flex: none;
            width: 8px;
            opacity: 0.36;
            text-align: right;
          }
        `
      : css``}
`;

export const AdditionalTimeCol = styled.div`
  grid-column-start: additional-time;

  display: flex;
  align-items: baseline;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DateCol = styled.div`
  grid-column-start: date;

  display: flex;
  align-items: baseline;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DeferredCol = styled.div`
  grid-column-start: deferred;

  display: flex;
  align-items: baseline;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const NameCol = styled.div`
  grid-column-start: name;

  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: baseline;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ProgressCol = styled.div`
  grid-column-start: progress;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const AccessCodeLabelCol = styled.div`
  grid-column-start: accessCodeLabel;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: baseline;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const AccessCol = styled.div`
  grid-column-start: access;
`;

export const GroupCol = styled.div`
  grid-column-start: group;
  display: flex;
  justify-content: flex-end;
`;

export const DurationCol = styled.div`
  grid-column-start: duration;
  display: flex;
  justify-content: flex-start;
`;

export const StatusCol = styled.div`
  grid-column-start: status;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  row-gap: 4px;

  .hidden-controls:not([aria-expanded="true"]) {
    visibility: hidden;
  }

  p {
    flex-shrink: 0;
  }
`;

export const SimilarityCol = styled.div`
  grid-column-start: similarity;

  display: flex;
  align-items: center;
`;

export const ActionCol = styled.div`
  grid-column-start: action;
`;

export const OptionsCol = styled.div`
  grid-column-start: options;
`;

export const DownloadCol = styled.div`
  grid-column-start: download;
`;

export const AdjustedScoreCol = styled.div`
  grid-column-start: adjusted-score;
  display: flex;
  justify-content: center;
`;

export const MarkedScoreCol = styled.div`
  grid-column-start: marked-score;
`;

export const LateByCol = styled.div`
  grid-column-start: late-by;

  display: flex;
  justify-content: center;
`;
export const ReleaseOnCol = styled.div`
  grid-column-start: released-on;

  display: flex;
  justify-content: center;
`;
