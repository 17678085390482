import { useCallback, useEffect } from "react";

import { useJitsu } from "@jitsu/react";

import { JitsuEvent } from "../events/JitsuEvent";
import { useAppDispatch, useAppSelector } from "../hooks";
import { refreshTemplates } from "./actions";
import {
  selectAllTemplates,
  selectCategories,
  selectSelectedCategory,
  selectSelectedFilter,
  selectSelectedSubcategory,
  selectShowUiSkeletons,
  selectTemplate,
  selectViewableTemplates,
} from "./selectors";
import {
  updateSelectedFilter,
  updateSelectedSubcategory,
} from "./templatesSlice";
import { TemplateFilter } from "./types";

/**
 * Update the selected subcategory in the Redux store.
 * @returns a callback to update the selected subcategory
 */
export const useUpdateSelectedSubcategory = () => {
  const dispatch = useAppDispatch();
  const { track } = useJitsu();
  return useCallback(
    (subcategoryId: string, subcategoryTitle: string) => {
      dispatch(updateSelectedSubcategory({ subcategoryId }));
      track(JitsuEvent.TEMPLATE_SUBCATEGORY_SELECTED, {
        subcategory_id: subcategoryId,
        subcategory_title: subcategoryTitle,
      });
    },
    [dispatch, track]
  );
};

/**
 * Update the selected template filter in the Redux store.
 * @returns a callback to update the templates filter
 */
export const useUpdateTemplatesFilter = () => {
  const dispatch = useAppDispatch();
  return useCallback(
    (newFilter: TemplateFilter) => {
      dispatch(updateSelectedFilter({ newFilter }));
    },
    [dispatch]
  );
};

/**
 * Populate categories, subcategories, and templates from Contentful.
 * NOTE: Only makes API calls if it has not loaded the data in this session
 * already.
 */
export const useContentfulTemplates = () => {
  const dispatch = useAppDispatch();
  const { hasLoaded } = useCategories();
  useEffect(() => {
    if (!hasLoaded) {
      dispatch(refreshTemplates());
    }
  }, [dispatch, hasLoaded]);
};

export const useCategories = () => useAppSelector(selectCategories);

/**
 * Get the templates that should be shown in the UI.
 * Takes care of selecting the correct templates given the
 * selected subcategory and filters in the Redux store.
 */
export const useViewableTemplates = () =>
  useAppSelector(selectViewableTemplates);

export const useSelectedCategory = () => useAppSelector(selectSelectedCategory);

/**
 * @returns the selected templates subcategory in the Redux store.
 */
export const useSelectedSubcategory = () =>
  useAppSelector(selectSelectedSubcategory);

/**
 * @returns the selected templates filter in the Redux store.
 */
export const useSelectedFilter = () => useAppSelector(selectSelectedFilter);

/**
 * @returns a template from the store given its ID.
 */
export const useTemplate = (templateId?: string) =>
  useAppSelector((state) => selectTemplate(state, templateId));

/**
 * @returns a callback to find a template by ID.
 */
export const useFindTemplate = () => {
  const allTemplates = useAppSelector(selectAllTemplates);
  return useCallback(
    (templateId: string) => {
      return allTemplates.find((template) => template.id === templateId);
    },
    [allTemplates]
  );
};

/**
 * Determines whether templates UI should show loading skeletons.
 */
export const useShowUiSkeletons = () => useAppSelector(selectShowUiSkeletons);
