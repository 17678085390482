import { useCallback, useState } from "react";
import { styled } from "styled-components";

import { Button, colors, Modal, OptionCard, Text } from "@vericus/cadmus-ui";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { TaskFormat, useUpdateTaskFormatMutation } from "generated/graphql";

import { TrackedAnchor } from "@/ui/shared/TrackedAnchor";

export interface TaskFormatModalProps {
  taskId: string;
  onExitComplete?: VoidFunction;
  open: boolean;
  selectedMultiFormatType?: TaskFormat;
  onSelectMultiFormatType: (newtype: TaskFormat) => void;
}
/**
 * @description `TaskFormatModal` is a Modal component for selecting and updating the format of a task.
 *
 * @component
 * @param {string} props.taskId The ID of the task.
 * @param {TaskFormat} [props.selectedMultiFormatType] The currently selected task format.
 * @param {function(TaskFormat): void} props.onSelectMultiFormatType A callback function that is called when a new task format is selected.
 * @param {function(): void} [props.onExitComplete] A callback function that is called when the modal exit is complete.
 * @param {boolean} props.open A boolean indicating whether the modal is open.
 * @returns {ReactElement} The rendered `TaskFormatModal` component.
 */

export const TaskFormatModal = ({
  taskId,
  selectedMultiFormatType,
  onSelectMultiFormatType,
  onExitComplete,
  open,
}: TaskFormatModalProps) => {
  const [updateName] = useUpdateTaskFormatMutation({ onError: () => {} });

  const onUpdateTaskFormat = useCallback(
    (format: TaskFormat) => {
      updateName({
        variables: { taskId, format },
        optimisticResponse: {
          updateTaskFormat: {
            id: taskId,
            format,
            __typename: "Task",
          },
        },
      });
    },
    [taskId, updateName]
  );

  const isClassic = selectedMultiFormatType === TaskFormat.Classic;
  const isMultiFormat = selectedMultiFormatType === TaskFormat.Multiformat;
  const cards = (
    <OptionCardsContainer role="radiogroup">
      <OptionCard
        selected={isMultiFormat}
        onClick={() => onSelectMultiFormatType(TaskFormat.Multiformat)}
        data-value="True"
        aria-checked={isMultiFormat}
        titleComponent={
          <>
            <Text kind="headingFive" as="span">
              Multi format builder
            </Text>
            <NewPill />
          </>
        }
        descriptionText="For a mix of multiple choice, short and long answer questions"
        name="multiFormat"
      />
      <OptionCard
        selected={isClassic}
        onClick={() => {
          onSelectMultiFormatType(TaskFormat.Classic);
        }}
        data-value="True"
        aria-checked={isClassic}
        name="classic"
        titleText="Classic Cadmus"
        descriptionText="For an extended response like essays, reports and case studies"
      />
    </OptionCardsContainer>
  );

  return (
    <Modal.Root open={open}>
      <Modal.Content withCloseButton={false} width={636}>
        <Container>
          <TaskFormatHeader>
            <StyledModalTitle>
              <Text kind="headingThree" as="span">
                Select the format of your assessment
              </Text>
            </StyledModalTitle>
            <StyledModalDescription>
              <TrackedAnchor
                linkId={"ia-header-tooltip-access"}
                href="https://support.cadmus.io/introduction-to-mfa"
                target="_blank"
              >
                Learn more about assessment formats
              </TrackedAnchor>
            </StyledModalDescription>
          </TaskFormatHeader>

          {cards}

          <ConfirmButton isSelected={!selectedMultiFormatType ? false : true}>
            <Button
              kind="secondary"
              onClick={() => {
                onUpdateTaskFormat(selectedMultiFormatType!);
                onExitComplete?.();
              }}
            >
              CONFIRM
            </Button>
          </ConfirmButton>
        </Container>
      </Modal.Content>
    </Modal.Root>
  );
};

export interface ConnectedTaskFormatModalProps {
  format?: TaskFormat;
  taskId: string;
  onClose?: VoidFunction;
}

export const ConnectedTaskFormatModal =
  NiceModal.create<ConnectedTaskFormatModalProps>(
    ({ format, taskId, onClose }) => {
      const [selectedMultiFormatType, setSelectedMultiFormatType] = useState<
        TaskFormat | undefined
      >(format);

      const handleSelectMultiFormatType = (newType: TaskFormat) => {
        setSelectedMultiFormatType(newType);
      };

      const modal = useModal();
      return (
        <TaskFormatModal
          open={modal.visible}
          onExitComplete={() => {
            modal.remove();
            if (selectedMultiFormatType === TaskFormat.Classic && onClose) {
              onClose();
            }
          }}
          taskId={taskId}
          selectedMultiFormatType={selectedMultiFormatType}
          onSelectMultiFormatType={handleSelectMultiFormatType}
        />
      );
    }
  );

const StyledModalTitle = styled(Modal.Title)`
  margin: 0;
`;

const StyledModalDescription = styled(Modal.Description)`
  margin: 0;
`;

const Container = styled.div`
  padding: 52px 82px;
`;

const OptionCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 40px;
`;

const TaskFormatHeader = styled.div`
  text-align: center;
  width: 100%;
`;
const NewPill = () => (
  <PillContainer>
    <Text kind="label" style={{ color: colors.lilac500 }}>
      New
    </Text>
  </PillContainer>
);

const PillContainer = styled.div`
  background-color: ${colors.white100};
  border: 1px solid ${colors.lilac500};
  border-radius: 8px;
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConfirmButton = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-content: right;
  padding-top: 24px;
  visibility: ${(p) => (p.isSelected ? "visible" : "hidden")};
`;
