import styled from "styled-components";

import { Color, Text, Tooltip } from "@vericus/cadmus-ui";

import { Rates } from "@/features/moderation/types";
import { QuestionBlockType } from "@/features/multi-format/components/question-type-dropdown-menu";
import { QuestionTypePill } from "@/features/question-bank-file-import/components/tables/question-type-pill";

import { EmptyCell } from "./header";
import * as styles from "./question-list.css";

interface QuestionRowProps {
  /**
   * Short version of the question prompt.
   */
  shortPrompt: string | null;
  /**
   * Type of the question being displayed.
   */
  questionType: QuestionBlockType;
  /**
   * Label for the order or sequence of the question
   */
  orderLabel: string | null;
  /**
   * Indicates whether the question has changes by markers that need review.
   */
  hasMarkerChanges: boolean;
  /**
   * The response and success rates for the question
   */
  rates: Rates | null;
}

/**
 *  Displays the collapsed state of the question in Question List on Moderation
 *  Page.
 */
export function QuestionRow(props: QuestionRowProps) {
  return (
    <>
      <div className={styles.column.order}>
        <Text kind="bodyMd" color="grey500">
          {props.orderLabel}.
        </Text>
      </div>
      <div className={styles.column.small}>
        <QuestionTypePill questionType={props.questionType} />
      </div>
      <div className={styles.column.question}>
        <QuestionCell prompt={props.shortPrompt} order={props.orderLabel} />
      </div>
      <div className={styles.column.small}>
        {props.hasMarkerChanges ? <ReviewPill /> : <EmptyCell />}
      </div>
      <div className={styles.column.small}>
        <RateCell rate={props.rates?.responseRate ?? null} />
      </div>
      <div className={styles.column.medium}>
        <RateCell
          rate={props.rates?.successRate ?? null}
          color="functionalSuccess"
        />
      </div>
    </>
  );
}

/**
 * Displays the cell for the question column.
 *
 * When the accordion is collapsed, it displays the short prompt. When the
 * accordion is expanded, it displays the question number, because the full
 * prompt will be displayed in the expanded content.
 *
 * Conditional rendering of those items depending on the accordion state is
 * controlled by CSS due to data-state attribute or radix accordion.
 */
function QuestionCell(props: { prompt: string | null; order: string | null }) {
  return (
    <>
      <WrappedText
        kind="bodyMd"
        color="neutralBlack300"
        className={styles.header}
      >
        {props.prompt ? `${props.prompt}...` : ""}
      </WrappedText>
      <WrappedText
        kind="bodyMd"
        color="neutralBlack300"
        bold={true}
        className={styles.order}
      >
        Question {props.order}
      </WrappedText>
    </>
  );
}

function ReviewPill() {
  return (
    <span className={styles.pill.review}>
      <Tooltip
        content={
          "Accepted answers have been added or removed for this question by a marker"
        }
        sideOffset={18}
      >
        <Text kind="systemSm" color="black100" bold={true} as="span">
          REVIEW
        </Text>
      </Tooltip>
    </span>
  );
}

function RateCell(props: { rate: number | null; color?: Color }) {
  return (
    <Text kind="headingSix" color={props.color}>
      {props.rate !== null ? `${props.rate}%` : "--"}
    </Text>
  );
}

const WrappedText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
