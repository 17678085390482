// Functions that can parse a string into a type or fail with `null`.
type Parser<T> = (v: string) => T | null;

// Parser for a number
// TODO actually restrict it to positive integers
export const intParser: Parser<number> = (value) => {
  try {
    const num = parseInt(value, 10);
    return isNaN(num) ? null : num;
  } catch (err) {
    return null;
  }
};

// Parser for a boolean.
export const boolParser: Parser<boolean> = (value) =>
  value === "true" ? true : value === "false" ? false : null;

/**
 * Get a parsed item from localStorage.
 *
 * @param prefix Key prefix
 * @param name Actual key name
 * @param parser Parse the underlying value to a useable type.
 * @return parsed localStorage value or `null` if not found or parser error.
 */
export function getItem<T>(
  prefix: string,
  name: string,
  parser: Parser<T>
): T | null {
  const key = `${prefix}:${name}`;

  try {
    const value = localStorage.getItem(key);
    return value ? parser(value) : null;
  } catch (err) {
    console.error(err);
    return null;
  }
}

/**
 * Write an item to localStorage.
 *
 * @param prefix Key prefix
 * @param name Actual key name
 * @param value raw string value to write.
 */
export const setItem = (prefix: string, name: string, value: string) => {
  const key = `${prefix}:${name}`;
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    console.error(err);
  }
};
