import { styled } from "styled-components";

import { Radio, Spacer, Text } from "@vericus/cadmus-ui";

interface Props {
  gradingService: string;
  setGradingService: (service: string) => void;
  hasMoodleGrader?: boolean;
  hasTurnitinGrader?: boolean;
  hasSpeedGrader?: boolean;
  hasCadmusGrader?: boolean;
}

/**
 * Radio group selector for the Marking Tool.
 */
export function MarkingToolRadio(props: Props) {
  const {
    hasMoodleGrader = false,
    hasTurnitinGrader = true,
    hasSpeedGrader = false,
    hasCadmusGrader = false,
  } = props;

  const noGrader =
    !hasMoodleGrader &&
    !hasTurnitinGrader &&
    !hasSpeedGrader &&
    !hasCadmusGrader;

  return (
    <FieldSet>
      <legend>
        <Text kind="headingFive" as="span">
          Marking Tool
        </Text>
      </legend>
      <Spacer spacing={9} />
      {hasCadmusGrader && (
        <Radio
          name="gradingService"
          content="Use Cadmus Grader for marking"
          value="cadmusgrader"
          checked={props.gradingService === "cadmusgrader"}
          onChange={() => props.setGradingService("cadmusgrader")}
        />
      )}
      {noGrader && (
        <Radio
          name="gradingService"
          content="Use Turnitin Feedback Studio for marking"
          value="turnitin"
          defaultChecked={props.gradingService === "turnitin"}
          disabled
          readOnly
        />
      )}
      {hasTurnitinGrader && (
        <Radio
          name="gradingService"
          content="Use Turnitin Feedback Studio for marking"
          value="turnitin"
          checked={props.gradingService === "turnitin"}
          onChange={() => props.setGradingService("turnitin")}
        />
      )}
      {hasMoodleGrader && (
        <Radio
          name="gradingService"
          content="Use Moodle Grader for marking"
          value="moodlegrader"
          checked={props.gradingService === "moodlegrader"}
          onChange={() => props.setGradingService("moodlegrader")}
        />
      )}
      {hasSpeedGrader && (
        <Radio
          name="gradingService"
          content="Use Canvas SpeedGrader for marking"
          value="speedgrader"
          checked={props.gradingService === "speedgrader"}
          onChange={() => props.setGradingService("speedgrader")}
        />
      )}
    </FieldSet>
  );
}

const FieldSet = styled.fieldset`
  border: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
`;
