import { isAfter, isBefore } from "date-fns";

import { InstructionSheetFragment } from "@/generated/graphql";

import { isExam, isExamClosed } from "./exam-utils";

/**
 * Determine whether the feedback date for this assessment has not passed yet.
 * NOTE: If an exam has noExamFeedbackDate true returns true.
 */
export function isBeforeFeedbackDate(
  sheet: Pick<
    InstructionSheetFragment,
    "returnDate" | "noExamFeedbackDate" | "examFeedbackDate" | "assessmentType"
  >
): boolean {
  const { returnDate, noExamFeedbackDate, examFeedbackDate } = sheet;
  if (isExam(sheet)) {
    if (noExamFeedbackDate || !examFeedbackDate) return true;
    return isBefore(new Date(), new Date(examFeedbackDate));
  }
  return isBefore(new Date(), new Date(returnDate));
}

/**
 * @returns the feedback return date for the assessment
 */
export function getAssessmentReturnDate(
  sheet: Pick<
    InstructionSheetFragment,
    "returnDate" | "noExamFeedbackDate" | "examFeedbackDate" | "assessmentType"
  >
): string | null {
  const { examFeedbackDate, noExamFeedbackDate, returnDate } = sheet;
  if (isExam(sheet)) {
    if (!noExamFeedbackDate) return examFeedbackDate;
    return null;
  }
  return returnDate;
}

/**
 * @returns whether the assessment is no longer accepting submissions
 * (ignoring any special considerations/extensions)
 */
export function isAssessmentClosed(
  sheet: Pick<
    InstructionSheetFragment,
    | "assessmentType"
    | "dueDate"
    | "examReadingTime"
    | "examWritingTime"
    | "examTiming"
    | "examStartDate"
    | "examEndDate"
  >
): boolean {
  if (isExam(sheet)) {
    return isExamClosed(sheet);
  }
  return !!sheet.dueDate && isAfter(new Date(), new Date(sheet.dueDate));
}
