import { useCallback } from "react";

import { useJitsu } from "@jitsu/react";
import toast from "react-hot-toast";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { useSyncGradesMutation } from "@/generated/graphql";

/**
 * Create a callback to invoke the syncGrades mutation with notifications.
 */
export const useSyncGrades = (assessmentId: string) => {
  const [mutate] = useSyncGradesMutation({
    variables: { assessmentId },
    ignoreResults: true,
  });
  const { track } = useJitsu();

  return useCallback(() => {
    const promise = mutate();
    track(JitsuEvent.GRADE_SYNCED);
    toast.promise(promise, {
      loading: `Syncing grades to the LMS...`,
      success: `Grades successfully synced with the LMS`,
      error: "Sorry! Something went wrong.",
    });
    return promise;
  }, [mutate, track]);
};
