import { RootState } from "data/store";

function getTask({ task }: RootState) {
  return task;
}

export const selectIsSaving = (state: RootState) => {
  return state.task.savesInFlight.length > 0;
};

export function getAssessmentId(state: RootState): string | null {
  return getTask(state).assessmentId;
}
