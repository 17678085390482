import { useState } from "react";

import { Icon, IconButton, Pill, Popover, Text } from "@vericus/cadmus-ui";

import { useJitsu } from "@jitsu/react";
import { CellContext } from "@tanstack/react-table";

import { JitsuEvent } from "@/data/events/JitsuEvent";
import { SubmissionDetails } from "@/graphql/types/SubmissionDetails";
import { StudentListMeta, StudentListRow } from "@/ui/class/progress/types";
import { SpecialConsiderationPill } from "@/ui/exam-special-considerations/SpecialConsiderationPill";
import { COMPACT_DATE_FORMAT, formatDate } from "@/utils/datetime";

import { DeferredMessage } from "./DeferredMessage";
import { ExtensionPill } from "./ExtensionPill";
import { ForceSubmittedPill } from "./ForceSubmittedPill";
import * as Grid from "./InfoGrid";
import {
  singleDisplayTags,
  TagList,
  TagSelectorIndividual,
} from "./TagSelector";
import { WithdrawnMessage } from "./WithdrawnMessage";

interface Props extends CellContext<StudentListRow, SubmissionDetails | null> {
  /** Render the status cell for a draft submission */
  isDraft?: boolean;

  /** Render late tag in Submission Column
   *
   * This control is added because we don't want to show
   * late tag in the submission column for the Moderation Table.
   *
   * @default true
   */
  lateTagRequired?: boolean;
}

/**
 * React table cell renderer for displaying the submission status (timestamp)
 * and tags for each class list row under the drafts or finals class list table.
 */
export function SubmissionStatusCell({
  row,
  getValue,
  table,
  isDraft,
  lateTagRequired = true,
}: Props) {
  const { track } = useJitsu();
  const [tagsPopoverOpen, setTagsPopoverOpen] = useState(false);

  const submission = getValue();
  const {
    displayFirstName,
    enrollment,
    tags,
    tab,
    withdrawn,
    withdrawnBy,
    settings,
    deferred,
  } = row.original!;
  const { addTag, removeTag, deleteCustomTag, tagConfigs } = table.options
    .meta! as StudentListMeta;
  const tagTexts = tags.map((t) => t.text);

  if (!submission) {
    return <Grid.StatusCol />;
  }

  if (withdrawn) {
    return (
      <Grid.StatusCol>
        <WithdrawnMessage withdrawnBy={withdrawnBy} />
      </Grid.StatusCol>
    );
  }

  if (deferred) {
    return (
      <Grid.StatusCol>
        <DeferredMessage />
      </Grid.StatusCol>
    );
  }

  return (
    <Grid.StatusCol>
      <Text kind="bodySm">
        {formatDate(submission.timestamp, COMPACT_DATE_FORMAT)}
      </Text>
      {!settings.isExam && !isDraft && (
        <ExtensionPill userName={displayFirstName} settings={settings} />
      )}
      {settings.hasSpecialConsideration && (
        <SpecialConsiderationPill
          anonymised={row.original.anonymous}
          enrollment={row.original.enrollment}
          examSettings={settings}
        />
      )}
      {submission.overtime && <Pill basic>{submission.overtime}</Pill>}
      {submission.late && lateTagRequired && (
        <Pill basic>{submission.late}</Pill>
      )}
      {submission.forceSubmitted && submission.submittedBy && (
        <ForceSubmittedPill submittedBy={submission.submittedBy} />
      )}
      <TagList tags={tagTexts} tagConfigs={tagConfigs} />
      <Popover.Root open={tagsPopoverOpen}>
        <Popover.Trigger asChild>
          <IconButton
            className="hidden-controls"
            onClick={() => {
              setTagsPopoverOpen(!tagsPopoverOpen);
            }}
          >
            <Icon iconName="AddTag" />
          </IconButton>
        </Popover.Trigger>
        <Popover.Content
          onInteractOutside={() => {
            setTagsPopoverOpen(false);
          }}
        >
          <TagSelectorIndividual
            tags={singleDisplayTags(tagTexts, tagConfigs)}
            onAddTag={(text, isCustom = false) => {
              addTag([enrollment], text, tab);
              track(JitsuEvent.ENROLLMENT_TAG_ADDED, {
                tag_name: text,
                class_tab: tab,
                enrollment_id: enrollment.id,
                student_id: enrollment.user.id,
                is_custom: isCustom,
              });
            }}
            onRemoveTag={(text, isCustom = false) => {
              removeTag([enrollment], text, tab);
              track(JitsuEvent.ENROLLMENT_TAG_REMOVED, {
                tag_name: text,
                class_tab: tab,
                enrollment_id: enrollment.id,
                student_id: enrollment.user.id,
                is_custom: isCustom,
              });
            }}
            onCreateTag={(text) => {
              addTag([enrollment], text, tab);
              track(JitsuEvent.ENROLLMENT_TAG_ADDED, {
                tag_name: text,
                class_tab: tab,
                enrollment_id: enrollment.id,
                student_id: enrollment.user.id,
                is_custom: true,
              });
            }}
            onDeleteTag={(text) => {
              deleteCustomTag(text, tab);
              setTagsPopoverOpen(false);
            }}
          />
        </Popover.Content>
      </Popover.Root>
    </Grid.StatusCol>
  );
}
