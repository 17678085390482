import { useState } from "react";

import { Button, Checkbox, Modal, Text } from "@vericus/cadmus-ui";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useDispatch } from "react-redux";

import { dismissedFeedbackReleaseDisclaimer } from "@/data/localFlags";

import * as styles from "./feedback-release-disclaimer.css";

interface ModalProps {
  onConfirm: () => void;
}

/** FeedbackReleaseDisclaimer connected to NiceModal */
export const FeedbackReleaseDisclaimerModal = NiceModal.create<ModalProps>(
  (props) => {
    const modal = useModal();
    return (
      <FeedbackReleaseDisclaimer
        open={modal.visible}
        onOpenChange={(open) => (open ? modal.show() : modal.hide())}
        onConfirm={props.onConfirm}
      />
    );
  }
);

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onConfirm: () => void;
}

/** Controlled Feedback Release Disclaimer Modal */
export function FeedbackReleaseDisclaimer(props: Props) {
  const [dismissed, setDismissed] = useState(false);
  const dispatch = useDispatch();

  return (
    <Modal.Root open={props.open} onOpenChange={props.onOpenChange}>
      <Modal.Content className={styles.root}>
        <div className={styles.des}>
          <Text kind="headingFour">Feedback release disclaimer</Text>
          <Text kind="bodyMd" color="navy400">
            Once the feedback has been released for any student in the class, no
            further class-wide moderation decisions like adding penalties or
            updating answers will be possible.
          </Text>
        </div>
        <div className={styles.term}>
          <Checkbox
            checked={dismissed}
            onChange={(e) => setDismissed(e.target.checked)}
            content="I understand that I won’t be able to make moderation decisions after the feedback for any student has been released."
          />
        </div>
        <div className={styles.action}>
          <Modal.Close asChild>
            <Button kind="outline">cancel</Button>
          </Modal.Close>
          <Modal.Close asChild>
            <Button
              kind="secondary"
              onClick={() => {
                if (dismissed) dispatch(dismissedFeedbackReleaseDisclaimer());
                props.onConfirm();
              }}
              disabled={!dismissed}
            >
              Accept
            </Button>
          </Modal.Close>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
}
