import { createFormContext } from "@mantine/form";
import { addMinutes, isBefore } from "date-fns";

import { ExamTiming, RequirementsFragment } from "@/generated/graphql";

const [SettingsFormProvider, useSettingsFormContext, useForm] =
  createFormContext<RequirementsFragment>();

/**
 * Given initial requirements. It returns a stateful settings
 * form with validation set up.
 */
const useSettingsForm = (requirements: RequirementsFragment) =>
  useForm({
    initialValues: { ...requirements },
    validate: {
      examStartDate: (value) => (value ? null : "Select a date"),
      examEndDate: (value, { examTiming, examStartDate }) => {
        if (examTiming === ExamTiming.Live) return null;
        if (!value) return "Select a date";
        if (examStartDate) {
          const examEnd = new Date(value);
          const examStart = new Date(examStartDate);
          if (isBefore(examEnd, examStart)) {
            return "Exam close must be after exam open";
          }
        }
        return null;
      },
      examReadingTime: (
        value,
        {
          noExamReadingTime,
          examStartDate,
          examTiming,
          examEndDate,
          examWritingTime,
        }
      ) => {
        if (!value && !noExamReadingTime) return "Please add duration";
        if (examTiming === ExamTiming.Window && examEndDate) {
          const inferredEndDate = new Date(
            inferLiveExamEndDate({
              examReadingTime: value,
              examWritingTime,
              noExamReadingTime,
              examStartDate,
            })
          );
          if (isBefore(new Date(examEndDate), inferredEndDate)) {
            return "Cannot be longer than the exam window";
          }
        }
        return null;
      },
      examWritingTime: (
        value,
        {
          examStartDate,
          examReadingTime,
          noExamReadingTime,
          examTiming,
          examEndDate,
        }
      ) => {
        if (!value) return "Please add duration";
        if (examTiming === ExamTiming.Window && examEndDate) {
          const inferredEndDate = new Date(
            inferLiveExamEndDate({
              examReadingTime,
              examWritingTime: value,
              noExamReadingTime,
              examStartDate,
            })
          );
          if (isBefore(new Date(examEndDate), inferredEndDate)) {
            return "Cannot be longer than the exam window";
          }
        }
        return null;
      },
      examType: (value) =>
        value.length > 0 ? null : "You must identify an exam type",
      maxGrade: (value) => (value >= 0 && value <= 100 ? null : "Please fill"),
      examFeedbackDate: (
        value,
        { noExamFeedbackDate, examStartDate, gradingService }
      ) => {
        if (noExamFeedbackDate || gradingService === "cadmusgrader")
          return null;
        if (!value) return "Please fill";
        if (
          examStartDate &&
          isBefore(new Date(value), new Date(examStartDate))
        ) {
          return "Must be after exam open";
        }
        return null;
      },
    },
    validateInputOnChange: [
      "examReadingTime",
      "examWritingTime",
      "maxGrade",
      "wordLimit",
      "lateSubmissionTimeLimit",
      "weight",
      "examFeedbackDate",
    ],
  });

/**
 * Finds when the exam will end using start date + reading time + writing time.
 * NOTE: falls back to using now as start date if the input date is `null`.
 * @returns an ISO 8601 date string for when the exam will end.
 */
export const inferLiveExamEndDate = (
  reqs: Pick<
    RequirementsFragment,
    | "examStartDate"
    | "examReadingTime"
    | "examWritingTime"
    | "noExamReadingTime"
  >
): string => {
  return addMinutes(
    reqs.examStartDate ? new Date(reqs.examStartDate) : new Date(),
    (reqs.noExamReadingTime ? 0 : reqs.examReadingTime ?? 0) +
      (reqs.examWritingTime ?? 0)
  ).toISOString();
};

export { SettingsFormProvider, useSettingsForm, useSettingsFormContext };
