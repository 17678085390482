import { useCallback, useMemo, useState } from "react";

import { Color, Desk, SectionLabel, Spacer, Text } from "@vericus/cadmus-ui";

import moment, { Moment } from "moment";
import {
  dateColors,
  DateTimeGrid,
  DeprecatedDatePicker,
  getHighlightDays,
  GridDate,
  GridTime,
  Legend,
  TimePicker,
  useJumpToToday,
} from "ui/DatePicker";

import { Footer } from "@/features/task/settings/TaskTimeline/Footer";
import { Header } from "@/features/task/settings/TaskTimeline/Header";
import { QuickOptions } from "@/features/task/settings/TaskTimeline/QuickOptions";

import {
  Step,
  useIsOutsideRange,
  validateSettingDraftDueDate,
  validateSettingDueDate,
  validateSettingReturnDate,
} from "./internal";

export type DateSettings = {
  dueDate: string | undefined;
  returnDate: string | undefined;
  draftDueDate: string | undefined;
};

interface TaskTimelineProps {
  /** Current final due date */
  dueDate: string | null;
  /** Current feedback return date */
  returnDate: string | null;
  /** Current draft due date */
  draftDueDate: string | null;
  /** prevents selection of the Draft due date and the display
   * of the Draft Due date selection step.
   */
  noDraft: boolean;
  /** Callback to update new dates picked in the local Settings state. */
  setDates(dates: {
    dueDate?: string;
    returnDate?: string;
    draftDueDate?: string;
  }): void;
  /** Callback to provide "go back" functionality after user cancel.  */
  onCancel(): void;
  /** Callback to provide "go next" functionality after user confirm date changes */
  onConfirm(dateSettings: DateSettings): void;
  /* Optional starting step, only used if the final 'dueDate' is not null. */
  initialStep?: Step;
}

/**
 * Linear update workflow for Task dates: final due date, feedback return date,
 * and optionally draft due date.
 *
 * Renders date time pickers for each date selection. Date selection is almost
 * linear, but the initial step can be set on mount using the `initialStep`
 * prop. The `Step` type is an enum distinguishing between final, return, and
 * draft due date picking steps.
 *
 * Final and Return dates are set together, while draft due can be set
 * separately. There are certain validation rules enforced on the temporal
 * orderings of the dates. These rules are:
 *
 *   - Return date is after Due Date
 *   - Draft due date is before due date
 *
 * If the validations fail, the offending date is reset to null. This is rarely
 * the case when simply following the linear workflow of setting the
 * Final-Return date and then the Draft date.
 *
 * Time is also selected alongside the date. While the time picker is separate,
 * it is merged with the currently selected date for the currently rendering
 * step.
 */
export function TaskTimeline({
  initialStep,
  setDates,
  onCancel,
  onConfirm,
  noDraft,
  ...props
}: TaskTimelineProps) {
  // State for current selection step
  const [step, setStep] = useState(() =>
    initialStep && props.dueDate ? initialStep : Step.Final
  );

  // Local moment states of the incoming dates.
  const [dueDate, setDueDate] = useState<Moment | null>(
    props.dueDate ? moment(props.dueDate) : null
  );
  const [returnDate, setReturnDate] = useState<Moment | null>(
    props.returnDate ? moment(props.returnDate) : null
  );
  const [draftDueDate, setDraftDueDate] = useState<Moment | null>(
    props.draftDueDate ? moment(props.draftDueDate) : null
  );

  // validation
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined
  );

  // Date value and updater for that value for the current step.
  let date;
  let setDate: (d: Moment | null) => void;
  let selectedColor: Color;
  switch (step) {
    case Step.Final:
      date = dueDate;
      setDate = (d) => {
        setDueDate(d);
        if (d) {
          validateSettingDueDate(
            d.toDate(),
            (e) => setErrorMessage(e),
            () => setErrorMessage(undefined)
          );
        }
      };
      selectedColor = dateColors.dueDate;
      break;
    case Step.Return:
      date = returnDate;
      setDate = (d) => {
        setReturnDate(d);
        if (d && dueDate) {
          validateSettingReturnDate(
            dueDate.toDate(),
            d.toDate(),
            (e) => setErrorMessage(e),
            () => setErrorMessage(undefined)
          );
        }
      };
      selectedColor = dateColors.returnDate;
      break;
    case Step.Draft:
      date = draftDueDate;
      setDate = (d) => {
        setDraftDueDate(d);

        if (d && dueDate) {
          validateSettingDraftDueDate(
            dueDate.toDate(),
            d.toDate(),
            (e) => setErrorMessage(e),
            () => setErrorMessage(undefined)
          );
        }
      };
      selectedColor = dateColors.draftDueDate;
      break;
  }

  // Callback to confirm updates to all the currently set dates.
  const handleConfirmDates = useCallback(() => {
    const dates: DateSettings = {
      dueDate: dueDate ? dueDate.toISOString() : undefined,
      returnDate: returnDate ? returnDate.toISOString() : undefined,
      draftDueDate: draftDueDate ? draftDueDate.toISOString() : undefined,
    };
    setDates(dates);
    onConfirm(dates);
  }, [dueDate, returnDate, draftDueDate, setDates, onConfirm]);

  // Days to highlight in the currently display step.
  const highlightDays = useMemo(
    () =>
      getHighlightDays({
        dueDate: step !== Step.Final ? dueDate?.toISOString() : undefined,
        returnDate: step === Step.Draft ? returnDate?.toISOString() : undefined,
      }),
    [step, dueDate, returnDate]
  );

  // Callback to jump the visible month to today's month.
  const [todayKey, jumpToToday] = useJumpToToday();
  // Predicate to disable certain date ranges.
  const isOutsideRange = useIsOutsideRange(step, dueDate);

  return (
    <Desk.Layout>
      <Desk.Table>
        <Spacer spacing={90} />
        <Header step={step} />
        <Spacer spacing={45} />
        <DateTimeGrid>
          <GridDate>
            <SectionLabel title="Select a date" />
            <DeprecatedDatePicker
              date={date}
              setDate={setDate}
              selectedColor={selectedColor}
              highlightDays={highlightDays}
              isOutsideRange={isOutsideRange}
              todayKey={todayKey}
            />
            <Spacer spacing={9} />
            <Text kind="headingSix" as="span">
              Quick Options
            </Text>
            <Spacer spacing={9} />
            <QuickOptions
              dueDate={dueDate}
              setDate={setDate}
              step={step}
              jumpToToday={jumpToToday}
            />
            <Spacer spacing={45} />
          </GridDate>
          <GridTime>
            <SectionLabel title="Select a time" />
            <Spacer spacing={18} />
            <TimePicker
              disabled={date === null}
              time={date}
              setTime={setDate}
              defaultTime="23:59"
              errorMessage={errorMessage}
            />
            <Legend
              dueDate={dueDate && dueDate.toDate()}
              returnDate={returnDate && returnDate.toDate()}
              draftDueDate={
                noDraft ? undefined : draftDueDate && draftDueDate.toDate()
              }
            />
          </GridTime>
        </DateTimeGrid>
        <Spacer spacing={108} />
      </Desk.Table>
      <Desk.Drawer>
        <Footer
          step={step}
          setStep={setStep}
          onConfirm={handleConfirmDates}
          onCancel={onCancel}
          noDraft={noDraft}
          disabled={date === null || errorMessage !== undefined}
          dueDate={dueDate}
          returnDate={returnDate}
          draftDueDate={draftDueDate}
        />
      </Desk.Drawer>
    </Desk.Layout>
  );
}
