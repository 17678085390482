import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { saveTask, trackNetworkRequest } from "./actions";

export interface TaskState {
  assessmentId: string | null;
  /** Current contents have not been successfully saved. */
  isUnsavedTask: boolean;
  /** Save requests' redux requestId that are still in flights*/
  savesInFlight: string[];
  /** Has the store state gone through its lifecycle of being hydrated */
  hydrated: boolean;
}

export const initialState: TaskState = {
  assessmentId: null,
  isUnsavedTask: false,
  savesInFlight: [],
  hydrated: false,
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    /**
     * Hydration is called generally after we find out some more information about its state.
     * This step exists because the initial state isn't smart and needs more information before
     * this part of the Redux store is more useful.
     *
     * Upon hydration, a flag `hydrated` is set to `true`, signalling that the state is generally
     * ready for consumption.
     * @param state
     * @param action
     */
    hydrateTaskState(state, action: PayloadAction<{ assessmentId: string }>) {
      /**
       * This could hydrate other properties in the state but for now,
       * we just hydrate the assessment ID.
       */
      state.assessmentId = action.payload.assessmentId;
      state.hydrated = true;
    },
  },
  extraReducers: (builder) => {
    // Track Task Save Requests
    builder.addCase(saveTask.pending, (state, action) => {
      state.isUnsavedTask = true;

      state.savesInFlight = trackRequest(
        state.savesInFlight,
        action.meta.requestId
      );
    });
    builder.addCase(saveTask.fulfilled, (state, action) => {
      state.isUnsavedTask = false;

      state.savesInFlight = untrackRequest(
        state.savesInFlight,
        action.meta.requestId
      );
    });
    builder.addCase(saveTask.rejected, (state, action) => {
      state.isUnsavedTask = true;

      state.savesInFlight = untrackRequest(
        state.savesInFlight,
        action.meta.requestId
      );
    });

    // Track other arbitrary requests related to the task
    // add the request ID being tracked
    builder.addCase(trackNetworkRequest.pending, (state, action) => {
      state.savesInFlight = trackRequest(
        state.savesInFlight,
        action.meta.requestId
      );
    });
    builder.addCase(trackNetworkRequest.fulfilled, (state, action) => {
      state.savesInFlight = untrackRequest(
        state.savesInFlight,
        action.meta.requestId
      );
    });
    // TODO: communicate and address failure state to as part of
    //  https://cadmus.atlassian.net/browse/CDMS-755
    builder.addCase(trackNetworkRequest.rejected, (state, action) => {
      state.savesInFlight = untrackRequest(
        state.savesInFlight,
        action.meta.requestId
      );
    });
  },
});

function trackRequest(inFlights: string[], requestId: string) {
  if (inFlights.includes(requestId)) {
    return inFlights;
  }
  return [requestId, ...inFlights];
}

function untrackRequest(inFlights: string[], requestId: string) {
  return inFlights.filter((rid) => rid !== requestId);
}

export const taskReducer = taskSlice.reducer;
export const { hydrateTaskState } = taskSlice.actions;
