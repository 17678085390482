import { useMemo } from "react";

import { Color } from "@vericus/cadmus-ui";

import { Moment } from "moment";
import {
  dateColors,
  isDateInvalid,
  isOutsideRangePredicate,
} from "ui/DatePicker";

/** Selection step */
export enum Step {
  Final,
  Return,
  Draft,
}

/**
 * Create a predicate to check if the passed date is outside the valid pickable
 * range in a calendar.
 */
export function useIsOutsideRange(step: Step, dueDate: Moment | null) {
  return useMemo(() => {
    switch (step) {
      // due date - from now onwards
      case Step.Final: {
        return isOutsideRangePredicate([new Date()], []);
      }

      // return date - from now / dueDate onwards
      case Step.Return: {
        const before = [new Date()];
        dueDate && before.push(dueDate.toDate());
        return isOutsideRangePredicate(before, []);
      }

      // draft due date - from now onwards until due date
      case Step.Draft: {
        const after = dueDate ? [dueDate.toDate()] : [];
        return isOutsideRangePredicate([new Date()], after);
      }
    }
  }, [step, dueDate]);
}

/**
 * Validate setting dueDate
 * dueDate has to be from now onwards.
 */
export const validateSettingDueDate = (
  dueDate: Date,
  onError: (e: string) => void,
  onSuccess: () => void,
  now: Date = new Date()
): void => {
  if (
    isDateInvalid(dueDate, [now], [], () =>
      onError("final due set in the past")
    )
  )
    return;

  onSuccess();
};

/**
 * Validate setting returnDate
 * returnDate has to be from now and dueDate onwards.
 */
export const validateSettingReturnDate = (
  dueDate: Date,
  returnDate: Date,
  onError: (e: string) => void,
  onSuccess: () => void,
  now: Date = new Date()
): void => {
  if (
    isDateInvalid(returnDate, [now], [], () =>
      onError("return date set in the past")
    )
  )
    return;

  if (
    isDateInvalid(returnDate, [dueDate], [], () =>
      onError("return date before final due")
    )
  )
    return;

  onSuccess();
};

/**
 * Validate setting draftDueDate
 * draftDueDate has to be from now onwards until dueDate.
 */
export const validateSettingDraftDueDate = (
  dueDate: Date,
  draftDue: Date,
  onError: (e: string) => void,
  onSuccess: () => void,
  now: Date = new Date()
): void => {
  if (
    isDateInvalid(draftDue, [now], [], () =>
      onError("draft due set in the past")
    )
  )
    return;
  if (
    isDateInvalid(draftDue, [], [dueDate], undefined, () =>
      onError("draft due after final due")
    )
  )
    return;

  onSuccess();
};

/**
 * Data for `Header` in `TaskTimeline`.
 */
export const STEP_CONFIG: Record<
  Step,
  {
    number: number;
    text: string;
    color: Color;
    afterText: string;
    description: string;
  }
> = {
  [Step.Final]: {
    number: 1,
    text: "final",
    color: dateColors.dueDate,
    afterText: "due date",
    description:
      "Before the due date, students can re-submit as many times as they like. No re-submissions are allowed after the due date. Students can still submit late if they haven’t made any previous submissions.",
  },
  [Step.Return]: {
    number: 2,
    text: "feedback return",
    color: dateColors.returnDate,
    afterText: "date",
    description:
      "After this date and time, students can access their grades and feedback within Cadmus.",
  },
  [Step.Draft]: {
    number: 3,
    text: "draft",
    color: dateColors.draftDueDate,
    afterText: "due date",
    description:
      "Before the draft due date, students can re-submit as many times as they like. No submissions are allowed after the draft due date.",
  },
};
