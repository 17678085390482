import * as React from "react";
import { styled } from "styled-components";

import { Icon } from "@vericus/cadmus-icons";
import { Input, LinkButton, Radio, Spacer, Text } from "@vericus/cadmus-ui";

import {
  AssessmentFragment,
  AssessmentFragmentDoc,
  MarkingToolPdf,
  TaskFormat,
  useHasSubmissionSubscription,
} from "generated/graphql";
import { HeraLaunch, useHeraLaunch } from "utils/useHeraLaunch";

import { useAppSelector } from "@/data/hooks";
import { selectTaskTotalPoints } from "@/features/multi-format/task-layout";
import { useInstitutionFeaturesFragment } from "@/graphql/institution-selectors";

import { RestrictStudentView } from "../../components/restrict-student-view";
import { SettingInfoCard } from "../SettingInfoCard";
import { SettingContainer } from "../styles";
import { MarkingToolRadio } from "./MarkingToolRadio";

interface Props {
  assessmentId: string;
  hasSubmission: boolean;
  maxGrade: number;
  setMaxGrade: (value: number) => void;
  sViewReports: number;
  setSViewReports: (value: number) => void;
  anonymousMarking: boolean | null;
  setAnonymousMarking: (anonymous: boolean | null) => void;
  gradingService: string;
  setGradingService: (service: string) => void;
  hasCanvasAGS: boolean;
  format: TaskFormat;
  restrictMarkingToolPdf: boolean;
  setRestrictStudentResultView: (value: boolean) => void;
  markingToolPdf: MarkingToolPdf | null;
  setStudentResultView: (value: MarkingToolPdf | null) => void;
}

/**
 * View and set Grading requirements like /maxpoints/ and /sViewReports/.
 *
 * These fields have default server values and are therefore not nullable. The
 * default value for /maxpoints/ is 100 and for /sViewReports/ it is 0 (full
 * visibility).
 *
 * The /maxpoints/ input is a numerical input which only validates numbers
 * between 0 and 100 (inclusive).
 */
export function GradeSetting(props: Props) {
  const {
    maxGrade,
    sViewReports,
    setMaxGrade,
    setSViewReports,
    assessmentId,
    setAnonymousMarking,
    anonymousMarking,
    markingToolPdf,
    setStudentResultView,
    hasSubmission,
    restrictMarkingToolPdf,
    setRestrictStudentResultView,
    gradingService,
    setGradingService,
    hasCanvasAGS,
    format,
  } = props;

  const onHeraLaunch = useHeraLaunch({
    launch: HeraLaunch.Settings,
    assessmentId: assessmentId,
  });

  const {
    moodlegraderFeatureEnabled,
    turnitinGraderFeatureEnabled,
    cadmusGraderFeatureEnabled,
  } = useInstitutionFeaturesFragment();

  // Subscribe to submission event on the assessment
  useHasSubmissionSubscription({
    variables: {
      assessmentId: assessmentId,
    },
    // skip the subscription if assessment already has submission
    skip: hasSubmission,
    onData({ client, data: subscriptionData }) {
      const hasSubmission = subscriptionData.data?.hasSubmission;
      const id = `Assessment:${assessmentId}`;
      const cachedData = client.readFragment<AssessmentFragment>({
        id,
        fragment: AssessmentFragmentDoc,
      });
      const newAssessment = {
        ...cachedData,
        hasSubmission,
      };
      client.writeFragment({
        id,
        fragment: AssessmentFragmentDoc,
        data: newAssessment,
      });
    },
  });
  const taskTotalPoints = useAppSelector(selectTaskTotalPoints);

  const [localMaxGrade, setLocalMaxGrade] = React.useState(maxGrade);

  // Change handler for max grade input
  const validateOnBlur = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // validation 0 <= val and val <= 100
      const val = parseInt(event.currentTarget.value);
      let validatedValue = maxGrade;

      if (val >= 0 && val <= 1000) {
        validatedValue = val;
      } else if (val > 1000) {
        validatedValue = 1000;
      } else if (val < 0) {
        validatedValue = 0;
      }
      setMaxGrade(validatedValue);
      setLocalMaxGrade(validatedValue);
    },
    [setMaxGrade, setLocalMaxGrade, maxGrade]
  );

  return (
    <SettingContainer>
      <Text kind="headingOne">Marking + Feedback</Text>
      {turnitinGraderFeatureEnabled && (
        <Text kind="bodyLg" color="shade1">
          You can mark assessments using Turnitin Feedback Studio
          {hasCanvasAGS ? " or Canvas SpeedGrader" : ""}
          {!!moodlegraderFeatureEnabled && !hasCanvasAGS
            ? " or Moodle Grader"
            : ""}
          . Hide student names on submissions with anonymous marking.
        </Text>
      )}
      <Spacer spacing={18} />
      <Text kind="lead">
        <a
          href="https://support.cadmus.io/teachers/controlling-how-students-view-similarity-information"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledLinkButton kind="solid">
            Learn more about marking in Cadmus <Icon iconName="Right" />
          </StyledLinkButton>
        </a>
      </Text>
      <Spacer spacing={18} />
      <MarkingToolRadio
        gradingService={gradingService}
        setGradingService={setGradingService}
        hasSpeedGrader={hasCanvasAGS}
        hasMoodleGrader={moodlegraderFeatureEnabled}
        hasTurnitinGrader={turnitinGraderFeatureEnabled}
        hasCadmusGrader={
          cadmusGraderFeatureEnabled && format === TaskFormat.Multiformat
        }
      />
      <Spacer spacing={36} />

      {format === TaskFormat.Multiformat &&
        ["speedgrader", "moodlegrader"].includes(gradingService) && (
          <>
            <RestrictStudentView
              restrictMarkingToolPdf={restrictMarkingToolPdf}
              setRestrictStudentResultView={setRestrictStudentResultView}
              markingToolPdf={markingToolPdf}
              setStudentResultView={setStudentResultView}
            />
            <Spacer spacing={36} />
          </>
        )}
      <AnonymousMarking
        hasSubmission={hasSubmission}
        anonymousMarking={anonymousMarking}
        setAnonymousMarking={setAnonymousMarking}
        gradingService={gradingService}
      />
      <Spacer spacing={36} />
      <Text kind="headingOne">Turnitin</Text>
      {gradingService === "turnitin" && (
        <Text kind="bodyLg" color="shade1">
          Set the maximum points for grading in Turnitin’s Feedback Studio. Once
          the return date passes, students access grades and feedback within
          Cadmus. Grades will also be returned to the Learning Management System
          (e.g. Grade Centre)
        </Text>
      )}
      {gradingService === "speedgrader" && (
        <Text kind="bodyLg" color="shade1">
          All submissions are processed by Turnitin for similarity checking. You
          can control how students access this information.
        </Text>
      )}
      <Spacer spacing={18} />
      <Text kind="lead">
        <a
          href="https://support.cadmus.io/teachers/controlling-how-students-view-similarity-information"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledLinkButton kind="solid">
            Learn more about using Cadmus + Turnitin <Icon iconName="Right" />
          </StyledLinkButton>
        </a>
      </Text>
      <Spacer spacing={36} />
      {gradingService === "turnitin" && (
        <>
          <Text kind="headingFive" as="span">
            Max Points
          </Text>
          <Text kind="bodySm">
            The assessment is graded out of{" "}
            <Input
              value={`${
                format === TaskFormat.Multiformat
                  ? taskTotalPoints
                  : localMaxGrade
              }`}
              onChange={(e) =>
                setLocalMaxGrade(parseInt(e.currentTarget.value))
              }
              onBlur={validateOnBlur}
              disabled={format === TaskFormat.Multiformat}
              aria-label="grade"
              type="number"
              min={0}
              max={1000}
            />{" "}
            points
          </Text>
          <Spacer spacing={36} />
        </>
      )}
      <SViewReports
        view={sViewReports}
        setView={setSViewReports}
        canViewReportBeforeDueDate={
          gradingService === "turnitin" && format === TaskFormat.Multiformat
            ? false
            : true
        }
      />
      <Spacer spacing={36} />
      <StyledLinkButton iconName="Turnitin" onClick={onHeraLaunch}>
        View advanced Turnitin settings for Finals
      </StyledLinkButton>
    </SettingContainer>
  );
}

interface SViewReportsProps {
  view: number;
  setView: (value: number) => void;
  // Is the option to show the similarity report to students before the due date
  // available i.e. is level `2` allowed to be selected in the radio list.
  canViewReportBeforeDueDate: boolean;
}

// Visibility options for the similarity report generation for final
// submissions.
const options = [
  // NOTE This level will be disabled if `props.canViewReportBeforeDueDate` is false
  {
    content: "Allow students to view similarity before the final due date",
    level: 2,
  },
  {
    content: "Allow students to view similarity after the final due date",
    level: 1,
  },
  {
    content: "Don’t allow students to view similarity for final submissions",
    level: 0,
  },
];

// Render radio button options for selecting a /sViewReports/ value.
function SViewReports(props: SViewReportsProps) {
  return (
    <FieldSet>
      <legend>
        <Text kind="headingFive" as="span">
          Turnitin Similarity Reports for Finals
        </Text>
      </legend>
      <Text kind="bodySm" color="shade1">
        Control how students access similarity within Cadmus for final
        submissions
      </Text>
      <Spacer spacing={18} />
      {options.map((option) => (
        <Radio
          key={option.level}
          name="gradeSimilarity"
          content={option.content}
          value={option.level}
          checked={props.view === option.level}
          onChange={() => props.setView(option.level)}
          disabled={option.level === 2 && !props.canViewReportBeforeDueDate}
        />
      ))}
    </FieldSet>
  );
}

interface AnonymousMarkingProps {
  hasSubmission: boolean;
  anonymousMarking: boolean | null;
  setAnonymousMarking: (anonymous: boolean | null) => void;
  gradingService: string;
}

const AnonymousMarking = (props: AnonymousMarkingProps) => {
  // Addendum on passing anonymous marking setting to Turnitin
  const feedbackStudio =
    props.gradingService === "turnitin" ? " and Feedback Studio" : "";

  return (
    <FieldSet>
      <legend>
        <Text kind="headingFive" as="span">
          Anonymous Marking
        </Text>
      </legend>
      <Spacer spacing={16} />

      <SettingInfoCard showNewPill={false} backgroundColor="blue">
        Hide student names on submissions with anonymous marking.
        <br />
        Anonymity settings cannot be changed once students have submitted.
      </SettingInfoCard>
      <Spacer spacing={20} />
      <Radio
        name="anonymousMarking"
        content={`Show student names in the class list${feedbackStudio}`}
        value="disabled"
        disabled={props.hasSubmission}
        checked={
          props.anonymousMarking === false || props.anonymousMarking === null
        }
        onChange={() => props.setAnonymousMarking(false)}
      />
      <Radio
        name="anonymousMarking"
        content={`Hide student names in the class list${feedbackStudio}`}
        value="enabled"
        disabled={props.hasSubmission}
        checked={props.anonymousMarking === true}
        onChange={() => props.setAnonymousMarking(true)}
      />
      {props.gradingService === "speedgrader" && (
        <>
          <Spacer spacing={18} />
          <a
            href="https://support.cadmus.io/setting-requirements-canvas#marking-feedback"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledLinkButton kind="solid">
              {`Remember to ${
                props.anonymousMarking ? "hide" : "show"
              } student names in SpeedGrader `}
              <Icon iconName="Right" />
            </StyledLinkButton>
          </a>
        </>
      )}
    </FieldSet>
  );
};

const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
`;

const FieldSet = styled.fieldset`
  border: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
`;
