import {
  QuestionDifficulty,
  QuestionResponse,
  QuestionType,
} from "@/generated/graphql";

export type ImportableQuestionType = Exclude<
  QuestionType,
  QuestionType.Overview | QuestionType.Section
>;

export type ImportedQuestionType = ImportableQuestionType | null;

export interface ParsedQuestion {
  id?: string;
  questionBankId?: string;
  // quiz file usually have a question identifier.
  // combining this with filename can potentially be used
  // for unique question detection
  questionId: string;
  saveId?: string;
  questionType: ImportableQuestionType | null;
  category?: string;
  difficulty?: QuestionDifficulty | null | undefined;
  shortPrompt: string | null;
  prompt: string;
  fileOriginId: string;
  points?: number;
  response?: QuestionResponse;
  feedback?: string;
  loading?: boolean;
  error?: Error;
}

export type FileOrigin = {
  id?: string;
  /**
   * client side only id so that operations can be done
   * before data goes to server
   **/
  fileOriginId: string;
  filepath: string;
  filename?: string;
  loading?: boolean;
  error?: Error;
};

export type ParsedQuestionBank = {
  questions: ParsedQuestion[];
  fileOrigin: FileOrigin;
};

export interface QuestionBankSlice {
  loading: boolean;
  categories: string[];
  processingFiles: number;
  processedFiles: number;
  banks: ParsedQuestionBank[];
}

export interface ImportedFile {
  fileId: FileOrigin["id"];
  filename: FileOrigin["filename"];
  filepath: FileOrigin["filepath"];
  questions: ParsedQuestion[];
  status: ImportFileStatus;
}

export enum ImportFileStatus {
  PartialImport = "Partial Import",
  Success = "Success",
  FailedToImport = "Failed to Import",
}
