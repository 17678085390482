import { useEffect } from "react";

import NiceModal from "@ebay/nice-modal-react";
import { useExposedNavigator } from "data/onboarding/useExposedNavigator";
import { useUserpilotIdentify } from "data/onboarding/useUserpilotIdentify";
import { useGA } from "logging/ga";
import { Outlet } from "react-router-dom";

import { useAppDispatch } from "@/data/hooks";
import { hydrateTaskState } from "@/data/task";
import { useGlobalParams } from "@/router/routing";
import { useExposedLocation } from "@/router/useExposedLocation";
import { Toaster } from "@/ui/notifications/Toaster";

import { useJitsuIdentify } from "./useJitsuIdentify";

/**
 * Top level route component.
 * At this point, we are working in the realms of an assessment.
 */
export function App() {
  // Capture and store the global Tenant and Assessment context via route params
  const { assessmentId } = useGlobalParams();
  // Identify the current user with UserPilot
  useUserpilotIdentify();
  // Listen for custom navigation events
  useExposedNavigator();
  // Identify the current user with Jitsu context
  useJitsuIdentify(assessmentId);
  // Add route information to the body tag.
  useExposedLocation();
  // Tracks visited pages
  useGA();

  // Hydrate the Redux Store
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(hydrateTaskState({ assessmentId }));
  }, [assessmentId, dispatch]);

  return (
    <NiceModal.Provider>
      <Toaster />
      <Outlet />
    </NiceModal.Provider>
  );
}
