import { useEffect } from "react";

import { StudentsDueExtensionPage } from "./StudentsDueDateExtensionPage";
import { StudentsTimeLimitExtensionPage } from "./StudentsTimeLimitExtensionPage";
import { ExtensionType, StudentsSettingsPageProps } from "./types";

/** Extending students' work settings */
export function StudentsSettingsPage(props: StudentsSettingsPageProps) {
  const { extensionType, timeLimit } = props;

  useEffect(() => {
    document.body.setAttribute("data-subroute", "extension");
    return () => {
      document.body.removeAttribute("data-subroute");
    };
  });

  if (extensionType === ExtensionType.TimeLimit && timeLimit !== null) {
    return <StudentsTimeLimitExtensionPage {...props} timeLimit={timeLimit} />;
  }
  return <StudentsDueExtensionPage {...props} />;
}
