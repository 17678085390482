import { useCallback } from "react";

import {
  formatReferencingStyle,
  getTenantStyles,
} from "@vericus/cadmus-common";
import {
  DropdownMenu,
  LinkButton,
  SelectButton,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import { __GLOBAL_TENANT } from "client/globals";

import { FadeIn, SettingContainer } from "../styles";

interface ReferencingStyleSettingProps {
  referencingStyle: string | null;
  noReferencing: boolean;
  setReferencingStyle: (value: string | null) => void;
  setNoReferencing: (value: boolean) => void;
  footnoteReferencingEnabled: boolean;
}

/**
 * View and set the optional Referencing style requirement via /noReferencing/
 * and /referencingStyle/.
 */
export function ReferencingStyleSetting({
  noReferencing,
  referencingStyle,
  setNoReferencing,
  setReferencingStyle,
  ...props
}: ReferencingStyleSettingProps) {
  return (
    <SettingContainer>
      <Text kind="headingOne">
        Referencing is{" "}
        <LinkButton
          inline
          onClick={() => setNoReferencing(!noReferencing)}
          color={!noReferencing ? "lilac500" : undefined}
        >
          {noReferencing ? "off" : "on"}
        </LinkButton>
      </Text>
      <Text kind="bodyLg" color="shade1">
        Set a referencing style for this assignment. Within Cadmus, students can
        access guides that help them follow this referencing style.
      </Text>
      {!noReferencing && (
        <FadeIn>
          <Spacer spacing={36} />
          <Text kind="headingFive">Referencing Style</Text>
          <ReferencesSelector
            referencingStyle={referencingStyle}
            setReferencingStyle={setReferencingStyle}
            footnoteReferencingEnabled={props.footnoteReferencingEnabled}
          />
        </FadeIn>
      )}
    </SettingContainer>
  );
}

type ReferencesSelectorProps = Pick<
  ReferencingStyleSettingProps,
  "referencingStyle" | "setReferencingStyle" | "footnoteReferencingEnabled"
>;

export const ReferencesSelector = ({
  referencingStyle,
  setReferencingStyle,
  footnoteReferencingEnabled,
}: ReferencesSelectorProps) => {
  const handleChange = useCallback(
    (style: string) => {
      setReferencingStyle(style);
    },
    [setReferencingStyle]
  );

  const styles =
    __GLOBAL_TENANT.current !== null
      ? getTenantStyles(__GLOBAL_TENANT.current, footnoteReferencingEnabled)
      : [];

  return (
    <Text kind="bodySm">
      {`You're required to follow `}
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <SelectButton
            aria-label={`Choose a referencing style: ${
              referencingStyle
                ? `${referencingStyle} selected`
                : "choose a style"
            }`}
          >
            {referencingStyle
              ? formatReferencingStyle(referencingStyle)
              : "choose a style"}
          </SelectButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          {styles.map((style) => (
            <DropdownMenu.Item
              key={style}
              aria-label={`${style} referencing style`}
              onSelect={() => handleChange(style)}
            >
              {formatReferencingStyle(style)}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </Text>
  );
};
