import { useState } from "react";

import { CrossIcon } from "@vericus/cadmus-icons";
import { ControlButton, LinkButton, Text } from "@vericus/cadmus-ui";

import clsx from "clsx";

import { User } from "@/generated/graphql";

import * as styles from "./rule-applied-students-list.css";

interface RuleAppliedStudentsListProps {
  className?: string;
  /** Students in list */
  students: Pick<User, "id" | "name">[];
  /** Callback func to remove student from list */
  onRemoveStudentFromList?: (studentId: string) => void;
  /** Whether this is a list of students that are included in rule */
  isIncludingList?: boolean;
  readonly?: boolean;
}

/** Component displays a list of students that are include in or exclude from rule */
export const RuleAppliedStudentsList = (
  props: RuleAppliedStudentsListProps
) => {
  const [showStudentsList, setShowExcludingStudents] = useState(false);
  return (
    <div className={clsx(styles.root, props.className)}>
      {props.students.length > 0 && (
        <LinkButton onClick={() => setShowExcludingStudents((state) => !state)}>
          <Text
            kind="bodyMd"
            color="navy500"
          >{`${props.isIncludingList ? "Applicable to" : "Excluding"} ${props.students.length} student${plural(props.students.length)}`}</Text>
        </LinkButton>
      )}
      {showStudentsList && (
        <div className={styles.excludeStudentTagsContainer}>
          {props.students.map((student) => (
            <div className={styles.studentTag} key={student.id}>
              <Text kind="bodyMd" color="neutralBlack300">
                {student.name}
              </Text>
              {!props.readonly && (
                <ControlButton
                  aria-label="Delete"
                  icon={
                    <CrossIcon
                      label=""
                      className={styles.crossIcon}
                      size={10}
                    />
                  }
                  onClick={() => props.onRemoveStudentFromList?.(student.id)}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function plural(count: number) {
  if (count === 0 || count > 1) return "s";
  return "";
}
