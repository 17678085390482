import { EnrollmentFragment, GroupFragment } from "generated/graphql";

/** Various extension settings types */
export enum ExtensionType {
  DueDate,
  TimeLimit,
}

/**
 * Props for the Student Settings Page and sub-pages.
 */
export interface StudentsSettingsPageProps {
  /** Assessment ID */
  assessmentId: string;
  /** Type of extension setting being updated in the component */
  extensionType: ExtensionType;
  /** Enrollments for which the settings are being edited */
  enrollments: EnrollmentFragment[];
  /** Total active student count for the class */
  activeStudentNums: number;
  /** All the groups available in the class */
  groups?: GroupFragment[];
  /** Assessment time limit if time limited assessment */
  timeLimit: number | null;
  /** Assessment final due date */
  dueDate: string;
  /** Assessment return date */
  returnDate: string;
  /** Close the settings page and return to the previous page */
  onClose: () => void;
  /** Whether all students are selected */
  selectedAll?: boolean;
}
