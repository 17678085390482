import { useMemo } from "react";

import { isAfter } from "date-fns";

import { InstructionSheetFragment } from "@/generated/graphql";
import { isExam } from "@/ui/task";

export interface ShowFeedbackFiltersProps {
  instructionSheet: Pick<
    InstructionSheetFragment,
    | "assessmentType"
    | "noExamFeedbackDate"
    | "examFeedbackDate"
    | "returnDate"
    | "gradingService"
  > | null;
}

/**
 * Determine whether to show feedback filters to the user based on the grading service,
 * feedback date, and assessment settings.
 * @returns whether feedback filters should be shown to the user
 */
export function useShowFeedbackFilters({
  instructionSheet,
}: ShowFeedbackFiltersProps): boolean {
  return useMemo(() => {
    if (!instructionSheet) return false;
    const { noExamFeedbackDate, examFeedbackDate, returnDate, gradingService } =
      instructionSheet;
    const isExamAssessment = isExam(instructionSheet);
    const isAfterExamFeedbackDate = Boolean(
      isExamAssessment &&
        !noExamFeedbackDate &&
        examFeedbackDate &&
        isAfter(new Date(), new Date(examFeedbackDate))
    );
    const isAfterAssignmentFeedbackDate =
      !isExamAssessment && isAfter(new Date(), new Date(returnDate));
    return (
      (isAfterAssignmentFeedbackDate || isAfterExamFeedbackDate) &&
      gradingService !== "speedgrader"
    );
  }, [instructionSheet]);
}
