import { forwardRef } from "react";
import { styled } from "styled-components";

import { colors, FocusAnimation, typography } from "@vericus/cadmus-ui";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * React children
   */
  children?: React.ReactNode;
}

/**
 * Buttons allow users to perform actions through the UI.
 *
 * They are typically used for navigational links, or triggers for certain
 * actions and events.
 *
 * _props extends `button` attributes_
 */
export const OutlineButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { children, ...buttonProps } = props;
    return (
      <ButtonRoot ref={ref} {...buttonProps}>
        {children}
      </ButtonRoot>
    );
  }
);

const ButtonRoot = styled.button`
  position: relative;
  padding: 8px 20px 6px 20px;
  margin: 0;

  min-width: 108px;

  border: 1px solid ${colors.black100};
  outline: none;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;

  font-family: ${typography.bodySm.fontFamily};
  font-size: ${typography.bodySm.fontSize};
  font-weight: ${typography.bodySm.fontWeight};
  letter-spacing: ${typography.bodySm.letterSpacing};
  line-height: ${typography.bodySm.lineHeight};

  background: white;
  color: ${colors.black200};

  &:disabled {
    opacity: 0.36;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    /* The :before pseudo-element creates a focus and ping outline*/
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      opacity: 1;
    }

    &:focus:before {
      ${FocusAnimation}
    }
  }

  &:hover,
  &:focus {
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(89, 115, 166, 0.06);
      pointer-events: none;
    }
  }
`;
