import { useFragment } from "@apollo/client";

import {
  InstitutionFeaturesFragment,
  InstitutionFeaturesFragmentDoc,
} from "@/generated/graphql";

/**
 * Select the global Institution features.
 */
export function useInstitutionFeaturesFragment() {
  const { data } = useFragment<InstitutionFeaturesFragment>({
    fragment: InstitutionFeaturesFragmentDoc,
    fragmentName: "InstitutionFeatures",
    from: {
      __typename: "Institution",
    },
  });

  return data;
}
