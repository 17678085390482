import { Alert, Radio, Spacer, Text } from "@vericus/cadmus-ui";

import { SettingInfoCard } from "../SettingInfoCard";
import { useSettingsFormContext } from "../use-settings-form";

export const ExamLDBSetting = () => {
  const settingsForm = useSettingsFormContext();
  const { enableExamLdb = false } = settingsForm.values;
  return (
    <>
      <Text kind="headingFive">Respondus LockDown Browser</Text>
      <Spacer spacing={16} />
      <SettingInfoCard showNewPill>
        <>
          Cadmus can now be used with Respondus LockDown Browser
          <br />
          <a
            href="https://support.cadmus.io/teachers/respondus-lockdown-browser"
            target="_blank"
            rel="noreferrer"
          >
            Learn more about running a Cadmus exam with LockDown Browser
          </a>
        </>
      </SettingInfoCard>
      <Spacer spacing={16} />
      <div role="radiogroup" aria-label="Lockdown browser setting">
        <Radio
          checked={!enableExamLdb}
          content="Students will not be required to use LockDown Browser"
          onChange={() => settingsForm.setFieldValue("enableExamLdb", false)}
        />
        <Radio
          checked={!!enableExamLdb}
          content="Students must use LockDown Browser to complete the exam"
          onChange={() => settingsForm.setFieldValue("enableExamLdb", true)}
        />
        {!!enableExamLdb && (
          <>
            <Spacer spacing={16} />
            <Alert severity="info">
              <Text kind="bodySm">
                This will impact the flexibility of your exam design in Cadmus.
                Students will also be required to download a specific Cadmus
                version of LockDown Browser.
                <br />
                <a
                  href="https://support.cadmus.io/teachers/respondus-lockdown-browser#exam-design-considerations"
                  target="_blank"
                  rel="noreferrer"
                >
                  Understand how the exam experience differs with LockDown
                  Browser
                </a>
              </Text>
            </Alert>
          </>
        )}
      </div>
    </>
  );
};
