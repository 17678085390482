import { useEffect, useState } from "react";

import {
  Content,
  createCadmusEditor,
  Editor,
} from "@vericus/cadmus-editor-prosemirror";

import { makeVar, useReactiveVar } from "@apollo/client";
import GLOBAL_SUGGESTION_CLIENT from "client/suggestion";
import { AppDispatch } from "data/store";
import { SaveStateFragment } from "generated/graphql";
import { debounce } from "ts-debounce";

import { saveTask } from "./actions";
import { TaskSaveDoc } from "./types";

/** Task editor reactive variable. */
export const taskEditorVar = makeVar<Editor | null>(null);

/** Hook to use the Task editor */
export const useTaskEditor = () => useReactiveVar(taskEditorVar);

/** Get the latest word count from the Task editor. */
export const useIsBodyEmpty = (): boolean => {
  const task = useTaskEditor();
  const [isEmpty, setIsEmpty] = useState(task ? task.isEmpty : true);
  useEffect(() => {
    const onTransaction = debounce(() => {
      if (task) {
        setIsEmpty(task.isEmpty);
      }
    }, 100);
    task?.on("transaction", onTransaction);
    return () => {
      task?.off("transaction", onTransaction);
    };
  }, [task]);
  return isEmpty;
};

/** Check if all editors are loaded. */
export const useEditorsLoaded = () => {
  const taskEditor = useTaskEditor();
  return taskEditor ? true : false;
};

// Information needed to initialise the Task `Editor`.
interface EditorLoadActionPayload {
  assessmentId: string;
  tenant: string;
  userId: string;
  loadedSave: SaveStateFragment | null;
}

/**
 * Initialise the Task editor with latest save.
 *
 * Once the Task editor is initialised it is made reactive through the `taskVar`
 * variable and should not be initialised again.
 *
 * @param action payload with information needed to initialise the Editor plugins
 * @param dispatch redux dispatch action for save callback
 */
export function loadTaskEditor(
  action: EditorLoadActionPayload,
  dispatch: AppDispatch
) {
  const task = taskEditorVar();
  let taskContent: Content = "";

  // If the editors are initialised do not re-initialise
  if (task) {
    return undefined;
  }

  if (action.loadedSave) {
    const content = deserialiseTaskEditor(action.loadedSave);
    if (content) {
      taskContent = content;
    }
  }

  const taskEditor = createCadmusEditor({
    editorId: "task",
    editorA11yLabel: "Assessment instructions editor",
    content: taskContent,
    imageAPIBase: `${import.meta.env.VITE_API_ENDPOINT}/api/media`,
    suggestionClient: GLOBAL_SUGGESTION_CLIENT,
    userRole: "lecturer",
    tenant: action.tenant,
    assessmentId: action.assessmentId,
    contentPlaceholder: "Start writing your instructions here...",
    userId: action.userId,
    onSave: () => {
      dispatch(
        saveTask({
          assessmentId: action.assessmentId,
        })
      );
    },
  });
  taskEditorVar(taskEditor);
}

/**
 * Deserialse and parse a server fetched Save document into the current
 * Prosemirror Editors based save document.
 *
 * If the save was from the older Slate editors based Athena, the editor
 * contents are parsed and converted to a semantic HTML string that Prosmirror
 * can parse.
 */
export function deserialiseTaskEditor(save: SaveStateFragment): Content | null {
  if (!save.rawContent) return null;
  return deserialiseTaskSaveContent(save.rawContent);
}

export function deserialiseTaskSaveContent(content: string): Content | null {
  try {
    const doc: TaskSaveDoc = JSON.parse(content);
    return JSON.parse(doc.content);
  } catch (e) {
    console.error("Error parsing save", e);
    return null;
  }
}

/**
 * Create a Task save JSON document with the current Task editor contents.
 */
export function serialiseTaskEditor(): TaskSaveDoc | null {
  const task = taskEditorVar();

  if (task) {
    return {
      editor: "prosemirror",
      content: JSON.stringify(task.getJSON()),
    };
  }

  return null;
}
