import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { saveTask } from "@/data/task";

import { ErrorKind } from "./types";

export interface ErrorState {
  kind: ErrorKind;
  /** Error subject */
  subject: string;
  /** Error body */
  message: string;
}

export const PRESETS = {
  [ErrorKind.UpdateSettings]: {
    subject: "Update Error",
    message:
      "There was an error updating your settings. Please try again or check your internet connection.",
  },
  [ErrorKind.SaveContent]: {
    subject: "Lost Connection to Cadmus",
    message:
      "Your edits can't be saved to the cloud until you're back online. Attempting to reconnect... ",
  },
  [ErrorKind.EditTask]: {
    subject: "Update Error",
    message:
      "There was an error updating your assessment. Please try again or check your internet connection.",
  },
  [ErrorKind.Release]: {
    subject: "Release Error",
    message:
      "There was an error releasing your assessment. Please try again or check your internet connection.",
  },
  [ErrorKind.RemoveRelease]: {
    subject: "Remove Release Error",
    message:
      "There was an error removing your future assessment. Please try again or check your internet connection.",
  },
  [ErrorKind.GroupUpdate]: {
    subject: "Groups Error",
    message:
      "There was an error editing your class groups. Please try again or check your internet connection.",
  },
  [ErrorKind.ReportGeneration]: {
    subject: "Report Error",
    message:
      "There was an unexpected error in generating the report. Please try again later.",
  },
  [ErrorKind.Network]: {
    subject: "Lost connection",
    message: "Check your internet connection and try again.",
  },
  [ErrorKind.Update]: {
    subject: "Update Failed",
    message:
      "Some updates couldn't be fetched, check your internet and refresh to see new changes.",
  },
};

export const errorSlice = createSlice({
  name: "error",
  initialState: null as ErrorState | null,
  reducers: {
    notifyError: (_state, action: PayloadAction<ErrorKind>) => {
      const kind = action.payload;
      const preset = PRESETS[kind];
      return {
        kind,
        ...preset,
      };
    },
    clearError: () => {
      return null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveTask.rejected, () => {
      const kind = ErrorKind.SaveContent;
      const preset = PRESETS[kind];
      return {
        kind,
        ...preset,
      };
    });
    builder.addCase(saveTask.fulfilled, (state) => {
      // Check if the current state has a ErrorKind.SaveContent
      if (state && state.kind === ErrorKind.SaveContent) {
        return null;
      }

      return state;
    });
  },
});

// Export Actions
export const { notifyError, clearError } = errorSlice.actions;

// Export Reducer
export const errorReducer = errorSlice.reducer;
