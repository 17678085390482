import { useState } from "react";

import { Icon, LinkButton, Spacer } from "@vericus/cadmus-ui";

import { useInstitutionFeaturesFragment } from "@/graphql/institution-selectors";

import { FadeIn } from "../styles";
import { ExamLDBSetting } from "./ExamLDBSetting";

export const ExamSubmissionAdvanceSetting = () => {
  const [expanded, setExpanded] = useState(false);
  const { ldbFeatureEnabled = false } = useInstitutionFeaturesFragment();

  if (!ldbFeatureEnabled) return null;

  return (
    <>
      <LinkButton
        onClick={() => setExpanded(!expanded)}
        iconName="Settings"
        icon={<Icon iconName="Settings" />}
        rightIcon={
          <Icon iconName={expanded ? "UpArrowhead" : "DownArrowhead"} />
        }
      >
        Advanced exam settings
      </LinkButton>
      {expanded && (
        <FadeIn>
          <Spacer spacing={60} />
          <ExamLDBSetting />
        </FadeIn>
      )}
      <Spacer spacing={60} />
    </>
  );
};
