import { SuggestionClient } from "@vericus/cadmus-editor-prosemirror";

import * as config from "@/config";

/** Global Suggestions Client */
const GLOBAL_SUGGESTION_CLIENT = config.SUGGESTION_ENDPOINT
  ? new SuggestionClient(config.SUGGESTION_ENDPOINT)
  : undefined;

export default GLOBAL_SUGGESTION_CLIENT;
