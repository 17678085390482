import { styled, CSSProperties } from "styled-components";

import { colors, Spacer, Text } from "@vericus/cadmus-ui";

import MagnifyingGlass from "@/assets/shared/magnifying-glass.svg";

import { Container, Content } from "./styles";

interface Props {
  clearSearchFilter: () => void;
  searchQuery: string;
  style?: CSSProperties;
}

export const SearchNoResultTable = (props: Props) => {
  return (
    <Container style={props.style}>
      <Content>
        <Img src={MagnifyingGlass} alt={"Magnifying glass"} />
        <Spacer spacing={34} />
        <Text kind={"headingFour"} color={"navy400"}>
          {`We couldn't find a match for "${props.searchQuery}"`}
        </Text>
        <Spacer spacing={12} />
        <Text kind="bodyMd" color={"navy400"}>
          Ensure the spelling is correct
        </Text>
        <Spacer spacing={4} />
        <ClearFiltersCTA kind="bodySm" onClick={props.clearSearchFilter}>
          Try searching again
        </ClearFiltersCTA>
      </Content>
    </Container>
  );
};

const Img = styled.img`
  display: block;
  width: 70px;
  margin: 0 auto;
  fill: ${() => colors.grey500};
`;

const ClearFiltersCTA = styled(Text)`
  color: ${(p) => p.theme.colors.lilac500};

  &:hover {
    cursor: pointer;
  }
`;
