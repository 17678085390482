import { DropdownMenu } from "@vericus/cadmus-ui";

import { Column } from "@tanstack/react-table";

import {
  disableFilter,
  FilterValue,
  PredicateFilter,
  ProgressStage,
  StudentListRow,
} from "@/ui/class/progress/types";

import { ProgressValue } from "../../table";
import {
  PredicateDropdownItem,
  PredicateFilterDropdown,
  useToggleFilter,
} from "./PredicateFilterDropdown";

interface StudentRiskFilterProps {
  /** Table instance */
  column: Column<StudentListRow>;
  /** Drafting is enabled in the assessment */
  hasDrafting: boolean;
  /** Marks are not available in the assessment. @default false */
  markingDisabled?: boolean;
  /**
   * Whether the assessment is an exam.
   */
  isExam: boolean;
  /**
   * Whether assessment has a feedback date or not
   * @default false
   */
  noFeedbackDate?: boolean;
  /**
   * Whether the feedback date has not passed yet.
   */
  isBeforeFeedback?: boolean;
}

export type StudentRiskFilterId =
  | "not-accessed"
  | "viewed-not-started"
  | "no-draft"
  | "no-final"
  | "feedback-not-viewed";

/** Used to provide different labels using different conditions */
type GetFilter = (hasDraftsOn: boolean) => PredicateFilter;

export const studentRiskFilters: Record<StudentRiskFilterId, GetFilter> = {
  "not-accessed": () => ({
    label: "Have not accessed Cadmus",
    predicate: (value: ProgressValue) => {
      const { progress, deferred, withdrawn } = value;
      return !(deferred || withdrawn) && progress <= ProgressStage.Enrolled;
    },
  }),
  "viewed-not-started": () => ({
    label: "Viewed but not started",
    predicate: (value: ProgressValue) => {
      const { progress, deferred, withdrawn } = value;
      return !(deferred || withdrawn) && progress === ProgressStage.Accessed;
    },
  }),
  "no-draft": () => ({
    label: "No draft submission",
    predicate: (value: ProgressValue) => {
      const { progress, deferred, withdrawn } = value;
      return !(deferred || withdrawn) && progress < ProgressStage.Draft;
    },
  }),
  "no-final": (hasDraftsOn) => ({
    label: hasDraftsOn ? "No final submission" : "No submission",
    predicate: (value: ProgressValue) => {
      const { progress, deferred, withdrawn } = value;
      return !(deferred || withdrawn) && progress < ProgressStage.Final;
    },
  }),
  "feedback-not-viewed": () => ({
    label: "Feedback not viewed",
    predicate: (value: ProgressValue) => {
      const { progress, deferred, withdrawn } = value;
      return (
        !(deferred || withdrawn) && progress < ProgressStage.FeedbackViewed
      );
    },
  }),
};

export const StudentRiskFilter = ({
  column,
  isBeforeFeedback,
  hasDrafting,
  markingDisabled = false,
  isExam,
  noFeedbackDate = false,
}: StudentRiskFilterProps) => {
  const activeFilters = (column.getFilterValue() as FilterValue) ?? [];
  const getFilter = (filterId: StudentRiskFilterId): PredicateFilter =>
    studentRiskFilters[filterId](hasDrafting);
  const isFilterSelected = (filterId: StudentRiskFilterId) =>
    activeFilters.find((f) => f.label === getFilter(filterId).label) !==
    undefined;
  const toggleFilter = useToggleFilter(column, "Students at risk");

  const items = (
    <>
      <PredicateDropdownItem
        predicate={getFilter("not-accessed")}
        selected={isFilterSelected("not-accessed")}
        onToggle={toggleFilter}
      />
      <PredicateDropdownItem
        predicate={getFilter("viewed-not-started")}
        selected={isFilterSelected("viewed-not-started")}
        onToggle={toggleFilter}
      />
      <DropdownMenu.Separator />
      {hasDrafting && !isExam && (
        <PredicateDropdownItem
          predicate={getFilter("no-draft")}
          selected={isFilterSelected("no-draft")}
          onToggle={toggleFilter}
        />
      )}
      <PredicateDropdownItem
        predicate={getFilter("no-final")}
        selected={isFilterSelected("no-final")}
        onToggle={toggleFilter}
      />
      <DropdownMenu.Separator />
      {!markingDisabled && !noFeedbackDate && (
        <>
          <PredicateDropdownItem
            predicate={disableFilter(
              getFilter("feedback-not-viewed"),
              isBeforeFeedback
            )}
            selected={isFilterSelected("feedback-not-viewed")}
            onToggle={toggleFilter}
          />
          <DropdownMenu.Separator />
        </>
      )}
    </>
  );

  return (
    <PredicateFilterDropdown
      column={column}
      label="Students at risk"
      items={items}
    />
  );
};
