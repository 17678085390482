import { ChangeEvent, useCallback, useRef, useState } from "react";
import { styled } from "styled-components";

import { Input, Radio, Spacer, Text, Tooltip } from "@vericus/cadmus-ui";

import { isAfter, startOfMinute } from "date-fns";

import { ExamTiming } from "@/generated/graphql";

import { SettingInfoCard } from "../SettingInfoCard";
import { FadeIn, SettingContainer } from "../styles";
import { useSettingsFormContext } from "../use-settings-form";
import { AccessCodeSetting } from "./AccessCodeSetting";
import { DateTimePicker } from "./DateTimePicker";
import { StyledLinkButton } from "./shared";

interface ExamTimingSettingProps {
  /** Whether students have started working on the exam already. */
  haveStudentsStarted: boolean;
  examTimingRef?: React.Ref<HTMLDivElement>;
  assessmentId: string;
  accessCodeFeatureEnabled: boolean;
}
/**
 * Allow teachers to change settings for:
 * - How the exam will be timed, e.g live vs window
 * - Start/end times
 * - Reading times
 * - Writing times
 */
export const ExamTimingSetting = ({
  haveStudentsStarted,
  examTimingRef,
  assessmentId,
  accessCodeFeatureEnabled,
}: ExamTimingSettingProps) => {
  const settingsForm = useSettingsFormContext();
  const {
    examTiming,
    examStartDate,
    examEndDate,
    examReadingTime,
    examWritingTime,
    noExamReadingTime,
  } = settingsForm.values;

  const startDate = examStartDate
    ? startOfMinute(new Date(examStartDate))
    : undefined;
  const endDate = examEndDate
    ? startOfMinute(new Date(examEndDate))
    : undefined;
  const isReadingTimeExpanded = !noExamReadingTime;

  const endDateTimePickerRef = useRef<HTMLButtonElement>(null);

  const [localExamReadingTime, setLocalExamReadingTime] =
    useState(examReadingTime);
  const onBlurExamReadingTimeValidation = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const validatedValue = validateInteger(
        e.currentTarget.value,
        examReadingTime
      );
      setLocalExamReadingTime(validatedValue);
      settingsForm.setFieldValue("examReadingTime", validatedValue);
    },
    [examReadingTime, settingsForm]
  );

  const [localExamWritingTime, setLocalExamWritingTime] =
    useState(examWritingTime);
  const onBlurExamWritingTimeValidation = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const validatedValue = validateInteger(
        e.currentTarget.value,
        examWritingTime
      );
      setLocalExamWritingTime(validatedValue);
      settingsForm.setFieldValue("examWritingTime", validatedValue);
    },
    [examWritingTime, settingsForm]
  );

  return (
    <SettingContainer ref={examTimingRef}>
      <Text kind="headingOne">Exam timing</Text>
      <Text kind="headingFive">How will students be taking this exam?</Text>
      <Spacer spacing={8} />
      <Tooltip
        content="Cannot be changed once students have started working"
        side="bottom"
        passThrough={!haveStudentsStarted}
      >
        <div
          role="radiogroup"
          aria-label="How will students be taking this exam?"
        >
          <Radio
            tabIndex={0}
            disabled={haveStudentsStarted}
            content="Together at the same time"
            checked={examTiming === ExamTiming.Live}
            onChange={(e) =>
              e.target.checked &&
              settingsForm.setFieldValue("examTiming", ExamTiming.Live)
            }
          />
          <Spacer spacing={8} />
          <Radio
            tabIndex={0}
            disabled={haveStudentsStarted}
            content="At their preferred time within a set window"
            checked={examTiming === ExamTiming.Window}
            onChange={(e) =>
              e.target.checked &&
              settingsForm.setFieldValue("examTiming", ExamTiming.Window)
            }
          />
        </div>
      </Tooltip>

      <Spacer spacing={64} />
      {examTiming === ExamTiming.Live && (
        <>
          <Text kind="headingFive">Exam start</Text>
          <Tooltip
            content="Cannot be changed once students have started working"
            side="bottom"
            passThrough={!haveStudentsStarted}
          >
            <Aligned>
              <Text
                kind="bodySm"
                as="span"
                color={haveStudentsStarted ? "grey300" : undefined}
              >
                This exam will start on{" "}
              </Text>
              <DateTimePicker
                value={startDate}
                disabled={haveStudentsStarted}
                width={195}
                style={{ margin: "0px 4px" }}
                onChange={(date) =>
                  settingsForm.setFieldValue(
                    "examStartDate",
                    date.toISOString()
                  )
                }
                onBlur={() => settingsForm.validateField("examStartDate")}
                errorMessage={settingsForm.errors.examStartDate}
              />
            </Aligned>
          </Tooltip>
        </>
      )}
      {examTiming === ExamTiming.Window && (
        <>
          <Text kind="headingFive">Exam open</Text>
          <Tooltip
            content="Cannot be changed once students have started working"
            side="bottom"
            passThrough={!haveStudentsStarted}
          >
            <Aligned>
              <Text
                kind="bodySm"
                as="span"
                color={haveStudentsStarted ? "grey300" : undefined}
              >
                Students can start the exam on
              </Text>
              <DateTimePicker
                value={startDate}
                disabled={haveStudentsStarted}
                width={195}
                style={{ margin: 4 }}
                onChange={(newStartDate) => {
                  if (endDate && isAfter(newStartDate, endDate)) {
                    // clear end date if new start date is after it
                    settingsForm.setFieldValue("examEndDate", null);
                    if (endDateTimePickerRef.current) {
                      // Mantine does not clear the date in the field even
                      // when the datetimepicker is controlled :/
                      endDateTimePickerRef.current.innerText = "";
                    }
                  }
                  settingsForm.setFieldValue(
                    "examStartDate",
                    newStartDate.toISOString()
                  );
                }}
                onBlur={() => settingsForm.validateField("examStartDate")}
                errorMessage={settingsForm.errors.examStartDate}
              />
            </Aligned>
          </Tooltip>

          <Text kind="headingFive">Exam close</Text>
          <Aligned>
            <Text kind="bodySm" as="span">
              Students must complete the exam by
            </Text>
            <DateTimePicker
              value={endDate}
              ref={endDateTimePickerRef}
              width={195}
              style={{ margin: 4 }}
              onChange={(date) =>
                date &&
                settingsForm.setFieldValue("examEndDate", date.toISOString())
              }
              minDate={examStartDate ? new Date(examStartDate) : undefined}
              onBlur={() => settingsForm.validateField("examEndDate")}
              errorMessage={settingsForm.errors.examEndDate}
            />
          </Aligned>
        </>
      )}
      <Spacer spacing={60} />
      {/* Access code */}
      {accessCodeFeatureEnabled && (
        <>
          <AccessCodeSetting
            assessmentId={assessmentId}
            haveStudentsStarted={haveStudentsStarted}
          />

          <Spacer spacing={60} />
        </>
      )}

      {/* Reading time */}
      <Text kind="headingFive">
        Reading time is{" "}
        <StyledLinkButton
          inline
          aria-label={`Reading time is ${isReadingTimeExpanded ? "on" : "off"}`}
          color={isReadingTimeExpanded ? "lilac500" : undefined}
          onClick={() =>
            settingsForm.setFieldValue("noExamReadingTime", !noExamReadingTime)
          }
        >
          {isReadingTimeExpanded ? "on" : "off"}
        </StyledLinkButton>
      </Text>
      <Spacer spacing={16} />
      <SettingInfoCard>
        Allocate time for students to read through their instructions, without
        being able to type in the main Work space or Notes section.
      </SettingInfoCard>
      {isReadingTimeExpanded && (
        <FadeIn>
          <Spacer spacing={16} />
          <Aligned>
            <Text kind="bodySm" as="span">
              Students will be given{" "}
              <StyledInput
                type="number"
                value={`${localExamReadingTime ?? ""}`}
                aria-invalid={!!settingsForm.errors.examReadingTime}
                aria-label="Reading time (in minutes)"
                min={1}
                onChange={(e) =>
                  setLocalExamReadingTime(
                    e.currentTarget.value
                      ? parseInt(e.currentTarget.value)
                      : null
                  )
                }
                onBlur={onBlurExamReadingTimeValidation}
                errorMessage={settingsForm.errors.examReadingTime}
                errorMessageProps={{ position: "relative" }}
              />{" "}
              minutes of reading time at the start of the exam
            </Text>
          </Aligned>
        </FadeIn>
      )}
      <Spacer spacing={60} />
      {/* Writing time */}
      <Text kind="headingFive">Writing time</Text>
      <Aligned>
        <Text kind="bodySm" as="span">
          Students will be given{" "}
          <StyledInput
            type="number"
            value={`${localExamWritingTime ?? ""}`}
            min={1}
            aria-invalid={!!settingsForm.errors.examWritingTime}
            aria-label="Writing time (in minutes)"
            onChange={(e) =>
              setLocalExamWritingTime(
                e.currentTarget.value ? parseInt(e.currentTarget.value) : null
              )
            }
            onBlur={onBlurExamWritingTimeValidation}
            errorMessage={settingsForm.errors.examWritingTime}
            errorMessageProps={{ position: "relative" }}
          />{" "}
          minutes to complete the exam
        </Text>
      </Aligned>
    </SettingContainer>
  );
};

const StyledInput = styled(Input)`
  span& {
    min-width: 89px;
    width: 89px;
  }
  input {
    min-width: 89px;
    width: 89px;
  }
`;

const Aligned = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 4px;
`;

/**
 * Given a string value, e.g. from an <input> it returns a valid integer.
 * @returns a valid integer in the range [1, inf]
 */
const validateInteger = (
  value: string,
  fallback: number | null
): number | null => {
  const parsedValue = parseInt(value);
  let validValue = fallback;
  if (parsedValue >= 1) {
    validValue = parsedValue;
  } else if (parsedValue < 1) {
    validValue = 1;
  }
  return validValue;
};
