import { Skeleton, Spacer } from "@vericus/cadmus-ui";

import * as Grid from "./InfoGrid";

export const TableSkeleton = () => (
  <>
    <Spacer spacing={24} />
    {Array.from(new Array(20)).map((_, index) => (
      <Grid.Row key={index}>
        <Grid.SelectCol>
          <Skeleton />
        </Grid.SelectCol>
        <Grid.IndexCol>
          <Skeleton />
        </Grid.IndexCol>
        <Grid.NameCol>
          <Skeleton />
        </Grid.NameCol>
        <Grid.ProgressCol>
          <Skeleton />
        </Grid.ProgressCol>
        <Grid.OptionsCol>
          <Skeleton />
        </Grid.OptionsCol>
      </Grid.Row>
    ))}
  </>
);
