import { styled } from "styled-components";

import { Anchored, Button, Grid, Spacer, Text } from "@vericus/cadmus-ui";

import CrashImg from "assets/errors/crash.png";

export function CrashError() {
  return (
    <Grid>
      <GridImg
        src={CrashImg}
        alt="An illustration of a woman opening a box, with a rocket launching out of it!"
      />
      <GridContent>
        <Text kind="displayTwo">Oops. Something went wrong</Text>
        <Text kind="lead">
          Our team has been notified of the issue. Cadmus saves regularly if
          {`it's connected to the internet, so your work is most likely safe `}
          <span role="img" aria-label="smile">
            🙂
          </span>
          . Please click the button below to reload.
        </Text>
        <Spacer spacing={27} />
        <Button
          kind="secondary"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload Cadmus
        </Button>
        <Anchored newTab href="https://support.cadmus.io/kb-tickets/new">
          <Button marginLeft={18}>Contact us for help</Button>
        </Anchored>
        <Spacer spacing={72} />
      </GridContent>
    </Grid>
  );
}

const GridImg = styled.img`
  grid-column: 3 / span 5;
  width: 100%;
  margin-top: 72px;

  @media screen and (max-width: 900px) {
    grid-column: 2 / span 7;
  }

  @media screen and (max-width: 630px) {
    grid-column: 1 / span 9;
  }
`;

const GridContent = styled.div`
  grid-column: 3 / span 5;
  text-align: center;
  margin-top: 18px;

  @media screen and (max-width: 900px) {
    grid-column: 1 / span 9;
  }
`;
