import { ChangeEvent, HTMLAttributes } from "react";
import { styled } from "styled-components";

import { Switch, Text } from "@vericus/cadmus-ui";

import { NumberInput } from "../components/number-input";

interface Props extends HTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  similarityValue: number | null;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  caseSensitive: boolean;
  onCaseSensitiveChange: () => void;
  disabled?: boolean;
}

export const SimilarityInput = ({
  hasError,
  similarityValue,
  onChange,
  caseSensitive,
  onCaseSensitiveChange,
  disabled = false,
  ...rest
}: Props) => (
  <SimilaritySection>
    <Text kind="bodySm" color="neutralBlack300">
      Required similarity %
    </Text>
    <Input
      disabled={disabled || caseSensitive}
      hasError={hasError}
      name="SimilarityInput"
      value={similarityValue?.toString() ?? ""}
      onChange={onChange}
      {...rest}
    />
    <VerticalDivider />
    <Switch
      checked={caseSensitive}
      onChange={onCaseSensitiveChange}
      content={"Case Sensitive"}
      labelPosition="left"
      disabled={disabled}
    />
  </SimilaritySection>
);

const SimilaritySection = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;
const VerticalDivider = styled.div`
  display: flex;
  border: 0;
  margin-left: 8px;
  margin-right: 8px;
  width: 1px;
  height: 26px;
  border-left: 1px solid ${(p) => p.theme.colors.grey100};
`;
const Input = styled(NumberInput)`
  width: 44px;
  height: 40px;
`;
