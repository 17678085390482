import { useCallback, useState } from "react";

/** Hook to provide internal state and reusable logics of option list input */
export const useOptionListState = (
  /** Current options that are used to validate their number and check for
   * duplicates */
  values: string[],
  /** Callback to add an option to the current options */
  onAddOption: (option: string) => void,
  /** Whether input is always shown */
  shouldShowInput?: boolean,
  /** Extra input validation function.
   * Return undefined if inputValue is validated, otherwise return error message
   */
  extraValidationFunc?: (inputValue: string) => string | undefined,
  /** Default value of input */
  defaultInputValue?: string
) => {
  const [showInput, setShowInput] = useState(() => {
    if (shouldShowInput) return true;
    if (values.length > 0) return false;
    return true;
  });
  const [inputValue, setInputValue] = useState(defaultInputValue ?? "");
  const [errorMsg, setErrorMsg] = useState<undefined | string>();

  const clearError = useCallback(() => {
    setErrorMsg(undefined);
  }, []);

  const resetInputState = useCallback(() => {
    setInputValue("");
    clearError();
  }, [clearError]);

  const optionsInclude = useCallback(
    (inputValue: string) => {
      return values.includes(inputValue);
    },
    [values]
  );
  /** Check whether input is empty or duplicated. Add input as a
   * new option if it pass the validation
   */
  const onAddButtonClick = useCallback(() => {
    if (showInput) {
      if (inputValue === "") {
        setErrorMsg("This cannot be left empty");
        return;
      } else if (optionsInclude(inputValue)) {
        setErrorMsg("Duplicate answer");
        return;
      } else if (
        extraValidationFunc &&
        extraValidationFunc(inputValue) !== undefined
      ) {
        setErrorMsg(extraValidationFunc(inputValue));
        return;
      }
      onAddOption(inputValue);
      resetInputState();
      return;
    }
    if (!shouldShowInput) {
      setShowInput(true);
    }
  }, [
    showInput,
    inputValue,
    shouldShowInput,
    onAddOption,
    resetInputState,
    extraValidationFunc,
    optionsInclude,
  ]);

  return {
    showInput,
    inputValue,
    inputHasError: errorMsg !== undefined,
    errorMsg,
    setInputValue,
    onAddButtonClick,
    resetInputState,
    clearError,
    setShowInput,
  };
};
