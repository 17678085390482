import { useCallback } from "react";

import toast from "react-hot-toast";

import { EnrollmentFragment } from "@/generated/graphql";

import { SpecialConsiderationFormAction } from "../SpecialConsiderationFormAction";

/**
 * Hook for spawning a toast message when the special considerations form is submitted.
 */
export const useSpawnToastCallback = () => {
  return useCallback(
    (
      enrollments: EnrollmentFragment[],
      formAction: SpecialConsiderationFormAction
    ) => {
      switch (formAction) {
        case SpecialConsiderationFormAction.UPDATED_READING_WRITING_TIME: {
          if (enrollments.length === 1) {
            toast.success(
              `${enrollments[0]?.user.givenName}'s reading and writing time have been extended`
            );
          } else {
            toast.success(
              `Reading and writing time has been extended for ${enrollments.length} students`
            );
          }

          break;
        }

        case SpecialConsiderationFormAction.REMOVED_READING_WRITING_TIME: {
          if (enrollments.length === 1) {
            toast.success(
              `${enrollments[0]?.user.givenName}'s extra time has been removed`
            );
          } else {
            toast.success(
              `Extra time has been removed for ${enrollments.length} students`
            );
          }

          break;
        }

        case SpecialConsiderationFormAction.UPDATED_DEFERRED: {
          if (enrollments.length === 1) {
            toast.success(
              `${enrollments[0]?.user.givenName} has been marked as deferred`
            );
          } else {
            toast.success(
              `${enrollments.length} students have been marked as deferred`
            );
          }
          break;
        }

        case SpecialConsiderationFormAction.REMOVED_DEFERRED: {
          if (enrollments.length === 1) {
            toast.success(
              `${enrollments[0]?.user.givenName} has been un-marked as deferred`
            );
          } else {
            toast.success(
              `${enrollments.length} students have been un-marked as deferred`
            );
          }
          break;
        }

        case SpecialConsiderationFormAction.UPDATED_START_TIME: {
          if (enrollments.length === 1) {
            toast.success(
              `${enrollments[0]?.user.givenName}'s start time has been updated`
            );
          } else {
            toast.success(
              `Start time for ${enrollments.length} students have been updated`
            );
          }
          break;
        }

        case SpecialConsiderationFormAction.REMOVED_START_END_TIME: {
          if (enrollments.length === 1) {
            toast.success(
              `${enrollments[0]?.user.givenName}'s exam timing has been reset`
            );
          } else {
            toast.success(
              `Exam timing for ${enrollments.length} students have been reset`
            );
          }
          break;
        }

        case SpecialConsiderationFormAction.REMOVED_START_TIME: {
          if (enrollments.length === 1) {
            toast.success(
              `${enrollments[0]?.user.givenName}'s start time has been removed`
            );
          } else {
            toast.success(
              `Start time for ${enrollments.length} students have been removed`
            );
          }
          break;
        }

        case SpecialConsiderationFormAction.UPDATED_READING_TIME: {
          if (enrollments.length === 1) {
            toast.success(
              `${enrollments[0]?.user.givenName}'s reading time has been extended`
            );
          } else {
            toast.success(
              `Reading time has been extended for ${enrollments.length} students`
            );
          }
          break;
        }

        case SpecialConsiderationFormAction.REMOVED_READING_TIME: {
          if (enrollments.length === 1) {
            toast.success(
              `${enrollments[0]?.user.givenName}'s reading time has been removed`
            );
          } else {
            toast.success(
              `Reading time has been removed for ${enrollments.length} students`
            );
          }
          break;
        }

        case SpecialConsiderationFormAction.UPDATED_WRITING_TIME: {
          if (enrollments.length === 1) {
            toast.success(
              `${enrollments[0]?.user.givenName}'s writing time has been extended`
            );
          } else {
            toast.success(
              `Writing time has been extended for ${enrollments.length} students`
            );
          }
          break;
        }

        case SpecialConsiderationFormAction.REMOVED_WRITING_TIME: {
          if (enrollments.length === 1) {
            toast.success(
              `${enrollments[0]?.user.givenName}'s writing time has been removed`
            );
          } else {
            toast.success(
              `Writing time has been removed for ${enrollments.length} students`
            );
          }
          break;
        }

        case SpecialConsiderationFormAction.UPDATED_SPECIAL_CONSIDERATIONS:
        default:
          if (enrollments.length === 1) {
            toast.success(
              `Special considerations for ${enrollments[0]?.user.givenName} have been added`
            );
          } else {
            toast.success(
              `Special considerations for ${enrollments.length} students have been added`
            );
          }

          break;
      }
    },
    []
  );
};
