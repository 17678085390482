import { styled, keyframes } from "styled-components";

import { Spacer, Text } from "@vericus/cadmus-ui";

import Sprite from "assets/loading-sprite.png";

const sprite = keyframes`
  from {
    background-position: 0px;
  }
  to {
    background-position: -5400px;
  }
`;

const Quill = styled.div`
  width: 180px;
  height: 81px;
  overflow: hidden;
  background-image: url(${Sprite});
  animation: ${sprite} 0.5s steps(30) infinite alternate;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const LoadingText = styled(Text)`
  text-transform: uppercase;
  font-weight: 600;
`;

/**
 *
 * A full page loading indicator. Uses a css animated spritesheet.
 */
export const Loading = ({ message }: { message?: string }) => (
  <Container>
    <Quill />
    <Spacer spacing={27} />
    {message ? (
      <LoadingText kind="systemSm" color="shade1">
        {message}
      </LoadingText>
    ) : null}
  </Container>
);
