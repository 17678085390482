import { styled } from "styled-components";

import { LinkButton, Spacer, Text } from "@vericus/cadmus-ui";

import { FadeIn } from "../styles";
import { useSettingsFormContext } from "../use-settings-form";
import { AccessCodeListWithData } from "./AccessCodeListWithData";

interface AccessCodeSettingProps {
  assessmentId: string;
  haveStudentsStarted: boolean;
}
export const AccessCodeSetting = ({
  assessmentId,
  haveStudentsStarted,
}: AccessCodeSettingProps) => {
  const settingsForm = useSettingsFormContext();
  const { enableExamAccessCode } = settingsForm.values;

  return (
    <>
      <Text kind="headingFive">
        Access code requirement is{" "}
        <StyledLinkButton
          inline
          aria-label={`Exam Access code ${
            !enableExamAccessCode ? "on" : "off"
          }`}
          color={enableExamAccessCode ? "lilac500" : undefined}
          onClick={() => {
            settingsForm.setFieldValue(
              "enableExamAccessCode",
              !enableExamAccessCode
            );
          }}
        >
          {enableExamAccessCode ? "on" : "off"}
        </StyledLinkButton>
      </Text>

      {enableExamAccessCode && (
        <FadeIn>
          <Spacer spacing={16} />
          <Aligned>
            <AccessCodeListWithData
              assessmentId={assessmentId}
              disabled={haveStudentsStarted}
            />
          </Aligned>
        </FadeIn>
      )}
    </>
  );
};

export const StyledLinkButton = styled(LinkButton)`
  font-weight: 600;
`;
const Aligned = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 4px;
`;
