import { styled } from "styled-components";

import { colors, desktop, mobile, tablet, Text } from "@vericus/cadmus-ui";

interface ClickableSubcategoryProps {
  isActive?: boolean;
  name: string;
  onClick: VoidFunction;
}

export const ClickableSubcategory = ({
  isActive,
  name,
  onClick,
}: ClickableSubcategoryProps) => {
  return (
    <Ribbon isActive={isActive} name={name} onClick={onClick}>
      <SubcategoryText
        isActive={isActive}
        kind="bodySm"
        aria-current={isActive ?? false}
        tabIndex={0}
      >
        {name}
      </SubcategoryText>
    </Ribbon>
  );
};

interface RibbonProps
  extends Pick<ClickableSubcategoryProps, "isActive" | "name"> {
  children?: JSX.Element;
  onClick: VoidFunction;
}

const Ribbon = ({ isActive, children, onClick, name }: RibbonProps) => {
  return (
    <RibbonContainer isActive={isActive} onClick={onClick} title={name}>
      {children}
    </RibbonContainer>
  );
};

// TODO: use a --height CSS variable for less JS execution?
const RibbonContainer = styled.div.attrs({ role: "button", tabIndex: 1 })<{
  isActive?: boolean;
}>`
  background-color: ${(p) => (p.isActive ? colors.blue500 : "none")};
  height: 32px;
  ${tablet`
    height: 36px;
  `}
  ${desktop`
    height: 36px;
  `}
  width: 100%;
  border-radius: 0px 100px 100px 0px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.1s ease-out;
  padding-right: 6px;

  &:hover {
    background-color: ${(p) => (p.isActive ? colors.blue500 : colors.grey50)};

    &:before {
      display: block;
      background-color: ${(p) => (p.isActive ? colors.blue500 : colors.grey50)};
      position: absolute;
      content: "";
      width: 100%;
      height: 32px;
      ${tablet`
        height: 36px;
      `}
      ${desktop`
        height: 36px;
      `}
      left: -100%;
    }
  }

  &:before {
    display: block;
    background-color: ${(p) => (p.isActive ? colors.blue500 : "none")};
    transition: background-color 0.1s ease-out;
    position: absolute;
    content: "";
    width: 100%;
    height: 32px;
    ${tablet`
      height: 36px;
    `}
    ${desktop`
      height: 36px;
    `}
    left: -100%;
  }
`;

const SubcategoryText = styled(Text)<{ isActive?: boolean; fontSize?: number }>`
  color: ${(p) => (p.isActive ? colors.white100 : colors.black200)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${mobile`
    font-size: 12px;  
  `}
`;
