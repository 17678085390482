import { colors, Icon, Spacer, Text } from "@vericus/cadmus-ui";

import { TaskFormat } from "@/generated/graphql";

import * as Req from "../RequirementRows";
import { SectionProps } from "./shared";

/**
 * Render the public section of assignment requirements
 */
export function VisibleAssignmentRequirements({
  reqs,
  diff,
  setFinalDueDate,
  format,
}: SectionProps) {
  return (
    <div data-testid="AssignmentRequirementsContainer">
      <Req.DueDate
        dueDate={reqs.dueDate}
        timeLimit={reqs.noTimeLimit ? null : reqs.timeLimit}
        hasChanged={diff?.dueDate || diff?.timeLimit}
        setDate={setFinalDueDate}
      />
      {reqs.draftDueDate !== null &&
        reqs.draftDueDate !== undefined &&
        !reqs.noDraft && (
          <Req.DraftDueDate
            draftDueDate={reqs.draftDueDate}
            hasChanged={diff?.draftDueDate}
          />
        )}
      {reqs.weight !== null && !reqs.noWeight && (
        <Req.Weight weight={reqs.weight} hasChanged={diff?.weight} />
      )}
      {reqs.wordLimit !== null &&
        !reqs.noLimit &&
        format === TaskFormat.Classic && (
          <Req.WordLimit
            wordLimit={reqs.wordLimit}
            countReferences={reqs.countReferences}
            hasChanged={diff?.wordLimit}
          />
        )}
      {reqs.referencingStyle !== null &&
        !reqs.noReferencing &&
        format === TaskFormat.Classic && (
          <Req.ReferencingStyle
            referencingStyle={reqs.referencingStyle}
            hasChanged={diff?.referencingStyle}
          />
        )}
    </div>
  );
}

/**
 * Render the private section of assignment requirements
 */
export function HiddenAssignmentRequirements({
  reqs,
  diff,
  format,
}: SectionProps) {
  return (
    <>
      <Spacer spacing={27} />
      <Text kind="headingSix" style={{ color: colors.grey600 }} as="div">
        <Icon iconName="Show" iconColor="grey500" /> Additional settings not
        visible to students
      </Text>
      <Spacer spacing={18} />

      {reqs.returnDate !== null && (
        <Req.ReturnDate
          returnDate={reqs.returnDate}
          hasChanged={diff?.returnDate}
        />
      )}
      <Req.GradingService
        gradingService={reqs.gradingService}
        hasChanged={diff?.gradingService}
      />
      <Req.AnonymousMarking
        anonymousMarking={reqs.anonymousMarking}
        hasChanged={diff?.anonymousMarking}
      />
      {reqs.maxGrade !== null && reqs.gradingService === "turnitin" && (
        <Req.MaxGrade
          maxGrade={reqs.maxGrade}
          hasChanged={diff?.maxGrade}
          format={format}
        />
      )}
      <Req.Similarity
        sViewReports={reqs.sViewReports}
        draftSViewReports={reqs.draftSViewReports}
        noDraft={reqs.noDraft}
        hasChanged={diff?.similarity || diff?.draftSimilarity}
      />
      {reqs.timeLimit !== null && !reqs.noTimeLimit && (
        <Req.TimeLimit
          timeLimit={reqs.timeLimit}
          hasChanged={diff?.timeLimit}
        />
      )}
      {reqs.allowLateResubmission && (
        <Req.AllowLateResubmission hasChanged={diff?.allowLateResubmission} />
      )}
    </>
  );
}
