import { HTMLAttributes } from "react";
import { styled } from "styled-components";

import {
  Color,
  colors,
  desktop,
  Icon,
  mobile,
  tablet,
  Text,
} from "@vericus/cadmus-ui";

export interface SidebarButtonProps extends HTMLAttributes<HTMLButtonElement> {
  icon: "Plus" | "Home";
  iconColor?: Color;
}

export const SidebarButton = (props: SidebarButtonProps) => {
  const { icon, children } = props;
  return (
    <Button {...props} icon={icon}>
      <Icon iconName={icon} />
      <Text kind="headingSix">{children}</Text>
    </Button>
  );
};

const Button = styled.button<SidebarButtonProps>`
  height: 44px;
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  border: none;
  outline: 0;
  position: relative;
  cursor: pointer;

  display: inline-flex;
  align-items: center;

  transition: background-color 0.1s ease-out;

  padding-left: 20px;
  ${tablet`
    padding-left: 32px;
  `}
  ${desktop`
    padding-left: 32px;
  `}

  ${Icon} {
    fill: ${(p) => (p.iconColor ? colors[p.iconColor] : colors.grey500)};
    margin-right: 12px;
  }
  ${Text} {
    color: ${colors.grey500};
  }
  ${mobile`
    font-size: 12px;
  `}

  &:hover {
    background-color: ${colors.grey50};
  }
`;
