import { Alert, AlertDialog, Text } from "@vericus/cadmus-ui";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

export enum RevealScope {
  ONE = "ONE",
  ALL = "ALL",
  SOME = "SOME",
}

export interface RevealStudentConfirmationModalProps {
  /**
   * When the user clicks on 'reveal' button, this callback will be fired.
   */
  onReveal: () => void;

  /**
   * The scope of the reveal action. This will change the messaging the user sees.
   */
  scope: RevealScope;
}

/**
 * The modal for when the user attempts to reveal a student's identity
 * in an anonymous marking enabled assessment.
 */
export const RevealStudentConfirmationModal = NiceModal.create(
  (props: RevealStudentConfirmationModalProps) => {
    const modal = useModal();

    return (
      <AlertDialog.Root
        open={modal.visible}
        onOpenChange={(open) => {
          if (!open) {
            modal.hide();
          }
        }}
      >
        <AlertDialog.Content onExitComplete={modal.remove}>
          {props.scope === RevealScope.ONE && (
            <AlertDialog.Title>
              Are you sure you want to reveal the student’s identity?
            </AlertDialog.Title>
          )}
          {props.scope === RevealScope.ALL && (
            <AlertDialog.Title>
              Are you sure you want to reveal everyone’s identity?
            </AlertDialog.Title>
          )}
          {props.scope === RevealScope.SOME && (
            <AlertDialog.Title>
              Are you sure you want to reveal the identities of these students?
            </AlertDialog.Title>
          )}
          <Alert severity={"warning"}>
            <AlertDialog.Description asChild>
              <Text kind={"bodyMd"}>
                This action will be recorded. Ensure you have a valid reason for
                revealing the student’s identity
              </Text>
            </AlertDialog.Description>
          </Alert>
          <AlertDialog.Actions>
            <AlertDialog.Cancel
              onClick={() => {
                modal.hide();
              }}
            >
              Cancel
            </AlertDialog.Cancel>
            {props.scope === RevealScope.ONE && (
              <AlertDialog.Action
                onClick={() => {
                  props.onReveal();
                  modal.hide();
                }}
              >
                Reveal student
              </AlertDialog.Action>
            )}
            {props.scope === RevealScope.ALL && (
              <AlertDialog.Action
                onClick={() => {
                  props.onReveal();
                  modal.hide();
                }}
              >
                Reveal all
              </AlertDialog.Action>
            )}
            {props.scope === RevealScope.SOME && (
              <AlertDialog.Action
                onClick={() => {
                  props.onReveal();
                  modal.hide();
                }}
              >
                Reveal
              </AlertDialog.Action>
            )}
          </AlertDialog.Actions>
        </AlertDialog.Content>
      </AlertDialog.Root>
    );
  }
);
