import { forwardRef } from "react";

import { TaskFormat } from "@/generated/graphql";

import { ExamMarkingSetting } from "./ExamMarkingSetting";
import { ExamSubmissionSetting } from "./ExamSubmissionSetting";
import { ExamTimingSetting } from "./ExamTimingSetting";
import { ConnectedExamTypeSetting } from "./ExamTypeSetting";

export interface ExamSettingsProps {
  /** Whether the backing LMS is Canvas. */
  hasCanvasAGS?: boolean;
  /** Whether students have started working on the exam already. */
  haveStudentsStarted: boolean;
  assessmentId: string;

  /** Whether submissions for the assessment have been made already. */
  hasSubmissions: boolean;
  format: TaskFormat;
  examTimingRef?: React.Ref<HTMLDivElement>;
  accessCodeFeatureEnabled: boolean;
  footnoteReferencingEnabled: boolean;
}

/**
 * Groups exam related settings UI components.
 */
export const ExamSettings = forwardRef<HTMLDivElement, ExamSettingsProps>(
  (
    {
      hasCanvasAGS,
      haveStudentsStarted,
      assessmentId,
      hasSubmissions,
      format,
      examTimingRef,
      accessCodeFeatureEnabled,
      footnoteReferencingEnabled,
    },
    ref
  ) => (
    <div ref={ref}>
      <ConnectedExamTypeSetting />
      <ExamTimingSetting
        accessCodeFeatureEnabled={accessCodeFeatureEnabled}
        haveStudentsStarted={haveStudentsStarted}
        examTimingRef={examTimingRef}
        assessmentId={assessmentId}
      />
      <ExamSubmissionSetting
        format={format}
        footnoteReferencingEnabled={footnoteReferencingEnabled}
      /> 
      <ExamMarkingSetting
        hasCanvasAGS={hasCanvasAGS}
        assessmentId={assessmentId}
        hasSubmissions={hasSubmissions}
        format={format}
      />
    </div>
  )
);
