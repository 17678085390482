import { ReactNode } from "react";
import { styled } from "styled-components";

import { Icon } from "@vericus/cadmus-icons";
import { IconButton, LinkButton, Spacer, Text } from "@vericus/cadmus-ui";

interface ToastContentProps {
  message: ReactNode;
  description?: ReactNode;
  action?: string;
  onAction?: VoidFunction;
  /** If truthy, a dismiss button will render that will call this function when clicked. */
  onDismiss?: VoidFunction;
}

/**
 * React component for custom Athena toasts.
 */
export function ToastContent(props: ToastContentProps) {
  const { message, description, action, onAction, onDismiss } = props;
  return (
    <Root>
      {onDismiss && (
        <DismissButton onClick={onDismiss} aria-label="Dismiss toast">
          <Icon iconName="Close" />
        </DismissButton>
      )}
      <Content>
        <Text kind="headingFive" color="inherit">
          {message}
        </Text>{" "}
        {description && (
          <>
            <Text kind="bodySm" color="inherit">
              {description}
            </Text>
            <Spacer spacing={8} />
          </>
        )}
        {action && (
          <Text kind="headingFive" color="inherit">
            <LinkButton inline color="blueA500" onClick={onAction}>
              {action}
            </LinkButton>
          </Text>
        )}
      </Content>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  color: ${(p) => p.theme.colors.white100};
`;

/** To give the dismiss button space */
const Content = styled.div`
  width: calc(100% - 36px);
`;

const DismissButton = styled(IconButton)`
  position: absolute;
  top: 0px;
  right: 0px;
  fill: #9dadca;
`;
