import {
  ModerationPenaltyRuleInput,
  ModerationPenaltyType,
  PenaltyFormatUnit,
} from "@/generated/graphql";

import {
  DefaultLateByInput,
  DefaultPenaltyScores,
} from "../../components/penalty-rule-form/input-constants";
import {
  LocalModerationPenaltyRule,
  NewLocalModerationPenaltyRule,
  PartialPenaltyRule,
} from "./types";
import { EnrollmentSubmitLateByMap } from "./use-enrollment-submit-lateby";
import { EnrollmentRulesMap } from "./use-students-applicable-rules-map";

/**
 * Given moderation penalty rule form states, return ModerationPenaltyRuleInput.
 * - minutesLateBy  will be default to 15, if is not set.
 * - penaltyScore will be default to 4, if is not set
 * @param penaltyRuleFormStates
 * @returns ModerationPenaltyRuleInput[]
 */
export const getModerationPenaltyRuleInputs = (
  penaltyRuleFormStates: (
    | (LocalModerationPenaltyRule & { isNew?: boolean })
    | NewLocalModerationPenaltyRule
  )[]
): ModerationPenaltyRuleInput[] => {
  return penaltyRuleFormStates.map((penaltyRuleFormState) => {
    const formatUnit = penaltyRuleFormState.formatUnit ?? PenaltyFormatUnit.Day;
    return {
      canStack: penaltyRuleFormState.canStack,
      formatUnit,
      // Set Id to null when this is a new penalty rule
      id: penaltyRuleFormState.isNew ? null : penaltyRuleFormState.id,
      includedTags: penaltyRuleFormState.includedTags ?? [],
      minutesLateBy:
        penaltyRuleFormState.minutesLateBy ??
        convertTimeToMinutes(DefaultLateByInput, formatUnit),
      penaltyScore: penaltyRuleFormState.penaltyScore ?? DefaultPenaltyScores,
      penaltyType: penaltyRuleFormState.penaltyType,
      reason: penaltyRuleFormState.reason,
    };
  });
};

/**
 * Given minutes and old format unit, return minutes that will
 * get displayed as same duration integer in new format unit.
 *
 * e.g. 21600 mins (1 day) -> 900 mins (1 hour)
 */
export const getMinutesOfDisplayDurationInNewUnit = (
  minutes: number,
  oldFormatUnit: PenaltyFormatUnit,
  newFormatUnit: PenaltyFormatUnit
) => {
  return convertTimeToMinutes(
    convertMinutesToFormatUnit(minutes, oldFormatUnit),
    newFormatUnit
  );
};

/** Given time number and unit, convert it to minutes */
export const convertTimeToMinutes = (time: number, unit: PenaltyFormatUnit) => {
  let minutesLateBy = time;
  switch (unit) {
    case PenaltyFormatUnit.Day:
      minutesLateBy = time * 1440;
      break;
    case PenaltyFormatUnit.Hour:
      minutesLateBy = time * 60;
      break;
  }
  return minutesLateBy;
};

/** Given minutes, convert it to given unit e.g. day. */
export const convertMinutesToFormatUnit = (
  time: number,
  unit: PenaltyFormatUnit
) => {
  let convertedTime = time;
  switch (unit) {
    case PenaltyFormatUnit.Day:
      convertedTime = time / 1440;
      break;
    case PenaltyFormatUnit.Hour:
      convertedTime = time / 60;
      break;
  }
  return convertedTime;
};

/**
 * Given a penalty rule, and a map of applied rules of each enrollment Id,
 * return enrollment ids that are applicable to this rule.
 */
export const getPenaltyRuleApplicableEnrollmentIds = <
  T extends PartialPenaltyRule,
>(
  penaltyRule: PartialPenaltyRule,
  enrollmentAppliedRulesMap: EnrollmentRulesMap<T>,
  enrollmentLateBy: EnrollmentSubmitLateByMap
) => {
  const applicableEnrollmentIds: string[] = [];

  Object.entries(enrollmentAppliedRulesMap).forEach(
    ([enrollmentId, appliedRules]) => {
      if (
        getIfPenaltyApplicableToEnrollment(
          enrollmentId,
          enrollmentLateBy,
          penaltyRule,
          appliedRules
        )
      )
        applicableEnrollmentIds.push(enrollmentId);
    }
  );

  return applicableEnrollmentIds;
};

/**
 * Given a penalty rule and the applied rules of an enrollment,
 * return whether the penalty of this rule is applicable to
 * enrollment
 *
 * @param enrollmentId
 * @param enrollmentLateBy
 * @param penaltyRuleToCheck ModerationPenaltyRule
 * @param appliedRules All applied rules of an enrollment
 * @returns boolean
 */
export function getIfPenaltyApplicableToEnrollment(
  enrollmentId: string,
  enrollmentLateBy: EnrollmentSubmitLateByMap,
  penaltyRuleToCheck: PartialPenaltyRule,
  appliedRules: PartialPenaltyRule[]
) {
  // Ordered by penalty type (more_than, custom), minutes_late_by (desc)
  const sortedAppliedRules = [
    ...appliedRules
      .filter(
        (appliedRule) =>
          appliedRule.penaltyType === ModerationPenaltyType.MoreThan
      )
      .sort((a, b) => (b.minutesLateBy ?? 0) - (a.minutesLateBy ?? 0)),
    ...appliedRules.filter(
      (appliedRule) => appliedRule.penaltyType === ModerationPenaltyType.Custom
    ),
  ];
  if (penaltyRuleToCheck.penaltyType === ModerationPenaltyType.Custom) {
    if (penaltyRuleToCheck.canStack) {
      return (
        sortedAppliedRules.find(
          (sortedAppliedRule) => sortedAppliedRule.id === penaltyRuleToCheck.id
        ) !== undefined
      );
    } else {
      let firstApplicableRule = null;
      let hasCanStackRule = false;
      for (const sortedAppliedRule of sortedAppliedRules) {
        if (
          sortedAppliedRule.penaltyType === ModerationPenaltyType.Custom &&
          sortedAppliedRule.canStack
        )
          hasCanStackRule = true;
        if (
          firstApplicableRule === null &&
          getEnrollmentWillBePenalisedByRule(
            enrollmentId,
            sortedAppliedRule,
            enrollmentLateBy
          )
        ) {
          // The first applied rule
          firstApplicableRule = sortedAppliedRule;
        }
      }
      if (hasCanStackRule) return false;
      return firstApplicableRule?.id === penaltyRuleToCheck.id;
    }
  } else {
    // LateMoreThanRule can't stack
    // lateMoreThanRule always come before custom rule
    const firstApplicableLateMoreThanRule = sortedAppliedRules.find((rule) =>
      getEnrollmentWillBePenalisedByRule(enrollmentId, rule, enrollmentLateBy)
    );
    // Only one lateMoreThanRule will be effective
    // If the first found late more than rule is the current rule
    return firstApplicableLateMoreThanRule?.id === penaltyRuleToCheck.id;
  }
}

/**
 * Given enrollment id, penalty rule and a map of submit late by due date in minutes
 * of each enrollment id. Return whether enrollment id will be penalised
 * if rule is applied to it
 *
 * @param enrollmentId
 * @param penaltyRule
 * @param enrollmentLateBy
 * @returns If rule is applied to enrollment, whether it will be penalised by rule
 */
export function getEnrollmentWillBePenalisedByRule(
  enrollmentId: string,
  penaltyRule: PartialPenaltyRule,
  enrollmentLateBy: EnrollmentSubmitLateByMap
) {
  const submitLateBy = enrollmentLateBy[enrollmentId];

  switch (penaltyRule.penaltyType) {
    case ModerationPenaltyType.Custom:
      return true;
    case ModerationPenaltyType.MoreThan: {
      if (
        submitLateBy === undefined ||
        submitLateBy === null ||
        penaltyRule.minutesLateBy === null
      )
        return false;
      else if (submitLateBy >= penaltyRule.minutesLateBy) return true;
      else return false;
    }
  }
}
