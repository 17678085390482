import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";

import { Input } from "@vericus/cadmus-ui";

import { debounce } from "ts-debounce";

interface InputProps
  extends Omit<React.ComponentProps<typeof Input>, "onChange" | "value"> {
  initialValue: string;
  onChange: (value: string) => void;
  debounceTime?: number;
}

interface DebounceInputSpecificProps {
  clearValue: () => void;
}
export interface DebouncedHTMLInputElement
  extends HTMLInputElement,
    DebounceInputSpecificProps {}

/**
 * A non-controlled input that emits onChange of the new value in a debounced
 * fashion.
 */
export const DebouncedInput = forwardRef<
  DebounceInputSpecificProps,
  InputProps
>((props: InputProps, ref) => {
  const { initialValue, onChange, ...inputProps } = props;
  const debounceTime = props.debounceTime ?? 800;
  const internalRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        internalRef.current?.focus();
      },
      clearValue: () => {
        if (internalRef.current) {
          internalRef.current.value = "";
        }
      },
    }),
    []
  );

  // Set the value internally, one time.
  useEffect(() => {
    if (internalRef.current) {
      internalRef.current.value = initialValue;
    }
  }, [initialValue, ref]);

  const debouncedOnChanged = useMemo(() => {
    return debounce(onChange, debounceTime);
  }, [debounceTime, onChange]);

  return (
    <Input
      ref={internalRef}
      {...inputProps}
      onChange={(e) => {
        debouncedOnChanged(e.target.value);
      }}
    />
  );
});
